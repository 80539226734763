import React, { useState, useEffect, useContext } from "react";

import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Colorize } from "@material-ui/icons";
import { ColorBox } from "material-ui-color";

import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";
import { IconButton, InputAdornment } from "@material-ui/core";
import { Input, Modal, Button } from "antd";
import { Divider, Stack, Typography, Grid } from '@mui/material';
import { ColorPicker } from 'antd';

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap"
  },
  multFieldLine: {
    display: "flex",
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(1)
    }
  },

  btnWrapper: {
    position: "relative"
  },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  colorAdorment: {
    width: 20,
    height: 20
  }
}));

const TagSchema = Yup.object().shape({
  name: Yup.string().min(3, "Mensagem muito curta").required("Obrigatório")
});

const TagModal = ({ open, onClose, tagId, reload, onTicketUpdate }) => {
  const classes = useStyles();
  const { user } = useContext(AuthContext);
  const [colorPickerModalOpen, setColorPickerModalOpen] = useState(false);
  const [color, setColor] = useState("#0F69E7");
  const initialState = {
    name: "",
    color: ""
  };

  const [tag, setTag] = useState(initialState);

  useEffect(()=>{
    return () =>{
      setTag(initialState);
    }
  },[])

  useEffect(() => {
    try {
      (async () => {
        if (!tagId) return;

        const { data } = await api.get(`/tags/${tagId}`);
        setTag(prevState => {
          return { ...prevState, ...data };
        });
        setColor(data.color);
      })();
    } catch (err) {
      toastError(err);
    }
  }, [tagId, open]);

  const handleClose = () => {
    setTag(initialState);
    setColorPickerModalOpen(false);
    onClose();
  };

  const handleChangeColor = (color) => { 
    let hexColor 
    try{
      hexColor = color.toHexString();
    }catch(error){

    }

    setColor(hexColor);
  }
  const handleSaveTag = async values => {
    const tagData = { ...values, userId: user.id };
    try {
      if (tagId) {
        await api.put(`/tags/${tagId}`, tagData);
      } else {
        const { data } = await api.post("/tags", tagData);
        onTicketUpdate && onTicketUpdate(data);
      }
      toast.success(i18n.t("Etiqueta salva com sucesso!"));
      if (typeof reload == "function") {
        reload();
      }
    } catch (err) {
      toastError(err);
    }
    handleClose();
  };


  const renderTitle = () => {
    return <Stack   >
      <Typography variant="h6" sx={{ ml: 1 }}>
        {tagId ? `Editar etiqueta` : `Nova etiqueta`}
      </Typography>
      <Typography variant="caption" sx={{ ml: 1 }}>
        {tagId ? `Edite a etiqueta` : `Adicione uma nova etiqueta`}
      </Typography>
    </Stack>
  }

  return (

    <Modal
      title={renderTitle()}
      open={open}
      onCancel={handleClose}
      footer={null}
    >
      <Divider sx={{ my: 1 }} />
      <Stack p={1}>
        <Formik
          initialValues={tag}
          enableReinitialize={true}
          validationSchema={TagSchema}
          onSubmit={(values, actions) => {
            setTimeout(() => {
              handleSaveTag({
                ...values,
                color: color
              });
              actions.setSubmitting(false);
            }, 400);
          }}
        >
          {({ touched, errors, isSubmitting, values, handleChange, handleSubmit }) => (
            <Form >
              <Grid container spacing={1} sx={{p:1}}>
                <Grid item xs>
                  <Stack >
                    <Typography variant="caption" color="gray">
                      Nome*
                    </Typography>
                    <Input
                      size="large"
                      id="name"
                      value={values.name}
                      onChange={handleChange}
                      name="name"
                      placeholder="Nome da etiqueta"
                    />
                  </Stack>
                </Grid>


                <Grid item xs={1.5}>
                  <Stack >
                    <Typography variant="caption" color="gray">
                      Cor*
                    </Typography>
                    <ColorPicker size="large" onChange={handleChangeColor} value={color} />
                  </Stack>
                </Grid>

              </Grid>
              <DialogActions style={{ marginTop: 10 }}>
                <Button
                  onClick={handleClose}
                  color="secondary"
                  disabled={isSubmitting}
                  variant="outlined"
                >
                  {i18n.t("tagModal.buttons.cancel")}
                </Button>
                <Button
                  type="primary"
                  color="primary"
                  disabled={isSubmitting}
                  onClick={handleSubmit}
                  variant="contained"
                  className={classes.btnWrapper}
                >
                  {tagId
                    ? `${i18n.t("tagModal.buttons.okEdit")}`
                    : `${i18n.t("tagModal.buttons.okAdd")}`}
                  {isSubmitting && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </Button>
              </DialogActions>

            </Form>
          )}
        </Formik>
      </Stack>
    </Modal>
  );
};

export default TagModal;
