import styled, { css } from 'styled-components'
import { Handle as Port } from 'react-flow-renderer'


const RESPONSE_COLOR = {
  SUCCESS: '#5FD07E',
  ERROR: '#EF5350',
}

export const Container = styled.div.attrs((props) => props)`
  ${({ selected }) =>
    selected &&
    css`
      box-shadow: rgba(0, 89, 220, 0.8) 0px 1px 1px,
        rgba(0, 89, 220, 0.8) 0px 0px 1px 1px;
    `}
`


export const Tag = styled.div.attrs((props) => props)`
  background: ${({ statusCode, theme }) =>
    statusCode < 400 ? RESPONSE_COLOR.SUCCESS : RESPONSE_COLOR.ERROR}66;
  border-radius: 5px;
  padding: 5px 20px;
  color: ${({ statusCode, theme }) =>
    statusCode < 400 ? RESPONSE_COLOR.SUCCESS : RESPONSE_COLOR.ERROR};
  display: inline;
`

export const HandleContainer = styled.div`
  position: absolute;
  top: calc(50% - 12px);
  right: -6px;
`

export const Handle = styled(Port)`
  && {
    background: ${({ theme }) => theme.palette.background.paper};
    outline: 4px solid ${(props) => props.color}77;
    transform: translate(0);
    width: 12px;
    height: 12px;
    top: 6px;
    position: relative;
    &:hover {
      transform: scale(1.5);
    }
  }
`
