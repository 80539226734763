import React, { useState, useEffect, useReducer, useRef } from "react";

import { isSameDay, parseISO, format } from "date-fns";
import clsx from "clsx";

import { green } from "@material-ui/core/colors";
import {
  Button,
  CircularProgress,
  Divider,
  IconButton,
  makeStyles,
} from "@material-ui/core";

import {
  AccessTime,
  Block,
  Done,
  DoneAll,
  ExpandMore,
  GetApp,
  Facebook,
  Instagram,
} from "@material-ui/icons";

import MarkdownWrapper from "../MarkdownWrapper";
import ModalImageCors from "../ModalImageCors";
import MessageOptionsMenu from "../MessageOptionsMenu";
import whatsBackground from "../../assets/wa-background.jpg";

import api from "../../services/api";
import toastError from "../../errors/toastError";
import { socketConnection } from "../../services/socket";
import { Avatar, Stack, Typography } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  messagesListWrapper: {
    overflow: "hidden",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    width: "100%",
    minWidth: 300,
    minHeight: 200,
  },

  messagesList: {
    backgroundImage: `url(${whatsBackground})`,
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    padding: "20px 20px 20px 20px",
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },

  circleLoading: {
    color: green[500],
    position: "absolute",
    opacity: "70%",
    top: 0,
    left: "50%",
    marginTop: 12,
  },

  messageLeft: {
    marginRight: 20,
    marginTop: 2,
    minWidth: 100,
    maxWidth: 600,
    height: "auto",
    display: "block",
    position: "relative",
    "&:hover #messageActionsButton": {
      display: "flex",
      position: "absolute",
      top: 0,
      right: 0,
    },

    whiteSpace: "pre-wrap",
    backgroundColor: "#ffffff",
    color: "#303030",
    alignSelf: "flex-start",
    borderTopLeftRadius: 0,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 5,
    paddingBottom: 0,
    boxShadow: "0 1px 1px #b3b3b3",
  },

  messageCenter: {
    marginRight: 20,
    marginTop: 5,
    marginBottom: 5,
    minWidth: 100,
    maxWidth: 900,
    height: "auto",
    display: "block",
    position: "relative",
    "&:hover #messageActionsButton": {
      display: "flex",
      position: "absolute",
      top: 0,
      right: 0,
    },

    whiteSpace: "pre-wrap",
    backgroundColor: "#fffccc",
    color: "#303030",
    alignSelf: "center",
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 5,
    paddingBottom: 0,
    boxShadow: "0 1px 1px #b3b3b3",
  },


  messageInternal: {

    marginLeft: 20,
    marginTop: 2,
    minWidth: 100,
    maxWidth: 600,
    height: "auto",
    display: "block",
    position: "relative",
    "&:hover #messageActionsButton": {
      display: "flex",
      position: "absolute",
      top: 0,
      right: 0,
    },

    whiteSpace: "pre-wrap",
    backgroundColor: "#fffccc",
    color: "#303030",
    alignSelf: "flex-end",
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 0,
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 5,
    paddingBottom: 0,
    boxShadow: "0 1px 1px #b3b3b3",
  },
  quotedContainerLeft: {
    margin: "-3px -80px 6px -6px",
    overflow: "hidden",
    backgroundColor: "#f0f0f0",
    borderRadius: "7.5px",
    display: "flex",
    position: "relative",
  },

  quotedMsg: {
    padding: 10,
    maxWidth: 300,
    height: "auto",
    display: "block",
    whiteSpace: "pre-wrap",
    overflow: "hidden",
  },

  quotedSideColorLeft: {
    flex: "none",
    width: "4px",
    backgroundColor: "#6bcbef",
  },

  messageRight: {
    marginLeft: 20,
    marginTop: 2,
    minWidth: 100,
    maxWidth: 600,
    height: "auto",
    display: "block",
    position: "relative",
    "&:hover #messageActionsButton": {
      display: "flex",
      position: "absolute",
      top: 0,
      right: 0,
    },

    whiteSpace: "pre-wrap",
    backgroundColor: "#c6e8f8",
    color: "#303030",
    alignSelf: "flex-end",
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 0,
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 5,
    paddingBottom: 0,
    boxShadow: "0 1px 1px #b3b3b3",
  },

  quotedContainerRight: {
    margin: "-3px -80px 6px -6px",
    overflowY: "hidden",
    backgroundColor: "#cfe9ba",
    borderRadius: "7.5px",
    display: "flex",
    position: "relative",
  },

  quotedMsgRight: {
    padding: 10,
    maxWidth: 300,
    height: "auto",
    whiteSpace: "pre-wrap",
  },

  quotedSideColorRight: {
    flex: "none",
    width: "4px",
    backgroundColor: "#35cd96",
  },

  messageActionsButton: {
    display: "none",
    position: "relative",
    color: "#999",
    zIndex: 1,
    backgroundColor: "inherit",
    opacity: "90%",
    "&:hover, &.Mui-focusVisible": { backgroundColor: "inherit" },
  },

  messageContactName: {
    display: "flex",
    color: "#6bcbef",
    fontWeight: 500,
  },

  textContentItem: {
    overflowWrap: "break-word",
    padding: "3px 80px 6px 6px",
  },

  textContentItemDeleted: {
    fontStyle: "italic",
    color: "rgba(0, 0, 0, 0.36)",
    overflowWrap: "break-word",
    padding: "3px 80px 6px 6px",
  },

  messageMedia: {
    objectFit: "cover",
    width: 250,
    height: 200,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  },

  timestamp: {
    fontSize: 11,
    position: "absolute",
    bottom: 0,
    right: 5,
    color: "#999",
  },

  dailyTimestamp: {
    alignItems: "center",
    textAlign: "center",
    alignSelf: "center",
    width: "110px",
    backgroundColor: "#e1f3fb",
    margin: "10px",
    borderRadius: "10px",
    boxShadow: "0 1px 1px #b3b3b3",
  },

  dailyTimestampText: {
    color: "#808888",
    padding: 8,
    alignSelf: "center",
    marginLeft: "0px",
  },

  ackIcons: {
    fontSize: 18,
    verticalAlign: "middle",
    marginLeft: 4,
  },

  deletedIcon: {
    fontSize: 18,
    verticalAlign: "middle",
    marginRight: 4,
  },

  ackDoneAllIcon: {
    color: "#0C3249",
    fontSize: 18,
    verticalAlign: "middle",
    marginLeft: 4,
  },

  downloadMedia: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "inherit",
    padding: 10,
  },
}));

const reducer = (state, action) => {
  if (action.type === "LOAD_MESSAGES") {
    const messages = action.payload;
    const newMessages = [];

    messages.forEach((message) => {
      const messageIndex = state.findIndex((m) => m.id === message?.id);
      if (messageIndex !== -1) {
        state[messageIndex] = message;
      } else {
        newMessages.push(message);
      }
    });

    return [...newMessages, ...state];
  }

  if (action.type === "ADD_MESSAGE") {
    const newMessage = action.payload;
    const messageIndex = state.findIndex((m) => m?.id === newMessage?.id);

    if (messageIndex !== -1) {
      state[messageIndex] = newMessage;
    } else {
      state.push(newMessage);
    }

    return [...state];
  }

  if (action.type === "UPDATE_MESSAGE") {
    const messageToUpdate = action.payload;
    const messageIndex = state.findIndex((m) => m.id === messageToUpdate.id);

    if (messageIndex !== -1) {
      state[messageIndex] = messageToUpdate;
    }

    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const MessagesList = ({ ticket, ticketId, isGroup }) => {
  const classes = useStyles();

  const [messagesList, dispatch] = useReducer(reducer, []);
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [loading, setLoading] = useState(false);
  const lastMessageRef = useRef();

  const [selectedMessage, setSelectedMessage] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const messageOptionsMenuOpen = Boolean(anchorEl);
  const currentTicketId = useRef()
  const currentMessage = useRef()


  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);

    currentTicketId.current = ticketId;
  }, [ticketId]);

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchMessages = async () => {
        if (ticketId === undefined) return;
        try {
          const { data } = await api.get("/messages/" + ticketId, {
            params: { pageNumber },
          });

          if (currentTicketId.current === ticketId) {
            dispatch({ type: "LOAD_MESSAGES", payload: data.messages });
            setHasMore(data.hasMore);
            setLoading(false);
          }

          if (pageNumber === 1 && data.messages.length > 1) {
            scrollToBottom();
          }
        } catch (err) {
          setLoading(false);
          toastError(err);
        }
      };
      fetchMessages();
    }, 500);
    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [pageNumber, ticketId]);

  useEffect(() => {
    const companyId = localStorage.getItem("companyId");
    const socket = socketConnection({ companyId: companyId });

    socket.on("connect", () => socket.emit("joinChatBox", `${ticket.id}`));

    socket.on(`company-${companyId}-appMessage`, (data) => {
      if (data.action === "create") {

        dispatch({ type: "ADD_MESSAGE", payload: data.message });
        scrollToBottom();
      }
      if (data.action === "create-internal") {
        dispatch({ type: "ADD_MESSAGE", payload: data.message });
        scrollToBottom();
      }

      if (data.action === "update") {
        if(currentMessage.current === data.message.id) return
        dispatch({ type: "UPDATE_MESSAGE", payload: data.message });
        currentMessage.current = data.message.id
      }
    });

    return () => {
      socket.disconnect();
    };
  }, [ticketId, ticket]);

  const loadMore = () => {
    setPageNumber((prevPageNumber) => prevPageNumber + 1);
  };

  const scrollToBottom = () => {
    if (lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({});
    }
  };

  const handleScroll = (e) => {
    if (!hasMore) return;
    const { scrollTop } = e.currentTarget;

    if (scrollTop === 0) {
      document.getElementById("messagesList").scrollTop = 1;
    }

    if (loading) {
      return;
    }

    if (scrollTop < 50) {
      loadMore();
    }
  };

  const handleOpenMessageOptionsMenu = (e, message) => {
    setAnchorEl(e.currentTarget);
    setSelectedMessage(message);
  };

  const handleCloseMessageOptionsMenu = (e) => {
    setAnchorEl(null);
  };

  const checkMessageMedia = (message) => {
    if (message?.mediaType === "image") {
      return <ModalImageCors imageUrl={message?.mediaUrl} />;
    }
    if (message?.mediaType === "audio") {
      return (
        <audio controls>
          <source src={message?.mediaUrl} type="audio/ogg"></source>
        </audio>
      );
    }

    if (message?.mediaType === "video") {
      return (
        <video
          className={classes.messageMedia}
          src={message?.mediaUrl}
          controls
        />
      );
    } else {
      return (
        <>
          <div className={classes.downloadMedia}>
            <Button
              startIcon={<GetApp />}
              color="primary"
              variant="outlined"
              target="_blank"
              href={message?.mediaUrl}
            >
              Download
            </Button>
          </div>
          <Divider />
        </>
      );
    }
  };

  const renderMessageAck = (message) => {
    if (message?.ack === 0) {
      return <AccessTime fontSize="small" className={classes.ackIcons} />;
    }
    if (message?.ack === 1) {
      return <Done fontSize="small" className={classes.ackIcons} />;
    }
    if (message?.ack === 2) {
      return <DoneAll fontSize="small" className={classes.ackIcons} />;
    }
    if (message?.ack === 3 || message?.ack === 4) {
      return <DoneAll fontSize="small" className={classes.ackDoneAllIcon} />;
    }
  };

  const renderDailyTimestamps = (message, index) => {
    if (index === 0) {
      return (
        <span
          className={classes.dailyTimestamp}
          key={`timestamp-${message?.id}`}
        >
          <div className={classes.dailyTimestampText}>
            {format(parseISO(messagesList[index].createdAt), "dd/MM/yyyy")}
          </div>
        </span>
      );
    }
    if (index < messagesList.length - 1) {
      let messageDay = parseISO(messagesList[index].createdAt);
      let previousMessageDay = parseISO(messagesList[index - 1].createdAt);

      if (!isSameDay(messageDay, previousMessageDay)) {
        return (
          <span
            className={classes.dailyTimestamp}
            key={`timestamp-${message?.id}`}
          >
            <div className={classes.dailyTimestampText}>
              {format(parseISO(messagesList[index].createdAt), "dd/MM/yyyy")}
            </div>
          </span>
        );
      }
    }
    if (index === messagesList.length - 1) {
      return (
        <div
          key={`ref-${message?.createdAt}`}
          ref={lastMessageRef}
          style={{ float: "left", clear: "both" }}
        />
      );
    }
  };

  const renderMessageDivider = (message, index) => {
    return <span style={{ marginTop: 16 }} key={`divider-${message?.id}`}></span>

    if (index < messagesList.length && index > 0) {
      let messageUser = messagesList[index]?.fromMe;
      let previousMessageUser = messagesList[index - 1]?.fromMe;

      if (messageUser !== previousMessageUser) {
        return (
          <span style={{ marginTop: 16 }} key={`divider-${message?.id}`}></span>
        );
      }
    }
  };

  const renderQuotedMessage = (message) => {
    return (
      <div
        className={clsx(classes.quotedContainerLeft, {
          [classes.quotedContainerRight]: message?.fromMe,
        })}
      >
        <span
          className={clsx(classes.quotedSideColorLeft, {
            [classes.quotedSideColorRight]: message?.quotedMsg?.fromMe,
          })}
        ></span>
        <div className={classes.quotedMsg}>
          {!message?.quotedMsg?.fromMe && (
            <span className={classes.messageContactName}>
              {message?.quotedMsg?.contact?.name}
            </span>
          )}
          {message?.quotedMsg?.mediaUrl && (
            <div className={classes.quotedMsgMedia}>
              {checkMessageMedia(message?.quotedMsg)}
            </div>
          )}
          {message?.quotedMsg?.body}
        </div>
      </div>
    );
  };

  const renderTagSuportCustomer = (ticketId, dateStartSuport, nameOfQueue) => {

    function formatNameOfQueue() {
      if(String(nameOfQueue).length <= 15) return nameOfQueue
      return String(nameOfQueue).slice(0, 16)+ " ..."
    }


      return (
        <Stack 
          direction="row" 
          alignItems="center" 
          display="flex" 
          width="100%"
          justifyContent="center"
        >
          <p
            style={{
              color: 'gray'
            }}
          >------------- {<strong>{formatNameOfQueue()}</strong>} #{ticketId} - {format(dateStartSuport, "dd/MM/yyyy HH:mm")} -------------</p>
        </Stack>
      )

  
  }

  const createFirstCustomSuportTag = (firstMessage, tagsTiketIdsCreated)=>{
    if(firstMessage === undefined) {
      const firstQueueName = (ticket && ticket.queue) ? ticket.queue.name : "Sem fila"
      const firstDate = (ticket && ticket.createdAt) ? new Date(ticket.createdAt) : new Date()
      tagsTiketIdsCreated.push(String(ticket.ticketId))
      return renderTagSuportCustomer(ticket.ticketId, firstDate, firstQueueName)
    }
    const firstQueueName = (firstMessage.ticket && firstMessage.ticket.queue) ? firstMessage.ticket.queue.name : "Sem fila"
    const firstDate = (firstMessage.ticket && firstMessage.ticket.createdAt) ? new Date(firstMessage.ticket.createdAt) : new Date()
    tagsTiketIdsCreated.push(String(firstMessage.ticketId))
    return renderTagSuportCustomer(firstMessage.ticketId, firstDate, firstQueueName)
  }


  const createCustomSuportTag = (tagsTiketIdsCreated, currentMessage) =>{

    const tagAlrearyExists = tagsTiketIdsCreated.includes(String(currentMessage.ticketId))

    if(tagAlrearyExists || !currentMessage.fromMe) return null
    const queueName = (currentMessage.ticket && currentMessage.ticket.queue) ? currentMessage.ticket.queue.name : "Sem fila"
    const dateTicket = (currentMessage.ticket && currentMessage.ticket.createdAt) ? new Date(currentMessage.ticket.createdAt) : new Date()
    tagsTiketIdsCreated.push(String(currentMessage.ticketId))
    return renderTagSuportCustomer(currentMessage.ticketId, dateTicket, queueName)
  }

  const renderMessages = () => {

    let tagsTiketIdsCreated = []


    const myfirstMessage = messagesList.find(message => message.fromMe === true)
    let newTag = createFirstCustomSuportTag(myfirstMessage, tagsTiketIdsCreated)

    if (messagesList.length > 0) {
      const viewMessagesList = messagesList.map((message, index) => {
        if (message?.channel !== "campaing" && message?.channel !== "internal") {

          let messageJSXToReturn = null

          if (!message?.fromMe) {
            messageJSXToReturn = (
              <React.Fragment key={message?.id}>
                {renderDailyTimestamps(message, index)}
                {newTag}
                <Stack direction="row" spacing={1} alignItems="center" sx={{ mt: 1 }}>
                  <Avatar src={ticket?.contact?.profilePicUrl} sx={{
                    width: 30,
                    height: 30,
                  }} />
                  <Stack direction="row" justifyContent="space-between" alignItems="center" display="flex" sx={{
                    width: "100%",
                    maxWidth: 600,
                  }}>
                    <Typography variant="caption" >{ticket?.contact?.name}</Typography>
                  </Stack>
                </Stack>
                <Stack sx={{
                  ml: 4.5,
                  mt: -2.5
                }}>



                  {renderMessageDivider(message, index)}
                  <div
                    className={classes.messageLeft}
                    title={message?.queueId && message?.queue?.name}
                  >
                    <IconButton
                      variant="contained"
                      size="small"
                      id="messageActionsButton"
                      disabled={message?.isDeleted}
                      className={classes.messageActionsButton}
                      onClick={(e) => handleOpenMessageOptionsMenu(e, message)}
                    >
                      <ExpandMore />
                    </IconButton>
                    {isGroup && (
                      <span className={classes.messageContactName}>
                        {message?.contact?.name}
                      </span>
                    )}
                    {message?.mediaUrl && checkMessageMedia(message)}
                    <div className={classes.textContentItem}>
                      {message?.quotedMsg && renderQuotedMessage(message)}
                      <MarkdownWrapper>{message?.body}</MarkdownWrapper>
                      <span className={classes.timestamp}>
                        {format(parseISO(message?.createdAt), "HH:mm")}
                      </span>
                    </div>
                  </div>
                </Stack>
              </React.Fragment>
            );
          } else {
            messageJSXToReturn = (
              <React.Fragment key={message?.id}>
                {newTag}
                <Stack direction="row" spacing={1} sx={{
                  mt: 1,
                  justifyContent: "flex-end",
                  alignItems: "center",

                }}>
                  <Stack direction="row" spacing={1}>
                    <Stack direction="row" justifyContent="space-between" alignItems="center" display="flex" sx={{
                      width: "100%",
                      maxWidth: 600,
                    }}>
                      <Typography variant="caption" >{ticket?.user?.name}</Typography>
                    </Stack>
                    <Avatar src={"Você"} sx={{
                      width: 30,
                      height: 30,
                    }} />

                  </Stack>
                </Stack>
                <Stack sx={{
                  mr: 4.5,
                  mt: -2.8
                }}>
                  
                  {renderDailyTimestamps(message, index)}
                  {renderMessageDivider(message, index)}
                  <div
                    className={classes.messageRight}
                    title={message?.queueId && message?.queue?.name}
                  >
                    <IconButton
                      variant="contained"
                      size="small"
                      id="messageActionsButton"
                      disabled={message?.isDeleted}
                      className={classes.messageActionsButton}
                      onClick={(e) => handleOpenMessageOptionsMenu(e, message)}
                    >
                      <ExpandMore />
                    </IconButton>
                    {message?.mediaUrl && checkMessageMedia(message)}
                    <div
                      className={clsx(classes.textContentItem, {
                        [classes.textContentItemDeleted]: message?.isDeleted,
                      })}
                    >
                      {message?.isDeleted && (
                        <Block
                          color="disabled"
                          fontSize="small"
                          className={classes.deletedIcon}
                        />
                      )}
                      {message?.quotedMsg && renderQuotedMessage(message)}
                      <MarkdownWrapper>{message?.body}</MarkdownWrapper>
                      <span className={classes.timestamp}>
                        {format(parseISO(message?.createdAt), "HH:mm")}
                        {renderMessageAck(message)}
                      </span>
                    </div>
                  </div>
                </Stack>
              </React.Fragment>
            );

          }
          newTag = null
          newTag = createCustomSuportTag(tagsTiketIdsCreated, message)
          return messageJSXToReturn
        }

        if (message?.channel === "campaing") {
          return (
            <React.Fragment key={message?.id}>
              {renderDailyTimestamps(message, index)}
              {renderMessageDivider(message, index)}
              <div
                className={classes.messageCenter}
                title={message?.queueId && message?.queue?.name}
              >

                <div className={classes.textContentItem}>
                  {message?.quotedMsg && renderQuotedMessage(message)}
                  <MarkdownWrapper>{message?.body}</MarkdownWrapper>
                  <span className={classes.timestamp}>
                    {format(parseISO(message?.createdAt), "HH:mm")}
                  </span>
                </div>
              </div>
            </React.Fragment>
          );
        }
        if (message?.channel === "internal") {
          return (
            <React.Fragment key={message?.id}>
              {renderDailyTimestamps(message, index)}
              {renderMessageDivider(message, index)}
              <div
                className={classes.messageInternal}
                title={message?.queueId && message?.queue?.name}
              >

                <div className={classes.textContentItem}>
                  {message?.quotedMsg && renderQuotedMessage(message)}
                  <MarkdownWrapper>{message?.body}</MarkdownWrapper>
                  <span className={classes.timestamp}>
                    {format(parseISO(message?.createdAt), "HH:mm")}
                  </span>
                </div>
              </div>
            </React.Fragment>
          );
        }
      });
      return viewMessagesList;
    } else {
      return <div>Say hello to your new contact!</div>;
    }
  };

  return (
    <div className={classes.messagesListWrapper}>
      <MessageOptionsMenu
        message={selectedMessage}
        anchorEl={anchorEl}
        menuOpen={messageOptionsMenuOpen}
        handleClose={handleCloseMessageOptionsMenu}
      />
      <div
        id="messagesList"
        className={classes.messagesList}
        onScroll={handleScroll}
      >
        {messagesList.length > 0 ? renderMessages() : []}
      </div>
      {ticket?.channel !== "whatsapp" && ticket?.channel !== "telegram" && (
        <div
          style={{
            width: "100%",
            display: "flex",
            padding: "10px",
            alignItems: "center",
            backgroundColor: "#E1F3FB",
          }}
        >
          {ticket?.channel === "facebook" ? (
            <Facebook small="true" />
          ) : (
            <Instagram small="true" />
          )}

          <span>
            Você tem 24h para responder após receber uma mensagem, de acordo
            com as políticas do Facebook.
          </span>
        </div>
      )}
      {loading && (
        <div>
          <CircularProgress className={classes.circleLoading} />
        </div>
      )}
    </div>
  );
};

export default MessagesList;