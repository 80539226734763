
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  Grid,
  Popover,
  Stack,
  Switch,
  Typography,
} from "@mui/material"
import NodeMenu from "../../../StyleNodes/Menu"
import React, { useRef, useState } from "react"
import { i18n } from "../../../../../../../translate/i18n";
import { Input, Select } from "antd"
import { renderIcons } from "../../../../Icons"

const handleStyle = { left: 10 }

export const WaitTimeMenu = ({ node, anchorRef }) => {
  const { data } = node
  const [values, setValues] = useState(data)
  const [openImport, setOpenImport] = useState(false)


  const handleRun = () => { }

  const handleChange = ({ target }) => {
    values[target.name] = target.value
    setValues({ ...values })
  }

  return (
    <NodeMenu
      open
      node={node}
      anchorRef={anchorRef}
      values={values}
      onChange={handleChange}
      onRun={handleRun}
    >
      <Stack direction="row" alignItems="center" spacing={1}>
        <Stack sx={{
          backgroundColor: `#0C3249`,
          padding: 1,
          borderRadius: "10px 10px 10px 10px"
        }}
        >
          {renderIcons(data?.payload?.icon)}
        </Stack>
        <Stack spacing={0}>
          <Typography variant="h6"> {i18n.t('Aguardar')}</Typography>
          <Typography color="text.secondary" sx={{ mt: -0.7 }}>
            {i18n.t('Aguardar um tempo determinado no fluxo')}
          </Typography>

        </Stack>
      </Stack>
      <Divider sx={{ my: 1 }} />
      <Box my={1}>
        <Typography variant="caption" color="text.secondary">
          Tempo de espera (em segundos):
        </Typography>
        <Input
          size="large"
          name="waitTime"
          onChange={handleChange}
          value={values?.waitTime}
          type="number"
          min="0"
          max="60000"
        />
      </Box>
    </NodeMenu>
  )
}

export default WaitTimeMenu
