import React, { useState, useEffect } from "react";

import {
	makeStyles,
	Paper
} from "@material-ui/core";

import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import Title from "../../components/Title";
import { i18n } from "../../translate/i18n";
import useHelps from "../../hooks/useHelps";
import { Stack, Typography } from "@mui/material";

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles(theme => ({
	mainPaper: {
		width: '100%',
		minHeight: '200px',
		overflowY: "scroll",
		borderRadius: "10px",
		boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
		...theme.scrollbarStyles,
	},
	heading: {
	  fontSize: theme.typography.pxToRem(15),
	  flexBasis: '33.33%',
	  flexShrink: 0,
	},
	secondaryHeading: {
	  fontSize: theme.typography.pxToRem(15),
	  color: theme.palette.text.secondary,
	}
}));

const Helps = () => {
	const classes = useStyles();

	const [records, setRecords] = useState([]);
	const { list } = useHelps()

	useEffect(() => {
		async function fetchData() {
			const helps = await list()
			setRecords(helps)
		}
		fetchData()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const renderVideo = (record) => {
		const url = `https://www.youtube.com/embed/${record.video}`;
		return (
			<iframe style={{ width: 700, height: 500 }} src={url} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
		)
			
	}

	const renderHelps = () => {
		return <>
			{ records.length ? records.map((record, key) => (
				<Accordion key={key}>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel1a-content"
						id="panel1a-header"
					>
						<Typography className={classes.heading} variant="h5">{ record.title }</Typography>
          				<Typography className={classes.secondaryHeading}>{ record.description }</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography>{ renderVideo(record) }</Typography>
					</AccordionDetails>
				</Accordion>
			)) : null }
		</>
	}

	return (
		<MainContainer className={classes.mainContainer}>
		<Paper className={classes.mainPaper} variant="outlined" style={{height: "100%", overflow: "hidden"}}>

			<MainHeader>
				<Stack>
				<Typography variant="h5" color="black" style={{fontWeight: "bold", marginLeft: "10px",marginTop:"10px"}} gutterBottom>
					{i18n.t("helps.title")}
				</Typography>
				<Typography style={{marginTop: "-10px", marginLeft: "10px"}} variant="caption" color="textSecondary">
					Cadastre ou importe seus contatos.
				</Typography>
				</Stack>
				<MainHeaderButtonsWrapper>
				</MainHeaderButtonsWrapper>
			</MainHeader>
			<Stack
				style={{
					overflowY: "auto",
					margin: "10px",
					padding: "20px",
					backgroundColor: "rgb(244 244 244 / 53%)",
					borderRadius: "5px",
					height: "93%"
				}}
				>
				<Paper >
					<Stack>
						{ renderHelps() }
					</Stack>
				</Paper>
			</Stack>
			</Paper>
		</MainContainer>
	);
};

export default Helps;
