import classnames from "classnames"
import Box from "@mui/material/Box"
import { getCellPadding } from "./helper"
import { TableComponent } from "./styles"



function Table({ size, padding, variant, sx, ...props }) {
  return (
    <TableComponent
      {...props}
      component="table"
      className={classnames({
        [`variant-${variant}`]: true,
      })}
      sx={{
        "& .table-cell": {
          ...getCellPadding(padding, size),
        },
        ...sx,
      }}
    />
  )
}
Table.defaultProps = {
  variant: "spaced-shadow",
  size: "medium",
  padding: "normal",
}

function TBody(props) {
  return <Box {...props} component="tbody" className="table-body" />
}

function THead(props) {
  return (
    <Box
      {...props}
      component="thead"
      className={classnames({
        "table-head": true,
        "table-head-sticky": props.sticky,
      })}
    />
  )
}

function TRow({ active, hover, ...rest }) {
  return (
    <Box
      {...rest}
      component="tr"
      className={classnames({
        "table-row": true,
        "table-row-active": active,
        "table-row-hover": hover,
      })}
      sx={{
        ".table-body &.table-row": {
          ...rest.sx,
        },
      }}
    />
  )
}
TRow.defaultProps = {
  active: false,
}

function TCell({ align, sx, padding, size, ...rest }) {
  return (
    <Box
      component="td"
      {...rest}
      className="tcell table-cell"
      sx={{
        textAlign: align,
        ".table-row &.tcell.table-cell": {
          ...sx,
        },
      }}
    />
  )
}
TCell.defaultProps = {
  align: "left",
}

export { Table, TBody, THead, TCell, TRow }
