// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MuiAccordion-root {
    background-color: #fff;
    color: rgba(0, 0, 0, 0.87);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 0px !important;
    box-shadow: none !important;
    padding: 0px !important;
    position: relative;
    transition: margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    overflow-anchor: none;
    border-radius: 0;
    margin: 0px;
}

.MuiAccordion-root.Mui-expanded {
    margin: 0px 0 !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/Flow/Diagram/Sidebar/components/Nodes/style.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,0BAA0B;IAE1B,6DAA6D;IAC7D,6BAA6B;IAC7B,2BAA2B;IAC3B,uBAAuB;IACvB,kBAAkB;IAElB,yDAAyD;IACzD,qBAAqB;IACrB,gBAAgB;IAChB,WAAW;AACf;;AAEA;IACI,wBAAwB;AAC5B","sourcesContent":[".MuiAccordion-root {\n    background-color: #fff;\n    color: rgba(0, 0, 0, 0.87);\n    -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;\n    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;\n    border-radius: 0px !important;\n    box-shadow: none !important;\n    padding: 0px !important;\n    position: relative;\n    -webkit-transition: margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;\n    transition: margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;\n    overflow-anchor: none;\n    border-radius: 0;\n    margin: 0px;\n}\n\n.MuiAccordion-root.Mui-expanded {\n    margin: 0px 0 !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
