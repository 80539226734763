import { TimeToLeave } from '@material-ui/icons'
import {
  AccessAlarm,
  AccessTime,
  AccessTimeFilledOutlined,
  AddToPhotos,
  AirlineStops,
  Api,
  AutoMode,
  CallSplit,
  ChatBubble,
  Code,
  CommentOutlined,
  Cookie,
  Edit,
  Email,
  FilterAlt,
  FindInPage,
  Fingerprint,
  Flag,
  Keyboard,
  Loop,
  MouseOutlined,
  OpenInBrowser,
  PlayArrowOutlined,
  Public,
  PublicOff,
  Save,
  Search,
  Sell,
  Storage,
  SwipeRight,
  Timelapse,
  UpdateDisabled,
  VoiceOverOff,
} from '@mui/icons-material'
import SettingsEthernetIcon from '@mui/icons-material/SettingsEthernet'
import { AiFillFileExcel } from 'react-icons/ai'

export const renderIcons = (icon, color,isDiagram) => {
  switch (icon) {
    case 'startIcon':
      return (
        <PlayArrowOutlined
          sx={{ color: color ? color : '#fff', width: isDiagram ? 45 : 30, height: isDiagram? 45 :  30 }}
        />
      )
    case 'requestIcon':
      return (
        <Api sx={{ color: color ? color : '#fff', width: isDiagram ? 45 : 30, height: isDiagram? 45 :  30  }} />
      )
    case 'graphqlIcon':
      return (
        <Api sx={{ color: color ? color : '#fff', width: isDiagram ? 45 : 30, height: isDiagram? 45 :  30  }} />
      )
    case 'dbQueryIcon':
      return (
        <Storage
          sx={{ color: color ? color : '#fff', width: isDiagram ? 45 : 30, height: isDiagram? 45 :  30  }}
        />
      )
    case 'conditionalIcon':
      return (
        <FilterAlt
          sx={{ color: color ? color : '#fff', width: isDiagram ? 45 : 30, height: isDiagram? 45 :  30  }}
        />
      )
    case 'runCodeIcon':
      return (
        <Code sx={{ color: color ? color : '#fff', width: isDiagram ? 45 : 30, height: isDiagram? 45 :  30  }} />
      )
    case 'scheduleIcon':
      return (
        <Timelapse
          sx={{ color: color ? color : '#fff', width: isDiagram ? 45 : 30, height: isDiagram? 45 :  30  }}
        />
      )
    case 'jsonIcon':
      return (
        <SettingsEthernetIcon
          sx={{ color: color ? color : '#fff', width: isDiagram ? 45 : 30, height: isDiagram? 45 :  30  }}
        />
      )
    case 'flowVariableIcon':
      return (
        <CallSplit
          sx={{ color: color ? color : '#fff', width: isDiagram ? 45 : 30, height: isDiagram? 45 :  30  }}
        />
      )
    case 'messageIcon':
      return (
        <ChatBubble
          sx={{ color: color ? color : '#fff', width: isDiagram ? 45 : 30, height: isDiagram? 45 :  30  }}
        />
      )
      case 'saveMessageIcon':
      return (
        <Save
          sx={{ color: color ? color : '#fff', width: isDiagram ? 45 : 30, height: isDiagram? 45 :  30  }}
        />
      )
      case 'timeIntervalIcon':
        return (
          <AccessTime
            sx={{ color: color ? color : '#fff', width: isDiagram ? 45 : 30, height: isDiagram? 45 :  30  }}
          />
        )
      case 'tagTicketIcon':
        return (
          <Sell
            sx={{ color: color ? color : '#fff', width: isDiagram ? 45 : 30, height: isDiagram? 45 :  30  }}
          />
        )
      case 'activeMessageIcon':
        return (
          <ChatBubble
            sx={{ color: color ? color : '#fff', width: isDiagram ? 45 : 30, height: isDiagram? 45 :  30  }}
          />
        )
        case 'splitIcon':
          return (
            <CallSplit
              sx={{ color: color ? color : '#fff', width: isDiagram ? 45 : 30, height: isDiagram? 45 :  30  }}
            />
          )
        case 'inactiveIcon':
          return (
            <AccessAlarm
              sx={{ color: color ? color : '#fff', width: isDiagram ? 45 : 30, height: isDiagram? 45 :  30  }}
            />
          )
        case 'endFlowIcon':
          return (
            <Flag
              sx={{ color: color ? color : '#fff', width: isDiagram ? 45 : 30, height: isDiagram? 45 :  30  }}
            />
          )
    case 'jumpIcon':
      return (
        <AirlineStops
          sx={{ color: color ? color : '#fff', width: isDiagram ? 45 : 30, height: isDiagram? 45 :  30  }}
        />
      )
    case 'browserIcon':
      return (
        <Public sx={{ color: color ? color : '#fff', width: isDiagram ? 45 : 30, height: isDiagram? 45 :  30  }} />
      )
    case 'gotoUrlIcon':
      return (
        <OpenInBrowser
          sx={{ color: color ? color : '#fff', width: isDiagram ? 45 : 30, height: isDiagram? 45 :  30  }}
        />
      )
    case 'injectJavascriptIcon':
      return (
        <Code sx={{ color: color ? color : '#fff', width: isDiagram ? 45 : 30, height: isDiagram? 45 :  30  }} />
      )
    case 'pageClickIcon':
      return (
        <MouseOutlined
          sx={{ color: color ? color : '#fff', width: isDiagram ? 45 : 30, height: isDiagram? 45 :  30  }}
        />
      )
    case 'pageTypeIcon':
      return (
        <Keyboard
          sx={{ color: color ? color : '#fff', width: isDiagram ? 45 : 30, height: isDiagram? 45 :  30  }}
        />
      )
    case 'waitSelectorIcon':
      return (
        <AccessTimeFilledOutlined
          sx={{ color: color ? color : '#fff', width: isDiagram ? 45 : 30, height: isDiagram? 45 :  30  }}
        />
      )
    case 'solveCaptchaIcon':
      return (
        <Fingerprint
          sx={{ color: color ? color : '#fff', width: isDiagram ? 45 : 30, height: isDiagram? 45 :  30  }}
        />
      )
    case 'closeBrowserIcon':
      return (
        <VoiceOverOff
          sx={{ color: color ? color : '#fff', width: isDiagram ? 45 : 30, height: isDiagram? 45 :  30  }}
        />
      )
    case 'jsonToXlsxIcon':
      return (
        <AiFillFileExcel
          style={{ color: color ? color : '#fff', width: isDiagram ? 45 : 30, height: isDiagram? 45 :  30  }}
        />
      )
    case 'waitTimeIcon':
      return (
        <AccessTime
          style={{ color: color ? color : '#fff', width: isDiagram ? 45 : 30, height: isDiagram? 45 :  30  }}
        />
      )
    case 'updateTicketIcon':
      return (
        <SwipeRight
          style={{ color: color ? color : '#fff', width: isDiagram ? 45 : 30, height: isDiagram? 45 :  30  }}
        />
      )
    case 'createTicketIcon':
      return (
        <AddToPhotos
          style={{ color: color ? color : '#fff', width: isDiagram ? 45 : 30, height: isDiagram? 45 :  30  }}
        />
      )
    case 'searchTicketIcon':
      return (
        <Search
          style={{ color: color ? color : '#fff', width: isDiagram ? 45 : 30, height: isDiagram? 45 :  30  }}
        />
      )
    case 'smtpIcon':
      return (
        <Email
          style={{ color: color ? color : '#fff', width: isDiagram ? 45 : 30, height: isDiagram? 45 :  30  }}
        />
      )
    case 'setCookiesIcon':
      return (
        <Cookie
          style={{ color: color ? color : '#fff', width: isDiagram ? 45 : 30, height: isDiagram? 45 :  30  }}
        />
      )
    case 'startLoopIcon':
      return (
        <Loop
          style={{ color: color ? color : '#fff', width: isDiagram ? 45 : 30, height: isDiagram? 45 :  30  }}
        />
      )
    case 'endLoopIcon':
      return (
        <UpdateDisabled
          style={{ color: color ? color : '#fff', width: isDiagram ? 45 : 30, height: isDiagram? 45 :  30  }}
        />
      )
    case 'loopControlIcon':
      return (
        <AutoMode
          style={{ color: color ? color : '#fff', width: isDiagram ? 45 : 30, height: isDiagram? 45 :  30  }}
        />
      )

    case 'findCookiesIcon':
      return (
        <FindInPage
          style={{ color: color ? color : '#fff', width: isDiagram ? 45 : 30, height: isDiagram? 45 :  30  }}
        />
      )
      case 'createTabulationIcon':
        return (
          <CommentOutlined
            style={{ color: color ? color : '#fff', width: isDiagram ? 45 : 30, height: isDiagram? 45 :  30  }}
          />
        )
  }
}
