import {
  Avatar,
  Box,
  Collapse,
  Chip,
  IconButton,
  Stack,
  Typography
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { TBody, THead, Table, TCell, TRow } from "../../../components/Table";
import moment from "moment";
import { useState } from "react";
import classNames from "classnames";
import TraceItem from "./Trace";

export default function Row({ item, onShow, index }) {
  const [open, setOpen] = useState(false);
  const [rowVisibility, setRowVisibility] = useState("collapse");

  const METHOD_COLOR = {
    GET: "#1E88E5",
    POST: "#5FD07E",
    PUT: "#FF9800",
    PATCH: "#1E88E5",
    DELETE: "#EF5350"
  };

  const handleToggleRow = () => {
    if (!open) {
      // going to open
      setRowVisibility("visible");
    }
    setOpen(!open);
  };

  const handleEndAnimation = (el, done) => {
    if (!open) {
      setTimeout(() => {
        setRowVisibility("collapse");
        done();
      }, 100);
    }
  };

  const request = JSON.parse(item?.request);
  return (
    <>
      <TRow
        sx={{
          position: "relative",
          zIndex: 1,
          bgcolor: index % 2 === 0 ? "background.paper" : "background.default",
          cursor: "pointer"
        }}
        active={open}
        onClick={handleToggleRow}
      >
        <TCell align="center">
          <Stack direction="row" >
            <IconButton size="small" onClick={handleToggleRow}>
              <Box
                component={ExpandMoreIcon}
                className={classNames({
                  active: open
                })}
                sx={{
                  transition: theme => theme.transitions.create("transform"),
                  transform: "rotate(0)",
                  "&.active": {
                    transform: "rotate(-180deg)"
                  }
                }}
              />
            </IconButton>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Chip
                sx={{
                  backgroundColor: METHOD_COLOR[request?.method],
                  color: "white",
                  fontWeight: "bold"
                }}
                size="small"
                label={request?.method}
              />
            </Stack>
          </Stack>
        </TCell>
        <TCell>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography
              color="text.secondary"
              variant="inherit"
              sx={{
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              /{request?.flow?.path}
            </Typography>
          </Stack>
        </TCell>

        <TCell>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography
              color="text.secondary"
              variant="inherit"
              sx={{
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {moment(item?.createdAt).format("DD/MM/YYYY HH:mm:ss")}
            </Typography>
          </Stack>
        </TCell>
        <TCell>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography
              color="text.secondary"
              variant="inherit"
              sx={{ wordBreak: "break-word" }}
            >
              {moment(item?.updatedAt).diff(
                moment(item?.createdAt),
                "milliseconds"
              )}{" "}
              ms
            </Typography>
          </Stack>
        </TCell>
        <TCell>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography
              color="text.secondary"
              variant="inherit"
              sx={{ wordBreak: "break-word" }}
            >
               {request?.flow?.name}
            </Typography>
          </Stack>
        </TCell>
      </TRow>
      <tr style={{ border: "none" }}>
        <td colSpan={7} style={{ border: "none", padding: 0 }}>
          <Box sx={{ visibility: rowVisibility }}>
            <Collapse
              in={open}
              timeout={215}
              unmountOnExit
              addEndListener={handleEndAnimation}
            >
              <Box
                sx={{
                  border: "1px solid",
                  borderColor: "divider2",
                  borderRadius: 2,
                  overflow: "hidden"
                }}
              >
                <TraceItem item={item} />
              </Box>
            </Collapse>
          </Box>
        </td>
      </tr>
    </>
  );
}
