import { useContext, useEffect, useState } from "react";
import { Box, useMediaQuery, Theme, DialogContent, Stack, Typography } from "@mui/material";
import { FormikProvider, useFormik } from "formik";
import Lottie from "lottie-react";
import SuccessAnimation from "../../../../../assets/lotties/success.json";
import { initialValues } from "./helper";
import { toast } from "react-toastify";
import { Input } from "antd";
import { Modal, Popconfirm, notification } from "antd";
import api from "../../../../../services/api";
import TextArea from "antd/es/input/TextArea";

export default function DialogValidationSuccess({
  open,
  snapId,
  onClose,
  flowId,
  branchId
}) {
  const [animationEnd, setAnimationEnd] = useState(false);
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    name: "",
    description: ""
  });

  useEffect(() => {
    return () => {
      setValues({
        name: "",
        description: ""
      });
    };
  }, []);

  const onSubmit = async () => {
    try {
      setLoading(true);
      const publish = await api.post(`/flows/publish/${branchId}`, {
        payload: {
          flow: flowId,
          snap: snapId,
          name: values.name,
          description: values.description
        }
      });
      setLoading(false);
      toast.success("Fluxo publicado com sucesso");
      onClose();
    } catch (error) {
      setLoading(false);
      toast.error("Erro ao publicar fluxo");
    }
  };

  const handleChange = e => {
    values[e.target.name] = e.target.value;
    setValues({ ...values });
  };

  useEffect(() => {
    !open && setAnimationEnd(false);
  }, [open]);

  const handleAnimationEnd = () => {
    setAnimationEnd(true);
  };

  return (
    <Modal
      open={open}
      onCancel={onClose}
      onOk={onSubmit}
      okButtonProps={{ disabled: !animationEnd }}
      okText={"Publicar"}
      cancelText={"Cancelar"}
      width={600}
      confirmLoading={loading}
      title={"Publicar fluxo"}
    >
      <Box
        sx={{
          p: 0,
          display: "flex",
          flexDirection: "column",
          overflowY: "hidden"
        }}
      >
        {!animationEnd ? (
          <Stack
            sx={{
              display: "flex",
              flexDirection: "column",
              minWidth: 400,
              p: 0,
              pt: 2,
              px: 1
            }}
          >
            <Stack alignItems="center" justifyContent="center">
              <Lottie
                animationData={SuccessAnimation}
                loop={false}
                onComplete={handleAnimationEnd}
                style={{
                  width: 300,
                  display: "initial"
                }}
              />
            </Stack>
          </Stack>
        ) : (
          <>
            <Stack
              sx={{
                minWidth: 400,
                p: 0,
                pt: 2,
                px: 1
              }}
            >
              <Stack spacing={3}>
                <Stack>
                  <Typography variant="caption" color="gray">
                    Nome da versão *
                  </Typography>
                  <Input
                    name="name"
                    placeholder={"Nome"}
                    size="large"
                    value={values.name}
                    onChange={handleChange}
                  />
                </Stack>
                <Stack>
                  <Typography variant="caption" color="gray">
                    Descrição *
                  </Typography>
                  <TextArea
                    size="large"
                    id="description"
                    value={values.description}
                    onChange={handleChange}
                    name="description"
                    placeholder="Descrição"
                  />
                </Stack>
              </Stack>

              <Stack spacing={3}></Stack>
            </Stack>
          </>
        )}
      </Box>
    </Modal>
  );
}
