import React, { useState, useEffect, useReducer, useContext } from "react";

import { Avatar, Button, Empty } from "antd";
import { toast } from "react-toastify";

import Paper from "@material-ui/core/Paper";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import Search from "antd/es/input/Search";
import EditIcon from "@material-ui/icons/Edit";
import { Stack, Typography } from "@mui/material";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";

import api from "../../../../services/api";
import { socketConnection } from "../../../../services/socket";

import { i18n } from "../../../../translate/i18n";
import toastError from "../../../../errors/toastError";
import { AuthContext } from "../../../../context/Auth/AuthContext";

import { Can } from "../../../../components/Can";
import MainHeader from "../../../../components/MainHeader";
import MainContainer from "../../../../components/MainContainer";
import TRowSkeleton from "../../../../components/TableRowSkeleton";
import ConfirmationModal from "../../../../components/ConfirmationModal";
import ContactGroupModal from "../../../../components/ContactGroupModal";
import ConfirmationModalImport from "../../../../components/ConfirmationModal";
import { TBody, THead, Table, TCell, TRow } from "../../../../components/Table";
import MainHeaderButtonsWrapper from "../../../../components/MainHeaderButtonsWrapper";
import moment from "moment";
import TableRowSkeleton from "../../../../components/TableRowSkeleton";

const reducer = (state, action) => {
  if (action.type === "LOAD_CONTACT_GROUPS") {
    const contactGroups = action.payload;
    const newContacts = [];

    contactGroups.forEach((contactGroup) => {
      const contactIndex = state.findIndex((c) => c.id === contactGroup.id);

      if (contactIndex !== -1) {
        state[contactIndex] = Object.assign(state[contactIndex], contactGroup);
      } else {
        newContacts.push({ ...contactGroup, showContacts: false });
      }
    });

    return [...state, ...newContacts];
  }

  if (action.type === "UPDATE_CONTACT_GROUPS") {
    const contactGroup = action.payload;
    const contactIndex = state.findIndex((c) => c.id === contactGroup.id);

    if (contactIndex !== -1) {
      state[contactIndex] = Object.assign(state[contactIndex], contactGroup);

      return [...state];
    } else {
      return [{ ...contactGroup, showContacts: false }, ...state];
    }
  }

  if (action.type === "DELETE_CONTACT_GROUPS") {
    const contactGroupId = action.payload;
    const contactGroupIndex = state.findIndex((c) => {
      return c.id === contactGroupId;
    });

    if (contactGroupIndex !== -1) {
      state.splice(contactGroupIndex, 1);
    }

    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    backgroundColor: "#fff",
    overflowY: "hidden",
    borderRadius: "10px",
    ...theme.scrollbarStyles,
  },
}));

const Wallet = () => {
  const classes = useStyles();

  const { user } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchParam, setSearchParam] = useState("");
  const [contactGroups, dispatch] = useReducer(reducer, []);
  const [selectedContactGroupId, setSelectedContactGroupId] = useState(null);
  const [contactModalOpen, setContactGroupModalOpen] = useState(false);
  const [deletingContactGroup, setDeletingContactGroup] = useState(null);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [contactGroupModal, setContactGroupModal] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [countContact, setCountContact] = useState(0);

  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [searchParam]);

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchContacts = async () => {
        try {
          const { data } = await api.get("/contact-groups", {
            params: { searchParam, pageNumber, userId: user?.id },
          });

          dispatch({
            type: "LOAD_CONTACT_GROUPS",
            payload: data.contactGroups,
          });

          setHasMore(data.hasMore);
          setCountContact(data.count);
        } catch (err) {
          toastError(err);
        } finally {
          setLoading(false);
        }
      };
      fetchContacts();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchParam, pageNumber, user]);

  useEffect(() => {
    const companyId = localStorage.getItem("companyId");
    const socket = socketConnection({ companyId });

    socket.on("connect", () => socket.emit("joinNotification"));

    socket.on(`company-${companyId}-contact-groups`, (data) => {
      if (data.action === "update" || data.action === "create") {
        dispatch({
          type: "UPDATE_CONTACT_GROUPS",
          payload: data.contactGroup,
        });
      }

      if (data.action === "delete") {
        dispatch({
          type: "DELETE_CONTACT_GROUPS",
          payload: +data.groupId,
        });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  const handleSearch = (event) => {
    setSearchParam(event.target.value.toLowerCase());
  };

  const handleOpenContactGroupModal = () => {
    setSelectedContactGroupId(null);
    setContactGroupModalOpen(true);
  };

  const handleCloseContactGroupModal = () => {
    setSelectedContactGroupId(null);
    setContactGroupModalOpen(false);
  };

  const hadleEditContactGroup = (groupId) => {
    setSelectedContactGroupId(groupId);
    setContactGroupModalOpen(true);
  };

  const handleRemoveContactGroup = async (groupId) => {
    try {
      await api.delete(`/contact-groups/${groupId}`);

      toast.success(i18n.t("Contato removido com sucesso!"));
    } catch (err) {
      toastError(err);
    } finally {
      setDeletingContactGroup(null);
      setSearchParam("");
      setPageNumber(1);
    }
  };

  const handleToggleContacts = (contactGroup) => {
    dispatch({
      type: "UPDATE_CONTACT_GROUPS",
      payload: {
        ...contactGroup,
        showContacts: !contactGroup.showContacts,
      },
    });
  };

  const loadMore = () => {
    setPageNumber((prevState) => prevState + 1);
  };

  const handleScroll = (e) => {
    if (!hasMore || loading) return;
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      loadMore();
    }
  };

  return (
    <MainContainer className={classes.mainContainer}>
      <ContactGroupModal
        open={contactModalOpen}
        onClose={handleCloseContactGroupModal}
        aria-labelledby="form-dialog-title"
        groupId={selectedContactGroupId}
      />
      <ConfirmationModal
        title={`${i18n.t("contacts.confirmationModal.deleteTitle")} ${
          deletingContactGroup?.name
        }?`}
        open={confirmOpen}
        onClose={setConfirmOpen}
        onConfirm={() => handleRemoveContactGroup(deletingContactGroup?.id)}
      >
        `${i18n.t("contacts.confirmationModal.deleteMessage")}`
      </ConfirmationModal>

      <ConfirmationModalImport
        title={`${i18n.t(
          "contactGroupListItems.confirmationModal.deleteTitle"
        )} ${deletingContactGroup?.name}?`}
        open={contactGroupModal}
        onClose={setContactGroupModal}
        onConfirm={() => handleRemoveContactGroup(deletingContactGroup?.id)}
      >
        `${i18n.t("contactGroupListItems.confirmationModal.deleteMessage")}`
      </ConfirmationModalImport>
      <Paper
        className={classes.mainPaper}
        variant="outlined"
        onScroll={handleScroll}
      >
        <MainHeader>
          <Stack>
            <Typography
              variant="h5"
              color="black"
              style={{
                fontWeight: "bold",
                marginLeft: "10px",
                marginTop: "10px",
              }}
              gutterBottom
            >
              Contatos Importados ({countContact})
            </Typography>
            <Typography
              style={{ marginTop: "-10px", marginLeft: "10px" }}
              variant="caption"
              color="textSecondary"
            >
              Crie ou gerencie os contatos importados.
            </Typography>
          </Stack>
          <MainHeaderButtonsWrapper>
            <Search
              placeholder={i18n.t("Buscar")}
              size="large"
              type="search"
              value={searchParam}
              onChange={handleSearch}
              style={{
                width: "300px",
                marginRight: "10px",
                marginLeft: "10px",
              }}
            />

            <Button
              size="large"
              type="primary"
              onClick={handleOpenContactGroupModal}
            >
              Adicionar importação
            </Button>
          </MainHeaderButtonsWrapper>
        </MainHeader>
        <Stack
          style={{
            padding: "20px",
            backgroundColor: "#fff",
            borderRadius: "5px",
            height: "93%",
            overflowY: "auto",
          }}
        >
          <Paper
            style={{
              boxShadow: "none",
              backgroundColor: "#fff",
            }}
          >
            <Table
              size="small"
              sx={{
                backgroundColor: "#fff",
              }}
            >
              <THead sticky>
                <TRow style={{ color: "#fff" }}>
                  <TCell
                    component="th"
                    align="center"
                    style={{ color: "#fff" }}
                  >
                    Nome
                  </TCell>
                  <TCell
                    component="th"
                    align="center"
                    style={{ color: "#fff" }}
                    noWrap
                  >
                    Quantidade
                  </TCell>
                  <TCell
                    component="th"
                    align="center"
                    style={{ color: "#fff" }}
                    noWrap
                  >
                    Carregados
                  </TCell>
                  <TCell
                    component="th"
                    align="center"
                    style={{ color: "#fff" }}
                    noWrap
                  >
                    Falhou
                  </TCell>
                  <TCell
                    component="th"
                    align="center"
                    style={{ color: "#fff" }}
                    noWrap
                  >
                    Responsável
                  </TCell>
                  <TCell
                    component="th"
                    align="center"
                    style={{ color: "#fff" }}
                    noWrap
                  >
                    Criado em
                  </TCell>
                  <TCell
                    component="th"
                    align="center"
                    style={{ color: "#fff" }}
                    sx={{ paddingRight: "40px" }}
                  >
                    {i18n.t("contacts.table.actions")}
                  </TCell>
                </TRow>
              </THead>

              <TBody>
                {contactGroups.map((contactGroup) => (
                  <>
                    <TRow
                      key={contactGroup.id}
                      sx={{ position: "relative", zIndex: 1 }}
                    >
                      <TCell align="center">{contactGroup.name}</TCell>
                      <TCell align="center">{contactGroup.count || 0}</TCell>
                      <TCell align="center">{contactGroup.valid || 0}</TCell>
                      <TCell align="center">{contactGroup.invalid || 0}</TCell>
                      <TCell align="center">
                        {contactGroup?.user?.name || "-"}
                      </TCell>
                      <TCell align="center">
                        {moment(contactGroup.createdAt).format(
                          "DD/MM/YYYY HH:mm"
                        )}
                      </TCell>
                      <TCell align="center">
                        <IconButton
                          size="small"
                          onClick={() => handleToggleContacts(contactGroup)}
                        >
                          {contactGroup.showContacts ? (
                            <ArrowDropUpIcon />
                          ) : (
                            <ArrowDropDownIcon />
                          )}
                        </IconButton>

                        <IconButton
                          size="small"
                          onClick={() => hadleEditContactGroup(contactGroup.id)}
                        >
                          <EditIcon />
                        </IconButton>

                        <Can
                          role={user.profile}
                          perform="contacts-page:deleteContact"
                          yes={() => (
                            <IconButton
                              size="small"
                              onClick={(e) => {
                                setConfirmOpen(true);
                                setDeletingContactGroup(contactGroup);
                              }}
                            >
                              <DeleteOutlineIcon />
                            </IconButton>
                          )}
                        />
                      </TCell>
                    </TRow>
                    {!!contactGroup.showContacts && (
                      <>
                        {!contactGroup.associations?.length ? (
                          <>
                            <Stack
                              sx={{
                                position: "absolute",
                                width: "100%",
                                padding: "8px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "column",
                              }}
                            >
                              <Paper
                                className={classes.mainPaper}
                                variant="outlined"
                                style={{
                                  width: "80%",
                                  paddingLeft: "8px",
                                  paddingRight: "8px",
                                }}
                                onScroll={handleScroll}
                              >
                                <Empty
                                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                                  description="Nenhum contato adicionado"
                                />
                              </Paper>
                            </Stack>
                            <div
                              style={{
                                ...(!!contactGroup.showContacts
                                  ? {
                                      paddingBottom: `${120}px`,
                                    }
                                  : {}),
                              }}
                            ></div>
                          </>
                        ) : (
                          <>
                            <Stack
                              sx={{
                                position: "absolute",
                                width: "100%",
                                padding: "8px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "column",
                              }}
                            >
                              <Paper
                                className={classes.mainPaper}
                                variant="outlined"
                                style={{
                                  width: "80%",
                                  paddingLeft: "8px",
                                  paddingRight: "8px",
                                }}
                                onScroll={handleScroll}
                              >
                                <Table size="small">
                                  <THead
                                    sticky
                                    style={{ position: "inherit", zIndex: 1 }}
                                  >
                                    <TRow style={{ color: "#fff" }}>
                                      <TCell
                                        component="th"
                                        style={{ color: "#fff" }}
                                        align="left"
                                      >
                                        <Typography
                                          variant="caption"
                                          sx={{
                                            color: "#fff",
                                            fontWeight: "bold",
                                            marginLeft: 2,
                                          }}
                                        >
                                          Nome
                                        </Typography>
                                      </TCell>
                                      <TCell
                                        component="th"
                                        style={{ color: "#fff" }}
                                        noWrap
                                      >
                                        Telefone
                                      </TCell>

                                      <TCell
                                        component="th"
                                        style={{ color: "#fff" }}
                                        noWrap
                                      >
                                        Status
                                      </TCell>

                                      <TCell
                                        component="th"
                                        style={{ color: "#fff" }}
                                        noWrap
                                      >
                                        Importado em
                                      </TCell>
                                    </TRow>
                                  </THead>

                                  <TBody>
                                    {contactGroup.associations.map(
                                      ({
                                        id,
                                        number,
                                        valid,
                                        duplicated,
                                        contact,
                                        createdAt,
                                      }) => (
                                        <TRow
                                          key={id}
                                          sx={{
                                            position: "relative",
                                            zIndex: 1,
                                          }}
                                        >
                                          <TCell
                                            sx={{
                                              backgroundColor: !valid
                                                ? "rgba(255, 0, 0, 0.1)"
                                                : "#fff",
                                            }}
                                          >
                                            <Stack
                                              direction="row"
                                              alignItems="center"
                                              spacing={1}
                                            >
                                              <Avatar
                                                src={
                                                  contact?.profilePicUrl || ""
                                                }
                                              />
                                              <Typography
                                                color="text.secondary"
                                                variant="inherit"
                                                sx={{ wordBreak: "break-word" }}
                                              >
                                                {contact?.name || number || "-"}
                                              </Typography>
                                            </Stack>
                                          </TCell>
                                          <TCell
                                            sx={{
                                              backgroundColor: !valid
                                                ? "rgba(255, 0, 0, 0.1)"
                                                : "#fff",
                                            }}
                                          >
                                            {contact?.number || number || "-"}
                                          </TCell>
                                          <TCell
                                            sx={{
                                              backgroundColor: !valid
                                                ? "rgba(255, 0, 0, 0.1)"
                                                : "#fff",
                                            }}
                                          >
                                            <Typography
                                              color="text.secondary"
                                              variant="inherit"
                                              sx={{ wordBreak: "break-word" }}
                                            >
                                              {(typeof valid !== "boolean"
                                                ? "Processando..."
                                                : !!valid
                                                ? "Válido"
                                                : "Inválido") +
                                                (!!duplicated
                                                  ? " - Duplicado"
                                                  : "")}
                                            </Typography>
                                          </TCell>
                                          <TCell
                                            sx={{
                                              backgroundColor: !valid
                                                ? "rgba(255, 0, 0, 0.1)"
                                                : "#fff",
                                            }}
                                          >
                                            {moment(createdAt).format(
                                              "DD/MM/YYYY HH:mm"
                                            )}
                                          </TCell>
                                        </TRow>
                                      )
                                    )}
                                    {loading && (
                                      <TableRowSkeleton columns={4} />
                                    )}
                                  </TBody>
                                </Table>
                              </Paper>
                            </Stack>
                            <div
                              style={{
                                ...(!!contactGroup.showContacts
                                  ? {
                                      paddingBottom: `${
                                        104 +
                                        54 * contactGroup.associations.length
                                      }px`,
                                    }
                                  : {}),
                              }}
                            ></div>
                          </>
                        )}
                      </>
                    )}
                  </>
                ))}
                {loading && <TRowSkeleton columns={4} />}
              </TBody>
            </Table>
          </Paper>
        </Stack>
      </Paper>
    </MainContainer>
  );
};

export default Wallet;
