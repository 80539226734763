import React, { RefObject, useEffect, useLayoutEffect, useRef } from 'react'
import { Box } from '@mui/material'
import InfiniteScroll from 'react-infinite-scroller'
import FabScroll from '../FabScroll'


export default function InfiniteScroller({
  children,
  hasMore,
  loadMore,
  scrollTo = 'top',
  sx,
  innerScrollRef,
  ...rest
}) {
  const scrollRef = useRef(null)

  useEffect(() => {
    if(!innerScrollRef) return
    scrollRef.current && (innerScrollRef.current = scrollRef.current)

  }, [scrollRef])

  useEffect(() => {
    if (rest.isReverse && scrollRef.current) {
      const { scrollHeight, offsetHeight } = scrollRef.current
      scrollRef.current.scrollTo({ top: scrollHeight - offsetHeight })
    }
  }, [rest.isReverse])

  return (
    <Box
      ref={scrollRef}
      className="fullHeight overflow-y tiny-scroll"
      sx={sx}
    >
      <InfiniteScroll
        hasMore={hasMore}
        loadMore={loadMore}
        useWindow={false}
        initialLoad={false}
        getScrollParent={() => scrollRef.current}
        {...rest}
      >
        {children}
      </InfiniteScroll>

      {!!scrollRef.current && <FabScroll to={scrollTo} scrollRef={scrollRef} />}
    </Box>
  )
}

InfiniteScroller.defaultProps = {
  scrollTo: 'top',
}
