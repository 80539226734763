import React from "react";
import { TRow, TCell } from "../Table";
import { Skeleton } from "antd";

const TableRowSkeleton = ({ avatar, columns }) => {
  return (
    <TRow>
      {avatar && (
        <TCell>
          <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <Skeleton.Avatar active size={40} shape="circle" />
            <Skeleton.Input active style={{ width: 80, height: 30, marginLeft: 16 }} />
          </div>
        </TCell>
      )}
      {Array.from({ length: columns }, (_, index) => (
        <TCell align="center" key={index}>
          <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <Skeleton.Input active style={{ width: 80, height: 30 }} />
          </div>
        </TCell>
      ))}
    </TRow>
  );
};

export default TableRowSkeleton;
