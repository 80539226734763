import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Popover,
  Stack,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material"
import NodeMenu from "../../../StyleNodes/Menu"

import { ExpandMore, InfoOutlined, UploadFileOutlined } from "@mui/icons-material"
import React, { useEffect, useRef, useState } from "react"
import { i18n } from "../../../../../../../translate/i18n";
import { Input, Select } from "antd"
import { renderIcons } from "../../../../Icons"

const handleStyle = { left: 10 }

export const EndLoopMenu = ({ node, anchorRef }) => {
  const { data } = node
  const [anchorEl, setAnchorEl] = useState(null)
  const [values, setValues] = useState(data)
  const [aliasItems, setAliasItems] = useState([])

 
  const handleRun = () => { }

  const handleChange = ({ target }) => {
    values[target.name] = target.value
    setValues({ ...values })
  }


  const handleChangeAlias = ({ target }) => {
    if (target.value.length > 20) return
    target.value = target.value.replace(/[^a-zA-Z0-9]/g, "")
    values[target.name] = target.value
    setValues({ ...values })
  }



  return (
    <NodeMenu
      open
      node={node}
      anchorRef={anchorRef}
      values={values}
      onChange={handleChange}
      onRun={handleRun}
    >
      <Stack direction="row" alignItems="center" spacing={1}>
        <Stack sx={{
          backgroundColor: `#0C3249`,
          padding: 1,
          borderRadius: "10px 10px 10px 10px"
        }}
        >
          {renderIcons(data?.payload?.icon)}
        </Stack>
        <Stack spacing={0}>
          <Typography variant="h6"> {i18n.t('Final do loop')}</Typography>
          <Typography color="text.secondary" sx={{ mt: -0.7 }}>
            {i18n.t('Final do loop no fluxo')}
          </Typography>

        </Stack>
      </Stack>
      <Divider sx={{ my: 1 }} />
      <Grid container spacing={2}>
      <Grid item xs={12}>
          <Stack>
            <Stack direction="row" alignItems="center" spacing={0.5}>
            <Typography variant="caption" color="text.secondary">
              {i18n.t('Alias')}
            </Typography>
              <Tooltip title="Use o alias criado para controlar o loop do fluxo">
                <IconButton size="small">
                  <InfoOutlined sx={{fontSize: "12px"}} />
                </IconButton>
              </Tooltip>
            </Stack>
            <Input
              placeholder="Alias"
              name="alias"
              value={values.alias}
              onChange={handleChangeAlias}
              size="large"
            />
          </Stack>
        </Grid>
      </Grid>
    </NodeMenu>
  )
}

export default EndLoopMenu
