import { Box, Button, Divider, Grid, Stack, Typography } from "@mui/material";
import NodeMenu from "../../../StyleNodes/Menu";

import AceEditor from "react-ace";

import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/mode-json";
import React, { useState } from "react";
import { i18n } from "../../../../../../../translate/i18n";
import { Input, Select } from "antd";
import { renderIcons } from "../../../../Icons";

export const RequestMenu = ({ node, anchorRef }) => {
  const { data } = node;
  const [anchorEl, setAnchorEl] = useState(null);
  const [values, setValues] = useState(data);
  const [openImport, setOpenImport] = useState(false);

  const handleRun = () => {};

  const handleChange = ({ target }) => {
    values[target.name] = target.value;
    setValues({ ...values });
  };

  const handleChangeSelect = (value) => {
    values["method"] = value;
    setValues({ ...values });
  };

  const handleChangeSwitch = ({ target }, value) => {
    values[target.name] = value;
    setValues({ ...values });
  };

  const handleOpenImport = (ev) => {
    setOpenImport(true);
    setAnchorEl(ev.target);
  };

  const handleCloseImport = () => {
    setOpenImport(false);
  };

  const handleImport = (result) => {
    const { url, method, params, data, header } = result;

    setValues({
      ...values,
      url,
      method,
      params,
      body: data ? JSON.stringify(data, null, 2) : undefined,
      headers: header ? JSON.stringify(header, null, 2) : undefined,
    });
    setOpenImport(false);
  };

  return (
    <NodeMenu
      open
      node={node}
      anchorRef={anchorRef}
      values={values}
      onChange={handleChange}
      onRun={handleRun}
    >
      <Stack direction="row" alignItems="center" spacing={1}>
        <Stack
          sx={{
            backgroundColor: `#0C3249`,
            padding: 1,
            borderRadius: "10px 10px 10px 10px",
          }}
        >
          {renderIcons(data?.payload?.icon)}
        </Stack>
        <Stack spacing={0}>
          <Typography variant="h6"> {i18n.t("Requisição HTTP")}</Typography>
          <Typography color="text.secondary" sx={{ mt: -0.7 }}>
            {i18n.t("Faça uma requisição HTTP")}
          </Typography>
        </Stack>
      </Stack>
      <Divider sx={{ my: 1 }} />
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Stack>
            <Typography variant="caption" color="text.secondary">
              {i18n.t("Método")}
            </Typography>
            <Select
              placeholder={i18n.t("Método")}
              id="method"
              onChange={handleChangeSelect}
              value={values.method}
              options={["POST", "GET", "PUT", "PATCH", "DELETE"].map(
                (option) => {
                  return { value: option, label: option };
                }
              )}
              size="large"
            />
          </Stack>
        </Grid>
        <Grid item xs={9}>
          <Stack>
            <Typography variant="caption" color="text.secondary">
              {i18n.t("URL")}
            </Typography>
            <Input
              placeholder="URL"
              name="url"
              value={values.url}
              onChange={handleChange}
              size="large"
            />
          </Stack>
        </Grid>
      </Grid>

      <Box my={1}>
        <Typography variant="caption" color="text.secondary">
          Headers
        </Typography>
        <AceEditor
          mode="json"
          theme="github"
          value={values.headers}
          onChange={(value) =>
            handleChange({
              target: { name: "headers", value },
            })
          }
          maxLines={10}
          minLines={10}
          name="UNIQUE_ID_OF_DIV"
          editorProps={{ $blockScrolling: true }}
          setOptions={{
            useWorker: false,
          }}
          style={{ width: "100%" }}
        />
      </Box>

      <Box my={1}>
        <Typography variant="caption" color="text.secondary">
          Body
        </Typography>
        <AceEditor
          mode="json"
          theme="github"
          value={values.body}
          onChange={(value) =>
            handleChange({
              target: { name: "body", value },
            })
          }
          setOptions={{
            useWorker: false,
          }}
          maxLines={20}
          minLines={10}
          name="UNIQUE_ID_OF_DIV"
          editorProps={{ $blockScrolling: true }}
          style={{ width: "100%" }}
        />
      </Box>
    </NodeMenu>
  );
};

export default RequestMenu;
