import { Divider, Grid, IconButton, Paper, Stack,Tooltip,Typography } from "@mui/material"
import { Box } from "@mui/system"
import StatusCodes from "./status"

import { Position, NodeProps } from "react-flow-renderer"
import { Container, HandleContainer, Handle, Tag } from "./styled"
import Node from "../../StyleNodes/Node"
import { useState } from "react"
import { InfoOutlined } from "@mui/icons-material"
export { JsonData } from "./data"
export { JsonMenu } from "./menu"

const handleStyle = { left: 10 }

const JsonNode = (node) => {
  const { data } = node


  return (

    <Node node={node}>
      <Box px={2} pb={1} pt={1}>
        <Grid container spacing={1}>
          <Grid item>
            <Stack direction="row" alignItems="center" spacing={1}>
            <Tag statusCode={data.statusCode}> {data.statusCode} </Tag>
            <Tooltip title={StatusCodes[data.statusCode]?.message}>
            <IconButton size="small" >
              <InfoOutlined />
            </IconButton>
            </Tooltip>
          
            </Stack>
          </Grid>
         
        </Grid>
      </Box>
    </Node>

  )
}

export default JsonNode
