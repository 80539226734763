import React, { useState, useEffect, useRef, useContext } from "react";

import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";
import { head } from "lodash";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import CircularProgress from "@material-ui/core/CircularProgress";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { AiFillEye } from "react-icons/ai";
import { i18n } from "../../translate/i18n";
import moment from "moment";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";
import FlowSelect from "../FlowSelect";
import ConfirmationModal from "../ConfirmationModal";
import { Modal, Button, Input, Select, Empty } from "antd";
import { Stack, Typography, Grid, Tooltip } from "@mui/material";
import TextArea from "antd/es/input/TextArea";
import ContactListModal from "../ContactListModal";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },

  textField: {
    marginRight: theme.spacing(1),
    flex: 1,
  },

  extraAttr: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  btnWrapper: {
    position: "relative",
  },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

const CampaignSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
});

const CampaignModal = ({
  open,
  onClose,
  campaignId,
  initialValues,
  onSave,
  resetPagination,
}) => {
  const classes = useStyles();
  const isMounted = useRef(true);
  const { user } = useContext(AuthContext);
  const { companyId } = user;

  const initialState = {
    name: "",
    message1: "",
    message2: "",
    message3: "",
    message4: "",
    message5: "",
    confirmationMessage1: "",
    confirmationMessage2: "",
    confirmationMessage3: "",
    confirmationMessage4: "",
    confirmationMessage5: "",
    status: "INATIVA", // INATIVA, PROGRAMADA, EM_ANDAMENTO, CANCELADA, FINALIZADA,
    confirmation: false,
    scheduledAt: "",
    whatsappId: "",
    contactListId: "",
    companyId,
  };

  const [campaign, setCampaign] = useState(initialState);
  const [whatsapps, setWhatsapps] = useState([]);
  const [channel, setChannel] = useState(null); // [
  const [reservedChannel, setReservedChannel] = useState(null); // [
  const [flow, setFlow] = useState(null); // [

  const [contactLists, setContactLists] = useState([]);
  const [contactListModalOpen, setContactListModalOpen] = useState(false);
  const [attachment, setAttachment] = useState(null);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  const [contactList, setContactList] = useState(null);
  const [campaignEditable, setCampaignEditable] = useState(true);
  const attachmentFile = useRef(null);
  const [importPlanOpen, setImportPlanOpen] = useState(false);
  const [importValues, setImportValues] = useState(null);
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);
  const handleCloseImport = (e) => {
    setImportPlanOpen(false);
  };
  useEffect(() => {
    if (isMounted.current) {
      if (initialValues) {
        setCampaign((prevState) => {
          return { ...prevState, ...initialValues };
        });
      }

      api
        .get(`/contact-lists/list`, { params: { companyId } })
        .then(({ data }) => setContactLists(data));

      api
        .get(`/whatsapp`, { params: { companyId, session: 0 } })
        .then(({ data }) => setWhatsapps(data));

      if (!campaignId) return;

      api.get(`/campaigns/${campaignId}`).then(({ data }) => {
        setCampaign((prev) => {
          let prevCampaignData = Object.assign({}, prev);

          Object.entries(data).forEach(([key, value]) => {
            if (key === "scheduledAt" && value !== "" && value !== null) {
              prevCampaignData[key] = moment(value).format("YYYY-MM-DDTHH:mm");
            } else {
              prevCampaignData[key] = value === null ? "" : value;
            }
          });

          return prevCampaignData;
        });
      });
    }
  }, [campaignId, open, initialValues, companyId]);

  useEffect(() => {
    const now = moment();
    const scheduledAt = moment(campaign.scheduledAt);
    const moreThenAnHour =
      !Number.isNaN(scheduledAt.diff(now)) && scheduledAt.diff(now, "hour") > 1;
    const isEditable =
      campaign.status === "INATIVA" ||
      (campaign.status === "PROGRAMADA" && moreThenAnHour);

    setCampaignEditable(isEditable);
  }, [campaign.status, campaign.scheduledAt]);

  const handleClose = () => {
    onClose();
    setCampaign(initialState);
  };

  const handleAttachmentFile = (e) => {
    const file = head(e.target.files);
    if (file) {
      setAttachment(file);
    }
  };

  const handleSaveCampaign = async (values) => {
    try {
      const dataValues = {};
      Object.entries(values).forEach(([key, value]) => {
        if (key === "scheduledAt" && value !== "" && value !== null) {
          dataValues[key] = moment(value).format("YYYY-MM-DD HH:mm:ss");
        } else {
          dataValues[key] = value === "" ? null : value;
        }
      });

      if (campaignId) {
        await api.put(`/campaigns/${campaignId}`, dataValues);

        if (attachment != null) {
          const formData = new FormData();
          formData.append("file", attachment);
          await api.post(`/campaigns/${campaignId}/media-upload`, formData);
        }
        handleClose();
      } else {
        const { data } = await api.post("/campaigns", dataValues);

        if (attachment != null) {
          const formData = new FormData();
          formData.append("file", attachment);
          await api.post(`/campaigns/${data.id}/media-upload`, formData);
        }
        if (onSave) {
          onSave(data);
        }
        handleClose();
      }
      toast.success(i18n.t("campaigns.toasts.success"));
    } catch (err) {
      toastError(err);
    }
  };

  const deleteMedia = async () => {
    if (attachment) {
      setAttachment(null);
      attachmentFile.current.value = null;
    }

    if (campaign.mediaPath) {
      await api.delete(`/campaigns/${campaign.id}/media-upload`);
      setCampaign((prev) => ({ ...prev, mediaPath: null, mediaName: null }));
      toast.success(i18n.t("campaigns.toasts.deleted"));
    }
  };

  const renderMessageField = (identifier) => {
    return (
      <Field
        as={TextField}
        id={identifier}
        name={identifier}
        fullWidth
        rows={5}
        label={i18n.t(`campaigns.dialog.form.${identifier}`)}
        placeholder={i18n.t("campaigns.dialog.form.messagePlaceholder")}
        multiline={true}
        variant="outlined"
        helperText="Utilize variáveis como {nome}, {numero}, {email} ou defina variáveis personalziadas."
        disabled={!campaignEditable && campaign.status !== "CANCELADA"}
      />
    );
  };

  const renderConfirmationMessageField = (identifier) => {
    return (
      <Field
        as={TextField}
        id={identifier}
        name={identifier}
        fullWidth
        rows={5}
        label={i18n.t(`campaigns.dialog.form.${identifier}`)}
        placeholder={i18n.t("campaigns.dialog.form.messagePlaceholder")}
        multiline={true}
        variant="outlined"
        disabled={!campaignEditable && campaign.status !== "CANCELADA"}
      />
    );
  };

  const cancelCampaign = async () => {
    try {
      await api.post(`/campaigns/${campaign.id}/cancel`);
      toast.success(i18n.t("campaigns.toasts.cancel"));
      setCampaign((prev) => ({ ...prev, status: "CANCELADA" }));
      resetPagination();
    } catch (err) {
      toast.error(err.message);
    }
  };

  const restartCampaign = async () => {
    try {
      await api.post(`/campaigns/${campaign.id}/restart`);
      toast.success(i18n.t("campaigns.toasts.restart"));
      setCampaign((prev) => ({ ...prev, status: "EM_ANDAMENTO" }));
      resetPagination();
    } catch (err) {
      toast.error(err.message);
    }
  };

  const renderTitle = () => {
    return (
      <Stack>
        <Typography variant="h6" sx={{ ml: 1 }}>
          {campaignEditable && !campaignId
            ? `Nova campanha`
            : `Editar campanha`}
        </Typography>
        <Typography variant="caption" sx={{ ml: 1 }}>
          {campaignEditable && !campaignId
            ? `Cria uma nova campanha para enviar mensagens aos seus contatos`
            : `Visualize e edite os dados da campanha`}
        </Typography>
      </Stack>
    );
  };

  const handleCloseContactListModal = () => {
    api
      .get(`/contact-lists/list`, { params: { companyId } })
      .then(({ data }) => setContactLists(data));
    setContactListModalOpen(false);
  };

  const handleDownloadExample = () => {
    window.open("/ExemploCampanha.xlsx", "_blank");
  };

  const handleSubmitImport = (values) => {
    setImportValues(values);
  };

  return (
    <>
      <ContactListModal
        open={contactListModalOpen}
        onClose={handleCloseContactListModal}
        onSave={console.log}
      />
      <ConfirmationModal
        title={i18n.t("campaigns.confirmationModal.deleteTitle")}
        open={confirmationOpen}
        onClose={() => setConfirmationOpen(false)}
        onConfirm={deleteMedia}
      >
        {i18n.t("campaigns.confirmationModal.deleteMessage")}
      </ConfirmationModal>
      <Modal
        title={renderTitle()}
        open={open}
        onCancel={handleClose}
        footer={null}
        width={800}
      >
        <div style={{ display: "none" }}>
          <input
            type="file"
            ref={attachmentFile}
            onChange={(e) => handleAttachmentFile(e)}
          />
        </div>
        <Formik
          initialValues={campaign}
          enableReinitialize={true}
          validationSchema={CampaignSchema}
          onSubmit={(values, actions) => {
            setTimeout(() => {
              handleSaveCampaign({
                ...values,
                confirmation: confirmation,
                contactListId: contactList,
                whatsappId: channel,
              });
              actions.setSubmitting(false);
            }, 400);
          }}
        >
          {({
            values,
            errors,
            touched,
            isSubmitting,
            handleChange,
            handleSubmit,
          }) => (
            <Form>
              <DialogContent dividers>
                <Grid spacing={2} container>
                  <Grid xs={12} item>
                    <Stack>
                      <Typography variant="caption" color="gray">
                        Nome da campanha*
                      </Typography>
                      <Input
                        size="large"
                        id="name"
                        value={values.name}
                        onChange={handleChange}
                        name="name"
                        placeholder="Nome"
                      />
                    </Stack>
                  </Grid>
                  <Grid xs={6} item>
                    <Stack>
                      <Typography variant="caption" color="gray">
                        Canal*
                      </Typography>

                      <Select
                        placeholder={"Canais principais"}
                        id="contacts"
                        optionFilterProp="children"
                        onChange={setChannel}
                        value={channel}
                        mode="multiple"
                        disabled={!campaignEditable}
                        options={
                          whatsapps &&
                          whatsapps.map((whatsapp) => {
                            return {
                              value: whatsapp.id,
                              label: whatsapp.name,
                            };
                          })
                        }
                        size="large"
                        style={{ width: "100%" }}
                        maxTagCount="responsive"
                        tagRender={(props) => {
                          const { label, closable, onClose } = props;
                          return (
                            <div style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              height: '30px',
                              padding: '4px 8px',
                              backgroundColor: '#F0F0F0',
                              borderRadius: '5px',
                              margin: '1px'
                            }}>
                              {label}
                              {closable && <span onClick={onClose} style={{ marginLeft: '8px', cursor: 'pointer' }}>×</span>}
                            </div>
                          );
                        }}
                      />
                    </Stack>
                  </Grid>
                  <Grid xs={6} item>
                    <Stack>
                      <Typography variant="caption" color="gray">
                        Canais reservas
                      </Typography>

                      <Select
                        placeholder={"Canais reservas"}
                        id="contacts"
                        optionFilterProp="children"
                        onChange={setReservedChannel}
                        value={reservedChannel}
                        mode="multiple"
                        disabled={!campaignEditable}
                        options={
                          whatsapps &&
                          whatsapps.map((whatsapp) => {
                            return {
                              value: whatsapp.id,
                              label: whatsapp.name,
                            };
                          })
                        }
                        size="large"
                        style={{ width: "100%" }}
                        maxTagCount="responsive"
                        tagRender={(props) => {
                          const { label, closable, onClose } = props;
                          return (
                            <div style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              height: '30px',
                              padding: '4px 8px',
                              backgroundColor: '#F0F0F0',
                              borderRadius: '5px',
                              margin: '1px'
                            }}>
                              {label}
                              {closable && <span onClick={onClose} style={{ marginLeft: '8px', cursor: 'pointer' }}>×</span>}
                            </div>
                          );
                        }}
                      />
                    </Stack>
                  </Grid>
                  <Grid xs={6} item>
                    <Stack>
                      <Typography variant="caption" color="gray">
                        Lista de contatos*
                      </Typography>
                      <Stack
                        direction="row"
                        spacing={0.2}
                        sx={{ mt: -0.5 }}
                        alignItems="center"
                      >
                        <Select
                          placeholder={"Lista de contatos"}
                          id="contacts"
                          optionFilterProp="children"
                          onChange={setContactList}
                          value={contactList}
                          disabled={!campaignEditable}
                          options={
                            contactLists &&
                            contactLists.map((contactLists) => {
                              return {
                                value: contactLists.id,
                                label: contactLists.name,
                              };
                            })
                          }
                          size="large"
                          style={{ width: "100%" }}
                        />
                        <Tooltip title="Visualizar listas">
                          <IconButton
                            onClick={() => setContactListModalOpen(true)}
                            size="small"
                            color="primary"
                          >
                            <AiFillEye />
                          </IconButton>
                        </Tooltip>
                      </Stack>
                    </Stack>
                  </Grid>

                  <Grid xs={6} item>
                    <Stack>
                      <Typography variant="caption" color="gray">
                        Agendamento*
                      </Typography>
                      <Input
                        size="large"
                        id="scheduledAt"
                        value={values.scheduledAt}
                        onChange={handleChange}
                        name="scheduledAt"
                        placeholder="Nome"
                        type="datetime-local"
                      />
                    </Stack>
                  </Grid>

                  <Grid item xs={12}>
                    <Stack>
                      <Typography variant="caption" color="text.secondary">
                        {i18n.t("Fluxo")}
                      </Typography>
                      <FlowSelect value={flow} onChange={setFlow} />
                    </Stack>
                  </Grid>
                  {!flow && (
                    <Grid item xs={12}>
                      <Stack>
                        <Typography variant="caption" color="gray">
                          Mensagem
                        </Typography>
                        <TextArea
                          size="large"
                          id="message1"
                          value={values.message1}
                          onChange={handleChange}
                          name="message1"
                          placeholder="Mensagem"
                        />
                      </Stack>
                    </Grid>
                  )}

                  {(campaign.mediaPath || attachment) && (
                    <Grid xs={12} item>
                      <Button startIcon={<AttachFileIcon />}>
                        {attachment != null
                          ? attachment?.name
                          : campaign.mediaName}
                      </Button>
                      {campaignEditable && (
                        <IconButton
                          onClick={() => setConfirmationOpen(true)}
                          color="secondary"
                        >
                          <DeleteOutlineIcon />
                        </IconButton>
                      )}
                    </Grid>
                  )}
                </Grid>
              </DialogContent>
              <DialogActions>
                {campaign.status === "CANCELADA" && (
                  <Button
                    color="primary"
                    onClick={() => restartCampaign()}
                    variant="outlined"
                  >
                    {i18n.t("campaigns.dialog.buttons.restart")}
                  </Button>
                )}
                {campaign.status === "EM_ANDAMENTO" && (
                  <Button
                    color="primary"
                    onClick={() => cancelCampaign()}
                    variant="outlined"
                  >
                    {i18n.t("campaigns.dialog.buttons.cancel")}
                  </Button>
                )}
                {!attachment &&
                  !campaign.mediaPath &&
                  campaignEditable &&
                  !flow && (
                    <Button
                      color="primary"
                      onClick={() => attachmentFile.current.click()}
                      disabled={isSubmitting}
                      variant="outlined"
                    >
                      {i18n.t("campaigns.dialog.buttons.attach")}
                    </Button>
                  )}
                <Button
                  onClick={handleClose}
                  color="secondary"
                  disabled={isSubmitting}
                  variant="outlined"
                >
                  {i18n.t("campaigns.dialog.buttons.close")}
                </Button>
                {(campaignEditable || campaign.status === "CANCELADA") && (
                  <Button
                    onClick={handleSubmit}
                    type="primary"
                    color="primary"
                    disabled={isSubmitting}
                    variant="contained"
                    className={classes.btnWrapper}
                  >
                    {campaignId
                      ? `${i18n.t("campaigns.dialog.buttons.edit")}`
                      : `${i18n.t("campaigns.dialog.buttons.add")}`}
                    {isSubmitting && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                  </Button>
                )}
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default CampaignModal;
