import {
  Box,
  Divider,
  Stack,
  Typography,
} from "@mui/material"
import NodeMenu from "../../../StyleNodes/Menu"

import AceEditor from "react-ace"

import "ace-builds/src-noconflict/theme-github"
import "ace-builds/src-noconflict/mode-json"
import "ace-builds/src-noconflict/ext-language_tools"
import "ace-builds/src-noconflict/ext-beautify"

import React, { useRef, useState } from "react"
import { i18n } from "../../../../../../../translate/i18n";
import { Input, Select } from "antd"
import { renderIcons } from "../../../../Icons"
import TagSelect from "../../../../../../../components/TagSelect"

const handleStyle = { left: 10 }

export const TagTicketMenu = ({ node, anchorRef }) => {
  const { data } = node
  const [anchorEl, setAnchorEl] = useState(null)
  const [values, setValues] = useState(data)
  const [openImport, setOpenImport] = useState(false)

  const handleRun = () => { }

  const handleChange = (data) => {
    values["tags"] = data
    setValues({ ...values })
  }

  return (
    <NodeMenu
      open
      node={node}
      anchorRef={anchorRef}
      values={values}
      onChange={handleChange}
      onRun={handleRun}
    >
      <Stack direction="row" alignItems="center" spacing={1}>
        <Stack sx={{
          backgroundColor: `#0C3249`,
          padding: 1,
          borderRadius: "10px 10px 10px 10px"
        }}
        >
          {renderIcons(data?.payload?.icon)}
        </Stack>
        <Stack spacing={0}>
          <Typography variant="h6"> {i18n.t('Adicionar tag')}</Typography>
          <Typography color="text.secondary" sx={{ mt: -0.7 }}>
            {`Adicione uma tag ao atendimento.`}
          </Typography>

        </Stack>
      </Stack>
      <Divider sx={{ my: 1 }} />
      <Box my={1}>
      <Stack>
                  <Typography variant="caption" color="gray">
                    Selecione as tags
                  </Typography>
                  <TagSelect
                    selectedTagsIds={values.tags}
                    onChange={handleChange}
                  />
                </Stack>
      </Box>
    </NodeMenu>
  )
}

export default TagTicketMenu
