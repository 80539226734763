import { useEffect, useMemo, useState } from "react"
import { Box, useMediaQuery, Theme, DialogContent, Typography, Stack, Button, Grid, Divider } from "@mui/material"
import {i18n} from "../../../../../translate/i18n"
import { renderIcons } from "../../Icons"
import { Modal, Popconfirm, notification } from "antd"
import { Error } from "@mui/icons-material"

export default function DialogValidationError({ open, onClose, validation }) {


  const nodesWithError = useMemo(() => {
    if (!validation || !validation.nodes) { return [] }
    const nodes = []
    for (const key of Object.keys(validation.nodes)) {
      if (validation.nodes[key].showWarning) {
        nodes.push({
          id: key,
          ...validation.nodes[key]
        })
      }
    }
    return nodes

  }, [validation])





  return (
    <Modal
      open={open}
      onCancel={onClose}
      onOk={onClose}
      cancelText={i18n.t('Fechar')}
      okButtonProps={{ style: { display: "none" } }}
    >

      <Stack direction="row" alignItems="center" spacing={1}>
        <Stack sx={{
          backgroundColor: `#0C3249`,
          padding: 1,
          borderRadius: "10px 10px 10px 10px"
        }}
        >
          <Error sx={{ color: "#fff", width: 30, height: 30 }} />
        </Stack>
        <Stack spacing={0}>
          <Typography variant="h6"> {i18n.t('Erro ao validar')}</Typography>
          <Typography color="text.secondary" sx={{ mt: -0.7 }}>
            {i18n.t('Sua publicação do fluxo contem erros')}
          </Typography>

        </Stack>
      </Stack>
      <Divider sx={{ my: 2 }} />

      <Grid container spacing={2}>
        {nodesWithError.map(it => (
          <Grid item xs={6}>
            <Stack spacing={2} sx={{
              boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.2)",
              borderRadius: "10px",
              padding: 2,
            }}>
              <Stack direction="row" alignItems="center" spacing={1.5}>

                <Stack sx={{
                  backgroundColor: `#0C3249`,
                  padding: 1,
                  borderRadius: "10px 10px 10px 10px"
                }}
                >
                  {renderIcons(it.payload?.icon)}
                </Stack>

                <Stack spacing={0}>
                  <Typography variant="body1"> {it.title}</Typography>
                  <Popconfirm
                    title={it.title}
                    description={<>
                      {it.errors.map(err => (
                        <>
                        <Divider sx={{ my: 1 }} />
                        <Typography variant="caption">
           
                          {i18n.t(err.message)}
                        </Typography>
                      </>
                      ))}
                    </>}
                    icon={<Error sx={{ color: "red",mt:0.2,mr:0.5, width: 18, height: 18 }} />}
                    cancelText="Fechar"
                    okButtonProps={{ style: { display: "none" } }}
                  >
                    <Stack sx={{
                      backgroundColor: `#e9e9e9`,
                      height: 20,
                      "&:hover": {
                        backgroundColor: `#dcdcdc`,
                      },
                      cursor: "pointer",
                      borderRadius: "10px 10px 10px 10px"

                    }} alignItems="center">

                      <Typography variant="caption" >
                        Ver erros
                      </Typography>
                    </Stack>
                  </Popconfirm>

                </Stack>
              </Stack>
            </Stack>
          </Grid>

        ))}
      </Grid>
    </Modal >
  )
}
