import * as Yup from "yup";
import { Formik, Form } from "formik";
import { toast } from "react-toastify";
import { Input, Modal, Button } from "antd";
import React, { useEffect, useState } from "react";

import {
  DialogActions,
  CircularProgress,
  Grid,
  Divider,
  Typography,
} from "@material-ui/core";
import { Stack } from "@mui/material";
import { green } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";

import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import toastError from "../../errors/toastError";

import ConnectionSelect from "../ConnectionSelect";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },

  multFieldLine: {
    display: "flex",
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(1),
    },
  },

  btnWrapper: {
    position: "relative",
  },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

const SessionSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
});

const ConnectionGroupModal = ({
  open,
  onClose,
  onSuccess,
  group,
  setLoading,
}) => {
  const classes = useStyles();

  const initialState = {
    name: "",
  };

  const [formKey, setFormKey] = useState(0);
  const [connectionGroup, setConnectionGroup] = useState(initialState);
  const [selectedConnectionIds, setSelectedConnectionIds] = useState([]);

  const resetForm = () => {
    setFormKey((prevKey) => prevKey + 1);
  };

  useEffect(() => {
    if (!open || !group) return;

    setConnectionGroup((prev) => {
      return { ...prev, ...group };
    });

    const connectionIds = group.childrens?.map(({ whatsapp }) => {
      return whatsapp.id;
    });

    setSelectedConnectionIds(connectionIds || []);
  }, [open, group]);

  const handleSaveConnectionGroup = async (values) => {
    try {
      setLoading(true);

      const whatsappData = { ...values, connectionIds: selectedConnectionIds };

      if (group?.id) {
        await api.put(`/connectionGroups/${group?.id}`, whatsappData);
      } else {
        await api.post("/connectionGroups", whatsappData);
      }

      toast.success(i18n.t("groupModal.success"));

      handleClose();
      onSuccess();
    } catch (err) {
      toastError(err);
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    onClose();
    setConnectionGroup(initialState);
    setSelectedConnectionIds([]);
    resetForm();
  };

  return (
    <div className={classes.root}>
      <Modal
        title={
          <Stack>
            <Typography variant="h6" sx={{ ml: 1 }}>
              {group?.id ? `Editar` : `Novo grupo de conexões`}
            </Typography>
            <Typography variant="caption" sx={{ ml: 1 }}>
              {group?.id
                ? `ID: ${group?.id}`
                : `Adicione um novo grupo de conexões`}
            </Typography>
          </Stack>
        }
        open={open}
        onCancel={handleClose}
        footer={null}
      >
        <Divider sx={{ my: 1 }} />
        <Stack p={1}>
          <Formik
            key={formKey}
            initialValues={connectionGroup}
            enableReinitialize={true}
            validationSchema={SessionSchema}
            onSubmit={(values, actions) => {
              setTimeout(() => {
                handleSaveConnectionGroup(values);
                actions.setSubmitting(false);
              }, 300);
            }}
          >
            {({
              values,
              touched,
              errors,
              handleSubmit,
              isSubmitting,
              handleChange,
            }) => (
              <Form>
                <Grid container spacing={1}>
                  <Grid item xs>
                    <Stack>
                      <Typography variant="caption" color="gray">
                        Nome*
                      </Typography>
                      <Input
                        size="large"
                        id="name"
                        value={values.name}
                        onChange={handleChange}
                        name="name"
                        placeholder="Nome do grupo"
                      />
                    </Stack>
                  </Grid>
                </Grid>
                <div>
                  <Stack sx={{ mt: 1, mb: 1 }}>
                    <Typography variant="caption" color="gray">
                      Conexões
                    </Typography>
                    <ConnectionSelect
                      selectedConnectionIds={selectedConnectionIds}
                      onChange={(selectedIds) => {
                        return setSelectedConnectionIds(selectedIds);
                      }}
                    />
                  </Stack>
                </div>

                <Stack mt={2}>
                  <DialogActions>
                    <Button
                      onClick={handleClose}
                      color="secondary"
                      disabled={isSubmitting}
                      variant="outlined"
                    >
                      {i18n.t("groupModal.buttons.cancel")}
                    </Button>
                    <Button
                      type="primary"
                      onClick={handleSubmit}
                      color="primary"
                      disabled={isSubmitting}
                      variant="contained"
                      className={classes.btnWrapper}
                    >
                      {group?.id
                        ? i18n.t("groupModal.buttons.okEdit")
                        : i18n.t("groupModal.buttons.okAdd")}
                      {isSubmitting && (
                        <CircularProgress
                          size={24}
                          className={classes.buttonProgress}
                        />
                      )}
                    </Button>
                  </DialogActions>
                </Stack>
              </Form>
            )}
          </Formik>
        </Stack>
      </Modal>
    </div>
  );
};

export default React.memo(ConnectionGroupModal);
