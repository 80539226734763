import React, { useState, useEffect, useContext } from "react";

import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Colorize } from "@material-ui/icons";
import { ColorBox } from "material-ui-color";

import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";
import { IconButton, InputAdornment } from "@material-ui/core";
import { Input, Modal, Button } from "antd";
import { Divider, Stack, Typography, Grid } from "@mui/material";
import { ColorPicker } from "antd";
import FlowDropzone from "../ImportFlowDropzone";
const { TextArea } = Input;
const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap"
  },
  multFieldLine: {
    display: "flex",
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(1)
    }
  },

  btnWrapper: {
    position: "relative"
  },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  colorAdorment: {
    width: 20,
    height: 20
  }
}));

const FlowSchema = Yup.object().shape({
  name: Yup.string().min(3, "Mensagem muito curta").required("Obrigatório")
});

const ImportFlowModal = ({ open, onClose, reload }) => {
  const classes = useStyles();
  const { user } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [flowImport, setFlowImport] = useState(null);

  const [values, setValues] = useState({
    name: "",
    description: ""
  });

  const handleClose = () => {
    setValues({
      name: "",
      description: ""
    });
    setFile(null)
    onClose();
  };


  const handleSaveFlow = async dataValues => {
    const flowData = { 
      flow: JSON.stringify(flowImport?.flow),
      meta: JSON.stringify(flowImport?.meta),
      name: values.name,
      description: values.description,
     };
    try {

      const { data } = await api.post("/flows/import", flowData);

      toast.success(i18n.t("Fluxo importado com sucesso!"));
      if (typeof reload == "function") {
        reload();
      }
    } catch (err) {
      toastError(err);
    }
    handleClose();
  };



  const renderTitle = () => {
    return (
      <Stack>
        <Typography variant="h6" sx={{ ml: 1 }}>
          Importar fluxo
        </Typography>
        <Typography variant="caption" sx={{ ml: 1 }}>
          Importe um fluxo existente
        </Typography>
      </Stack>
    );
  };

  const onSetJson = json => {
    values["name"] = json.name + " - Importado";
    values["description"] = json.description;
    setValues({ ...values });
    setFlowImport(json);
  };

  const handleChange = e => {
    values[e.target.name] = e.target.value;
    setValues({ ...values });
  };

  const handleSubmit = async () => {
  
  };

  return (
    <Modal
      title={renderTitle()}
      open={open}
      onCancel={handleClose}
      footer={null}
    >
      <Divider sx={{ my: 1 }} />
      <Stack p={1}>
        <Grid container spacing={1} sx={{ p: 1 }}>
          <Grid item xs={12}>
            <FlowDropzone onSetJson={onSetJson} onSetFile={setFile} file={file}/>
          </Grid>
          <Grid item xs>
            <Stack>
              <Typography variant="caption" color="gray">
                Nome*
              </Typography>
              <Input
                size="large"
                id="name"
                value={values.name}
                onChange={handleChange}
                name="name"
                placeholder="Nome do fluxo"
              />
            </Stack>
          </Grid>

          <Grid item xs={12}>
            <Stack>
              <Typography variant="caption" color="gray">
                Descrição
              </Typography>
              <TextArea
                // multiline

                size="large"
                id="description"
                value={values.description}
                onChange={handleChange}
                name="description"
                placeholder="Descrição do fluxo"
              />
            </Stack>
          </Grid>
        </Grid>
        <DialogActions style={{ marginTop: 10 }}>
          <Button
            onClick={handleClose}
            color="secondary"
            variant="outlined"
          >
            Cancelar
          </Button>
          <Button
            type="primary"
            color="primary"
            disabled={!values.name || !file}
            onClick={handleSaveFlow}
            variant="contained"
            className={classes.btnWrapper}
          >
            Importar
            {loading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </Button>
        </DialogActions>
      </Stack>
    </Modal>
  );
};

export default ImportFlowModal;
