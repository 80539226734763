import { Stack } from '@mui/material'
import PasswordStrengthTest from '../PasswordStrengthTest'
import InputPassword from '..'

export const InputPasswordStrength = (props) => {

  return (
    <Stack spacing={1}>
      <InputPassword {...props} />
      <PasswordStrengthTest value={props.defaultValue || props.value || ''} />
    </Stack>
  )
}

export default InputPasswordStrength
