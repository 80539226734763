import React, {
    useState,
  } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import MainContainer from "../../components/MainContainer";
import Board, { moveCard } from "@lourenci/react-kanban";
import "@lourenci/react-kanban/dist/styles.css";
import "./style.css"
  const useStyles = makeStyles((theme) => ({
    mainPaper: {
      flex: 1,
      backgroundColor: "#fff",
      overflowY: "hidden",
      borderRadius: "10px",
      ...theme.scrollbarStyles,
    },
  }));
  
const boarddata= {
    columns: [
      {
        id: 1,
        title: "Backlog",
        backgroundColor: "#fff",
        cards: [
          {
            id: 1,
            title: "Card title 1",
            description: "Card content"
          },
          {
            id: 2,
            title: "Card title 2",
            description: "Card content"
          },
          {
            id: 3,
            title: "Card title 3",
            description: "Card content"
          }
        ]
      },
      {
        id: 2,
        title: "Doing",
        cards: [
          {
            id: 9,
            title: "Card title 9",
            description: "Card content"
          }
        ]
      },
      {
        id: 3,
        title: "Q&A",
        cards: [
          {
            id: 10,
            title: "Card title 10",
            description: "Card content"
          },
          {
            id: 11,
            title: "Card title 11",
            description: "Card content"
          }
        ]
      },
      {
        id: 4,
        title: "Production",
        cards: [
          {
            id: 12,
            title: "Card title 12",
            description: "Card content"
          },
          {
            id: 13,
            title: "Card title 13",
            description: "Card content"
          }
        ]
      }
    ]
  };
  const items = [];
  



  const BoardPage = () => {
    const classes = useStyles();
    const history = useHistory();
    const [controlledBoard, setBoard] = useState(boarddata);
  
    function handleCardMove(_card, source, destination) {
      const updatedBoard = moveCard(controlledBoard, source, destination);
      setBoard(updatedBoard);
    }
    return (
      <MainContainer className={classes.mainContainer}>
          <Board 
            onCardDragEnd={handleCardMove} 
            disableColumnDrag>
        {controlledBoard}
      </Board>
      </MainContainer>
    );
  };
  
  export default BoardPage;
  