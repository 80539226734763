
export const daysOfWeeksArray = [{
    label: "Domingo", value: "sunday"
  }, {
    label: "Segunda", value: "monday"
  }, {
    label: "Terça", value: "tuesday"
  }, {
    label: "Quarta", value: "wednesday"
  }, {
    label: "Quinta", value: "thursday"
  }, {
    label: "Sexta", value: "friday"
  }, {
    label: "Sábado", value: "saturday"
  }]