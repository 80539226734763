export const ScheduleData = {
  hasInput: true,
  port: {
    next: {
      color: '#26C6DA',
    },
  },
  type: 'schedule',
  title: 'Schedule',
  label: 'Schedule node',
}

export default ScheduleData
