import {
  Box,
  Checkbox,
  Divider,
  Grid,
  IconButton,
  Slider,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material"
import NodeMenu from "../../../StyleNodes/Menu"
import "ace-builds/src-noconflict/theme-github"
import "ace-builds/src-noconflict/mode-mysql"
import { DeleteOutline, ExpandMore, UploadFileOutlined } from "@mui/icons-material"
import React, { useCallback, useEffect, useRef, useState } from "react"
import { i18n } from "../../../../../../../translate/i18n";
import { Button, Input, Select } from "antd"
import { renderIcons } from "../../../../Icons"
import { v4 as uuidV4 } from "uuid"
import { availableConditions } from "../data"
// import Scrollbar from "perfect-scrollbar";

// import "perfect-scrollbar/css/perfect-scrollbar.css";


const handleStyle = { left: 10 }

export const SplitMenu = ({ node, anchorRef }) => {
  const { data } = node
  const [anchorEl, setAnchorEl] = useState(null)
  const [values, setValues] = useState(data)
  const [openImport, setOpenImport] = useState(false)
  // const comp = useRef(null);

  const handleRun = () => { }

  const handleChange = ({ target }) => {
    if (target.name === 'encrypt') values[target.name] = target.checked
    else values[target.name] = target.value
    setValues({ ...values })
  }


  const handleChangeSelect = (value) => {
    values["dbType"] = value
    setValues({ ...values })
  }


  const handleConditionSelectChange = (value, index) => {
    values.conditions[index]["comparator"] = value
    setValues({ ...values })
  }

  const handleConditionChange = ({ target }, index) => {
    values.conditions[index][target.name] = target.value
    setValues({ ...values })
  }

  const handleAddCondition = () => {
    const id = uuidV4()

    const totalConditions = values.conditions.length
    values.payload.port[id] = {
      color: values.colors[totalConditions % values.colors.length],
    }

    values.conditions = values.conditions.concat({
      id,
      percent: 10,
    })

    setValues({ ...values })
  }

  const handleDeleteConnection = useCallback(
    (id) => {
      delete values.payload?.port[id]
      setValues({
        ...values,
        conditions: values.conditions.filter((cond) => cond.id != id),
      })
    },
    [values]
  )

  // useEffect(() => {
  //   let ps = new Scrollbar(comp.current);
  //   return () => {
  //     if (ps) {
  //       ps.destroy();
  //       ps = null;
  //     }
  //   };
  // }, []);

  return (
    <NodeMenu
      open
      node={node}
      anchorRef={anchorRef}
      values={values}
      onChange={handleChange}
      onRun={handleRun}
    >
      <Stack direction="row" alignItems="center" spacing={1}>
        <Stack sx={{
          backgroundColor: `#0C3249`,
          padding: 1,
          borderRadius: "10px 10px 10px 10px"
        }}
        >
          {renderIcons(data?.payload?.icon)}
        </Stack>
        <Stack spacing={0}>
          <Typography variant="h6"> {i18n.t('Randomizar')}</Typography>
          <Typography color="text.secondary" sx={{ mt: -0.7 }}>
            {i18n.t('Randomização de fluxo')}
          </Typography>

        </Stack>
      </Stack>
      <Divider sx={{ my: 1 }} />
      <div  style={{ maxHeight: 400, overflow: 'hidden', position: "relative" }} >
      <Stack spacing={1} sx={{
        maxHeight: 400,
        overflowY: 'scroll',
        overflowX: 'hidden',
      }}>


        {values.conditions.map((cond, index) => (
          <>

            <Grid container spacing={1}>
              <Grid item xs={9}>
                <Stack>
                  <Typography variant="caption" color="text.secondary">
                    {`Randomização ${index + 1}`}
                  </Typography>
                  <Slider
                      defaultValue={cond.percent}
                      valueLabelDisplay="auto"
                      onChange={(ev) => handleConditionChange({
                        target: {
                          name: "percent",
                          value: ev.target.value,
                        },
                      }, index)}
                      shiftStep={30}
                      step={5}
                      marks
                      min={10}
                      max={100}
                    />
                </Stack>
              </Grid>
             
                <Grid item xs>
                  <Stack>
             
                    <Input
              
                      name="percent"
                      size="large"
                      readOnly
                      addonAfter={
                        <Typography variant="caption" color="white" fontWeight="bold">
                          %
                        </Typography>
                      }
                      type="number"
                      required
                      value={cond.percent}
                      onChange={(ev) => handleConditionChange(ev, index)}
                      style={{ width: "100%", marginTop: 15}}
                      placeholder="Valor"
                    />
                  </Stack>
                </Grid>

       
              <Grid item justifyContent="center" alignItems="center" xs={1}>
                <Tooltip title={i18n.t('Remover')}>
                  <IconButton
                    size="small"
                    sx={{ marginTop: 2 }}
                    disabled={values.conditions.length === 1}
                    onClick={() => handleDeleteConnection(cond.id)}
                  >
                    <DeleteOutline />
                  </IconButton>

                </Tooltip>

              </Grid>
            </Grid>
            <Divider sx={{ my: 1 }} />
          </>
        ))}
      </Stack>
      </div>
      <Stack direction="row" justifyContent="center" mt={2}>
        <Button onClick={handleAddCondition} disabled={values.conditions.length === 50}>
          + Adicionar
        </Button>
      </Stack>
    </NodeMenu>
  )
}

export default SplitMenu
