import React from "react";
import { Select, Typography } from "antd";
import { i18n } from "../../translate/i18n";

const TicketsQueueSelect = ({
  userQueues,
  selectedQueueIds = [],
  onChange,
}) => {
  const handleChange = (values) => {
    onChange(values);
  };

  const filterOption = (input, option) =>
    option.children.props.children[1].props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  const tagRender = (props) => {
    const { label, closable, onClose } = props;
    return (
      <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '25px',
        padding: '4px 8px',
        backgroundColor: '#F0F0F0',
        borderRadius: '5px',
        margin: '1px'
      }}>
        {label}
        {closable && <span onClick={onClose} style={{ marginLeft: '8px', cursor: 'pointer' }}>×</span>}
      </div>
    );
  };

  return (
    <div style={{ padding: "4px 12px 8px 12px" }}>
      <Select
        mode="multiple"
        placeholder={i18n.t("Departamento")}
        id="queue"
        allowClear
        filterOption={filterOption}
        optionFilterProp="children"
        onChange={handleChange}
        value={selectedQueueIds}
        options={userQueues.map(queue => ({
          label: (
            <div style={{ display: 'flex', alignItems: 'center', marginRight: '4px' }}>
              <div style={{
                width: '15px',
                height: '15px',
                borderRadius: '50%',
                backgroundColor: queue.color,
                marginRight: '4px'
              }} />
              <Typography.Text style={{ fontWeight: 500, marginRight: '4px' }}>-</Typography.Text>
              <Typography.Text style={{ fontWeight: 500 }}>{queue.name}</Typography.Text>
            </div>
          ),
          value: queue.id
        }))}
        size="middle"
        style={{ width: '100%' }}
        maxTagCount="responsive"
        tagRender={tagRender}
      />
    </div>
  );
};

export default TicketsQueueSelect;
