import React, { useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";

import TicketsManager from "../../components/TicketsManagerTabs/";
import Ticket from "../../components/Ticket/";
import TickerTransfer from "../../components/TickerTransferDialog";
import { socketConnection } from "../../services/socket";
import { AuthContext } from "../../context/Auth/AuthContext";
import { i18n } from "../../translate/i18n";
import emptChat from "../../assets/openchat.png"
import { Stack, Typography } from "@mui/material";
const useStyles = makeStyles(theme => ({
  chatContainer: {
    flex: 1,
    // backgroundColor: "#eee",

    height: `calc(100% - 48px)`,
    overflowY: "hidden",
  },

  chatPapper: {
    // backgroundColor: "red",
    display: "flex",
    height: "100%",
  },

  contactsWrapper: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
    overflowY: "hidden",
  },
  messagesWrapper: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
  },
  welcomeMsg: {
    backgroundColor: "#eee",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    height: "100%",
    textAlign: "center",
    border: "none",
    margin: "10px",
    borderRadius: "12px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
  },
}));

const TicketsCustom = () => {
  const classes = useStyles();
  const { ticketId } = useParams();
  const { user: loggedInUser } = useContext(AuthContext);
  const [openTransferDialog, setOpenTransferDialog] = React.useState(false);
  const [ticketTransferInfos, setTicketTransferInfos] = React.useState({});
  const handleCloseTransferDialog = () => {
    setOpenTransferDialog(false);
  }

  // useEffect(() => {
  // 	const companyId = localStorage.getItem("companyId");
  // 	const socket = socketConnection({ companyId });
  // 	socket.on("connect", () => {
  // 		socket.emit("joinNotification");
  // 	});
  // 	socket.on(`company-${companyId}-ticket`, data => {
  // 		if (data.action === "updateTransfer" && data?.ticket?.user?.id === loggedInUser?.id) {
  // 			setTicketTransferInfos(data?.ticket);
  // 			setOpenTransferDialog(true);
  // 		}
  // 	});
  // }, [])



  return (
    <div className={classes.chatContainer}>
      <div className={classes.chatPapper}>
        <TickerTransfer
          open={openTransferDialog && ticketTransferInfos}
          onClose={handleCloseTransferDialog}
          infos={ticketTransferInfos}
        />
        <Grid container spacing={0}>
          <Grid item xs={4} className={classes.contactsWrapper}>
            <TicketsManager />
          </Grid>
          <Grid item xs={8} className={classes.messagesWrapper}>
            {ticketId ? (
              <>
                <Ticket />
              </>
            ) : (
              <Paper square variant="outlined" className={classes.welcomeMsg}>
                <Stack>
                  <img src={emptChat} alt="empty chat" width={250} style={{
                    marginLeft: 35,
                  }} />
                  <Typography variant="body2" sx={{ mt: 2 }}>
                    Selecione uma conversa para iniciar o antedimento
                  </Typography>
                </Stack>

              </Paper>
            )}
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default TicketsCustom;
