
import {
  Box,
  Checkbox,
  Divider,
  Grid,
  IconButton,
  ListItemText,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material"
import NodeMenu from "../../../StyleNodes/Menu"
import { DeleteOutline, ExpandMore, UploadFileOutlined } from "@mui/icons-material"
import React, { useCallback, useEffect, useRef, useState } from "react"
import { i18n } from "../../../../../../../translate/i18n";
import { Button, Input, Select } from "antd"
import { renderIcons } from "../../../../Icons"
import { daysOfWeeksArray } from "../utils"

const handleStyle = { left: 10 }

export const ScheduleMenu = ({ node, anchorRef }) => {
  const { data } = node
  const [anchorEl, setAnchorEl] = useState(null)
  const [values, setValues] = useState(data)
  const [openImport, setOpenImport] = useState(false)

  const handleRun = () => { }




  const handleScheduleSelectChange = (value) => {
    values.daysOfWeek = value
    setValues({ ...values })
  }


  const handleChange = ({ target }) => {
    values[target.name] = target.value
    setValues({ ...values })
  }


  return (
    <NodeMenu
      open
      node={node}
      anchorRef={anchorRef}
      values={values}
      onChange={handleChange}
      onRun={handleRun}
    >
      <Stack direction="row" alignItems="center" spacing={1}>
        <Stack sx={{
          backgroundColor: `#0C3249`,
          padding: 1,
          borderRadius: "10px 10px 10px 10px"
        }}
        >
          {renderIcons(data?.payload?.icon)}
        </Stack>
        <Stack spacing={0}>
          <Typography variant="h6"> {i18n.t('Tarefa')}</Typography>
          <Typography color="text.secondary" sx={{ mt: -0.7 }}>
            {i18n.t('Adicione uma tarefa automática')}
          </Typography>

        </Stack>
      </Stack>
      <Divider sx={{ my: 1 }} />
      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid item xs={12}>
          <Stack>
            <Typography variant="caption" color="text.secondary">
              {`Dias da semana`}
            </Typography>
            <Select
              mode="multiple"
              placeholder={i18n.t('Dias da semana')}
              onChange={handleScheduleSelectChange}
              value={values.daysOfWeek}
              options={daysOfWeeksArray}
              style={{ width: "100%" }}
              size="large"
            />
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack>
            <Typography variant="caption" color="text.secondary">
              {`Hora do tarefa`}
            </Typography>
            <Input
              name="scheduleAt"
              size="large"
              required
              type="time"
              value={values.scheduleAt}
              onChange={handleChange}
              style={{ width: "100%" }}
              placeholder="Hora do agendamento"
            />
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack>
            <Typography variant="caption" color="text.secondary">
              {`Em dias`}
            </Typography>
            <Input
              name="days"
              size="large"
              required
              type="number"
              value={values.days}
              onChange={handleChange}
              style={{ width: "100%" }}
              placeholder="Dias para execução"
            />
          </Stack>
        </Grid>
      </Grid>

    </NodeMenu>
  )
}

export default ScheduleMenu
