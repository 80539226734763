import React, { useEffect, useState } from "react";
import { Select, Typography } from "antd";
import toastError from "../../errors/toastError";
import api from "../../services/api";

export function TagsFilter({ onFiltered }) {
  const [tags, setTags] = useState([]);
  const [selecteds, setSelecteds] = useState([]);

  useEffect(() => {
    async function fetchData() {
      await loadTags();
    }
    fetchData();
  }, []);

  const loadTags = async () => {
    try {
      const { data } = await api.get(`/tags/list`);
      setTags(data);
    } catch (err) {
      toastError(err);
    }
  };

  const onChange = (value) => {
    const selectedTags = tags.filter(tag => value.includes(tag.id));
    setSelecteds(selectedTags);
    onFiltered(selectedTags);
  };

  const options = tags.map(tag => ({
    value: tag.id,
    label: (
      <div style={{ display: 'flex', alignItems: 'center', marginRight: '4px' }}>
        <div style={{
          width: '15px',
          height: '15px',
          borderRadius: '50%',
          backgroundColor: tag.color,
          marginRight: '4px'
        }} />
        <Typography.Text style={{ fontWeight: 500, marginRight: '4px' }}>-</Typography.Text>
        <Typography.Text style={{ fontWeight: 500 }}>{tag.name}</Typography.Text>
      </div>
    )
  }));

  const tagRender = (props) => {
    const { label, closable, onClose } = props;
    return (
      <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '25px',
        padding: '4px 8px',
        backgroundColor: '#F0F0F0',
        borderRadius: '5px',
        margin: '1px'
      }}>
        {label}
        {closable && <span onClick={onClose} style={{ marginLeft: '8px', cursor: 'pointer' }}>×</span>}
      </div>
    );
  };

  return (
    <div style={{ padding: "0px 12px 4px 12px" }}>
      <Select
        mode="multiple"
        placeholder="Filtro por Tags"
        value={selecteds.map(tag => tag.id)}
        onChange={onChange}
        options={options}
        size="middle"
        style={{ width: '100%' }}
        maxTagCount="responsive"
        tagRender={tagRender}
        filterOption={(input, option) =>
          option.label.props.children[2].props.children.toLowerCase().includes(input.toLowerCase())
        }
      />
    </div>
  );
}
