import { Button } from "antd";
import React, { useState } from "react";

import { Stack } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";

import MainContainer from "../../components/MainContainer";

import Groups from "./components/groups";
import Connections from "./components/connections";

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    backgroundColor: "#fff",
    overflowY: "hidden",
    borderRadius: "10px",
    ...theme.scrollbarStyles,
  },
}));

const MainConnections = () => {
  const classes = useStyles();
  const [tab, setTab] = useState(0);

  return (
    <MainContainer className={classes.mainContainer}>
      <Stack direction="row" spacing={1} sx={{ ml: 4, mb: -2 }}>
        <Button
          type={tab === 0 ? "primary" : "dashed"}
          onClick={() => setTab(0)}
          style={{
            borderRadius: "10px 10px 0px 0px",
            boxShadow: "none",
          }}
          size="large"
        >
          Conexões
        </Button>
        <Button
          type={tab === 1 ? "primary" : "dashed"}
          onClick={() => setTab(1)}
          style={{
            borderRadius: "10px 10px 0px 0px",
            boxShadow: "none",
          }}
          size="large"
        >
          Grupo de conexões
        </Button>
      </Stack>
      {tab === 0 && <Connections />}
      {tab === 1 && <Groups />}
    </MainContainer>
  );
};

export default MainConnections;
