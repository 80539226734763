import { Grid, IconButton, Input, Stack, Tooltip, Typography,Box } from "@mui/material"
import { useCallback, useContext, useEffect, useRef, useState } from "react"
import { Position } from "react-flow-renderer"
import { Container, Handle, } from "./styled"
import { CloseOutlined, Error, SaveOutlined,  Settings } from "@mui/icons-material"
import { BuilderContext } from "../.."
import { renderIcons } from "../../../Icons"


const Node = ({ node, children }) => {
  const { openMenu, validation, saveNode } = useContext(BuilderContext)
  const { selected, data, type } = node
  const [titleValue, setTitleValue] = useState(data.title)
  const [editTitle, setEditTitle] = useState(false)
  const clickRef = useRef(null)
  const showWarning = validation?.valid === false && validation.nodes[node.id]?.showWarning

  const handleOpenMenu = (e) => {
    if(node?.data?.payload?.dontChange) return
    openMenu(node)
  }

  const handleEditTitle = () => {
    setEditTitle(true)
  }

  const handleChangeTitle = (e) => {
    setTitleValue(e.target.value)
  }




  const handleStop = useCallback((save) => {
    setEditTitle(false)
    if (save) {
      saveNode({ ...node, data: { ...data, title: titleValue } })
    } else {
      setTitleValue(data.title)
    }
  }, [data, node, saveNode, titleValue])


  useEffect(() => {

    //on key press enter or esc
    const handleKeyPress = (event) => {
      if (event.key === 'Enter') {
        handleStop && handleStop(true);
      }
      if (event.key === 'Escape') {
        handleStop && handleStop(false);
      }
    }
    document.addEventListener('keydown', handleKeyPress);

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleStop]);


  return (
    <Container selected={selected} warning={showWarning} className="customNode" onDoubleClick={handleOpenMenu}>
      {data.payload?.hasInput && <Handle type="target" position={Position.Left} />}


      <Box px={1} alignItems="center" justifyContent="center" sx={{

        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
      }}>
        <Grid container spacing={2} px={1} pb={1} alignItems="center" justifyContent="center">
          <Grid item>
            <Stack sx={{
              height: 40,
              width: 40,
              borderRadius: 1,
              backgroundColor: data?.payload?.category?.color || "#E0E0E0",
            }}
              alignItems="center"
              justifyContent="center"
            >

              {renderIcons(data?.payload?.icon)}
            </Stack>
          </Grid>
          <Grid item xs >
            <Box ref={clickRef}>
              {!editTitle ? (
                <Stack onClick={handleEditTitle} sx={{ cursor: "text" }}>


                  <Typography variant="body1"  >
                    {titleValue}
                  </Typography>
                </Stack>
              ) : (
                <Stack direction="row" alignItems="center" >
                  <Input
                    autoFocus
                    sx={{
                      width: "100%",
                      maxWidth: 180,
                    }}
                    onChange={handleChangeTitle}
                    value={titleValue}
                  />
                  <Tooltip title="Salvar">
                    <IconButton onClick={() => handleStop(true)} >
                      <SaveOutlined />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Cancelar">
                    <IconButton onClick={() => handleStop(false)} >
                      <CloseOutlined />
                    </IconButton>
                  </Tooltip>

                </Stack>

              )}
            </Box>
          </Grid>
          {showWarning && (
            <Grid item mr={-2.5}>
              <IconButton onClick={() => { }} color="error">
                <Error color="error" />
              </IconButton>
            </Grid>
          )}
          {!node?.data?.payload?.dontChange && (


            <Grid item >
              <IconButton onClick={handleOpenMenu} >
                <Settings />
              </IconButton>
            </Grid>
          )}
        </Grid>
      </Box>

      <Stack sx={{
        p:2,
      }}>
        <Stack sx={{
          backgroundColor: "#f2f2f2",
          borderRadius: 2,
        }} >
        {children}
        </Stack>
    
      </Stack>


    </Container >
  )
}

export default Node
