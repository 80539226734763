import { Divider, Grid, IconButton, Paper, Typography } from "@mui/material"
import { Box } from "@mui/system"
import { Position, NodeProps } from "react-flow-renderer"
import { Container, HandleContainer, Handle, Tag } from "./styled"
import Node from "../../StyleNodes/Node"

export { TagTicketMenu } from "./menu"

const handleStyle = { left: 10 }

const TagTicketNode = (node) => {
  const { data } = node


  return (

    <Node node={node}>
    <Grid
     container
     style={{ position: "relative" }}
     spacing={1}
     px={2}
     mt={0}
     alignItems="center"
   >
     <Grid item sx={{ mb: 1 }}>
       <Typography variant="caption" color="gray">
          Adicionar tag
       </Typography>
     </Grid>
     <Grid item>
     <HandleContainer>
        <Handle
          type="source"
          position={Position.Right}
          id="next"
          color={data.payload?.port.next.color}
        />
      </HandleContainer>
     </Grid>
   </Grid>
  

 </Node >
  )
}

export default TagTicketNode
