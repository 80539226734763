export const JumpData = {
  hasInput: true,
  flow: {
    id: 1,
    name: 'Flow 1',
  },
  returnOptions: false,
  data: {},
  type: 'jump',
  title: 'Jump',
  label: 'Jump node',
}

export default JumpData
