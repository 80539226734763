import React from "react";
import { Modal, Typography } from "antd";

const ConfirmationModal = ({ title, children, open, onClose, onConfirm }) => {
  const renderTitle = () => {
    return (
      <div style={{ flexDirection: "column", marginTop: -32 }}>
        <Typography.Title level={4}>
          {title}
        </Typography.Title>
        <Typography.Text
          style={{
            fontWeight: "bold",
          }}
        >
          {children === undefined
            ? "Essa ação não poderá ser desfeita."
            : children}
        </Typography.Text>
      </div>
    );
  };

  return (
    <Modal
      visible={open}
      onCancel={() => onClose(false)}
      title={renderTitle()}
      onOk={() => {
        onClose(false);
        onConfirm();
      }}
    />
  );
};

export default ConfirmationModal;
