import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Modal } from "antd";
import { Divider, Stack, Typography, Grid } from '@mui/material';


const AttachmentModal = ({ title, open, onClose, imgUrl }) => {

	const renderTitle = () => {
		return <Stack   >
		  <Typography variant="h6" sx={{ ml: 1 }}>
		 	 {title}
		  </Typography>
		  <Typography variant="caption" sx={{ ml: 1 }}>
			 Veja os anexos da mensagem.
		  </Typography>
		</Stack>
	  }
	const returnFileTypeFromUrlExt = (url) => {
		if (!url) return;
		const ext = url?.split('.').pop();
		return ext;
	}
	return (
		<Modal
			open={open}
			title={renderTitle()}
			onCancel={() => onClose(false)}
			footer={null}
		>
			<DialogContent dividers>
				{returnFileTypeFromUrlExt(imgUrl) === 'pdf' ? ( 
					<embed src={imgUrl} width="1080px" height="720px" />
				) : (
					<img src={imgUrl} alt="attachment" width="100%" height="100%" />
				)}
			
			</DialogContent>
		</Modal>
	);
};

export default AttachmentModal;
