import { Chip, Divider, Grid, IconButton, Paper, Stack, Typography } from "@mui/material"
import { Box } from "@mui/system"
import { Position, NodeProps } from "react-flow-renderer"
import { Container, HandleContainer, Handle, Tag } from "./styled"
import Node from "../../StyleNodes/Node"
import { availableConditions } from "./data"
export { ConditionalMenu } from "./menu"
export { ConditionalData } from "./data"
const handleStyle = { left: 10 }

const ConditionalNode = (node) => {
  const { data } = node

  return (
    <Node node={node} >
      {data.conditions.map((it, index) => (
        <Grid
          container
          style={{ position: "relative" }}
          spacing={1}
          mt={0}
          px={2}
          alignItems="center"
        >
          <Grid item sx={{ mb: 1 }}>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Typography variant="caption" color="gray">
                Condição - {index + 1}
              </Typography>
              {it.left && (
                <Chip sx={{
                  backgroundColor: `${data.colors[index]}`,
                  ml:1
                }} size="small" label={<Stack direction="row">
                  <Typography variant="caption" color="white">
                    {it.left} {availableConditions.find((item) => item.value === it.comparator)?.label} {it.right}
                  </Typography>
                </Stack>} />
              )}

            </Stack>
          </Grid>
        
          <Grid item>
            <HandleContainer>
              <Handle
                type="source"
                position={Position.Right}
                id={it.id}
                color={node.data.payload?.port[it.id].color}
              />
            </HandleContainer>
          </Grid>
        </Grid>
      ))}
    <Divider />
      <Grid
        container
        style={{ position: "relative" }}
        spacing={1}
        px={2}
        mt={0}
        alignItems="center"
      >
        <Grid item sx={{ mb: 1 }}>
          <Typography variant="caption" color="gray">
            Caso contrário
          </Typography>
        </Grid>
        <Grid item>
          <HandleContainer>
            <Handle
              type="source"
              position={Position.Right}
              id="else"
              color={node.data.payload?.port.else.color}
            />
          </HandleContainer>
        </Grid>
      </Grid>
    </Node>
  )
}

export default ConditionalNode
