import React, { useEffect, useState } from "react";
import { Modal, Select, Typography, Button } from "antd";
import toastError from "../../errors/toastError";
import api from "../../services/api";

const TicketQueueModal = ({ open, onClose, onSelectQueue, user}) => {
  const [queues, setQueues] = useState([]);
  const [selectedQueue, setSelectedQueue] = useState(null);

  useEffect(() => {
    const mapQueues = async () => {
      try {
        const data = user.queues;
        const queuesData = data.map((item) => ({
          value: item.id,
          label: (
            <div style={{ display: 'flex', alignItems: 'center', marginRight: '4px' }}>
              <div style={{
                width: '15px',
                height: '15px',
                borderRadius: '50%',
                backgroundColor: item.color,
                marginRight: '4px'
              }} />
              <Typography.Text style={{ fontWeight: 500, marginRight: '4px' }}>-</Typography.Text>
              <Typography.Text style={{ fontWeight: 500 }}>{item.name}</Typography.Text>
            </div>
          ),
        }));
        setQueues(queuesData);
      } catch (err) {
        toastError(err);
      }
    };

    mapQueues();
  }, []);

  const handleQueueChange = (value) => {
    setSelectedQueue(value);
  };

  const handleOk = () => {
    if (selectedQueue) {
      onSelectQueue(selectedQueue);
      onClose();
    }
  };

  const filterOption = (input, option) => {
    return option.label.props.children[2].props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  };

  return (
    <Modal
      title="Selecionar Departamento"
      open={open}
      onCancel={onClose}
      footer={[
        <Button key="back" onClick={onClose}>
          Cancelar
        </Button>,
        <Button key="submit" type="primary" onClick={handleOk} disabled={!selectedQueue}>
          Selecionar
        </Button>,
      ]}
    >
      <Select
        placeholder="Selecione um departamento"
        id="queue"
        filterOption={filterOption}
        optionFilterProp="children"
        onChange={handleQueueChange}
        value={selectedQueue}
        options={queues}
        size="large"
        style={{ width: '100%' }}
        allowClear
      />
    </Modal>
  );
};

export default TicketQueueModal;
