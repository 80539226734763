import React, { useState, useEffect, useReducer } from "react";
import { toast } from "react-toastify";

import { useHistory } from "react-router-dom";
import { TBody, THead, Table, TCell, TRow } from "../Table";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";

import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditIcon from "@material-ui/icons/Edit";
import PeopleIcon from "@material-ui/icons/People";
import { Stack, Typography, Grid, Tooltip } from "@mui/material";


import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import TRowSkeleton from "../TableRowSkeleton";
import ContactListDialog from "../ContactListDialog";
import ContactListItemsDialog from "../ImportContactsModal"

import ConfirmationModal from "../ConfirmationModal";
import toastError from "../../errors/toastError";

import { socketConnection } from "../../services/socket";
import { Modal, Button } from "antd";

const reducer = (state, action) => {
  if (action.type === "LOAD_CONTACTLISTS") {
    const contactLists = action.payload;
    const newContactLists = [];

    contactLists.forEach((contactList) => {
      const contactListIndex = state.findIndex((u) => u.id === contactList.id);
      if (contactListIndex !== -1) {
        state[contactListIndex] = contactList;
      } else {
        newContactLists.push(contactList);
      }
    });

    return [...state, ...newContactLists];
  }

  if (action.type === "UPDATE_CONTACTLIST") {
    const contactList = action.payload;
    const contactListIndex = state.findIndex((u) => u.id === contactList.id);

    if (contactListIndex !== -1) {
      state[contactListIndex] = contactList;
      return [...state];
    } else {
      return [contactList, ...state];
    }
  }

  if (action.type === "DELETE_CONTACTLIST") {
    const contactListId = action.payload;

    const contactListIndex = state.findIndex((u) => u.id === contactListId);
    if (contactListIndex !== -1) {
      state.splice(contactListIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    borderRadius: "10px",
    boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
    ...theme.scrollbarStyles,
  },
}));

const ContactListModal = ({
  open,
  onClose,
  onSave
}) => {
  const classes = useStyles();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [selectedContactList, setSelectedContactList] = useState(null);
  const [deletingContactList, setDeletingContactList] = useState(null);
  const [contactListModalOpen, setContactListModalOpen] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [searchParam, setSearchParam] = useState("");
  const [contactLists, dispatch] = useReducer(reducer, []);
  const [importContacts, setImportContacts] = useState(false);
  const [contactListItemsModalOpen, setContactListItemsModalOpen] = useState(false);
  const [newContactList, setNewContactList] = useState(null);

  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [searchParam]);

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchContactLists = async () => {
        try {
           api.get("/contact-lists/", {
            params: { searchParam, pageNumber },
          }).then(({data}) => {
            dispatch({ type: "LOAD_CONTACTLISTS", payload: data.records });
            setHasMore(data.hasMore);
            setLoading(false);
          }).catch((err) => {
            toastError(err);

          })
      
        } catch (err) {
          toastError(err);
        }
      };
      fetchContactLists();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchParam, pageNumber]);

  useEffect(() => {
    const companyId = localStorage.getItem("companyId");
    const socket = socketConnection({ companyId });

    socket.on(`company-${companyId}-ContactList`, (data) => {
      if (data.action === "update" || data.action === "create") {
        dispatch({ type: "UPDATE_CONTACTLIST", payload: data.record });
      }

      if (data.action === "delete") {
        dispatch({ type: "DELETE_CONTACTLIST", payload: +data.id });
      }
    });


    return () => {
      socket.disconnect();
    };
  }, []);

  const handleOpenContactListModal = () => {
    setSelectedContactList(null);
    setContactListModalOpen(true);
  };

  const handleCloseContactListModal = () => {
    setSelectedContactList(null);
    setContactListModalOpen(false);
  };

  const handleSearch = (event) => {
    setSearchParam(event.target.value.toLowerCase());
  };

  const handleEditContactList = (contactList) => {
    setSelectedContactList(contactList);
    setContactListModalOpen(true);
  };

  const handleDeleteContactList = async (contactListId) => {
    try {
      await api.delete(`/contact-lists/${contactListId}`);
      toast.success(i18n.t("contactLists.toasts.deleted"));
    } catch (err) {
      toastError(err);
    }
    setDeletingContactList(null);
    setSearchParam("");
    setPageNumber(1);
  };

  const loadMore = () => {
    setPageNumber((prevState) => prevState + 1);
  };

  const handleClose = () => {
    onClose();
  };

  const handleScroll = (e) => {
    if (!hasMore || loading) return;
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      loadMore();
    }
  };

  const goToContacts = (id) => {
    setNewContactList(id);
    setContactListItemsModalOpen(true);
  };



  const renderTitle = () => {
    return <Stack   >
      <Stack direction="row" justifyContent={"space-between"} alignItems="center">
      <Typography variant="h6" sx={{ ml: 1 }}>
         Lista de contatos
      </Typography>
      <Button
        type="primary"
        onClick={handleOpenContactListModal}
        style={{
          marginRight: "30px"
        }}
      >
        Nova lista
      </Button>
      </Stack>
      <Typography variant="caption" sx={{ ml: 1 }}>
          Crie ou edite suas listas de contatos
      </Typography>
    </Stack>
  }
  const handleCloseImportContacts = async() => {
    const { data: dataList } = await api.get("/contact-lists/", {
      params: { searchParam, pageNumber },
    });
    dispatch({ type: "LOAD_CONTACTLISTS", payload: dataList.records });
    setNewContactList(false)
    setContactListItemsModalOpen(false)
  }

  return (
    <Modal
      title={renderTitle()}
      open={open}
      onCancel={handleClose}
      onOk={handleClose}
      width={800}
    >
      {importContacts ? (
        <Typography variant="h6" sx={{ ml: 1 }}>
          Importar Contatos
        </Typography>
      ):(

        <>
      <ContactListItemsDialog
        open={contactListItemsModalOpen}
        onClose={handleCloseImportContacts}
        onSave={console.log}
        contactListId={newContactList}
      />
      <ConfirmationModal
        title={
          deletingContactList &&
          `${i18n.t("contactLists.confirmationModal.deleteTitle")} ${
            deletingContactList?.name
          }?`
        }
        open={confirmModalOpen}
        onClose={setConfirmModalOpen}
        onConfirm={() => handleDeleteContactList(deletingContactList.id)}
      >
        {i18n.t("contactLists.confirmationModal.deleteMessage")}
      </ConfirmationModal>
      <ContactListDialog
        open={contactListModalOpen}
        onClose={handleCloseContactListModal}
        aria-labelledby="form-dialog-title"
        contactListId={selectedContactList && selectedContactList.id}
      />
      <Paper
       style={{
        boxShadow: "none",
        backgroundColor: "#fff",
        border: "none",
        minHeight: "400px",
      }} 
        className={classes.mainPaper}
        variant="outlined"
        onScroll={handleScroll}
      >
        <Table size="small">
          <THead>
            <TRow>
              <TCell align="center" color="white">
                {i18n.t("contactLists.table.name")}
              </TCell>
              <TCell align="center" color="white">
                {i18n.t("contactLists.table.contacts")}
              </TCell>
              <TCell align="center" color="white">
                {i18n.t("contactLists.table.actions")}
              </TCell>
            </TRow>
          </THead>
          <TBody>
            <>
              {contactLists.map((contactList) => (
                <TRow key={contactList.id}>
                  <TCell align="center">{contactList?.name}</TCell>
                  <TCell align="center">
                    {contactList.contactsCount || 0}
                  </TCell>
                  <TCell align="center">
             

                    <IconButton
                      size="small"
                      onClick={() => goToContacts(contactList.id)}
                    >
                      <PeopleIcon />
                    </IconButton>

                    <IconButton
                      size="small"
                      onClick={() => handleEditContactList(contactList)}
                    >
                      <EditIcon />
                    </IconButton>

                    <IconButton
                      size="small"
                      onClick={(e) => {
                        setConfirmModalOpen(true);
                        setDeletingContactList(contactList);
                      }}
                    >
                      <DeleteOutlineIcon />
                    </IconButton>
                  </TCell>
                </TRow>
              ))}
              {loading && <TRowSkeleton columns={3} />}
            </>
          </TBody>
        </Table>
      </Paper>
      </>
      )}
    </Modal>
  );
};

export default ContactListModal;
