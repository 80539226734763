import zxcvbn from 'zxcvbn'

export const getPasswordStrength = (pass) => {
  if (!pass) return 0
  return zxcvbn(pass).score
}

export const isValidPasswordStrength = (
  pass,
  minStrength = 3,
) => getPasswordStrength(pass) >= minStrength
