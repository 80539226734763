import styled, { css } from 'styled-components'
import { Handle as Port } from 'react-flow-renderer'

export const Container = styled.div.attrs((props) => props)`
  min-width: 300px;
  min-height: 70px;
  max-width: 400px;
  background: #fafafaEE;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  border-radius: 10px;
  position: relative;
  ${({ selected }) =>
    selected &&
    css`
      outline: 1px solid rgba(0, 89, 220, 0.8);
      box-shadow: rgba(0, 89, 220, 0.8) 0px 0px 0px 1px, rgba(0, 89, 220, 0.16) 0px 14px 22px;
    `}

  ${({ warning }) =>
    warning &&
    css`
    box-shadow: rgb(255 0 0 / 40%) 0px 4px 12px, rgba(93, 93, 26, 0.1) 0px 8px 22px, rgb(172 0 0 / 33%) 0px 12px 40px;
    `}

    word-break: break-all;
`

export const Avatar = styled.div`
  width: 50px;
  height: 50px;
  padding: 10px;
  background: ${(props) => props.color}22;
  border-radius: 5px;
`

export const Tag = styled.div`
  background: #5fd07e66;
  border-radius: 5px;
  padding: 5px 20px;
  color: #5fd07e;
  display: inline;
`

export const HandleContainer = styled.div`
  position: absolute;
  top: calc(50% - 12px);
  right: -6px;
`

export const Handle = styled(Port)`
  && {
    background: #fafafa;
    outline: 4px solid ${(props) => props.color}77;
    transform: translate(0);
    width: 12px;
    height: 12px;
    top: 6px;
    position: relative;
    &:hover {
      transform: scale(1.5);
    }
  }
`
