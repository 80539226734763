import {
    Typography,
    Stack,
    Divider,
    Button,
    Box,
    Grid,
    IconButton,
    Avatar
  } from "@mui/material";

import FacebookIcon from "../../../../assets/img/channels/facebook.svg";
import WhatsAppIcon from "../../../../assets/img/channels/whatsapp.svg";
import TelegramIcon from "../../../../assets/img/channels/telegram.svg";
import InstagramIcon from "../../../../assets/img/channels/instagram.svg";
import Gmail from "../../../../assets/img/channels/gmail.png";
import Outlook from "../../../../assets/img/channels/owa.png";
import { Bolt, Check, WhatsApp } from "@mui/icons-material";


export default function FreePlan({
  onSelect
}){
    return (
        <Stack
        sx={{
          backgroundColor: "#FFFFFF",
          borderRadius: "10px",
          padding: 3,
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.05)",
          width: "300px",
          height: "580px"
        }}
        justifyContent="space-between"
        spacing={1}
      >
        <Stack>

   
        <Stack direction="row" spacing={2} alignItems="center">
          <Stack
            sx={{
              borderRadius: "50%",
              backgroundColor: "black",
              height: "50px",
              width: "50px"
            }}
            justifyContent="center"
            alignItems="center"
          >
            <Bolt
              sx={{
                color: "#FFFFFF",
                fontSize: "40px"
              }}
            />
          </Stack>
          <Typography variant="h5" color="text.secondary" fontWeight="bold">
            FREE
          </Typography>
        </Stack>
        <Stack direction="row" textAlign="inherit" alignItems="end">
        <Typography variant="h4" sx={{mt:2}}>R$ 0,00</Typography>
        <Typography variant="body2" sx={{mt:2}}>/ 3 dias de teste</Typography>

        </Stack>
        <Divider sx={{ my: 2 }} />
        <Typography variant="body1">FEATURES</Typography>
        <Stack direction="row" spacing={1}>
          <Check sx={{ color: "#0C3249" }} />
          <Typography variant="body1">TakeFlow Chat</Typography>
        </Stack>
        <Stack direction="row" spacing={1}>
          <Check sx={{ color: "#0C3249" }} />
          <Typography variant="body1">Mensagens ilimitadas</Typography>
        </Stack>
        <Stack direction="row" spacing={1}>
          <Check sx={{ color: "#0C3249" }} />
          <Typography variant="body1">1 Usuários</Typography>
        </Stack>
        <Stack direction="row" spacing={1}>
          <Check sx={{ color: "#0C3249" }} />
          <Typography variant="body1">1 Conexão</Typography>
        </Stack>
        <Stack direction="row" spacing={1}>
          <Check sx={{ color: "#0C3249" }} />
          <Typography variant="body1">3 Campanhas</Typography>
        </Stack>

        <Divider sx={{ mt: 2, mb: 3 }} />

        <Avatar
          src={WhatsAppIcon}
          sx={{
         width: 40,
              height: 40,
            objectFit: "fill",
            background: "background.default",
            border: 0,
            p: 1
          }}
          imgProps={{
            style: {
              objectFit: "fill"
            }
          }}
        />
     </Stack>
        <Stack
          onClick={onSelect}
          sx={{
            mt: 2,
            cursor: "pointer",
            backgroundColor: "black",
            color: "#FFFFFF",
            height: "40px",
            width: "100%",
            borderRadius: "10px"
          }}
          justifyContent="center"
          alignItems="center"
        >
          <Typography variant="body1" color="white" fontWeight="bold" >
            INICIAR TESTE
          </Typography>
        </Stack>
      </Stack>
    )
}