import React, { useState, useContext } from "react";
import { Stack, Box, Grid, Typography } from "@mui/material";
import { Input } from "antd";
import { AuthContext } from "../../context/Auth/AuthContext";
import { ReactComponent as LoginNovo } from "../../assets/bg_login.svg";
import InputPassword from "../../components/InputPassword";
import { Link } from "react-router-dom";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import { LoginButton } from "./styled";
import "./login.css";
import StepButton from "@mui/material/StepButton";
import Button from "@mui/material/Button";
import RegistrationData from "./steps/Data";
import RegistrationPlan from "./steps/Plans";
import { Divider } from "@mui/material";
import FinalizationStep from "./steps/Finalization";
import EmailConfirmation from "./steps/EmailConfirmation";

const steps = ["Dados de cadastro","Confirmação de e-mail", "Seleção do plano", "Finalização"];

export default function SignInSide() {
  const [user, setUser] = useState({ email: "", password: "" });
  const [values, setValues] = useState({
    email: "",
    password: "",
    phone: "",
    confirmPassword: ""
  });
  const { handleLogin } = useContext(AuthContext);
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});
  const [confirmPassword, setConfirmPassword] = useState("unvalid");
  const [plan, setPlan] = useState(null);

  const [company, setCompany] = useState(null);
  const handleChangeInput = e => {
    setUser({ ...user, [e.target?.name]: e.target.value });
  };

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleSubmit = e => {
    e.preventDefault();
    handleLogin(user);
  };

  const handleChange = e => {
    values[e.target.name] = e.target.value;
    setValues({...values});
    if(e.target.name === "password"){
      if(e.target.value.length < 6){
        setConfirmPassword("short")
      }
    }
    if(values.password && e.target.name === "confirmPassword") {
      if(e.target.value === values.password){
        setConfirmPassword("checked")
      }else{
        setConfirmPassword("invalid")
      }
    }
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleStep = step => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };


  const getStepContent = step => {
    switch (step) {
      case 0:
        return (
          <RegistrationData 
            handleChange={handleChange}
            values={values}
            confirmPassword={confirmPassword}
            activeStep={activeStep}
            handleNext={handleNext}
            handleBack={handleBack}
            setCompany={setCompany}
          />
        )
        case 1:
          return (
            <EmailConfirmation 
              handleChange={handleChange}
              values={values}
              activeStep={activeStep}
              handleBack={handleBack}
              handleNext={handleNext}
            />
          )
      case 2:
        return (
          <RegistrationPlan 
            handleChange={handleChange}
            values={values}
            handleNext={handleNext}
            handleBack={handleBack}
            activeStep={activeStep}
            company={company}
            plan={plan}
            setPlan={setPlan}
          />
        )
      case 3:
        return (
          <FinalizationStep 
            plan={plan}
            company={company}
            values={values}
          />
        )
    }
  }

  return (
    <>
      <Grid container wrap="nowrap" className="fullHeight" overflow="hidden">
        <Grid
          item
          xs={4}
          sx={{
            backgroundColor: "#0f3058",
            height: "100%",
            borderRadius: "0px 50px 50px 0px",
            display: "block",
            position: "relative",
            zIndex: "0"
          }}
        >
          <LoginNovo
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-30%, -50%)",
              zIndex: "1",
              display: "block",
              maxWidth: "130%",
              maxHeight: "130%",
              width: "auto",
              height: "auto",
              minWidth: "130%",
              minHeight: "130%"
            }}
          />
        </Grid>
        <Grid item xs={8} className="fullHeight overflow-y">
          <Grid
            container
            className="fullHeight"
            alignItems="center"
            justifyContent="center"
          >
            <Grid item p={1} xs={9}>
              <Box
                component="form"
                noValidate
                onSubmit={handleSubmit}
                sx={{ mt: 1 }}
              >
                <Box sx={{ width: "100%" }}>
                  <Stepper nonLinear activeStep={activeStep}>
                    {steps.map((label, index) => (
                      <Step key={label} completed={completed[index]} >
                        <StepButton color="inherit" >
                          {label}
                        </StepButton>
                      </Step>
                    ))}
                  </Stepper>
                  <div>
                
   
                      <React.Fragment>
                        <Typography sx={{ mt: 2, mb: 1, py: 1 }}>
                          {getStepContent(activeStep)}
                        </Typography>
    
                      </React.Fragment>
                    
                  </div>
                </Box>

              
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}