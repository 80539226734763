export const readFile = async (file)=> {
  return new Promise((resolve, reject) => {
    const fr = new FileReader()
    fr.readAsText(file, 'UTF-8')
    fr.onload = async () => {
      const json = JSON.parse(fr.result)
      if (fr.result) {
        resolve(json)
      } else {
        reject()
      }
    }
  })
}
