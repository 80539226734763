import React, { useEffect, useState, useContext } from "react";
import { BrowserRouter, Switch, useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import LoggedInLayout from "../layout";
import Dashboard from "../pages/Dashboard/";
import TicketResponsiveContainer from "../pages/TicketResponsiveContainer";
import Signup from "../pages/Signup/";
import Login from "../pages/Login/";
import Connections from "../pages/Connections/";
import AccountInfos from "../pages/AccountInfos";
import SettingsCustom from "../pages/SettingsCustom/";
import Financeiro from "../pages/Financeiro/";
import Users from "../pages/Users";
import Contacts from "../pages/Contacts/";
import Queues from "../pages/Queues/";
import Tags from "../pages/Tags/";
import MessagesAPI from "../pages/MessagesAPI/";
import Helps from "../pages/Helps/";
import Accounts from "../pages/Accounts";
import Flow from "../pages/Flow";
import Logs from "../pages/Logs";
import Board from "../pages/BoardPage";
import BoardControl from "../pages/Board";

// import Companies from "../pages/Companies/";
import QuickMessages from "../pages/QuickMessages/";
import { AuthProvider, AuthContext } from "../context/Auth/AuthContext";
import { TicketsContextProvider } from "../context/Tickets/TicketsContext";
import { WhatsAppsProvider } from "../context/WhatsApp/WhatsAppsContext";
import Route from "./Route";
import Schedules from "../pages/Schedules";
import Campaigns from "../pages/Campaigns";
import CampaignsNew from "../pages/CampaignsNew";
import Plans from "../pages/Plans";

import CampaignsConfig from "../pages/CampaignsConfig";
import CampaignReport from "../pages/CampaignReport";
import Annoucements from "../pages/Annoucements";
import Chat from "../pages/Chat";
import Subscription from "../pages/Subscription/";
import ScheduleMessages from "../pages/ScheduleMessages";
import ChatBot from "../pages/ChatBot";
import Diagram from "../pages/Flow/Diagram";
import FlowLayout from "../layout/FlowLayout";
import RecoveryPassword from "../pages/Recovery";
import Invite from "../pages/Invite";

const Routes = () => {
  const [showCampaigns, setShowCampaigns] = useState(false);

  useEffect(() => {
    const cshow = localStorage.getItem("cshow");
    if (cshow !== undefined) {
      setShowCampaigns(true);
    }
  }, []);

  return (
    <BrowserRouter>
      <AuthProvider>
        <TicketsContextProvider>
          <Switch>
            <Route exact path="/login" component={Login} />
            <Route exact path="/auth/recovery" component={RecoveryPassword} />
            <Route exact path="/auth/invite" component={Invite} />

            <Route exact path="/signup" component={Signup} />
            {/* <Route exact path="/create-company" component={Companies} /> */}
            <WhatsAppsProvider>
              <LoggedInLayout>
                <Route exact path="/" component={Dashboard} isPrivate />

                <Route
                  exact
                  path="/board"
                  component={Board}
                  isPrivate
                  isSuper
                />

                <Route
                  exact
                  path="/tickets/:ticketId?"
                  component={TicketResponsiveContainer}
                  isPrivate
                />

                <Route
                  exact
                  path="/boardcontrol/:boardId?"
                  component={BoardControl}
                  isPrivate
                  isSuper
                />

                <Route
                  exact
                  path="/connections"
                  component={Connections}
                  isPrivate
                  isAdmin
                />

                <Route
                  exact
                  path="/account"
                  component={AccountInfos}
                  isPrivate
                  isAdmin
                />

                <Route
                  exact
                  path="/quick-messages"
                  component={QuickMessages}
                  isPrivate
                />

                <Route
                  exact
                  path="/schedule-messages"
                  component={ScheduleMessages}
                  isPrivate
                />

                <Route
                  exact
                  path="/schedules"
                  component={Schedules}
                  isPrivate
                />

                <Route exact path="/tags" component={Tags} isPrivate />

                <Route exact path="/contacts" component={Contacts} isPrivate />

                {/* <Route exact path="/helps" component={Helps} isPrivate /> */}

                <Route
                  exact
                  path="/users"
                  component={Users}
                  isPrivate
                  isAdmin
                />

                {/* 
                <Route
                  exact
                  path="/messages-api"
                  component={MessagesAPI}
                  isPrivate
                  isAdmin
                /> */}

                <Route
                  exact
                  path="/campaignsnew"
                  component={CampaignsNew}
                  isPrivate
                />

                <Route
                  exact
                  path="/settings"
                  component={SettingsCustom}
                  isPrivate
                  isAdmin
                />

                <Route exact path="/flows" component={Flow} isPrivate isAdmin />

                <Route
                  exact
                  path="/flow/:flowId?"
                  component={Diagram}
                  flowLayout={true}
                  isPrivate
                  isAdmin
                />

                <Route exact path="/logs" component={Logs} isPrivate isAdmin />

                <Route
                  exact
                  path="/financeiro"
                  component={Financeiro}
                  isPrivate
                  isAdmin
                />

                <Route
                  exact
                  path="/queues"
                  component={Queues}
                  isPrivate
                  isAdmin
                />

                {/* <Route
                  exact
                  path="/chatbot"
                  component={ChatBot}
                  isPrivate
                  isAdmin
                /> */}

                {/* <Route
                  exact
                  path="/announcements"
                  component={Annoucements}
                  isPrivate
                  isSuper
                /> */}

                <Route
                  exact
                  path="/accounts"
                  component={Accounts}
                  isPrivate
                  isSuper
                />

                {/* <Route
                  exact
                  path="/subscription"
                  component={Subscription}
                  isPrivate
                  isAdmin
                /> */}

                <Route
                  exact
                  path="/plans"
                  component={Plans}
                  isPrivate
                  isSuper
                />

                {/* <Route exact path="/chats/:id?" component={Chat} isPrivate /> */}

                <Route
                  exact
                  path="/broadcasts"
                  component={CampaignsNew}
                  isPrivate
                  isAdmin
                />

                {/* <Route
                  exact
                  path="/campaigns"
                  component={Campaigns}
                  isPrivate
                  isAdmin
                /> */}

                {/* <Route
                  exact
                  path="/campaign/:campaignId/report"
                  component={CampaignReport}
                  isPrivate
                  isAdmin
                /> */}

                {/* <Route
                  exact
                  path="/campaigns-config"
                  component={CampaignsConfig}
                  isPrivate
                  isAdmin
                /> */}
              </LoggedInLayout>
            </WhatsAppsProvider>
          </Switch>
          <ToastContainer autoClose={3000} />
        </TicketsContextProvider>
      </AuthProvider>
    </BrowserRouter>
  );
};

export default Routes;
