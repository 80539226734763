import React, { useEffect, useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Chip from "@material-ui/core/Chip";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import { Select } from "antd";
import { AuthContext } from "../../context/Auth/AuthContext";

import { Stack, Box, Typography, Avatar } from "@mui/material"
const useStyles = makeStyles(theme => ({
	chips: {
		display: "flex",
		flexWrap: "wrap",
	},
	chip: {
		margin: 2,
	},
}));

const ContactSelect = ({ selectedContactsId, onChange }) => {
	const classes = useStyles();
	const [contacts, setContacts] = useState([]);
	const { user } = useContext(AuthContext);
	const { companyId } = user;

	useEffect(() => {
		(async () => {
			try {
				const { data } = await api.get("/contacts/list", {
					params: { companyId: companyId }
				});
				const contactsData = data.map((item) => {
					return {
						value: item.id,
						label: <Stack direction="row" spacing={1} alignItems="center" mt={0.5} mr={1}>
							<Avatar
								alt={item.name}
								src={item.profilePicUrl}
								sx={{ width: 24, height: 24 }}
							/>


							<Typography variant="body2" sx={{ fontWeight: 500 }}>
								-
							</Typography>
							<Typography variant="body2" sx={{ fontWeight: 500 }}>
								{item?.name}
							</Typography>
						</Stack>,
					}
				})
				setContacts(contactsData);
			} catch (err) {
				toastError(err);
			}
		})();
	}, []);

	const handleChange = e => {
		onChange(e.target.value);
	};

	const filterOption = (input, option) => {
		//filter by tag name
		return option.label.props.children[2].props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
	}

	return (
		<Select
			showSearch
			placeholder={"Selecione um contato"}
			id="contacts"
			filterOption={filterOption}
			optionFilterProp="children"
			onChange={onChange}
			value={selectedContactsId}
			options={contacts}
			size="large"
			style={{ width: '100%' }}
		/>
	);
};

export default ContactSelect;
