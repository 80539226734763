import React, { useMemo ,forwardRef} from "react";
import { Box, FormHelperText, Stack, Typography } from "@mui/material";
import Dropzone from 'react-dropzone'

import fileFormatIcons from './Formats'

const DefaultDropzone = forwardRef(function (
  { children, label, error, typeIcon, loading, filename, format, ...rest },
  ref
) {

    const IconFormat = useMemo(() => {
        return fileFormatIcons[typeIcon || 'json']
      }, [typeIcon])
  return (
    <Stack>
      <Dropzone ref={ref} {...rest}>
        {({ getRootProps, getInputProps }) => (
          <Stack>
            {!!label && (
              <Typography color="primary" variant="caption">
                {label}
              </Typography>
            )}
            <Box
              {...getRootProps()}
              sx={{
                cursor: loading ? "not-allowed" : "pointer",
                border: error ? "2px dashed" : "2px dashed",
                borderColor: error ? "error.main" : "divider",
                px: 2,
                py: 4,
                borderRadius: 4
              }}
            >
              <input {...getInputProps()} />
              <Stack alignItems="center" spacing={2}>
                <IconFormat
                  loading={loading}
                  filename={filename}
                  format={format}
                />
              </Stack>
            </Box>
          </Stack>
        )}
      </Dropzone>

      <FormHelperText error>{error}</FormHelperText>
    </Stack>
  );
});

DefaultDropzone.defaultProps = {
  multiple: false,
  maxSize: 2048000,
  typeIcon: "json"
};

export default Dropzone;
