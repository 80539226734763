import React from 'react';
import { Input } from 'antd';

const InputPassword = ({
    ...rest
}) => {
  const [passwordVisible, setPasswordVisible] = React.useState(false);
  return (
       <Input.Password
          {...rest}
          visibilityToggle={{ visible: passwordVisible, onVisibleChange: setPasswordVisible }}
        />
      
  );
};

export default InputPassword;