import React, {
  useState,
  useEffect,
  useReducer,
  useCallback,
  useContext
} from "react";
import { toast } from "react-toastify";
import Row from "./components/Row";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import { TBody, THead, Table, TCell, TRow } from "../../components/Table";

import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditIcon from "@material-ui/icons/Edit";
import Search from "antd/es/input/Search";

import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import Title from "../../components/Title";
import { Button } from "antd";

import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import TagModal from "../../components/TagModal";
import ConfirmationModal from "../../components/ConfirmationModal";
import toastError from "../../errors/toastError";
import { Chip } from "@material-ui/core";
import { socketConnection } from "../../services/socket";
import { AuthContext } from "../../context/Auth/AuthContext";
import { Stack, Typography } from "@mui/material";

const reducer = (state, action) => {
  if (action.type === "LOAD_LOGS") {
    const logs = action.payload;
    const newLogs = [];

    logs.forEach(log => {
      const logIndex = state.findIndex(s => s.id === log.id);
      if (logIndex !== -1) {
        state[logIndex] = log;
      } else {
        newLogs.push(log);
      }
    });

    return [...state, ...newLogs];
  }

  if (action.type === "UPDATE_LOGS") {
    const log = action.payload;
    const logIndex = state.findIndex(s => s.id === log.id);

    if (logIndex !== -1) {
      state[logIndex] = log;
      return [...state];
    } else {
      return [log, ...state];
    }
  }

  if (action.type === "DELETE_LOG") {
    const logId = action.payload;

    const logIndex = state.findIndex(s => s.id === logId);
    if (logIndex !== -1) {
      state.splice(logIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const useStyles = makeStyles(theme => ({
  mainPaper: {
    flex: 1,
    backgroundColor: "#fff",
    overflowY: "hidden",
    borderRadius: "10px",
    ...theme.scrollbarStyles
  }
}));

const Logs = () => {
  const classes = useStyles();

  const { user } = useContext(AuthContext);

  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [selectedTag, setSelectedTag] = useState(null);
  const [deletingTag, setDeletingTag] = useState(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [searchParam, setSearchParam] = useState("");
  const [logs, dispatch] = useReducer(reducer, []);
  const [logModalOpen, setTagModalOpen] = useState(false);

  const fetchLogs = useCallback(async () => {
    try {
      const { data } = await api.get("/logs/", {
        params: { searchParam, pageNumber }
      });
      dispatch({ type: "LOAD_LOGS", payload: data.logs });
      setHasMore(data.hasMore);
      setLoading(false);
    } catch (err) {
      toastError(err);
    }
  }, [searchParam, pageNumber]);
  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [searchParam]);

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      fetchLogs();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchParam, pageNumber, fetchLogs]);

  useEffect(() => {
    const socket = socketConnection({ companyId: user.companyId });

    socket.on("user", data => {
      if (data.action === "update" || data.action === "create") {
        dispatch({ type: "UPDATE_LOGS", payload: data.logs });
      }

      if (data.action === "delete") {
        dispatch({ type: "DELETE_USER", payload: +data.logId });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, [user]);

  const handleSearch = event => {
    setSearchParam(event.target.value.toLowerCase());
  };

  const handleEditTag = log => {
    setSelectedTag(log);
    setTagModalOpen(true);
  };

  const loadMore = () => {
    setPageNumber(prevState => prevState + 1);
  };

  const handleScroll = e => {
    if (!hasMore || loading) return;
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      loadMore();
    }
  };

  return (
    <MainContainer>
      <Paper
        className={classes.mainPaper}
        variant="outlined"
        onScroll={handleScroll}
      >
        <MainHeader>
          <Stack>
            <Typography
              variant="h5"
              color="black"
              style={{
                fontWeight: "bold",
                marginLeft: "10px",
                marginTop: "10px"
              }}
              gutterBottom
            >
              Logs
            </Typography>
            <Typography
              style={{ marginTop: "-10px", marginLeft: "10px" }}
              variant="caption"
              color="textSecondary"
            >
              Veja os logs de execução do fluxo
            </Typography>
          </Stack>

          {/* <MainHeaderButtonsWrapper>
            <Search
              placeholder={i18n.t("Buscar")}
              size="large"
              type="search"
              value={searchParam}
              onChange={handleSearch}
              style={{
                width: "300px",
                marginRight: "10px",
                marginLeft: "10px"
              }}
            />
          </MainHeaderButtonsWrapper> */}
        </MainHeader>
        <Stack
          style={{
            padding: "20px",
            backgroundColor: "#fff",
            borderRadius: "5px",
            height: "93%",
            overflowY: "auto"
          }}
        >
          <Paper
            style={{
              boxShadow: "none",
              backgroundColor: "#fff"
            }}
          >
            <Table
              size="small"
              sx={{
                backgroundColor: "#fff"
              }}
            >
              <THead sticky="true">
                <TRow>
                  <TCell style={{ color: "#fff" }} align="left">
                    <Stack sx={{ml: 4}}>
                    Método
                    </Stack>
                    
                  </TCell>
                  <TCell style={{ color: "#fff" }}>
                    Rota
                  </TCell>
                  <TCell style={{ color: "#fff" }}>
                    Criado em
                  </TCell>
                  <TCell style={{ color: "#fff" }}>
                    Tempo de execução
                  </TCell>
                  <TCell style={{ color: "#fff" }}>
                    Fluxo
                  </TCell>
                </TRow>
              </THead>
              <TBody>
                <>
                  {logs.map((log, index) => (
                    <Row
                      key={`credor-${log?.id}`}
                      item={log}
                      index={index}
                    />
                  ))}
                  {loading && <TableRowSkeleton columns={3} />}
                </>
              </TBody>
            </Table>
          </Paper>
        </Stack>
      </Paper>
    </MainContainer>
  );
};

export default Logs;
