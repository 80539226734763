export const StartData = {
  icon: 'startIcon',
  category: {
    color: "#0C3249",
  },
  port: {
    next: {
      color: "#0C3249",
    },
  },
  daysOfWeek: [],
  title: "Bem vindo e nome",
  type: "start",
  label: "start node",
}

export default StartData
