import React, { useState, useEffect, useContext } from "react";

import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";

import CircularProgress from "@material-ui/core/CircularProgress";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import { Divider, Stack, Typography, Grid } from '@mui/material';
import InputPassword from "../../components/InputPassword";

import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import toastError from "../../errors/toastError";
import QueueSelect from "../QueueSelect";
import { AuthContext } from "../../context/Auth/AuthContext";
import { Can } from "../Can";
import { Modal, Input, Button, Select } from "antd";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},
	multFieldLine: {
		display: "flex",
		"& > *:not(:last-child)": {
			marginRight: theme.spacing(1),
		},
	},

	btnWrapper: {
		position: "relative",
	},

	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
}));

const UserSchema = Yup.object().shape({
	name: Yup.string()
		.min(2, "Too Short!")
		.max(50, "Too Long!")
		.required("Required"),
	password: Yup.string().min(5, "Too Short!").max(50, "Too Long!"),
	email: Yup.string().email("Invalid email").required("Required"),
});

const EditUserModal = ({ open, onClose, userId }) => {
	const classes = useStyles();

	const initialState = {
		name: "",
		email: "",
		password: "",
		profile: "user",
	};

	const { user: loggedInUser } = useContext(AuthContext);

	const [user, setUser] = useState(initialState);
	const [selectedQueueIds, setSelectedQueueIds] = useState([]);
	const [userPermissions, setUserPermissions] = useState();


	useEffect(() => {
		const fetchUser = async () => {
			if (!userId) return;
			try {
				const { data } = await api.get(`/users/${userId}`);
				setUser(prevState => {
					return { ...prevState, ...data };
				});
				const userQueueIds = data.queues?.map(queue => queue.id);
				setSelectedQueueIds(userQueueIds);
			} catch (err) {
				toastError(err);
			}
		};

		fetchUser();
	}, [userId, open]);

	const handleClose = () => {
		onClose();
		setUser(initialState);
	};

	const handleSaveUser = async values => {
		const userData = { ...values, queueIds: selectedQueueIds };
		try {
			if (userId) {
				await api.put(`/users/${userId}`, userData);
			} else {
				await api.post("/users", userData);
			}
			toast.success(i18n.t("userModal.success"));
		} catch (err) {
			toastError(err);
		}
		handleClose();
	};


	const renderTitle = () => {
		return <Stack   >
			<Typography variant="h6" sx={{ ml: 1 }}>
				{userId ? `Editar usuário` : `Adicionar usuário`}
			</Typography>
			<Typography variant="caption" sx={{ ml: 1 }}>
				{userId ? `Edite o usuário` : `Adicione um usuário para ter acesso ao sistema`}
			</Typography>
		</Stack>
	}

	const handleChangePermissions = (e) => {
		setUserPermissions(e);
	}



	return (

		<Modal
			title={renderTitle()}
			open={open}
			onCancel={handleClose}
			footer={null}
		>
			<Divider sx={{ my: 1 }} />
			<Stack p={1}>
				<Formik
					initialValues={user}
					enableReinitialize={true}
					validationSchema={UserSchema}
					onSubmit={(values, actions) => {
						setTimeout(() => {
							handleSaveUser({
								...values,
								profile: userPermissions,
							});
							actions.setSubmitting(false);
						}, 400);
					}}
				>
					{({ touched, errors, isSubmitting, values, handleChange, handleSubmit }) => (
						<Form>
							<Grid container spacing={1}>
								<Grid item xs={6}>
									<Stack >
										<Typography variant="caption" color="gray">
											Nome*
										</Typography>
										<Input
											size="large"
											id="name"
											value={values.name}
											onChange={handleChange}
											name="name"
											placeholder="Nome do usuário"
										/>
									</Stack>
								</Grid>

								<Grid item xs={6}>
									<Stack >
										<Typography variant="caption" color="gray">
											Senha*
										</Typography>
										<InputPassword
											id="password"
											value={values.password}
											onChange={handleChange}
											name="password"
											placeholder="Senha"
											size="large"
										/>
									</Stack>
								</Grid>

								<Grid item xs={6}>
									<Stack >
										<Typography variant="caption" color="gray">
											Email*
										</Typography>
										<Input
											size="large"
											id="email"
											value={values.email}
											onChange={handleChange}
											name="email"
											type="email"
											placeholder="Email do usuário"
										/>
									</Stack>
								</Grid>

								<Grid item xs={6}>
									<Can
										role={loggedInUser.profile}
										perform="user-modal:editQueues"
										yes={() => (<Stack >
											<Typography variant="caption" color="gray">
												Perfil*
											</Typography>
											<Select
												placeholder={"Nível de acesso"}
												id="profile"
												optionFilterProp="children"
												onChange={handleChangePermissions}
												value={userPermissions}
												options={[{
													value: "admin",
													label: "Administrador"
												}, {
													value: "user",
													label: "Usuário"
												}]}
												size="large"
												style={{ width: '100%' }}
											/>
										</Stack>)}
									/>
								</Grid>
								<Grid item xs={12}>

									<Can
										role={loggedInUser.profile}
										perform="user-modal:editQueues"
										yes={() => (
											<Stack>
												<Typography variant="caption" color="gray">
													Departamentos*
												</Typography>
												<QueueSelect
													selectedQueueIds={selectedQueueIds}
													onChange={values => setSelectedQueueIds(values)}
												/>
											</Stack>
										)}
									/>
								</Grid>
							</Grid>
							<DialogActions style={{
								marginTop: "20px",
							}}>
								<Button
									onClick={handleClose}
									color="secondary"
									disabled={isSubmitting}
									variant="outlined"
								>
									{i18n.t("userModal.buttons.cancel")}
								</Button>
								<Button
									type="primary"
									onClick={handleSubmit}
									color="primary"
									disabled={isSubmitting}
									variant="contained"
									className={classes.btnWrapper}
								>
									{userId
										? `${i18n.t("userModal.buttons.okEdit")}`
										: `${i18n.t("userModal.buttons.okAdd")}`}
									{isSubmitting && (
										<CircularProgress
											size={24}
											className={classes.buttonProgress}
										/>
									)}
								</Button>
							</DialogActions>
						</Form>
					)}
				</Formik>
			</Stack>
		</Modal>

	);
};

export default EditUserModal;
