import React, { useState, useEffect, useContext, useMemo } from "react";

import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";

import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Autocomplete, {
	createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import { WhatsAppsContext } from "../../context/WhatsApp/WhatsAppsContext";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import ButtonWithSpinner from "../ButtonWithSpinner";
import ContactModal from "../ContactModal";
import ErrorModal from "../ErrorModal";
import toastError from "../../errors/toastError";
import QueueSelect from "../QueueSelect";
import { AuthContext } from "../../context/Auth/AuthContext";
import { Chip, IconButton, ListItemText, MenuItem } from "@material-ui/core";
import {
	Add,
	Facebook,
	Instagram,
	PanoramaFishEyeOutlined,
	PlusOne,
	ShowChart,
	Visibility,
	WhatsApp
} from "@material-ui/icons";
import { Input, Modal, Button, Select } from "antd";

import { toast } from "react-toastify";
import { Divider, Stack, Typography, Grid, Tooltip, Avatar } from '@mui/material';
import ContactSelect from "../ContactSelect";
import QueueSelectSingle from "../QueueSelectSingle";
import ChannelSelect from "../ChannelSelect";
import { FaUser, FaUserPlus } from "react-icons/fa";

const filter = createFilterOptions({
	trim: true,
});

const NewTicketModal = ({ modalOpen, onClose, initialContact }) => {

	const [options, setOptions] = useState([]);
	const [loading, setLoading] = useState(false);
	const [searchParam, setSearchParam] = useState("");
	const [selectedContact, setSelectedContact] = useState(null);
	const [selectedQueue, setSelectedQueue] = useState("");
	const [selectedChannel, setSelectedChannel] = useState("");
	const [newContact, setNewContact] = useState({});
	const [contactId, setContactId] = useState()
	const [contacts, setContacts] = useState([]);

	const [errorMessage, setErrorMessage] = useState()
	const [contactModalOpen, setContactModalOpen] = useState(false);
	const [errorTickerModalOpen, setErrorTicketModalOpen] = useState(false)
	const [usedConnections, setUsedConnections] = useState([])
	const { whatsApps, loading: loadingWhatsapps } = useContext(WhatsAppsContext);
	const { user } = useContext(AuthContext);
	const { companyId } = user;


	const filterOption = (input, option) => {
		//filter by tag name
		return option.label.props.children[2].props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
	}


	useEffect(() => {
		( () => {
				 api.get("/contacts/list", {
					params: { companyId: companyId }
				}).then(({data})=>{
					const contactsData = data.map((item) => {
						return {
							value: item.id,
							label: <Stack direction="row" spacing={1} alignItems="center" mt={0.5} mr={1}>
								<Avatar
									alt={item.name}
									src={item.profilePicUrl}
									sx={{ width: 24, height: 24 }}
								/>
	
	
								<Typography variant="body2" sx={{ fontWeight: 500 }}>
									-
								</Typography>
								<Typography variant="body2" sx={{ fontWeight: 500 }}>
									{item?.name}
								</Typography>
							</Stack>,
						}
					})
					setContacts(contactsData);
				}).catch((err)=>{
					toastError(err);
				})
				
	
		})();
	}, []);


	useEffect(() => {
		if (initialContact?.id !== undefined) {
			setOptions([initialContact]);
			setSelectedContact(initialContact.id);
		}
	}, [initialContact]);

	useEffect(() => {
		if (!modalOpen || searchParam.length < 3) {
			setLoading(false);
			return;
		}
		setLoading(true);
		const delayDebounceFn = setTimeout(() => {
			const fetchContacts = async () => {
				try {
					const { data } = await api.get("contacts", {
						params: { searchParam },
					});
					setOptions(data.contacts);
					setLoading(false);
				} catch (err) {
					setLoading(false);
					toastError(err);
				}
			};

			fetchContacts();
		}, 500);
		return () => clearTimeout(delayDebounceFn);
	}, [searchParam, modalOpen]);


	const handleGetContactTicketId = async () => {
		const { data } = await api.get(`/tickets/open/${contactId}`);
		if (data) {
			setUsedConnections(data)
		}
	}

	const handleClose = () => {
		onClose();
		setSearchParam("");
		setSelectedContact(null);
		setSelectedChannel("");
	};

	const handleSaveTicket = async contactId => {
		if (!contactId) return;
		if (selectedQueue === "" && user.profile !== 'admin') {
			toast.error("Selecione uma fila");
			return;
		}
		if (selectedChannel === "") {
			toast.error("Selecione uma conexão");
			return;
		}
		setLoading(true);
		try {
			const queueId = selectedQueue !== "" ? selectedQueue : null;
			const { data: ticket } = await api.post("/tickets", {
				contactId: contactId,
				queueId,
				userId: user.id,
				status: "open",
				whatsappId: selectedChannel
			});
			onClose(ticket);
			setSearchParam("");
			setSelectedContact(null);
			setSelectedChannel("");
		} catch (err) {
			const errorMsg = err.response?.data?.error;
			try {
				const parsedError = JSON.parse(errorMsg)
				setErrorMessage(parsedError)
			} catch (error) {
				toastError(err);
			}
		}
		setLoading(false);
	};

	const handleSelectOption = (e, newValue) => {
		if (newValue?.number) {
			setSelectedContact(newValue);
			handleGetContactTicketId()
		} else if (newValue?.name) {
			setNewContact({ name: newValue?.name });
			setContactModalOpen(true);
		}
	};

	const IconChannel = channel => {
		switch (channel) {
			case "facebook":
				return <Facebook />;
			case "instagram":
				return <Instagram />;
			case "whatsapp":
				return <WhatsApp />;
			default:
				return "error";
		}
	};


	const handleCloseContactModal = () => {
		setContactModalOpen(false);
	};

	const handleOpenContactModal = () => {
		setContactModalOpen(true);
	}

	const handleAddNewContactTicket = contact => {
		const payload = {
			value: contact.id,
			label: <Stack direction="row" spacing={1} alignItems="center" mt={0.5} mr={1}>
				<Avatar
					alt={contact.name}
					src={contact.profilePicUrl}
					sx={{ width: 24, height: 24 }}
				/>


				<Typography variant="body2" sx={{ fontWeight: 500 }}>
					-
				</Typography>
				<Typography variant="body2" sx={{ fontWeight: 500 }}>
					{contact?.name}
				</Typography>
			</Stack>,
		}
		setSelectedContact(contact.id);
		setContacts(prevState => [...prevState, payload]);
	};

	const createAddContactOption = (filterOptions, params) => {
		const filtered = filter(filterOptions, params);

		if (params.inputValue !== "" && !loading && searchParam.length >= 3) {
			filtered.push({
				name: `${params.inputValue}`,
			});
		}

		return filtered;
	};

	const renderOption = option => {
		if (option.number) {
			setContactId(option.id)
			return `${option?.name} - ${option.number}`;
		} else {
			return `${i18n.t("newTicketModal.add")} ${option?.name}`;
		}
	};

	const renderOptionLabel = option => {
		if (option.number) {
			return `${option?.name} - ${option.number}`;
		} else {
			return `${option?.name}`;
		}
	};

	const renderContactAutocomplete = () => {
		if (initialContact === undefined || initialContact.id === undefined) {
			return (
				<Grid xs={12} item>
					<Autocomplete
						fullWidth
						options={options}
						loading={loading}
						clearOnBlur
						autoHighlight
						freeSolo
						clearOnEscape
						getOptionLabel={renderOptionLabel}
						renderOption={renderOption}
						filterOptions={createAddContactOption}
						onChange={(e, newValue) => handleSelectOption(e, newValue)}
						renderInput={params => (
							<TextField
								{...params}
								label={i18n.t("newTicketModal.fieldLabel")}
								variant="outlined"
								autoFocus
								onChange={e => setSearchParam(e.target.value)}
								onKeyPress={e => {
									if (loading || !selectedContact) return;
									else if (e.key === "Enter") {
										handleSaveTicket(selectedContact.id);
									}
								}}
								InputProps={{
									...params.InputProps,
									endAdornment: (
										<React.Fragment>
											{loading ? (
												<CircularProgress color="inherit" size={20} />
											) : null}
											{params.InputProps.endAdornment}
										</React.Fragment>
									),
								}}
							/>
						)}
					/>
				</Grid>
			)
		}
		return null;
	}

	const handleCloseErrorTicketModal = () => {
		setErrorTicketModalOpen(false)
	}




	const renderTitle = () => {
		return <Stack   >
			<Typography variant="h6" sx={{ ml: 1 }}>
				Novo atendimento
			</Typography>
			<Typography variant="caption" sx={{ ml: 1 }}>
				Busque um contato ou adicione um novo
			</Typography>
		</Stack>
	}
	return (
		<>
			<ErrorModal
				open={errorTickerModalOpen}
				onClose={handleCloseErrorTicketModal}
				message={errorMessage}
			></ErrorModal>
			<ContactModal
				open={contactModalOpen}
				initialValues={newContact}
				onClose={handleCloseContactModal}
				onSave={handleAddNewContactTicket}
			></ContactModal>
			<Modal
				title={renderTitle()}
				open={modalOpen}
				onCancel={handleClose}
				footer={null}
			>
				<Divider sx={{ mb: 2, mt: 1 }} />


				<Grid container spacing={1} sx={{ mt: 2 }}>
					{(initialContact === undefined || initialContact.id === undefined) ? (
						<>

							<Grid item xs>
								<Stack >
									<Typography variant="caption" color="gray">
										Contato *
									</Typography>
									<Select
										showSearch
										placeholder={"Selecione um contato"}
										id="contacts"
										filterOption={filterOption}
										optionFilterProp="children"
										onChange={setSelectedContact}
										value={selectedContact}
										options={contacts}
										size="large"
										style={{ width: '100%' }}
									/>
								</Stack>
							</Grid>
							<Grid item xs={1}>
								<Tooltip title="Adicionar contato" placement="top">
									<IconButton onClick={handleOpenContactModal} style={{ marginTop: "15px" }} >
										<FaUserPlus />
									</IconButton>
								</Tooltip>
							</Grid>
						</>
					) : null}


					<Grid item xs={12}>
						<Stack >
							<Typography variant="caption" color="gray">
								Departamento *
							</Typography>
							<QueueSelectSingle
								queues={user.queues}
								selectedQueueIds={selectedQueue}
								onChange={values => setSelectedQueue(values)}
							/>
						</Stack>
					</Grid>
					{selectedContact && (
						<Grid item xs={12}>
							<Stack >
								<Typography variant="caption" color="gray">
									Canal *
								</Typography>
								<ChannelSelect
									selectedChannel={selectedChannel}
									onChange={values => setSelectedChannel(values)}
									usedConnections={usedConnections}
								/>
							</Stack>
						</Grid>

					)}


				</Grid>
				<DialogActions style={{
					marginTop: "20px",
				}}>
					<Button
						onClick={handleClose}
						color="secondary"
						disabled={loading}
						variant="outlined"
					>
						{i18n.t("newTicketModal.buttons.cancel")}
					</Button>
					<Button
						onClick={() => handleSaveTicket(selectedContact)}
						color="secondary"
						type="primary"
						loading={loading}
						disabled={!selectedContact}
						variant="outlined"
					>
						{i18n.t("newTicketModal.buttons.ok")}
					</Button>

				</DialogActions>
			</Modal>
		</>
	);
};

export default NewTicketModal;
