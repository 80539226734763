import { Box, darken, styled } from '@mui/material'

const fontSizes = {
  smaller: '0.75rem',
  small: '0.9rem',
  medium: '1rem',
}

export const TableComponent = styled(Box)`
  position: relative;
  width: 100%;

  & .table-row {
    z-index: 2;
  }
  & .table-cell {
    vertical-align: middle;
    line-height: 1.5;

    ${({ size }) =>
      !!size &&
      `
      font-size: ${fontSizes[size] || fontSizes.medium};
    `}
  }
  & .table-head th.table-cell {
    font-size: ${fontSizes.smaller};
    ${({ theme }) => `
      color: ${theme.palette.text.secondary};
      padding-top: ${theme.spacing(0.5)};
      padding-bottom: ${theme.spacing(0.5)};
    `}
  }
  & .table-head.table-head-sticky .table-cell {
    position: sticky;
    background-color: #fafafa;
    top: 5px;
    z-index: 2;
  }

  &.variant-spaced-shadow .table-head .table-cell {
    height: 50px;
    ${({ theme }) => `
      background-color: #001529;
      box-shadow: 0 -${theme.spacing(3.25)} 0 0 ${
      theme.palette.background.paper
    };

      &:first-of-type {
        box-shadow: 0 -${theme.spacing(3.25)} 0 ${theme.spacing(3.25)} ${
      theme.palette.background.paper
    };
      }
      &:last-of-type {
        box-shadow: ${theme.spacing(3.25)} -${theme.spacing(
      3.25,
    )} 0 ${theme.spacing(3.25)} ${theme.palette.background.paper};
      }
    `}
  }

  &.variant-spaced-shadow .table-cell,
  &.variant-spaced-border .table-cell {
    :first-of-type {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }
    :last-of-type {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }

  &.variant-spaced-shadow {
    border-spacing: 0 10px;

    & .table-body .table-row {
      box-shadow: #0C324936 0px 2px 4px;
      border-radius: 10px;

      &.table-row-active,
      &.table-row-hover:hover {
        ${({ theme }) => `
          background-color: ${theme.palette.background.paper};
        `}
      }
    }

    & .table-body .table-cell {
      ${({ theme }) => `
        background-color: ${theme.palette.background.paper};
      `}
    }
  }

  &.variant-spaced-border {
    border-spacing: 0 10px;

    & .table-body .table-row {
      border-radius: 10px;

      & .table-cell {
        ${({ theme }) => `
          border-color: ${theme.palette.divider2};
        `}
      }
    }

    & .table-body .table-row.table-row-active .table-cell,
    & .table-body .table-row.table-row-hover:hover .table-cell {
      ${({ theme }) => `
        border-color: ${theme.palette.primary.main};
        color: ${theme.palette.primary.main};
      `}
    }

    & .table-cell {
      border-style: solid;
      border-width: 0px;
      border-top-width: 1px;
      border-bottom-width: 1px;

      :first-of-type {
        border-left-width: 1px;
      }

      :last-of-type {
        border-right-width: 1px;
      }
    }
  }

  &.variant-collapsed-strip {
    border-collapse: collapse;

    & .table-row {
      border-bottom: 1px solid;
      ${({ theme }) => `
        background-color: ${theme.palette.background.paper};
        border-color: ${theme.palette.divider};
      `}

      &.table-row-active,
      &.table-row-hover:hover {
        ${({ theme }) => `
          background-color: ${theme.palette.divider};
        `}
      }
    }
  }

  &.variant-collapsed-strip-reverse {
    border-collapse: collapse;

    & .table-head .table-cell {
      ${({ theme }) => `
        background-color: ${theme.palette.background.paper};
        box-shadow: 0 -${theme.spacing(1.5)} 0 0 ${
        theme.palette.background.paper
      };
  
        &:first-of-type {
          box-shadow: 0 -${theme.spacing(1.5)} 0 ${theme.spacing(1.5)} ${
        theme.palette.background.paper
      };
        }
        &:last-of-type {
          box-shadow: ${theme.spacing(1.5)} -${theme.spacing(
        1.5,
      )} 0 ${theme.spacing(1.5)} ${theme.palette.background.paper};
        }
      `}
    }

    & .table-row {
      border-bottom: 1px solid;

      ${({ theme }) => `
        border-color: ${theme.palette.divider};
        background-color: ${theme.palette.background.paper};
      `}

      &.table-row-active,
      &.table-row-hover:hover {
        ${({ theme }) => `
          background-color: ${darken(theme.palette.background.paper, 0.25)};
        `}
      }
    }
  }

  &.variant-logs {
    border-spacing: 0;

    & .table-head .table-row {
      ${({ theme }) => `
        background-color: ${theme.palette.background.paper};
      `}

      & .table-cell {
        font-weight: normal;
      }
    }
    & .table-body .table-row {
      ${({ theme }) => `
        background-color: ${theme.palette.background.paper};
      `}

      &:last-of-type .table-cell {
        border-bottom: 0px;
      }
    }
    & .table-cell {
      ${({ theme }) => `
        color: ${theme.palette.text.secondary};
        border-bottom: 1px solid ${theme.palette.divider};
        padding-top: ${theme.spacing(0.5)};
        padding-bottom: ${theme.spacing(0.5)};
        padding-left: ${theme.spacing(2)};
        padding-right: ${theme.spacing(2)};
      `}
      font-size: ${fontSizes.smaller};
    }
  }
`
