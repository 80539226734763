import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Tooltip,
  Typography
} from "@mui/material";
import { Container } from "./styled";
import BackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate, useHistory, useParams } from "react-router-dom";
import { AirlineStops, Download, Save } from "@mui/icons-material";
import { EyeFilled } from "@ant-design/icons";
import api  from "../../../../services/api";
import { toast } from "react-toastify";

export default ({
  flow,
  validating,
  onPublish,
  onShowBuilds,
  saving,
  onSaving
}) => {
  const history = useHistory();
  const { flowId } = useParams();

  const handleBack = () => {
    window.location.href = "/flows";
  };
  const handleListBuilds = () => {
    onShowBuilds();
  };

  const handleExportFlow = async () => {
    const flowData = await api
      .get("/getflow", {
        params: { flowName: flowId }
      })
      .then(response => {
        return response?.data;
      });
      const flow = flowData?.branch;

    const blob = new Blob(
      [
        JSON.stringify({
          flow: flow.diagram,
          name: flow.name,
          tags: flow?.tags || [],
          description: flow?.description || ""
        })
      ],
      { type: "application/json" }
    );
    const href = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = href;
    link.download = `${flow.name}.bot`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    toast.success("Fluxo exportado com sucesso");
  };

  return (
    <Container>
      <Grid container alignItems="center" className="fullHeight" spacing={1}>
        <Grid item>
          <IconButton
            size="small"
            sx={{
              backgroundColor: "#e9e9e9 !important",
              borderRadius: "10px !important",
              "&:hover": {
                backgroundColor: "#d7d7d7 !important"
              }
            }}
            onClick={handleBack}
          >
            <BackIcon fontSize="small" />
          </IconButton>
        </Grid>
        <Grid item xs sx={{ mr: 5 }}>
          <Typography variant="body1"> {flow.name} </Typography>
        </Grid>

       <Grid item>
      <Tooltip title={'Versões'}>
            <IconButton color="primary" onClick={handleListBuilds}>
              <EyeFilled />
            </IconButton>
          </Tooltip>
      </Grid> 
        <Grid item>
          {saving ? (
            <CircularProgress size={16} style={{ marginRight: 5 }} />
          ) : (
            <Tooltip title={"Salvar"}>
              <IconButton color="primary" onClick={onSaving}>
                <Save />
              </IconButton>
            </Tooltip>
          )}
        </Grid>
        <Grid item>
          <Tooltip title={"Exportar"}>
            <IconButton color="primary" onClick={handleExportFlow}>
              <Download />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item>
          <Button size="small" onClick={onPublish}>
            {validating ? (
              <>
                <CircularProgress
                  size={16}
                  color="inherit"
                  style={{ marginRight: 5 }}
                />
                <Typography>{"Validando"}</Typography>
              </>
            ) : (
              <>
                <AirlineStops style={{ marginRight: 5 }} fontSize="small" />
                <Typography>{"Publicar"}</Typography>
              </>
            )}
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};
