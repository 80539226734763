import {
  Box,
  Checkbox,
  Divider,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material"
import NodeMenu from "../../../StyleNodes/Menu"
import "ace-builds/src-noconflict/theme-github"
import "ace-builds/src-noconflict/mode-mysql"
import { DeleteOutline, ExpandMore, UploadFileOutlined } from "@mui/icons-material"
import React, { useCallback, useEffect, useRef, useState } from "react"
import { i18n } from "../../../../../../../translate/i18n";
import { Button, Input, Select } from "antd"
import { renderIcons } from "../../../../Icons"
import { v4 as uuidV4 } from "uuid"
import { availableConditions } from "../data"
// import Scrollbar from "perfect-scrollbar";

// import "perfect-scrollbar/css/perfect-scrollbar.css";


const handleStyle = { left: 10 }

export const ConditionalMenu = ({ node, anchorRef }) => {
  const { data } = node
  const [anchorEl, setAnchorEl] = useState(null)
  const [values, setValues] = useState(data)
  const [openImport, setOpenImport] = useState(false)
  // const comp = useRef(null);

  const handleRun = () => { }

  const handleChange = ({ target }) => {
    if (target.name === 'encrypt') values[target.name] = target.checked
    else values[target.name] = target.value
    setValues({ ...values })
  }


  const handleChangeSelect = (value) => {
    values["dbType"] = value
    setValues({ ...values })
  }


  const handleConditionSelectChange = (value, index) => {
    values.conditions[index]["comparator"] = value
    setValues({ ...values })
  }

  const handleConditionChange = ({ target }, index) => {
    values.conditions[index][target.name] = target.value
    setValues({ ...values })
  }

  const handleAddCondition = () => {
    const id = uuidV4()

    const totalConditions = values.conditions.length
    values.payload.port[id] = {
      color: values.colors[totalConditions % values.colors.length],
    }

    values.conditions = values.conditions.concat({
      id,
      left: "",
      right: "",
      comparator: "eq",
    })

    setValues({ ...values })
  }

  const handleDeleteConnection = useCallback(
    (id) => {
      delete values.payload?.port[id]
      setValues({
        ...values,
        conditions: values.conditions.filter((cond) => cond.id != id),
      })
    },
    [values]
  )

  // useEffect(() => {
  //   let ps = new Scrollbar(comp.current);
  //   return () => {
  //     if (ps) {
  //       ps.destroy();
  //       ps = null;
  //     }
  //   };
  // }, []);

  return (
    <NodeMenu
      open
      node={node}
      anchorRef={anchorRef}
      values={values}
      onChange={handleChange}
      onRun={handleRun}
    >
      <Stack direction="row" alignItems="center" spacing={1}>
        <Stack sx={{
          backgroundColor: `#0C3249`,
          padding: 1,
          borderRadius: "10px 10px 10px 10px"
        }}
        >
          {renderIcons(data?.payload?.icon)}
        </Stack>
        <Stack spacing={0}>
          <Typography variant="h6"> {i18n.t('Condição')}</Typography>
          <Typography color="text.secondary" sx={{ mt: -0.7 }}>
            {i18n.t('Adicione uma condição para seu bot')}
          </Typography>

        </Stack>
      </Stack>
      <Divider sx={{ my: 1 }} />
      <div  style={{ maxHeight: 400, overflow: 'hidden', position: "relative" }} >
      <Stack spacing={1} sx={{
        maxHeight: 400,
        overflowY: 'scroll',
        overflowX: 'hidden',
      }}>


        {values.conditions.map((cond, index) => (
          <>

            <Grid container spacing={1}>
              <Grid item xs>
                <Stack>
                  <Typography variant="caption" color="text.secondary">
                    {`Campo ${index + 1}`}
                  </Typography>
                  <Input
                    name="left"
                    value={cond.left}
                    onChange={(ev) => handleConditionChange(ev, index)}
                    style={{ width: "100%" }}
                    size="large"
                    required
                    placeholder="Campo"
                  />
                </Stack>
              </Grid>
              <Grid item xs={4}>
                <Stack>
                  <Typography variant="caption" color="text.secondary">
                    {`Comparador ${index + 1}`}
                  </Typography>
                  <Select
                    placeholder={i18n.t('Comparador')}
                    id="comparator"
                    key={`comparator-${cond.id}`}
                    onChange={(ev) => handleConditionSelectChange(ev, index)}
                    value={cond.comparator}
                    options={availableConditions.map((option) => {
                      return { value: option.value, label: i18n.t(option.label) }
                    })}
                    style={{ width: "100%" }}
                    size="large"
                  />
                </Stack>
              </Grid>
              {['exists', 'notExists'].indexOf(cond.comparator) === -1 ? (
                <Grid item xs>
                  <Stack>
                    <Typography variant="caption" color="text.secondary">
                      {`Valor ${index + 1}`}
                    </Typography>
                    <Input
                      name="right"
                      size="large"
                      required
                      value={cond.right}
                      onChange={(ev) => handleConditionChange(ev, index)}
                      style={{ width: "100%" }}
                      placeholder="Valor"
                    />
                  </Stack>
                </Grid>

              ) : ""}
              <Grid item justifyContent="center" alignItems="center" xs={1}>
                <Tooltip title={i18n.t('Remover')}>
                  <IconButton
                    size="small"
                    sx={{ marginTop: 3 }}
                    disabled={values.conditions.length === 1}
                    onClick={() => handleDeleteConnection(cond.id)}
                  >
                    <DeleteOutline />
                  </IconButton>

                </Tooltip>

              </Grid>
            </Grid>
            <Divider sx={{ my: 1 }} />
          </>
        ))}
      </Stack>
      </div>
      <Stack direction="row" justifyContent="center" mt={2}>
        <Button onClick={handleAddCondition}>
          + Adicionar condição
        </Button>
      </Stack>
    </NodeMenu>
  )
}

export default ConditionalMenu
