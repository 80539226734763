import { Typography, Stack, Divider } from "@mui/material";
import { useState, useContext } from "react";
import Box from "@mui/material/Box";
import { AuthContext } from "../../../context/Auth/AuthContext";
import { openApi } from "../../../services/api";

import SuccessAnimation from "../../../assets/lotties/success.json";
import Lottie from "lottie-react";

export default function FinalizationStep({ plan, company, values }) {
  const [animationEnd, setAnimationEnd] = useState(false);
  const { handleLogin } = useContext(AuthContext);

  const handleAnimationEnd = async () => {
    setAnimationEnd(true);
    if (values?.email && values?.password) {
      const data = {
        id: company,
        plan: plan
      };
      try {
        const result = await openApi.post("/companies/plan", data);
      } catch (error) {}
      window.location.href = "/login";
    }
  };
  return (
    <>
      <Stack
        spacing={2}
        sx={{
          marginTop: 3,
          minHeight: "500px"
        }}
      >
        <Box
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            overflowY: "hidden"
          }}
        >
          <Stack
            sx={{
              display: "flex",
              flexDirection: "column",
              minWidth: 600,
              p: 0,
              pt: 2,
              px: 1
            }}
          >
            <Stack alignItems="center" justifyContent="center">
              <Lottie
                animationData={SuccessAnimation}
                loop={false}
                onComplete={handleAnimationEnd}
                style={{
                  width: 300,
                  display: "initial"
                }}
              />
              <Typography variant="h3" color="success.main" sx={{ mt: -10 }}>
                Parabéns!
              </Typography>
              <Typography variant="body1" color="text.secondary">
                Sua conta foi criada com sucesso!
              </Typography>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </>
  );
}
