import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  Divider,
  Grid,
  Popover,
  Stack,
  Switch,
  Typography,
} from "@mui/material"
import NodeMenu from "../../../StyleNodes/Menu"

import AceEditor from "react-ace"

import "ace-builds/src-noconflict/theme-github"
import "ace-builds/src-noconflict/mode-mysql"
import React, { useRef, useState } from "react"
import { i18n } from "../../../../../../../translate/i18n";
import { Input, Select } from "antd"
import { renderIcons } from "../../../../Icons"
import InputPassword from "../../../../../../../components/InputPassword"

const handleStyle = { left: 10 }

export const DBQueryMenu = ({ node, anchorRef }) => {
  const { data } = node
  const [values, setValues] = useState(data)
  const [openImport, setOpenImport] = useState(false)


  const handleRun = () => { }

  const handleChange = ({ target }) => {
    if (target.name === 'encrypt') values[target.name] = target.checked
    else values[target.name] = target.value
    setValues({ ...values })
  }


  const handleChangeSelect = (value) => {
    values["dbType"] = value
    setValues({ ...values })
  }

  const handleDBConfigsChange = ({ target }) => {
    values.dbConfigs[target.name] = target.value
    setValues({ ...values })
  }




  return (
    <NodeMenu
      open
      node={node}
      anchorRef={anchorRef}
      values={values}
      onChange={handleChange}
      onRun={handleRun}
    >
      <Stack direction="row" alignItems="center" spacing={1}>
        <Stack sx={{
          backgroundColor: `#0C3249`,
          padding: 1,
          borderRadius: "10px 10px 10px 10px"
        }}
        >
          {renderIcons(data?.payload?.icon)}
        </Stack>
        <Stack spacing={0}>
          <Typography variant="h6"> {i18n.t('Banco de dados')}</Typography>
          <Typography color="text.secondary" sx={{ mt: -0.7 }}>
            {i18n.t('Executar query em um banco de dados')}
          </Typography>

        </Stack>
      </Stack>
      <Divider sx={{ my: 1 }} />
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Stack sx={{
              width: "100%",
            }}>
              <Typography variant="caption" color="text.secondary">
                {i18n.t('Tipo')}
              </Typography>
              <Select
                placeholder={i18n.t('Selecione o tipo do banco')}
                id="dbType"
                onChange={handleChangeSelect}
                value={values.dbType}
                options={["SQLServer", "MySql", "PgSql", "PLSQL"].map((option) => {
                  return { value: option, label: option }
                })}
                style={{ width: "100%" }}
                size="large"
              />

            </Stack>
            {values.dbType === 'SQLServer' && (
              <Stack alignItems="left" >
                <Typography variant="caption" color="text.secondary">
                  {i18n.t('Criptografar')}
                </Typography>
                <Stack justifyContent="flex-start" alignItems="center" direction="row" spacing={1}>

                  <Checkbox
                    name="encrypt"
                    onChange={handleChange}
                    value={values?.encrypt || false}

                  />
                </Stack>
              </Stack>
            )}
          </Stack>
        </Grid>
        <Grid item xs={9}>
          <Stack>
            <Typography variant="caption" color="text.secondary">
              {i18n.t('Host')}
            </Typography>
            <Input
              placeholder="Host"
              name="dbHost"
              onChange={handleDBConfigsChange}
              value={values?.dbConfigs?.dbHost}
              size="large"
            />
          </Stack>
        </Grid>
        <Grid item xs={3}>
          <Stack>
            <Typography variant="caption" color="text.secondary">
              {i18n.t('Porta')}
            </Typography>
            <Input
              placeholder="Porta"
              name="dbPort"
              value={values?.dbConfigs?.dbPort}
              onChange={handleDBConfigsChange}
              size="large"
            />
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack>
            <Typography variant="caption" color="text.secondary">
              {i18n.t('Usuário')}
            </Typography>
            <Input
              placeholder="Usuário"
              name="dbUser"
              value={values?.dbConfigs?.dbUser}
              onChange={handleDBConfigsChange}
              size="large"
            />
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack>
            <Typography variant="caption" color="text.secondary">
              {i18n.t('Senha')}
            </Typography>
            <InputPassword
              placeholder="Senha"
              name="dbPassword"
              value={values?.dbConfigs?.dbPassword}
              onChange={handleDBConfigsChange}
              size="large"
            />
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack>
            <Typography variant="caption" color="text.secondary">
              {i18n.t('Nome do Banco de Dados')}
            </Typography>
            <Input
              placeholder="Nome do Banco de Dados"
              name="dbName"
              value={values?.dbConfigs?.dbName}
              onChange={handleDBConfigsChange}
              size="large"
            />
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack>
            <Typography variant="caption" color="text.secondary">
              {i18n.t('Nome da instância')}
            </Typography>
            <Input
              placeholder="Nome da instância"
              name="instanceName"
              value={values?.dbConfigs?.instanceName}
              onChange={handleDBConfigsChange}
              size="large"
            />
          </Stack>
        </Grid>
      </Grid>

      <Box my={1}>
        <Typography variant="caption" color="text.secondary">

          Query
        </Typography>

        <AceEditor
          mode="mysql"
          theme="github"
          value={values.query}
          onChange={(value) =>
            handleChange({
              target: { name: "query", value },
            })
          }
          placeholder={`SELECT * FROM tableName WHERE 1=1`}
          minLines={10}
          maxLines={200}
          tabSize={2}
          setOptions={{
            useWorker: false,
          }}
          name="payload"
          editorProps={{ $blockScrolling: true }}
          style={{
            width: "100%",
            border: "1px solid #CACACA",
            borderRadius: "0.4em",
          }}
        />

      </Box>


    </NodeMenu>
  )
}

export default DBQueryMenu
