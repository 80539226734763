import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  Grid,
  Popover,
  Stack,
  Switch,
  Typography,
} from "@mui/material"
import NodeMenu from "../../../StyleNodes/Menu"

import AceEditor from "react-ace"

import "ace-builds/src-noconflict/theme-github"
import "ace-builds/src-noconflict/mode-json"
import { ExpandMore, UploadFileOutlined } from "@mui/icons-material"
import React, { useRef, useState } from "react"
import { i18n } from "../../../../../../../translate/i18n";
import { Input, Select } from "antd"
import { renderIcons } from "../../../../Icons"

const handleStyle = { left: 10 }

export const InactiveMenu = ({ node, anchorRef }) => {
  const { data } = node
  const [anchorEl, setAnchorEl] = useState(null)
  const [values, setValues] = useState(data)
  const [openImport, setOpenImport] = useState(false)

  const handleRun = () => { }

  const handleChange = ({ target }) => {
    values[target.name] = target.value
    setValues({ ...values })
  }


  const handleChangeSelect = (value) => {
    values["method"] = value
    setValues({ ...values })
  }

  const handleChangeSwitch = ({ target }, value) => {
    values[target.name] = value
    setValues({ ...values })
  }

  const handleOpenImport = (ev) => {
    setOpenImport(true)
    setAnchorEl(ev.target)
  }

  const handleCloseImport = () => {
    setOpenImport(false)
  }

  const handleImport = (result) => {
    const { url, method, params, data, header } = result

    setValues({
      ...values,
      url,
      method,
      params,
      body: data ? JSON.stringify(data, null, 2) : undefined,
      headers: header ? JSON.stringify(header, null, 2) : undefined,
    })
    setOpenImport(false)
  }

  return (
    <NodeMenu
      open
      node={node}
      anchorRef={anchorRef}
      values={values}
      onChange={handleChange}
      onRun={handleRun}
    >
      <Stack direction="row" alignItems="center" spacing={1}>
        <Stack sx={{
          backgroundColor: `#0C3249`,
          padding: 1,
          borderRadius: "10px 10px 10px 10px"
        }}
        >
          {renderIcons(data?.payload?.icon)}
        </Stack>
        <Stack spacing={0}>
          <Typography variant="h6"> {i18n.t('Inatividade')}</Typography>
          <Typography color="text.secondary" sx={{ mt: -0.7 }}>
            {i18n.t('Temporizador de inatividade do usuário')}
          </Typography>

        </Stack>
      </Stack>
      <Divider sx={{ my: 1 }} />
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Stack>
            <Typography variant="caption" color="text.secondary">
              {i18n.t('Tempo')}
            </Typography>
            <Input
              placeholder="10"
              name="time"
              addonAfter={
                <>
                  <Typography variant="caption" color="white">
                    {i18n.t('Minutos')}
                  </Typography>
                </>
              }
              type="number"
              value={values.time}
              onChange={handleChange}
              size="large"
            />
          </Stack>
        </Grid>
      </Grid>
    </NodeMenu>
  )
}

export default InactiveMenu
