import React, { useState, useEffect, useRef } from "react";

import * as Yup from "yup";
import { Formik, FieldArray, Form, Field } from "formik";
import { toast } from "react-toastify";
import { Divider, Stack, Typography, Grid } from '@mui/material';

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import CircularProgress from "@material-ui/core/CircularProgress";
import { ColorPicker } from 'antd';
import UserSelect from "../UserSelect"

import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import toastError from "../../errors/toastError";
import { Input, Modal, Button } from "antd";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {
    marginRight: theme.spacing(1),
    flex: 1
  },

  extraAttr: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },

  btnWrapper: {
    position: "relative"
  },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  }
}));

const ContactSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  number: Yup.string().min(8, "Too Short!").max(50, "Too Long!"),
  email: Yup.string().email("Invalid email")
});

const ContactWalletModal = ({ open, onClose, contactId, initialValues, onSave }) => {
  const classes = useStyles();
  const isMounted = useRef(true);
  const [color, setColor] = useState("#0F69E7");
  const [selectedUser, setSelectedUser] = useState(null);
  const initialState = {
    name: "",
    color: "",
  };

  const [contact, setContact] = useState(initialState);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    const fetchContact = async () => {
      if (initialValues) {
        setContact(prevState => {
          return { ...prevState, ...initialValues };
        });
      }

      if (!contactId) return;

      try {
        const { data } = await api.get(`/contactwallet/${contactId}`);
        if (isMounted.current) {
          setColor(data.color)
          setSelectedUser(data?.ownerId)
          setContact(data);
        }
      } catch (err) {
        toastError(err);
      }
    };

    fetchContact();
  }, [contactId, open, initialValues, selectedUser]);

  const handleClose = () => {
    onClose();
    setColor("#0F69E7");
    setSelectedUser(null);
    setContact(initialState);
  };

  const handleSaveContact = async values => {
    try {
      if (contactId) {
        if(!selectedUser){
          toast.error("Selecione um usuário responsável")
          return;
        }
        await api.put(`/contactwallet/${contactId}`, {
          ...values,
          color: color,
          ownerId: selectedUser
        });
        handleClose();
      } else {
        if(!selectedUser){
          toast.error("Selecione um usuário responsável")
          return;
        }
        const { data } = await api.post("/contactwallet", {
          ...values,
          color: color,
          ownerId: selectedUser
        });
        if (onSave) {
          onSave(data);
        }
        handleClose();
      }
      toast.success(i18n.t("contactModal.success"));
    } catch (err) {
      toastError(err);
    }
  };


  const handleChangeColor = (color) => { 
    let hexColor 
    try{
      hexColor = color.toHexString();
    }catch(error){

    }

    setColor(hexColor);
  }

  const renderTitle = () => {
    return <Stack   >
      <Typography variant="h6" sx={{ ml: 1 }}>
        {contactId ? `Editar ${contact.name}` : `Nova carteira`}
      </Typography>
      <Typography variant="caption" sx={{ ml: 1 }}>
        {contactId ? `ID: ${contactId}` : `Crie uma nova carteira de clientes`}
      </Typography>
    </Stack>
  }

  return (

    <Modal
      title={renderTitle()}
      open={open}
      onCancel={handleClose}
      footer={null}
    >
      <Divider sx={{ my: 1 }} />
      <Stack p={1}>


        <Formik
          initialValues={contact}
          enableReinitialize={true}
          validationSchema={ContactSchema}
          onSubmit={(values, actions) => {
            setTimeout(() => {
              handleSaveContact(values);
              actions.setSubmitting(false);
            }, 400);
          }}
        >
          {({ values, errors, touched, isSubmitting, handleChange, handleSubmit }) => (
            <Form>
              <Grid container spacing={1}>
                <Grid item xs>
                  <Stack >
                    <Typography variant="caption" color="gray">
                      Nome*
                    </Typography>
                    <Input
                      size="large"
                      id="name"
                      value={values.name}
                      onChange={handleChange}
                      name="name"
                      placeholder="Nome da carteira"
                    />
                  </Stack>

                </Grid>
                <Grid item xs={1.5}>
                  <Stack >
                    <Typography variant="caption" color="gray">
                      Cor*
                    </Typography>
                    <ColorPicker size="large" onChange={handleChangeColor} value={color} />
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <Stack >
                    <Typography variant="caption" color="gray">
                      Responsável
                    </Typography>
                    <UserSelect
                      selectedUserId={selectedUser}
                      onChange={setSelectedUser}
                    />
                  </Stack>

                </Grid>
   
              </Grid>
              <DialogActions style={{
                marginTop: 10
              }}>
                <Button
                  onClick={handleClose}
                  color="secondary"
                  disabled={isSubmitting}
                  variant="outlined"
                >
                  {i18n.t("contactModal.buttons.cancel")}
                </Button>
                <Button
                  type="primary"
                  color="primary"
                  onClick={handleSubmit}
                  disabled={isSubmitting}
                  variant="contained"
                  className={classes.btnWrapper}
                >
                  {contactId
                    ? `${i18n.t("contactModal.buttons.okEdit")}`
                    : `${i18n.t("contactModal.buttons.okAdd")}`}
                  {isSubmitting && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Stack>
    </Modal>

  );
};

export default ContactWalletModal;
