import React, { useEffect, useState, useContext } from "react";
import toastError from "../../errors/toastError";
import { Select, Typography } from "antd";
import { WhatsAppOutlined, FacebookOutlined, InstagramOutlined } from '@ant-design/icons';
import { WhatsAppsContext } from "../../context/WhatsApp/WhatsAppsContext";

const ChannelSelect = ({ selectedChannel, onChange, usedConnections }) => {
  const [whatsappConnections, setWhatsappConnections] = useState([]);
  const { whatsApps } = useContext(WhatsAppsContext);

  const IconChannel = (channel) => {
    switch (channel) {
      case "facebook":
        return <FacebookOutlined />;
      case "instagram":
        return <InstagramOutlined />;
      case "whatsapp":
        return <WhatsAppOutlined />;
      default:
        return "error";
    }
  };

  useEffect(() => {
    const loadConnections = async () => {
      try {
        if (!whatsApps.length) return;

        const connections = whatsApps.map((whatsApp) => {
          const used = usedConnections.find(used => used.whatsappId === whatsApp.id);
          const label = (
            <div style={{ display: 'flex', alignItems: 'center', marginRight: '4px' }}>
              {IconChannel(whatsApp?.channel)}
              <Typography.Text style={{ fontWeight: 500, marginLeft: '4px', marginRight: '4px' }}>-</Typography.Text>
              <Typography.Text style={{ fontWeight: 500 }}>{whatsApp?.name}</Typography.Text>
              {used && <Typography.Text type="secondary" style={{ marginLeft: '8px' }}>(Em atendimento)</Typography.Text>}
            </div>
          );

          return {
            value: whatsApp.id,
            disabled: !!used,
            label,
          };
        });

        setWhatsappConnections(connections);
      } catch (err) {
        toastError(err);
      }
    };

    loadConnections();
  }, [usedConnections, whatsApps]);

  const filterOption = (input, option) => {
    return option.label.props.children[2].props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  };

  return (
    <Select
      placeholder={"Selecione um canal"}
      showSearch
      id="queue"
      allowClear
      filterOption={filterOption}
      optionFilterProp="children"
      onChange={onChange}
      value={selectedChannel}
      options={whatsappConnections}
      size="large"
      style={{ width: '100%' }}
      maxTagCount="responsive"
      tagRender={(props) => {
        const { label, closable, onClose } = props;
        return (
          <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '30px',
            padding: '4px 8px',
            backgroundColor: '#F0F0F0',
            borderRadius: '5px',
            margin: '1px'
          }}>
            {label}
            {closable && <span onClick={onClose} style={{ marginLeft: '8px', cursor: 'pointer' }}>×</span>}
          </div>
        );
      }}
    />
  );
};

export default ChannelSelect;
