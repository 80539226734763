import React, { useState, useEffect, useContext } from "react";
import { Input, Modal, Button, DatePicker } from "antd";
import { Divider, Stack, Typography, Grid } from "@mui/material";
import { PlansConstant } from "./Plans";
import {
  formatCVC,
  formatCpfCnpj,
  formatCreditCardNumber,
  formatExpirationDate,
  formatFormData
} from "./utils";
import { Bolt, Check, WhatsApp } from "@mui/icons-material";
import toastError from "../../errors/toastError";
import { openApi } from "../../services/api";

const PaymentModal = ({ open, onClose, plan, company,onConfirm }) => {
  const [focus, setFocus] = useState("");
  const [loading, setLoading] = useState(false);
  const [planSelected, setPlanSelected] = useState();

  const [values, setValues] = useState({
    cvc: "",
    expiry: "",
    focus: "",
    name: "",
    number: "",
    cpfCnpj: "",
    address: "",
    complement: "",
    number: "",
    cep: "",
    email: ""
  });

  const handleChange = event => {
    const { name, value } = event.target;
    if (name === "number") values[name] = formatCreditCardNumber(value);
    if (name === "expiry") values[name] = formatExpirationDate(value);
    if (name === "cvc") values[name] = formatCVC(value);
    if (name === "cpfCnpj") {
      if (value.length > 18) return;
      values[name] = formatCpfCnpj(value);
    }
    if (name === "name") values[name] = value;
    if (name === "cep") values[name] = value;
    if (name === "address") values[name] = value;
    if (name === "addressNumber") values[name] = value;
    if (name === "addressComplement") values[name] = value;
    if (name === "email") values[name] = value;

    setValues({ ...values });
  };

  const renderTitle = () => {
    return (
      <Stack direction="row" spacing={2} alignItems="center">
        <Stack
          sx={{
            borderRadius: "50%",
            backgroundColor: planSelected?.color,
            height: "50px",
            width: "50px"
          }}
          justifyContent="center"
          alignItems="center"
        >
          <Bolt
            sx={{
              color: "#FFFFFF",
              fontSize: "40px"
            }}
          />
        </Stack>
        <Stack>
          <Typography variant="h5" fontWeight="bold">
            {planSelected?.name}
          </Typography>
          <Typography
            variant="h6"
            sx={{ mt: -1 }}
            color="success.main"
            fontWeight="bold"
          >
            R$ {planSelected?.value}
          </Typography>
        </Stack>
      </Stack>
    );
  };

  const handleClose = () => {
    onClose();
  };

  useEffect(() => {
    if (!plan) return;
    setPlanSelected(PlansConstant.find(item => item.name === plan));
    return () => {
      setPlanSelected(null);
    };
  }, [plan]);

  const getRemoteIp = async () => {
    const response = await fetch('https://api.ipify.org?format=json');
    const data = await response.json();
    return data.ip;
  }

  const handleSubmitPayment = async () => {
    
    // if(!company) return;
    if(!values.number) return toastError("Número do cartão inválido");
    if(!values.expiry) return toastError("Validade do cartão inválida");
    if(!values.cvc) return toastError("Código de segurança inválido");
    if(!values.name) return toastError("Nome do titular inválido");
    if(!values.email) return toastError("E-mail inválido");
    if(!values.cpfCnpj) return toastError("CPF/CNPJ inválido");
    if(!values.cep) return toastError("CEP inválido");
    if(!values.address) return toastError("Endereço inválido");
    if(!values.addressNumber) return toastError("Número do endereço inválido");
    const data = {
      companyId: company,
      ...values,
      ccv: values.cvc,
      holderName: values.name,
      planName: planSelected.name,
      planValue: planSelected.value,
      remoteIp:  await getRemoteIp()
    }
    setLoading(true);
    try{
      const result =  await openApi.post("/payment", data);
      return onConfirm()
    }catch(error){
      setLoading(false);
      if(error?.response?.data?.error){
        return toastError(error?.response?.data?.error[0]?.description)
      }
      
    }
  };

  return (
    <Modal
      title={renderTitle()}
      open={open}
      onCancel={handleClose}
      okText="Confirmar"
      cancelText="Cancelar"
      onOk={handleSubmitPayment}
      confirmLoading={loading}
      width={700}
    >
      <Divider sx={{ my: 1 }} />
      <Stack direction="row" p={0}>
       
          <Typography variant="h6" color="primary.main">
            Dados do cartão
          </Typography>
          <Stack sx={{ mr: 2 }} spacing={1}>
            <Stack>
              <Typography variant="caption" color="text.secondary">
                Número do cartão
              </Typography>
              <Input
                size="large"
                value={values.number}
                onChange={handleChange}
                name="number"
                autoFocus
                type="tel"
                pattern="[\d| ]{16,22}"
                onFocus={e => setFocus(e.target.name)}
              />
            </Stack>

            <Stack>
              <Typography variant="caption" color="text.secondary">
                Validade
              </Typography>
              <Input
                size="large"
                value={values.expiry}
                onChange={handleChange}
                name="expiry"
                pattern="\d\d/\d\d"
                onFocus={e => setFocus(e.target.name)}
              />
            </Stack>

            <Stack>
              <Typography variant="caption" color="text.secondary">
                Código de segurança
              </Typography>
              <Input
                size="large"
                value={values.cvc}
                onChange={handleChange}
                name="cvc"
                pattern="\d{3,4}"
                onFocus={e => setFocus(e.target.name)}
              />
            </Stack>

            <Divider sx={{ my: 1 }} />

            <Typography variant="h6" color="primary.main">
              Dados do titular
            </Typography>

            <Stack>
              <Typography variant="caption" color="text.secondary">
                Nome do titular
              </Typography>
              <Input
                size="large"
                value={values.name}
                onChange={handleChange}
                name="name"
                onFocus={e => setFocus(e.target.value)}
              />
            </Stack>


            <Stack>
              <Typography variant="caption" color="text.secondary">
                CPF/CNPJ
              </Typography>
              <Input
                size="large"
                value={values.cpfCnpj}
                onChange={handleChange}
                name="cpfCnpj"
         
              />
            </Stack>

   
          </Stack>
        </Stack>

    </Modal>
  );
};

export default PaymentModal;
