import React, { useState, useContext, useEffect } from "react";
import { Stack, Box, Grid, Typography } from "@mui/material"
import { Button, Input } from "antd";
import { AuthContext } from "../../context/Auth/AuthContext";
import { ReactComponent as LoginNovo } from "../../assets/bg_login.svg"
import InputPassword from "../../components/InputPassword";
import InputPasswordStrength from "../../components/InputPassword/InputPasswordStrength";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import { toast } from "react-toastify";

export default function RecoveryPassword() {
  const [user, setUser] = useState({ email: "", password: "" });
  const { handleLogin } = useContext(AuthContext);
  const [confirmPassword, setConfirmPassword] = useState("unvalid");
  const [recoveryData, setRecoveryData] = useState({});//[1]
  const [values, setValues] = useState({
    password: "",
    confirmPassword: ""
  });
  const handleChangeInput = e => {
    setUser({ ...user, [e.target?.name]: e.target.value });
  };


  useEffect(async () => {
    //get query params
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const token = params.get('token');
    if (!token) {
      window.location.href = "/login"
    }
    try {
      const { data } = await api.get(`/auth/validate/${token}`);
      setRecoveryData(data.recoveryInfos);
    } catch (err) {
      toastError(err);
      window.location.href = "/login"
    }

  }, [])

  const handleSubmit = async () => {
    try {
      const { data } = await api.post(`/auth/recoverypassword`, {
        userId: recoveryData?.user?.id,
        password: values.password,
        token: recoveryData?.token
      });
      toast.success("Senha alterada com sucesso!");
      window.location.href = "/login"
    } catch (err) {
      toastError(err);
    }
  };
  const handleChange = e => {
    values[e.target.name] = e.target.value;
    setValues({ ...values });
    if (e.target.name === "password") {
      if (e.target.value.length < 6) {
        setConfirmPassword("short")
      }
    }
    if (values.password && e.target.name === "confirmPassword") {
      if (e.target.value === values.password) {
        setConfirmPassword("checked")
      } else {
        setConfirmPassword("invalid")
      }
    }
  };
  return (
    <>

      <Grid container wrap="nowrap" className="fullHeight" overflow="hidden" >
        <Grid item xs={4} sx={{
          backgroundColor: '#0f3058',
          height: '100%',
          borderRadius: '0px 50px 50px 0px',
          display: 'block',
          position: 'relative',
          zIndex: '0',
        }}>

          <LoginNovo
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-30%, -50%)',
              zIndex: '1',
              display: 'block',
              maxWidth: '130%',
              maxHeight: '130%',
              width: 'auto',
              height: 'auto',
              minWidth: '130%',
              minHeight: '130%',
            }}
          />

        </Grid>
        <Grid item xs={8} className="fullHeight overflow-y" >

          <Grid
            container
            className="fullHeight"
            alignItems="center"
            justifyContent="center"
          >
            <Grid item p={1} xs={9} >

              <Typography variant="h4">
                {`Olá, ${recoveryData?.user?.name}!`}
              </Typography>
              <Typography variant="body1" mb={3}>
                Preencha os campos para redefinir sua senha
              </Typography>
              <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>

                <Stack  >

                  <Typography variant="caption" color="text.secondary">
                    Senha
                  </Typography>
                  <InputPasswordStrength
                    size="large"
                    id="password"
                    name="password"
                    value={values.password}
                    placeholder="Senha"
                    onChange={handleChange}
                    required
                  />

                </Stack>
                <Stack sx={{ mt: 2 }}>
                  <InputPassword
                    id="password"
                    value={values.confirmPassword}
                    onChange={handleChange}
                    name="confirmPassword"
                    placeholder="Confirmar senha"
                    size="large"
                  />
                </Stack>
                {confirmPassword === "invalid" && (
                  <Typography variant="caption" color="red">
                    Senhas não conferem
                  </Typography>
                )}

                <Grid container justifyContent="center" mt={2}>
                  <Grid item xs>
                    <Button
                      style={{
                        width: "100%",
                        backgroundColor: "#0f3058",
                        color: "#FFFFFF",
                        "&:hover": {
                          backgroundColor: "#0f305822"
                        },
                      }}

                      type="primary"
                      onClick={handleSubmit}
                      loading={false}
                      disabled={!values.confirmPassword || !values.password}
                      size="large"
                      fullWidth
                    >
                      <span>Confirmar</span>
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Grid>

      </Grid>
    </>
  );
}