import { Chip, Divider, Grid, IconButton, Paper, Stack, Typography } from "@mui/material"
import { daysOfWeeksArray } from "./utils/index"
import { Position, NodeProps } from "react-flow-renderer"
import { Container, HandleContainer, Handle, Tag } from "./styled"
import Node from "../../StyleNodes/Node"
export { ScheduleMenu } from "./menu"
export { ScheduleData } from "./data"
const handleStyle = { left: 10 }

const ScheduleNode = (node) => {
  const { data } = node
  return (
    <Node node={node}>

      <Grid
        container
        style={{ position: "relative" }}
        spacing={1}
        px={2}
        alignItems="center"
      >
        <Grid item sx={{ mb: 1 }}>
          <Stack direction="row" spacing={1}>
            <Typography variant="caption" color="gray">
              {!data?.days && data?.daysOfWeek.length > 0 ? (
                data?.daysOfWeek.map((item, i) => {

                  if (i === data?.daysOfWeek.length - 1) {
                    return (
                      <>
                        <Chip sx={{ mr: 1, mt: 1 }} size="small" label={daysOfWeeksArray.find((it) => it.value === item)?.label} color="primary" />

                          <Chip sx={{ mr: 1, mt: 1 }} size="small" label={`agendado em ${data.scheduleAt ? data?.scheduleAt : "-"}`} color="default" />
                      </>
                    )
       
                  
               
                  } else {
                    return <Chip sx={{ mr: 1, mt: 1 }} size="small" label={daysOfWeeksArray.find((it) => it.value === item)?.label} color="primary" />

                  }
                })
              ) : "Agendado em " + data.days + " dia(s)"}
            </Typography>


          </Stack>
        </Grid>
        <Grid item>
          <HandleContainer>
            <Handle
              type="source"
              position={Position.Right}
              id="next"
              color="#0C3249"
            />
          </HandleContainer>
        </Grid>
      </Grid>


    </Node >
  )
}

export default ScheduleNode
