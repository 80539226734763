import React from "react";
import { Layout } from "antd";

const { Content } = Layout;

const MainContainer = ({ children }) => {
  return (
    <Content
      style={{
        flex: 1,
        padding: "16px",
        height: "calc(100% - 48px)",
        overflowY: "hidden",
      }}
    >
      <div
        style={{
          height: "100%",
          overflowY: "hidden",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {children}
      </div>
    </Content>
  );
};

export default MainContainer;
