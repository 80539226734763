import React from "react"

export default ({
  sourceX,
  sourceY,
  sourcePosition,
  targetX,
  targetY,
  targetPosition,
  connectionLineType,
  connectionLineStyle,
  fromHandle,
  fromNode,
}) => {
  return (
    <g>
      <path
        fill="none"
        stroke={fromHandle?.color || "#0C3249"}
        strokeWidth={3}
        className="animated"
        d={`M${sourceX},${sourceY} C ${sourceX} ${targetY} ${sourceX} ${targetY} ${targetX},${targetY}`}
      />
      <circle
        cx={targetX}
        cy={targetY}
        fill="#fff"
        r={1}
        stroke={fromHandle?.color || "#0C3249"}
        strokeWidth={3}
      />
    </g>
  )
}
