import React, { useState, useEffect, useContext } from "react";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import TelegramIcon from "../../assets/img/channels/gupshup.svg";
import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import {
  DialogActions,
  CircularProgress,
  Switch,
  FormControlLabel,
  Grid,
  Divider,
  Typography
} from "@material-ui/core";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import toastError from "../../errors/toastError";
import { Stack, Avatar } from "@mui/material";
import { Input, Modal, Button } from "antd";
import TextArea from "antd/es/input/TextArea";
import { AuthContext } from "../../context/Auth/AuthContext";
import FlowSelect from "../FlowSelect";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap"
  },

  multFieldLine: {
    display: "flex",
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(1)
    }
  },

  btnWrapper: {
    position: "relative"
  },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  }
}));

const SessionSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  token: Yup.string()
});

const GupshupModal = ({ open, onClose, telegramId }) => {
  const classes = useStyles();
  const { user } = useContext(AuthContext);

  const initialState = {
    name: "",
    token: ""
  };

  const [whatsApp, setWhatsApp] = useState(initialState);
  const [selectFlow, setSelectFlow] = useState(null);

  const handleSaveWhatsApp = async values => {
    const telegramData = {...values, flow: selectFlow ? selectFlow : null};
    try {
      if (telegramId) {
        await api.put(`/telegram/${telegramId}`, telegramData);
      } else {
        await api.post("/telegram", telegramData);
      }
      toast.success("Telegram salvo com sucesso");
      onClose();
    } catch (err) {
      toastError(err);
    }
  };

  const handleClose = () => {
    onClose();
    setWhatsApp(initialState);
  };

  const renderTitle = () => {
    return (
      <Stack>
        <Stack direction="row" alignItems="center" spacing={0.5}>
          <Avatar
            src={TelegramIcon}
            sx={{
              width: 55,
              height:55,
              objectFit: "fill",
              background: "background.default",
              border: 0,
              p: 1
            }}
            imgProps={{
              style: {
                objectFit: "fill"
              }
            }}
          />
          <Stack spacing={0}>
            <Typography variant="h6" >
              {telegramId ? `Editar conexão gupshup` : `Nova conexão gupshup`}
            </Typography>

            <Typography variant="caption" style={{marginTop: -6}}>
              {telegramId ? `ID: ${telegramId}` : `Adicione uma nova conexão`}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    );
  };

  return (
    <div className={classes.root}>
      <Modal
        title={renderTitle()}
        open={open}
        onCancel={handleClose}
        footer={null}
      >
        <Divider sx={{ my: 1 }} />
        <Stack p={1}>
          <Formik
            initialValues={whatsApp}
            enableReinitialize={true}
            validationSchema={SessionSchema}
            onSubmit={(values, actions) => {
              setTimeout(() => {
                handleSaveWhatsApp({
                  ...values,

                  flow: selectFlow
                });
                actions.setSubmitting(false);
              }, 400);
            }}
          >
            {({
              values,
              touched,
              errors,
              handleSubmit,
              isSubmitting,
              handleChange
            }) => (
              <Form>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Stack>
                      <Typography variant="caption" color="gray">
                        Nome*
                      </Typography>
                      <Input
                        size="large"
                        id="name"
                        value={values.name}
                        onChange={handleChange}
                        name="name"
                        placeholder="Nome do bot"
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={12}>
                    <Stack>
                      <Typography variant="caption" color="gray">
                        App name *
                      </Typography>
                      <Input
                        size="large"
                        id="name"
                        value={values.appName}
                        onChange={handleChange}
                        name="appName"
                        placeholder="Nome do aplicativo"
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={12}>
                    <Stack>
                      <Typography variant="caption" color="gray">
                        Api-key *
                      </Typography>
                      <Input
                        size="large"
                        id="name"
                        value={values.apiKey}
                        onChange={handleChange}
                        name="apiKey"
                        placeholder="Chave de api"
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={12}>
                    <Stack>
                      <Typography variant="caption" color="gray">
                        Number *
                      </Typography>
                      <Input
                        size="large"
                        id="name"
                        value={values.number}
                        onChange={handleChange}
                        name="number"
                        placeholder="Número do canal"
                      />
                    </Stack>
                  </Grid>
                </Grid>
                <div>
                  {user?.company?.plan?.flows && (
                    <Stack sx={{ mt: 1, mb: 1 }}>
                      <Typography variant="caption" color="gray">
                        Fluxo
                      </Typography>
                      <FlowSelect
                        selectedTagsIds={selectFlow}
                        onChange={value => setSelectFlow(value)}
                      />
                    </Stack>
                  )}
                </div>
                <Stack mt={2}>
                  <DialogActions>
                    <Button
                      onClick={handleClose}
                      color="secondary"
                      disabled={isSubmitting}
                      variant="outlined"
                    >
                      {i18n.t("whatsappModal.buttons.cancel")}
                    </Button>
                    <Button
                      type="primary"
                      onClick={handleSubmit}
                      color="primary"
                      disabled={isSubmitting}
                      variant="contained"
                      className={classes.btnWrapper}
                    >
                      {telegramId
                        ? i18n.t("whatsappModal.buttons.okEdit")
                        : i18n.t("whatsappModal.buttons.okAdd")}
                      {isSubmitting && (
                        <CircularProgress
                          size={24}
                          className={classes.buttonProgress}
                        />
                      )}
                    </Button>
                  </DialogActions>
                </Stack>
              </Form>
            )}
          </Formik>
        </Stack>
      </Modal>
    </div>
  );
};

export default React.memo(GupshupModal);
