// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.react-kanban-column {
    padding: 15px;
    border-radius: 12px;
    background-color: #eee;
    margin: 5px;
    overflow: scroll;
}

[data-rbd-droppable-context-id="0"] {
    overflow-anchor: none;
}

.react-kanban-card {
    border-radius: 13px;
    background-color: #fff;
    padding: 10px;
    margin-bottom: 7px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Board/style.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,sBAAsB;IACtB,WAAW;IACX,gBAAgB;AACpB;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,mBAAmB;IACnB,sBAAsB;IACtB,aAAa;IACb,kBAAkB;IAClB,2CAA2C;AAC/C","sourcesContent":[".react-kanban-column {\n    padding: 15px;\n    border-radius: 12px;\n    background-color: #eee;\n    margin: 5px;\n    overflow: scroll;\n}\n\n[data-rbd-droppable-context-id=\"0\"] {\n    overflow-anchor: none;\n}\n\n.react-kanban-card {\n    border-radius: 13px;\n    background-color: #fff;\n    padding: 10px;\n    margin-bottom: 7px;\n    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
