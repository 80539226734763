import React from 'react';

import AceEditor from "react-ace"

import "ace-builds/src-noconflict/theme-github"
import "ace-builds/src-noconflict/mode-json"

export default function RequestTrace({
    item,
}) {
    
    return (
        <AceEditor
          mode="json"
          theme="github"
          value={item}
          readOnly
          maxLines={10}
          minLines={10}
          name="UNIQUE_ID_OF_DIV"
          editorProps={{ $blockScrolling: true }}
          setOptions={{
            useWorker: false,
          }}
          style={{ width: "100%" }}
        />

    )
}

