import { Fab, useScrollTrigger, Zoom } from "@mui/material"
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded"
import KeyboardArrowUpRoundedIcon from "@mui/icons-material/KeyboardArrowUpRounded"
import { shouldShowFabToScrollBottom } from "./helper"



export default function FabScroll({ to, scrollRef, sx }) {
  const trigger = useScrollTrigger({
    ...(to === "bottom" && {
      getTrigger: shouldShowFabToScrollBottom,
    }),
    disableHysteresis: true,
    target: scrollRef.current,
    threshold: 200,
  })

  const handleScroll = () => {
    if (!scrollRef.current) return

    let top = 0
    if (to === "bottom") {
      const { scrollHeight, offsetHeight } = scrollRef.current
      top = scrollHeight - offsetHeight
    }

    scrollRef.current.scrollTo({ top })
  }

  return (
    <Zoom in={trigger}>
      <Fab
        onClick={handleScroll}
        size="small"
        color="primary"
        sx={{
          position: "fixed",
          bottom: 120,
          right: 90,
          ...sx,
        }}
      >
        {to === "top" ? (
          <KeyboardArrowUpRoundedIcon />
        ) : (
          <KeyboardArrowDownRoundedIcon />
        )}
      </Fab>
    </Zoom>
  )
}
