export const JsonData = {
  hasInput: true,
  category: {
    color: "#5FD07E",
  },
  statusCode: 200,
  type: "json",
  method: 'GET',
  title: "Json",
  label: "json node",
}

export default JsonData
