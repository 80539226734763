import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  Grid,
  Popover,
  Stack,
  Switch,
  Typography,
} from "@mui/material"
import NodeMenu from "../../../StyleNodes/Menu"

import AceEditor from "react-ace"

import "ace-builds/src-noconflict/theme-github"
import "ace-builds/src-noconflict/mode-json"
import "ace-builds/src-noconflict/mode-graphqlschema"
import { ExpandMore, UploadFileOutlined } from "@mui/icons-material"
import React, { useRef, useState } from "react"
import { i18n } from "../../../../../../../translate/i18n";
import { Input, Select } from "antd"
import { renderIcons } from "../../../../Icons"

const handleStyle = { left: 10 }

export const GraphqlMenu = ({ node, anchorRef }) => {
  const { data } = node
  const [values, setValues] = useState(data)

  const handleRun = () => { }

  const handleChange = ({ target }) => {
    values[target.name] = target.value
    setValues({ ...values })
  }


  return (
    <NodeMenu
      open
      node={node}
      anchorRef={anchorRef}
      values={values}
      onChange={handleChange}
      onRun={handleRun}
    >
      <Stack direction="row" alignItems="center" spacing={1}>
        <Stack sx={{
          backgroundColor: `#0C3249`,
          padding: 1,
          borderRadius: "10px 10px 10px 10px"
        }}
        >
          {renderIcons(data?.payload?.icon)}
        </Stack>
        <Stack spacing={0}>
          <Typography variant="h6"> {i18n.t('Requisição Graphql')}</Typography>
          <Typography color="text.secondary" sx={{ mt: -0.7 }}>
            {i18n.t('Faça uma requisição Graphql')}
          </Typography>

        </Stack>
      </Stack>
      <Divider sx={{ my: 1 }} />
      <Grid container spacing={2}>

        <Grid item xs={12}>
          <Stack>
            <Typography variant="caption" color="text.secondary">
              {i18n.t('Host')}
            </Typography>
            <Input
              placeholder="https://api.example.com"
              name="host"
              value={values.host}
              onChange={handleChange}
              size="large"
            />
          </Stack>
        </Grid>
      </Grid>





      <Box my={1}>
        <Typography variant="caption" color="text.secondary">
          Headers
        </Typography>
        <AceEditor
          mode="json"
          theme="github"
          placeholder={`{\n    \"access_token\": \"value\"\n}`}
          value={values.headers}
          onChange={(value) =>
            handleChange({
              target: { name: "headers", value },
            })
          }
          maxLines={30}
          minLines={3}
          name="UNIQUE_ID_OF_DIV"
          editorProps={{ $blockScrolling: true }}
          setOptions={{
            useWorker: false,
          }}
          style={{ width: "100%" }}
        />
      </Box>

      <Box my={1}>
        <Typography variant="caption" color="text.secondary">
          Schema
        </Typography>
        <AceEditor
          mode="graphqlschema"
          theme="github"
          placeholder={`query{}`}
          value={values.schema}
          onChange={(value) =>
            handleChange({
              target: { name: "schema", value },
            })
          }
          maxLines={30}
          minLines={3}
          name="UNIQUE_ID_OF_DIV"
          editorProps={{ $blockScrolling: true }}
          setOptions={{
            useWorker: false,
            enableLiveAutocompletion: true,
            enableBasicAutocompletion: true,
            highlightSelectedWord: true,
          }}
          style={{ width: "100%" }}
        />
      </Box>


      <Box my={1}>
        <Typography variant="caption" color="text.secondary">

          Variáveis
        </Typography>
        <AceEditor
          mode="json"
          theme="github"
          value={values.variables}
          onChange={(value) =>
            handleChange({
              target: { name: "variables", value },
            } )
          }
          setOptions={{
            useWorker: false,
          }}
          maxLines={30}
          placeholder={`{\n    \"variable\": \"value\"\n}`}
          minLines={3}
          name="UNIQUE_ID_OF_DIV"
          editorProps={{ $blockScrolling: true }}
          style={{ width: "100%" }}
        />
      </Box>
    </NodeMenu>
  )
}

export default GraphqlMenu
