import React, {
    useState,
    useEffect,
    useReducer,
    useCallback,
    useContext
  } from "react";
  import { toast } from "react-toastify";
  
  import { makeStyles } from "@material-ui/core/styles";
  import Paper from "@material-ui/core/Paper";
  import IconButton from "@material-ui/core/IconButton";
  import { TBody, THead, Table, TCell, TRow } from "../../components/Table";
  
  import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
  import EditIcon from "@material-ui/icons/Edit";
  import Search from "antd/es/input/Search"
  
  import MainContainer from "../../components/MainContainer";
  import MainHeader from "../../components/MainHeader";
  import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
  import Title from "../../components/Title";
  import { Button } from "antd"
  
  import api from "../../services/api";
  import { i18n } from "../../translate/i18n";
  import TableRowSkeleton from "../../components/TableRowSkeleton";
  import TagModal from "../../components/TagModal";
  import ConfirmationModal from "../../components/ConfirmationModal";
  import toastError from "../../errors/toastError";
  import { Chip } from "@material-ui/core";
  import { socketConnection } from "../../services/socket";
  import { AuthContext } from "../../context/Auth/AuthContext";
  import { Stack, Typography } from "@mui/material";
  import BoardModal from "../../components/BoardModal";
  import moment from "moment";
  
  const reducer = (state, action) => {
    if (action.type === "LOAD_TAGS") {
      const tags = action.payload;
      const newTags = [];
  
      tags.forEach(tag => {
        const tagIndex = state.findIndex(s => s.id === tag.id);
        if (tagIndex !== -1) {
          state[tagIndex] = tag;
        } else {
          newTags.push(tag);
        }
      });
  
      return [...state, ...newTags];
    }
  
    if (action.type === "UPDATE_TAGS") {
      const tag = action.payload;
      const tagIndex = state.findIndex(s => s.id === tag.id);
  
      if (tagIndex !== -1) {
        state[tagIndex] = tag;
        return [...state];
      } else {
        return [tag, ...state];
      }
    }
  
    if (action.type === "DELETE_TAG") {
      const tagId = action.payload;
  
      const tagIndex = state.findIndex(s => s.id === tagId);
      if (tagIndex !== -1) {
        state.splice(tagIndex, 1);
      }
      return [...state];
    }
  
    if (action.type === "RESET") {
      return [];
    }
  };
  
  const useStyles = makeStyles(theme => ({
    mainPaper: {
      flex: 1,
      backgroundColor: "#fff",
      overflowY: "hidden",
      borderRadius: "10px",
      ...theme.scrollbarStyles,
    }
  }));
  
  const BoardsPage = () => {
    const classes = useStyles();
  
    const { user } = useContext(AuthContext);
  
    const [loading, setLoading] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [hasMore, setHasMore] = useState(false);
    const [selectedTag, setSelectedTag] = useState(null);
    const [deletingTag, setDeletingTag] = useState(null);
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [searchParam, setSearchParam] = useState("");
    const [tags, dispatch] = useReducer(reducer, []);
    const [tagModalOpen, setTagModalOpen] = useState(false);
  
    const fetchTags = useCallback(async () => {
      try {
        const { data } = await api.get("/boards/", {
          params: { searchParam, pageNumber }
        });
        dispatch({ type: "LOAD_TAGS", payload: data.boards });
        setHasMore(data.hasMore);
        setLoading(false);
      } catch (err) {
        toastError(err);
      }
    }, [searchParam, pageNumber]);
  
    useEffect(() => {
      dispatch({ type: "RESET" });
      setPageNumber(1);
    }, [searchParam]);
  
    useEffect(() => {
      setLoading(true);
      const delayDebounceFn = setTimeout(() => {
        fetchTags();
      }, 500);
      return () => clearTimeout(delayDebounceFn);
    }, [searchParam, pageNumber, fetchTags]);
  
    useEffect(() => {
      const socket = socketConnection({ companyId: user.companyId });
  
      socket.on("user", data => {
        if (data.action === "update" || data.action === "create") {
          dispatch({ type: "UPDATE_TAGS", payload: data.tags });
        }
  
        if (data.action === "delete") {
          dispatch({ type: "DELETE_USER", payload: +data.boardId });
        }
      });
  
      return () => {
        socket.disconnect();
      };
    }, [user]);
  
    const handleOpenTagModal = () => {
      setSelectedTag(null);
      setTagModalOpen(true);
    };
  
    const handleCloseTagModal = () => {
      setSelectedTag(null);
      setTagModalOpen(false);
    };
  
    const handleSearch = event => {
      setSearchParam(event.target.value.toLowerCase());
    };
  
    const handleEditTag = tag => {
      setSelectedTag(tag);
      setTagModalOpen(true);
    };
  
    const handleDeleteTag = async tagId => {
      try {
        await api.delete(`/boards/${tagId}`);
        toast.success("Quadro removido com sucesso");
      } catch (err) {
        toastError(err);
      }
      setDeletingTag(null);
      setSearchParam("");
      setPageNumber(1);
  
      dispatch({ type: "RESET" });
      setPageNumber(1);
      await fetchTags();
    };
  
    const loadMore = () => {
      setPageNumber(prevState => prevState + 1);
    };
  
    const handleScroll = e => {
      if (!hasMore || loading) return;
      const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
      if (scrollHeight - (scrollTop + 100) < clientHeight) {
        loadMore();
      }
    };
  
    return (
      <MainContainer>
        <ConfirmationModal
          title={deletingTag && `Deseja realmente deletar a etiqueta?`}
          open={confirmModalOpen}
          onClose={setConfirmModalOpen}
          onConfirm={() => handleDeleteTag(deletingTag.id)}
        >
          {i18n.t("tags.confirmationModal.deleteMessage")}
        </ConfirmationModal>
        <BoardModal
          open={tagModalOpen}
          onClose={handleCloseTagModal}
          reload={fetchTags}
          aria-labelledby="form-dialog-title"
          tagId={selectedTag && selectedTag.id}
        />
        <Paper
          className={classes.mainPaper}
          variant="outlined"
          onScroll={handleScroll}
        >
          <MainHeader>
            <Stack>
              <Typography variant="h5" color="black" style={{ fontWeight: "bold", marginLeft: "10px", marginTop: "10px" }} gutterBottom>
                Quadros
              </Typography>
              <Typography style={{ marginTop: "-10px", marginLeft: "10px" }} variant="caption" color="textSecondary">
                Cadastre e gerencie os quadros
              </Typography>
            </Stack>
  
            <MainHeaderButtonsWrapper>
              <Search
                placeholder={i18n.t("Buscar")}
                size="large"
                type="search"
                value={searchParam}
                onChange={handleSearch}
                style={{
                  width: "300px",
                  marginRight: "10px",
                  marginLeft: "10px",
                }}
              />
              <Button
  
                size="large"
                type="primary"
                onClick={handleOpenTagModal}
              >
                Criar quadro
              </Button>
  
            </MainHeaderButtonsWrapper>
          </MainHeader>
          <Stack
            style={{
              padding: "20px",
              backgroundColor: "#fff",
              borderRadius: "5px",
              height: "93%",
              overflowY: "auto",
              
            }}
          >
                <Paper style={{
              boxShadow: "none",
              backgroundColor: "#fff",
            }} >
            <Table size="small" sx={{
                backgroundColor: "#fff",
              }}>
                         <THead sticky="true">
  
                  <TRow>
                    <TCell style={{ color: "#fff" }} align="center">
                      Nome
                    </TCell>
                    <TCell style={{ color: "#fff" }} align="center">
                      Criado em
                    </TCell>
                    <TCell style={{ color: "#fff" }} align="center">
                      {i18n.t("tags.table.actions")}
                    </TCell>
                  </TRow>
                </THead>
                <TBody>
                  <>
                    {tags.map(tag => (
                      <TRow key={tag.id}>
                        <TCell align="center">
                          <Chip
                            variant="outlined"
                            style={{
                              backgroundColor: tag.color,
                              textShadow: "1px 1px 1px #000",
                              color: "white"
                            }}
                            label={tag?.name}
                            size="small"
                          />
                        </TCell>
                        <TCell align="center">{moment(tag?.createdAt).format("DD/MM/YYYY")}</TCell>
                        <TCell align="center">
                        <Button
                            type="primary"
                            onClick={()=>{
                              window.location.href = `/boardcontrol/${tag.id}`
                            }}
                            style={{
                              marginRight: "10px"
                            }}
                            >
                                Acessar
                          </Button>
                          <Button
                            type="dashed"
                            onClick={() => {
                              setDeletingTag(tag);
                              setConfirmModalOpen(true);
                            }}
                            style={{
                              marginRight: "10px",
                              borderColor: "red",
                              color: "red"
                            }}
                            >
                                Deletar
                          </Button>
                         
                        </TCell>
                      </TRow>
                    ))}
                    {loading && <TableRowSkeleton columns={3} />}
                  </>
                </TBody>
              </Table>
            </Paper>
          </Stack>
        </Paper>
      </MainContainer>
    );
  };
  
  export default BoardsPage;