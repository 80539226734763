import {
  Chip,
  Divider,
  Grid,
  IconButton,
  Paper,
  Typography,
} from '@mui/material'
import { Box } from '@mui/system'
import { Position, NodeProps } from 'react-flow-renderer'
import Node from '../../StyleNodes/Node'
import { i18n } from "../../../../../../translate/i18n";
import { useState } from 'react'
export { LoopControlMenu } from './menu'

const handleStyle = { left: 10 }

const LoopControlNode = (node) => {
  const { data } = node

  const actions = {
    break: {
      color: 'error',
      label: i18n.t('Parar'),
    },
    continue: {
      color: 'success',
      label: i18n.t('Continuar'),
    },
    returnIndex: {
      color: 'info',
      label: i18n.t('Retornar'),
    },
  }

  return (
    <Node node={node}>

        <Grid
          container
          style={{ position: 'relative', height: 50 }}
          px={1}
          spacing={1}
          alignItems="center"
        >
          <Grid item sx={{mb:1, mt:1}} xs={12}  alignItems="center" justifyContent="center" >
          {node.data.action ? (
            <Chip color={actions[node.data.action].color} size="small" sx={{color: "white"}} label={actions[node.data.action].label} />
          ): (
            <Typography variant="caption" color="gray">
            Selecione uma ação
          </Typography>
          )}
            </Grid>
        </Grid>
    </Node>
  )
}

export default LoopControlNode
