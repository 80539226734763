import React, { useState, useEffect, useRef, useContext } from "react";
import * as Yup from "yup";
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import { AuthContext } from "../../context/Auth/AuthContext";
import { Modal, Button, Input, Select, Empty, Spin } from "antd";
import { Stack, Typography, Grid, Tooltip, Box, Divider, IconButton, Avatar } from "@mui/material";
import toastError from "../../errors/toastError";
import api from "../../services/api";

import moment from "moment";
import TableRowSkeleton from "../TableRowSkeleton";
import { TBody, THead, Table, TCell, TRow } from "../Table";
const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap"
  },

  textField: {
    marginRight: theme.spacing(1),
    flex: 1
  },

  extraAttr: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },

  btnWrapper: {
    position: "relative"
  },


  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  }
}));

const CampaignSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required")
});
const getBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
const ShowCampaignResultModal = ({
  open,
  onClose,
  campaign,
}) => {
  const classes = useStyles();
  const [campaignResult, setCampaignResult] = useState([]);
  const isMounted = useRef(true);
  const { user } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if(!campaign) return;
    if (isMounted.current) {
      api
        .get(`/broadcast/${campaign}` )
        .then(({ data }) => setCampaignResult(data));

    }
  }, [campaign]);


  const renderTitle = () => {
    return (
      <Stack>
        <Typography variant="h6" sx={{ ml: 1 }}>
          Resultado
        </Typography>
        <Typography variant="caption" sx={{ ml: 1 }}>
          Veja o resultado da campanha
        </Typography>
      </Stack>
    );
  };



  const handleClose = () => {

    onClose();
  }

  const onSubmit = async () => {
    try {
      
    } catch (error) {
      toastError(error)
    }
  }

 


  return (
    <>

      <Modal
        title={renderTitle()}
        open={open}
        onClose={handleClose}
        onCancel={handleClose}
        footer={null}
        width={700}
      >
        <Divider sx={{ my: 1 }} />

        <Box sx={{ p: 1 }}>
            <Stack sx={{
              maxHeight: 400,
              overflow: 'auto',
            }}>

            <Table size="small">
              <THead sticky="true">
                <TRow>
                  <TCell align="center" sx={{ color: "white" }}>
                      ID
                  </TCell>
             
                  <TCell align="center" sx={{ color: "white" }}>
                    Nome
                  </TCell>
                  <TCell align="center" sx={{ color: "white" }}>
                    Telefone
                  </TCell>
                  <TCell align="center" sx={{ color: "white" }}>
                    Enviado em
                  </TCell>
                  <TCell align="center" sx={{ color: "white" }}>
                    Status
                  </TCell>
                </TRow>
              </THead>
              <TBody>
                <>
                  {campaignResult.map((campaign, index) => (
                    <TRow key={campaign.id}>
                      <TCell align="center">{index + 1}</TCell>
                      <TCell align="center">
                        {campaign.name}
                      </TCell>
                      <TCell align="center">
                        {campaign.number}
                      </TCell>
                  
                      <TCell align="center">
                        {campaign.completedAt ? moment(campaign.completedAt).format("DD/MM/YYYY HH:mm:ss"): "Aguardando"}
                      </TCell>
                      <TCell align="center">
                        {campaign.status}
                      </TCell>
                     
                    </TRow>
                  ))}
                </>
              </TBody>
            </Table>
            </Stack>

        </Box>
      </Modal>
    </>
  );
};

export default ShowCampaignResultModal;
