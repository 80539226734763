export const NodesConstant = [
  {
    "_id": {
      "$oid": "6570bf341bb1b0f6342ab9c7"
    },
    "name": "Conteúdo",
    "description": "Envie uma resposta ao usuário",
    "type": "message",
    "category": "action",
    "icon": "messageIcon",
    "default": true,
    "data": {
      "returnOptions": false,
      "continueFlow": true,
      "time": 0,
      "colors": [
        "#0C3249",
        "#995beb",
        "#6093a8",
        "#645d72",
        "#759ba9",
        "#b25e63",
        "#b35e44",
        "#0046f7",
        "#68585e",
        "#0062fb",
        "#576ca7",
        "#2b2748",
        "#a97dcd",
        "#795678",
        "#af5cd7",
        "#677950",
        "#8d4b4c",
        "#438fa0",
        "#b156d5",
        "#b75d4e",
        "#63648f",
        "#7f3d8f",
        "#56758e",
        "#725a5b",
        "#85a1b8",
        "#78a09d",
        "#1d1a37",
        "#689298",
        "#6d71a7",
        "#7c7a68",
        "#715d5e",
        "#9f7553",
        "#5c418d",
        "#649b90",
        "#894b53",
        "#946639",
        "#71bc8c",
        "#84608a",
        "#67988a",
        "#8a6a7d",
        "#8b7347",
        "#b15d5a",
        "#6a5278",
        "#2e312b",
        "#8f615d",
        "#be6a40",
        "#9a563f",
        "#be8139",
        "#586eba",
        "#3b8b8a"
      ],
      "inactivity": false,
      "messagesArray": [],
      "payload": {
        "hasInput": true,
        "port": {
          "success": { "color": "#0C3249" },
        }
      },

      "title": "Conteúdo"
    }
  },

  {
    "_id": {
      "$oid": "658470e8171e9d4e9912914"
    },
    "name": "Mensagem ativa",
    "description": "Envie uma mensagem ativa para o contato",
    "type": "activeMessage",
    "category": "action",
    "icon": "activeMessageIcon",
    "default": true,
    "data": {
      "messagesArray": [],
      "maxRetries":1,
      "payload": {
        "hasInput": true,
        "port": {
          "error": {
            "color": "#EF5350"
          },
          "success": {
            "color": "#5FD07E"
          }
        }
      },
      "title": "Mensagem ativa"
    }
  },
  {
    "_id": {
      "$oid": "658470e8171e9d4e9912914"
    },
    "name": "Intervalo de tempo",
    "description": "Adicione um intervalo de tempo ao seu fluxo",
    "type": "timeInterval",
    "category": "action",
    "icon": "timeIntervalIcon",
    "default": true,
    "data": {
      "timezone":"America/Sao_Paulo",
      "timeIntervals": [
        {
          "daysOfWeek": [
            "sunday",
            "monday",
            "tuesday",
            "wednesday",
            "thursday",
            "friday",
            "saturday"
          ],
          "from": "00:00",
          "until": "23:59",
         
        }
      ],
      "maxRetries":1,
      "payload": {
        "hasInput": true,
        "port": {
          "error": {
            "color": "#EF5350"
          },
          "success": {
            "color": "#5FD07E"
          }
        }
      },
      "title": "Intervalo de tempo"
    }
  },

  {
    "_id": {
      "$oid": "630277b02fc3f454446633bd"
    },
    "name": "Salvar mensagem",
    "description": "Salvar mensagem do usuário",
    "type": "saveMessage",
    "category": "action",
    "icon": "saveMessageIcon",
    "default": true,
    "data": {
      "payload": {
        "hasInput": true,
        "next": true,
        "port": {
          "next": {
            "color": "#0C3249"
          }
        }
      },
      "next": true,
      "title": "Salvar mensagem",
    }
  },
  {
    "_id": {
      "$oid": "6303c8562fc3f4544408d0b5"
    },
    "name": "Randomizador",
    "description": "Randomização de fluxo de atendimento",
    "type": "split",
    "category": "action",
    "icon": "splitIcon",
    "default": true,
    "data": {
      "payload": {
        "hasInput": true,
        "port": {
          "c1": {
            "color": "#0C3249"
          },
          "c2": {
            "color": "#995beb"
          },
        }
      },
      "conditions": [
        {
          "id": "c1",
          "percent": 50,
        },
        {
          "id": "c2",
          "percent": 50,
        }
      ],
      "colors": [
        "#0C3249",
        "#995beb",
        "#6093a8",
        "#645d72",
        "#759ba9",
        "#b25e63",
        "#b35e44",
        "#0046f7",
        "#68585e",
        "#0062fb",
        "#576ca7",
        "#2b2748",
        "#a97dcd",
        "#795678",
        "#af5cd7",
        "#677950",
        "#8d4b4c",
        "#438fa0",
        "#b156d5",
        "#b75d4e",
        "#63648f",
        "#7f3d8f",
        "#56758e",
        "#725a5b",
        "#85a1b8",
        "#78a09d",
        "#1d1a37",
        "#689298",
        "#6d71a7",
        "#7c7a68",
        "#715d5e",
        "#9f7553",
        "#5c418d",
        "#649b90",
        "#894b53",
        "#946639",
        "#71bc8c",
        "#84608a",
        "#67988a",
        "#8a6a7d",
        "#8b7347",
        "#b15d5a",
        "#6a5278",
        "#2e312b",
        "#8f615d",
        "#be6a40",
        "#9a563f",
        "#be8139",
        "#586eba",
        "#3b8b8a"
      ],
      "title": "Randomizador"
    }
  },

  {
    "_id": {
      "$oid": "658470e8171e9d49e9912913"
    },
    "name": "Transferir atendimento",
    "description": "Transfira o atendimento para outro agente ou departamento",
    "type": "ticketTransfer",
    "category": "action",
    "icon": "updateTicketIcon",
    "default": true,
    "data": {
      "payload": {
        "hasInput": true,
        "port": {
          "error": {
            "color": "#EF5350",
            "template": {
              "statusCode": 400,
              "body": {
                "message": "Error"
              }
            }
          },
          "success": {
            "color": "#5FD07E",
            "template": {
              "statusCode": 200,
              "body": {
                "name": "Resultado"
              }
            }
          }
        }
      },
      "title": "Transferir atendimento"
    }
  },
  {
    "_id": {
      "$oid": "658470e8171e9d49e9912912"
    },
    "name": "Encerrar atendimento",
    "description": "Encerre o atendimento",
    "type": "closeTicket",
    "category": "action",
    "icon": "closeBrowserIcon",
    "default": true,
    "data": {
      "payload": {
        "hasInput": true,
        "dontChange": true,
        "port": {
          "next": {
            "color": "#26C6DA"
          }
        }
      },
      "title": "Encerrar atendimento"
    }
  },

  {
    "_id": {
      "$oid": "658470e8171e9d49e9912914"
    },
    "name": "Etiqueta",
    "description": "Adicione uma etiqueta ao atendimento",
    "type": "tagTicket",
    "category": "action",
    "icon": "tagTicketIcon",
    "default": true,
    "data": {
      "payload": {
        "hasInput": true,
        "port": {
          "next": {
            "color": "#26C6DA"
          }
        }
      },
      "title": "Etiqueta"
    }
  },
  {
    "_id": {
      "$oid": "635817c2843d7a07066c229a"
    },
    "name": "Ativar fluxo",
    "description": "Ativar outro fluxo",
    "type": "jump",
    "category": "action",
    "icon": "jumpIcon",
    "default": true,
    "data": {
      "payload": {
        "port": {},
        "hasInput": true
      },
      "flow": null,
      "data": "",
      "returnOptions": false,
      "title": "Ativar fluxo"
    }
  },

  {
    "_id": {
      "$oid": "630277b02fc3f454446633bd"
    },
    "name": "Variável",
    "description": "Salvar uma variável de fluxo",
    "type": "flowVariable",
    "category": "action",
    "icon": "flowVariableIcon",
    "default": true,
    "data": {
      "payload": {
        "hasInput": true,
        "next": true,
        "port": {
          "next": {
            "color": "#0C3249"
          }
        }
      },
      "next": true,
      "title": "Variável",
      "data": "{\n  \"variable\": \"value\"\n}"
    }
  },
  {
    "_id": {
      "$oid": "658470e8171e9d49e9912912"
    },
    "name": "Fim de fluxo",
    "description": "Encerrar o fluxo",
    "type": "endFlow",
    "category": "action",
    "icon": "endFlowIcon",
    "default": true,
    "data": {
      "payload": {
        "hasInput": true,
        "dontChange": true,
        "port": {
        }
      },
      "title": "Fim de fluxo"
    }
  },
  {
    "_id": {
      "$oid": "637f7d3aba06a5b87132c03a"
    },
    "name": "GraphQL",
    "description": "Execute uma requisição Graphql",
    "type": "graphql",
    "category": "integration",
    "icon": "graphqlIcon",
    "default": true,
    "data": {
      "payload": {
        "hasInput": true,
        "port": {
          "error": {
            "color": "#EF5350"
          },
          "success": {
            "color": "#5FD07E"
          }
        }
      },
      "method": "GET",
      "title": "Graphql 01"
    }
  },
  

  {
    "_id": {
      "$oid": "637518c18749ad00779caa9b"
    },
    "name": "Banco de dados",
    "description": "Execute uma query no banco de dados",
    "type": "dbQuery",
    "category": "integration",
    "icon": "dbQueryIcon",
    "default": true,
    "data": {
      "payload": {
        "hasInput": true,
        "port": {
          "error": {
            "color": "#EF5350"
          },
          "success": {
            "color": "#5FD07E"
          }
        }
      },
      "dbType": null,
      "encrypt": false,
      "dbConfigs": {
        "dbName": null,
        "dbPort": null,
        "dbHost": null,
        "dbUser": null,
        "dbPassword": null
      },
      "query": null,
      "title": "Banco de dados"
    }
  },


  {
    "_id": {
      "$oid": "6303c8562fc3f4544408d0b5"
    },
    "name": "Condição",
    "description": "Verifique uma condição",
    "type": "conditional",
    "category": "integration",
    "icon": "conditionalIcon",
    "default": true,
    "data": {
      "payload": {
        "hasInput": true,
        "port": {
          "c1": {
            "color": "#0C3249"
          },
          "else": {
            "color": "#EF5350"
          }
        }
      },
      "conditions": [
        {
          "id": "c1",
          "left": "",
          "comparator": "eq",
          "right": ""
        }
      ],
      "colors": [
        "#0C3249",
        "#995beb",
        "#6093a8",
        "#645d72",
        "#759ba9",
        "#b25e63",
        "#b35e44",
        "#0046f7",
        "#68585e",
        "#0062fb",
        "#576ca7",
        "#2b2748",
        "#a97dcd",
        "#795678",
        "#af5cd7",
        "#677950",
        "#8d4b4c",
        "#438fa0",
        "#b156d5",
        "#b75d4e",
        "#63648f",
        "#7f3d8f",
        "#56758e",
        "#725a5b",
        "#85a1b8",
        "#78a09d",
        "#1d1a37",
        "#689298",
        "#6d71a7",
        "#7c7a68",
        "#715d5e",
        "#9f7553",
        "#5c418d",
        "#649b90",
        "#894b53",
        "#946639",
        "#71bc8c",
        "#84608a",
        "#67988a",
        "#8a6a7d",
        "#8b7347",
        "#b15d5a",
        "#6a5278",
        "#2e312b",
        "#8f615d",
        "#be6a40",
        "#9a563f",
        "#be8139",
        "#586eba",
        "#3b8b8a"
      ],
      "title": "Condição"
    }
  },

  {
    "_id": {
      "$oid": "65708c0c1bb1b0f6342ab9c1"
    },
    "name": "Tarefa",
    "description": "Inicia uma tarefa automática",
    "type": "schedule",
    "category": "integration",
    "icon": "scheduleIcon",
    "default": true,
    "data": {
      "payload": {
        "hasInput": true,
        "next": true,
        "port": {
          "next": {
            "color": "#26C6DA"
          }
        }
      },
      "scheduleAt": "",
      "daysOfWeek": [
        "sunday",
        "monday",
        "tuesday",
        "wednesday",
        "thursday",
        "friday",
        "saturday"
      ],
      "title": "Tarefa",
      "timezone": "America/Sao_Paulo"
    }
  },
  {
    "_id": {
      "$oid": "630277332fc3f4544465f1ee"
    },
    "name": "Código Javascript",
    "description": "Execute um código Javascript",
    "type": "code",
    "category": "integration",
    "icon": "runCodeIcon",
    "default": true,
    "data": {
      "payload": {
        "hasInput": true,
        "port": {
          "error": {
            "color": "#EF5350"
          },
          "success": {
            "color": "#5FD07E"
          }
        }
      },
      "title": "Code",
      "code": "const moment = require('moment')\nconst request = require('request')\nconst lodash = require('lodash')\n\nmodule.exports = async (input, flow) => { \n  // Execute o código JS. E capture o valor retornado.\n}\n"
    }
  },
  
  {
    "_id": {
      "$oid": "657b4751dd424dfbffe55d3b"
    },
    "name": "Aguardar",
    "description": "Aguarde um tempo no fluxo",
    "type": "waitTime",
    "category": "integration",
    "icon": "waitTimeIcon",
    "default": true,
    "data": {
      "payload": {
        "hasInput": true,
        "next": true,
        "port": {
          "next": {
            "color": "#0C3249"
          }
        }
      },
      "next": true,
      "title": "Aguardar"
    }
  },
  {
    "_id": {
      "$oid": "630260da2fc3f4544459f760"
    },
    "name": "Requisição HTTP",
    "description": "Requisição HTTP",
    "type": "request",
    "category": "integration",
    "icon": "requestIcon",
    "default": true,
    "data": {
      "payload": {
        "hasInput": true,
        "port": {
          "error": {
            "color": "#EF5350",
            "template": {
              "statusCode": 400,
              "body": {
                "message": "Error"
              }
            }
          },
          "success": {
            "color": "#5FD07E",
            "template": {
              "statusCode": 200,
              "body": {
                "name": "Lucas"
              }
            }
          }
        }
      },
      "retryable": false,
      "method": "GET",
      "title": "Requisição 01"
    }
  },


  {
    "_id": {
      "$oid": "657b46acdd424dfbffe55d3a"
    },
    "name": "JSON para XLSX",
    "description": "Converta um JSON para XLSX",
    "type": "jsonToXlsx",
    "category": "integration",
    "icon": "jsonToXlsxIcon",
    "default": true,
    "data": {
      "payload": {
        "hasInput": true,
        "port": {
          "error": {
            "color": "#EF5350"
          },
          "success": {
            "color": "#5FD07E"
          }
        }
      },
      "title": "JSON para XLSX"
    }
  },

  {
    "_id": {
      "$oid": "6302754d2fc3f4544464ec6a"
    },
    "name": "Resposta JSON",
    "description": "Retorna uma resposta JSON de encerramento",
    "type": "json",
    "category": "integration",
    "icon": "jsonIcon",
    "default": true,
    "data": {
      "payload": {
        "hasInput": true
      },
      "title": "Resposta JSON",
      "statusCode": "200",
      "headers": "",
      "body": ""
    }
  },
 
  {
    "_id": {
      "$oid": "658470e8171e9d49e9912911"
    },
    "name": "SMTP",
    "description": "Envio de e-mail SMTP",
    "type": "smtp",
    "category": "integration",
    "icon": "smtpIcon",
    "default": true,
    "data": {
      "payload": {
        "hasInput": true,
        "port": {
          "error": {
            "color": "#EF5350",
            "template": {
              "statusCode": 400,
              "body": {
                "message": "Error"
              }
            }
          },
          "success": {
            "color": "#5FD07E",
            "template": {
              "statusCode": 200,
              "body": {
                "name": "Resultado"
              }
            }
          }
        }
      },
      "title": "SMTP 01"
    }
  },

  // {
  //   "_id": {
  //     "$oid": "65940f7f7e138bc2ffad38c0"
  //   },
  //   "name": "Iniciar Loop",
  //   "description": "Inicia uma loop no fluxo",
  //   "type": "startLoop",
  //   "category": "flow",
  //   "icon": "startLoopIcon",
  //   "default": true,
  //   "data": {
  //     "payload": {
  //       "hasInput": true,
  //       "port": {
  //         "error": {
  //           "color": "#EF5350"
  //         },
  //         "success": {
  //           "color": "#5FD07E"
  //         }
  //       }
  //     },
  //     "title": "Iniciar Loop"
  //   }
  // },
  // {
  //   "_id": {
  //     "$oid": "65940fa57e138bc2ffad38c1"
  //   },
  //   "name": "Controle de loop",
  //   "description": "Controla o loop no fluxo",
  //   "type": "loopControl",
  //   "category": "flow",
  //   "icon": "loopControlIcon",
  //   "default": true,
  //   "data": {
  //     "payload": {
  //       "hasInput": true,
  //       "port": {}
  //     },
  //     "title": "Controle de loop"
  //   }
  // },
  // {
  //   "_id": {
  //     "$oid": "65940fc57e138bc2ffad38c2"
  //   },
  //   "name": "Final do loop",
  //   "description": "Final do loop no fluxo",
  //   "type": "endLoop",
  //   "category": "flow",
  //   "icon": "endLoopIcon",
  //   "default": true,
  //   "data": {
  //     "payload": {
  //       "hasInput": true,
  //       "port": {
  //         "next": {
  //           "color": "#0C3249"
  //         }
  //       }
  //     },
  //     "title": "Final loop"
  //   }
  // },
]