import React, { useState, useEffect, useRef } from "react";

import * as Yup from "yup";
import { Formik, Form } from "formik";
import { toast } from "react-toastify";
import { Input, Modal, Button } from "antd";

import { green } from "@material-ui/core/colors";
import DownloadIcon from "@material-ui/icons/GetApp";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import DialogActions from "@material-ui/core/DialogActions";
import { Divider, Stack, Typography, Grid } from "@mui/material";
import CircularProgress from "@material-ui/core/CircularProgress";

import planilhaExemplo from "../../assets/planilha-importacao.xlsx";

import api from "../../services/api";
import UserSelect from "../UserSelect";
import { i18n } from "../../translate/i18n";
import toastError from "../../errors/toastError";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginRight: theme.spacing(1),
    flex: 1,
  },
  extraAttr: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  btnWrapper: {
    position: "relative",
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

const ContactGroupCreateSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Muito curto!")
    .max(50, "Muito longo!")
    .required("Esse campo é obrigatório"),
  file: Yup.mixed().required("Selecione uma planilha com os contatos"),
});

const ContactGroupEditSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Muito curto!")
    .max(50, "Muito longo!")
    .required("Esse campo é obrigatório"),
});

const ContactGroupModal = ({ open, onClose, groupId, initialValues }) => {
  const classes = useStyles();
  const isMounted = useRef(true);
  const [formKey, setFormKey] = useState(0);
  const [file, setFile] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const initialState = {
    name: "",
    file: null,
  };

  const [contactGroup, setContactGroup] = useState(initialState);

  const resetForm = () => {
    setFormKey((prevKey) => prevKey + 1);
  };

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    const fetchContact = async () => {
      if (initialValues) {
        setContactGroup((prevState) => {
          return { ...prevState, ...initialValues };
        });
      }

      if (!groupId) return;

      try {
        const { data } = await api.get(`/contact-groups/${groupId}`);

        if (isMounted.current) {
          setSelectedUser(data?.userId);
          setContactGroup({ ...data, file: null });
        }
      } catch (err) {
        toastError(err);
      }
    };

    fetchContact();
  }, [groupId, open, initialValues]);

  const handleClose = () => {
    onClose();
    setSelectedUser(null);
    setContactGroup(initialState);
    setFile(null);
    resetForm();
  };

  const handleSaveContact = async (values) => {
    try {
      if (groupId) {
        await api.put(`/contact-groups/${groupId}`, {
          name: values["name"],
          userId: selectedUser || null,
        });

        toast.success(i18n.t("contactGroupModal.editSuccess"));

        handleClose();
      } else {
        const formData = new FormData();

        formData.append("file", file);
        formData.append("name", values["name"]);

        if (selectedUser) {
          formData.append("userId", `${selectedUser}`);
        }

        await api.post("/contact-groups", formData);

        toast.success(i18n.t("contactGroupModal.addSuccess"));

        handleClose();
      }
    } catch (err) {
      toastError(err);
    }
  };

  const renderTitle = () => {
    return (
      <Stack>
        <Typography variant="h6" sx={{ ml: 1 }}>
          {groupId ? `Editar ${contactGroup.name}` : `Adicionar Importação`}
        </Typography>
        <Typography variant="caption" sx={{ ml: 1 }}>
          {groupId
            ? `ID: ${groupId}`
            : `Adicione uma nova importação de contatos`}
        </Typography>
      </Stack>
    );
  };

  return (
    <Modal
      title={renderTitle()}
      open={open}
      onCancel={handleClose}
      footer={null}
    >
      <Divider sx={{ my: 1 }} />
      <Stack p={1}>
        <Formik
          key={formKey}
          initialValues={contactGroup}
          enableReinitialize={true}
          validationSchema={
            !groupId ? ContactGroupCreateSchema : ContactGroupEditSchema
          }
          onSubmit={(values, actions) => {
            setTimeout(() => {
              handleSaveContact(values);
              actions.setSubmitting(false);
            }, 400);
          }}
        >
          {({
            values,
            errors,
            touched,
            isSubmitting,
            handleChange,
            handleSubmit,
          }) => (
            <Form>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Stack>
                    <Typography variant="caption" color="gray">
                      Nome*
                    </Typography>
                    <Input
                      size="large"
                      id="name"
                      value={values.name}
                      onChange={handleChange}
                      name="name"
                      placeholder="Nome da importação"
                    />
                    {touched.name && !!errors.name && (
                      <Typography variant="caption" color="red">
                        {errors.name}
                      </Typography>
                    )}
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <Stack>
                    <Typography variant="caption" color="gray">
                      Responsável
                    </Typography>
                    <UserSelect
                      selectedUserId={selectedUser}
                      onChange={setSelectedUser}
                    />
                  </Stack>
                </Grid>
                {!groupId && (
                  <Grid item xs={12}>
                    <Stack>
                      <Typography variant="caption" color="gray">
                        Planilha de contatos*
                      </Typography>
                      <Input
                        type="file"
                        accept=".xls,.xlsx"
                        size="large"
                        id="file"
                        value={values.file}
                        onChange={(ev) => {
                          handleChange(ev);

                          const file = ev.currentTarget?.files?.[0];

                          setFile(file || null);
                        }}
                        name="file"
                        placeholder="Contatos da importação"
                      />
                      {touched.file && !!errors.file && (
                        <Typography variant="caption" color="red">
                          {errors.file}
                        </Typography>
                      )}
                    </Stack>
                  </Grid>
                )}
              </Grid>
              <DialogActions style={{ marginTop: 10 }}>
                <div
                  style={{
                    display: "flex",
                    flexGrow: 1,
                  }}
                >
                  <Button
                    color="secondary"
                    disabled={isSubmitting}
                    variant="contained"
                    style={{ paddingTop: "0px", paddingLeft: "4px" }}
                  >
                    <a
                      href={planilhaExemplo}
                      download="planilha-importação.xlsx"
                    >
                      <IconButton size="small" title="Baixar Planilha Exemplo">
                        <DownloadIcon />
                      </IconButton>
                      Baixar exemplo
                    </a>
                  </Button>
                </div>
                <Button
                  onClick={handleClose}
                  color="secondary"
                  disabled={isSubmitting}
                  variant="contained"
                >
                  {i18n.t("contactGroupModal.buttons.cancel")}
                </Button>
                <Button
                  type="primary"
                  color="primary"
                  onClick={handleSubmit}
                  disabled={isSubmitting}
                  variant="contained"
                  className={classes.btnWrapper}
                >
                  {groupId
                    ? `${i18n.t("contactGroupModal.buttons.okEdit")}`
                    : `${i18n.t("contactGroupModal.buttons.okAdd")}`}
                  {isSubmitting && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Stack>
    </Modal>
  );
};

export default ContactGroupModal;
