import React, { useState, useEffect, useContext } from "react";

import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";

import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";
import { IconButton, InputAdornment } from "@material-ui/core";
import { Input, Modal, Button } from "antd";
import { Divider, Stack, Typography, Grid } from '@mui/material';
import { ColorPicker } from 'antd';
import { DeleteOutline } from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap"
  },
  multFieldLine: {
    display: "flex",
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(1)
    }
  },

  btnWrapper: {
    position: "relative"
  },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  colorAdorment: {
    width: 20,
    height: 20
  }
}));

const TagSchema = Yup.object().shape({
  name: Yup.string().min(3, "Mensagem muito curta").required("Obrigatório")
});

const BoardModal = ({ open, onClose, tagId, reload, onTicketUpdate }) => {
  const classes = useStyles();
  const { user } = useContext(AuthContext);
  const [colorPickerModalOpen, setColorPickerModalOpen] = useState(false);
  const [color, setColor] = useState("#0F69E7");
  const [values, setValues] = useState({
    name: "",
    color: "#0F69E7",
    columns: []
  })




  const handleClose = () => {
    setValues({
      name: "",
      color: "#0F69E7",
      columns: []
    })
    setColorPickerModalOpen(false);
    onClose();
  };

  const handleChangeColor = (color) => {
    let hexColor
    try {
      hexColor = color.toHexString();
    } catch (error) {

    }

    setColor(hexColor);
    setValues({ ...values, color: hexColor })
  }
  const handleSaveTag = async () => {
    try {
      const { data } = await api.post("/boards", {
        ...values,
        columns: JSON.stringify(values.columns)
      });
      toast.success("Quadro criado com sucesso!");
      if (typeof reload == "function") {
        reload();
      }
    } catch (err) {
      toastError(err);
    }
    handleClose();
  };

  const handleChangeMessages = (e, index) => {
    values.columns[index].name = e.target.value;
    setValues({ ...values });
  };

  const handleChange = (e) => {
    values[e.target.name] = e.target.value
    setValues({ ...values })
  }

  const renderTitle = () => {
    return <Stack   >
      <Typography variant="h6" sx={{ ml: 1 }}>
        {tagId ? `Editar quadro` : `Novo quadro`}
      </Typography>
      <Typography variant="caption" sx={{ ml: 1 }}>
        {tagId ? `Edite o quadro` : `Adicione um novo quadro`}
      </Typography>
    </Stack>
  }

  const handleAddMessage = type => {
    values.columns.push({
      name: ""
    });
    setValues({ ...values });
  };

  const handleDeleteMessage = index => {
    values.columns.splice(index, 1);
    setValues({ ...values });
  };

  return (

    <Modal
      title={renderTitle()}
      open={open}
      onCancel={handleClose}
      onOk={handleSaveTag}
      okText="Salvar"
      cancelText="Cancelar"
    >
           <Stack sx={{
        maxHeight: "60vh",
        overflowY: "auto"
      }}>
      <Divider sx={{ my: 1 }} />
      <Stack p={1} >

        <Grid container spacing={1} sx={{ p: 1 }}>
          <Grid item xs>
            <Stack >
              <Typography variant="caption" color="gray">
                Nome*
              </Typography>
              <Input
                size="large"
                id="name"
                value={values.name}
                onChange={handleChange}
                name="name"
                placeholder="Nome do quadro"
              />
            </Stack>
          </Grid>


          <Grid item xs={1.5}>
            <Stack >
              <Typography variant="caption" color="gray">
                Cor*
              </Typography>
              <ColorPicker size="large" onChange={handleChangeColor} value={color} />
            </Stack>
          </Grid>

          {values.columns.length > 0 && (
            <Grid item xs={12} sx={{
              padding: 1,
              backgroundColor: "#f5f5f5",
              marginTop: 2,
              margin: 1,
              borderRadius: 2

            }}>

              <Grid item xs={12}>
                <Typography variant="caption" color="gray">
                  Colunas*
                </Typography>
                <Divider sx={{
                  mb: 1
                }} />
              </Grid>
              {values.columns.map((column, index) => (
                <>

                  <Grid item xs={12}>
                    <Stack>
                      <Typography variant="caption" color="gray">
                        Nome da coluna*
                      </Typography>
                      <Stack direction="row" alignItems="center" spacing={1}>

                        <Input
                          size="large"
                          id="name"
                          value={column.name}
                          onChange={(e) => handleChangeMessages(e, index)}
                          name="name"
                          placeholder="Nome da coluna"
                        />
                        <IconButton onClick={() => handleDeleteMessage(index)}>
                          <DeleteOutline />
                        </IconButton>
                      </Stack>
                    </Stack>
                  </Grid>
                  {index < values.columns.length - 1 && (
                    <Grid item xs={12}>
                      <Divider sx={{
                        mt: 1,
                        mb: 1
                      }} />
                    </Grid>
                  )}

                </>
              ))}
            </Grid>
          )}




        </Grid>



      </Stack>

      <Stack justifyContent="center" alignItems="center" sx={{
        mt: -1
      }}>


        <Button
          type="dashed"
          onClick={handleAddMessage}
          style={{
            width: "300px"
          }}
        >
          Adicionar coluna +
        </Button>
      </Stack>
      <Stack>
        <Divider sx={{
          mt: 2
        }} />
      </Stack>
      </Stack>
    </Modal>
  );
};

export default BoardModal;