import React, { useEffect, useReducer, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../../context/Auth/AuthContext";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { TBody, THead, Table, TCell, TRow } from "../../components/Table";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import Title from "../../components/Title";
import SubscriptionModal from "../../components/SubscriptionModal";
import api from "../../services/api";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import {
  Stack,
  Typography,
  Chip,
  Tooltip,
  IconButton,
  Divider
} from "@mui/material";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import { Button } from "antd";
import toastError from "../../errors/toastError";

import moment from "moment";
import { FaFileInvoice, FaPage4, FaRegFilePdf } from "react-icons/fa";
import SelectPlanModal from "../../components/SelectPlanModal";
import CreateCardModal from "../../components/CreateCardModal";

const reducer = (state, action) => {
  if (action.type === "LOAD_INVOICES") {
    const invoices = action.payload;
    const newUsers = [];

    invoices.forEach(user => {
      const userIndex = state.findIndex(u => u.id === user.id);
      if (userIndex !== -1) {
        state[userIndex] = user;
      } else {
        newUsers.push(user);
      }
    });

    return [...state, ...newUsers];
  }

  if (action.type === "UPDATE_USERS") {
    const user = action.payload;
    const userIndex = state.findIndex(u => u.id === user.id);

    if (userIndex !== -1) {
      state[userIndex] = user;
      return [...state];
    } else {
      return [user, ...state];
    }
  }

  if (action.type === "DELETE_USER") {
    const userId = action.payload;

    const userIndex = state.findIndex(u => u.id === userId);
    if (userIndex !== -1) {
      state.splice(userIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const useStyles = makeStyles(theme => ({
  mainPaper: {
    flex: 1,
    backgroundColor: "#fff",
    overflowY: "hidden",
    borderRadius: "10px",
    ...theme.scrollbarStyles
  }
}));

const Invoices = () => {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [searchParam] = useState("");
  const [invoices, dispatch] = useReducer(reducer, []);
  const [storagePlans, setStoragePlans] = React.useState([]);
  const [createCreditCardModal, setCreateCreditCardModal] = useState(false);
  const [contactModalOpen, setContactModalOpen] = useState(false);
  const [method, setMethod] = useState(null);
  const [company, setCompany] = useState(null);

  const { user } = useContext(AuthContext);
  const history = useHistory();
  if (user.profile !== "admin") {
    history.push("/tickets");
  }

  const handleOpenContactModal = method => {
    setMethod(method);
    setContactModalOpen(true);
  };

  const handleCloseContactModal = () => {
    setContactModalOpen(false);
  };
  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [searchParam]);

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchInvoices = async () => {
        try {
          const { data } = await api.get("/getinvoices");
          const { data: companyData } = await api.get("/getcompany");
          setCompany(companyData);
          dispatch({ type: "LOAD_INVOICES", payload: data });
          setHasMore(false);
          setLoading(false);
        } catch (err) {
          toastError(err);
        }
      };
      fetchInvoices();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchParam, pageNumber]);

  const loadMore = () => {
    setPageNumber(prevState => prevState + 1);
  };

  const handleScroll = e => {
    if (!hasMore || loading) return;
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      loadMore();
    }
  };

  const renderStatus = status => {
    switch (status) {
      case "REFUNDED":
        return <Chip label="ESTORNADO" color="error" />;
      case "DONE":
        return <Chip label="PAGO" color="success" />;
        case "CONFIRMED":
          return <Chip label="CONFIRMADO" color="success" />;
      case "CANCELLED":
        return <Chip label="CANCELADO" color="error" />;
      case "PENDING":
        return <Chip label="PENDENTE" color="warning" />;
      case "AWAITING_CREDIT":
        return <Chip label="AGUARDANDO CRÉDITO" color="warning" />;
      case "REFUSED":
        return <Chip label="RECUSADO" color="error" />;
      case "RECEIVED":
        return <Chip label="CONFIRMADO" color="success" />;
    }
  };

  const getInvoiceMethods = method => {
    switch (method) {
      case "CREDIT_CARD":
        return "CARTÃO DE CRÉDITO";
      case "BOLETO":
        return "BOLETO";
      case "PIX":
        return "PIX";
      default:
        return "NÃO DEFINIDO";
    }
  };

  const getPlanColor = plan => {
    switch (plan) {
      case "FREE":
        return "black";
      case "BASIC":
        return "#0C3249";
      case "PRO":
        return "#915EFF";
      case "ESSENTIAL":
        return "#04A477";
      default:
        return "#E07941";
    }
  };

  return (
    <MainContainer>
      <SelectPlanModal
        open={contactModalOpen}
        onClose={handleCloseContactModal}
        plan={company?.plan}
        method={method}
      ></SelectPlanModal>
      <CreateCardModal
        open={createCreditCardModal}
        onClose={() => setCreateCreditCardModal(false)}
        plan={company?.plan}
        method={method}
      ></CreateCardModal>
      <Paper
        className={classes.mainPaper}
        variant="outlined"
        onScroll={handleScroll}
      >
        <MainHeader>
          <Stack direction="row" spacing={3} sx={{ mb: -2 }}>
            <Stack>
              <Typography
                variant="h5"
                color="black"
                style={{
                  fontWeight: "bold",
                  marginLeft: "10px",
                  marginTop: "10px"
                }}
                gutterBottom
              >
                Planos e Faturas
              </Typography>
              <Typography
                style={{ marginTop: "-10px", marginLeft: "10px" }}
                variant="caption"
                color="textSecondary"
              >
                Gerencie seus planos e faturas.
              </Typography>
            </Stack>
            {company?.plan?.id && (
              <Stack
                sx={{
                  p: 1
                }}
              >
                <Stack
                  sx={{
                    backgroundColor: "#fafafa",
                    borderRadius: "10px",
                    p: 1
                  }}
                >
                  <Typography variant="caption" color="textSecondary">
                    Plano atual:
                  </Typography>
                  <Chip
                    label={["FREE", "BASIC", "PRO", "ESSENTIAL"].includes(company?.plan?.name) ? company?.plan?.name : "ENTERPRISE"}
                    size="small"
                    sx={{
                      backgroundColor: getPlanColor(company?.plan?.name),
                      color: "#fff"
                    }}
                  />
                </Stack>
              </Stack>
            )}
          </Stack>
          <MainHeaderButtonsWrapper>
            {["FREE", "BASIC", "PRO", "ESSENTIAL"].includes(company?.plan?.name) ? (
              <>
              {company?.plan?.name === "FREE" ? (
              <Button
                size="large"
                type="primary"
                onClick={() => handleOpenContactModal("create")}
              >
                Contratar plano
              </Button>
            ) : (
              <Button
                size="large"
                type="primary"
                onClick={() => handleOpenContactModal("upgrade")}
              >
                Alterar plano
              </Button>
            )}
              </>
            ) : null}
            
          </MainHeaderButtonsWrapper>
        </MainHeader>
        <Stack
          style={{
            padding: "20px",
            backgroundColor: "#fff",
            borderRadius: "5px",
            height: "93%",
            overflowY: "auto"
          }}
        >
          <Paper
            style={{
              boxShadow: "none",
              backgroundColor: "#fff"
            }}
          >
            <Table
              size="small"
              sx={{
                backgroundColor: "#fff"
              }}
            >
              <THead sticky="true">
                <TRow>
                  <TCell style={{ color: "#fff" }} align="center">
                    Data
                  </TCell>
                  <TCell style={{ color: "#fff" }} align="center">
                    Identificador
                  </TCell>

                  <TCell style={{ color: "#fff" }} align="center">
                    Método de pagamento
                  </TCell>
                  <TCell style={{ color: "#fff" }} align="center">
                    Valor
                  </TCell>
                  <TCell style={{ color: "#fff" }} align="center">
                    Descrição
                  </TCell>
                  <TCell style={{ color: "#fff" }} align="center">
                    Status
                  </TCell>
                  <TCell style={{ color: "#fff" }} align="center">
                    Ações
                  </TCell>
                </TRow>
              </THead>
              <TBody>
                <>
                  {invoices.map(invoices => (
                    <TRow key={invoices.id}>
                      <TCell align="center">
                        {moment(invoices.dueDate).format("DD/MM/YYYY")}
                      </TCell>
                      <TCell align="center">{invoices.id}</TCell>
                      <TCell align="center">
                        {invoices.billingType === "CREDIT_CARD" ? (
                          <Tooltip
                            title={`${invoices?.creditCard?.creditCardBrand} - ${invoices?.creditCard?.creditCardNumber}`}
                            placement="top"
                          >
                            <Chip
                              label={
                                <Stack direction="row">
                                  <Typography
                                    variant="caption"
                                    color="textSecondary"
                                  >
                                    {getInvoiceMethods(invoices.billingType)}
                                  </Typography>
                                </Stack>
                              }
                            />
                          </Tooltip>
                        ) : (
                          <Chip
                            label={
                              <Stack direction="row">
                                <Typography
                                  variant="caption"
                                  color="textSecondary"
                                >
                                  {getInvoiceMethods(invoices.billingType)}
                                </Typography>
                              </Stack>
                            }
                          />
                        )}
                      </TCell>
                      <TCell style={{ fontWeight: "bold" }} align="center">
                        {invoices.value.toLocaleString("pt-br", {
                          style: "currency",
                          currency: "BRL"
                        })}
                      </TCell>
                      <TCell align="center">{invoices.description}</TCell>
                      <TCell style={{ fontWeight: "bold" }} align="center">
                        {renderStatus(invoices?.status)}
                      </TCell>
                      <TCell align="center">
                        <Stack
                          direction="row"
                          spacing={2}
                          justifyContent="center"
                        >
                          <Tooltip title="Visualizar fatura" placement="top">
                            <IconButton
                              onClick={() => {
                                window.open(invoices.invoiceUrl, "_blank");
                              }}
                              size="small"
                            >
                              <FaFileInvoice />
                            </IconButton>
                          </Tooltip>
                          {invoices?.transactionReceiptUrl && (
                            <Tooltip
                              title="Visualizar comprovante"
                              placement="top"
                            >
                              <IconButton
                                onClick={() => {
                                  window.open(
                                    invoices.transactionReceiptUrl,
                                    "_blank"
                                  );
                                }}
                                size="small"
                              >
                                <FaRegFilePdf />
                              </IconButton>
                            </Tooltip>
                          )}
                        </Stack>
                      </TCell>
                    </TRow>
                  ))}
                  {loading && <TableRowSkeleton columns={4} />}
                </>
              </TBody>
            </Table>
          </Paper>
        </Stack>
      </Paper>
    </MainContainer>
  );
};

export default Invoices;
