import styled from 'styled-components'
import Bg from '../../assets/bg_login.svg'
import { LoadingButton } from '@mui/lab'

export const Background = styled.img.attrs({
  src: Bg,
})`
  height: 100%;
  display: block;
  max-width: 100%;
`

export const LoginButton = styled(LoadingButton)``
