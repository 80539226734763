import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Chip from "@material-ui/core/Chip";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import { Select } from "antd";
import { Stack,Box,Typography } from "@mui/material"
import { AuthContext } from "../../context/Auth/AuthContext";
const useStyles = makeStyles(theme => ({
	chips: {
		display: "flex",
		flexWrap: "wrap",
	},
	chip: {
		margin: 2,
	},
}));

const QueueSelectSingle = ({ selectedQueueIds, onChange, queues}) => {
	const classes = useStyles();
	const [queuesData, setQueuesData] = useState([])
	
	useEffect(() => {
		(async () => {
			try {

				let data = queues

				if(queues) {
					data = queues
				}else{
					const res = await api.get("/queue");
					data = res.data
				}



				const queuesInfos = data.map((item) => {
					return {
						value: item.id,
						label: <Stack direction="row" spacing={1}  alignItems="center" mt={0.5} mr={1}>
							<Box sx={{
							
								width: '15px',
								height: '15px',
								borderRadius: '50%',
								backgroundColor: item.color,
							}}>
		
							</Box>
							<Typography variant="body2" sx={{ fontWeight: 500 }}>
								-
							</Typography>
							<Typography variant="body2" sx={{ fontWeight: 500 }}>
								{item?.name}
							</Typography>
						</Stack>,
					}
				})
				setQueuesData(queuesInfos);
			} catch (err) {
				toastError(err);
			}
		})();
	}, [queues]);

	const handleChange = e => {
		onChange(e.target.value);
	};

	const filterOption = (input, option) => {
        //filter by tag name
        return option.label.props.children[2].props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
       }

	return (
		<Select
			showSearch
			placeholder="Selecione um departamento"
			id="queue"
			allowClear
			filterOption={filterOption}
			optionFilterProp="children"
			onChange={onChange}
			value={selectedQueueIds}
			options={queuesData}
			size="large"
			style={{ width: '100%' }}
		/>
	);
};

export default QueueSelectSingle;
