import React, {
  useState,
  useEffect,
  useReducer,
  useContext,
  useRef,
} from "react";

import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import { TBody, THead, Table, TCell, TRow } from "../../../../components/Table";

import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Avatar from "@material-ui/core/Avatar";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import Search from "antd/es/input/Search";
import { Button } from "antd";
import IconButton from "@material-ui/core/IconButton";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditIcon from "@material-ui/icons/Edit";

import api from "../../../../services/api";
import TRowSkeleton from "../../../../components/TableRowSkeleton";
import ContactModal from "../../../../components/ContactModal";
import ConfirmationModal from "../../../../components/ConfirmationModal/";
import ContactListItemModal from "../../../../components/ContactListItemModal";

import ConfirmationModalImport from "../../../../components/ConfirmationModal/";
import planilhaExemplo from "../../../../assets/planilha.xlsx";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import { CloudDownload, PhoneAndroid } from "@material-ui/icons";
import { i18n } from "../../../../translate/i18n";
import MainHeader from "../../../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../../../components/MainHeaderButtonsWrapper";
import MainContainer from "../../../../components/MainContainer";
import toastError from "../../../../errors/toastError";
import { AuthContext } from "../../../../context/Auth/AuthContext";
import { Can } from "../../../../components/Can";
import NewTicketModal from "../../../../components/NewTicketModal";
import { socketConnection } from "../../../../services/socket";
import { Box, Stack, Tooltip, Typography } from "@mui/material";
import ContactWalletModal from "../../../../components/ContactWalletModal";

const reducer = (state, action) => {
  if (action.type === "LOAD_CONTACTS") {
    const contacts = action.payload;
    const newContacts = [];

    contacts.forEach((contact) => {
      const contactIndex = state.findIndex((c) => c.id === contact.id);
      if (contactIndex !== -1) {
        state[contactIndex] = contact;
      } else {
        newContacts.push(contact);
      }
    });

    return [...state, ...newContacts];
  }

  if (action.type === "UPDATE_CONTACTS") {
    const contact = action.payload;
    const contactIndex = state.findIndex((c) => c.id === contact.id);

    if (contactIndex !== -1) {
      state[contactIndex] = contact;
      return [...state];
    } else {
      return [contact, ...state];
    }
  }

  if (action.type === "DELETE_CONTACT") {
    const contactId = action.payload;
    const contactIndex = state.findIndex((c) => c.id === contactId);
    if (contactIndex !== -1) {
      state.splice(contactIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    backgroundColor: "#fff",
    overflowY: "hidden",
    borderRadius: "10px",
    ...theme.scrollbarStyles,
  },
}));

const Wallet = () => {
  const classes = useStyles();
  const history = useHistory();

  const { user } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchParam, setSearchParam] = useState("");
  const [contactListItemModalOpen, setContactListItemModalOpen] =
    useState(false);
  const [contacts, dispatch] = useReducer(reducer, []);
  const [selectedContactId, setSelectedContactId] = useState(null);
  const [contactModalOpen, setContactModalOpen] = useState(false);
  const [newTicketModalOpen, setNewTicketModalOpen] = useState(false);
  const [contactTicket, setContactTicket] = useState({});
  const [deletingContact, setDeletingContact] = useState(null);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [importContactModal, setImportContactModal] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const fileUploadRef = useRef(null);
  const [countContact, setCountContact] = useState(0);

  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [searchParam]);

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchContacts = async () => {
        try {
          const { data } = await api.get("/contactwallet/", {
            params: { searchParam, pageNumber },
          });
          dispatch({ type: "LOAD_CONTACTS", payload: data.contacts });
          setHasMore(data.hasMore);
          setCountContact(data.count);
          setLoading(false);
        } catch (err) {
          toastError(err);
        }
      };
      fetchContacts();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchParam, pageNumber]);

  useEffect(() => {
    const companyId = localStorage.getItem("companyId");
    const socket = socketConnection({ companyId });

    socket.on(`company-${companyId}-contact-wallet`, (data) => {
      if (data.action === "update" || data.action === "create") {
        if (data?.type && data?.type === "list") {
          for (let i in data.contacts) {
            dispatch({ type: "UPDATE_CONTACTS", payload: data.contacts[i] });
          }
          toast.success(
            i18n.t(`${data?.contacts?.length} contatos importados!`)
          );
        } else {
          dispatch({ type: "UPDATE_CONTACTS", payload: data.contact });
        }
      }

      if (data.action === "delete") {
        dispatch({ type: "DELETE_CONTACT", payload: +data.contactId });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  const handleSearch = (event) => {
    setSearchParam(event.target.value.toLowerCase());
  };

  const handleImportContacts = async () => {
    try {
      const formData = new FormData();
      formData.append("file", fileUploadRef.current.files[0]);
      await api.request({
        url: `contact-lists/upload-plan`,
        method: "POST",
        data: formData,
      });
    } catch (err) {
      toastError(err);
    }
  };
  const handleOpenContactModal = () => {
    setSelectedContactId(null);
    setContactModalOpen(true);
  };

  const handleCloseContactModal = () => {
    setSelectedContactId(null);
    setContactModalOpen(false);
  };

  // const handleSaveTicket = async contactId => {
  // 	if (!contactId) return;
  // 	setLoading(true);
  // 	try {
  // 		const { data: ticket } = await api.post("/tickets", {
  // 			contactId: contactId,
  // 			userId: user?.id,
  // 			status: "open",
  // 		});
  // 		history.push(`/tickets/${ticket.id}`);
  // 	} catch (err) {
  // 		toastError(err);
  // 	}
  // 	setLoading(false);
  // };

  const handleCloseOrOpenTicket = (ticket) => {
    setNewTicketModalOpen(false);
    if (ticket !== undefined && ticket.uuid !== undefined) {
      history.push(`/tickets/${ticket.uuid}`);
    }
  };

  const hadleEditContact = (contactId) => {
    setSelectedContactId(contactId);
    setContactModalOpen(true);
  };

  const handleDeleteContact = async (contactId) => {
    try {
      await api.delete(`/contactwallet/${contactId}`);
      toast.success(i18n.t("Carteira removida com sucesso!"));
    } catch (err) {
      toastError(err);
    }
    setDeletingContact(null);
    setSearchParam("");
    setPageNumber(1);
  };

  const handleimportContact = async () => {
    try {
      await api.post("/contactwallet/import");
      history.go(0);
    } catch (err) {
      toastError(err);
    }
  };

  const loadMore = () => {
    setPageNumber((prevState) => prevState + 1);
  };

  const handleScroll = (e) => {
    if (!hasMore || loading) return;
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      loadMore();
    }
  };
  const handleCloseContactListItemModal = () => {
    setSelectedContactId(null);
    setContactListItemModalOpen(false);
  };

  return (
    <MainContainer className={classes.mainContainer}>
      <NewTicketModal
        modalOpen={newTicketModalOpen}
        initialContact={contactTicket}
        onClose={(ticket) => {
          handleCloseOrOpenTicket(ticket);
        }}
      />
      {/* <ContactListItemModal
        open={contactListItemModalOpen}
        onClose={handleCloseContactListItemModal}
        aria-labelledby="form-dialog-title"
        contactId={selectedContactId}
      /> */}

      <ContactWalletModal
        open={contactModalOpen}
        onClose={handleCloseContactModal}
        aria-labelledby="form-dialog-title"
        contactId={selectedContactId}
      />
      <ConfirmationModal
        title={
          deletingContact
            ? `${i18n.t("contacts.confirmationModal.deleteTitle")} ${
                deletingContact?.name
              }?`
            : `${i18n.t("contacts.confirmationModal.importTitlte")}`
        }
        open={confirmOpen}
        onClose={setConfirmOpen}
        onConfirm={(e) =>
          deletingContact
            ? handleDeleteContact(deletingContact.id)
            : handleimportContact()
        }
      >
        {deletingContact
          ? `${i18n.t("contacts.confirmationModal.deleteMessage")}`
          : `${i18n.t("contacts.confirmationModal.importMessage")}`}
      </ConfirmationModal>

      <ConfirmationModalImport
        title={
          deletingContact
            ? `${i18n.t("contactListItems.confirmationModal.deleteTitle")} ${
                deletingContact?.name
              }?`
            : `${i18n.t("contactListItems.confirmationModal.importTitlte")}`
        }
        open={importContactModal}
        onClose={setImportContactModal}
        onConfirm={() =>
          deletingContact
            ? handleDeleteContact(deletingContact.id)
            : handleImportContacts()
        }
      >
        {deletingContact ? (
          `${i18n.t("contactListItems.confirmationModal.deleteMessage")}`
        ) : (
          <>
            {i18n.t("contactListItems.confirmationModal.importMessage")}
            <a href={planilhaExemplo} download="planilha.xlsx">
              Clique aqui para baixar planilha exemplo.
            </a>
          </>
        )}
      </ConfirmationModalImport>
      <Paper
        className={classes.mainPaper}
        variant="outlined"
        onScroll={handleScroll}
      >
        <MainHeader>
          <Stack>
            <Typography
              variant="h5"
              color="black"
              style={{
                fontWeight: "bold",
                marginLeft: "10px",
                marginTop: "10px",
              }}
              gutterBottom
            >
              {i18n.t("Carteiras")} ({countContact})
            </Typography>
            <Typography
              style={{ marginTop: "-10px", marginLeft: "10px" }}
              variant="caption"
              color="textSecondary"
            >
              Crie ou gerencie suas carteiras de contatos.
            </Typography>
          </Stack>
          <MainHeaderButtonsWrapper>
            <Search
              placeholder={i18n.t("Buscar")}
              size="large"
              type="search"
              value={searchParam}
              onChange={handleSearch}
              style={{
                width: "300px",
                marginRight: "10px",
                marginLeft: "10px",
              }}
            />

       

            <Button
              size="large"
              type="primary"
              onClick={handleOpenContactModal}
            >
              Criar carteira
            </Button>
          </MainHeaderButtonsWrapper>
        </MainHeader>
        <Stack
          style={{
            padding: "20px",
            backgroundColor: "#fff",
            borderRadius: "5px",
            height: "93%",
            overflowY: "auto",
          }}
        >
          <Paper
            style={{
              boxShadow: "none",
              backgroundColor: "#fff",
            }}
          >
            <>
              <input
                style={{ display: "none" }}
                id="upload"
                name="file"
                type="file"
                accept=".xls,.xlsx"
                onChange={() => {
                  setImportContactModal(true);
                }}
                ref={fileUploadRef}
              />
            </>
            <Table
              size="small"
              sx={{
                backgroundColor: "#fff",
              }}
            >
              <THead sticky="true">
                <TRow style={{ color: "#fff" }}>
                  <TCell
                    component="th"
                    style={{ color: "#fff" }}
                    align="center"
                  >
                    Nome
                  </TCell>
                  <TCell component="th" style={{ color: "#fff" }} noWrap>
                    Cor
                  </TCell>
                  <TCell component="th" style={{ color: "#fff" }} noWrap>
                    Responsável
                  </TCell>
                  <TCell component="th" style={{ color: "#fff"}} sx={{
                    paddingRight: "40px"
                  
                  }}  align="right">
                    {i18n.t("contacts.table.actions")}
                  </TCell>
                </TRow>
              </THead>

              <TBody>
                {contacts.map((contact) => (
                  <TRow
                    key={contact.id}
                    sx={{ position: "relative", zIndex: 1 }}
                  >
                    <TCell align="center">
                    {contact.name}
                    </TCell>
                    <TCell>
                      <Box sx={{
                        height: "20px",
                        width: "20px",
                        borderRadius: "50%",
                        backgroundColor: contact.color,
                      }}/>
                    </TCell>
                    <TCell>{contact?.owner?.name}</TCell>
                    <TCell align="right">
                    
                      <IconButton
                        size="small"
                        onClick={() => hadleEditContact(contact.id)}
                      >
                        <EditIcon />
                      </IconButton>
                      <Can
                        role={user.profile}
                        perform="contacts-page:deleteContact"
                        yes={() => (
                          <IconButton
                            size="small"
                            onClick={(e) => {
                              setConfirmOpen(true);
                              setDeletingContact(contact);
                            }}
                          >
                            <DeleteOutlineIcon />
                          </IconButton>
                        )}
                      />
                    </TCell>
                  </TRow>
                ))}
                {loading && <TRowSkeleton columns={4} />}
              </TBody>
            </Table>
          </Paper>
        </Stack>
      </Paper>
    </MainContainer>
  );
};

export default Wallet;
