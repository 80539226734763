import React, { useEffect, useState } from "react";

import { Stack, Typography } from "@mui/material";

import toastError from "../../errors/toastError";

import api from "../../services/api";

const ConnectionGroupSelect = ({ onAdd }) => {
  const [groups, setGroups] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const { data } = await api.get("/connectionGroups");

        setGroups(data);
      } catch (err) {
        toastError(err);
      }
    })();
  }, []);

  return (
    !!groups?.length &&
    groups.map((group) => (
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        mt={1}
        mr={1}
        style={{ cursor: "pointer" }}
        onClick={() => onAdd(group)}
      >
        <Typography variant="body2" sx={{ fontWeight: 500 }}>
          {group.name}
        </Typography>
        <Typography variant="body2" sx={{ fontWeight: 500 }}>
          -
        </Typography>
        <Typography variant="body2" sx={{ fontWeight: 700 }}>
          {group.childrens?.length || 0} Conexões
        </Typography>
      </Stack>
    ))
  );
};

export default ConnectionGroupSelect;
