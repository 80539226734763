import React, { useState, useEffect, useContext } from "react";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import Title from "../../components/Title";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import { makeStyles, Paper } from "@material-ui/core";


import { Button } from "antd";
import QuickMessagesTable from "../../components/QuickMessagesTable";
import QuickMessageDialog from "../../components/QuickMessageDialog";
import ConfirmationModal from "../../components/ConfirmationModal";
import { Stack, Typography } from "@mui/material";
import { i18n } from "../../translate/i18n";
import { toast } from "react-toastify";

import useQuickMessages from "../../hooks/useQuickMessages";
import { AuthContext } from "../../context/Auth/AuthContext";

const useStyles = makeStyles(theme => ({
  mainPaper: {
    flex: 1,
    overflowY: "hidden",
    borderRadius: "10px",
    backgroundColor: "#fff",
    ...theme.scrollbarStyles
  }
}));

function QuickMessages(props) {
  const classes = useStyles();
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [messageSelected, setMessageSelected] = useState({});
  const [showOnDeleteDialog, setShowOnDeleteDialog] = useState(false);

  const {
    list: listMessages,
    save: saveMessage,
    update: updateMessage,
    deleteRecord: deleteMessage
  } = useQuickMessages();

  const { user } = useContext(AuthContext);

  useEffect(() => {
    const fetchData = async () => {
      await loadingQuickMessages();
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadingQuickMessages = async () => {
    setLoading(true);
    try {
      const companyId = localStorage.getItem("companyId");
      const messages = await listMessages({ companyId, userId: user.id });
      setMessages(messages);
    } catch (e) {
      toast.error(e);
    }
    setLoading(false);
  };

  const handleOpenToAdd = () => {
    setModalOpen(true);
  };

  const handleOpenToEdit = message => {
    setMessageSelected(message);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setMessageSelected({ id: null, message: "", shortcode: "" });
  };

  const handleSave = async message => {
    handleCloseModal();
    try {
      await saveMessage(message);
      await loadingQuickMessages();
      toast.success("Messagem adicionada com sucesso.");
    } catch (e) {
      toast.error(e);
    }
  };

  const handleEdit = async message => {
    handleCloseModal();
    try {
      await updateMessage(message);
      await loadingQuickMessages();
      toast.success("Messagem atualizada com sucesso.");
    } catch (e) {
      toast.error(e);
    }
  };

  const handleDelete = async message => {
    handleCloseModal();
    try {
      await deleteMessage(message.id);
      await loadingQuickMessages();
      toast.success("Messagem excluída com sucesso.");
    } catch (e) {
      toast.error(e);
    }
  };

  return (
    <MainContainer className={classes.mainContainer}>
      <Paper className={classes.mainPaper} variant="outlined">
        <MainHeader>
          <Stack>
            <Typography
              variant="h5"
              color="black"
              style={{
                fontWeight: "bold",
                marginLeft: "10px",
                marginTop: "10px"
              }}
              gutterBottom
            >
              {i18n.t("quickMessages.title")}
            </Typography>
            <Typography
              style={{ marginTop: "-10px", marginLeft: "10px" }}
              variant="caption"
              color="textSecondary"
            >
              Crie mensagens rápidas para serem utilizadas em atendimentos.
            </Typography>
          </Stack>
          <MainHeaderButtonsWrapper>
            <Button

              size="large"
              type="primary"
              onClick={handleOpenToAdd}
            >
              Criar mensagem
            </Button>
          </MainHeaderButtonsWrapper>
        </MainHeader>
        <Stack
          style={{
            padding: "20px",
            backgroundColor: "#fff",
            borderRadius: "5px",
            height: "93%",
            overflowY: "auto",
          }}
        >
          <Paper style={{
            boxShadow: "none",
            backgroundColor: "#fff",
            marginTop: 10
          }} >
            <QuickMessagesTable
              readOnly={false}
              messages={messages}
              showLoading={loading}
              editMessage={handleOpenToEdit}
              deleteMessage={message => {
                setMessageSelected(message);
                setShowOnDeleteDialog(true);
              }}
            />
          </Paper>
        </Stack>
      </Paper>
      <QuickMessageDialog
        messageSelected={messageSelected}
        modalOpen={modalOpen}
        onClose={handleCloseModal}
        editMessage={handleEdit}
        saveMessage={handleSave}
      />
      <ConfirmationModal
        title="Excluir Registro"
        open={showOnDeleteDialog}
        onClose={setShowOnDeleteDialog}
        onConfirm={async () => {
          await handleDelete(messageSelected);
        }}
      >
        Deseja realmente excluir este registro?
      </ConfirmationModal>
    </MainContainer>
  );
}

export default QuickMessages;
