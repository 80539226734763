export const ConditionalData = {
  hasInput: true,
  // port: {
  //   error: {
  //     color: "#EF5350",
  //   },
  //   success: {
  //     color: "#5FD07E",
  //   },
  // },
  category: {
    color: "#5FD07E",
  },
  statusCode: 200,
  type: "json",
  method: 'GET',
  title: "Conditional",
  label: "json node",
}

export const availableConditions = [{
  label: "Igual", value: "eq"
}, {
  label: "Diferente", value: "ne"
}, {
  label: "Maior", value: "gt"
}, {
  label: "Maior ou igual", value: "gte"
}, {
  label: "Menor", value: "lt"
}, {
  label: "Menor ou igual", value: "lte"
}, {
  label: "Existe", value: "exists"
}, {
  label: "Não existe", value: "notExists"
}, {
  label: "Não contém", value: "contains"
}, {
  label: "Contém", value: "notContains"
},
{
  label: "Inicia com", value: "startsWith"
}
]

export const conditionsLabel = {
  eq: "=",
  ne: "!=",
  gt: ">",
  gte: ">=",
  lt: "<",
  lte: "<=",
  exists: "Exists",
  notExists: "Not exists",
  contains: "Contains",
  notContains: "Not contains"
}

export default ConditionalData
