import { Chip, Divider, Grid, IconButton, Paper, Stack, Typography } from "@mui/material"
import { Box } from "@mui/system"
import { Position, NodeProps } from "react-flow-renderer"
import { Container, HandleContainer, Handle, Tag } from "./styled"
import Node from "../../StyleNodes/Node"
export { WaitTimeMenu } from "./menu"

const handleStyle = { left: 10 }

const WaitTimeNode = (node) => {
  const { data } = node


  return (
    <Node node={node} >
      <Grid
        container
        style={{ position: "relative" }}
        spacing={1}
        px={2}
        alignItems="center"
      >
       
          <Grid item sx={{ mb: 1, mt:1 }} justifyContent="flex-end" display="flex" alignItems="right">
            <Stack direction="row" alignItems="right" spacing={1} justifyContent="flex-end">
              <Chip color="info" label={`${data?.waitTime ? data?.waitTime : "0"} segundos`} />
            </Stack>

          </Grid>
    
        <Grid item>
          <HandleContainer>
            <Handle
              type="source"
              position={Position.Right}
              id="next"
              color={data.payload?.port.next.color}
            />

          </HandleContainer>
        </Grid>
      </Grid>
    </Node>
  )
}

export default WaitTimeNode
