import React, { useState, useContext, useEffect } from "react";
import clsx from "clsx";

import {
  makeStyles,
  Drawer,
  AppBar,
  Toolbar,
  List,
  MenuItem,
  IconButton,
  Menu,
  useTheme,
  useMediaQuery,
  Box,
  Button
} from "@material-ui/core";
import Avatar from "@mui/material";
import { Stack, Grid, Popover, Divider, Typography } from "@mui/material";
import { Link, useHistory, useParams } from "react-router-dom";
import { ChevronLeftOutlined, ChevronRightOutlined } from "@mui/icons-material";

import AccountCircle from "@material-ui/icons/AccountCircle";
import QrCodeModalSupport from "../components/QrCodeModalSupport";
import MainListItems from "./MainListItems";
import NotificationsPopOver from "../components/NotificationsPopOver";
import UserModal from "../components/UserModal";
import { AuthContext } from "../context/Auth/AuthContext";
import BackdropLoading from "../components/BackdropLoading";
import { i18n } from "../translate/i18n";
import toastError from "../errors/toastError";
import AnnouncementsPopover from "../components/AnnouncementsPopover";
import { socketConnection } from "../services/socket";
import ChatPopover from "../pages/Chat/ChatPopover";
import logo from "../assets/logoCompact.svg";
import logoPequena from "../assets/logo.svg";
import TutorialModal from "./TutorialModal/Tutorial";

const drawerWidth = 300;

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    height: "100vh",
    [theme.breakpoints.down("sm")]: {
      height: "calc(100vh - 56px)"
    }
  },

  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "12px",
    minHeight: "48px"
  },
  toolbarIconShift: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "12px",

    minHeight: "48px"
  },
  appBar: {
    backgroundImage:
      "linear-gradient(to right bottom, #125fb9, #1462be, #1565c3, #1769c8, #0C3249)",
    boxShadow: "none",
    zIndex: 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },

  appBarShift: {
    marginLeft: drawerWidth,
    zIndex: 1,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginTop: 10,
    marginLeft: 3,
    color: "black"
  },
  menuButtonHidden: {
    display: "none"
  },
  title: {
    flexGrow: 1,
    fontSize: 14
  },
  drawerPaper: {
    zIndex: 1,
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerPaperClose: {
    zIndex: 1,
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9)
    }
  },
  appBarSpacer: {
    minHeight: "48px"
  },
  content: {
    flex: 1,
    overflow: "auto",
    marginTop: "35px"
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4)
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column"
  },
  containerWithScroll: {
    flex: 1,
    marginLeft: 1,
    marginRight: 1,
    overflowY: "scroll",
    overflow: "hidden"
  },
  company: {
    padding: theme.spacing(1),
    backgroundColor: "#fff",
    borderRadius: "10px",
    maxWidth: "260px",
    minWidth: "260px",
    minHeight: "80px",
    boxShadow:
      "rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 8%) 0px 0px 8px"
  },
  companyCollapse: {
    padding: "2px",
    backgroundColor: "#fff",
    borderRadius: "10px",
    minWidth: "40px",
    minHeight: "40px",
    boxShadow:
      "rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 8%) 0px 0px 8px"
  }
}));

const FlowLayout = ({ children }) => {
  const classes = useStyles();
  useEffect(() => {
    const companyId = localStorage.getItem("companyId");
    const userId = localStorage.getItem("userId");

    const socket = socketConnection({ companyId });

    socket.on(`company-${companyId}-auth`, data => {
      if (data.user.id === +userId) {
        toastError("Sua conta foi acessada em outro computador.");
        setTimeout(() => {
          localStorage.clear();
          window.location.reload();
        }, 1000);
      }
    });

    socket.emit("userStatus");
    const interval = setInterval(() => {
      socket.emit("userStatus");
    }, 1000 * 60 * 5);

    return () => {
      socket.disconnect();
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  return (
    <div className={classes.root}>
      <main className={classes.content}>
        {children ? children : null}
      </main>
    </div>
  );
};

export default FlowLayout;
