import React, {
  useState,
  useEffect,
  useReducer,
  useCallback,
  useContext,
} from "react";
import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import { Stack, Typography } from "@mui/material";
import AttachmentIcon from "@material-ui/icons/Attachment";
import { TBody, THead, Table, TCell, TRow } from "../../components/Table";

import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditIcon from "@material-ui/icons/Edit";
import { Button } from "antd";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import Search from "antd/es/input/Search"

import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import ScheduleModal from "../../components/ScheduleModal";
import ConfirmationModal from "../../components/ConfirmationModal";
import toastError from "../../errors/toastError";
import moment from "moment";
import { capitalize } from "lodash";
import { socketConnection } from "../../services/socket";
import { AuthContext } from "../../context/Auth/AuthContext";
import AttachmentModal from "../../components/AttachmentModal";

// A custom hook that builds on useLocation to parse
// the query string for you.
const getUrlParam = (param) => {
  return new URLSearchParams(window.location.search).get(param);
};

const reducer = (state, action) => {
  if (action.type === "LOAD_SCHEDULES") {
    const schedules = action.payload;
    const newSchedules = [];

    schedules.forEach((schedule) => {
      const scheduleIndex = state.findIndex((s) => s.id === schedule.id);
      if (scheduleIndex !== -1) {
        state[scheduleIndex] = schedule;
      } else {
        newSchedules.push(schedule);
      }
    });

    return [...state, ...newSchedules];
  }

  if (action.type === "UPDATE_SCHEDULES") {
    const schedule = action.payload;
    const scheduleIndex = state.findIndex((s) => s.id === schedule.id);

    if (scheduleIndex !== -1) {
      state[scheduleIndex] = schedule;
      return [...state];
    } else {
      return [schedule, ...state];
    }
  }

  if (action.type === "DELETE_SCHEDULE") {
    const scheduleId = action.payload;

    const scheduleIndex = state.findIndex((s) => s.id === scheduleId);
    if (scheduleIndex !== -1) {
      state.splice(scheduleIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    backgroundColor: "#fff",
    overflowY: "hidden",
    borderRadius: "10px",
    ...theme.scrollbarStyles,
  },
}));

const Schedules = () => {
  const classes = useStyles();

  const { user } = useContext(AuthContext);

  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [selectedSchedule, setSelectedSchedule] = useState(null);
  const [deletingSchedule, setDeletingSchedule] = useState(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [searchParam, setSearchParam] = useState("");
  const [schedules, dispatch] = useReducer(reducer, []);
  const [scheduleModalOpen, setScheduleModalOpen] = useState(false);
  const [contactId, setContactId] = useState(+getUrlParam("contactId"));
  const [attachmentModalOpen, setAttachmentModalOpen] = useState(false);
  const [attachment, setAttachment] = useState(null);


  const fetchSchedules = useCallback(async () => {
    try {
      const { data } = await api.get("/schedules/", {
        params: { searchParam, pageNumber },
      });
      dispatch({ type: "LOAD_SCHEDULES", payload: data.schedules });
      setHasMore(data.hasMore);
      setLoading(false);
    } catch (err) {
      toastError(err);
    }
  }, [searchParam, pageNumber]);

  const handleOpenScheduleModalFromContactId = useCallback(() => {
    if (contactId) {
      handleOpenScheduleModal();
    }
  }, [contactId]);

  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [searchParam]);

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      fetchSchedules();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [
    searchParam,
    pageNumber,
    contactId,
    fetchSchedules,
    handleOpenScheduleModalFromContactId,
  ]);

  useEffect(() => {
    handleOpenScheduleModalFromContactId();
    const socket = socketConnection({ companyId: user.companyId });

    socket.on("user", (data) => {
      if (data.action === "update" || data.action === "create") {
        dispatch({ type: "UPDATE_SCHEDULES", payload: data.schedules });
      }

      if (data.action === "delete") {
        dispatch({ type: "DELETE_USER", payload: +data.scheduleId });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, [handleOpenScheduleModalFromContactId, user]);

  const cleanContact = () => {
    setContactId("");
  };

  const handleOpenAttachmentModal = (attachment) => {
    setAttachment(attachment);
    setAttachmentModalOpen(true);
  };

  const handleCloseAttachmentModal = () => {
    setAttachment(null);
    setAttachmentModalOpen(false);
  };

  const handleOpenScheduleModal = () => {
    setSelectedSchedule(null);
    setScheduleModalOpen(true);
  };

  const handleCloseScheduleModal = () => {
    setSelectedSchedule(null);
    setScheduleModalOpen(false);
  };

  const handleSearch = (event) => {
    setSearchParam(event.target.value.toLowerCase());
  };

  const handleEditSchedule = (schedule) => {
    setSelectedSchedule(schedule);
    setScheduleModalOpen(true);
  };

  const handleDeleteSchedule = async (scheduleId) => {
    try {
      await api.delete(`/schedules/${scheduleId}`);
      toast.success(i18n.t("schedules.toasts.deleted"));
    } catch (err) {
      toastError(err);
    }
    setDeletingSchedule(null);
    setSearchParam("");
    setPageNumber(1);

    dispatch({ type: "RESET" });
    setPageNumber(1);
    await fetchSchedules();
  };

  const loadMore = () => {
    setPageNumber((prevState) => prevState + 1);
  };

  const handleScroll = (e) => {
    if (!hasMore || loading) return;
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      loadMore();
    }
  };

  const truncate = (str, len) => {
    if (str.length > len) {
      return str.substring(0, len) + "...";
    }
    return str;
  };

  return (
    <MainContainer>
      <ConfirmationModal
        title={
          deletingSchedule &&
          `${i18n.t("schedules.confirmationModal.deleteTitle")}`
        }
        open={confirmModalOpen}
        onClose={setConfirmModalOpen}
        onConfirm={() => handleDeleteSchedule(deletingSchedule.id)}
      >
        {i18n.t("schedules.confirmationModal.deleteMessage")}
      </ConfirmationModal>
      <ScheduleModal
        open={scheduleModalOpen}
        onClose={handleCloseScheduleModal}
        reload={fetchSchedules}
        scheduleData={selectedSchedule}
        aria-labelledby="form-dialog-title"
        scheduleId={selectedSchedule && selectedSchedule.id}
        contactId={contactId}
        cleanContact={cleanContact}
      />
      <Paper
        className={classes.mainPaper}
        variant="outlined"
        onScroll={handleScroll}
      >
        <MainHeader>
          <Stack>
            <Typography variant="h5" color="black" style={{ fontWeight: "bold", marginLeft: "10px", marginTop: "10px" }} gutterBottom>
              {i18n.t("schedules.title")}
            </Typography>
            <Typography style={{ marginTop: "-10px", marginLeft: "10px" }} variant="caption" color="textSecondary">
              Agende mensagens para serem enviadas automaticamente.
            </Typography>
          </Stack>

          <MainHeaderButtonsWrapper>
          <Search
              placeholder={i18n.t("Buscar")}
              size="large"
              type="search"
              value={searchParam}
              onChange={handleSearch}
              style={{
                width: "300px",
                marginRight: "10px",
                marginLeft: "10px",
              }}
            />

            <Button
              size="large"
              type="primary"
              onClick={handleOpenScheduleModal}
            >
              Novo agendamento
            </Button>
          </MainHeaderButtonsWrapper>
        </MainHeader>
        <AttachmentModal
          title={"Visualizar anexo"}
          open={attachmentModalOpen}
          onClose={handleCloseAttachmentModal}
          imgUrl={attachment}
        >
          {i18n.t("announcements.confirmationModal.deleteMessage")}
        </AttachmentModal>
        <Stack
          style={{
            padding: "20px",
            backgroundColor: "#fff",
            borderRadius: "5px",
            height: "93%",
            overflowY: "auto",
          }}
        >
          <Paper style={{
            boxShadow: "none",
            backgroundColor: "#fff",
            marginTop: "10px",
          }} >
            <Table size="small" sx={{
              backgroundColor: "#fff",
            }}>
              <THead sticky="true">

                <TRow>
                  <TCell style={{ color: "#fff" }} align="center">
                    {i18n.t("schedules.table.contact")}
                  </TCell>
                  <TCell style={{ color: "#fff" }} align="center">
                    {i18n.t("schedules.table.body")}
                  </TCell>
                  <TCell style={{ color: "#fff" }} align="center">
                    {i18n.t("schedules.table.sendAt")}
                  </TCell>
                  <TCell style={{ color: "#fff" }} align="center">
                    {i18n.t("schedules.table.status")}
                  </TCell>
                  <TCell style={{ color: "#fff" }} align="center">
                    {i18n.t("schedules.table.attachment")}
                  </TCell>
                  <TCell style={{ color: "#fff" }} align="center">
                    {i18n.t("schedules.table.actions")}
                  </TCell>
                </TRow>
              </THead>
              <TBody>
                <>
                  {schedules.map((schedule) => (
                    <TRow key={schedule.id}>
                      <TCell align="center">{schedule.contact?.name}</TCell>
                      <TCell align="center" title={schedule.body}>
                        {truncate(schedule.body, 25)}
                      </TCell>
                      <TCell align="center">
                        {moment(schedule.sendAt).format("DD/MM/YYYY HH:mm:ss")}
                      </TCell>
                      <TCell align="center">
                        {capitalize(schedule.status)}
                      </TCell>
                      {schedule?.mediaPath && schedule?.mediaPath !== "false" ? (
                        <TCell align="center">
                          <IconButton
                            size="small"
                            onClick={() => handleOpenAttachmentModal(schedule?.mediaPath)}
                          >
                            <AttachmentIcon />
                          </IconButton>
                          {/* <img src={process.env.REACT_APP_BACKEND_URL + "public/" + schedule?.mediaPath} width="60px" alt="attachment" /> */}
                        </TCell>
                      ) : (
                        <TCell align="center">

                        </TCell>
                      )}

                      <TCell align="center">
                        <IconButton
                          size="small"
                          onClick={() => handleEditSchedule(schedule)}
                        >
                          <EditIcon />
                        </IconButton>

                        <IconButton
                          size="small"
                          onClick={(e) => {
                            setConfirmModalOpen(true);
                            setDeletingSchedule(schedule);
                          }}
                        >
                          <DeleteOutlineIcon />
                        </IconButton>
                      </TCell>
                    </TRow>
                  ))}
                  {loading && <TableRowSkeleton columns={5} />}
                </>
              </TBody>
            </Table>
          </Paper>
        </Stack>
      </Paper>
    </MainContainer>
  );
};

export default Schedules;
