import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";

import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core";
import { Divider, Stack, Typography, Grid } from '@mui/material';

import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import UserSelect from "../UserSelect"
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import ButtonWithSpinner from "../ButtonWithSpinner";
import toastError from "../../errors/toastError";
import useQueues from "../../hooks/useQueues";
import { Modal, Button } from "antd";
import QueueSelectSingle from "../QueueSelectSingle";

const useStyles = makeStyles((theme) => ({
  maxWidth: {
    width: "100%",
  },
}));

const filterOptions = createFilterOptions({
  trim: true,
});

const TransferTicketModalCustom = ({ modalOpen, onClose, ticketid }) => {
  const history = useHistory();
  const [options, setOptions] = useState([]);
  const [queues, setQueues] = useState([]);
  const [allQueues, setAllQueues] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchParam, setSearchParam] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedQueue, setSelectedQueue] = useState("");
  const classes = useStyles();
  const { findAll: findAllQueues } = useQueues();
  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (isMounted.current) {
      const loadQueues = async () => {
        const list = await findAllQueues();
        setAllQueues(list);
        setQueues(list);
      };
      loadQueues();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!modalOpen || searchParam.length < 3) {
      setLoading(false);
      return;
    }
    const delayDebounceFn = setTimeout(() => {
      setLoading(true);
      const fetchUsers = async () => {
        try {
          const { data } = await api.get("/users/", {
            params: { searchParam },
          });
          setOptions(data.users);
          setLoading(false);
        } catch (err) {
          setLoading(false);
          toastError(err);
        }
      };

      fetchUsers();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchParam, modalOpen]);


  useEffect(()=>{
    if(!selectedUser) return
    handleLoadUser(selectedUser)
  },[selectedUser])

  const handleClose = () => {
    onClose();
    setSearchParam("");
    setSelectedUser(null);
  };

  const handleLoadUser = async (userId) => {
    setLoading(true)
    const userLoaded = await api.get(`/users/${userId}`)
    setQueues(userLoaded.data.queues)
    setLoading(false)
  }

  const handleSaveTicket = async (e) => {
    e.preventDefault();
    if (!ticketid) return;
    //if (!selectedQueue || selectedQueue === "") return;
    setLoading(true);
    try {
      let data = {};

      if (selectedUser) {
        data.userId = selectedUser;
      }

      if (selectedQueue && selectedQueue !== null) {
        data.queueId = selectedQueue;

        if (!selectedUser) {
          data.status = "pending";
          data.userId = null;
        }
      }

      await api.put(`/tickets/${ticketid}?method=transfer`, data);

      history.push(`/tickets`);
    } catch (err) {
      setLoading(false);
      toastError(err);
    }
  };

  const renderTitle = () => {
    return <Stack   >
      <Typography variant="h6" sx={{ ml: 1 }}>
        Transferir atendimento
      </Typography>
      <Typography variant="caption" sx={{ ml: 1 }}>
        Transfira um atendimento para um usuário ou departamento.
      </Typography>
    </Stack>
  }

  return (
    <Modal
      title={renderTitle()}
      open={modalOpen}
      onCancel={handleClose}
      footer={null}
    >
      <Divider sx={{ my: 1 }} />

  
        <Grid container spacing={1} p={1}>
          <Grid item xs={12}>
            <Stack >
              <Typography variant="caption" color="gray">
                Usuário
              </Typography>
              <UserSelect
                selectedUserId={selectedUser}
                onChange={setSelectedUser}
              />
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack >
              <Typography variant="caption" color="gray">
                Departamento
              </Typography>
              <QueueSelectSingle
                queues={queues}
                selectedQueueIds={selectedQueue}
                onChange={setSelectedQueue}
              />
            </Stack>
          </Grid>
        </Grid>

        <DialogActions>
          <Button
            onClick={handleClose}
            color="secondary"
            disabled={loading}
            variant="outlined"
          >
            {i18n.t("transferTicketModal.buttons.cancel")}
          </Button>
          <Button
            variant="contained"
            type="primary"
            onClick={handleSaveTicket}
            color="primary"
            loading={loading}
          >
            {i18n.t("transferTicketModal.buttons.ok")}
          </Button>
        </DialogActions>

    </Modal>
  );
};

export default TransferTicketModalCustom;