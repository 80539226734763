import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import { Divider, Stack, Typography, Grid } from '@mui/material';
import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import toastError from "../../errors/toastError";
import { Input, Modal, Button } from "antd";

const RecoveryPassModal = ({ open, onClose }) => {

  const [values, setValues] = useState({
    email: "",
  });
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    values[e.target.name] = e.target.value;
    setValues({ ...values });
  }

  const handleClose = () => {
    setValues({});
    onClose();
  }

  const handleSaveContact = async () => {
    setLoading(true);
    try {
     const { data } = await api.post(`/auth/recovery/${values?.email}`);
     setLoading(false);
      handleClose();
      toast.success(i18n.t("Você receberá um e-mail com as instruções para recuperação de senha."));
    } catch (err) {
      setLoading(false);
      toastError(err);
    }
  };


  const renderTitle = () => {
    return <Stack   >
      <Typography variant="h6" sx={{ ml: 1 }}>
        Recuperação de senha
      </Typography>
      <Typography variant="caption" sx={{ ml: 1 }}>
        Preencha os campos abaixo para recuperar sua senha
      </Typography>
    </Stack>
  }

  return (

    <Modal
      title={renderTitle()}
      open={open}
      onCancel={handleClose}
      onOk={handleSaveContact}
      okText="Confirmar"
      confirmLoading={loading}
      cancelText="Cancelar"
    >
      <Divider sx={{ my: 1 }} />
      <Stack p={1}>


        <Grid container spacing={1}>

          <Grid item xs={12}>
            <Stack >
              <Typography variant="caption" color="gray">
                Email
              </Typography>
              <Input
                size="large"
                id="email"
                type="email"
                value={values?.email}
                onChange={handleChange}
                name="email"
                placeholder="Email da conta"
              />
            </Stack>

          </Grid>

        </Grid>

      </Stack>
    </Modal>

  );
};

export default RecoveryPassModal;
