// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:where(.css-dev-only-do-not-override-xhz82s).ant-input-group .ant-input-group-addon {
    position: relative;
    padding: 0 11px;
    color: rgba(0, 0, 0, 0.88);
    font-weight: normal;
    font-size: 14px;
    text-align: center;
    background-color: #0C3249 !important;
    border: 1px solid #0C3249;
    border-radius: 6px;
    transition: all 0.3s;
    line-height: 1;
}


.ant-input-group-addon {
    background: #0C3249 !important;
    border: 1px solid #d9d9d9;
}

.item i { 
    color: #474747; 
    font-size: 1.13rem; 
    height: 20px;
} 
  
.item.dragging { 
    opacity: 0.6; 
    height: 20px !important;
    max-height: 20px !important;
} 

.sortable-list { 
    width: 425px; 
    background: none !important; 
} `, "",{"version":3,"sources":["webpack://./src/pages/Flow/Diagram/Builder/nodes/message/menu/style.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,eAAe;IACf,0BAA0B;IAC1B,mBAAmB;IACnB,eAAe;IACf,kBAAkB;IAClB,oCAAoC;IACpC,yBAAyB;IACzB,kBAAkB;IAClB,oBAAoB;IACpB,cAAc;AAClB;;;AAGA;IACI,8BAA8B;IAC9B,yBAAyB;AAC7B;;AAEA;IACI,cAAc;IACd,kBAAkB;IAClB,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,uBAAuB;IACvB,2BAA2B;AAC/B;;AAEA;IACI,YAAY;IACZ,2BAA2B;AAC/B","sourcesContent":[":where(.css-dev-only-do-not-override-xhz82s).ant-input-group .ant-input-group-addon {\n    position: relative;\n    padding: 0 11px;\n    color: rgba(0, 0, 0, 0.88);\n    font-weight: normal;\n    font-size: 14px;\n    text-align: center;\n    background-color: #0C3249 !important;\n    border: 1px solid #0C3249;\n    border-radius: 6px;\n    transition: all 0.3s;\n    line-height: 1;\n}\n\n\n.ant-input-group-addon {\n    background: #0C3249 !important;\n    border: 1px solid #d9d9d9;\n}\n\n.item i { \n    color: #474747; \n    font-size: 1.13rem; \n    height: 20px;\n} \n  \n.item.dragging { \n    opacity: 0.6; \n    height: 20px !important;\n    max-height: 20px !important;\n} \n\n.sortable-list { \n    width: 425px; \n    background: none !important; \n} "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
