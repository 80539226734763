import React, { useState, useEffect, useContext } from "react";
import { Input, Modal, Button, DatePicker } from "antd";
import { Divider, Stack, Typography, Grid, Box } from "@mui/material";
import Cards from "react-credit-cards-2";
import "react-credit-cards-2/dist/es/styles-compiled.css"
import toastError from "../../errors/toastError";
import api, { openApi } from "../../services/api";
import { AuthContext } from "../../context/Auth/AuthContext";
import { toast } from "react-toastify";
import { Bolt, Check, WhatsApp } from "@mui/icons-material";

import {
  formatCVC,
  formatCpfCnpj,
  formatCreditCardNumber,
  formatExpirationDate,
  formatFormData
} from "./utils";


const CreateCardModal = ({ open, onClose, plan, method }) => {
  const [focus, setFocus] = useState("");
  const [loading, setLoading] = useState(false);

	const { user } = useContext(AuthContext);
	const { companyId } = user;

  const [values, setValues] = useState({
    cvc: "",
    expiry: "",
    focus: "",
    name: "",
    number: "",
    cpfCnpj: "",
    address: "",
    complement: "",
    number: "",
    cep: "",
    email: ""
  });

  const getRemoteIp = async () => {
    const response = await fetch("https://api.ipify.org?format=json");
    const data = await response.json();
    return data.ip;
  };

  const renderTitle = () => {
    return (
      <Stack direction="row" spacing={2} alignItems="center">
        <Stack
          sx={{
            borderRadius: "50%",
            backgroundColor: "#E07941",
            height: "50px",
            width: "50px"
          }}
          justifyContent="center"
          alignItems="center"
        >
          <Bolt
            sx={{
              color: "#FFFFFF",
              fontSize: "40px"
            }}
          />
        </Stack>
        <Stack>
          <Typography variant="h5" fontWeight="bold">
            ENTERPRISE
          </Typography>
          <Typography
            variant="h6"
            sx={{ mt: -1 }}
            color="success.main"
            fontWeight="bold"
          >
            R$ {plan?.value}
          </Typography>
        </Stack>
      </Stack>
    );
  };

  const handleClose = () => {
  
    onClose();
  };


  const handleConfirm = () => {
    onClose();
    toast.success("Plano atualizado com sucesso!");
  };


  const handleSubmitPayment = async () => {
    // if(!company) return;
    if (!values.number) return toastError("Número do cartão inválido");
    if (!values.expiry) return toastError("Validade do cartão inválida");
    if (!values.cvc) return toastError("Código de segurança inválido");
    if (!values.name) return toastError("Nome do titular inválido");
    if (!values.email) return toastError("E-mail inválido");
    if (!values.cpfCnpj) return toastError("CPF/CNPJ inválido");
    if (!values.cep) return toastError("CEP inválido");
    if (!values.address) return toastError("Endereço inválido");
    if (!values.addressNumber) return toastError("Número do endereço inválido");
    const data = {
      companyId: companyId,
      ...values,
      ccv: values.cvc,
      holderName: values.name,
      planName: plan.name,
      planValue: plan.value,
      remoteIp: await getRemoteIp()
    };
    setLoading(true);
    try {
      
        const result = await openApi.post("/payment", data);
        setLoading(false);
        onClose();
        return toast.success("Plano atualizado com sucesso! Por favor, atualize a página.");
    
    } catch (error) {
      setLoading(false);
      if (error?.response?.data?.error) {
        return toastError(error?.response?.data?.error[0]?.description);
      }
    }
  };



  const handleChange = event => {
    const { name, value } = event.target;
    if (name === "number") values[name] = formatCreditCardNumber(value);
    if (name === "expiry") values[name] = formatExpirationDate(value);
    if (name === "cvc") values[name] = formatCVC(value);
    if (name === "cpfCnpj") {
      if (value.length > 18) return;
      values[name] = formatCpfCnpj(value);
    }
    if (name === "name") values[name] = value;
    if (name === "cep") values[name] = value;
    if (name === "address") values[name] = value;
    if (name === "addressNumber") values[name] = value;
    if (name === "addressComplement") values[name] = value;
    if (name === "email") values[name] = value;

    setValues({ ...values });
  };

 

  return (
    <Modal   
      open={open}
      onCancel={handleClose}
      onOk={handleSubmitPayment}
      okText="Confirmar"
      cancelText="Cancelar"
      confirmLoading={loading}
      title={renderTitle()}
      width="800px"
    >
 <Divider sx={{ my: 1 }} />
      <Stack direction="row" p={0}>
        <Stack sx={{ width: "50%" }} justifyContent="center">
          <Cards
            cvc={values.cvc}
            expiry={values.expiry}
            focused={focus}
            name={values.name}
            number={values.number}
          />
        </Stack>
        <Divider orientation="vertical" flexItem sx={{ mr: 2 }} />
        <Stack sx={{ width: "50%", maxHeight: "400px", overflowY: "scroll" }}>
          <Typography variant="h6" color="primary.main">
            Dados do cartão
          </Typography>
          <Stack sx={{ mr: 2 }} spacing={1}>
            <Stack>
              <Typography variant="caption" color="text.secondary">
                Número do cartão
              </Typography>
              <Input
                size="large"
                value={values.number}
                onChange={handleChange}
                name="number"
                autoFocus
                type="tel"
                pattern="[\d| ]{16,22}"
                onFocus={e => setFocus(e.target.name)}
              />
            </Stack>

            <Stack>
              <Typography variant="caption" color="text.secondary">
                Validade
              </Typography>
              <Input
                size="large"
                value={values.expiry}
                onChange={handleChange}
                name="expiry"
                pattern="\d\d/\d\d"
                onFocus={e => setFocus(e.target.name)}
              />
            </Stack>

            <Stack>
              <Typography variant="caption" color="text.secondary">
                Código de segurança
              </Typography>
              <Input
                size="large"
                value={values.cvc}
                onChange={handleChange}
                name="cvc"
                pattern="\d{3,4}"
                onFocus={e => setFocus(e.target.name)}
              />
            </Stack>

            <Divider sx={{ my: 1 }} />

            <Typography variant="h6" color="primary.main">
              Dados do titular
            </Typography>

            <Stack>
              <Typography variant="caption" color="text.secondary">
                Nome do titular
              </Typography>
              <Input
                size="large"
                value={values.name}
                onChange={handleChange}
                name="name"
                onFocus={e => setFocus(e.target.value)}
              />
            </Stack>

            <Stack>
              <Typography variant="caption" color="text.secondary">
                E-mail
              </Typography>
              <Input
                size="large"
                value={values.email}
                onChange={handleChange}
                name="email"
                type="email"
              />
            </Stack>

            <Stack>
              <Typography variant="caption" color="text.secondary">
                CPF/CNPJ
              </Typography>
              <Input
                size="large"
                value={values.cpfCnpj}
                onChange={handleChange}
                name="cpfCnpj"
              />
            </Stack>

            <Stack>
              <Typography variant="caption" color="text.secondary">
                CEP
              </Typography>
              <Input
                size="large"
                value={values.cep}
                onChange={handleChange}
                name="cep"
              />
            </Stack>
            <Stack>
              <Typography variant="caption" color="text.secondary">
                Endereço
              </Typography>
              <Input
                size="large"
                value={values.address}
                onChange={handleChange}
                name="address"
              />
            </Stack>
            <Stack direction="row" spacing={1}>
              <Stack>
                <Typography variant="caption" color="text.secondary">
                  Número
                </Typography>
                <Input
                  size="large"
                  value={values.addressNumber}
                  onChange={handleChange}
                  name="addressNumber"
                  type="number"
                />
              </Stack>
              <Stack>
                <Typography variant="caption" color="text.secondary">
                  Complemento
                </Typography>
                <Input
                  size="large"
                  value={values.addressComplement}
                  onChange={handleChange}
                  name="addressComplement"
                />
              </Stack>
            </Stack>
          </Stack>
          
        </Stack>
    
      </Stack>    
    </Modal>
  );
};

export default CreateCardModal;
