import { Chip, Divider, Grid, IconButton, Paper, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Position, NodeProps } from "react-flow-renderer";
import { Container, HandleContainer, Handle, Tag } from "./styled";
import Node from "../../StyleNodes/Node";

export { MessageMenu } from "./menu";
const handleStyle = { left: 10 };

const MessageNode = node => {
  const { data } = node;
  return (
    <Node node={node}>
      {data?.messagesArray[0]?.type === "Lista" ? (
        <>
          {data.messagesArray[0]?.listMessages?.map((it, index) => (
            <Grid
              container
              style={{ position: "relative" }}
              spacing={1}
              mt={0}
              px={2}
              alignItems="center"
            >
              <Grid item sx={{ mb: 1 }}>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Typography variant="caption" color="gray">
                    Opção - {it.option}
                  </Typography>
                  {it.messageOption && (
                    <Chip sx={{
                      backgroundColor: `${node.data.payload?.port[it.id].color}`,
                      ml: 1
                    }} size="small" label={<Stack direction="row">
                      <Typography variant="caption" color="white">
                        {it.messageOption.length > 20 ? it.messageOption.substring(0, 18) + "..." : it.messageOption}
                      </Typography>
                    </Stack>} />
                  )}

                </Stack>
              </Grid>

              <Grid item>
                <HandleContainer>
                  <Handle
                    type="source"
                    position={Position.Right}
                    id={it.id}
                    color={node.data.payload?.port[it.id].color}
                  />
                </HandleContainer>
              </Grid>
            </Grid>
          ))}
        </>
      ) : (
        <>
          <Grid
            container
            style={{ position: "relative", height: 50 }}
            spacing={1}
            px={2}
            alignItems="center"
          >
            <Grid item>
              {node?.data?.continueFlow ? (
                <Typography
                  color={node.data.payload?.port?.success?.color}
                  variant="caption"
                >

                  CONTINUAR FLUXO
                </Typography>
              ) : (
                <Typography
                  color={node.data.payload?.port?.success?.color}
                  variant="caption"
                >

                  AGUARDAR RESPOSTA
                </Typography>
              )}
            </Grid>
            <Grid item>
              <HandleContainer>
                <Handle
                  type="source"
                  position={Position.Right}
                  id="success"
                  color={node.data.payload?.port?.success?.color}
                />
              </HandleContainer>
            </Grid>
          </Grid>
        </>
      )}
      {node?.data?.payload?.port?.else && (
        <>
          <Grid
            container
            style={{ position: "relative", height: 10 }}
            px={0}
            spacing={1}
            alignItems="center"
          >
            <Grid item xs={12}>
              <Divider sx={{
                my: 1
              }} />
            </Grid>
          </Grid>
          <Grid
            container
            style={{ position: "relative", height: 50 }}
            px={2}
            mt={0.5}
            spacing={1}
            alignItems="center"
          >

            <Grid item>
              <Typography
                color={node.data.payload?.port?.else?.color}
                variant="caption"
                sx={{
                  mb: -1
                }}
              >
                APÓS {data?.maxRetries} TENTATIVAS
              </Typography>
            </Grid>

            <Grid item>
              <HandleContainer>
                <Handle
                  type="source"
                  position={Position.Right}
                  id="else"
                  color={node.data.payload?.port?.else?.color}
                />
              </HandleContainer>
            </Grid>
          </Grid>
        </>
      )}
      {node?.data?.payload?.port?.error && (
        <>
          <Grid
            container
            style={{ position: "relative", height: 10 }}
            px={0}
            spacing={1}
            mt={-2}
            mb={2}
            alignItems="center"
          >
            <Grid item xs={12}>
              <Divider sx={{
                my: 1
              }} />
            </Grid>
          </Grid>
        <Grid
          container
          style={{ position: "relative", height: 50 }}
          px={2}
          mt={-2}
          spacing={1}
          alignItems="center"
        >
          <Grid item>
            <Typography
              color={node.data.payload?.port?.error?.color}
              variant="caption"
            >
              {node?.data?.time} {"MINUTOS DE INATIVIDADE"}
            </Typography>
          </Grid>

          <Grid item>
            <HandleContainer>
              <Handle
                type="source"
                position={Position.Right}
                id="error"
                color={node.data.payload?.port?.error?.color}
              />
            </HandleContainer>
          </Grid>
        </Grid>
        </>
      )}

    </Node>
  );
};

export default MessageNode;
