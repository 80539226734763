import {
  Box,
  Divider,
  Stack,
  Typography,
} from "@mui/material"
import NodeMenu from "../../../StyleNodes/Menu"

import AceEditor from "react-ace"

import "ace-builds/src-noconflict/theme-github"
import "ace-builds/src-noconflict/mode-json"
import "ace-builds/src-noconflict/ext-language_tools"
import "ace-builds/src-noconflict/ext-beautify"

import React, { useRef, useState } from "react"
import { i18n } from "../../../../../../../translate/i18n";
import { Input, Select } from "antd"
import { renderIcons } from "../../../../Icons"
import TagSelect from "../../../../../../../components/TagSelect"

const handleStyle = { left: 10 }

export const SaveMessageMenu = ({ node, anchorRef }) => {
  const { data } = node
  const [anchorEl, setAnchorEl] = useState(null)
  const [values, setValues] = useState(data)
  const [openImport, setOpenImport] = useState(false)

  const handleRun = () => { }

  const handleChange = (data) => {
    values["messageType"] = data
    values["data"] = JSON.stringify({
      [data]: "{{input.message}}"
    })
    setValues({ ...values })
  }

  const options = [{
    value: "nome",
    label: "Nome completo",
  },{
    value: "primeiroNome",
    label: "Primeiro nome",
  } ,{
    value: "sobrenome",
    label: "Sobrenome",
  },{
    value: "cpf",
    label: "CPF",
  }, {
    value: "cnpj",
    label: "CNPJ",
  },
  {
    value: "endereco",
    label: "Endereço",
  },
  {
    value: "telefone",
    label: "Telefone",
  },
  {
    value: "email",
    label: "Email",
  }, {
    value: "dataNascimento",
    label: "Data de nascimento",
  },{
    value: "dataCompleta",
    label: "Data",
  },
{
    value: "dataHora",
    label: "Data e hora"
},{
  value: "saudacao",
  label: "Saudação"
}]

  return (
    <NodeMenu
      open
      node={node}
      anchorRef={anchorRef}
      values={values}
      onChange={handleChange}
      onRun={handleRun}
    >
      <Stack direction="row" alignItems="center" spacing={1}>
        <Stack sx={{
          backgroundColor: `#0C3249`,
          padding: 1,
          borderRadius: "10px 10px 10px 10px"
        }}
        >
          {renderIcons(data?.payload?.icon)}
        </Stack>
        <Stack spacing={0}>
          <Typography variant="h6"> {i18n.t('Salvar mensagem')}</Typography>
          <Typography color="text.secondary" sx={{ mt: -0.7 }}>
            {`Salvar mensagem do usuário`}
          </Typography>

        </Stack>
      </Stack>
      <Divider sx={{ my: 1 }} />
      <Box my={1}>
        <Stack>
          <Typography variant="caption" color="gray">
            Tipo da mensagem
          </Typography>
          <Select

            placeholder={"Selecione um tipo"}
            id="queue"
            allowClear
            onChange={handleChange}
            value={values?.messageType}
            options={options}
            size="large"
            style={{ width: '100%' }}
          />
        </Stack>
      </Box>
    </NodeMenu>
  )
}

export default SaveMessageMenu
