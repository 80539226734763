/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect, useReducer, useContext } from "react";

import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import Paper from "@material-ui/core/Paper";

import TicketListItem from "../TicketListItemCustom";
import TicketsListSkeleton from "../TicketsListSkeleton";
import nochat from "../../assets/nochat.png";

import useTickets from "../../hooks/useTickets";
import { i18n } from "../../translate/i18n";
import { AuthContext } from "../../context/Auth/AuthContext";
import { socketConnection } from "../../services/socket";
import { Divider, Typography } from "@material-ui/core";
import { Stack } from "@mui/system";

const useStyles = makeStyles((theme) => ({
  ticketsListWrapper: {
    position: "relative",
    display: "flex",
    height: "100%",
    flexDirection: "column",
    overflow: "hidden",
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },

  ticketsList: {
    flex: 1,
    maxHeight: "100%",
    overflowY: "scroll",
    backgroundColor: "#F3F3F3",
  },

  ticketsListHeader: {
    color: "rgb(67, 83, 105)",
    zIndex: 2,
    backgroundColor: "white",
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  ticketsCount: {
    fontWeight: "normal",
    color: "rgb(104, 121, 146)",
    marginLeft: "8px",
    fontSize: "14px",
  },

  noTicketsText: {
    textAlign: "center",
    color: "rgb(104, 121, 146)",
    fontSize: "14px",
    lineHeight: "1.4",
  },

  noTicketsTitle: {
    textAlign: "center",
    fontSize: "16px",
    fontWeight: "600",
    margin: "0px",
  },

  noTicketsDiv: {
    display: "flex",
    height: "100px",
    margin: 40,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const reducer = (state, action) => {
  if (action.type === "LOAD_TICKETS") {
    const newTickets = action.payload;

    newTickets.forEach((ticket) => {
      const ticketIndex = state.findIndex((t) => t.id === ticket.id);
      if (ticketIndex !== -1) {
        state[ticketIndex] = ticket;
        if (ticket.unreadMessages > 0) {
          state.unshift(state.splice(ticketIndex, 1)[0]);
        }
      } else {
        state.push(ticket);
      }
    });

    return [...state];
  }

  if (action.type === "RESET_UNREAD") {
    const ticketId = action.payload;

    const ticketIndex = state.findIndex((t) => t.id === ticketId);
    if (ticketIndex !== -1) {
      state[ticketIndex].unreadMessages = 0;
    }

    return [...state];
  }

  if (action.type === "UPDATE_TICKET") {
    const ticket = action.payload;
    const ticketRepeated  = state.findIndex(t=> t.contactId   === ticket.contactId && t.whatsappId === ticket.whatsappId)
    const ticketIndex = state.findIndex((t) => t.id === ticket.id);
    if(ticketRepeated !== -1) {
      state[ticketRepeated] = ticket;
    }
    else if (ticketIndex !== -1) {
      state[ticketIndex] = ticket;
    } else {
      state.unshift(ticket);
    }

    return [...state];
  }

  if (action.type === "UPDATE_TICKET_UNREAD_MESSAGES") {
    const ticket = action.payload;

    const ticketIndex = state.findIndex((t) => t.id === ticket.id);
    if (ticketIndex !== -1) {
      state[ticketIndex] = ticket;
      state.unshift(state.splice(ticketIndex, 1)[0]);
    } else {
      state.unshift(ticket);
    }

    return [...state];
  }

  if (action.type === "UPDATE_TICKET_CONTACT") {
    const contact = action.payload;
    const ticketIndex = state.findIndex((t) => t.contactId === contact.id);
    if (ticketIndex !== -1) {
      state[ticketIndex].contact = contact;
    }
    return [...state];
  }

  if (action.type === "DELETE_TICKET") {
    const ticketId = action.payload;
    const ticketIndex = state.findIndex((t) => t.id === ticketId);
    if (ticketIndex !== -1) {
      state.splice(ticketIndex, 1);
    }

    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const TicketsListCustom = (props) => {
  const {
    status,
    searchParam,
    tags,
    users,
    showAll,
    selectedQueueIds,
    updateCount,
    style,
    customSearch,
    label,
  } = props;
  const classes = useStyles();
  const [pageNumber, setPageNumber] = useState(1);
  const [ticketsList, dispatch] = useReducer(reducer, []);
  const { user } = useContext(AuthContext);
  const { profile, queues } = user;

  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [status, searchParam, dispatch, showAll, tags, users, selectedQueueIds]);

  const { tickets, hasMore, loading } = useTickets({
    pageNumber,
    searchParam,
    status,
    showAll,
    tags: JSON.stringify(tags),
    users: JSON.stringify(users),
    queueIds: JSON.stringify(selectedQueueIds),
  });

  useEffect(() => {
    const queueIds = queues.map((q) => q.id);

    const newFilterTickets = tickets.filter(item => {
      if (item.userId === user.id || queueIds.indexOf(item.queueId) > -1) {
        return item
      }
    })
    if (profile === "user") {
      dispatch({ type: "LOAD_TICKETS", payload: status === "closed" ? tickets.filter(item => item.userId === user.id) : newFilterTickets });
    } else {
      dispatch({ type: "LOAD_TICKETS", payload: tickets });
    }
  }, [tickets, status, searchParam, queues, profile]);

  useEffect(() => {
    const companyId = localStorage.getItem("companyId");
    const socket = socketConnection({ companyId });
    const shouldUpdateTicket = (ticket) =>
      (!ticket?.userId || ticket?.userId === user?.id || showAll) &&
      (!ticket?.queueId || selectedQueueIds.indexOf(ticket.queueId) > -1);

    const notBelongsToUserQueues = (ticket) =>
      ticket.queueId && selectedQueueIds.indexOf(ticket.queueId) === -1;

    socket.on("connect", () => {
      if (status) {
        socket.emit("joinTickets", status);
      } else {
        socket.emit("joinNotification");
      }
    });

    socket.on(`company-${companyId}-ticket`, (data) => {
      if (data.action === "updateUnread") {
        dispatch({
          type: "RESET_UNREAD",
          payload: data.ticketId,
        });
      }
      
      if (data.action === "update" && shouldUpdateTicket(data.ticket)) {
        dispatch({
          type: "UPDATE_TICKET",
          payload: data.ticket,
        });
      }

      if (data.action === "update" && notBelongsToUserQueues(data.ticket)) {
        dispatch({ type: "DELETE_TICKET", payload: data.ticket.id });
      }

      if (data.action === "delete") {
        dispatch({ type: "DELETE_TICKET", payload: data.ticketId });
      }
    });

    socket.on(`company-${companyId}-appMessage`, (data) => {
      if (data.action !== "create-internal") {

        if (data.action === "create" && shouldUpdateTicket(data.ticket)) {
          
          dispatch({
            type: "UPDATE_TICKET_UNREAD_MESSAGES",
            payload: data.ticket,
          });
        }
      }
    });

    socket.on(`company-${companyId}-contact`, (data) => {
      if (data.action === "update") {
        dispatch({
          type: "UPDATE_TICKET_CONTACT",
          payload: data.contact,
        });
      }
      
    });
    

    return () => {
      socket.disconnect();
    };
  }, [status, showAll, user, selectedQueueIds, tags, users, profile, queues]);

  // useEffect(() => {
  //   const companyId = localStorage.getItem("companyId");

  //   socket.on("connect", () => {
  //     if (status) {
  //       socket.emit("joinTickets", status);
  //     } else {
  //       socket.emit("joinNotification");
  //     }
  //   });

  //   socket.on(`company-${companyId}-ticket`, (data) => {
  //     if (data.action === "update") {
  //       dispatch({
  //         type: "UPDATE_TICKET",
  //         payload: data.ticket,
  //       });

  //     }

  //   });

  

  //   return () => {
  //     socket.disconnect();
  //   };
  // }, [status, showAll, user, selectedQueueIds, tags, users, profile, queues]);


  useEffect(() => {
    if (typeof updateCount === "function") {
      updateCount(ticketsList.length);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticketsList]);

  const loadMore = () => {
    setPageNumber((prevState) => prevState + 1);
  };

  const handleScroll = (e) => {
    if (!hasMore || loading) return;

    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;

    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      loadMore();
    }
  };

  useEffect(() => {
    if (customSearch) {
        const filterTickets = customSearch.map((search)=>{
          return ticketsList.filter((ticket)=>{
            if(search.contactId === ticket.contact.id){
              return ticket
            }
          })
        })
        dispatch({ type: "RESET" });
        dispatch({ type: "LOAD_TICKETS", payload: filterTickets[0] });
    }else{
      dispatch({ type: "RESET" });
      const queueIds = queues.map((q) => q.id);

      const newFilterTickets = tickets.filter(item => {
        if (item.userId === user.id || queueIds.indexOf(item.queueId) > -1) {
          return item
        }
      })
      if (profile === "user") {
        dispatch({ type: "LOAD_TICKETS", payload: status === "closed" ? tickets.filter(item => item.userId === user.id) : newFilterTickets });
      } else {
        dispatch({ type: "LOAD_TICKETS", payload: tickets });
      }
    }

  }, [customSearch]);


  return (
    <Paper className={classes.ticketsListWrapper} style={style}>

      <Paper
        square
        name="closed"
        elevation={0}
        className={classes.ticketsList}
        onScroll={handleScroll}
      >
        {label && (
          <>
            <Stack
              style={{ backgroundColor: "rgb(12 12 12 / 88%)", color: "white" }}
            >
              <Typography
                variant="body1"
                style={{ fontWeight: "bold", marginBottom: 5, marginTop: 5, marginLeft: 10 }}
              >
                {label + " " + "(" + ticketsList.length + ")"}
              </Typography>
            </Stack>
            <Divider style={{ marginBottom: 10 }} />
          </>
        )}


        <List style={{ paddingTop: 0 }}>
          {ticketsList.length === 0 && !loading ? (
            <Stack sx={{
              height: "100%",
              marginTop: 13,
            }} 
            justifyContent="center" alignItems="center">
               <img src={nochat}
                style={{ width: 150, height: 150, marginBottom: 10 }} />
              <Typography
                variant="body1"
                style={{ fontWeight: "bold", marginBottom: 1, marginTop: 5, marginLeft: 10 }}
              >
                Nenhum atendimento encontrato
              </Typography>
              <Typography
                variant="caption"
                style={{ marginBottom: 5, marginLeft: 10, color: "#757575" }}
              >
                Clique no icone de chat para iniciar um atendimento
              </Typography>
            </Stack>
          ) : (
            <>
              {ticketsList.map((ticket) => (
                <TicketListItem ticket={ticket} key={ticket.id} />
              ))}
            </>
          )}
          {loading && <TicketsListSkeleton />}
        </List>

      </Paper>
    </Paper>
  );
};

export default TicketsListCustom;
