import { Grid , Stack} from "@mui/material";
import { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import Builder from "./Builder";
import Toolbar from "./Toolbar";
import { useParams } from "react-router-dom";

import ValidationErrorDialog from "./Dialogs/ValidationError"
import PublishFlowDialog from "./Dialogs/PublishFlow"
import ListBuildsDialog from "./Dialogs/ListBuilds"
import { toast } from "react-toastify";
import api from "../../../services/api";

import toastError from "../../../errors/toastError";
import { useEdgesState, useNodesState } from "reactflow";

const Diagram = () => {
  const [validation, setValidation] = useState();
  const [openDialog, setOpenDialog] = useState("");
  const [snapShot, setSnapShot] = useState();
  const [nodes, setNodes] = useNodesState([]);
  const [edges, setEdges] = useEdgesState([]);
  const [flow, setFlow] = useState();
  const { flowId } = useParams();
  const [loading, setLoading] = useState(false);
  const [loadingValidation, setLoadingValidation] = useState(false);
  const [loadingDraft, setLoadingDraft] = useState(false);

  const getFlow = async () => {
    const flowData = await api
      .get("/getflow", {
        params: { flowName: flowId }
      })
      .then(response => {
        setFlow(response?.data);
        setNodes(response?.data?.branch?.diagram?.nodes);
        setEdges(response?.data?.branch?.diagram?.edges);
      });
  };

  useEffect(() => {
    if (!flowId) return;
    getFlow();
  }, [flowId]);

  const validateBranch = async () => {};
  const saveBranch = async () => {};

  //   const [validateBranch, { loading: loadingValidation }] = useMutation(VALIDATE_BRANCH, {
  //     onCompleted: ({ validateBranch }) => {
  //       setSnapShot(validateBranch?.snapId)
  //     }
  //   })

  //   const [saveBranch, { loading: loadingDraft }] = useMutation(SAVE_BRANCH_DRAFT, {
  //     onCompleted: ({ validateBranch }) => {
  //       setSnapShot(validateBranch?.snapId)
  //     }
  //   })

  const handleDialogClose = async () => {
    setOpenDialog("");
  };

  const handlePublish = async () => {
    try {
      const validateBranch = await api.post(`/flows/validate/${flow?.branch?.id}`, {
        payload: JSON.stringify({
          nodes,
          edges
        })
     })
      setSnapShot(validateBranch?.data?.id)
      setValidation({ valid: true });
      setOpenDialog("publishBranch");
    } catch (e) {
      const data = e?.response?.data;
      const response = e;
      if (response?.message === "Unauthorized") {
        toast.error("Você foi desconectado");
      } else {
        setValidation({
          valid: false,
          nodes: JSON.parse(data)
        });
        setOpenDialog("validationError");
      }
    }
  };

  const handleSave = async () => {
    try {
      if (!flow?.branch?.id) return;
      setLoadingDraft(true);
      const validateBranch = await api.post(`/saveFlow/${flow?.branch?.id}`, {
        payload: JSON.stringify({
          nodes,
          edges
        })
      }).then(response => {
        setLoadingDraft(false);
      } )

      //   await saveBranch({
      //     variables: {
      //       id: flow?.branch?._id,
      //       payload: JSON.stringify({
      //         nodes,
      //         edges
      //       })
      //     }
      //   })
    } catch (e) {
      toastError("Erro ao salvar fluxo!")
    }
  };

    // useEffect(() => {
    //   const interval = setInterval(() => {
    //     handleSave();
    //   }, 30000);

    //   return () => clearInterval(interval);
    // });

  const handleShowBuilds = () => {
    setOpenDialog("showFlowBuilds");
  };

  return !loading && flow ? (
    <>
    <ValidationErrorDialog
        open={openDialog === 'validationError'}
        onClose={handleDialogClose}
        validation={validation}
      />
        <PublishFlowDialog
          open={openDialog === 'publishBranch'}
          snapId={snapShot}
          onClose={handleDialogClose}
          flowId={flow?.id}
          branchId={flow?.branch?.id}
        />
        <ListBuildsDialog
          open={openDialog === 'showFlowBuilds'}
          onClose={handleDialogClose}
          indexName={flow?.id}
          branchId={flow?.branch?.id}
        />

      {/* 
      {openDialog === 'publishBranch' && (
        <PublishFlowDialog
          open
          snapId={snapShot}
          onClose={handleDialogClose}
          flowId={flow?.id}
          branchId={flow?.branch?.id}
        />
      )}
     */}
      <Toolbar
        flow={flow}
        onPublish={handlePublish}
        onShowBuilds={handleShowBuilds}
        validating={loadingValidation}
        saving={loadingDraft}
        onSaving={handleSave}
      />

      <Grid container wrap="nowrap" className="fullHeight overflow-y-hidden">
        <Grid
          item
          className="fullHeight overflow-y-hidden"
          style={{
            backgroundColor: "transparent"
          }}
        >
          <Sidebar />
        </Grid>
        <Grid item xs style={{ position: "relative" }}>
          <Builder
            nodes={nodes}
            edges={edges}
            onChangeNodes={setNodes}
            onChangeEdges={setEdges}
            flow={flow}
            validation={validation}
          />
        </Grid>
      </Grid>
    </>
  ) : null;
};

Diagram.propTypes = {};

export default Diagram;
