import {
  Typography,
  Stack,
  Divider,
  Button,
  Box,
  Grid,
  IconButton,
  Avatar,
} from "@mui/material";

import FacebookIcon from "../../../../assets/img/channels/facebook.svg";
import WhatsAppIcon from "../../../../assets/img/channels/whatsapp.svg";
import TelegramIcon from "../../../../assets/img/channels/telegram.svg";
import InstagramIcon from "../../../../assets/img/channels/instagram.svg";
import Gmail from "../../../../assets/img/channels/gmail.png";
import Outlook from "../../../../assets/img/channels/owa.png";
import { Bolt, Check, WhatsApp } from "@mui/icons-material";
import toastError from "../../../../errors/toastError";

export default function GenericPlan({ onSelect, disabled, plan }) {
  const handleSelect = () => {
    if (disabled)
      return toastError(
        "Não é permitido regredir o plano, entre em contato com o suporte para mais informações."
      );
    onSelect();
  };

  return (
    <Stack
      sx={{
        backgroundColor: disabled ? "#E0E0E0" : "#FFFFFF",
        borderRadius: "10px",
        padding: 3,
        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.05)",
        width: "300px",
        height: "580px",
        border: "1px solid #E0E0E0",
      }}
      spacing={1}
      justifyContent="space-between"
    >
      <Stack>
        <Stack direction="row" spacing={2} alignItems="center">
          <Stack
            sx={{
              borderRadius: "50%",
              backgroundColor: disabled ? "#b9b9b9" : plan.color,
              height: "50px",
              width: "50px",
            }}
            justifyContent="center"
            alignItems="center"
          >
            <Bolt
              sx={{
                color: "#FFFFFF",
                fontSize: "40px",
              }}
            />
          </Stack>
          <Typography variant="h5" color="text.secondary" fontWeight="bold">
            {plan.name}
          </Typography>
        </Stack>
        <Typography
          variant="h4"
          sx={{ mt: 2 }}
          color={disabled ? "text.disabled" : "text.primary"}
        >
          R$ {plan.value}
        </Typography>
        <Divider sx={{ my: 2 }} />
        <Typography
          variant="body1"
          color={disabled ? "text.disabled" : "text.primary"}
        >
          FEATURES
        </Typography>
        <Stack
          sx={{
            maxHeight: "100px",
            overflowY: "scroll",
          }}
        >
          <Stack direction="row" spacing={1}>
            <Check sx={{ color: disabled ? "#b9b9b9" : "#0C3249" }} />
            <Typography
              variant="body1"
              color={disabled ? "text.disabled" : "text.primary"}
            >
              {plan.users} Usuários
            </Typography>
          </Stack>
          <Stack direction="row" spacing={1}>
            <Check sx={{ color: disabled ? "#b9b9b9" : "#0C3249" }} />
            <Typography
              variant="body1"
              color={disabled ? "text.disabled" : "text.primary"}
            >
              {plan.flows} Fluxos
            </Typography>
          </Stack>
          <Stack direction="row" spacing={1}>
            <Check sx={{ color: disabled ? "#b9b9b9" : "#0C3249" }} />
            <Typography
              variant="body1"
              color={disabled ? "text.disabled" : "text.primary"}
            >
              {plan.connections} Conexão
            </Typography>
          </Stack>
          <Stack direction="row" spacing={1}>
            <Check sx={{ color: disabled ? "#b9b9b9" : "#0C3249" }} />
            <Typography
              variant="body1"
              color={disabled ? "text.disabled" : "text.primary"}
            >
              {plan.campaigns} Campanhas
            </Typography>
          </Stack>
        </Stack>
        {/*   
          <Divider sx={{ mt: 2, mb: 3 }} />
  
          <Stack direction="row" spacing={1}>
            <Avatar
              src={WhatsAppIcon}
              sx={{
                width: 40,
                height: 40,
                objectFit: "fill",
                background: "background.default",
                border: 0,
                p: 1
              }}
              imgProps={{
                style: {
                  objectFit: "fill"
                }
              }}
            />
            <Avatar
              src={Outlook}
              sx={{
                width: 40,
                height: 40,
                objectFit: "fill",
                background: "background.default",
                border: 0,
                p: 1
              }}
              imgProps={{
                style: {
                  objectFit: "fill"
                }
              }}
            />
            <Avatar
              src={Gmail}
              sx={{
                width: 40,
                height: 40,
                objectFit: "fill",
                background: "background.default",
                border: 0,
                p: 1
              }}
              imgProps={{
                style: {
                  objectFit: "fill"
                }
              }}
            />
            <Avatar
              src={InstagramIcon}
              sx={{
                width: 40,
                height: 40,
                objectFit: "fill",
                background: "background.default",
                border: 0,
                p: 1
              }}
              imgProps={{
                style: {
                  objectFit: "fill"
                }
              }}
            />
          </Stack>
          <Stack direction="row" spacing={1}>
            <Avatar
              src={FacebookIcon}
              sx={{
                width: 40,
                height: 40,
                objectFit: "fill",
                background: "background.default",
                border: 0,
                p: 1
              }}
              imgProps={{
                style: {
                  objectFit: "fill"
                }
              }}
            />
            <Avatar
              src={TelegramIcon}
              sx={{
                width: 40,
                height: 40,
                objectFit: "fill",
                background: "background.default",
                border: 0,
                p: 1
              }}
              imgProps={{
                style: {
                  objectFit: "fill"
                }
              }}
            />
          </Stack> */}
      </Stack>
      <Stack
        onClick={handleSelect}
        sx={{
          mt: "20px",
          cursor: "pointer",
          backgroundColor: disabled ? "#b9b9b9" : plan.color,
          color: "#FFFFFF",
          height: "40px",
          width: "100%",
          borderRadius: "10px",
        }}
        justifyContent="center"
        alignItems="center"
      >
        <Typography variant="body1" color="white" fontWeight="bold">
          OBTER {plan.name}
        </Typography>
      </Stack>
    </Stack>
  );
}
