// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.item i { 
    color: #474747; 
    font-size: 1.13rem; 
} 
  
.item.dragging { 
    opacity: 0.6; 
} 

.sortable-list { 
    width: 425px; 
    background: none !important; 
} `, "",{"version":3,"sources":["webpack://./src/pages/Flow/Diagram/Builder/nodes/activeMessage/menu/style.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,kBAAkB;AACtB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,2BAA2B;AAC/B","sourcesContent":[".item i { \n    color: #474747; \n    font-size: 1.13rem; \n} \n  \n.item.dragging { \n    opacity: 0.6; \n} \n\n.sortable-list { \n    width: 425px; \n    background: none !important; \n} "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
