import React, { useState, useEffect, useRef } from "react";
import * as Yup from "yup";
import { Formik, FieldArray, Form } from "formik";
import { toast } from "react-toastify";
import { Input, Modal, Button, Divider, Typography, Spin } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import toastError from "../../errors/toastError";

const ContactSchema = Yup.object().shape({
  name: Yup.string().min(2, "Too Short!").max(50, "Too Long!").required("Required"),
  number: Yup.string().min(8, "Too Short!").max(50, "Too Long!"),
  email: Yup.string().email("Invalid email"),
});

const ContactModal = ({ open, onClose, contactId, initialValues, onSave }) => {
  const isMounted = useRef(true);

  const initialState = {
    name: "",
    number: "",
    email: "",
  };

  const [contact, setContact] = useState(initialState);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    const fetchContact = async () => {
      if (initialValues) {
        setContact((prevState) => {
          return { ...prevState, ...initialValues };
        });
      }

      if (!contactId) return;

      try {
        const { data } = await api.get(`/contacts/${contactId}`);
        if (isMounted.current) {
          setContact(data);
        }
      } catch (err) {
        toastError(err);
      }
    };

    fetchContact();
  }, [contactId, open, initialValues]);

  const handleClose = () => {
    onClose();
    setContact(initialState);
  };

  const handleSaveContact = async (values) => {
    try {
      if (contactId) {
        await api.put(`/contacts/${contactId}`, values);
        handleClose();
      } else {
        const { data } = await api.post("/contacts", values);
        if (onSave) {
          onSave(data);
        }
        handleClose();
      }
      toast.success(i18n.t("contactModal.success"));
    } catch (err) {
      toastError(err);
    }
  };

  const renderTitle = () => (
    <div style={{ flexDirection: "column", marginTop: -32, marginBottom: -16 }}>
      <Typography.Title level={3}>
        {contactId ? `${contact.name}` : "Novo contato"}
      </Typography.Title>
      <Typography.Text
        style={{ marginTop: -16 }}
        type="secondary"
      >
        {contactId ? `ID: ${contactId}` : "Crie um novo contato para iniciar um atendimento"}
      </Typography.Text>
    </div>
  );

  return (
    <Modal
      title={renderTitle()}
      visible={open}
      onCancel={handleClose}
      footer={null}
    >
      <Divider />
      <Formik
        initialValues={contact}
        enableReinitialize
        validationSchema={ContactSchema}
        onSubmit={(values, actions) => {
          setTimeout(() => {
            handleSaveContact(values);
            actions.setSubmitting(false);
          }, 400);
        }}
      >
        {({ values, errors, isSubmitting, handleChange, handleSubmit }) => (
          <Form>
            <div style={{ marginTop: -16, marginBottom: 8 }}>
              <Typography.Text>Nome*</Typography.Text>
              <Input
                size="large"
                id="name"
                value={values.name}
                onChange={handleChange}
                name="name"
                placeholder="Nome do contato"
              />
            </div>
            <div style={{ marginBottom: 8 }}>
              <Typography.Text>Telefone*</Typography.Text>
              <Input
                size="large"
                id="number"
                value={values.number}
                onChange={handleChange}
                name="number"
                placeholder="Telefone do contato"
              />
            </div>
            <div style={{ marginBottom: 8 }}>
              <Typography.Text>Email</Typography.Text>
              <Input
                size="large"
                id="email"
                type="email"
                value={values.email}
                onChange={handleChange}
                name="email"
                placeholder="Email do contato"
              />
            </div>
            <Divider />
            <FieldArray name="extraInfo">
              {({ push, remove }) => (
                <>
                  {values.extraInfo &&
                    values.extraInfo.length > 0 &&
                    values.extraInfo.map((info, index) => (
                      <div
                        key={`${index}-info`}
                        style={{ display: "flex", alignItems: "center", marginBottom: 16, marginTop: -8 }}
                      >
                        <div style={{ flex: 1, marginRight: 8 }}>
                          <Typography.Text>Campo*</Typography.Text>
                          <Input
                            size="large"
                            value={values.extraInfo[index].name}
                            onChange={handleChange}
                            name={`extraInfo[${index}].name`}
                            placeholder="Nome do campo"
                          />
                        </div>
                        <div style={{ flex: 1, marginRight: 8 }}>
                          <Typography.Text>Valor*</Typography.Text>
                          <Input
                            size="large"
                            value={values.extraInfo[index].value}
                            onChange={handleChange}
                            name={`extraInfo[${index}].value`}
                            placeholder="Valor"
                          />
                        </div>
                        <Button
                          type="text"
                          danger
                          size="large"
                          icon={<DeleteOutlined />}
                          onClick={() => remove(index)}
                          style={{
                            marginTop: 22,
                            display: "flex",
                            alignItems: "center",
                          }}
                        />
                      </div>
                    ))}
                  <Button
                    type="dashed"
                    style={{ width: "100%", marginBottom: 16 }}
                    onClick={() => push({ name: "", value: "" })}
                  >
                    {`+ ${i18n.t("contactModal.buttons.addExtraInfo")}`}
                  </Button>
                </>
              )}
            </FieldArray>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                onClick={handleClose}
                disabled={isSubmitting}
                style={{ marginRight: 8 }}
              >
                {i18n.t("contactModal.buttons.cancel")}
              </Button>
              <Button
                type="primary"
                onClick={handleSubmit}
                disabled={isSubmitting}
              >
                {contactId
                  ? `${i18n.t("contactModal.buttons.okEdit")}`
                  : `${i18n.t("contactModal.buttons.okAdd")}`}
                {isSubmitting && <Spin size="small" style={{ marginLeft: 8 }} />}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default ContactModal;
