import {
  Typography,
  Stack,
  Divider,
  Button,
  Grid,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import { Input } from "antd";
import { useEffect, useState } from "react";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import EnterPrisePlan from "./PlansConstants/Enterprise";
import PaymentModal from "../../../components/PlanModal";
import toastError from "../../../errors/toastError";
import api, { openApi } from "../../../services/api";
import GenericPlan from "./PlansConstants/GenericPlan";

export default function RegistrationPlan({
  values,
  handleNext,
  company,
  plan,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [phone, setPhone] = useState("");
  const [personalPlanDialog, setPersonalPlanDialog] = useState(false);
  const [open, setOpen] = useState(false);
  const [plans, setPlans] = useState([]);

  const dialogTheme = createTheme({
    palette: {
      primary: {
        main: "#E07941",
      },
    },
  });

  const sendPersonalContact = async () => {
    try {
      setIsLoading(true);
      let formattedPhone = phone;

      if (!phone?.startsWith("55")) {
        formattedPhone = `55${phone}`;
      }

      await api.post("/api/messages/sendToPersonalContact", {
        phone: formattedPhone,
      });

      window.open(
        `https://wa.me/${process.env.REACT_APP_WA_CONTACT}`,
        "_blank"
      );
    } catch (error) {
      toastError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getWidthPage = () => {
    return window.innerWidth;
  };

  useEffect(() => {
    setPhone(values.phone);
  }, [values.phone]);

  useEffect(() => {
    openApi
      .get("/plans/public")
      .then((response) => {
        setPlans(response.data);
      })
      .catch((error) => {
        toastError("Erro ao buscar os planos");
      });

    return () => {
      setPlans([]);
    };
  }, [setPlans]);

  const settings = {
    className: "center",
    dots: true,
    infinite: false,
    padding: "60px",
    speed: 500,
    slidesToShow: getWidthPage() < 1500 ? 2 : 3,
    centerPadding: "60px",
    slidesToScroll: 2,
    width: "100%",
  };

  const handleSubmit = async (type) => {
    if (type === "FREE") {
      return handleNext();
    }
    handleSubmitPayment(type);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    setOpen(false);
    handleNext();
  };

  const handleSubmitPayment = async (planType) => {
    const data = {
      companyData: {
        ...values,
      },
      plan: planType,
    };
    try {
      const result = await openApi.post("/companies/register", data);
      if (result?.data?.paymentLink) {
        window.location.href = result.data.paymentLink;
      }
    } catch (error) {
      toastError(error);
    }
  };

  return (
    <>
      <Dialog
        open={personalPlanDialog}
        onClose={() => setPersonalPlanDialog(false)}
        style={{
          borderRadius: "10px",
        }}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle
          id="form-dialog-title"
          style={{
            backgroundColor: "#E07941",
          }}
        >
          <Typography color={"white"} fontWeight="bold">
            Plano personalizado
          </Typography>
        </DialogTitle>

        <DialogContent dividers>
          <Grid
            spacing={2}
            container
            style={{
              paddingTop: "8px",
              paddingBottom: "24px",
            }}
          >
            <Grid xs={12} item>
              <Stack>
                <Typography variant="caption" color="text.secondary">
                  Seu telefone de contato
                </Typography>
                <Input
                  size="large"
                  id="telefone"
                  value={phone}
                  onChange={(ev) => setPhone(ev.target.value)}
                  name="phone"
                  type="tel"
                  placeholder="(11) 99999-9999"
                />
              </Stack>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setPersonalPlanDialog(false)}
            color="primary"
            variant="outlined"
          >
            Fechar
          </Button>
          <ThemeProvider theme={dialogTheme}>
            <Button
              onClick={sendPersonalContact}
              disabled={!phone?.trim()}
              color="primary"
              variant="contained"
            >
              Entrar em contato
              {isLoading && (
                <CircularProgress
                  size={24}
                  style={{
                    color: "#FFFFFF",
                  }}
                  className={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: -12,
                    marginLeft: -12,
                  }}
                />
              )}
            </Button>
          </ThemeProvider>
        </DialogActions>
      </Dialog>
      <Stack
        sx={{
          marginTop: 3,
        }}
      >
        <PaymentModal
          open={open}
          onClose={handleClose}
          plan={plan}
          company={company}
          onConfirm={handleConfirm}
        />
        <Divider sx={{ mb: 3 }} />
        <Stack sx={{ mb: 3 }}>
          <Typography variant="h5" color="primary">
            Chegou a hora de escolher seu plano.
          </Typography>
          <Typography variant="body1" color="text.secondary">
            Por favor, selecione um plano abaixo:
          </Typography>
        </Stack>

        <Slider {...settings}>
          {plans.length &&
            plans.map((item) => (
              <GenericPlan
                onSelect={() => handleSubmit(item.name)}
                disabled={false}
                plan={item}
              />
            ))}

          <EnterPrisePlan
            onSelect={() => {
              setPersonalPlanDialog(true);
            }}
          />
        </Slider>

        <Divider sx={{ mb: 3, mt: 3 }} />
      </Stack>
    </>
  );
}
