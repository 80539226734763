import styled, { css } from "styled-components"
import { Handle as Port } from "react-flow-renderer"



const METHOD_COLOR = {
  GET: "#1E88E5",
  POST: "#5FD07E",
  PUT: "#FF9800",
  PATCH: "#1E88E5",
  DELETE: "#EF5350",
}

export const Container = styled.div.attrs((props) => props)`
  border: 1px solid #cacaca;
  background: #fff;
  min-width: 200px;
  min-height: 100px;
  pointer-events: none;
  opacity: 0.8;

  ${({ selected }) =>
    selected &&
    css`
      box-shadow: rgba(0, 89, 220, 0.8) 0px 1px 1px,
        rgba(0, 89, 220, 0.8) 0px 0px 1px 1px;
    `}

  &.react-flow__node {
    background: blue;
    width: 400px;
  }
`


export const Tag = styled.div.attrs((props) => props)`
  background:  ${({method})=> METHOD_COLOR[method]}66;
  border-radius: 5px;
  padding: 5px 20px;
  color: ${({method})=> METHOD_COLOR[method]};
  display: inline;
`

export const HandleContainer = styled.div`
  position: absolute;
  top: calc(50% - 12px);
  right: -6px;
`

export const Handle = styled(Port)`
  && {
    background: ${(props) => props.color};
    transform: translate(0);
    width: 6px;
    height: 6px;
    top: 6px;
    position: relative;
    &:hover {
      transform: scale(1.5);
    }
  }
`
