import React, { useState, useEffect } from "react";
import Routes from "./routes";
import "react-toastify/dist/ReactToastify.css";

import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { ptBR } from "@material-ui/core/locale";
import './app.css';
import { ConfigProvider } from 'antd';
import pt_BR from 'antd/es/locale/pt_BR';

const App = () => {
  const [locale, setLocale] = useState();

  const theme = createTheme(
    {
      scrollbarStyles: {
        '&::-webkit-scrollbar': {
          width: '5px',
          height: '5px',
          boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3)',
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#E3E3E3',
        },
        '&::-webkit-scrollbar-track': {
          backgroundColor: '#FFF',
        },
      },
      palette: {
        light: { main: '#F3F3F3' },
        primary: { main: '#001529' },
        secondary: { main: '#01f39f' },
        notification: { main: '#4BAF50' },
        error: { main: '#FF0000' },
        text: {
          primary: '#333333',
          secondary: '#666666',
        },
        toolbar: "linear-gradient(to right, #001529, #01E598, #01f39f)"
      },
    },
    locale
  );

  useEffect(() => {
    const i18nlocale = localStorage.getItem("i18nextLng");
    const browserLocale =
      i18nlocale.substring(0, 2) + i18nlocale.substring(3, 5);

    if (browserLocale === "ptBR") {
      setLocale(ptBR);
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <ConfigProvider
        locale={pt_BR}
        theme={{
          token: {
            colorLight: theme.palette.light.main,
            colorPrimary: theme.palette.primary.main,
            colorSecondary: theme.palette.secondary.main,
            colorNotification: theme.palette.notification.main,
            colorTextPrimary: theme.palette.text.primary,
            colorTextSecondary: theme.palette.text.secondary,
            colorError: theme.palette.error.main,
            colorGradient: theme.palette.toolbar,
          },
        }}
      >
        <Routes />
      </ConfigProvider>
    </ThemeProvider>
  );
};

export default App;
