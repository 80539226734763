import React from "react";

import { Stack, Typography} from "@mui/material"
import { Modal } from "antd";
import UserSelect from "../UserSelect"
import WalletSelect from "../WalletSelect";
const ConfirmationModalImport = ({  open, onClose, onConfirm }) => {
	const [selectedUser, setSelectedUser] = React.useState(null);
	
	
	const renderTitle = () => {
		return <Stack   >
		  <Typography variant="h6" sx={{ ml: 1 }}>
			Importação de contatos
		  </Typography>
		</Stack>
	  }
	
	  const handleClose = () => {
		setSelectedUser(null);
		onClose(false);
	  }
	return (
		<Modal
			open={open}
			onCancel={() => onClose(false)}
			title={renderTitle()}
			onOk={() => {
				handleClose()
				onConfirm(selectedUser);
				setSelectedUser(null)
			}}
		>
		<Stack p={1}>

                  <Stack >
                    <Typography variant="caption" color="gray">
                      Atribuir a uma carteira de contatos
                    </Typography>
                    <WalletSelect
                      selectedUserId={selectedUser}
                      onChange={setSelectedUser}
                    />
                  </Stack>

		</Stack>
		</Modal>
	);
};

export default ConfirmationModalImport;
