import { Divider, Grid, IconButton, Paper, Typography } from "@mui/material"
import { Box, Stack } from "@mui/system"
import { Position, NodeProps } from "react-flow-renderer"
import { Container, HandleContainer, Handle, Tag } from "./styled"
import Node from "../../StyleNodes/Node"

export { EndFlowMenu } from "./menu"
const handleStyle = { left: 10 }

const EndFlowNode = (node) => {
  const { data } = node


  return (
    <Node node={node} >
      <Box px={2} pb={1} pt={1}>
        <Grid container spacing={1}>
          <Grid item>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Typography variant="body2" >
                Encerramento
              </Typography>


            </Stack>
          </Grid>

        </Grid>
      </Box>
    </Node>
  )
}

export default EndFlowNode
