import { Chip, Divider, Grid, IconButton, Paper, Stack, Typography } from "@mui/material"
import { Box } from "@mui/system"
import { Position, NodeProps } from "react-flow-renderer"
import { Container, HandleContainer, Handle, Tag } from "./styled"
import Node from "../../StyleNodes/Node"
import { availableConditions } from "./data"
export { SplitMenu } from "./menu"
export { ConditionalData } from "./data"
const handleStyle = { left: 10 }

const SplitNode = (node) => {
  const { data } = node

  return (
    <Node node={node} >
      {data.conditions.map((it, index) => (
        <Grid
          container
          style={{ position: "relative" }}
          spacing={1}
          mt={0}
          px={2}
          alignItems="center"
        >
          <Grid item sx={{ mb: 1 }}>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Typography variant="caption" color="gray">
                Randomização - {index + 1}
              </Typography>
              {it.percent && (
                <Chip sx={{
                  backgroundColor: `${data.colors[index]}`,
                  ml:1
                }} size="small" label={<Stack direction="row">
                  <Typography variant="caption" color="white">
                    {it.percent}%
                  </Typography>
                </Stack>} />
              )}

            </Stack>
          </Grid>
        
          <Grid item>
            <HandleContainer>
              <Handle
                type="source"
                position={Position.Right}
                id={it.id}
                color={node.data.payload?.port[it.id].color}
              />
            </HandleContainer>
          </Grid>
        </Grid>
      ))}
    
    </Node>
  )
}

export default SplitNode
