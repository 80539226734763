import { useDebouncedValue } from "rooks";
import React, { useEffect, useState } from "react";
import { Divider, Grid, Paper, Stack, Box , IconButton} from "@mui/material";
import { NodesConstant } from "./nodes";
import AllNodes from "./components/Nodes";
import Search from "antd/es/input/Search";
import NodeItemSkeleton from "./components/skeleton";
import { ArrowLeft, ArrowRight, ArrowRightAlt } from '@mui/icons-material'

export default () => {
  const [searchValue, setSearchValue] = useState("");
  const [debouncedSearch] = useDebouncedValue(searchValue, 600);
  const [tabOpen, setTabOpen] = useState(true);
  const [nodes, setNodes] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setNodes(NodesConstant);
  }, []);


  const handleTab = () => {
    setTabOpen(!tabOpen)
  }

  const handleChangeSearch = event => {
    if(event.target.value === "") {
      setNodes(NodesConstant);
      setSearchValue(event.target.value);
      return;
    }
    const filteredNodes = NodesConstant.filter(node =>
      node.name.toLowerCase().includes(event.target.value.toLowerCase())
    );
    setNodes(filteredNodes);
    setSearchValue(event.target.value);
  };

  return (
    <Stack
      direction="row"
      alignItems="center"
      className="fullHeight"
      overflow="hidden"
    >
      <Paper
        sx={{
          mt: 2,
          ml: 2,
          height: "93%",
          overflow: "hidden",

          width: tabOpen ? 300 : 15,
          transition: ({ transitions: { duration, easing } }) =>
            `width ${duration.complex}ms ${easing.easeInOut}`
        }}
      >
        <Grid
          container
          direction="column"
          className="fullHeight"
          sx={{
            mr: 3
          }}
        >
          <Grid item>
            <Stack direction="row" sx={{ padding: 2 }}>
              <Search
                name="search"
                type="search"
                size="large"
                style={{ width: "100%" }}
                placeholder={"Buscar"}
                defaultValue={searchValue}
                onChange={handleChangeSearch}
              />
            </Stack>

            <Box my={1}>
              <Divider />

              <Divider />
            </Box>
          </Grid>

          <Grid item xs style={{ overflow: "auto" }}>
            {loading ? (
              <NodeItemSkeleton />
            ) : (
              <>
                <AllNodes nodes={nodes} />
              </>
            )}
          </Grid>
        </Grid>
      </Paper>
      <IconButton onClick={handleTab} sx={{ ml: '-15px' }}>
        <Stack
          sx={{
            backgroundColor: '#0C3249',
            borderRadius: '25px',
            width: '20px',
            height: '20px',
          }}
          alignItems="center"
          justifyContent="center"
        >
          {tabOpen ? (
            <ArrowLeft
              sx={{
                color: 'white',
              }}
            />
          ) : (
            <ArrowRight
              sx={{
                color: 'white',
              }}
            />
          )}
        </Stack>
      </IconButton>
    </Stack>
  );
};
