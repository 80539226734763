import {
  Box,
  Divider,
  Stack,
  Typography,
} from "@mui/material"
import NodeMenu from "../../../StyleNodes/Menu"

import AceEditor from "react-ace"

import "ace-builds/src-noconflict/theme-github"
import "ace-builds/src-noconflict/mode-json"
import "ace-builds/src-noconflict/ext-language_tools"
import "ace-builds/src-noconflict/ext-beautify"

import React, { useRef, useState } from "react"
import { i18n } from "../../../../../../../translate/i18n";
import { Input, Select } from "antd"
import { renderIcons } from "../../../../Icons"

const handleStyle = { left: 10 }

export const FlowVariableMenu = ({ node, anchorRef }) => {
  const { data } = node
  const [anchorEl, setAnchorEl] = useState(null)
  const [values, setValues] = useState(data)
  const [openImport, setOpenImport] = useState(false)

  const handleRun = () => { }

  const handleChange = ({ target }) => {
    values[target.name] = target.value
    setValues({ ...values })
  }


  return (
    <NodeMenu
      open
      node={node}
      anchorRef={anchorRef}
      values={values}
      onChange={handleChange}
      onRun={handleRun}
    >
      <Stack direction="row" alignItems="center" spacing={1}>
        <Stack sx={{
          backgroundColor: `#0C3249`,
          padding: 1,
          borderRadius: "10px 10px 10px 10px"
        }}
        >
          {renderIcons(data?.payload?.icon)}
        </Stack>
        <Stack spacing={0}>
          <Typography variant="h6"> {i18n.t('Variável de fluxo')}</Typography>
          <Typography color="text.secondary" sx={{ mt: -0.7 }}>
            {`As variáveis podem ser acessadas por {{flow.variavel}}`}
          </Typography>

        </Stack>
      </Stack>
      <Divider sx={{ my: 1 }} />
      <Box my={1}>
        <Typography variant="caption" color="text.secondary">
          Variáveis JSON
        </Typography>
        <AceEditor
          mode="json"
          theme="github"
          value={values.data}
          onChange={(value) =>
            handleChange({
              target: { name: "data", value },
            } )
          }
          setOptions={{
            enableLiveAutocompletion: true,
            enableBasicAutocompletion: true,
            useWorker: false,
            highlightSelectedWord: true,
          }}
          maxLines={20}
          minLines={10}
          name="UNIQUE_ID_OF_DIV"
          editorProps={{ $blockScrolling: true }}
          style={{ width: "100%" }}
        />
      </Box>
    </NodeMenu>
  )
}

export default FlowVariableMenu
