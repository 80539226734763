import openSocket from "socket.io-client";
import { isObject } from "lodash";
import api from "./api";
export function socketConnection(params) {
  let userId = null;
  if (localStorage.getItem("userId")) {
    userId = localStorage.getItem("userId");
  }

  const sessionToken = api.defaults.headers.Authorization
  return openSocket(process.env.REACT_APP_SOCKET_RUNTIME_URL, {
    transports: ["websocket", "polling", "flashsocket"],
    pingTimeout: 18000,
    pingInterval: 18000,
    query: isObject(params) ? { ...params, userId, token: sessionToken } : { userId, token:sessionToken },
  });
}

