import React, { useState, useEffect, useContext } from "react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import {
  DialogActions,
  CircularProgress,
  Switch,
  FormControlLabel,
  Grid,
  Divider,
  Typography
} from "@material-ui/core";
import { Collapse } from "antd";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import toastError from "../../errors/toastError";
import QueueSelect from "../QueueSelect";
import { IconButton, Stack, Tooltip } from "@mui/material";
import { Input, Modal, Button } from "antd";
import TextArea from "antd/es/input/TextArea";
import { InfoOutlined } from "@material-ui/icons";
import { AuthContext } from "../../context/Auth/AuthContext";
import FlowSelect from "../FlowSelect";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap"
  },

  multFieldLine: {
    display: "flex",
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(1)
    }
  },

  btnWrapper: {
    position: "relative"
  },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  }
}));

const SessionSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required")
});

const WhatsAppModal = ({ open, onClose, whatsAppId }) => {
  const classes = useStyles();
  const { user } = useContext(AuthContext);

  const initialState = {
    name: "",
    greetingMessage: "",
    complationMessage: "",
    outOfHoursMessage: "",
    ratingMessage: "",
    isDefault: false,
    token: "",
    provider: "beta"
  };
  const [whatsApp, setWhatsApp] = useState(initialState);
  const [selectedQueueId, setSelectedQueueId] = useState("none");
  const [selectedQueueIds, setSelectedQueueIds] = useState([]);
  const [selectFlow, setSelectFlow] = useState(null);
  const [selectFlowWelcome, setSelectFlowWelcome] = useState(null);

  const [loadingQueue, setLoadingQueue] = useState(false);
  const [queues, setQueues] = useState([]);
  const [loadingTime, setLoadingTime] = useState(false);
  const [redirectTime, setRedirectTime] = useState("");
  const companyId = localStorage.getItem("companyId");

  useEffect(() => {
    (async () => {
      try {
        const { data } = await api.get("/queue", {
          params: { companyId }
        });
        setQueues(data);
      } catch (err) {
        toastError(err);
      }
    })();
  }, [companyId]);

  useEffect(() => {
    const fetchSession = async () => {
      if (!whatsAppId) return;

      try {
        const { data } = await api.get(`whatsapp/${whatsAppId}?session=0`);
        setWhatsApp(data);

        const whatsQueueIds = data.queues?.map(queue => queue.id);
        setSelectedQueueIds(whatsQueueIds);
        const flowSelect = data?.flow
        const welcomeSelect = data?.welcome
        setSelectFlowWelcome(welcomeSelect)
        setSelectFlow(flowSelect)
      } catch (err) {
        toastError(err);
      }
    };
    fetchSession();
  }, [whatsAppId]);

  const handleSaveWhatsApp = async values => {
    const whatsappData = { ...values, queueIds: selectedQueueIds, flow: selectFlow ? selectFlow : null ,welcome: selectFlowWelcome ? selectFlowWelcome : null };
    delete whatsappData["queues"];
    delete whatsappData["session"];

    if (whatsappData.queueRedirect === "none") {
      whatsappData["queueRedirectTime"] = null;
      whatsappData["queueRedirect"] = null;
    } else if (
      whatsappData.queueRedirect &&
      whatsappData.queueRedirect !== "none" &&
      !whatsappData.queueRedirectTime
    ) {
      toast.error("Selecione o intervalo de tempo para redirecionamento.");
      return;
    }
    try {
      if (whatsAppId) {
        await api.put(`/whatsapp/${whatsAppId}`, whatsappData);
      } else {
        await api.post("/whatsapp", whatsappData);
      }
      toast.success(i18n.t("whatsappModal.success"));
      handleClose();
    } catch (err) {
      toastError(err);
    }
  };

  const handleClose = () => {
    onClose();
    setWhatsApp(initialState);
    setSelectedQueueId("none");
    setSelectedQueueIds([]);
    setRedirectTime("");
  };

  useEffect(() => {
    if (whatsApp?.queueRedirect) {
      setSelectedQueueId(whatsApp?.queueRedirect);
    }
    if (whatsApp?.queueRedirectTime) {
      setRedirectTime(whatsApp?.queueRedirectTime);
    }
  }, [whatsApp]);

  async function handleSelectTime(value) {
    setRedirectTime(value);
    setLoadingTime(true);
    // await update({
    //   key: "timeRedirect",
    //   value,
    // });
    // await update({
    //   key: "queueRedirect",
    //   value: selectedQueueId,
    // });
    setLoadingTime(false);
  }
  async function handleSelectQueueRedirect(value) {
    setLoadingQueue(true);
    // if(value === "none"){
    //   setSelectedQueueId("none");
    //   setRedirectTime("none");
    //   await update({
    //     key: "queueRedirect",
    //     value: "none",
    //   });
    //   await update({
    //     key: "timeRedirect",
    //     value: "none",
    //   });
    //   toast.success("Operação atualizada com sucesso");

    //   setLoadingQueue(false);
    //   return
    // }
    setSelectedQueueId(value);
    setLoadingQueue(false);
    toast.success("Selecione o intervalo de tempo para redirecionamento.");
  }

  const renderTitle = () => {
    return (
      <Stack>
        <Typography variant="h6" sx={{ ml: 1 }}>
          {whatsAppId ? `Editar` : `Nova conexão`}
        </Typography>
        <Typography variant="caption" sx={{ ml: 1 }}>
          {whatsAppId ? `ID: ${whatsAppId}` : `Adicione uma nova conexão`}
        </Typography>
      </Stack>
    );
  };

  return (
    <div className={classes.root}>
      <Modal
        title={renderTitle()}
        open={open}
        onCancel={handleClose}
        footer={null}
      >
        <Divider sx={{ my: 1 }} />
        <Stack p={1}>
          <Formik
            initialValues={whatsApp}
            enableReinitialize={true}
            validationSchema={SessionSchema}
            onSubmit={(values, actions) => {
              setTimeout(() => {
                handleSaveWhatsApp({
                  ...values,
                  queueRedirect: selectedQueueId,
                  queueRedirectTime: redirectTime,
                  flow: selectFlow
                });
                actions.setSubmitting(false);
              }, 400);
            }}
          >
            {({
              values,
              touched,
              errors,
              handleSubmit,
              isSubmitting,
              handleChange
            }) => (
              <Form>
                <Grid container spacing={1}>
                  <Grid item xs>
                    <Stack>
                      <Typography variant="caption" color="gray">
                        Nome*
                      </Typography>
                      <Input
                        size="large"
                        id="name"
                        value={values.name}
                        onChange={handleChange}
                        name="name"
                        placeholder="Nome do canal"
                      />
                    </Stack>
                  </Grid>
                  {/* <Grid item xs={1.5} sx={{ ml: 1 }}>
                    <Stack>
                      <Typography variant="caption" color="gray">
                        Padrão*
                      </Typography>
                      <FormControlLabel
                        control={
                          <Field
                            as={Switch}
                            color="primary"
                            name="isDefault"
                            checked={values.isDefault}
                          />
                        }
                      />
                    </Stack>
                  </Grid> */}
                </Grid>
                <div>
                  <Stack sx={{ mt: 1, mb: 1 }}>
                    <Typography variant="caption" color="gray">
                      Departamentos
                    </Typography>
                    <QueueSelect
                      selectedQueueIds={selectedQueueIds}
                      onChange={selectedIds => setSelectedQueueIds(selectedIds)}
                    />
                  </Stack>
                  {user?.company?.plan?.flows ? (
                    <Stack sx={{ mt: 1, mb: 1 }}>
                      <Stack direction="row" spacing={0.5} alignItems="center">


                        <Typography variant="caption" color="gray">
                          Fluxo de boas vindas
                        </Typography>
                        <Tooltip title="Esse fluxo será ativado quando um novo contato entrar na plataforma">


                          <IconButton size="small">
                            <InfoOutlined style={{
                              fontSize: 14
                            }} />
                          </IconButton>
                        </Tooltip>
                      </Stack>
                      <FlowSelect
                        selectedTagsIds={selectFlowWelcome}
                        onChange={value => setSelectFlowWelcome(value)}
                      />
                    </Stack>
                  ) : null}
                  {user?.company?.plan?.flows ? (
                    <Stack sx={{ mt: 1, mb: 1 }}>
                      <Stack direction="row" spacing={0.5} alignItems="center">


                        <Typography variant="caption" color="gray">
                          Fluxo padrão de atendimento
                        </Typography>
                        <Tooltip title="Esse fluxo será ativado quando um contato já existente iniciar uma nova conversa.">


                          <IconButton size="small">
                            <InfoOutlined style={{
                              fontSize: 14
                            }} />
                          </IconButton>
                        </Tooltip>
                      </Stack>
                      <FlowSelect
                        selectedTagsIds={selectFlow}
                        onChange={value => setSelectFlow(value)}
                      />
                    </Stack>
                  ) : null}
                </div>
                <Collapse
                  items={[
                    {
                      key: "1",
                      label: "Opções de mensagens",
                      children: (
                        <Stack sx={{ mt: -1.5 }}>
                          <Stack>
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={1}
                            >
                              <Typography variant="caption" color="gray">
                                Mensagem de seleção de departamento
                              </Typography>
                              <Tooltip title="Mensagem que seu contato irá receber para selecionar um departamento">
                                <InfoOutlined
                                  fontSize="small"
                                  color="disabled"
                                />
                              </Tooltip>
                            </Stack>
                            <TextArea
                              size="large"
                              id="name"
                              value={values.greetingMessage}
                              onChange={handleChange}
                              name="greetingMessage"
                              placeholder="Mensagem"
                            />
                          </Stack>

                          <Stack sx={{ mt: 1 }}>
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={1}
                            >
                              <Typography variant="caption" color="gray">
                                Mensagem de finalização
                              </Typography>
                              <Tooltip title="Mensagem que seu contato irá receber quando finalizar uma conversa com seu canal">
                                <InfoOutlined
                                  fontSize="small"
                                  color="disabled"
                                />
                              </Tooltip>
                            </Stack>
                            <TextArea
                              size="large"
                              id="name"
                              value={values.complationMessage}
                              onChange={handleChange}
                              name="complationMessage"
                              placeholder="Mensagem"
                            />
                          </Stack>

                         
                        </Stack>
                      )
                    }
                  ]}
                ></Collapse>

                <Stack mt={2}>
                  <DialogActions>
                    <Button
                      onClick={handleClose}
                      color="secondary"
                      disabled={isSubmitting}
                      variant="outlined"
                    >
                      {i18n.t("whatsappModal.buttons.cancel")}
                    </Button>
                    <Button
                      type="primary"
                      onClick={handleSubmit}
                      color="primary"
                      disabled={isSubmitting}
                      variant="contained"
                      className={classes.btnWrapper}
                    >
                      {whatsAppId
                        ? i18n.t("whatsappModal.buttons.okEdit")
                        : i18n.t("whatsappModal.buttons.okAdd")}
                      {isSubmitting && (
                        <CircularProgress
                          size={24}
                          className={classes.buttonProgress}
                        />
                      )}
                    </Button>
                  </DialogActions>
                </Stack>
              </Form>
            )}
          </Formik>
        </Stack>
      </Modal>
    </div>
  );
};

export default React.memo(WhatsAppModal);
