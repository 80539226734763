import {
  Typography,
  Grid,
  Tooltip,
  Stack,
  Divider,
  Box,
  AccordionSummary,
  Accordion,
  AccordionDetails
} from "@mui/material"

import NodesCategories from "../../ConstantNodes";
import { Node } from "./styled";
import { renderIcons } from "../../../Icons";
import { ExpandMore } from "@mui/icons-material";
import "./style.css"
export const AllNodes = ({ nodes }) => {
  const onDragStart = (event, nodeType) => {
    event.dataTransfer.setData(
      "application/reactflow",
      JSON.stringify(nodeType)
    );
    event.dataTransfer.effectAllowed = "move";
  };
  const sections = {};
  for (const node of nodes) {
    if (!sections[node.category]) {
      sections[node.category] = {
        title: NodesCategories[node.category]?.title,
        color: NodesCategories[node.category]?.color,
        nodes: []
      };
    }

    sections[node.category].nodes.push(node);
  }
  return (
    <Box>
      {Object.keys(sections).map(section => (
        <Accordion defaultExpanded={sections[section].title === "Ação" ? true: false} sx={{ margin: 0 }}>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography variant="body2" fontWeight="semibold">
              {sections[section].title}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>

            {sections[section].nodes.map(node => (

              <Node
                className="dndnode"
                onDragStart={event => onDragStart(event, node)}
                draggable
              >
                <Stack
                  sx={{
                    border: "1px solid #E0E0E0",
                    borderRadius: "10px",
                    backgroundColor: "transparent"
                  }}
                >
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    spacing={1}
                    sx={{
                      backgroundColor: "transparent"
                    }}
                  >
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <Tooltip
                        title={<>{node.description}</>}
                        placement="top"
                      >
                        <Stack
                          sx={{
                            margin: 1,
                            backgroundColor:
                              NodesCategories[node.category]?.color,
                            padding: 1,
                            borderRadius: "10px 0px 0px 10px"
                          }}
                        >
                          {renderIcons(node.icon)}
                        </Stack>
                      </Tooltip>
                      <Typography variant="caption">
                        {node.name}
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
              </Node>

            ))}

          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
};
export default AllNodes
