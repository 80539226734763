import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import DialogActions from "@material-ui/core/DialogActions";
import { Input, Modal, Button } from "antd";
import { Divider, Stack, Typography, Grid } from '@mui/material';

import { i18n } from "../../translate/i18n";
import { ColorPicker } from 'antd';

import api from "../../services/api";
import toastError from "../../errors/toastError";




const QueueModal = ({ open, onClose, queueId }) => {

  const [queue, setQueue] = useState({
    name: "",
    color: "",
  });
  const [color, setColor] = useState("#0F69E7");


  useEffect(()=>{
    return () =>{
      setQueue({
        name: "",
        color: "",
      });
      setColor("#0F69E7");
    }
  },[])




  useEffect(() => {
    (async () => {
      if (!queueId) return;
      try {
        const { data } = await api.get(`/queue/${queueId}`);
        setQueue((prevState) => {
          return { ...prevState, ...data };
        });
        setColor(data.color);
      } catch (err) {
        toastError(err);
      }
    })();

    return () => {
      setQueue({
        name: "",
        color: "",
        greetingMessage: "",
      });
    };
  }, [queueId, open]);

  const handleClose = () => {
    setQueue({
      name: "",
      color: "",
      greetingMessage: "",
    })
    setColor("#0F69E7");
    onClose();
  
  };

  const handleChangeColor = (color) => { 
    let hexColor 
    try{
      hexColor = color.toHexString();
    }catch(error){

    }

    setColor(hexColor);
  }

  const handleChange = (e) => {
    queue[e.target.name] = e.target.value;
    setQueue({ ...queue });
  };
    


  const handleSaveQueue = async () => {
    try {
      if (queueId) {
        await api.put(`/queue/${queueId}`, { ...queue, color });
        toast.success("Departamento atualizado com sucesso");
      } else {
        await api.post("/queue", { ...queue, color });
        toast.success("Departamento criado com sucesso");
      }
   
      handleClose();
    } catch (err) {
      toastError(err);
    }
  };



  const renderTitle = () => {
    return <Stack   >
      <Typography variant="h6" sx={{ ml: 1 }}>
        {queueId ? `Editar departamento` : `Novo departamento`}
      </Typography>
      <Typography variant="caption" sx={{ ml: 1 }}>
        {queueId ? `Edite seu departamento e os horários de atendimento` : `Adicione um departamento e os horários de atendimento`}
      </Typography>
    </Stack>
  }
  return (

    <Modal
      title={renderTitle()}
      open={open}
      onCancel={handleClose}
      onOk={handleSaveQueue}
      okText={queueId ? "Salvar" : "Adicionar"}
    >
      <Divider sx={{ my: 1 }} />


 
        <Stack p={1}>

                <Grid container spacing={1} sx={{ p: 1 }}>
                  <Grid item xs>
                    <Stack >
                      <Typography variant="caption" color="gray">
                        Nome*
                      </Typography>
                      <Input
                        size="large"
                        id="name"
                        value={queue.name}
                        onChange={handleChange}
                        name="name"
                        placeholder="Nome do departamento"
                      />
                    </Stack>
                  </Grid>

                  <Grid item xs={1}>
                    <Stack >
                      <Typography variant="caption" color="gray">
                        Cor*
                      </Typography>
                      <ColorPicker size="large" onChange={handleChangeColor} value={color} />
                    </Stack>
                  </Grid>
                </Grid>
              
        </Stack>


     
    </Modal>
  );
};

export default QueueModal;
