import React, { useState, useContext } from "react";
import { Stack, Box, Grid, Typography } from "@mui/material"
import { Button, Input } from "antd";
import { AuthContext } from "../../context/Auth/AuthContext";
import { ReactComponent as LoginNovo } from "../../assets/bg_login.svg"
import InputPassword from "../../components/InputPassword";
import AlternativeLogins from "./AlternativeLogins"
import { Link } from "react-router-dom"
import { LoginButton } from "./styled";
import "./login.css"
import RecoveryPassModal from "../../components/RecoveryPass";

export default function SignInSide() {
  const [user, setUser] = useState({ email: "", password: "" });
  const [open, setOpen] = useState(false);
  const { handleLogin } = useContext(AuthContext);

  const handleChangeInput = e => {
    setUser({ ...user, [e.target?.name]: e.target.value });
  };

  const handleSubmit = e => {
    e.preventDefault();
    handleLogin(user);
  };

  return (
    <>
      <RecoveryPassModal
        open={open}
        onClose={() => setOpen(false)}
      />
      <Grid container wrap="nowrap" className="fullHeight" overflow="hidden" >
        <Grid item xs={4} sx={{
          backgroundColor: '#0f3058',
          height: '100%',
          borderRadius: '0px 50px 50px 0px',
          display: 'block',
          position: 'relative',
          zIndex: '0',
        }}>

          <LoginNovo
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-30%, -50%)',
              zIndex: '1',
              display: 'block',
              maxWidth: '130%',
              maxHeight: '130%',
              width: 'auto',
              height: 'auto',
              minWidth: '130%',
              minHeight: '130%',
            }}
          />

        </Grid>
        <Grid item xs={8} className="fullHeight overflow-y" >

          <Grid
            container
            className="fullHeight"
            alignItems="center"
            justifyContent="center"
          >
            <Grid item p={1} xs={9} >

              <Typography variant="h3" mb={2}>
                Bem vindo
              </Typography>
              <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>

                <Stack spacing={2} >
                  <Input
                    size="large"
                    id="email"
                    autoFocus
                    value={user.email}
                    onChange={handleChangeInput}
                    name="email"
                    type="email"
                    placeholder="Email"
                  />

                  <InputPassword
                    id="password"
                    value={user.password}
                    onChange={handleChangeInput}
                    name="password"
                    placeholder="Senha"
                    size="large"
                  />

                </Stack>

                <Stack
                  justifyContent="flex-end"
                  alignItems="center"
                  spacing={3}
                  direction="row"
                  mt={1}
                >
                  <Stack onClick={() => setOpen(true)}>
                    <Typography variant="caption" style={{
                      color: '#004468',
                      textDecoration: 'none',
                      cursor: 'pointer'
                    }}>Esqueceu sua senha?</Typography>
                  </Stack>
                  <Stack>
                    <Box
                      component={Link}
                      to="/signup"
                      sx={{
                        color: '#004468',
                        textDecoration: 'none'
                      }}
                    >
                      <Typography variant="caption">Registre-se</Typography>
                    </Box>
                  </Stack>
                </Stack>
                <Grid container justifyContent="center" mt={2}>
                  <Grid item xs>
                    <Button
                      style={{
                        width: "100%",
                        backgroundColor: "#0f3058",
                        color: "#FFFFFF",
                        "&:hover": {
                          backgroundColor: "#0f305822"
                        },
                      }}

                      type="primary"
                      onClick={handleSubmit}
                      loading={false}
                      disabled={!user.email || !user.password}
                      size="large"
                      fullWidth
                    >
                      <span>Entrar</span>
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Grid>

      </Grid>
    </>
  );
}