import React, {
  useState,
  useEffect,
  useReducer,
  useCallback,
  useContext
} from "react";
import { toast } from "react-toastify";
import Paper from "@material-ui/core/Paper";
import MainContainer from "../../components/MainContainer";
import { AuthContext } from "../../context/Auth/AuthContext";
import { makeStyles } from "@material-ui/core/styles";
import { Stack, Card, Avatar, Typography, Divider } from "@mui/material";
import useCompanies from "../../hooks/useCompanies";
import useSettings from "../../hooks/useSettings";
import timeZones from 'timezones-list'
import api from "../../services/api";

import { Button, Input, Select } from "antd";
const useStyles = makeStyles(theme => ({
  mainPaper: {
    flex: 1,
    backgroundColor: "#fff",
    overflowY: "hidden",
    borderRadius: "10px",
    ...theme.scrollbarStyles
  }
}));

const AccountInfos = () => {
  const classes = useStyles();
  const { user } = useContext(AuthContext);
  const [company, setCompany] = useState(null);
  const { find, updateSchedules } = useCompanies();
  const { getAll: getAllSettings } = useSettings();
  const [settings, setSettings] = useState(null);
  useEffect(() => {
    find(user.companyId).then(response => {
      setCompany(response);
    });
    getAllSettings().then(settingList => {
      setSettings(settingList);
    });
  }, []);


  const handleChangeSelect = (data) => {
    company["timezone"] = data
    setCompany({ ...company })
  }


  const handleChange = (e) => {
    company[e.target.name] = e.target.value
    setCompany({ ...company })
  }

  const handleSave = async () => {
    if (!company.id) return toast.error("Erro ao salvar")
    try {
      const response = await api.put(`/companies/${company.id}`, company)
      toast.success("Salvo com sucesso")
    } catch (error) {
      toast.error("Erro ao salvar")
    }
  }

  return (
    <MainContainer>
      <Stack justifyContent="center" alignItems="center" display="flex" spacing={2} sx={{ height: "98%", }}>
        <Stack
          sx={{
            width: "70%",
            height: "100%",
            backgroundColor: "#fff",
            borderRadius: "10px",
            boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px"
          }}
        >
          <Stack
            sx={{
              height: "100px",
              background:
                "linear-gradient(#0C3249b8, #0C3249f5) center center / cover no-repeat, url(https://api-prod-minimal-v510.vercel.app/assets/images/cover/cover_4.jpg)",
              backgroundPosition: "center center",
              borderRadius: "10px 10px 0 0",
              backgroundSize: "cover"
            }}
          />

          <Stack
            sx={{
              px: "60px",
              marginTop: "-60px"
            }}
            direction="row"
            spacing={2}
          >
            <Avatar
              src="https://api-prod-minimal-v510.vercel.app/assets/images/avatar/avatar_12.jpg"
              sx={{
                width: "100px",
                height: "100px",
                border: "5px solid #fff"
              }}
            />
            <Stack>
              <Typography
                variant="h5"
                sx={{ fontWeight: "bold", color: "white" }}
              >
                {company?.name}
              </Typography>
              <Typography variant="body2" sx={{ color: "white" }}>
                {company?.cpfCnpj}
              </Typography>
            </Stack>
          </Stack>
          <Stack p={3} spacing={1} sx={{
            height: "100%"

          }}>
            <Typography
              variant="h6"
              color="primary.main"
              sx={{ fontWeight: "bold" }}
            >
              Informações da conta
            </Typography>
            <Divider />
            <Stack justifyContent="space-between" sx={{
              height: "100%"
            }}>
              <Stack spacing={1}>
                <Stack>
                  <Typography variant="caption" color="gray">
                    Nome
                  </Typography>
                  <Input
                    size="large"
                    id="name"
                    value={company?.name}
                    name="name"
                    onChange={handleChange}
                    placeholder="Nome do contato"
                  />
                </Stack>
                <Stack>
                  <Typography variant="caption" color="gray">
                    CPF / CNPJ
                  </Typography>
                  <Input
                    size="large"
                    id="name"
                    value={company?.cpfCnpj}
                    name="cpfCnpj"
                    onChange={handleChange}
                    placeholder="CPF / CNPJ"
                  />
                </Stack>
                <Stack>
                  <Typography variant="caption" color="gray">
                    Telefone
                  </Typography>
                  <Input
                    size="large"
                    id="name"
                    value={company?.phone}
                    name="phone"
                    onChange={handleChange}
                    placeholder="Telefone"
                  />
                </Stack>
                <Stack>
                  <Typography variant="caption" color="gray">
                    Email
                  </Typography>
                  <Input
                    size="large"
                    id="name"
                    value={company?.email}
                    name="email"
                    onChange={handleChange}
                    placeholder="Email"
                  />
                </Stack>
                <Stack>
                  <Typography variant="caption" color="gray">
                    Endereço
                  </Typography>
                  <Input
                    size="large"
                    id="name"
                    name="endereco"
                    onChange={handleChange}
                    placeholder="Endereço"
                  />
                </Stack>

                <Stack>
                  <Typography variant="caption" color="gray">
                    Fuso horário
                  </Typography>
                  <Select
                    showSearch
                    placeholder={"Selecione uma zona"}
                    id="queue"
                    allowClear
                    onChange={handleChangeSelect}
                    value={company?.timezone}
                    options={timeZones.map((it) => ({ value: it.tzCode, label: it.name }))}
                    size="large"
                    style={{ width: '100%' }}
                  />
                </Stack>
              </Stack>

              <Stack justifyContent="flex-end" alignItems="flex-end" style={{
                width: "100%",
              }}>
                <Button
                  type="primary"
                  size="large"
                  onClick={handleSave}
                  style={{ width: "100px" }}
                >
                  Salvar
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </MainContainer>
  );
};

export default AccountInfos;
