import { Button, Divider, Grid, Typography } from "@mui/material"
import GoogleLogo from "../../../assets/googlesvg.svg"
import FacebookLogo from "../../../assets/facebooksvg.svg"

export default function AlternativeLogins({ title }) {

  return (
    <>
      <Grid container justifyContent="center" spacing={2}>
        <Grid item xs={6}>
          <Button 
            
            disabled={false}
            variant="outlined"
            fullWidth
        
            startIcon={(<img src={GoogleLogo} alt="Google" width="30px" />)}
          >
            Entrar com Google
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button 
            variant="outlined"
            disabled={false}
            fullWidth
         
            startIcon={(<img src={FacebookLogo} alt="Facebook" width="30px" />)}
          >
            Entrar com Facebook
          </Button>
        </Grid>
   
      </Grid>
      {!!title && (
        <Grid container alignItems="center" spacing={1} my={4}>
          <Grid item xs>
            <Divider />
          </Grid>
          <Grid item>
            <Typography>{title}</Typography>
          </Grid>
          <Grid item xs>
            <Divider />
          </Grid>
        </Grid>
      )}
    </>
  )
}
