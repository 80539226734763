import React, { useState, useEffect, useRef } from "react";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { Divider, Stack, Typography, Grid } from '@mui/material';
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import { i18n } from "../../translate/i18n";
import usePlans from "../../hooks/usePlans";

import api from "../../services/api";
import toastError from "../../errors/toastError";
import { Input, Modal, Button, Select } from "antd";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {
    marginRight: theme.spacing(1),
    flex: 1
  },

  extraAttr: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },

  btnWrapper: {
    position: "relative"
  },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  }
}));

const AccountSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  number: Yup.string().min(8, "Too Short!").max(50, "Too Long!"),
  email: Yup.string().email("Invalid email")
});

const EditAccountModal = ({ open, onClose, accountData, onSave }) => {
    const { list: listPlans } = usePlans();
  const classes = useStyles();
  const isMounted = useRef(true);
  const [account, setAccount] = useState(null);
  const [plans, setPlans] = useState([]);
  const [values, setValues] = useState({
    name: "",
    phone: "",
    cpfCnpj: "",
    email: "",
    plan: 0,
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

    useEffect(() => {
       if(!accountData) return
       listPlans().then((res) => {
              const plans = res?.map((item)=>{
               
                return {
                    label: item.name,
                    value: item.id
                }
              })
                setPlans(plans)
    
         })
       setValues({
        ...accountData,
        plan: accountData?.plan?.id
       })
    }, [accountData])
    
    const handleChange = (e) => {
        if(e.target.name === 'plan'){
            values[e.target.name] = e.target.value
            values["planId"] = e.target.value
            setValues({...values})
            return
        }
        values[e.target.name] = e.target.value
        setValues({...values})

    }
  const handleClose = () => {
    onClose();
    setValues({});
  };

  const handleSaveAccount = () => {
    onSave(values);
    setValues({});
  };


  const renderTitle = () => {
    return <Stack   >
      <Typography variant="h6" sx={{ ml: 1 }}>
        {`Editar ${values?.name}`}
      </Typography>
      <Typography variant="caption" sx={{ ml: 1 }}>
             Edite os dados da conta
      </Typography>
    </Stack>
  }

  return (

    <Modal
      title={renderTitle()}
      open={open}
      onCancel={handleClose}
      onOk={handleSaveAccount}
      okText="Salvar"
      cancelText="Cancelar"
      confirmLoading={loading}
    >
      <Divider sx={{ my: 1 }} />
      <Stack p={1}>


              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Stack >
                    <Typography variant="caption" color="gray">
                      Nome*
                    </Typography>
                    <Input
                      size="large"
                      id="name"
                      value={values.name}
                      onChange={handleChange}
                      name="name"
                      disabled
                      placeholder="Nome do contato"
                    />
                  </Stack>

                </Grid>
                <Grid item xs={12}>
                  <Stack >
                    <Typography variant="caption" color="gray">
                      CPF / CNPJ*
                    </Typography>
                    <Input
                      size="large"
                      id="name"
                      value={values.cpfCnpj}
                      onChange={handleChange}
                      name="cpfCnpj"
                      disabled
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <Stack >
                    <Typography variant="caption" color="gray">
                      Plano
                    </Typography>
                    <Select
                      size="large"
                      id="name"
                      options={plans}
                      value={values.plan}
                      onChange={(e) => handleChange({target: {name: 'plan', value: e}})}
                    />
                  </Stack>
                </Grid>
        </Grid>
      </Stack>
    </Modal>

  );
};

export default EditAccountModal;
