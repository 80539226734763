import styled, { css } from "styled-components"
import { Handle as Port } from "react-flow-renderer"


export const Container = styled.div.attrs((props) => props)`
  min-width: 200px;
  min-height: 100px;
  background: ${({ theme }) => theme.palette.background.paper};
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding-bottom: 10px;
  position: relative;
  ${({ selected }) =>
    selected &&
    css`
      box-shadow: rgba(0, 89, 220, 0.8) 0px 1px 1px,
        rgba(0, 89, 220, 0.8) 0px 0px 1px 1px;
    `}
`

export const Avatar = styled.div`
  width: 50px;
  height: 50px;
  padding: 10px;
  background: ${(props) => props.color}66;
  border-radius: 5px;
`

export const Tag = styled.div.attrs((props) => props)`
  background: ${({ color }) => color}66;
  border-radius: 5px;
  padding: 5px 20px;
  color: ${({ color }) => color};
  display: inline;
`

export const HandleContainer = styled.div`
  position: absolute;
  top: calc(50% - 12px);
  right: -6px;
`

export const Handle = styled(Port)`
  && {
    background: ${(props) => props.color};
    transform: translate(0);
    width: 6;
    height: 6;
    top: 6px;
    position: relative;
    &:hover {
      transform: scale(1.5);
    }
  }
`
