// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dndflow {
    flex-direction: column;
    display: flex;
    flex-grow: 1;
    height: 100%;
  }
  

  
  .dndflow .dndnode {
    height: 60px;
    border: 1px solid #dfdfdf;
    border-radius: 10px;
    margin-bottom: 10px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
    background-color: #f0f0f0;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    cursor: grab;
  }
  
  .dndflow .dndnode.input {
    border-color: #0041d0;
  }
/*   
  .dndflow .dndnode.output {
    border-color: #ff0072;
  } */
  
  .dndflow .reactflow-wrapper {
    flex-grow: 1;
    height: 100%;
  }
  
  .dndflow .selectall {
    margin-top: 10px;
  }
  
  @media screen and (min-width: 768px) {
    .dndflow {
      flex-direction: row;
    }
  
    .dndflow aside {
      width: 20%;
      max-width: 250px;
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/components/ChatBotModal/components/style.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,aAAa;IACb,YAAY;IACZ,YAAY;EACd;;;;EAIA;IACE,YAAY;IACZ,yBAAyB;IACzB,mBAAmB;IACnB,mBAAmB;IACnB,wCAAwC;IACxC,yBAAyB;IACzB,aAAa;IACb,6BAA6B;IAC7B,mBAAmB;IACnB,YAAY;EACd;;EAEA;IACE,qBAAqB;EACvB;AACF;;;KAGK;;EAEH;IACE,YAAY;IACZ,YAAY;EACd;;EAEA;IACE,gBAAgB;EAClB;;EAEA;IACE;MACE,mBAAmB;IACrB;;IAEA;MACE,UAAU;MACV,gBAAgB;IAClB;EACF","sourcesContent":[".dndflow {\n    flex-direction: column;\n    display: flex;\n    flex-grow: 1;\n    height: 100%;\n  }\n  \n\n  \n  .dndflow .dndnode {\n    height: 60px;\n    border: 1px solid #dfdfdf;\n    border-radius: 10px;\n    margin-bottom: 10px;\n    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);\n    background-color: #f0f0f0;\n    display: flex;\n    /* justify-content: center; */\n    align-items: center;\n    cursor: grab;\n  }\n  \n  .dndflow .dndnode.input {\n    border-color: #0041d0;\n  }\n/*   \n  .dndflow .dndnode.output {\n    border-color: #ff0072;\n  } */\n  \n  .dndflow .reactflow-wrapper {\n    flex-grow: 1;\n    height: 100%;\n  }\n  \n  .dndflow .selectall {\n    margin-top: 10px;\n  }\n  \n  @media screen and (min-width: 768px) {\n    .dndflow {\n      flex-direction: row;\n    }\n  \n    .dndflow aside {\n      width: 20%;\n      max-width: 250px;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
