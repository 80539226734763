import { Typography, Stack, Divider, Button, Box } from "@mui/material";
import { Input, Select } from "antd";
import InputPassword from "../../../components/InputPassword";
import InputPasswordStrength from "../../../components/InputPassword/InputPasswordStrength";
import { useState } from "react";
import toastError from "../../../errors/toastError";
import { openApi } from "../../../services/api";
function validaCpfCnpj(val) {
  if (val.length == 14) {
    var cpf = val.trim();

    cpf = cpf.replace(/\./g, "");
    cpf = cpf.replace("-", "");
    cpf = cpf.split("");

    var v1 = 0;
    var v2 = 0;
    var aux = false;

    for (var i = 1; cpf.length > i; i++) {
      if (cpf[i - 1] != cpf[i]) {
        aux = true;
      }
    }

    if (aux == false) {
      return false;
    }

    for (var i = 0, p = 10; cpf.length - 2 > i; i++, p--) {
      v1 += cpf[i] * p;
    }

    v1 = (v1 * 10) % 11;

    if (v1 == 10) {
      v1 = 0;
    }

    if (v1 != cpf[9]) {
      return false;
    }

    for (var i = 0, p = 11; cpf.length - 1 > i; i++, p--) {
      v2 += cpf[i] * p;
    }

    v2 = (v2 * 10) % 11;

    if (v2 == 10) {
      v2 = 0;
    }

    if (v2 != cpf[10]) {
      return false;
    } else {
      return true;
    }
  } else if (val.length == 18) {
    var cnpj = val.trim();

    cnpj = cnpj.replace(/\./g, "");
    cnpj = cnpj.replace("-", "");
    cnpj = cnpj.replace("/", "");
    cnpj = cnpj.split("");

    var v1 = 0;
    var v2 = 0;
    var aux = false;

    for (var i = 1; cnpj.length > i; i++) {
      if (cnpj[i - 1] != cnpj[i]) {
        aux = true;
      }
    }

    if (aux == false) {
      return false;
    }

    for (var i = 0, p1 = 5, p2 = 13; cnpj.length - 2 > i; i++, p1--, p2--) {
      if (p1 >= 2) {
        v1 += cnpj[i] * p1;
      } else {
        v1 += cnpj[i] * p2;
      }
    }

    v1 = v1 % 11;

    if (v1 < 2) {
      v1 = 0;
    } else {
      v1 = 11 - v1;
    }

    if (v1 != cnpj[12]) {
      return false;
    }

    for (var i = 0, p1 = 6, p2 = 14; cnpj.length - 1 > i; i++, p1--, p2--) {
      if (p1 >= 2) {
        v2 += cnpj[i] * p1;
      } else {
        v2 += cnpj[i] * p2;
      }
    }

    v2 = v2 % 11;

    if (v2 < 2) {
      v2 = 0;
    } else {
      v2 = 11 - v2;
    }

    if (v2 != cnpj[13]) {
      return false;
    } else {
      return true;
    }
  } else {
    return false;
  }
}
export default function RegistrationData({
  handleChange,
  values,
  confirmPassword,
  handleNext,
  handleBack,
  activeStep,
  setCompany,
}) {
  const [employees, setEmployees] = useState(null);
  const handleChangeEmployees = (value) => {
    setEmployees(value);
  };

  const handleReturn = () => {
    window.location.href = "/login";
  };

  const validationSchema = () => {
    if (!values.name) return false;
    if (!values.email || !values.email.includes("@")) return false;
    if (!values.phone) return false;
    if (!values.password) return false;
    if (!values.confirmPassword) return false;
    // if(!values.cpfCnpj) return false;
    if (!employees) return false;
    return true;
  };

  const handleSignUp = async (values) => {
    if (!validaCpfCnpj(values?.cpfCnpj)) {
      return {
        error: true,
        type: "cpfCnpj",
      };
    }
    const data = {
      email: values?.email,
      password: values?.password,
      name: values?.name,
      phone: values?.phone,
      status: true,
      cpfCnpj: values?.cpfCnpj,
      campaignsEnabled: true,
    };
    try {
      const result = await openApi.post("/companies/cadastro", data);
      if (result?.data) {
        setCompany(result?.data?.id);
      }
      return {
        error: false,
        type: "company",
      };
    } catch (err) {
      return {
        error: true,
        nessage: err?.response?.data?.error,
        type: "company",
      };
    }
  };

  const handleCheckSignUp = async (values) => {
    if (!validaCpfCnpj(values?.cpfCnpj)) {
      return {
        error: true,
        type: "cpfCnpj",
      };
    }
    const data = {
      email: values?.email,
      password: values?.password,
      name: values?.name,
      phone: values?.phone,
      status: true,
      cpfCnpj: values?.cpfCnpj,
      campaignsEnabled: true,
    };
    try {
      await openApi.post("/companies/check", data);

      return {
        error: false,
        type: "company",
      };
    } catch (err) {
      return {
        error: true,
        message: err?.response?.data?.error,
        type: "company",
      };
    }
  };

  const handleSubmit = async () => {
    const result = await handleCheckSignUp(values);
    if (result.error && result.type !== "cpfCnpj") {
      if (result.message) {
        return toastError(result.message);
      } else {
        return toastError("Erro ao cadastrar empresa");
      }
    } else {
      if (result.type === "cpfCnpj") {
        return toastError("CPF ou CNPJ inválido");
      }
    }

    handleNext();
  };

  const handleFommatCpfCnpj = (value) => {
    const cpfCnpj = value?.replace(/\D/g, "");

    if (cpfCnpj?.length <= 11) {
      const newCpf = cpfCnpj?.replace(
        /(\d{3})(\d{3})(\d{3})(\d{2})/,
        "$1.$2.$3-$4"
      );
      handleChange({ target: { name: "cpfCnpj", value: newCpf } });
    }
    if (cpfCnpj?.length > 11) {
      const newCnpj = cpfCnpj?.replace(
        /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
        "$1.$2.$3/$4-$5"
      );
      handleChange({ target: { name: "cpfCnpj", value: newCnpj } });
    }
  };

  return (
    <>
      <Stack
        sx={{
          marginTop: 3,
        }}
      >
        <Divider sx={{ mb: 3 }} />
        <Stack sx={{ mb: 3 }}>
          <Typography variant="h5" color="primary">
            Vamos começar seu cadastro.
          </Typography>
          <Typography variant="body1" color="text.secondary">
            Por favor, preencha os campos abaixo:
          </Typography>
        </Stack>
        <Stack spacing={2}>
          <Stack>
            <Typography variant="caption" color="text.secondary">
              Nome
            </Typography>
            <Input
              size="large"
              id="company"
              autoFocus
              value={values.name}
              onChange={handleChange}
              name="name"
              placeholder="Nome"
            />
          </Stack>
          <Stack>
            <Typography variant="caption" color="text.secondary">
              CPF ou CNPJ
            </Typography>
            <Input
              size="large"
              id="cpfCnpj"
              value={values.cpfCnpj}
              onChange={(e) => handleFommatCpfCnpj(e.target.value)}
              name="cpfCnpj"
              placeholder="CPF ou CNPJ"
            />
          </Stack>
          <Stack>
            <Typography variant="caption" color="text.secondary">
              Email
            </Typography>
            <Input
              size="large"
              id="email"
              value={values.email}
              onChange={handleChange}
              name="email"
              type="email"
              placeholder="Email"
            />
          </Stack>
          <Stack>
            <Typography variant="caption" color="text.secondary">
              Telefone
            </Typography>
            <Input
              size="large"
              id="telefone"
              value={values.phone}
              onChange={handleChange}
              name="phone"
              type="tel"
              placeholder="+55 (11) 99999-9999"
            />
          </Stack>
          <Stack>
            <Typography variant="caption" color="text.secondary">
              Senha
            </Typography>
            <InputPasswordStrength
              size="large"
              id="password"
              name="password"
              value={values.password}
              placeholder="Senha"
              onChange={handleChange}
              required
            />
          </Stack>
          <Stack>
            <InputPassword
              id="password"
              value={values.confirmPassword}
              onChange={handleChange}
              name="confirmPassword"
              placeholder="Confirmar senha"
              size="large"
            />
          </Stack>

          {confirmPassword === "invalid" && (
            <Typography variant="caption" color="red">
              Senhas não conferem
            </Typography>
          )}
          <Stack>
            <Typography variant="caption" color="text.secondary">
              Quantidade de funcionários
            </Typography>
            <Select
              placeholder="Quantidade de funcionários"
              id="employees"
              onChange={handleChangeEmployees}
              value={employees}
              options={EMPLOYEES_NUMBERS}
              size="large"
            />
          </Stack>
        </Stack>

        <Divider sx={{ mb: 3, mt: 3 }} />
        <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
          <Button color="inherit" onClick={handleReturn} sx={{ mr: 1 }}>
            Voltar
          </Button>
          <Box sx={{ flex: "1 1 auto" }} />
          <Button
            variant="contained"
            onClick={handleSubmit}
            disabled={!validationSchema()}
            sx={{
              backgroundColor: "#0C3249",
              color: "#FFFFFF",
            }}
          >
            Próximo
          </Button>
        </Box>
      </Stack>
    </>
  );
}

const EMPLOYEES_NUMBERS = [
  {
    value: 10,
    label: `1-10 funcionários`,
  },
  {
    value: 50,
    label: `11-50 funcionários`,
  },
  {
    value: 100,
    label: `51-100 funcionários`,
  },
  {
    value: 1000,
    label: `100+ funcionários`,
  },
];
