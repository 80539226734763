import React, { useState, useEffect, useContext, useRef } from "react";
import {
  TextField,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import PropType from "prop-types";
import ConfirmationModal from "../ConfirmationModal";
import api from "../../services/api";
import { head } from "lodash";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { i18n } from "../../translate/i18n";
import { makeStyles } from "@material-ui/core/styles";
import ButtonWithSpinner from "../ButtonWithSpinner";
import { AuthContext } from "../../context/Auth/AuthContext";
import { FormControlLabel, Switch } from "@material-ui/core";
import { toast } from "react-toastify";
import { Stack, Typography, Grid, Divider } from "@mui/material";
import { isNil, isObject, has, get } from "lodash";
import { Modal, Button, Input } from "antd";
import TextArea from "antd/es/input/TextArea";

const MessageSchema = Yup.object().shape({
  shortcode: Yup.string().required("Required"),
  message: Yup.string().required("Required")
});

const useStyles = makeStyles(theme => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "350px"
    }
  },
  list: {
    width: "100%",
    maxWidth: "350px",
    maxHeight: "200px",
    backgroundColor: theme.palette.background.paper
  },
  inline: {
    width: "100%"
  }
}));

function QuickMessageDialog(props) {
  const classes = useStyles();

  const initialMessage = {
    id: null,
    shortcode: "",
    message: "",
    isGlobal: false
  };

  const { modalOpen, saveMessage, editMessage, onClose, messageSelected } =
    props;
  const [dialogOpen, setDialogOpen] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [message, setMessage] = useState(initialMessage);
  const [loading, setLoading] = useState(false);
  const [quickMessage, setQuickMessage] = useState(null);
  const [attachmentModalOpen, setAttachmentModalOpen] = useState(false);
  const [attachment, setAttachment] = useState(null);
  const [isGlobal, setIsGlobal] = useState(false);
  const attachmentFile = useRef(null);

  const { user } = useContext(AuthContext);
  const { profile } = user;

  useEffect(() => {
    verifyAndSetMessage();
    setDialogOpen(modalOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalOpen]);

  useEffect(() => {
    verifyAndSetMessage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageSelected]);

  const messageSelectedIsValid = () => {
    return (
      isObject(messageSelected) &&
      has(messageSelected, "id") &&
      !isNil(get(messageSelected, "id"))
    );
  };

  const verifyAndSetMessage = () => {
    if (messageSelectedIsValid()) {
      const { id, message, shortcode, isGlobal } = messageSelected;
      setMessage({ id, message, shortcode, isGlobal });
    } else {
      setMessage(initialMessage);
    }
  };

  const handleClose = () => {
    onClose();
    setIsGlobal(false);
    setAttachment(null);
    setLoading(false);
  };

  const handleAttachmentFile = e => {
    const file = head(e.target.files);
    if (file) {
      setAttachment(file);
    }
  };
  const deleteMedia = async () => {
    if (attachment) {
      setAttachment(null);
      attachmentFile.current.value = null;
    }

    if (quickMessage?.mediaPath) {
      await api.delete(`/quickMessages/${quickMessage.id}/media-upload`);
      setQuickMessage(prev => ({
        ...prev,
        mediaPath: null
      }));
      toast.success(i18n.t("schedules.toasts.deleted"));
      if (typeof props.reload == "function") {
        props.reload();
      }
    }
  };

  const handleSave = async values => {
    if (messageSelectedIsValid()) {
      editMessage({
        ...messageSelected,
        ...values,
        userId: user.id,
        isGlobal
      });
    } else {
      saveMessage({
        ...values,
        userId: user.id,
        isGlobal
      });
    }
    handleClose();
  };


  const renderTitle = () => {
    return <Stack   >
      <Typography variant="h6" sx={{ ml: 1 }}>
        Mensagem rápida
      </Typography>
      <Typography variant="caption" sx={{ ml: 1 }}>
        Crie ou edite mensagens rápidas para serem utilizadas em atendimentos.
      </Typography>
    </Stack>
  }

  return (
    <Modal
      title={renderTitle()}
      open={dialogOpen}
      onCancel={handleClose}
      footer={null}
    >
      <ConfirmationModal
        title={i18n.t("campaigns.confirmationModal.deleteTitle")}
        open={confirmationOpen}
        onClose={() => setConfirmationOpen(false)}
        onConfirm={deleteMedia}
      >
        {i18n.t("campaigns.confirmationModal.deleteMessage")}
      </ConfirmationModal>
      <div style={{ display: "none" }}>
        <input
          type="file"
          ref={attachmentFile}
          onChange={e => handleAttachmentFile(e)}
        />
      </div>
      <Stack p={1}>

      <Divider sx={{mb:1}} />
        <Formik
          initialValues={message}
          enableReinitialize={true}
          validationSchema={MessageSchema}
          onSubmit={(values, actions) => {
            setLoading(true);
            setTimeout(() => {
              handleSave(values);
              actions.setSubmitting(false);
            }, 400);
          }}
        >
          {({ touched, errors, isSubmitting, values, handleSubmit, handleChange }) => (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs>
                  <Stack >
                    <Typography variant="caption" color="gray">
                      Atalho *
                    </Typography>
                    <Input
                      size="large"
                      id="shortcode"
                      value={values.shortcode}
                      onChange={handleChange}
                      name="shortcode"
                      placeholder="Atalho"
                    />
                  </Stack>

                </Grid>
                <Grid item xs={1.5}>
                <Stack >
                    <Typography variant="caption" color="gray">
                      Geral 
                    </Typography>
                  {profile === "admin" && (
                    <FormControlLabel
                      style={{ marginRight: 7, color: "gray",marginTop: 8 }}
           
                      labelPlacement="start"
                      control={
                        <Switch
                          size="small"
                          checked={isGlobal || messageSelected?.isGlobal}
                          onChange={() => setIsGlobal(!isGlobal)}
                          name="isGlobalMessage"
                          color="primary"
                        />
                      }
                    />
                  )}
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <Stack >
                    <Typography variant="caption" color="gray">
                      Resposta *
                    </Typography>
                    <TextArea
                      size="large"
                      id="message"
                      value={values.message}
                      onChange={handleChange}
                      name="message"
                      placeholder="Mensagem"
                    />
                  </Stack>

                </Grid>


              </Grid>
              <DialogActions>

                <Button onClick={handleClose} color="primary">
                  Cancelar
                </Button>
                <Button
                  loading={loading}
                  color="primary"
                  type="primary"
                  onClick={handleSubmit}
                >
                  Salvar
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Stack>
    </Modal>
  );
}

QuickMessageDialog.propType = {
  modalOpen: PropType.bool,
  onClose: PropType.func
};

export default QuickMessageDialog;
