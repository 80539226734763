export const CodeData = {
  hasInput: true,
  // port: {
  //   error: {
  //     color: "#EF5350",
  //   },
  //   success: {
  //     color: "#5FD07E",
  //   },
  // },
  category: {
    color: "#5FD07E",
  },
  statusCode: 200,
  type: "json",
  method: 'GET',
  title: "Code",
  label: "json node",
}

export default CodeData
