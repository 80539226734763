import "./index.css";

import React, { useState, useContext } from "react";
import { AuthContext } from "../../context/Auth/AuthContext";
import { Modal, Input, Select, Empty, Spin, DatePicker, Collapse } from "antd";
import pt from "antd/es/date-picker/locale/pt_BR"
import { Stack, Typography, Grid, Tooltip, Box, Divider, Avatar } from "@mui/material";
import TagSelect from "../TagSelect";
import { putObject } from "../../commons/utils/s3";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import TextArea from "antd/es/input/TextArea";
import * as XLSX from "xlsx";

import { AttachFileOutlined, DeleteOutline, Download } from "@mui/icons-material";
import FlowSelect from "../FlowSelect";
import moment from "moment";
import { toast } from "react-toastify";
import TableRowSkeleton from "../TableRowSkeleton";
import { TBody, THead, Table, TCell, TRow } from "../Table";
import ConnectionGroupSelect from "../ConnectionGroupSelect";
import ContactGroupSelect from "../ContactGroupSelect";
import CollapsePanel from "antd/es/collapse/CollapsePanel";
import ConnectionSelect from "../ConnectionSelect";

const NewCampaingModal = ({
  open,
  onClose,
}) => {
  const [contacts, setContacts] = useState([]);
  const { user } = useContext(AuthContext);
  const { companyId } = user;
  const [loading, setLoading] = useState(false);
  const [selectedContactGroup, setSelectedContactGroup] = useState(null);
  const [values, setValues] = useState({
    name: "",
    companyId: companyId,
    method: null,
    channels: [],

    scheduledAt: "",
    tags: [],
    fileUrl: null,
    fileName: null,
    attachUrl: null,
    attachName: null,
    flow: null,
    message: null,
    distance: 1

  });

  const renderTitle = () => {
    return (
      <Stack>
        <Typography variant="h6" sx={{ ml: 1 }}>
          Nova campanha
        </Typography>
        <Typography variant="caption" sx={{ ml: 1 }}>
          Crie uma nova campanha para enviar mensagens aos seus contatos
        </Typography>
      </Stack>
    );
  };

  const handleConvert = (file) => {
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: "binary" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = XLSX.utils.sheet_to_json(worksheet);
        const parseValue = json?.map((item, index) => {

          return {
            id: index,
            name: item?.nome || "",
            number: item?.numero || ""
          }
        })

        setContacts(parseValue);

      };
      reader.readAsBinaryString(file);
    }
  }

  const handleUpload = () => {
    const input = document.getElementById("inputPlan");
    input.click();
    input.onchange = e => {
      const file = e.target.files[0];
      if (file) {
        const dataFile = putObject(file.name, file, file.type);
        dataFile
          .then(data => {
            values.fileUrl = data.file;
            values.fileName = "..." + data.file.split("/").pop().slice(-15)
            handleConvert(file)
            setValues({ ...values });
          })
          .catch(err => {
            toastError(err);
          });
      }
    }
  };

  const handleUploadAttachment = () => {
    const inputAth = document.getElementById("inputAttach");
    inputAth.click();
    inputAth.onchange = e => {
      const file = e.target.files[0];
      if (file) {
        const dataFile = putObject(file.name, file, file.type);
        dataFile
          .then(data => {
            values.attachUrl = data.file;
            values.attachName = "..." + data.file.split("/").pop().slice(-15)
            setValues({ ...values });
          })
          .catch(err => {
            toastError(err);
          });
      }
    }
  };

  const handleRemoveAttach = () => {
    values.attachUrl = null;
    values.attachName = null;
    document.getElementById("inputAttach").value = "";
    setValues({ ...values });
  }

  const handleRemoveFile = () => {
    values.fileUrl = null;
    values.fileName = null;
    setContacts([])
    document.getElementById("inputPlan").value = "";
    setValues({ ...values });
  }

  const handleChange = (e) => {
    values[e.target.name] = e.target.value;
    if (e.target.name === "tags") {
      handleGetContactsTags(e.target.value)
    }
    setValues({ ...values });
  }

  const handleClose = () => {
    setValues({
      name: "",
      companyId: companyId,
      method: null,
      channels: [],

      scheduledAt: "",
      tags: [],
      fileUrl: null,
      fileName: null,
      attachUrl: null,
      attachName: null,
      flow: null,
      message: null,
      distance: 1
    });
    setContacts([]);
    setLoading(false);
    onClose();
  }

  const onSubmit = async () => {
    try {
      const dataValues = {};
      Object.entries(values).forEach(([key, value]) => {
        if (key === "scheduledAt" && value !== "" && value !== null) {
          dataValues[key] = moment(value).format("YYYY-MM-DD HH:mm:ss");
        } else {
          dataValues[key] = value === "" ? null : value;
        }
      });
      if (!values.name || !values.method || !values.channels || !values.scheduledAt) {
        return toastError("Preencha todos os campos obrigatórios")
      }
      // if (values?.scheduledAt) {
      //   const scheduledMoment = moment(values.scheduledAt);
      //   const twoMinutesFromNow = moment().add(1, 'minutes');
      //   // Verifica se o horário agendado é antes de daqui a dois minutos
      //   if (values.scheduledAt.isBefore(twoMinutesFromNow)) {
      //     return toast.error("Data de agendamento inválida. Deve ser pelo menos 2 minutos no futuro.");
      //   }
      // }
      if (!values?.flow && !values?.message) {
        return toastError("Preencha a mensagem ou selecione um fluxo")
      }
      await api.post("/broadcast", {
        companyId: companyId,
        ...values
      });
      toast.success("Campanha criada com sucesso")
      setValues({
        name: "",
        companyId: companyId,
        method: null,
        channels: [],

        scheduledAt: "",
        tags: [],
        fileUrl: null,
        fileName: null,
        attachUrl: null,
        attachName: null,
        flow: null,
        message: null,
        distance: 1
      });
      setContacts([]);
      setLoading(false);
      onClose();
    } catch (error) {
      toastError(error)
    }
  }

  const handleDownloadExample = () => {
    window.open("/planilha.xlsx", '_blank')
  }

  const handleChangeDelay = (e) => {
    values["distance"] = e.target.value
    setValues({ ...values })
  }
  
  const handleChangeSelectedContactGroup = async (value) => {    
    if (!value) {
      setSelectedContactGroup(null);
      setContacts([])
      return;
    }

    setSelectedContactGroup(value.id);

    const contacts = value.associations?.map(({ contact }) => {
      return contact;
    })
    
    setContacts(contacts || [])
  }

  const handleGetContacts = async () => {
    const delayDebounceFn = setTimeout(() => {
      const fetchContacts = async () => {
        try {
          const { data } = await api.get("/contacts/list", { 
            params: { ignoreDuplicated: "true" }
          });

          setContacts(data);
          setLoading(false);
        } catch (err) {
          toastError(err);
        }
      };
      fetchContacts();
    }, 1000);
    return () => clearTimeout(delayDebounceFn);
  };


  const handleGetContactsTags = async (tags) => {
    if (!tags.length) {
      setContacts([])
      return

    }
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchContactsTags = async () => {
        try {
          const { data } = await api.get(`/contactTags`, { params: { tags: tags?.join(",") } });
          const contacts = data.map(item => {
            return {
              id: item?.ticket?.contact?.id,
              name: item?.ticket?.contact?.name,
              number: item?.ticket?.contact?.number,
              profilePicUrl: item?.ticket?.contact?.profilePicUrl
            }
          })
          //remove duplicated values 
          const removeDuplicated = contacts.filter((contact, index, self) =>
            index === self.findIndex((t) => (
              t.id === contact.id
            ))
          )
          setContacts(removeDuplicated);
          setLoading(false);
        } catch (err) {
          toastError(err);
        }
      };
      fetchContactsTags();
    }, 1000);
    return () => clearTimeout(delayDebounceFn);
  };

  const handleChangeSelect = async (value) => {
    values["method"] = value
    values.tags = null
    setContacts([]);
    if (value === "allContacts") {
      setLoading(true);
      handleGetContacts();
    }

    setValues({ ...values })
  }

  const buddhistLocale = {
    ...pt,
    lang: {
      ...pt.lang,
      fieldDateFormat: 'YYYY-MM-DD',
      fieldDateTimeFormat: 'YYYY-MM-DD HH:mm',
      yearFormat: 'YYYY',
      cellYearFormat: 'YYYY',
    },
  };

  return (
    <>
      <Modal
        title={renderTitle()}
        open={open}
        onCancel={handleClose}
        okText="Salvar"
        onOk={onSubmit}
        cancelText="Cancelar"
        width={1200}
      >
        <Divider sx={{ my: 1 }} />

        <Box sx={{ p: 1 }}>
          <Grid spacing={4} container sx={{
            padding: 2,
          }}>
            <Grid item xs={6} >
              <Grid spacing={2} container sx={{
                backgroundColor: "#f5f5f5",
                borderRadius: 2,
                overflowY: "auto",
                maxHeight: 610
              }} p={1} pr={2}>
                <Grid xs={6} item>
                  <Stack>
                    <Typography variant="caption" color="gray">
                      Nome da campanha*
                    </Typography>
                    <Input
                      size="large"
                      id="name"
                      value={values.name}
                      onChange={handleChange}
                      name="name"
                      placeholder="Nome"
                    />
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Stack>
                    <Typography variant="caption" color="text.secondary">
                      Modo de envio*
                    </Typography>
                    <Select
                      placeholder={"Modo"}
                      id="method"
                      onChange={handleChangeSelect}
                      value={values.method}
                      options={[{
                        value: "allContacts",
                        label: "Todos os contatos"
                      }, {
                        value: "byLabel",
                        label: "Por etiqueta"
                      }, {
                        value: "bySheet",
                        label: "Por planilha"
                      }, {
                        value: "byImport",
                        label: "Por importados"
                      }].map((option) => {
                        return { value: option.value, label: option.label }
                      })}
                      size="large"
                    />
                  </Stack>
                </Grid>
                {values.method === "byLabel" && (
                  <>
                    <Grid item xs={12}>
                      <Stack>
                        <Typography variant="caption" color="gray">
                          Selecione as etiquetas
                        </Typography>
                        <TagSelect
                          selectedTagsIds={values.tags}
                          onChange={(e) => {
                            handleChange({
                              target: {
                                name: "tags",
                                value: e
                              }
                            })
                          }}
                        />
                      </Stack>
                    </Grid>
                  </>
                )}
                {values.method === "bySheet" && (
                  <>
                    <Grid
                      item
                      xs={12}

                    >
                      <Stack>
                        <Stack direction="row" spacing={1} alignItems="center" >
                          <Typography
                            variant="caption"
                            color="text.secondary"
                          >
                            Importar planilha
                          </Typography>

                        </Stack>
                        <Stack direction="row" spacing={2}>
                          <Tooltip title={"Baixar exemplo"}>

                            <Stack sx={{
                              height: 40,
                              width: 50,
                              borderRadius: 2,
                              cursor: "pointer",
                              border: "1px dashed #ccc",
                              backgroundColor: "white",
                              display: "flex",
                              "&:hover": {
                                backgroundColor: "#f5f5f5",
                              }
                            }}
                              onClick={handleDownloadExample}
                              justifyContent="center"
                              alignItems="center"
                            >

                              <Download
                                style={{ fontSize: 21, color: "black" }}
                              />


                            </Stack>
                          </Tooltip>
                          <Tooltip title={values?.fileName ? "Remover arquivo" : "Anexar arquivo"}>

                            <Stack sx={{
                              height: 40,
                              width: "100%",
                              borderRadius: 2,
                              cursor: "pointer",
                              backgroundColor: "white",
                              border: "1px dashed #ccc",
                              display: "flex",
                              "&:hover": {
                                backgroundColor: "#f5f5f5",
                              },
                              px: 1.5
                            }}
                              onClick={values?.fileName ? handleRemoveFile : handleUpload}
                              justifyContent="center"
                              alignItems="center"
                              direction="row"
                            >
                              <Stack sx={{
                                width: "100%",
                              }}>
                                <Typography variant="caption" color={values?.fileName ? "green" : "text.secondary"}>
                                  {values?.fileName || "Anexar arquivo"}
                                </Typography>
                              </Stack>
                              {values?.fileName ? (
                                <DeleteOutline
                                  style={{ fontSize: 21, color: "red" }}
                                />
                              ) : (
                                <AttachFileOutlined
                                  style={{ fontSize: 21, color: "black" }}
                                />
                              )}


                            </Stack>
                          </Tooltip>
                        </Stack>

                        <input
                          type="file"
                          id={"inputPlan"}
                          accept=".xlsx, .xls, .csv"
                          style={{
                            display: "none"
                          }} />
                      </Stack>
                    </Grid>

                  </>
                )}
                {values.method === "byImport" && (
                  <>
                    <Grid
                      item
                      xs={12}
                    >
                      <Stack>
                        <Typography variant="caption" color="text.secondary">
                          Importação selecionada
                        </Typography>
                        <ContactGroupSelect
                          value={selectedContactGroup}
                          onChange={handleChangeSelectedContactGroup}
                        />
                      </Stack>
                    </Grid>

                  </>
                )}
                <Grid xs={12} item>
                  <Stack id="contacts" sx={{ mt: 1, mb: 1 }}>
                    <Typography variant="caption" color="gray">
                      Canais principais *
                    </Typography>
                    <ConnectionSelect
                      selectedConnectionIds={values.channels}
                      onChange={(e) => {
                        handleChange({
                          target: {
                            name: "channels",
                            value: e
                          }
                        })
                      }}
                    />
                  </Stack>
                </Grid>
                <Grid xs={12} item>
                  <Collapse>
                    <CollapsePanel header="Grupos de conexões" className="custom-collapse-panel">
                      <Stack sx={{ mt: -1.5 }}>
                        <ConnectionGroupSelect
                          onAdd={(group) => {
                            for (const children of group.childrens) {
                              if (values.channels?.includes(children.whatsappId)) continue;

                              handleChange({
                                target: {
                                  name: "channels",
                                  value: [...values.channels, children.whatsappId]
                                }
                              })
                            }
                          }}
                        />
                      </Stack>
                    </CollapsePanel>
                  </Collapse>
                </Grid>
                <Grid xs={6} item>
                  <Stack>
                    <Typography variant="caption" color="gray">
                      Agendamento*
                    </Typography>
                    <DatePicker
                      size="large"
                      id="scheduledAt"
                      value={values.scheduledAt}
                      onChange={(e)=>{
                        handleChange({
                          target: {
                            name: "scheduledAt",
                            value: e
                          }
                        })
                      }}
                      name="scheduledAt"
                      locale={buddhistLocale}
                      showTime


                      type="datetime-local"
                    />
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Stack>
                    <Typography variant="caption" color="text.secondary">
                      Disparar fluxo
                    </Typography>
                    <FlowSelect
                      value={values.flow}
                      onChange={(e) => {
                        handleChange({
                          target: {
                            name: "flow",
                            value: e
                          }
                        })

                      }}
                    />
                  </Stack>
                </Grid>


                <Grid item xs={12}>
                  <Stack>
                    <Typography variant="caption" color="gray">
                      Atraso entre mensagens (segundos)
                    </Typography>
                    <Select
                      placeholder={"Selecione um intervalo"}
                      id="distance"
                      onChange={
                        (e) => {
                          handleChangeDelay({
                            target: {
                              value: e,
                              name: "distance"
                            }
                          })
                        }
                      }
                      value={values.distance}
                      options={[{
                        value: 1,
                        label: "Muito curto (de 1 à 5 segundos)"
                      },
                      {
                        value: 2,
                        label: "Curto (de 5 à 20 segundos)"
                      },
                      {
                        value: 3,
                        label: "Médio (de 20 à 50 segundos)"
                      },
                      {
                        value: 4,
                        label: "Longo (de 50 à 120 segundos)"
                      },
                      {
                        value: 5,
                        label: "Muito longo (de 120 à 300 segundos)"
                      }
                      ].map((option) => {
                        return { value: option.value, label: option.label }
                      })}
                      size="large"
                    />


                  </Stack>
                </Grid>

                {!values?.flow && (
                  <>
                    <Grid item xs={12}>
                      <Stack>
                        <Typography variant="caption" color="gray">
                          Mensagem
                        </Typography>
                        <TextArea
                          size="large"
                          id="message"
                          value={values.message}
                          onChange={handleChange}
                          name="message"
                          placeholder="Mensagem"

                        />
                        <Tooltip title={"Anexar arquivo"}>
                          <Stack sx={{
                            height: 40,
                            width: "250px",
                            mt: 1,
                            backgroundColor: "white",
                            borderRadius: 2,
                            cursor: "pointer",
                            border: "1px dashed #ccc",
                            display: "flex",
                            "&:hover": {
                              backgroundColor: "#f5f5f5",
                            },
                            px: 1.5
                          }}
                            onClick={values?.attachName ? handleRemoveAttach : handleUploadAttachment}
                            justifyContent="center"
                            alignItems="center"
                            direction="row"
                          >
                            <Stack sx={{
                              width: "100%",
                            }}>
                              <Typography variant="caption" color={values?.attachName ? "green" : "text.secondary"}>
                                {values?.attachName || "Anexar arquivo"}
                              </Typography>
                            </Stack>
                            {values?.attachName ? (
                              <DeleteOutline
                                style={{ fontSize: 21, color: "red" }}
                              />
                            ) : (
                              <AttachFileOutlined
                                style={{ fontSize: 21, color: "black" }}
                              />
                            )}


                          </Stack>

                        </Tooltip>
                        <input type="file" id={"inputAttach"} style={{
                          display: "none"
                        }} />
                      </Stack>
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid spacing={2} container sx={{
                backgroundColor: "#f5f5f5",
                borderRadius: 2,
              }} p={1} pr={2}>
                <Grid item xs={12}>
                  <Typography variant="body1" sx={{ mt: -2 }} color="text.secondary" fontWeight="bold">
                    Contatos
                  </Typography>
                  <Typography variant="body2" sx={{ mt: -0.5 }} color="text.secondary" >
                    Quantidade de contatos selecionados: {contacts?.length}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Stack sx={{
                    height: "100%",
                  }}
                    justifyContent="center"
                    alignItems="center"
                  >
                    {loading ? (
                      <Stack sx={{
                        height: "100%",
                        width: "100%",
                        display: "flex",
                        minHeight: 323,
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column"
                      }}>
                        <Spin />
                        <Typography variant="caption" color="text.secondary">
                          Carregando contatos
                        </Typography>
                      </Stack>
                    ) : (
                      <>

                        {!contacts.length ? (
                          <Stack sx={{
                            height: "100%",
                            width: "100%",
                            display: "flex",
                            minHeight: 323,
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column"
                          }}>
                            <Empty
                              image={Empty.PRESENTED_IMAGE_SIMPLE}
                              description="Nenhum contato selecionado"
                            />
                          </Stack>
                        ) : (
                          <Stack sx={{
                            maxHeight: 400,
                            overflowY: "auto",
                            width: "100%",
                          }}>
                            <Table size="small">
                              <THead sticky="true">
                                <TRow style={{ color: "#fff" }}>

                                  <TCell component="th" style={{ color: "#fff" }} align="left">
                                    <Typography variant="caption" sx={{
                                      color: "#fff",
                                      fontWeight: "bold",
                                      marginLeft: 2
                                    }}>
                                      Nome
                                    </Typography>
                                  </TCell>
                                  <TCell component="th" style={{ color: "#fff" }} noWrap>
                                    Telefone
                                  </TCell>

                                </TRow>
                              </THead>

                              <TBody >

                                {contacts.map((contact) => (
                                  <TRow key={contact.id} sx={{ position: "relative", zIndex: 1 }}>
                                    <TCell sx={{
                                      backgroundColor: !contact?.name || !contact?.number ? "rgba(255, 0, 0, 0.1)" : "#fff",
                                    }} >
                                      <Stack direction="row" alignItems="center" spacing={1}>
                                        <Avatar src={contact?.profilePicUrl || ""} />
                                        <Typography color="text.secondary" variant="inherit" sx={{ wordBreak: 'break-word' }}>
                                          {contact.name}
                                        </Typography>
                                      </Stack>
                                    </TCell>
                                    <TCell sx={{
                                      backgroundColor: !contact?.name || !contact?.number ? "rgba(255, 0, 0, 0.1)" : "#fff",
                                    }} >{contact.number}</TCell>

                                  </TRow>
                                ))}
                                {loading && <TableRowSkeleton columns={4} />}

                              </TBody>

                            </Table>
                          </Stack>
                        )}

                      </>

                    )}

                  </Stack>
                </Grid>
              </Grid>
            </Grid>

          </Grid>


        </Box>
      </Modal>
    </>
  );
};

export default NewCampaingModal;
