import { useEffect, useState } from "react";
import { Select, Tag, Typography } from "antd";
import { UserOutlined } from '@ant-design/icons';
import toastError from "../../errors/toastError";
import api from "../../services/api";

const { Option } = Select;

export function UsersFilter({ onFiltered, initialUsers }) {
  const [users, setUsers] = useState([]);
  const [selecteds, setSelecteds] = useState([]);

  useEffect(() => {
    async function fetchData() {
      await loadUsers();
    }
    fetchData();
  }, []);

  useEffect(() => {
    setSelecteds([]);
    if (
      Array.isArray(initialUsers) &&
      Array.isArray(users) &&
      users.length > 0
    ) {
      onChange(initialUsers);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialUsers, users]);

  const loadUsers = async () => {
    try {
      const { data } = await api.get(`/users/list`);
      const userList = data.map((u) => ({ id: u.id, name: u?.name }));
      setUsers(userList);
    } catch (err) {
      toastError(err);
    }
  };

  const onChange = (value) => {
    const selectedUsers = users.filter((user) => value.includes(user.id));
    setSelecteds(selectedUsers);
    onFiltered(selectedUsers);
  };

  return (
    <div style={{ padding: "4px 12px" }}>
      <Select
        mode="multiple"
        size="middle"
        style={{ width: '100%' }}
        value={selecteds.map((user) => user.id)}
        onChange={onChange}
        placeholder="Filtro por Users"
        tagRender={(props) => {
          const { label, closable, onClose } = props;
          return (
            <Tag
              onClose={onClose}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '25px',
                padding: '4px 8px',
                backgroundColor: '#F0F0F0',
                borderRadius: '5px',
                margin: '1px'
              }}
            >
              {label}
              {closable && <span onClick={onClose} style={{ marginLeft: '8px', cursor: 'pointer' }}>×</span>}
            </Tag>
          );
        }}
      >
        {users.map((user) => (
          <Option key={user.id} value={user.id}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <UserOutlined style={{ marginRight: '4px' }} />
              <Typography.Text style={{ fontWeight: 500, marginRight: '4px' }}>-</Typography.Text>
              <Typography.Text style={{ fontWeight: 500 }}>{user.name}</Typography.Text>
            </div>
          </Option>
        ))}
      </Select>
    </div>
  );
}
