import { useContext, useEffect, useState } from "react";
import { Box, useMediaQuery, Theme, DialogContent, Stack, Typography, Divider } from "@mui/material";
import { CircularProgress } from "@mui/material";
import InfiniteScroller from "../../../../../components/InfiniteScroller"
import { toast } from "react-toastify";
import { Input } from "antd";
import { Modal, Popconfirm, notification } from "antd";
import api from "../../../../../services/api";
import TextArea from "antd/es/input/TextArea";
import BuildItem from "./BuildItem"
import toastError from "../../../../../errors/toastError";

export default function ListBuildsDialog({
    open,
    onClose,
    indexName,
    branchId,
    ...rest
}) {
  const [animationEnd, setAnimationEnd] = useState(false);
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState([]);
  const [loadingIndex, setLoadingIndex] = useState(-1);

  useEffect(() => {
    if (!indexName) return;
    const fetchData = async () => {
      try {
        setLoading(true);
        const builds = await api.get(`/flows/listbuilds/${indexName}`);
        setValues(builds.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        toast.error("Erro ao buscar versões");
      }
    };
    fetchData();
  }, [indexName]);

  const handleLoadMore = async () => {}
 
  const handleResetBuild = async (build) => {
    try{
      const response = await api.post(`/flows/resetsnap/${build.id}`);
    }catch(error){
      toastError(error)
    }finally{
      window.location.reload()
    }
  }
  return (
    <Modal
      open={open}
      footer={null}
      onCancel={onClose}
      width={600}
      confirmLoading={loading}
      title={"Lista de versões"}
    >
      <Box sx={{ flexGrow: 1 }}>

            <Divider />
            <Stack spacing={1} sx={{mt: 2, 
              maxHeight: 600,
              overflowY: "auto",
              pr: 2
            }}>
              {values.map((build, index) => (
                <BuildItem
                  key={`build-item-${build.id}`}
                  item={build}
                  loading={loadingIndex === index}
                  disabled={loadingIndex > -1 && loadingIndex !== index}
                  onReset={() => handleResetBuild(build)}
                />
              ))}
            </Stack>

            {!loading && !values.length && (
              <Stack alignItems="center" justifyContent="center" p={2} pb={4}>
                Nenhuma versão encontrada
              </Stack>
            )}

            {loading && (
              <Stack alignItems="center" justifyContent="center" p={2} pb={4}>
                <CircularProgress />
              </Stack>
            )}

        </Box>
    </Modal>
  );
}
