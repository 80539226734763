export const PlansConstant = [
    {
        id:1,
        value: 549,
        name: "BASIC",
        color: "#0C3249",
    },
    {
        id: 2,
        value: 699,
        name: "ESSENTIAL",
        color: "#04A477",
    },
    {
        id: 3,
        value: 799,
        name: "PRO",
        color: "#915EFF",
    },
    {
        id: 4,
        value: 1099,
        name: "ENTERPRISE",
        color: "#E07941",
    },

]