import { Divider, Grid, IconButton, Paper, Typography } from "@mui/material"
import { Box } from "@mui/system"
import { Position, NodeProps } from "react-flow-renderer"
import { Container, HandleContainer, Handle, Tag } from "./styled"
import Node from "../../StyleNodes/Node"

export { JumpData } from "./data"
export { JumpMenu } from "./menu"

const handleStyle = { left: 10 }

const JumpNode = (node) => {
  const { data } = node


  return (
    <Node node={node} >
      {data?.payload?.port?.success && (

        <>
          <Grid
            container
            style={{ position: "relative", height: 50 }}
            spacing={1}
            px={2}
            alignItems="center"
          >
            <Grid item>
              <Typography color={node.data.payload?.port?.success?.color} variant="caption">
                SUCESSO
              </Typography>
            </Grid>
            <Grid item>

              <HandleContainer>
                <Handle
                  type="source"
                  position={Position.Right}
                  id="success"
                  color={node.data.payload?.port?.success?.color}
                />
              </HandleContainer>
            </Grid>
          </Grid>

          <Divider />

          <Grid
            container
            style={{ position: "relative", height: 50 }}
            px={2}
            spacing={1}
            alignItems="center"
          >
            <Grid item>
              <Typography color={node.data.payload?.port?.error?.color} variant="caption">
                ERRO
              </Typography>
            </Grid>

            <Grid item>

              <HandleContainer>
                <Handle
                  type="source"
                  position={Position.Right}
                  id="error"
                  color={node.data.payload?.port?.error?.color}
                />
              </HandleContainer>
            </Grid>
          </Grid>
        </>
      )}
    </Node>
  )
}

export default JumpNode
