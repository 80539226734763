import React from "react";
import Typography from "@material-ui/core/Typography";

export default function Title(props) {
	return (
		<Typography variant="h5" color="black" style={{fontWeight: "bold", marginLeft: "10px",marginTop:"10px"}} gutterBottom>
			{props.children}
		</Typography>
	);
}
