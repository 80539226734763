import React, { useState, useEffect, useRef, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import { parseISO, format, isSameDay } from "date-fns";
import { v4 as uuidv4 } from "uuid";
import { Card, Badge, Button, Avatar, Tooltip, Typography, theme } from "antd";
import {
  WhatsAppOutlined,
  FacebookOutlined,
  InstagramOutlined,
  AndroidOutlined,
  EyeOutlined,
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import api from "../../services/api";
import MarkdownWrapper from "../MarkdownWrapper";
import { AuthContext } from "../../context/Auth/AuthContext";
import { TicketsContext } from "../../context/Tickets/TicketsContext";
import toastError from "../../errors/toastError";
import TicketMessagesDialog from "../TicketMessagesDialog";
import { WhatsAppsContext } from "../../context/WhatsApp/WhatsAppsContext";
import TicketQueueModal from "../TicketQueueModal";

const { useToken } = theme;

const TicketListItemCustom = ({ ticket }) => {
  const history = useHistory();
  const { token } = useToken();
  const [loading, setLoading] = useState(false);
  const [ticketUser, setTicketUser] = useState(null);
  const [whatsAppName, setWhatsAppName] = useState(null);
  const [openTicketMessageDialog, setOpenTicketMessageDialog] = useState(false);
  const [isQueueModalVisible, setIsQueueModalVisible] = useState(false);
  const { ticketId } = useParams();
  const isMounted = useRef(true);
  const { setCurrentTicket } = useContext(TicketsContext);
  const { user } = useContext(AuthContext);
  const { whatsApps } = useContext(WhatsAppsContext);

  useEffect(() => {
    if (ticket.userId && ticket.user) {
      setTicketUser(ticket.user?.name);
    }

    if (ticket.whatsappId && whatsApps.length > 0) {
      const selectedWhatsApp = whatsApps.find(w => w.id === ticket.whatsappId);
      setWhatsAppName(selectedWhatsApp ? selectedWhatsApp.name : "SEM CANAL");
    } else {
      setWhatsAppName("SEM CANAL");
    }

    return () => {
      isMounted.current = false;
    };
  }, [ticket, whatsApps]);

  const handleCloseTicket = async (id) => {
    setLoading(true);
    try {
      await api.put(`/tickets/${id}`, {
        status: "closed",
        userId: user?.id,
        queueId: ticket.queueId,
      });
    } catch (err) {
      setLoading(false);
      toastError(err);
    }
    if (isMounted.current) {
      setLoading(false);
    }
    history.push(`/tickets/`);
  };

  const handleAcepptTicket = async (id) => {
    if (!ticket.queueId) {
      setIsQueueModalVisible(true);
      return;
    }

    setLoading(true);
    try {
      await api.put(`/tickets/${id}`, {
        status: "open",
        userId: user?.id,
        queueId: ticket.queueId,
      });
    } catch (err) {
      setLoading(false);
      toastError(err);
    }
    if (isMounted.current) {
      setLoading(false);
    }
    history.push(`/tickets/${ticket.uuid}`);
  };

  const handleQueueSelection = async (queueId) => {
    setLoading(true);
    try {
      await api.put(`/tickets/${ticket.id}`, {
        status: "open",
        userId: user?.id,
        queueId: queueId,
      });
      setIsQueueModalVisible(false);
    } catch (err) {
      setLoading(false);
      toastError(err);
    }
    if (isMounted.current) {
      setLoading(false);
    }
    history.push(`/tickets/${ticket.uuid}`);
  };

  const handleSelectTicket = (ticket) => {
    const code = uuidv4();
    const { id, uuid } = ticket;
    setCurrentTicket({ id, uuid, code });
  };

  const truncateMessage = (message, maxLength) => {
    if (message.length > maxLength) {
      return message.substring(0, maxLength) + "...";
    }
    return message;
  };

  const channelColor = (channel) => {
    switch (channel) {
      case "whatsapp":
        return "#25D366";
      case "facebook":
        return "#3b5998";
      case "instagram":
        return "#E1306C";
      default:
        return "#05AD7A";
    }
  };

  const renderBadges = () => {
    const badges = [];

    badges.push(
      <Tooltip title={"Canal"} key="whatsapp">
        <Badge
          count={whatsAppName ? whatsAppName.toUpperCase() : "SEM CANAL"}
          style={{
            backgroundColor: channelColor(ticket.channel),
            padding: "0 4px",
            borderRadius: "8px",
            color: token.colorLight,
            fontWeight: "bold",
            fontSize: "0.7em",
            border: "none",
          }}
        />
      </Tooltip>
    );

    badges.push(
      <Tooltip title={"Departamento"} key="queue">
        <Badge
          count={ticket.queue?.name ? ticket.queue.name.toUpperCase() : "SEM DEPARTAMENTO"}
          style={{
            backgroundColor: ticket.queue?.color || token.colorTextSecondary,
            padding: "0 4px",
            borderRadius: "8px",
            color: token.colorLight,
            fontWeight: "bold",
            fontSize: "0.7em",
            border: "none",
          }}
        />
      </Tooltip>
    );

    return badges;
  };

  const renderIcons = () => {
    const icons = [];

    if (["pending", "open"].includes(ticket.status)) {
      icons.push(
        <Tooltip title="Resolver" key="close">
          <Button
            type="dashed"
            shape="circle"
            size="small"
            icon={<CloseOutlined style={{ color: token.colorPrimary }} />}
            onClick={() => handleCloseTicket(ticket.id)}
            style={{ boxShadow: "none", backgroundColor: token.colorLight }}
          />
        </Tooltip>
      );
    }

    if (ticket.status === "bot") {
      icons.push(
        <Tooltip title="Chatbot" key="bot">
          <Button
            type="dashed"
            shape="circle"
            size="small"
            icon={<AndroidOutlined style={{ color: token.colorPrimary }} />}
            style={{ boxShadow: "none", backgroundColor: token.colorLight }}
          />
        </Tooltip>
      );
    }

    icons.push(
      <Tooltip title="Espiar Conversa" key="spy">
        <Button
          type="dashed"
          shape="circle"
          size="small"
          icon={<EyeOutlined style={{ color: token.colorPrimary }} />}
          onClick={() => setOpenTicketMessageDialog(true)}
          style={{ boxShadow: "none", backgroundColor: token.colorLight }}
        />
      </Tooltip>
    );

    if (["bot", "pending"].includes(ticket.status)) {
      icons.push(
        <Tooltip title="Aceitar Conversa" key="accept">
          <Button
            type="dashed"
            shape="circle"
            size="small"
            icon={<CheckOutlined style={{ color: token.colorPrimary }} />}
            onClick={() => handleAcepptTicket(ticket.id)}
            style={{ boxShadow: "none", backgroundColor: token.colorLight }}
          />
        </Tooltip>
      );
    }

    return icons;
  };

  return (
    <React.Fragment key={ticket.id}>
      <TicketMessagesDialog
        open={openTicketMessageDialog}
        handleClose={() => setOpenTicketMessageDialog(false)}
        ticketId={ticket.id}
      />
      <TicketQueueModal
        open={isQueueModalVisible}
        onClose={() => setIsQueueModalVisible(false)}
        onSelectQueue={handleQueueSelection}
        user={user}
      />
      <Card
        onClick={() => {
          if (ticket.status !== "pending") {
            handleSelectTicket(ticket);
          }
        }}
        style={{
          width: "100%",
          marginBottom: 0,
          cursor: ticket.status === "pending" ? "unset" : "pointer",
          border: "1px solid #d9d9d9",
        }}
        actions={[
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              marginTop: "-12px",
              marginBottom: "-12px",
              paddingLeft: "8px",
              paddingRight: "8px",
              backgroundColor: token.colorLight,
              borderBottomLeftRadius: "8px",
              borderBottomRightRadius: "8px",
            }}
          >
            <div style={{ display: "flex", gap: "8px", padding: 2 }}>
              {renderBadges()}
            </div>
            <div style={{ display: "flex", gap: "8px", padding: 2 }}>
              {renderIcons()}
            </div>
          </div>
        ]}
      >
        <Card.Meta
          avatar={
            <Badge count={ticket.unreadMessages ? ticket.unreadMessages : 0} offset={[-5, 10]}>
              <div
                style={{
                  position: "relative",
                  width: 52,
                  height: 52,
                  borderRadius: "50%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: 3,
                  border: `2px solid ${ticket.unreadMessages ? token.colorNotification : "#d9d9d9"}`,
                }}
              >
                <Avatar
                  src={ticket?.contact?.profilePicUrl}
                  shape="circle"
                  size={48}
                />
              </div>
            </Badge>
          }
          title={
            <div style={{ fontSize: "1.0em", marginTop: 7, display: "flex", justifyContent: "space-between" }}>
              <span>
                {ticket.channel === "whatsapp" && (
                  <WhatsAppOutlined style={{ color: "#25D366", marginRight: 5 }} />
                )}
                {ticket.channel === "facebook" && (
                  <FacebookOutlined style={{ color: "#3b5998", marginRight: 5 }} />
                )}
                {ticket.channel === "instagram" && (
                  <InstagramOutlined style={{ color: "#E1306C", marginRight: 5 }} />
                )}
                {ticket.contact?.name}
              </span>
              <span style={{ fontSize: "0.9em" }}>
                {isSameDay(parseISO(ticket.updatedAt), new Date())
                  ? format(parseISO(ticket.updatedAt), "HH:mm")
                  : format(parseISO(ticket.updatedAt), "dd/MM/yyyy")}
              </span>
            </div>
          }
          style={{ margin: "-20px -14px -20px -14px" }}
          description={
            <>
              <div style={{ marginTop: -10, display: "flex", justifyContent: "space-between" }}>
                <div>
                  <Typography.Text ellipsis>
                    {ticket.lastMessage && ticket.lastMessage.includes("data:image/png;base64") ? (
                      <MarkdownWrapper> Localização</MarkdownWrapper>
                    ) : (
                      <MarkdownWrapper>{truncateMessage(ticket.lastMessage || "", 45)}</MarkdownWrapper>
                    )}
                  </Typography.Text>
                </div>
              </div>
            </>
          }
        />
      </Card>
    </React.Fragment>
  );
};

export default TicketListItemCustom;
