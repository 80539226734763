import React, { useEffect, useState } from "react";
import { Select, Typography } from "antd";
import { WifiOutlined } from '@ant-design/icons';
import toastError from "../../errors/toastError";
import api from "../../services/api";

const ConnectionSelect = ({ selectedConnectionIds, onChange }) => {
  const [connections, setConnections] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const { data } = await api.get("/whatsapp");

        const connectionsData = data.map((item) => {
          return {
            value: item.id,
            label: (
              <div style={{ display: 'flex', alignItems: 'center', marginRight: '4px' }}>
                <WifiOutlined style={{
                  width: '15px',
                  height: '15px',
                  color: item.status === "CONNECTED" ? "green" : "red",
                  marginRight: '4px'
                }} />
                <Typography.Text style={{ fontWeight: 500, marginRight: '4px' }}>-</Typography.Text>
                <Typography.Text style={{ fontWeight: 500 }}>{item.name}</Typography.Text>
              </div>
            ),
          };
        });
        setConnections(connectionsData);
      } catch (err) {
        toastError(err);
      }
    })();
  }, []);

  const filterOption = (input, option) => {
    //filter by connection name
    return (
      option.label.props.children[2].props.children
        .toLowerCase()
        .indexOf(input.toLowerCase()) >= 0
    );
  };

  return (
    <Select
      mode="multiple"
      placeholder={"Selecione as conexões"}
      id="connection"
      allowClear
      filterOption={filterOption}
      optionFilterProp="children"
      onChange={onChange}
      value={selectedConnectionIds}
      options={connections}
      size="large"
      style={{ width: "100%" }}
      maxTagCount="responsive"
      tagRender={(props) => {
        const { label, closable, onClose } = props;
        return (
          <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '30px',
            padding: '4px 8px',
            backgroundColor: '#F0F0F0',
            borderRadius: '5px',
            margin: '1px'
          }}>
            {label}
            {closable && <span onClick={onClose} style={{ marginLeft: '8px', cursor: 'pointer' }}>×</span>}
          </div>
        );
      }}
    />
  );
};

export default ConnectionSelect;
