
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Popover,
  Stack,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material"
import NodeMenu from "../../../StyleNodes/Menu"

import AceEditor from "react-ace"

import "ace-builds/src-noconflict/theme-github"
import "ace-builds/src-noconflict/mode-json"
import { ExpandMore, InfoOutlined, UploadFileOutlined } from "@mui/icons-material"
import React, { useRef, useState } from "react"
import { i18n } from "../../../../../../../translate/i18n";
import { Input, Select } from "antd"
import { renderIcons } from "../../../../Icons"

const handleStyle = { left: 10 }

export const StartLoopMenu = ({ node, anchorRef }) => {
  const { data } = node
  const [anchorEl, setAnchorEl] = useState(null)
  const [values, setValues] = useState(data)
  const [openImport, setOpenImport] = useState(false)

  const handleRun = () => { }

  const handleChange = ({ target }) => {
    values[target.name] = target.value
    setValues({ ...values })
  }

  const handleChangeAlias = ({ target }) => {
    if (target.value.length > 20) return
    target.value = target.value.replace(/[^a-zA-Z0-9]/g, "")
    values[target.name] = target.value
    setValues({ ...values })
  }
  const handleChangeSelect = (value) => {
    values["mode"] = value
    setValues({ ...values })
  }


  return (
    <NodeMenu
      open
      node={node}
      anchorRef={anchorRef}
      values={values}
      onChange={handleChange}
      onRun={handleRun}
    >
      <Stack direction="row" alignItems="center" spacing={1}>
        <Stack sx={{
          backgroundColor: `#0C3249`,
          padding: 1,
          borderRadius: "10px 10px 10px 10px"
        }}
        >
          {renderIcons(data?.payload?.icon)}
        </Stack>
        <Stack spacing={0}>
          <Typography variant="h6"> {i18n.t('Iniciar loop')}</Typography>
          <Typography color="text.secondary" sx={{ mt: -0.7 }}>
            {i18n.t('Inicie um loop no seu fluxo')}
          </Typography>

        </Stack>
      </Stack>
      <Divider sx={{ my: 1 }} />
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Stack>
            <Stack direction="row" alignItems="center" spacing={0.5}>
            <Typography variant="caption" color="text.secondary">
              {i18n.t('Alias')}
            </Typography>
              <Tooltip title="O alias é um nome que você pode dar para o loop, para que você possa identificá-lo mais facilmente.">
                <IconButton size="small">
                  <InfoOutlined sx={{fontSize: "12px"}} />
                </IconButton>
              </Tooltip>

            </Stack>
            <Input
              placeholder="Alias"
              name="alias"
              value={values.alias}
              onChange={handleChangeAlias}
              size="large"
            />
          </Stack>
        </Grid>
        <Grid item xs>
          <Stack>
            <Typography variant="caption" color="text.secondary">
              {i18n.t('Modo')}
            </Typography>
            <Select
              placeholder={i18n.t('Modo')}
              id="mode"
              onChange={handleChangeSelect}
              value={values.mode}
              options={[{
                label: "Percorrer número de vezes",
                value: "times"
              }, {
                label: "Percorrer dado",
                value: "data"
              }].map((option) => {
                return { value: option.value, label: option.label }
              })}
              size="large"
            />
          </Stack>
        </Grid>
        {values.mode === "times" && (
          <Grid item xs={2.5}>
            <Stack>
              <Typography variant="caption" color="text.secondary">
                {i18n.t('Número de vezes')}
              </Typography>
              <Input
                placeholder="5"
                name="times"
                type="number"
                value={values.times}
                onChange={handleChange}
                size="large"
              />
            </Stack>
          </Grid>
        )}
      </Grid>
      <Divider sx={{ my: 1 }} />
      {values.mode === "data" && (
        <Box my={1}>
          <Typography variant="caption" color="text.secondary">
            Dados
          </Typography>
          <AceEditor
            mode="json"
            theme="github"
            value={values.data}
            onChange={(value) =>
              handleChange({
                target: { name: "data", value },
              } )
            }
            setOptions={{
              useWorker: false,
            }}
            maxLines={20}
            minLines={10}
            name="UNIQUE_ID_OF_DIV"
            editorProps={{ $blockScrolling: true }}
            style={{ width: "100%" }}
          />
        </Box>
      )}
    </NodeMenu>
  )
}

export default StartLoopMenu
