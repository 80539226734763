import { TBody, THead, Table } from "../../../../components/Table";
import Row from "./TraceRow";
import { Stack } from "@mui/material";

export default function TraceLogs({
  item,
}) {
  return (
    <Stack sx={{backgroundColor: "#f5f5f5", borderRadius: "5px", px:1}}>
    <Table>
       
           <TBody  >
                {item?.map((trace, index) => (
                    <Row key={`trace-${index}`} item={trace} index={index} />
                ))}
           </TBody>
    </Table>
    </Stack>
  )
}

