import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { Badge, Input, Tooltip, Button, theme } from 'antd';
import { SearchOutlined, RobotOutlined, CheckSquareOutlined, MessageOutlined, BellOutlined, PlusCircleOutlined, EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import api from "../../services/api";
import NewTicketModal from "../NewTicketModal";
import TicketsList from "../TicketsListCustom";
import { AuthContext } from "../../context/Auth/AuthContext";
import { Can } from "../Can";
import TicketsQueueSelect from "../TicketsQueueSelect";
import { TagsFilter } from "../TagsFilter";
import { UsersFilter } from "../UsersFilter";

const { useToken } = theme;

const TicketsManagerTabs = () => {
  const history = useHistory();
  const { token } = useToken();
  const [searchParam, setSearchParam] = useState("");
  const [activeTab, setActiveTab] = useState("open");
  const [newTicketModalOpen, setNewTicketModalOpen] = useState(false);
  const [showAllTickets, setShowAllTickets] = useState(false);
  const searchInputRef = useRef();
  const searchInputRefAditional = useRef();
  const { user } = useContext(AuthContext);
  const { profile } = user;

  const [openCount, setOpenCount] = useState(0);
  const [pendingCount, setPendingCount] = useState(0);
  const [botCount, setBotCount] = useState(0);
  const [customSearch, setCustomSearch] = useState();
  const userQueueIds = user.queues.map(q => q.id);
  const [selectedQueueIds, setSelectedQueueIds] = useState(userQueueIds || []);
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [windowMode, setWindowMode] = useState("full");

  useEffect(() => {
    if (user.profile.toUpperCase() === "ADMIN") {
      setShowAllTickets(true);
    }
  }, [user.profile]);

  useEffect(() => {
    if (activeTab === "search" && searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [activeTab]);

  useEffect(() => {
    const windowSize = window.innerWidth;
    if (windowSize < 1400) {
      setWindowMode("small");
    } else {
      setWindowMode("full");
    }
  }, []);

  useEffect(() => {
    const observer = new ResizeObserver(() => {
      window.requestAnimationFrame(() => {
        document.body.style.transform = 'scale(1)';
        document.body.style.transform = '';
      });
    });

    observer.observe(document.body);

    return () => observer.disconnect();
  }, [activeTab]);

  let searchTimeout;

  const handleSearch = e => {
    const searchedTerm = e.target.value.toLowerCase();

    clearTimeout(searchTimeout);

    if (searchedTerm === "") {
      setSearchParam(searchedTerm);
      setActiveTab("open");
      return;
    }

    searchTimeout = setTimeout(() => {
      setSearchParam(searchedTerm);
    }, 500);
  };

  const handleSearchAditional = async e => {
    const valueParam = e.target.value;
    if (valueParam.length < 3) {
      return;
    }
    const { data } = await api.get(`/search-contact-custom/${valueParam}`);
    if (data.length) {
      setCustomSearch(data);
    } else {
      setCustomSearch(null);
    }
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const handleCloseOrOpenTicket = ticket => {
    setNewTicketModalOpen(false);
    if (ticket !== undefined && ticket.uuid !== undefined) {
      history.push(`/tickets/${ticket.uuid}`);
    }
  };

  const handleSelectedTags = selecteds => {
    const tags = selecteds.map(t => t.id);
    setSelectedTags(tags);
  };

  const handleSelectedUsers = selecteds => {
    const users = selecteds.map(t => t.id);
    setSelectedUsers(users);
  };

  const renderTicketsList = (status) => {
    return (
      <div style={{ overflowY: "auto", width: "100%", height: "100%", backgroundColor: token.colorLight, boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)", padding: "10px" }}>
        <TicketsList
          status={status}
          showAll={showAllTickets}
          selectedQueueIds={selectedQueueIds}
          updateCount={(val) => {
            if (status === "open") setOpenCount(val);
            if (status === "pending") setPendingCount(val);
            if (status === "bot") setBotCount(val);
          }}
          style={{ borderRadius: "12px", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)" }}
        />
      </div>
    );
  };

  return (
    <div style={{ backgroundColor: token.colorLight, position: "relative", display: "flex", width: "100%", height: "100%", flexDirection: "column", overflowY: "hidden" }}>
      <NewTicketModal
        modalOpen={newTicketModalOpen}
        onClose={ticket => {
          handleCloseOrOpenTicket(ticket);
        }}
      />
      <div style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: token.colorPrimary,
        padding: "16px 8px",
        margin: "10px",
        borderRadius: "12px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)"
      }}>
        <Tooltip title="Novo atendimento">
          <Button
            shape="default"
            icon={<PlusCircleOutlined style={{ color: token.colorPrimary }} />}
            size="large"
            type="dashed"
            style={{ marginRight: 8, background: token.colorLight }}
            onClick={() => setNewTicketModalOpen(true)}
          />
        </Tooltip>
        <Can
          role={user.profile}
          perform="tickets-manager:showall"
          yes={() => (
            <>
              <Tooltip title={showAllTickets ? "Meus Tickets" : "Todos os Tickets"}>
                <Button
                  shape="default"
                  icon={showAllTickets ? <EyeOutlined style={{ color: token.colorPrimary }} /> : <EyeInvisibleOutlined style={{ color: token.colorPrimary }} />}
                  size="large"
                  type="dashed"
                  style={{ marginRight: 8, background: token.colorLight }}
                  onClick={() => setShowAllTickets(prevState => !prevState)}
                />
              </Tooltip>
            </>
          )}
        />
        <Tooltip title="Atendimentos">
          <Badge count={openCount} offset={[-15, 0]}>
            <Button
              shape="default"
              icon={<MessageOutlined style={{ color: token.colorPrimary }} />}
              size="large"
              type="dashed"
              style={{
                marginRight: 8,
                background: token.colorLight,
                border: activeTab === "open" ? `2px solid ${token.colorSecondary}` : '2px solid transparent',
                boxShadow: activeTab === "open" ? `0 0 0 2px ${token.colorSecondary}` : 'none'
              }}
              onClick={() => handleTabChange("open")}
            />
          </Badge>
        </Tooltip>
        <Tooltip title="Aguardando">
          <Badge count={pendingCount} offset={[-15, 0]}>
            <Button
              shape="default"
              icon={<BellOutlined style={{ color: token.colorPrimary }} />}
              size="large"
              type="dashed"
              style={{
                marginRight: 8,
                background: token.colorLight,
                border: activeTab === "pending" ? `2px solid ${token.colorSecondary}` : '2px solid transparent',
                boxShadow: activeTab === "pending" ? `0 0 0 2px ${token.colorSecondary}` : 'none'
              }}
              onClick={() => handleTabChange("pending")}
            />
          </Badge>
        </Tooltip>
        <Tooltip title="ChatBot">
          <Badge count={botCount} offset={[-15, 0]}>
            <Button
              shape="default"
              icon={<RobotOutlined style={{ color: token.colorPrimary }} />}
              size="large"
              type="dashed"
              style={{
                marginRight: 8,
                background: token.colorLight,
                border: activeTab === "bot" ? `2px solid ${token.colorSecondary}` : '2px solid transparent',
                boxShadow: activeTab === "bot" ? `0 0 0 2px ${token.colorSecondary}` : 'none'
              }}
              onClick={() => handleTabChange("bot")}
            />
          </Badge>
        </Tooltip>
        <Tooltip title="Resolvidos">
          <Button
            shape="default"
            icon={<CheckSquareOutlined style={{ color: token.colorPrimary }} />}
            size="large"
            type="dashed"
            style={{
              marginRight: 8,
              background: token.colorLight,
              border: activeTab === "closed" ? `2px solid ${token.colorSecondary}` : '2px solid transparent',
                boxShadow: activeTab === "closed" ? `0 0 0 2px ${token.colorSecondary}` : 'none'
            }}
            onClick={() => handleTabChange("closed")}
          />
        </Tooltip>
        <Tooltip title="Pesquisa Avançada">
          <Button
            shape="default"
            icon={<SearchOutlined style={{ color: token.colorPrimary }} />}
            size="large"
            type="dashed"
            style={{
              background: token.colorLight,
              border: activeTab === "search" ? `2px solid ${token.colorSecondary}` : '2px solid transparent',
                boxShadow: activeTab === "search" ? `0 0 0 2px ${token.colorSecondary}` : 'none'
            }}
            onClick={() => setActiveTab("search")}
          />
        </Tooltip>
      </div>
      <div style={{ flex: 1, display: activeTab === "open" ? 'block' : 'none', overflowY: 'auto' }}>
        {renderTicketsList("open")}
      </div>
      <div style={{ flex: 1, display: activeTab === "pending" ? 'block' : 'none', overflowY: 'auto' }}>
        {renderTicketsList("pending")}
      </div>
      <div style={{ flex: 1, display: activeTab === "bot" ? 'block' : 'none', overflowY: 'auto' }}>
        {renderTicketsList("bot")}
      </div>
      <div style={{ flex: 1, display: activeTab === "closed" ? 'block' : 'none', overflowY: 'auto' }}>
        {renderTicketsList("closed")}
      </div>
      {activeTab === "search" && (
        <div style={{ padding: "0px 4px", width: "100%", opacity: activeTab === "search" ? 1 : 0 }}>
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center", background: token.colorLight, width: "100%" }}>
            <div style={{ flex: 1, display: "flex", padding: "4px 8px", width: "100%" }}>
              <Input
                style={{ width: "100%" }}
                ref={searchInputRef}
                placeholder="Buscar atendimentos e mensagens"
                type="search"
                onChange={handleSearch}
                suffix={<SearchOutlined style={{ color: token.colorTextPrimary }} />}
              />
            </div>
            <div style={{ flex: 1, display: "flex", padding: "4px 8px", width: "100%" }}>
              <Input
                style={{ width: "100%" }}
                ref={searchInputRefAditional}
                placeholder="Buscar por campos adicionais do usuário"
                type="search"
                onChange={handleSearchAditional}
                suffix={<SearchOutlined style={{ color: token.colorTextPrimary }} />}
              />
            </div>
          </div>
        </div>
      )}
      {activeTab === "search" && (
        <div style={{ flex: 1, display: "flex", flexDirection: "column", padding: "4px 0", opacity: 1, overflowY: 'auto' }}>
          <TagsFilter onFiltered={handleSelectedTags} />
          {profile === "admin" && (
            <UsersFilter onFiltered={handleSelectedUsers} />
          )}
          <TicketsQueueSelect
            selectedQueueIds={selectedQueueIds}
            userQueues={user?.queues}
            onChange={values => setSelectedQueueIds(values)}
          />
          <TicketsList
            searchParam={searchParam}
            showAll={true}
            customSearch={customSearch}
            tags={selectedTags}
            users={selectedUsers}
            selectedQueueIds={selectedQueueIds}
            style={{ borderRadius: "12px", margin: "0px 10px 6px 10px", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)" }}
          />
        </div>
      )}
    </div>
  );
};

export default TicketsManagerTabs;
