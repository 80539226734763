// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ant-select-outlined.ant-select-multiple .ant-select-selection-item {
    background: rgba(0, 0, 0, 0.06);
    border: 1px solid transparent;
    height: 30px !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/Flow/Diagram/Builder/nodes/timeInterval/menu/style.css"],"names":[],"mappings":"AAAA;IACI,+BAA+B;IAC/B,6BAA6B;IAC7B,uBAAuB;AAC3B","sourcesContent":[".ant-select-outlined.ant-select-multiple .ant-select-selection-item {\n    background: rgba(0, 0, 0, 0.06);\n    border: 1px solid transparent;\n    height: 30px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
