import {
  Typography,
  Stack,
  Divider,
  Button,
  Box,
  Grid,
  IconButton,
  Avatar
} from "@mui/material";
import { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import FreePlan from "./PlansConstants/Free";
import BasicPlan from "./PlansConstants/Basic";
import EssentialPlan from "./PlansConstants/Essential";
import ProPlan from "./PlansConstants/Pro";
import EnterPrisePlan from "./PlansConstants/Enterprise";
import { toast } from "react-toastify";
import api, { openApi } from "../../../services/api";
import toastError from "../../../errors/toastError";
import GenericPlan from "./PlansConstants/GenericPlan";

export default function SelectPlanStep({ handleNext, setPlan, plan,company , method}) {
  const [confirmationCode, setConfirmationCode] = useState(null);
  const [open, setOpen] = useState(false);
  const [plans, setPlans] = useState([]);
  const [visiblePlans, setVisiblePlans] = useState([]);


  useEffect(()=>{
    if(!company) return;
    
      const response =  openApi.get("/plans/public").then((response) => {
      setPlans(response.data);
    }).catch((error) => {
      toastError("Erro ao buscar os planos");
    });
    

    return () => {
      setPlans([]);
    };
  },[company,setPlans]);

  const handleSubmitPayment = async (type) => {
    // if(!company) return;

    const data = {
      companyId: company,
      plan: type,
    };
    try {
      if(method === "create"){
        const result = await openApi.post("/payment", data);
        return handleNext();
      }
      if(method === "upgrade"){
        const result = await api.post("/updateplan", data);
        return handleNext();
 
      }
    } catch (error) {
      if (error?.response?.data?.error) {
        return toastError(error?.response?.data?.error[0]?.description);
      }
    }
  };

  const handleSubmit = async type => {
    setPlan(type);
    handleNext();
  
  };
  useEffect(() => {
    if (!plan) {
      return;
    }
    if (plan?.name === "FREE") {
      setVisiblePlans(["BASIC", "ESSENTIAL", "PRO", "ENTERPRISE"]);
    }
    if (plan?.name === "BASIC") {
      setVisiblePlans(["ESSENTIAL", "PRO", "ENTERPRISE"]);
    }
    if (plan?.name === "ESSENTIAL") {
      setVisiblePlans(["PRO", "ENTERPRISE"]);
    }
    if (plan?.name === "PRO") {
      setVisiblePlans(["ENTERPRISE"]);
    }
  }, [plan]);
  



  return (

        <Stack
          direction="row"
          spacing={2}
          padding={2}
          alignItems="center"
          justifyContent="center"
          maxWidth="100%"
          width="100%"
          overflow="auto"
        >
          {/* <BasicPlan
            onSelect={() => handleSubmitPayment("BASIC")}
            disabled={!visiblePlans.includes("BASIC")}
          />

          <EssentialPlan
            onSelect={() => handleSubmitPayment("ESSENTIAL")}
            disabled={!visiblePlans.includes("ESSENTIAL")}
          /> */}

          {plans.length && plans.map((item) => (
                <GenericPlan
                  onSelect={() => handleSubmitPayment(item.name)}
                  disabled={!visiblePlans.includes(item.name)}
                  plan={item}
                />

              ))}
          {/* <GenericPlan
            onSelect={() => handleSubmitPayment("PRO")}
            disabled={!visiblePlans.includes("PRO")}
          /> */}
          {/* <ProPlan
            onSelect={() => handleSubmitPayment("PRO")}
            disabled={!visiblePlans.includes("PRO")}
          /> */}

          <EnterPrisePlan
            onSelect={() => {
              toast.info("Para contratar o plano Enterprise, entre em contato com o nosso time de vendas.");
            }}
            disabled={!visiblePlans.includes("ENTERPRISE")}
          />
        </Stack>
  );
}
