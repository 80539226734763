import { Select } from "antd";
import React, { useEffect, useState } from "react";

import { Stack, Typography } from "@mui/material";

import toastError from "../../errors/toastError";

import api from "../../services/api";

const ContactGroupSelect = ({ value, onChange }) => {
  const [contactGroups, setContactGroups] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const { data } = await api.get("/contact-groups/list");

        setContactGroups(data.contactGroups);
      } catch (err) {
        toastError(err);
      }
    })();
  }, []);

  const filterOption = (input, option) => {
    return (
      option.label.props.children[0].props.children
        .toLowerCase()
        .indexOf(input.toLowerCase()) >= 0
    );
  };

  const handleChange = (value) => {
    const item = contactGroups.find(({ id }) => {
      return id === value;
    });

    onChange(item);
  };

  return (
    <Select
      placeholder={"Selecione uma importação"}
      id="contact-group"
      allowClear
      filterOption={filterOption}
      optionFilterProp="children"
      onChange={handleChange}
      value={value}
      size="large"
      style={{ width: "100%" }}
      options={contactGroups.map((item) => {
        return {
          value: item.id,
          label: (
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              mt={0.5}
              mr={1}
            >
              <Typography variant="body2" sx={{ fontWeight: 500 }}>
                {item?.name}
              </Typography>
            </Stack>
          ),
        };
      })}
    />
  );
};

export default ContactGroupSelect;
