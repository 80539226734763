import React, {
  useState,
  useEffect,
  useReducer,
  useCallback,
  useContext
} from "react";
import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import { TBody, THead, Table, TCell, TRow } from "../../components/Table";

import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditIcon from "@material-ui/icons/Edit";
import Search from "antd/es/input/Search"

import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import Title from "../../components/Title";
import { Button } from "antd"

import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import PlanDialog from "../../components/PlanModal";
import ConfirmationModal from "../../components/ConfirmationModal";
import toastError from "../../errors/toastError";
import { Chip } from "@material-ui/core";
import { socketConnection } from "../../services/socket";
import { AuthContext } from "../../context/Auth/AuthContext";
import { Stack, Typography } from "@mui/material";
import CreatePlanModal from "../../components/PlanDialogPage";

const reducer = (state, action) => {
  if (action.type === "LOAD_TAGS") {
    const plans = action.payload;
    const newPlans = [];

    plans.forEach(plan => {
      const planIndex = state.findIndex(s => s.id === plan.id);
      if (planIndex !== -1) {
        state[planIndex] = plan;
      } else {
        newPlans.push(plan);
      }
    });

    return [...state, ...newPlans];
  }

  if (action.type === "UPDATE_TAGS") {
    const plan = action.payload;
    const planIndex = state.findIndex(s => s.id === plan.id);

    if (planIndex !== -1) {
      state[planIndex] = plan;
      return [...state];
    } else {
      return [plan, ...state];
    }
  }

  if (action.type === "DELETE_TAG") {
    const planId = action.payload;

    const planIndex = state.findIndex(s => s.id === planId);
    if (planIndex !== -1) {
      state.splice(planIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const useStyles = makeStyles(theme => ({
  mainPaper: {
    flex: 1,
    backgroundColor: "#fff",
    overflowY: "hidden",
    borderRadius: "10px",
    ...theme.scrollbarStyles,
  }
}));

const Plans = () => {
  const classes = useStyles();

  const { user } = useContext(AuthContext);

  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [deletingPlan, setDeletingPlan] = useState(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [searchParam, setSearchParam] = useState("");
  const [plans, dispatch] = useReducer(reducer, []);
  const [planModalOpen, setPlanModalOpen] = useState(false);

  const fetchPlans = useCallback(async () => {
    try {
      const { data } = await api.get("/plans/", {
        params: { searchParam, pageNumber }
      });
      dispatch({ type: "LOAD_TAGS", payload: data.plans });
      setHasMore(data.hasMore);
      setLoading(false);
    } catch (err) {
      toastError(err);
    }
  }, [searchParam, pageNumber]);

  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [searchParam]);

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      fetchPlans();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchParam, pageNumber, fetchPlans]);

  useEffect(() => {
    const socket = socketConnection({ companyId: user.companyId });

    socket.on("user", data => {
      if (data.action === "update" || data.action === "create") {
        dispatch({ type: "UPDATE_TAGS", payload: data.plans });
      }

      if (data.action === "delete") {
        dispatch({ type: "DELETE_USER", payload: +data.planId });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, [user]);

  const handleOpenPlanModal = () => {
    setSelectedPlan(null);
    setPlanModalOpen(true);
  };

  const handleClosePlanModal = () => {
    setSelectedPlan(null);
    setPlanModalOpen(false);
  };

  const handleSearch = event => {
    setSearchParam(event.target.value.toLowerCase());
  };

  const handleEditPlan = plan => {
    setSelectedPlan(plan);
    setPlanModalOpen(true);
  };

  const handleDeletePlan = async planId => {
    try {
      await api.delete(`/plan/${planId}`);
      toast.success("Plano removido com sucesso");
    } catch (err) {
      toastError(err);
    }
    setDeletingPlan(null);
    setSearchParam("");
    setPageNumber(1);

    dispatch({ type: "RESET" });
    setPageNumber(1);
    await fetchPlans();
  };

  const loadMore = () => {
    setPageNumber(prevState => prevState + 1);
  };

  const handleScroll = e => {
    if (!hasMore || loading) return;
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      loadMore();
    }
  };

  return (
    <MainContainer>
      <ConfirmationModal
        title={deletingPlan && `${i18n.t("plans.confirmationModal.deleteTitle")}`}
        open={confirmModalOpen}
        onClose={setConfirmModalOpen}
        onConfirm={() => handleDeletePlan(deletingPlan.id)}
      >
        {i18n.t("plans.confirmationModal.deleteMessage")}
      </ConfirmationModal>
      <CreatePlanModal
        open={planModalOpen}
        onClose={handleClosePlanModal}
        reload={fetchPlans}
        aria-labelledby="form-dialog-title"
        planId={selectedPlan && selectedPlan.id}
      />
      <Paper
        className={classes.mainPaper}
        variant="outlined"
        onScroll={handleScroll}
      >
        <MainHeader>
          <Stack>
            <Typography variant="h5" color="black" style={{ fontWeight: "bold", marginLeft: "10px", marginTop: "10px" }} gutterBottom>
              Planos
            </Typography>
            <Typography style={{ marginTop: "-10px", marginLeft: "10px" }} variant="caption" color="textSecondary">
              Administração dos planos TakeFlow
            </Typography>
          </Stack>

          <MainHeaderButtonsWrapper>
            <Search
              placeholder={i18n.t("Buscar")}
              size="large"
              type="search"
              value={searchParam}
              onChange={handleSearch}
              style={{
                width: "300px",
                marginRight: "10px",
                marginLeft: "10px",
              }}
            />
            <Button

              size="large"
              type="primary"
              onClick={handleOpenPlanModal}
            >
              Novo plano
            </Button>

          </MainHeaderButtonsWrapper>
        </MainHeader>
        <Stack
          style={{
            padding: "20px",
            backgroundColor: "#fff",
            borderRadius: "5px",
            height: "93%",
            overflowY: "auto",
            
          }}
        >
              <Paper style={{
            boxShadow: "none",
            backgroundColor: "#fff",
          }} >
          <Table size="small" sx={{
              backgroundColor: "#fff",
            }}>
                       <THead sticky="true">

                <TRow>
                  <TCell style={{ color: "#fff" }} align="center">
                    Nome
                  </TCell>
          
                  <TCell style={{ color: "#fff" }} align="center">
                    Usuários
                  </TCell>
                  <TCell style={{ color: "#fff" }} align="center">
                    Conexões
                  </TCell>
                  <TCell style={{ color: "#fff" }} align="center">
                    Departamentos
                  </TCell>
                  <TCell style={{ color: "#fff" }} align="center">
                    Flows
                  </TCell>
                  <TCell style={{ color: "#fff" }} align="center">
                    Visível
                  </TCell>
                  <TCell style={{ color: "#fff" }} align="center">
                    Campanhas
                  </TCell>
                  <TCell style={{ color: "#fff" }} align="center">
                    Valor
                  </TCell>
                  <TCell style={{ color: "#fff" }} align="center">
                    Ações
                  </TCell>
                </TRow>
              </THead>
              <TBody>
                <>
                  {plans.map(plan => (


               
                    <TRow key={plan.id}>
                      <TCell align="center">
                        <Chip
                        
                          style={{
                            backgroundColor: plan?.color ? plan.color : "black",
                       
                            color: "white"
                          }}
                          label={plan?.name}
                          size="small"
                        />
                      </TCell>
     
                      <TCell align="center">{plan.users}</TCell>
                      <TCell align="center">{plan.connections}</TCell>
                      <TCell align="center">{plan.queues}</TCell>
                      <TCell align="center">{plan.flows ? plan.flows : "Não possui"}</TCell>
                      <TCell align="center">{plan.visible? "Visível": "Oculto"}</TCell>
                      <TCell align="center">{plan.campaigns ? plan.campaigns : "Nao incluso"}</TCell>
                      <TCell align="center">{plan.value}</TCell>

                      <TCell align="center">
                        <IconButton
                          size="small"
                          onClick={() => handleEditPlan(plan)}
                        >
                          <EditIcon />
                        </IconButton>

                        <IconButton
                          size="small"
                          onClick={e => {
                            setConfirmModalOpen(true);
                            setDeletingPlan(plan);
                          }}
                        >
                          <DeleteOutlineIcon />
                        </IconButton>
                      </TCell>
                    </TRow>
                  ))}
                  {loading && <TableRowSkeleton columns={3} />}
                </>
              </TBody>
            </Table>
          </Paper>
        </Stack>
      </Paper>
    </MainContainer>
  );
};

export default Plans;
