import { Typography, Stack, Divider, Button, Box , Grid} from "@mui/material";
import { Input, Select } from "antd";
import { useState } from "react";
import VerificationInput from "react-verification-input";
import { openApi } from "../../../services/api";
import toastError from "../../../errors/toastError";


export default function EmailConfirmation({ 
  handleChange, 
  values,
  handleNext,
  handleBack,
  activeStep,
}) {
  const [confirmationCode, setConfirmationCode] = useState(null);
  const [counter, setCounter] = useState(0);
  const [counterTime, setCounterTime] = useState(0);


  const handleCounter = () => {
    if(counter > 0) return;
    setCounter(counter + 1);
    setCounterTime(60);
    const interval = setInterval(() => {
      setCounterTime(counterTime => counterTime - 1);
    }, 1000);
    setTimeout(() => {
      clearInterval(interval);
      setCounter(0);
    }, 60000);
  }


  const handleCheckCode = async () =>{
    const data = {
      code: confirmationCode,
      email: values.email
    }
    try{
      const result =  await openApi.post("/confirmationcode", data);
      if(!result?.data?.success){
        return {
          error: true
        }
      }
      return {
        error: false
      }
    }catch(error){
      return {
        error: true
      }
    }
  }

  const validationSchema = () =>{
    if(!values.code) return false;
    return true;
  }

  const handleSubmit = async () =>{
    const result = await handleCheckCode();
    if(result.error){
      return toastError("Código inválido");
    }
    handleNext();
  }

  return (
    <>
      <Stack
        sx={{
          marginTop: 3
        }}
      >
        <Divider sx={{ mb: 3 }} />
        <Stack>
          <Typography variant="h5" color="primary">
            Enviamos um código de confirmação para o seu e-mail.
          </Typography>
          <Typography variant="body1" color="text.secondary">
            Por favor, insira o código abaixo:
          </Typography>
          <Stack sx={{ mt: 2 }}>
            <Grid container spacing={2}>
            <Grid item xs={6}>
            <VerificationInput
              value={confirmationCode}
              onChange={setConfirmationCode}
            />
              </Grid>
              
            </Grid>
          </Stack>
          <Stack direction="row" spacing={1} sx={{mt:1}}>
          <Typography variant="body1" color="text.secondary" sx={{mt:1}}>
            Não recebeu o código? 
          </Typography>
            <Typography variant="body1" sx={{mt:1}} onClick={handleCounter} disabled={counter > 0} color= {counter > 0 ? "text.disabled" : "primary"}
             style={{cursor: counter > 0 ? "not-allowed" : "pointer"}}>
                {counterTime > 0 ? `Reenviar código em ${counterTime} segundos` : "Reenviar código"}
            </Typography>
          </Stack>
      
        </Stack>
        <Divider sx={{ mb: 3, mt: 3 }} /> 
        <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
          <Button
            color="inherit"
            disabled={activeStep === 0}
            onClick={handleBack}
            sx={{ mr: 1 }}
          >
            Voltar
          </Button>
          <Box sx={{ flex: "1 1 auto" }} />
          <Button
            variant="contained"
            onClick={handleSubmit}
            disabled={!confirmationCode}
            sx={{
              backgroundColor: "#0C3249",
              color: "#FFFFFF"
            }}
          >
            Próximo
          </Button>
        </Box>
      </Stack>
    </>
  );
}
