import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import { DialogContent, Divider, Typography } from "@material-ui/core";
import qrCodeImage from "../../assets/frame.png"
// warning icon
import { Warning } from "@material-ui/icons"
import { Stack } from "@mui/material"
const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},
	
}));

const QrCodeModalSupport = ({ open, onClose }) => {
	const classes = useStyles();

	const handleClose = () => {
		onClose();
	};

	return (
		<div className={classes.root}>
			<Dialog
				open={open}
				onClose={handleClose}
				maxWidth="xs"
				fullWidth
				scroll="paper"
			>
				<DialogTitle id="form-dialog-title">
						<Typography>
						Escaneie o QRCode abaixo para solicitar suporte
						</Typography>
				</DialogTitle>
				<Divider />
				<DialogContent>
				<Stack direction="row">
						<Warning 
							style={{
								fontSize: "20px",
								color: "#910b0b"
							}}
						/>
						<Typography variant="body1" style={{marginLeft: "10px", color: "#910b0b", fontWeight: "bold"}}> Atenção! </Typography>
						</Stack>
						<Typography variant="caption" style={{marginTop: "5px"}}>Não escaneie o QRCode com o mesmo aparelho conectado a plataforma.</Typography>
					    <Divider style={{marginTop: "10px"}} />
						<Stack>
							<Stack>
							<img src={qrCodeImage} style={{}} alt="logo" />
							</Stack>
						</Stack>
				</DialogContent>
			</Dialog>
		</div>
	);
};

export default QrCodeModalSupport;
