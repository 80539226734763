import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  Grid,
  Popover,
  Stack,
  Switch,
  Typography,
} from "@mui/material"
import NodeMenu from "../../../StyleNodes/Menu"

import AceEditor from "react-ace"
import "ace-builds/src-noconflict/theme-github"
import "ace-builds/src-noconflict/mode-json"
import React, { useRef, useState } from "react"
import { i18n } from "../../../../../../../translate/i18n";
import { Input, Select } from "antd"
import { renderIcons } from "../../../../Icons"

const handleStyle = { left: 10 }

export const SMTPMenu = ({ node, anchorRef }) => {
  const { data } = node
  const [anchorEl, setAnchorEl] = useState(null)
  const [values, setValues] = useState(data)
  const [openImport, setOpenImport] = useState(false)

  const handleRun = () => { }

  const handleChange = ({ target }) => {
    values[target.name] = target.value
    setValues({ ...values })
  }


  const handleChangeSelect = (value) => {
    values["security"] = value
    setValues({ ...values })
  }

  return (
    <NodeMenu
      open
      node={node}
      anchorRef={anchorRef}
      values={values}
      onChange={handleChange}
      onRun={handleRun}
    >
      <Stack direction="row" alignItems="center" spacing={1}>
        <Stack sx={{
          backgroundColor: `#0C3249`,
          padding: 1,
          borderRadius: "10px 10px 10px 10px"
        }}
        >
          {renderIcons(data?.payload?.icon)}
        </Stack>
        <Stack spacing={0}>
          <Typography variant="h6"> {i18n.t('SMTP')}</Typography>
          <Typography color="text.secondary" sx={{ mt: -0.7 }}>
            {i18n.t('Envio de e-mail SMTP')}
          </Typography>

        </Stack>
      </Stack>
      <Divider sx={{ my: 1 }} />
      <Grid container spacing={2}>
      <Grid item xs={6}>
          <Stack>
            <Typography variant="caption" color="text.secondary">
              {i18n.t('Servidor')}
            </Typography>
            <Input
              placeholder="yourserver.com"
              name="server"
              value={values.server}
              onChange={handleChange}
              size="large"
            />
          </Stack>
        </Grid>
        <Grid item xs={3}>
          <Stack>
            <Typography variant="caption" color="text.secondary">
              {i18n.t('Porta')}
            </Typography>
            <Input
              placeholder="25"
              name="port"
              type="number"
              value={values.port}
              onChange={handleChange}
              size="large"
            />
          </Stack>
        </Grid>
        <Grid item xs={3}>
          <Stack>
            <Typography variant="caption" color="text.secondary">
              {i18n.t('Segurança')}
            </Typography>
            <Select
              placeholder={i18n.t('Segurança')}
              id="security"
              onChange={handleChangeSelect}
              value={values.security}
              options={["Auto", "Nenhuma", "SSL", "TLS"].map((option) => {
                return { value: option, label: option }
              })}
              size="large"
            />
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack>
            <Typography variant="caption" color="text.secondary">
              {i18n.t('Usuário')}
            </Typography>
            <Input
              placeholder="Usuário"
              name="username"
              value={values.username}
              onChange={handleChange}
              size="large"
            />
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack>
            <Typography variant="caption" color="text.secondary">
              {i18n.t('Senha')}
            </Typography>
            <Input
              placeholder="Senha"
              name="password"
              value={values.password}
              onChange={handleChange}
              size="large"
            />
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack>
            <Typography variant="caption" color="text.secondary">
              {i18n.t('Remetente')}
            </Typography>
            <Input
              placeholder="Remetente"
              name="from"
              value={values.from}
              onChange={handleChange}
              size="large"
            />
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack>
            <Typography variant="caption" color="text.secondary">
              {i18n.t('Destinatário')}
            </Typography>
            <Input
              placeholder="Destinatário"
              name="to"
              value={values.to}
              onChange={handleChange}
              size="large"
            />
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack>
            <Typography variant="caption" color="text.secondary">
              {i18n.t('Título do e-mail')}
            </Typography>
            <Input
              placeholder="Título do e-mail"
              name="subject"
              value={values.subject}
              onChange={handleChange}
              size="large"
            />
          </Stack>
        </Grid>
      </Grid>





      <Box my={1}>
        <Typography variant="caption" color="text.secondary">

          Mensagem
        </Typography>
        <AceEditor
          mode="text"
          theme="github"
          value={values.message}
          onChange={(value) =>
            handleChange({
              target: { name: "message", value },
            })
          }
          maxLines={10}
          minLines={10}
          name="UNIQUE_ID_OF_DIV"
          editorProps={{ $blockScrolling: true }}
          setOptions={{
            useWorker: false,
          }}
          style={{ width: "100%" }}
        />
      </Box>
    </NodeMenu>
  )
}

export default SMTPMenu
