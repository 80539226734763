import {
  Divider,
  Grid,
  Popover,
  Stack,
  Switch,
  Checkbox,
  Slider,
  IconButton,
  Tooltip,
  Typography,
  Box
} from "@mui/material";
import NodeMenu from "../../../StyleNodes/Menu";
import { AudioPlayer } from 'react-audio-player-component';
import { v4 as uuidV4 } from "uuid"

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/mode-json";
import React, { useRef, useState, useCallback, useEffect } from "react";
import { i18n } from "../../../../../../../translate/i18n";
import { Input, Select, Upload, Modal, Empty, Button, Dropdown, Space } from "antd";
import {
  AlarmAdd,
  AttachFileOutlined,
  DeleteOutline,
  DragHandle,
  FontDownloadOutlined,
  FormatListNumbered,
  FormatSize,
  Phone,
  Photo,
  UploadFileOutlined
} from "@mui/icons-material";
import { putObject } from "../../../../../../../commons/utils/s3";
import "./style.css";
import { renderIcons } from "../../../../Icons";
import toastError from "../../../../../../../errors/toastError";
import { ArrowDropDownOutlined, PictureInPicture, VoiceChat } from "@material-ui/icons";
const { TextArea } = Input;
const handleStyle = { left: 10 };
const getBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
export const MessageMenu = ({ node, anchorRef }) => {
  const { data } = node;
  const [values, setValues] = useState(data);
  const [dragging, setDragging] = useState(false);
  const [enableDrag, setEnableDrag] = useState(true)
  const [listState, setListState] = useState(false);
  const scrollContainerRef = useRef()


  const handleRun = () => { };

  const handleChange = ({ target }) => {
    if (target.name === "maxRetries") {
      if (target.value > 10) {
        return toastError("O valor máximo é 10")
      }

    }
    if (target.name === "time") {
      if (target.value > 60) {
        return toastError("O valor máximo é 60")
      }
    }
    values[target.name] = target.value;
    setValues({ ...values });
  };

  useEffect(() => {
    if (values?.messagesArray?.map((item) => item.type === "Lista").includes(true)) {
      setListState(true)
    }
  }, [values?.messagesArray])

  const handleChangeSwitch = value => {
    values["returnOptions"] = value;
    if (value === true) {
      if (values?.continueFlow) {
        values["continueFlow"] = false;
        return setValues({
          ...values
        });
      }
    } else {
      values["outcontext"] = false;
      values["inactivity"] = false;

      delete values.payload?.port.else
      delete values.payload?.port.error

      values["continueFlow"] = true;
      setValues({ ...values });
    }
  };
  const handleChangeInactivity = value => {
    values["inactivity"] = value;
    if (value === true) {
      setValues({ ...values, payload: { ...values.payload, port: { ...values.payload.port, error: { color: "#ea8d06" } } } })
    } else {
      delete values.payload?.port.error
      setValues({ ...values })
    }


  };

  const handleChangeOutContext = value => {
    values["outcontext"] = value;
    if (value === true) {
      setValues({ ...values, payload: { ...values.payload, port: { ...values.payload.port, else: { color: "#EF5350" } } } })
    } else {
      delete values.payload?.port.else
      setValues({ ...values })
    }


  };
  const handleChangeSwitchContinue = value => {
    values["continueFlow"] = value;
    if (value === true) {
      if (values?.returnOptions) {
        values["returnOptions"] = false;
        return setValues({
          ...values
        });
      }
    } else {
      values["returnOptions"] = true;
      setValues({ ...values });
    }
  };
  const handleChangeMessages = (e, index) => {
    e.preventDefault()
    e.stopPropagation()
    values.messagesArray[index].message = e.target.value;
    setValues({ ...values });
  };

  const handleChangeListTitle = (e, index) => {
    values.messagesArray[index].listTitle = e.target.value;
    setValues({ ...values });
  };

  const handleUpload = index => {
    const input = document.getElementById(index + "input");
    input.click();
    input.onchange = e => {
      const file = e.target.files[0];
      const fileList = e.target.files;
      if (file) {
        const dataFile = putObject(file.name, file, file.type);
        dataFile
          .then(data => {
            values.messagesArray[index].fileUrl = data.file;
            setValues({ ...values });
          })
          .catch(err => {
            toastError(err);
          });
      }
    }
  };

  const handleChangeInterval = (e, index) => {
    values.messagesArray[index].interval = e.target.value;
    setValues({ ...values });
  };


  const handleAddMessage = type => {

    if (listState) {
      return toastError("Você já adicionou uma lista de mensagens, remova a lista para adicionar outro tipo de mensagem.")
    }
    if (type === "Lista") {
      const id = uuidV4()
      setListState(true)
      delete values.payload?.port.success
      values.returnOptions = true
      values.continueFlow = false
      values.listMessage = true
      values.messagesArray = [{
        type: type,
        listMessages: [{
          id: id,
          option: 1,
          messageOption: ""
        }]
      }]

      const totalMessagesList = values.messagesArray[0].listMessages.length
      values.payload.port[id] = {
        color: values.colors[totalMessagesList % values.colors.length],
      }
    } else {
      values.messagesArray.push({ type });
    }

    setValues({ ...values });
  };


  const handleDeleteMessage = index => {
    const typeMessage = values.messagesArray[index].type
    if (typeMessage === "Lista") {
      setListState(false)
      values.listMessage = false
      setValues({ ...values, payload: { ...values.payload, port: { ...values.payload.port, success: { color: "#0C3249" } } } })

    }
    values.messagesArray.splice(index, 1);
    setValues({ ...values });
  };

  const handleDragStart = (ev, item) => {

    setDragging({ draggingItem: item });
    ev.dataTransfer.setData('text/plain', '');
  }

  const handleDragEnd = (e) => {
    setDragging(false);
  }

  const handleDragOver = (ev) => {
    ev.preventDefault();
  }

  const handleAddListOption = (index) => {
    const id = uuidV4()

    values.messagesArray[index].listMessages.push({
      id: id,
      option: values?.messagesArray[index]?.listMessages?.length + 1,
      messageOption: ""
    });
    const totalMessagesList = values.messagesArray[index].listMessages.length

    values.payload.port[id] = {
      color: values.colors[totalMessagesList % values.colors.length],
    }

    setValues({ ...values });
  }

  const handleDrop = (e, targetItem) => {
    e.preventDefault();
    e.stopPropagation();
  
    const draggedItem = dragging.draggingItem;
    const items = [...values.messagesArray.filter(item => item !== draggedItem)];
    const boundingRect = e.currentTarget.getBoundingClientRect();
    const mouseY = e.clientY;
    const isDropBelow = mouseY > (boundingRect.top + boundingRect.height / 2);
    let targetIndex = items.indexOf(targetItem);
    if (isDropBelow) {
      targetIndex++; 
    }
    if (targetIndex > items.length) {
      targetIndex = items.length; 
    }
    items.splice(targetIndex, 0, draggedItem);
    setValues({ ...values, messagesArray: items }); 
    e.currentTarget.style.transition = 'transform 0.2s ease, opacity 0.2s ease'; 
    e.currentTarget.style.opacity = '1';
    e.currentTarget.style.transform = 'scale(1)'; 

  }



  const handleAddVariable = (type, index) => {
    values.messagesArray[index].message = values.messagesArray[index]?.message ? values.messagesArray[index]?.message + ` {{flow.${type}}}` : `{{flow.${type}}}`
    setValues({ ...values });
  }



  const handleDeleteConnection = useCallback(
    (id, index) => {
      delete values.payload?.port[id]

      const filterMessageArray = values.messagesArray[index].listMessages.filter((item) => item.id !== id)
      setValues({
        ...values,
        messagesArray: values.messagesArray.map((item, indexItem) => {
          if (indexItem === index) {
            return {
              ...item,
              listMessages: filterMessageArray
            }
          }
          return item
        })
      })
    },
    [values]
  )

  const handleDragOverMessages  = (e) =>{
    e.preventDefault();
    
    const scrollContainer = scrollContainerRef.current;
    const bounding = scrollContainer.getBoundingClientRect();

    const mouseY = e.clientY;

    const bottomThreshold = bounding.bottom - 100; 
    const topThreshold = bounding.top + 100; 

    if (mouseY > bottomThreshold) {
      scrollContainer.scrollTop += 7; 
    }

    if (mouseY < topThreshold) {
      scrollContainer.scrollTop -= 7; 
    }
}

  const handleChangeListOption = (e, index, indexList) => {
    e.preventDefault()
    e.stopPropagation();
    values.messagesArray[index].listMessages[indexList][e.target.name] = e.target.value;
    setValues({ ...values });
  }

  const handleElementDragEnter = (e, message) => {
    if(dragging === false || dragging.draggingItem === message) return
    
    e.currentTarget.style.opacity = '0.4'
    e.currentTarget.style.scale = '1.1'
  }

  const handleElementDragLeave = (e, message) => {
    if(dragging === false || dragging.draggingItem === message) return
    if (!e.currentTarget.contains(e.relatedTarget)) {
      e.currentTarget.style.opacity = '1'
      e.currentTarget.style.scale = '1'
    }
  }

  const handleMoseLeave = (e) =>{
    e.currentTarget.style.opacity = '1'
    e.currentTarget.style.scale = '1'
  }

  return (
    <NodeMenu
      open
      node={node}
      anchorRef={anchorRef}
      values={values}
      onChange={handleChange}
      onRun={handleRun}
      size={1300}
    >
      <Stack justifyContent="space-between" spacing={1}>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Stack
            sx={{
              backgroundColor: `#0C3249`,
              padding: 1,
              borderRadius: "10px 10px 10px 10px"
            }}
          >
            {renderIcons(data?.payload?.icon)}
          </Stack>
          <Stack spacing={0}>
            <Typography variant="h6"> {i18n.t("Conteúdo")}</Typography>
            <Typography color="text.secondary" sx={{ mt: -0.7 }}>
              {i18n.t("Envie uma resposta para o contato")}
            </Typography>
          </Stack>
        </Stack>
        <Divider sx={{ my: 1 }} />
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Grid container spacing={2} sx={{
              mb: 3
            }}>
              <Grid item xs={12}>
                <Stack>
                  <Typography variant="caption" color="text.secondary">
                    {i18n.t("Tipo da mensagem")}
                  </Typography>
                  <Stack direction="row" spacing={2} sx={{ mt: 1 }}>

                    <Stack
                      onClick={() => handleAddMessage("Texto")}
                      sx={{
                        backgroundColor: listState ? "#979797" : `#387599`,
                        padding: 1,
                        width: "65px",
                        borderRadius: "10px 10px 10px 10px",
                        border: `2px solid #0C3249 `,
                        borderStyle: "dashed",
                        color: "white",
                        cursor: listState ? "not-allowed" : "pointer",
                        "&:hover": {
                          backgroundColor: listState ? "#979797" : "#2c6586",
                          color: "white"
                        }
                      }}
                      justifyContent="center"
                      alignItems="center"
                    >
                      <FormatSize />
                      <Typography variant="caption" color="white">
                        {i18n.t("Texto")}
                      </Typography>
                    </Stack>

                    <Stack
                      onClick={() => handleAddMessage("Arquivo")}
                      sx={{
                        backgroundColor: listState ? "#979797" : `#387599`,
                        padding: 1,
                        width: "65px",
                        borderRadius: "10px 10px 10px 10px",
                        border: `2px solid #0C3249 `,
                        borderStyle: "dashed",
                        color: "white",
                        cursor: listState ? "not-allowed" : "pointer",
                        "&:hover": {
                          backgroundColor: listState ? "#979797" : "#2c6586",
                          color: "white"
                        }
                      }}
                      justifyContent="center"
                      alignItems="center"
                    >
                      <AttachFileOutlined />
                      <Typography variant="caption" color="white">
                        {i18n.t("Arquivo")}
                      </Typography>
                    </Stack>

                    <Stack
                      onClick={() => handleAddMessage("Audio")}
                      sx={{
                        backgroundColor: listState ? "#979797" : `#387599`,
                        padding: 1,
                        width: "65px",
                        borderRadius: "10px 10px 10px 10px",
                        border: `2px solid #0C3249 `,
                        borderStyle: "dashed",
                        color: "white",
                        cursor: listState ? "not-allowed" : "pointer",
                        "&:hover": {
                          backgroundColor: listState ? "#979797" : "#2c6586",
                          color: "white"
                        }
                      }}
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Phone />
                      <Typography variant="caption" color="white">
                        {i18n.t("Áudio")}
                      </Typography>
                    </Stack>

                    <Stack
                      onClick={() => handleAddMessage("Video")}
                      sx={{
                        backgroundColor: listState ? "#979797" : `#387599`,
                        padding: 1,
                        width: "65px",
                        borderRadius: "10px 10px 10px 10px",
                        border: `2px solid #0C3249 `,
                        borderStyle: "dashed",
                        color: "white",
                        cursor: listState ? "not-allowed" : "pointer",
                        "&:hover": {
                          backgroundColor: listState ? "#979797" : "#2c6586",
                          color: "white"
                        }
                      }}
                      justifyContent="center"
                      alignItems="center"
                    >
                      <VoiceChat />
                      <Typography variant="caption" color="white">
                        {i18n.t("Vídeo")}
                      </Typography>
                    </Stack>

                    <Stack
                      onClick={() => handleAddMessage("Imagem")}
                      sx={{
                        backgroundColor: listState ? "#979797" : `#387599`,
                        padding: 1,
                        width: "65px",
                        borderRadius: "10px 10px 10px 10px",
                        border: `2px solid #0C3249 `,
                        borderStyle: "dashed",
                        color: "white",
                        cursor: listState ? "not-allowed" : "pointer",
                        "&:hover": {
                          backgroundColor: listState ? "#979797" : "#2c6586",
                          color: "white"
                        }
                      }}
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Photo />
                      <Typography variant="caption" color="white">
                        {i18n.t("Imagem")}
                      </Typography>
                    </Stack>
                    <Stack
                      onClick={() => handleAddMessage("Intervalo")}
                      sx={{
                        backgroundColor: listState ? "#979797" : `#387599`,
                        padding: 1,
                        width: "65px",
                        borderRadius: "10px 10px 10px 10px",
                        border: `2px solid #0C3249 `,
                        borderStyle: "dashed",
                        color: "white",
                        cursor: listState ? "not-allowed" : "pointer",
                        "&:hover": {
                          backgroundColor: listState ? "#979797" : "#2c6586",
                          color: "white"
                        }
                      }}
                      justifyContent="center"
                      alignItems="center"
                    >
                      <AlarmAdd />
                      <Typography variant="caption" color="white">
                        {i18n.t("Intervalo")}
                      </Typography>
                    </Stack>
                    <Stack
                      onClick={() => handleAddMessage("Lista")}
                      sx={{
                        backgroundColor: listState ? "#979797" : `#387599`,
                        padding: 1,
                        width: "65px",
                        borderRadius: "10px 10px 10px 10px",
                        border: `2px solid #0C3249 `,
                        borderStyle: "dashed",
                        color: "white",
                        cursor: listState ? "not-allowed" : "pointer",
                        "&:hover": {
                          backgroundColor: listState ? "#979797" : "#2c6586",
                          color: "white"
                        }
                      }}
                      justifyContent="center"
                      alignItems="center"
                    >
                      <FormatListNumbered />
                      <Typography variant="caption" color="white">
                        {i18n.t("Lista")}
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <Divider sx={{ my: 2}} />
                </Grid>
              <Grid item xs={12}>
              <Typography color="text.secondary" fontWeight="bold">
                        {i18n.t("Opções avançadas")}
                      </Typography>
                        </Grid>
              {!listState ? (
                
                <Grid item xs={12}>
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <Switch
                      checked={values?.continueFlow}
                      onChange={e => handleChangeSwitchContinue(e.target.checked)}
                    />
                    <Stack spacing={0}>
                      <Typography color="text.secondary" fontWeight="bold">
                        {i18n.t("Continuar fluxo")}
                      </Typography>
                      <Typography
                        variant="caption"
                        color="text.secondary"
                        sx={{ mt: -0.7 }}
                      >
                        {i18n.t("Continuar fluxo ao enviar mensagem")}
                      </Typography>
                    </Stack>
                  </Stack>
                </Grid>
              ) : null}
              <Grid item xs={12} sx={{
                mt: -1.5
              }}>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Switch

                    checked={values?.returnOptions}
                    onChange={e => handleChangeSwitch(e.target.checked)}
                  />
                  <Stack spacing={0}>
                    <Typography color="text.secondary" fontWeight="bold">
                      {i18n.t("Aguardar resposta do contato")}
                    </Typography>
                    <Typography
                      variant="caption"
                      color="text.secondary"
                      sx={{ mt: -0.7 }}
                    >
                      {i18n.t("Aguardar resposta do contato")}
                    </Typography>
                  </Stack>
                </Stack>
              </Grid>
              {values?.returnOptions && (
                <>

                  <Grid item xs={1}>
                    <Box sx={{
                      borderLeft: "1px dashed #0C3249",
                      borderBottom: "1px dashed #0C3249",
                      height: "25px",
                      width: "30px",
                      mt: -2,
                      ml: 3
                    }}></Box>
                  </Grid>
                  <Grid item xs={6.5} sx={{ mt: -1.5 }}>
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <Switch

                        checked={values?.inactivity}
                        onChange={e => handleChangeInactivity(e.target.checked)}
                      />
                      <Stack spacing={0}>
                        <Typography color="text.secondary" fontWeight="bold">
                          {i18n.t("Validar inatividade")}
                        </Typography>
                        <Typography
                          variant="caption"
                          color="text.secondary"
                          sx={{ mt: -0.7 }}
                        >
                          {i18n.t("Ativar um nó ao fim do tempo de inatividade")}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Grid>

                  <Grid item xs={3.5} sx={{ mt: -1.5 }}>
                    <Stack>

                      <Input
                        placeholder="10"
                        name="time"
                        min={0}
                        max={60}
                        disabled={!values?.inactivity}
                        addonAfter={
                          <>
                            <Typography variant="caption" color="white">
                              {i18n.t('Minutos')}
                            </Typography>
                          </>
                        }
                        type="number"
                        value={values.time}
                        onChange={handleChange}
                        size="large"
                      />
                    </Stack>
                  </Grid>

                  {listState && (
                    <>
                      <Grid item xs={1.01}>
                        <Box sx={{
                          borderLeft: "1px dashed #0C3249",
                          borderBottom: "1px dashed #0C3249",
                          height: "25px",
                          width: "30px",
                          mt: -2,
                          ml: 3
                        }}></Box>
                      </Grid>
                      <Grid item xs={6.5} sx={{ mt: -1.5 }}>
                        <Stack direction="row" alignItems="center" spacing={1}>
                          <Switch

                            checked={values?.outcontext}
                            onChange={e => handleChangeOutContext(e.target.checked)}
                          />
                          <Stack spacing={0}>
                            <Typography color="text.secondary" fontWeight="bold">
                              {i18n.t("Validar mensagem")}
                            </Typography>
                            <Typography
                              variant="caption"
                              color="text.secondary"
                              sx={{ mt: -0.7 }}
                            >
                              {i18n.t("Tratar mensagem fora de contexto")}
                            </Typography>
                          </Stack>
                        </Stack>
                      </Grid>
                      <Grid item xs={3.5} sx={{ mt: -1 }}>
                        <Stack>

                          <Input
                            placeholder="10"
                            name="maxRetries"
                            min={0}
                            max={10}
                            disabled={!values?.outcontext}
                            addonAfter={
                              <>
                                <Typography variant="caption" color="white">
                                  {i18n.t('Tentativas')}
                                </Typography>
                              </>
                            }
                            type="number"
                            value={values.maxRetries}
                            onChange={handleChange}
                            size="large"
                          />
                        </Stack>
                      </Grid>
                      
                    </>
                  )}

                </>
              )}

            </Grid>
          </Grid>
            <Grid 
              item xs={6}
              justifyContent={'center'}
              
            >
              <div style={{ maxHeight: 550, overflow: "hidden", position: "relative", height: "100%" }}>
                <Stack
                  onDragOver={(e)=>handleDragOverMessages(e)}
                  ref={scrollContainerRef}
                  spacing={1}
                  sx={{
                    maxHeight: 550,
                    overflowY: "scroll",
                    overflowX: "hidden",
                    height: "100%"
                  }}
                >
                  <Grid
                    container
                    className="sortable-list"
                    spacing={2}
                    sx={{
                      pr: 4
                    }}
                  >
                    {values?.messagesArray.length
                      ? values?.messagesArray?.map((message, index) => (
                        <>

                          {message.type === "Texto" ? (

                            <Grid
                              onDragEnter={(e)=>handleElementDragEnter(e, message)}
                              onDragLeave={(e)=>handleElementDragLeave(e, message)}
                              onMouseLeave={(e)=>{handleMoseLeave(e)}}
                              item
                              xs={12}
                              draggable={enableDrag}
                              onDragStart={(e) => {
                                handleDragStart(e, message)
                              }}
                              onDragEnd={handleDragEnd}
                              onDragOver={handleDragOver}
                              onDrop={(e) => handleDrop(e, message)}
                              className={dragging.draggingItem === message ? "dragging" : ""}
                              sx={{
                                backgroundColor: `#f1f1f199`,
                                borderRadius: "10px 10px 10px 10px",
                                padding: 2,
                                ml: 2,
                              }}
                            >
                              <Stack
                              >
                                
                                <Stack
                                  onDragStart={(e)=>{
                                    console.log()
                                  }}
                                  direction="row"
                                  justifyContent="space-between"
                                  alignItems="center"
                                >
                                  <Typography
                                    variant="caption"
                                    color="text.secondary"
                                  >
                                    {i18n.t("Mensagem")}
                                  </Typography>
                                  <Stack direction={"row"} spacing={1} alignItems="center">
                                    <DragHandle style={{
                                      cursor: "grab"
                                    }} />
                                    <IconButton
                                      size="small"
                                      onClick={() => handleDeleteMessage(index)}
                                    >
                                      <DeleteOutline />
                                    </IconButton>

                                  </Stack>
                                </Stack>
                                <TextArea
                                  onFocus={()=>setEnableDrag(false)} 
                                  onBlur={()=>setEnableDrag(true)} 
                                  placeholder="Digite sua mensagem"
                                  name="message"
                                  style={{ height: 200 }}
                                  value={message.message}
                                  onChange={e => handleChangeMessages(e, index)}
                                  size="large"
                                />
                                <Stack direction="row" spacing={1} alignItems="center" sx={{ ml: 1 }}>
                                  <Dropdown
                                    menu={{
                                      items: [
                                        {
                                          label: <Typography variant="caption">Nome</Typography>,
                                          key: `${index}0`,
                                          onClick: (e) => {
                                            handleAddVariable("nome", index)
                                          }
                                        },
                                        {
                                          label: <Typography variant="caption" >Primeiro nome</Typography>,
                                          key: `${index}1`,
                                          onClick: (e) => {
                                            handleAddVariable("primeiroNome", index)
                                          }
                                        },
                                        {
                                          label: <Typography variant="caption">Sobrenome</Typography>,
                                          key: `${index}2`,
                                          onClick: (e) => {
                                            handleAddVariable("sobrenome", index)
                                          }
                                        },
                                        {
                                          label: <Typography variant="caption" >CPF</Typography>,
                                          key: `${index}3`,
                                          onClick: (e) => {
                                            handleAddVariable("cpf", index)
                                          }
                                        },
                                        {
                                          label: <Typography variant="caption" >CNPJ</Typography>,
                                          key: `${index}4`,
                                          onClick: (e) => {
                                            handleAddVariable("cnpj", index)
                                          }
                                        },
                                        {
                                          label: <Typography variant="caption" >Endereço</Typography>,
                                          key: `${index}5`,
                                          onClick: (e) => {
                                            handleAddVariable("endereco", index)
                                          }
                                        },
                                        {
                                          label: <Typography variant="caption">Telefone</Typography>,
                                          key: `${index}6`,
                                          onClick: (e) => {
                                            handleAddVariable("telefone", index)
                                          }
                                        },
                                        {
                                          label: <Typography variant="caption" >Email</Typography>,
                                          key: `${index}7`,
                                          onClick: (e) => {
                                            handleAddVariable("email", index)
                                          }
                                        },
                                        {
                                          label: <Typography variant="caption">Data de nascimento</Typography>,
                                          key: `${index}8`,
                                          onClick: (e) => {
                                            handleAddVariable("dataNascimento", index)
                                          }
                                        },
                                        {
                                          label: <Typography variant="caption">Data</Typography>,
                                          key: `${index}9`,
                                          onClick: (e) => {
                                            handleAddVariable("dataCompleta", index)
                                          }
                                        },
                                        {
                                          label: <Typography variant="caption">Data e hora</Typography>,
                                          key: `${index}10`,
                                          onClick: (e) => {
                                            handleAddVariable("dataHora", index)
                                          }
                                        },
                                        {
                                          label: <Typography variant="caption">Saudação</Typography>,
                                          key: `${index}11`,
                                          onClick: (e) => {
                                            handleAddVariable("saudacao", index)
                                          }
                                        },
                                      ],
                                    }}
                                    trigger={['click']}
                                  >
                                    <a onClick={(e) => e.preventDefault()}>
                                      <Space>
                                        Adicionar variável
                                        <ArrowDropDownOutlined style={{ marginTop: 6 }} />
                                      </Space>
                                    </a>
                                  </Dropdown>
                                </Stack>
                              </Stack>
                            </Grid>

                          ) : message?.type === "Intervalo" ? (
                            <>
                              <Grid
                                onDragEnter={(e)=>handleElementDragEnter(e, message)}
                                onDragLeave={(e)=>handleElementDragLeave(e, message)}
                                onMouseLeave={(e)=>{handleMoseLeave(e)}}

                                item
                                xs={12}
                                draggable={enableDrag}
                                onDragStart={(e) => handleDragStart(e, message)}
                                onDragEnd={handleDragEnd}
                                onDragOver={handleDragOver}
                                onDrop={(e) => handleDrop(e, message)}
                                className={dragging.draggingItem === message ? "dragging" : ""}
                                sx={{
                                  backgroundColor: `#f1f1f199`,
                                  borderRadius: "10px 10px 10px 10px",
                                  padding: 2,
                                  ml: 2
                                }}
                              >
                                <Stack>
                                  <Stack
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                  >
                                    <Typography
                                      variant="caption"
                                      color="text.secondary"
                                    >
                                      {i18n.t(`${message.type} de tempo`)}
                                    </Typography>
                                    <Stack direction={"row"} spacing={1} alignItems="center">
                                      <DragHandle style={{
                                        cursor: "grab"
                                      }} />
                                      <IconButton
                                        size="small"
                                        onClick={() => handleDeleteMessage(index)}
                                      >
                                        <DeleteOutline />
                                      </IconButton>

                                    </Stack>
                                  </Stack>
                                  <Stack direction="row" alignItems="center" spacing={1} sx={{
                                    width: "100%",
                                    pl: 2,
                                    mt: -2
                                  }}>
                                    <Slider
                                      defaultValue={message.interval}
                                      valueLabelDisplay="auto"
                                      onChange={(ev) => handleChangeInterval({
                                        target: {
                                          name: "interval",
                                          value: ev.target.value,
                                        },
                                      }, index)}
                                      style={{
                                        marginTop: 15,
                                      }}
                                      min={1}
                                      max={100}
                                    />
                                    <Stack>
                                      <Input
                                        onFocus={()=>setEnableDrag(false)}
                                        onBlur={()=>setEnableDrag(true)}
                                        name="interval"
                                        size="large"
                                        readOnly
                                        addonAfter={
                                          <Typography variant="caption" color="white" fontWeight="bold">
                                            segundos
                                          </Typography>
                                        }
                                        type="number"
                                        required
                                        value={message.interval}
                                        onChange={(ev) => handleChangeInterval(ev, index)}
                                        style={{ width: "100%", marginTop: 15 }}
                                        placeholder="Valor"
                                      />
                                    </Stack>
                                  </Stack>
                                </Stack>
                              </Grid>
                            </>
                          ) : message?.type === "Lista" ? (
                            <>
                            <Grid
                              onDragEnter={(e)=>handleElementDragEnter(e, message)}
                              onDragLeave={(e)=>handleElementDragLeave(e, message)}
                              onMouseLeave={(e)=>{handleMoseLeave(e)}}
                              
                              item
                              xs={12}
                              draggable={enableDrag}
                              onDragStart={(e) => handleDragStart(e, message)}
                              onDragEnd={handleDragEnd}
                              onDragOver={handleDragOver}
                              onDrop={(e) => handleDrop(e, message)}
                              className={dragging.draggingItem === message ? "dragging" : ""}
                              sx={{
                                backgroundColor: `#f1f1f199`,
                                borderRadius: "10px 10px 10px 10px",
                                padding: 2,
                                ml: 2,
                              }}
                            >
                              <Stack>
                                <Stack
                                  direction="row"
                                  justifyContent="space-between"
                                  alignItems="center"
                                >
                                  <Typography
                                    variant="caption"
                                    color="text.secondary"
                                  >
                                    {i18n.t("Lista")}
                                  </Typography>
                                  <Stack direction={"row"} spacing={1} alignItems="center">
                                    <DragHandle style={{
                                      cursor: "grab"
                                    }} />
                                    <IconButton
                                      size="small"
                                      onClick={() => handleDeleteMessage(index)}
                                    >
                                      <DeleteOutline />
                                    </IconButton>

                                  </Stack>
                                </Stack>
                                <Divider sx={{ my: 2 }} />
                                <Stack spacing={2}>
                                  <Stack>
                                    <Stack
                                      direction="row"
                                      justifyContent="space-between"
                                      alignItems="center"
                                    >
                                      <Typography
                                        variant="caption"
                                        color="text.secondary"
                                      >
                                        {i18n.t("Titulo da lista")}
                                      </Typography>

                                    </Stack>
                                    <TextArea
                                      onFocus={()=>setEnableDrag(false)}
                                      onBlur={()=>setEnableDrag(true)}
                                      placeholder="Digite sua mensagem"
                                      name="listTitle"
                                      style={{ height: 80 }}
                                      value={message.listTitle}
                                      onChange={e => handleChangeListTitle(e, index)}
                                      size="large"
                                    />
                                  </Stack>
                                  {message?.listMessages?.map((list, indexList) => (
                                    <Stack direction="row" spacing={2} alignItems="center">
                                      <Stack >
                                        <Typography
                                          variant="caption"
                                          color="text.secondary"
                                        >
                                          Opção
                                        </Typography>
                                        <Input
                                          onFocus={()=>setEnableDrag(false)}
                                          onBlur={()=>setEnableDrag(true)}
                                          placeholder="0"
                                          name="option"
                                          
                                          min={0}
                                          style={{
                                            width: "90px"
                                          }}
                                          value={list.option}
                                          onChange={e => handleChangeListOption(e, index, indexList)}
                                          size="large"
                                        />
                                      </Stack>
                                      <Stack sx={{
                                        width: "100%"
                                      }}>
                                        <Typography
                                          variant="caption"
                                          color="text.secondary"
                                        >
                                          Mensagem
                                        </Typography>
                                        <Stack direction="row" spacing={1} alignItems="center">

                                          <Input
                                            onFocus={()=>setEnableDrag(false)}
                                            onBlur={()=>setEnableDrag(true)}
                                            placeholder="Mensagem da opção"
                                            name="messageOption"
                                            value={list.messageOption}
                                            onChange={e => handleChangeListOption(e, index, indexList)}
                                            size="large"
                                          />
                                          <Tooltip title={i18n.t("Deletar opção")}>
                                            <IconButton
                                              size="small"
                                              disabled={message?.listMessages?.length === 1}
                                              onClick={() => handleDeleteConnection(list.id, index)}
                                            >
                                              <DeleteOutline />
                                            </IconButton>
                                          </Tooltip>
                                        </Stack>
                                      </Stack>

                                    </Stack>
                                  ))}


                                  <Stack justifyContent="center" alignItems="center" display="flex" sx={{
                                    width: "100%"
                                  }}>
                                    <Button
                                      onClick={() => handleAddListOption(index)}
                                      type="dashed"
                                      style={{
                                        width: "300px"
                                      }}>
                                      + Adicionar opção
                                    </Button>

                                  </Stack>

                                </Stack>
                              </Stack>
                            </Grid>
                            {values?.outcontext && (
                          <Grid item xs={12} sx={{ mt: -1 }}>
                            <Typography variant="caption" >
                              {i18n.t('Mensagem para fora de contexto')}
                            </Typography>
                            <TextArea
                              placeholder="Digite sua mensagem"
                              name="retryMessage"
                              style={{ height: 80, maxHeight: 90 ,width: "100%"}}
                              value={values.retryMessage}
                              onChange={handleChange}
                              size="large"
                            />
                          </Grid>
                        )}
                            </>
                          ) : (
                            <>
                              <Grid
                                onDragEnter={(e)=>handleElementDragEnter(e, message)}
                                onDragLeave={(e)=>handleElementDragLeave(e, message)}
                                onMouseLeave={(e)=>{handleMoseLeave(e)}}

                                item
                                xs={12}
                                draggable={enableDrag}
                                onDragStart={(e) => handleDragStart(e, message)}
                                onDragEnd={handleDragEnd}
                                onDragOver={handleDragOver}
                                onDrop={(e) => handleDrop(e, message)}
                                className={dragging.draggingItem === message ? "dragging" : ""}
                                sx={{
                                  backgroundColor: `#f1f1f199`,
                                  borderRadius: "10px 10px 10px 10px",
                                  padding: 2,
                                  ml: 2
                                }}
                              >
                                <Stack>
                                  <Stack
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                  >
                                    <Typography
                                      variant="caption"
                                      color="text.secondary"
                                    >
                                      {i18n.t(`${message.type} URL`)}
                                    </Typography>
                                    <Stack direction={"row"} spacing={1} alignItems="center">
                                      <DragHandle style={{
                                        cursor: "grab"
                                      }} />
                                      <IconButton
                                        size="small"
                                        onClick={() => handleDeleteMessage(index)}
                                      >
                                        <DeleteOutline />
                                      </IconButton>

                                    </Stack>
                                  </Stack>
                                  <Input
                                    onFocus={()=>setEnableDrag(false)}
                                    onBlur={()=>setEnableDrag(true)}
                                    placeholder={
                                      message.type === "Imagem"
                                        ? "https://example.com/image.png"
                                        : message.type === "Documento"
                                          ? "https://example.com/document.pdf"
                                          : "https://example.com/audio.mp3"
                                    }
                                    name="fileUrl"
                                    addonAfter={
                                      <Tooltip title={i18n.t("Anexar arquivo")}>
                                        <IconButton
                                          size="small"
                                          onClick={() => handleUpload(index)}
                                        >
                                          <AttachFileOutlined
                                            style={{ fontSize: 20, color: "white" }}
                                          />
                                        </IconButton>
                                      </Tooltip>
                                    }
                                    value={message.fileUrl}
                                    onChange={e => handleChangeMessages(e, index)}
                                    size="large"
                                  />
                                  <input
                                    onFocus={()=>setEnableDrag(false)}
                                    onBlur={()=>setEnableDrag(true)}
                                    accept={message?.type === "Imagem" ? "image/*" : message?.type === "Audio" ? "audio/*" : message?.type === "Video" ? "video/*" : "application/*"}
                                    type="file"
                                    id={index + "input"} style={{
                                      display: "none"
                                    }} />
                                  {message?.type === "Imagem" && message?.fileUrl && (
                                    <Stack alignItems="center" justifyContent="center" sx={{
                                      backgroundColor: `white`,
                                      borderRadius: "10px 10px 10px 10px",
                                      mt: 2
                                    }}>
                                      <img
                                        src={message.fileUrl}
                                        style={{
                                          maxWidth: "200px",
                                          height: "auto",
                                          marginTop: 10,
                                          borderRadius: 10
                                        }}
                                      />
                                    </Stack>
                                  )}
                                  {message?.type === "Audio" && message?.fileUrl && (
                                    <Stack alignItems="center" justifyContent="center" sx={{
                                      backgroundColor: `white`,
                                      borderRadius: "10px 10px 10px 10px",
                                      mt: 2,
                                      py: 2
                                    }}>
                                      <AudioPlayer
                                        src={message.fileUrl}
                                        minimal={true}
                                        width={350}
                                        trackHeight={40}
                                        barWidth={1}
                                        gap={1}
                                        seekBarColor="#e0e0e0"
                                        volumeControlColor="#000"
                                        visualise={true}
                                        backgroundColor="#e0e0e0"
                                        barColor="#000"
                                        barPlayedColor="#000"
                                        skipDuration={2}
                                        showLoopOption={true}
                                        showVolumeControl={true}
                                      />
                                    </Stack>
                                  )}

                                  {message?.type === "Video" && message?.fileUrl && (
                                    <Stack alignItems="center" justifyContent="center" sx={{
                                      backgroundColor: `white`,
                                      borderRadius: "10px 10px 10px 10px",
                                      mt: 2,
                                      py: 2
                                    }}>
                                      <video
                                        src={message.fileUrl}
                                        style={{
                                          maxWidth: "500px",
                                          height: "auto",
                                          marginTop: 10,
                                          borderRadius: 10
                                        }}
                                        controls
                                      />
                                    </Stack>
                                  )}
                                </Stack>
                              </Grid>

                            </>
                          )}

                          <Grid item xs={12} sx={{
                            mt: 0.5
                          }}>

                          </Grid>

                        </>
                      ))
                      : <Grid item xs={12} sx={{
                        backgroundColor: `#f1f1f199`,
                        borderRadius: "10px 10px 10px 10px",
                        padding: 2,
                        ml: 2,


                      }}>
                        <Stack direction="row" justifyContent="center" alignItems="center" sx={{
                          height: "200px"

                        }}>
                          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Selecione um tipo de mensagem" />

                        </Stack>
                      </Grid>
                    }
                  </Grid>


                </Stack>
              </div>

              <KeyboardArrowDownIcon></KeyboardArrowDownIcon>
            </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mt: 1 }}>



        </Grid>
      </Stack>
    </NodeMenu>
  );
};

export default MessageMenu;
