import React, { useState, useEffect, useRef } from "react";
import { ArrowDownward, ArrowDropDown, ArrowRight } from "@material-ui/icons";
import { Divider, Stack, Typography, Grid } from '@mui/material';
import { Input, Modal, Button,Collapse, theme } from "antd";
import ChannelVideo from "../../assets/videos/Canal.mp4";
import DepartamentVideo from "../../assets/videos/Departamento.mp4";
import UserVideo from "../../assets/videos/Usuario.mp4";
const TutorialModal = ({ open, onClose}) => {

  const handleClose = () => {
    onClose();

  };

  const getItems = (panelStyle) => [
    {
      key: '1',
      label: <Typography variant="h6" sx={{ ml: 1 , mt: -0.5}}>Criando um departamento</Typography>,
      children: <video src={ChannelVideo} width="900" height="450" controls />,
      style: panelStyle,
    },
    {
      key: '2',
      label: <Typography variant="h6" sx={{ ml: 1 , mt: -0.5}}>Criando usuários</Typography>,
      children: <video src={DepartamentVideo} width="900" height="450" controls />,
      style: panelStyle,
    },
    {
      key: '3',
      label: <Typography variant="h6" sx={{ ml: 1, mt: -0.5 }}>Conectando um canal</Typography>,
      children: <video src={UserVideo} width="900" height="450" controls />,
      style: panelStyle,
    },
  ];

  const { token } = theme.useToken();

  const panelStyle = {
    marginBottom: 24,
    background: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: 'none',
  };

  const renderTitle = () => {
    return <Stack   >
      <Typography variant="h6" sx={{ ml: 1 }}>
        Tutorial
      </Typography>
      <Typography variant="caption" sx={{ ml: 1 }}>
        Veja os vídeos abaixo para aprender a usar o sistema
      </Typography>
    </Stack>
  }

  return (

    <Modal
      title={renderTitle()}
      open={open}
      onCancel={handleClose}
      footer={null}
      width={1000}
    >
      <Divider sx={{ my: 1 }} />
      <Stack sx={{
        maxHeight: 500,
        overflowY: 'auto',
      }}>
   <Collapse
      bordered={false}
      style={{
        background: token.colorBgContainer,
      }}
      items={getItems(panelStyle)}
    />
    </Stack>
    </Modal>

  );
};

export default TutorialModal;
