import React, {
  useState,
  useEffect,
  useReducer,
  useCallback,
  useContext
} from "react";
import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import { TBody, THead, Table, TCell, TRow } from "../../components/Table";

import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditIcon from "@material-ui/icons/Edit";
import Search from "antd/es/input/Search";
import { Link } from "react-router-dom";

import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import Title from "../../components/Title";
import { Button } from "antd";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import PauseCircleOutlineIcon from "@material-ui/icons/PauseCircleOutline";

import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import FlowModal from "../../components/FlowModal";
import ConfirmationModal from "../../components/ConfirmationModal";
import toastError from "../../errors/toastError";
import { Chip } from "@material-ui/core";
import { socketConnection } from "../../services/socket";
import { AuthContext } from "../../context/Auth/AuthContext";
import { Stack, Typography, Tooltip } from "@mui/material";
import { CopyAll, Info } from "@mui/icons-material";
import moment from "moment";
import { useHistory } from "react-router-dom";
import ImportFlowModal from "../../components/ImportFlowModal";

const reducer = (state, action) => {
  if (action.type === "LOAD_FLOWS") {
    const flows = action.payload;
    const newFlows = [];

    flows.forEach(flow => {
      const flowIndex = state.findIndex(s => s.id === flow.id);
      if (flowIndex !== -1) {
        state[flowIndex] = flow;
      } else {
        newFlows.push(flow);
      }
    });

    return [...state, ...newFlows];
  }

  if (action.type === "UPDATE_FLOWS") {
    const flow = action.payload;
    const flowIndex = state.findIndex(s => s.id === flow.id);

    if (flowIndex !== -1) {
      state[flowIndex] = flow;
      return [...state];
    } else {
      return [flow, ...state];
    }
  }

  if (action.type === "DELETE_FLOW") {
    const flowId = action.payload;

    const flowIndex = state.findIndex(s => s.id === flowId);
    if (flowIndex !== -1) {
      state.splice(flowIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const useStyles = makeStyles(theme => ({
  mainPaper: {
    flex: 1,
    backgroundColor: "#fff",
    overflowY: "hidden",
    borderRadius: "10px",
    ...theme.scrollbarStyles
  }
}));

const Flows = () => {
  const classes = useStyles();

  const { user } = useContext(AuthContext);

  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [selectedFlow, setSelectedFlow] = useState(null);
  const [deletingFlow, setDeletingFlow] = useState(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [searchParam, setSearchParam] = useState("");
  const [flows, dispatch] = useReducer(reducer, []);
  const [importFlow, setImportFlow] = useState(false);
  const [flowModalOpen, setFlowModalOpen] = useState(false);
  const history = useHistory()
  const fetchFlows = useCallback(async () => {
    try {
      const { data } = await api.get("/flows/", {
        params: { searchParam, pageNumber }
      });
      dispatch({ type: "LOAD_FLOWS", payload: data.flows });
      setHasMore(data.hasMore);
      setLoading(false);
    } catch (err) {
      toastError(err);
    }
  }, [searchParam, pageNumber]);

  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [searchParam]);

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      fetchFlows();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchParam, pageNumber, fetchFlows]);

  useEffect(() => {
    const socket = socketConnection({ companyId: user.companyId });

    socket.on("user", data => {
      if (data.action === "update" || data.action === "create") {
        dispatch({ type: "UPDATE_FLOWS", payload: data.flows });
      }

      if (data.action === "delete") {
        dispatch({ type: "DELETE_USER", payload: +data.flowId });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, [user]);

  const handleOpenFlowModal = () => {
    setSelectedFlow(null);
    setFlowModalOpen(true);
  };

  const handleCloseFlowModal = () => {
    setSelectedFlow(null);
    setFlowModalOpen(false);
  };

  const handleSearch = event => {
    setSearchParam(event.target.value.toLowerCase());
  };

  const handleEditFlow = flow => {
    setSelectedFlow(flow);
    setFlowModalOpen(true);
  };

  const handleDeleteFlow = async flowId => {
    try {
      await api.delete(`/flows/${flowId}`);
      toast.success(i18n.t("Fluxo removido com sucesso!"));
    } catch (err) {
      toastError(err);
    }
    setDeletingFlow(null);
    setSearchParam("");
    setPageNumber(1);

    dispatch({ type: "RESET" });
    setPageNumber(1);
    await fetchFlows();
  };

  const handleUpdateFlowStatus = async (flowId,status) => {
    try {
      await api.post(`/flows/schedule/${flowId}`,{
        status: status
      });
      toast.success(i18n.t("Fluxo atualizado com sucesso!"));
    } catch (err) {
      toastError(err);
    }
    setSearchParam("");
    setPageNumber(1);

    dispatch({ type: "RESET" });
    setPageNumber(1);
    await fetchFlows();
  };

  const loadMore = () => {
    setPageNumber(prevState => prevState + 1);
  };

  const handleScroll = e => {
    if (!hasMore || loading) return;
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      loadMore();
    }
  };

  return (
    <MainContainer>
      <ConfirmationModal
        title={
          deletingFlow && `Deletar fluxo ${deletingFlow.name}`
        }
        open={confirmModalOpen}
        onClose={setConfirmModalOpen}
        onConfirm={() => handleDeleteFlow(deletingFlow.id)}
      >
        {i18n.t("flows.confirmationModal.deleteMessage")}
      </ConfirmationModal>
      <FlowModal
        open={flowModalOpen}
        onClose={handleCloseFlowModal}
        reload={fetchFlows}
        aria-labelledby="form-dialog-title"
        flowId={selectedFlow}
      />
      <ImportFlowModal
        open={importFlow}
        onClose={() => setImportFlow(false)}
        reload={fetchFlows}
        />
      <Paper
        className={classes.mainPaper}
        variant="outlined"
        onScroll={handleScroll}
      >
        <MainHeader>
          <Stack>
            <Typography
              variant="h5"
              color="black"
              style={{
                fontWeight: "bold",
                marginLeft: "10px",
                marginTop: "10px"
              }}
              gutterBottom
            >
              Fluxo de atendimento
            </Typography>
            <Typography
              style={{ marginTop: "-10px", marginLeft: "10px" }}
              variant="caption"
              color="textSecondary"
            >
              Crie fluxos para automatizar o atendimento
            </Typography>
          </Stack>

          <MainHeaderButtonsWrapper>
            <Search
              placeholder={i18n.t("Buscar")}
              size="large"
              type="search"
              value={searchParam}
              onChange={handleSearch}
              style={{
                width: "300px",
                marginRight: "10px",
                marginLeft: "10px"
              }}
            />
              <Button size="large" type="primary" onClick={()=> setImportFlow(true)}>
              Importar fluxo
            </Button>
            <Button size="large" type="primary" onClick={handleOpenFlowModal}>
              Criar fluxo
            </Button>
          </MainHeaderButtonsWrapper>
        </MainHeader>
        <Stack
          style={{
            padding: "20px",
            backgroundColor: "#fff",
            borderRadius: "5px",
            height: "93%",
            overflowY: "auto"
          }}
        >
          <Paper
            style={{
              boxShadow: "none",
              backgroundColor: "#fff"
            }}
          >
            <Table
              size="small"
              sx={{
                backgroundColor: "#fff"
              }}
            >
              <THead sticky="true">
                <TRow>
                  <TCell style={{ color: "#fff" }} align="center">
                    Status
                  </TCell>
                  <TCell style={{ color: "#fff" }} align="center">
                    Nome
                  </TCell>
                  <TCell style={{ color: "#fff" }} align="center">
                    Criado em
                  </TCell>
                  <TCell style={{ color: "#fff" }} align="center">
                    Atualizado em
                  </TCell>
                  <TCell style={{ color: "#fff" }} align="center">
                    Agendamento
                  </TCell>
                  <TCell style={{ color: "#fff" }} align="center">
                    Webhook
                  </TCell>
                  <TCell style={{ color: "#fff" }} align="center">
                    Ações
                  </TCell>
                </TRow>
              </THead>
              <TBody>
                <>
                  {flows.map(flow => (
                    <TRow key={flow.id}>
                      <TCell align="center">
                        <Chip
                          label={
                            flow?.status === "PUBLISHED"
                              ? "PUBLICADO"
                              : "RASCUNHO"
                          }
                          size="small"
                          color={
                            flow.status === "PUBLISHED"
                              ? "primary"
                              : "primary.main"
                          }
                        />
                      </TCell>
                      <TCell align="center">
                        <Stack
                          direction="row"
                          alignItems="center"
                          justifyContent="center"
                          spacing={1}
                        >
                          <Typography variant="body2" color="primary" sx={{
                            cursor: "pointer"
                          }} onClick={() => {
                            //add to params flow name
                            window.location.href = `/flow/${flow.meta.name}`
                         
                          }}> {flow.name} </Typography>
                          {flow?.description && (
                            <Tooltip title={flow?.description} placement="top">
                              <IconButton size="small" sx={{ ml: 1 }}>
                                <Info sx={{ fontSize: 16 }} />
                              </IconButton>
                            </Tooltip>
                          )}
                        </Stack>
                      </TCell>
                      <TCell align="center">{moment(flow?.createdAt).format("DD/MM/YY HH:mm")}</TCell>
                      <TCell align="center">{moment(flow?.updatedAt).format("DD/MM/YY HH:mm")}</TCell>
                      <TCell align="center">
                        {flow?.meta?.days ? (
                          <Chip
                            label="Com agendamento"
                            size="small"
                            color="primary"
                          />
                        ): (
                          <Chip
                            label="Sem agendamento"
                            size="small"
                            color="default"
                          />
                        

                        )}
                      </TCell>

                      <TCell align="center">
                        <Tooltip title="Copiar webhook" placement="top">
                        <IconButton size="small" onClick={()=>{
                          navigator.clipboard.writeText(`${process.env.REACT_APP_BACKEND_URL}/runtime/${flow?.path}/${flow?.access_token}`)
                          toast.success("Webhook copiado")
                        }}>
                          <CopyAll />
                        </IconButton>
                        </Tooltip>
                      </TCell>

                      <TCell align="center">
                        {flow?.meta?.days ? (
                            <>
                              {flow?.meta?.schedule ? (
                                <Tooltip title="Parar agendamento" style={{
                                  marginLeft: "-30px"
                                }}>
                                   <IconButton
                                   onClick={() => handleUpdateFlowStatus(flow.id,false)}
                                   title="Parar agendamento"
                                   size="small"
                                 >
                                   <PauseCircleOutlineIcon />
                                 </IconButton>
                                </Tooltip>
                              ):(
                                <Tooltip title="Iniciar agendamento" style={{
                                  marginLeft: "-30px"
                                }}>
                                <IconButton
                                onClick={() => handleUpdateFlowStatus(flow.id,true)}
                                title="Iniciar agendamento"
                                size="small"
                              >
                                <PlayCircleOutlineIcon />
                              </IconButton>
                            </Tooltip>


                              )}
                            </>

                        ):null}
                        <IconButton
                          size="small"
                          onClick={() => handleEditFlow(flow)}
                        >
                          <EditIcon />
                        </IconButton>

                        <IconButton
                          size="small"
                          onClick={e => {
                            setConfirmModalOpen(true);
                            setDeletingFlow(flow);
                          }}
                        >
                          <DeleteOutlineIcon />
                        </IconButton>
                      </TCell>
                    </TRow>
                  ))}
                  {loading && <TableRowSkeleton columns={3} />}
                </>
              </TBody>
            </Table>
          </Paper>
        </Stack>
      </Paper>
    </MainContainer>
  );
};

export default Flows;
