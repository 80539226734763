import React, { useContext, useEffect, useReducer, useState } from "react";
import { Link as RouterLink, useHistory, useLocation } from "react-router-dom";
import { Menu, Badge } from "antd";
import {
  WhatsAppOutlined,
  SyncOutlined,
  SettingOutlined,
  UsergroupAddOutlined,
  ContactsOutlined,
  ApartmentOutlined,
  ThunderboltOutlined,
  CalendarOutlined,
  TagsOutlined,
  RocketOutlined,
  DollarOutlined,
  HistoryOutlined,
  HomeOutlined,
  ToolOutlined,
  AppstoreOutlined,
} from "@ant-design/icons";
import { i18n } from "../translate/i18n";
import { WhatsAppsContext } from "../context/WhatsApp/WhatsAppsContext";
import { AuthContext } from "../context/Auth/AuthContext";
import { Can } from "../components/Can";
import { socketConnection } from "../services/socket";
import api from "../services/api";
import OnlyForSuperUser from "../components/OnlyForSuperUser";
import toastError from "../errors/toastError";
import { isArray } from "lodash";

const { SubMenu } = Menu;

function getPathSelected(to, pathname) {
  return pathname === to;
}

const reducer = (state, action) => {
  if (action.type === "LOAD_CHATS") {
    const chats = action.payload;
    const newChats = [];

    if (isArray(chats)) {
      chats.forEach((chat) => {
        const chatIndex = state.findIndex((u) => u.id === chat.id);
        if (chatIndex !== -1) {
          state[chatIndex] = chat;
        } else {
          newChats.push(chat);
        }
      });
    }

    return [...state, ...newChats];
  }

  if (action.type === "UPDATE_CHATS") {
    const chat = action.payload;
    const chatIndex = state.findIndex((u) => u.id === chat.id);

    if (chatIndex !== -1) {
      state[chatIndex] = chat;
      return [...state];
    } else {
      return [chat, ...state];
    }
  }

  if (action.type === "DELETE_CHAT") {
    const chatId = action.payload;

    const chatIndex = state.findIndex((u) => u.id === chatId);
    if (chatIndex !== -1) {
      state.splice(chatIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }

  if (action.type === "CHANGE_CHAT") {
    const changedChats = state.map((chat) => {
      if (chat.id === action.payload.chat.id) {
        return action.payload.chat;
      }
      return chat;
    });
    return changedChats;
  }
};

const MainListItems = (props) => {
  const { drawerClose } = props;
  const { whatsApps } = useContext(WhatsAppsContext);
  const { user } = useContext(AuthContext);
  const [connectionWarning, setConnectionWarning] = useState(false);
  const [showCampaigns, setShowCampaigns] = useState(false);
  const location = useLocation();
  const [invisible, setInvisible] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchParam] = useState("");
  const [chats, dispatch] = useReducer(reducer, []);
  const [openKeys, setOpenKeys] = useState([]);

  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [searchParam]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      fetchChats();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchParam, pageNumber]);

  useEffect(() => {
    const companyId = localStorage.getItem("companyId");
    const socket = socketConnection({ companyId });

    socket.on(`company-${companyId}-chat`, (data) => {
      if (data.action === "new-message") {
        dispatch({ type: "CHANGE_CHAT", payload: data });
      }
      if (data.action === "update") {
        dispatch({ type: "CHANGE_CHAT", payload: data });
      }
    });
    return () => {
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    let unreadsCount = 0;
    if (chats.length > 0) {
      for (let chat of chats) {
        for (let chatUser of chat.users) {
          if (chatUser.userId === user.id) {
            unreadsCount += chatUser.unreads;
          }
        }
      }
    }
    if (unreadsCount > 0) {
      setInvisible(false);
    } else {
      setInvisible(true);
    }
  }, [chats, user.id]);

  useEffect(() => {
    if (localStorage.getItem("cshow")) {
      setShowCampaigns(true);
    }
  }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (whatsApps.length > 0) {
        const offlineWhats = whatsApps.filter((whats) => {
          return (
            whats.status === "qrcode" ||
            whats.status === "PAIRING" ||
            whats.status === "DISCONNECTED" ||
            whats.status === "TIMEOUT" ||
            whats.status === "OPENING"
          );
        });
        if (offlineWhats.length > 0) {
          setConnectionWarning(true);
        } else {
          setConnectionWarning(false);
        }
      }
    }, 2000);
    return () => clearTimeout(delayDebounceFn);
  }, [whatsApps]);

  const fetchChats = async () => {
    try {
      const { data } = await api.get("/chats/", {
        params: { searchParam, pageNumber },
      });
      dispatch({ type: "LOAD_CHATS", payload: data.records });
    } catch (err) {
      toastError(err);
    }
  };

  const renderLink = (to, label, icon, subMenu = false) => {
    const checkSelected = getPathSelected(to, location.pathname);
    const menuItemStyle = {
      display: "flex",
      alignItems: "center",
      fontSize: "16px",
      padding: "0 22px",
      height: "40px",
      backgroundColor: checkSelected ? "#091A2F" : "transparent",
      color: checkSelected ? "#FFFFFF" : "inherit",
    };

    return (
      <Menu.Item key={to} style={menuItemStyle} icon={icon}>
        <RouterLink to={to} style={{ marginLeft: 14, color: "inherit" }}>
          {label}
        </RouterLink>
      </Menu.Item>
    );
  };

  const renderSubMenu = (key, label, icon, children) => {
    const handleSubMenuClick = (key) => {
      setOpenKeys((prevOpenKeys) =>
        prevOpenKeys.includes(key) ? [] : [key]
      );
    };

    const checkSelected = openKeys.includes(key);
    const menuItemStyle = {
      display: "flex",
      alignItems: "center",
      fontSize: "16px",
      padding: "0 22px",
      height: "40px",
      backgroundColor: checkSelected ? "#091A2F" : "transparent",
      color: checkSelected ? "#FFFFFF" : "inherit",
    };

    return (
      <SubMenu
        key={key}
        title={
          <span style={menuItemStyle} onClick={() => handleSubMenuClick(key)}>
            {icon}
            <span>{label}</span>
          </span>
        }
      >
        {children}
      </SubMenu>
    );
  };

  return (
    <>
      <Menu
        mode="inline"
        defaultSelectedKeys={['/']}
        selectedKeys={[location.pathname]}
        theme="light"
        onClick={drawerClose}
        openKeys={openKeys}
        onOpenChange={(keys) => setOpenKeys(keys)}
      >
        <Can
          role={user.profile}
          perform="dashboard:view"
          yes={() => renderLink("/", "Dashboard", <HomeOutlined />)}
        />
        {renderLink(
          "/tickets",
          i18n.t("mainDrawer.listItems.tickets"),
          <WhatsAppOutlined />
        )}
        <Can
          role={user.profile}
          perform="broadcasts:view"
          yes={() =>
            renderLink(
              "/broadcasts",
              i18n.t("mainDrawer.listItems.campaigns"),
              <RocketOutlined />
            )
          }
        />
        <Can
          role={user.profile}
          perform="flows:view"
          yes={() =>
            renderLink("/flows", i18n.t("Fluxos"), <ThunderboltOutlined />)
          }
        />
        {renderLink(
          "/contacts",
          i18n.t("mainDrawer.listItems.contacts"),
          <ContactsOutlined />
        )}
        {renderLink(
          "/schedules",
          i18n.t("mainDrawer.listItems.schedules"),
          <CalendarOutlined />
        )}
        {renderLink(
          "/quick-messages",
          i18n.t("mainDrawer.listItems.quickMessages"),
          <ThunderboltOutlined />
        )}
        {renderLink("/tags", i18n.t("Etiquetas"), <TagsOutlined />)}
        <Can
          role={user.profile}
          perform="drawer-admin-items:view"
          yes={() =>
            renderSubMenu("settings", "Configurações", <SettingOutlined style={{ marginRight: "14px" }} />, [
              renderLink(
                "/queues",
                i18n.t("Departamentos"),
                <ApartmentOutlined />,
                true
              ),
              <Can
                role={user.profile}
                perform="connections:view"
                yes={() =>
                  renderLink(
                    "/connections",
                    i18n.t("mainDrawer.listItems.connections"),
                    <Badge
                      count={connectionWarning ? "!" : 0}
                      offset={[10, 0]}
                      style={{ transform: "translate(10px, -10px)" }}
                    >
                      <SyncOutlined />
                    </Badge>,
                    true
                  )
                }
              />,
              renderLink(
                "/users",
                i18n.t("mainDrawer.listItems.users"),
                <UsergroupAddOutlined />,
                true
              ),
              renderLink(
                "/financeiro",
                i18n.t("Financeiro"),
                <DollarOutlined />,
                true
              ),
              renderLink(
                "/account",
                "Dados da conta",
                <AppstoreOutlined />,
                true
              ),
              renderLink(
                "/logs",
                i18n.t("Logs"),
                <HistoryOutlined />,
                true
              ),
            ])
          }
        />
        <OnlyForSuperUser
          user={user}
          yes={() =>
            renderSubMenu("admin", "Administração", <ToolOutlined style={{ marginRight: "14px" }} />, [
              renderLink("/accounts", "Contas", <AppstoreOutlined />, true),
              renderLink("/plans", "Planos", <DollarOutlined />, true),
              renderLink(
                "/invoicing",
                "Faturamento",
                <DollarOutlined />,
                true
              ),
            ])
          }
        />
      </Menu>

    </>
  );
};

export default MainListItems;
