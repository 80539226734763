import React, { useState, useEffect, useContext } from "react";

import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Colorize } from "@material-ui/icons";
import { ColorBox } from "material-ui-color";

import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";
import { IconButton, InputAdornment } from "@material-ui/core";
import { Input, Modal, Button } from "antd";
import { Divider, Stack, Typography, Grid } from '@mui/material';
import { ColorPicker } from 'antd';
const { TextArea } = Input;
const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap"
  },
  multFieldLine: {
    display: "flex",
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(1)
    }
  },

  btnWrapper: {
    position: "relative"
  },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  colorAdorment: {
    width: 20,
    height: 20
  }
}));

const FlowSchema = Yup.object().shape({
  name: Yup.string()
});

const FlowModal = ({ open, onClose, flowId, reload, onTicketUpdate }) => {
  const classes = useStyles();
  const { user } = useContext(AuthContext);
  const [colorPickerModalOpen, setColorPickerModalOpen] = useState(false);
  const [color, setColor] = useState("#0F69E7");
  const initialState = {
    name: "",
    description: "",
  };

  const [flow, setFlow] = useState(initialState);

  useEffect(()=>{
    return () =>{
      setFlow(initialState);
    }
  },[])

  useEffect(() => {
    if(flowId){
      setFlow({
        name: flowId.name,
        description: flowId.description,
      })
    }
  }, [flowId]);

  const handleClose = () => {
    setFlow(initialState);
    setColorPickerModalOpen(false);
    onClose();
  };

  const handleSaveFlow = async values => {
    const flowData = { ...values };
    try {
      if(!flowData.name || flowData.name.length < 3)return toastError('O nome deve ter pelo menos 3 caracteres!')
      if (flowId) {
        await api.put(`/flow/${flowId.id}`, flowData);
      } else {
        const { data } = await api.post("/flows", flowData);
        onTicketUpdate && onTicketUpdate(data);
      }
      toast.success(i18n.t("Fluxo salvo com sucesso"));
      if (typeof reload == "function") {
        reload();
      }
    } catch (err) {
      toastError(err);
    }
    handleClose();
  };


  const renderTitle = () => {
    return <Stack   >
      <Typography variant="h6" sx={{ ml: 1 }}>
        {flowId ? `Editar fluxo` : `Novo fluxo`}
      </Typography>
      <Typography variant="caption" sx={{ ml: 1 }}>
        {flowId ? `Editar fluxo` : `Adicione um novo fluxo`}
      </Typography>
    </Stack>
  }

  return (

    <Modal
      title={renderTitle()}
      open={open}
      onCancel={handleClose}
      footer={null}
    >
      <Divider sx={{ my: 1 }} />
      <Stack p={1}>
        <Formik
          initialValues={flow}
          enableReinitialize={true}
          validationSchema={FlowSchema}
          onSubmit={(values, actions) => {
            setTimeout(() => {
              handleSaveFlow({
                ...values,
                color: color
              });
              actions.setSubmitting(false);
            }, 400);
          }}
        >
          {({ touched, errors, isSubmitting, values, handleChange, handleSubmit }) => (
            <Form >
              <Grid container spacing={1} sx={{p:1}}>
                <Grid item xs>
                  <Stack >
                    <Typography variant="caption" color="gray">
                      Nome*
                    </Typography>
                    <Input
                      size="large"
                      id="name"
                      value={values.name}
                      onChange={handleChange}
                      name="name"
                      placeholder="Nome do fluxo"
                    />
                  </Stack>
                </Grid>


                <Grid item xs={12}>
                  <Stack >
                    <Typography variant="caption" color="gray">
                      Descrição
                    </Typography>
                    <TextArea
                      // multiline
                
                      
                      size="large"
                      id="description"
                      value={values.description}
                      onChange={handleChange}
                      name="description"
                      placeholder="Descrição do fluxo"
                    />
                  </Stack>
                </Grid>

              </Grid>
              <DialogActions style={{ marginTop: 10 }}>
                <Button
                  onClick={handleClose}
                  color="secondary"
                  disabled={isSubmitting}
                  variant="outlined"
                >
                  Cancelar
                </Button>
                <Button
                  type="primary"
                  color="primary"
                  disabled={isSubmitting}
                  onClick={handleSubmit}
                  variant="contained"
                  className={classes.btnWrapper}
                >
                  {flowId
                    ? `Editar`
                    : `Salvar`}
                  {isSubmitting && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </Button>
              </DialogActions>

            </Form>
          )}
        </Formik>
      </Stack>
    </Modal>
  );
};

export default FlowModal;
