import { Button } from "antd";
import React, { useState } from "react";

import { Stack } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";

import Wallet from "./components/wallet";
import Import from "./components/import";
import Contacts from "./components/contacts";
import MainContainer from "../../components/MainContainer";

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    backgroundColor: "#fff",
    overflowY: "hidden",
    borderRadius: "10px",
    ...theme.scrollbarStyles,
  },
}));

const MainContacts = () => {
  const classes = useStyles();

  const [tab, setTab] = useState(0);

  return (
    <MainContainer className={classes.mainContainer}>
      <Stack direction="row" spacing={1} sx={{ ml: 4, mb: -2 }}>
        <Button
          type={tab === 0 ? "primary" : "dashed"}
          onClick={() => setTab(0)}
          style={{
            borderRadius: "10px 10px 0px 0px",
            boxShadow: "none",
          }}
          size="large"
        >
          Contatos
        </Button>
        <Button
          type={tab === 1 ? "primary" : "dashed"}
          onClick={() => setTab(1)}
          style={{
            borderRadius: "10px 10px 0px 0px",
            boxShadow: "none",
          }}
          size="large"
        >
          Carteiras
        </Button>
        <Button
          type={tab === 2 ? "primary" : "dashed"}
          onClick={() => setTab(2)}
          style={{
            borderRadius: "10px 10px 0px 0px",
            boxShadow: "none",
          }}
          size="large"
        >
          Importados
        </Button>
      </Stack>
      {tab === 0 && <Contacts />}
      {tab === 1 && <Wallet />}
      {tab === 2 && <Import />}
    </MainContainer>
  );
};

export default MainContacts;
