import React, { useState, useEffect, useContext, useReducer } from 'react';
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  MessageOutlined,
  SyncOutlined,
  SettingOutlined,
  UsergroupAddOutlined,
  ContactsOutlined,
  ApartmentOutlined,
  ThunderboltOutlined,
  CalendarOutlined,
  TagsOutlined,
  NotificationOutlined,
  DollarOutlined,
  ContainerOutlined,
  HistoryOutlined,
  BarChartOutlined,
  ToolOutlined,
  WalletOutlined,
  CopyrightOutlined,
  UserOutlined,
  PartitionOutlined,
  LogoutOutlined,
  ArrowUpOutlined,
  ArrowDownOutlined
} from '@ant-design/icons';
import { Layout, Menu, Button, Avatar, Tooltip, Badge, Popover, Typography, Divider, theme } from 'antd';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { AuthContext } from "../context/Auth/AuthContext";
import { WhatsAppsContext } from "../context/WhatsApp/WhatsAppsContext";
import { socketConnection } from "../services/socket";
import toastError from "../errors/toastError";
import QrCodeModalSupport from "../components/QrCodeModalSupport";
import NotificationsPopOver from "../components/NotificationsPopOver";
import TutorialModal from "./TutorialModal/Tutorial";
import EditUserModal from "../components/EditUserModal";
import { i18n } from "../translate/i18n";
import BackdropLoading from "../components/BackdropLoading";
import logo from "../assets/logoCompact.svg";
import logoPequena from "../assets/logo.svg";
import api from '../services/api';

const { useToken } = theme;

const { Header, Sider, Content } = Layout;

const reducer = (state, action) => {
  switch (action.type) {
    case 'LOAD_CHATS':
      const chats = action.payload;
      const newChats = [];

      if (Array.isArray(chats)) {
        chats.forEach((chat) => {
          const chatIndex = state.findIndex((u) => u.id === chat.id);
          if (chatIndex !== -1) {
            state[chatIndex] = chat;
          } else {
            newChats.push(chat);
          }
        });
      }

      return [...state, ...newChats];
    case 'UPDATE_CHATS':
      const chat = action.payload;
      const chatIndex = state.findIndex((u) => u.id === chat.id);

      if (chatIndex !== -1) {
        state[chatIndex] = chat;
        return [...state];
      } else {
        return [chat, ...state];
      }
    case 'DELETE_CHAT':
      const chatId = action.payload;
      const chatIndexDelete = state.findIndex((u) => u.id === chatId);
      if (chatIndexDelete !== -1) {
        state.splice(chatIndexDelete, 1);
      }
      return [...state];
    case 'RESET':
      return [];
    case 'CHANGE_CHAT':
      const changedChats = state.map((chat) => {
        if (chat.id === action.payload.chat.id) {
          return action.payload.chat;
        }
        return chat;
      });
      return changedChats;
    default:
      return state;
  }
};

const LoggedInLayout = ({ children }) => {
  const { token } = useToken();
  const [collapsed, setCollapsed] = useState(() => {
    const savedState = localStorage.getItem('sider-collapsed');
    return savedState ? JSON.parse(savedState) : true;
  });
  const { user, handleLogout, loading } = useContext(AuthContext);
  const [drawerOpen, setDrawerOpen] = useState(true);
  const [qrCodeModalOpen, setQrCodeModalOpen] = useState(false);
  const [userModalOpen, setUserModalOpen] = useState(false);
  const [openTutorialModal, setOpenTutorialModal] = useState(false);
  const location = useLocation();
  const [chats, dispatch] = useReducer(reducer, []);
  const [connectionWarning, setConnectionWarning] = useState(false);
  const { whatsApps } = useContext(WhatsAppsContext);
  const [menuOpen, setMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [flowId, setFlowId] = useState(null);
  const [invisible, setInvisible] = useState(true);
  const history = useHistory();
  const version = "v1.0.0";

  useEffect(() => {
    const path = window.location.pathname.split('/');
    if (path[1] === 'flow') {
      setFlowId(path[2]);
    } else {
      setFlowId(null);
    }
    return () => {
      setFlowId(null);
    };
  }, [location.pathname]);

  useEffect(() => {
    const companyId = localStorage.getItem('companyId');
    const socket = socketConnection({ companyId });

    socket.on(`company-${companyId}-chat`, (data) => {
      if (data.action === 'new-message') {
        dispatch({ type: 'CHANGE_CHAT', payload: data });
      }
      if (data.action === 'update') {
        dispatch({ type: 'CHANGE_CHAT', payload: data });
      }
    });
    socket.on(`company-${companyId}-auth`, (data) => {
      if (data.user.id === +user.id) {
        toastError('Sua conta foi acessada em outro computador.');
        setTimeout(() => {
          localStorage.clear();
          window.location.reload();
        }, 1000);
      }
    });

    socket.emit('userStatus');
    const interval = setInterval(() => {
      socket.emit('userStatus');
    }, 1000 * 60 * 5);

    return () => {
      socket.disconnect();
      clearInterval(interval);
    };
  }, [user]);

  useEffect(() => {
    const fetchChats = async () => {
      try {
        const { data } = await api.get('/chats/', {
          params: { searchParam: '', pageNumber: 1 },
        });
        dispatch({ type: 'LOAD_CHATS', payload: data.records });
      } catch (err) {
        toastError(err);
      }
    };

    fetchChats();
  }, []);

  useEffect(() => {
    let unreadsCount = 0;
    if (chats.length > 0) {
      for (let chat of chats) {
        for (let chatUser of chat.users) {
          if (chatUser.userId === user.id) {
            unreadsCount += chatUser.unreads;
          }
        }
      }
    }
    setInvisible(unreadsCount === 0);
  }, [chats, user.id]);

  useEffect(() => {
    const offlineWhats = whatsApps.filter((whats) => {
      return (
        whats.status === 'qrcode' ||
        whats.status === 'PAIRING' ||
        whats.status === 'DISCONNECTED' ||
        whats.status === 'TIMEOUT' ||
        whats.status === 'OPENING'
      );
    });
    setConnectionWarning(offlineWhats.length > 0);
  }, [whatsApps]);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
    setMenuOpen(true);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setMenuOpen(false);
  };

  const handleOpenQrCodeModal = () => {
    setQrCodeModalOpen(true);
  };

  const handleCloseQrCodeModal = () => {
    setQrCodeModalOpen(false);
  };

  const handleOpenUserModal = () => {
    setUserModalOpen(true);
    handleCloseMenu();
  };

  const handleClickLogout = () => {
    handleCloseMenu();
    handleLogout();
  };

  const renderLink = (to, label, icon) => {
    const selected = to === '/' ? location.pathname === to : location.pathname.startsWith(to);
    return {
      key: to,
      icon: <Tooltip title={label}>{icon}</Tooltip>,
      label: (
        <Link to={to} style={{ marginLeft: collapsed ? 0 : 0, color: 'inherit' }} onClick={() => setDrawerOpen(false)}>
          {label}
        </Link>
      ),
      style: selected ? { background: token.colorSecondary, color: token.colorPrimary } : {},
    };
  };

  const renderLinkWithBadge = (to, label, icon, badgeCount, isSettings = false, isConnections = false) => {
    const selected = location.pathname.startsWith(to);
    const badgeStyle = {
      position: 'absolute',
      top: isSettings ? (collapsed ? 10 : -100) : 0,
      right: isSettings ? (collapsed ? -5 : 0) : -100,
      fontSize: isSettings ? (collapsed ? '10px' : '12px') : '16px',
    };

    return {
      key: to,
      icon: (
        <Link to={to} style={{ color: 'inherit' }} onClick={() => setDrawerOpen(false)}>
          <Badge count={badgeCount} style={badgeStyle}>
            {React.cloneElement(icon, { style: { color: selected ? token.colorPrimary : token.colorLight, } })}
          </Badge>
        </Link>
      ),
      label: (
        <Link
          to={to}
          style={{
            marginLeft: collapsed ? 0 : 0,
            color: selected ? token.colorPrimary : 'inherit',
            position: 'relative',
          }}
          onClick={() => setDrawerOpen(false)}
        >
          {label}
        </Link>
      ),
      style: selected ? { background: token.colorSecondary, color: token.colorPrimary } : {},
    };
  };

  const adminItems = [
    renderLink('/accounts', 'Empresas', <CopyrightOutlined />),
    renderLink('/plans', 'Planos', <ContainerOutlined />),
    renderLink('/invoicing', 'Faturamento', <WalletOutlined />),
  ];

  const settingsItems = [
    renderLink('/queues', i18n.t('Departamentos'), <ApartmentOutlined />),
    renderLinkWithBadge('/connections', i18n.t('mainDrawer.listItems.connections'), <SyncOutlined />, connectionWarning ? '!' : 0, false, true),
    renderLink('/users', i18n.t('mainDrawer.listItems.users'), <UsergroupAddOutlined />),
    renderLink('/financeiro', i18n.t('Financeiro'), <DollarOutlined />),
    renderLink('/account', 'Dados da conta', <UserOutlined />),
    renderLink('/logs', i18n.t('Logs'), <HistoryOutlined />),
  ];

  const menuItems = [
    renderLink('/', 'Dashboard', <BarChartOutlined />),
    renderLink('/tickets', i18n.t('mainDrawer.listItems.tickets'), <MessageOutlined />),
    renderLink('/broadcasts', i18n.t('mainDrawer.listItems.campaigns'), <NotificationOutlined />),
    renderLink('/flows', 'Fluxos', <PartitionOutlined />),
    renderLink('/contacts', i18n.t('mainDrawer.listItems.contacts'), <ContactsOutlined />),
    renderLink('/schedules', i18n.t('mainDrawer.listItems.schedules'), <CalendarOutlined />),
    renderLink('/quick-messages', i18n.t('mainDrawer.listItems.quickMessages'), <ThunderboltOutlined />),
    renderLink('/tags', 'Etiquetas', <TagsOutlined />),
    {
      key: 'settings',
      icon: (
        <Tooltip title="Configurações">
          <Badge
            count={connectionWarning ? '!' : 0}
            style={{
              position: 'absolute',
              top: collapsed ? 8 : 0,
              right: collapsed ? -8 : -130,
              maxHeight: collapsed ? 15 : 18,
              minWidth: collapsed ? 15 : 18,
              fontSize: collapsed ? '12px' : '16px',
            }}
          >
            <SettingOutlined />
          </Badge>
        </Tooltip>
      ),
      label: (
        <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
          Configurações
        </div>
      ),
      children: settingsItems,
      expandIcon: ({ isOpen }) => (
        !collapsed && (
          isOpen ? (
            <ArrowUpOutlined style={{ color: token.colorLight, position: 'absolute', right: '10px' }} />
          ) : (
            <ArrowDownOutlined style={{ color: token.colorLight, position: 'absolute', right: '10px' }} />
          )
        )
      )
    },
    {
      key: 'admin',
      icon: <Tooltip title="Administração"><ToolOutlined /></Tooltip>,
      label: 'Administração',
      children: adminItems,
      expandIcon: ({ isOpen }) => (
        !collapsed && (
          isOpen ? (
            <ArrowUpOutlined style={{ color: token.colorLight, position: 'absolute', right: '10px' }} />
          ) : (
            <ArrowDownOutlined style={{ color: token.colorLight, position: 'absolute', right: '10px' }} />
          )
        )
      )
    },
  ];

  const filteredMenuItems = menuItems.filter(item => {
    if (item.key === '/' || item.key === '/broadcasts' || item.key === '/flows' || item.key === 'settings') {
      return user.profile === 'admin';
    }
    if (item.key === 'admin') {
      return user.super;
    }
    return true;
  });

  if (loading) {
    return <BackdropLoading />;
  }

  return (
    <Layout style={{ minHeight: '100vh', overflow: 'hidden' }}>
      <QrCodeModalSupport
        open={qrCodeModalOpen}
        onClose={handleCloseQrCodeModal}
      />

      <TutorialModal
        open={openTutorialModal}
        onClose={() => setOpenTutorialModal(false)}
      />

      <EditUserModal
        open={userModalOpen}
        onClose={() => setUserModalOpen(false)}
        userId={user?.id}
      />

      {!flowId && (
        <Sider
          trigger={null}
          collapsible
          collapsed={collapsed}
          width={220}
          style={{
            height: '100vh',
            position: 'fixed',
            left: 0,
            overflow: 'hidden',
          }}
          collapsedWidth={70}
          onCollapse={(collapsed) => {
            setCollapsed(collapsed);
            localStorage.setItem('sider-collapsed', collapsed);
          }}
        >
          <div className="logo" style={{ padding: 16, textAlign: 'center' }}>
            <img
              src={collapsed ? logo : logoPequena}
              alt="Logo"
              style={{
                height: '32px',
              }}
            />
          </div>
          <Menu theme="dark" mode="inline" defaultSelectedKeys={['1']} items={filteredMenuItems} />
          {/* <div style={{
            textAlign: 'center',
            padding: '5px',
            display: "flex",
            alignItems: "center",
            paddingLeft: collapsed ? '40px' : '35px',
            color: token.colorLight,
            backgroundColor: token.colorTextSecondary,
            fontWeight: 'bold',
            fontSize: collapsed ? '10px' : '12px',
            borderRadius: "8px",
            border: "none",
            position: 'absolute',
            bottom: 10,
            left: '10%',
            transform: 'translateX(-50%)',
            width: collapsed ? '120%' : '40%',
          }}>
            {version}
          </div> */}
          <div style={{
            textAlign: 'center',
            padding: '5px',
            color: token.colorLight,
            backgroundColor: token.colorTextSecondary,
            fontWeight: 'bold',
            fontSize: collapsed ? '10px' : '12px',
            position: 'absolute',
            bottom: 0,
            width: '100%',
          }}>
            {version}
          </div>
        </Sider>
      )}

      <Layout className="site-layout" style={{ marginLeft: !flowId && !collapsed ? 220 : 70, overflow: 'hidden' }}>
        <Header
          className="site-layout-background"
          style={{
            padding: 0,
            background: token.colorGradient,
            position: 'fixed',
            width: `calc(100% - ${!flowId && !collapsed ? 220 : 70}px)`,
            zIndex: 1,
          }}
        >
          {!flowId && (
            <Button
              type="text"
              icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              onClick={() => {
                const newCollapsedState = !collapsed;
                setCollapsed(newCollapsedState);
                localStorage.setItem('sider-collapsed', newCollapsedState);
              }}
              style={{
                fontSize: '16px',
                width: 64,
                height: 64,
                color: token.colorSecondary,
              }}
            />
          )}
          <div style={{ float: 'right', paddingRight: 24, background: token.colorSecondary, color: token.colorPrimary }}>
            {user.id && <NotificationsPopOver />}
            <Popover
              content={
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <Avatar size="default" icon={<UserOutlined />} />
                  <Typography.Text>{user?.name}</Typography.Text>
                  <Divider style={{ margin: '8px 0' }} />
                  <Button type="text" onClick={handleOpenUserModal} style={{ width: '100%', textAlign: 'left' }}>
                    <UserOutlined style={{ marginRight: 8 }} />
                    {i18n.t('Perfil')}
                  </Button>
                  <Button type="text" onClick={handleClickLogout} style={{ width: '100%', textAlign: 'left' }}>
                    <LogoutOutlined style={{ marginRight: 8 }} />
                    {i18n.t('Sair')}
                  </Button>
                </div>
              }
              trigger="click"
            >
              <Avatar icon={<UserOutlined />} style={{ cursor: 'pointer' }} />
            </Popover>
          </div>
        </Header>
        <Content
          style={{
            paddingTop: 64,
            overflow: 'auto',
            height: 'calc(100vh - 64px)',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            borderRadius: "12px",
            background: token.colorLight,
          }}
        >
          {children}
        </Content>
      </Layout>
    </Layout>
  );
};

export default LoggedInLayout;
