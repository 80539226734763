import React from "react";
import {
  getBezierPath,
} from "react-flow-renderer";
import { Path, PathSelected } from "./styled";

export default function CustomEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  data,
  color,
  markerEnd,
  selected = false
}) {
  const edgePath = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
    curvature: 0.1
  });
  return (
    <>
      {selected && (
        <PathSelected
          id={id}
          className="react-flow__edge-path"
          color={color}
          d={edgePath}
        />
      )}

      <Path
        id={id}
        className="react-flow__edge-path"
        selected={selected}
        color={color}
        d={edgePath}
        markerEnd={markerEnd}
      />
    </>
  );
}
