import styled, { css, keyframes } from 'styled-components'


const Animation = keyframes`
  0% {
    stroke-dashoffset: 24;
  }
  100% {
    stroke-dashoffset: 0;
  }
`
export const Path = styled.path.attrs((props) => ({
  selected: props.selected,
  color: props.color,
}))`
  && {
    stroke-width: 6px;
    animation: dash 1s linear infinite;
    -webkit-animation: dash 1s linear infinite;
    stroke-dasharray: 10, 0.5;
    animation-name: ${Animation};
    animation-duration: 1s;
    animation-iteration-count: infinite;
    cursor: pointer;
    ${({ selected, color }) =>
      selected
        ? css`
            stroke: ${color} !important;
          `
        : css`
            stroke: ${color}88;
          `}
    &:hover {
      stroke: ${({ color }) => color} !important;
    }
  }
`

export const PathSelected = styled.path.attrs((props) => ({
  selected: props.selected,
  color: props.color,
}))`
  && {
    stroke-width: 12px;
    stroke: ${({ color }) => color} !important;
    opacity: 0.2;
  }
`
