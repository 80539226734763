import {
  Box,
  Grid,
  Stack,
  Typography,
  Divider,
  Tooltip,
  IconButton,
  Chip,
  Checkbox,
  MenuItem,
  ListItemText,
  Select as MuiSelect
} from "@mui/material"
import NodeMenu from "../../../StyleNodes/Menu"
import { useState } from "react"
import { i18n } from "../../../../../../../translate/i18n";
import { toast } from "react-toastify"
import { renderIcons } from "../../../../Icons"
import { Input, Select, Collapse } from "antd"
import { CopyAll } from "@mui/icons-material"
import AceEditor from "react-ace"
import { daysOfWeeksArray } from "./utils";
import "./style.css"
import "ace-builds/src-noconflict/mode-sh"
import "ace-builds/src-noconflict/theme-terminal"
import timeZones from 'timezones-list'


export const METHODS = [{
  label: 'POST',
  value: 'post',
  color: '#5FD07E'
}, {
  label: 'GET',
  value: 'get',
  color: '#1E88E5'
}, {
  label: 'PUT',
  value: 'put',
  color: '#FF9800'
}, {
  label: 'PATCH',
  value: 'patch',
  color: '#50e3c2'
}, {
  label: 'DELETE',
  value: 'delete',
  color: '#EF5350'
}]

export const StartMenu = ({ node }) => {


  const { data } = node
  const [values, setValues] = useState(data)
  const handleRun = () => { }

  const handleChange = ({ target }) => {
    values[target.name] = target.value
    setValues({ ...values })
  }

  const handleChangeSelect = (data) => {
    values["timezone"] = data
    setValues({ ...values })
  }
  const method = METHODS.find(it => it.value === values.method)

  const handleChangeDays = ({ target }) => {
    values[target.name] = target.value === 'string' ? target.value.split(',') : target.value
    const ordered = values[target.name].map((it) => {
      const day = daysOfWeeksArray?.map((day) => {
        if (day.name === it) return {
          id: day.id,
          name: day.name
        }
      })
      return day
    })
    const joinArrayAndRemoveUndefineds = ordered.flat().filter((it) => it !== undefined)
    const organizeArrayById = joinArrayAndRemoveUndefineds.sort((a, b) => a.id - b.id)
    if (target.value?.length) {
      if (target.value.indexOf(i18n.t('all days')) === target.value?.length - 1 || target.value?.length === 7) {
        values[target.name] = daysOfWeeksArray?.map((day) => day.name)
      } else {
        values[target.name] = organizeArrayById.map((day) => day.name)
      }
    } else {
      values[target.name] = []
    }
    setValues({ ...values })
  };


  return (
    <NodeMenu
      open
      node={node}
      values={values}
      onChange={handleChange}
      onRun={handleRun}
    >
      <Stack direction="row" alignItems="center" spacing={1}>
        <Stack sx={{
          backgroundColor: `#0C3249`,
          padding: 1,
          borderRadius: "10px 10px 10px 10px"
        }}
        >
          {renderIcons(data?.payload?.icon)}
        </Stack>
        <Stack spacing={0}>
          <Typography variant="h6"> {i18n.t('Nó inicial')}</Typography>
          <Typography color="text.secondary" sx={{ mt: -0.7 }}>
            {i18n.t('Seu bot iniciará por esse nó')}
          </Typography>

        </Stack>
      </Stack>
      <Divider sx={{ my: 1 }} />

      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Stack>
            <Typography variant="caption" color="text.secondary">
              {i18n.t('URL')}
            </Typography>
            <Input
              name="path"
              value={`${process.env.REACT_APP_BACKEND_URL}/runtime/${values.path}`}
              onChange={handleChange}
              readOnly
              style={{
                width: "100%"
              }}
            />
          </Stack>
        </Grid>
        <Grid item xs={2}>
          <Stack sx={{ ml: -0.5 }}>
            <Typography variant="caption" color="text.secondary" sx={{ ml: 0.5 }}>
              {i18n.t('Método')}
            </Typography>
            <Chip label="POST" color="success" size="medium" sx={{ mt: 0.5, ml: -1 }} />
          </Stack>
        </Grid>
        <Grid item xs={5}>
          <Stack>
            <Typography variant="caption" color="text.secondary">
              {i18n.t('Path')}
            </Typography>
            <Input
              name="path"
              value={values.path}
              onChange={handleChange}
              readOnly
              placeholder={i18n.t('Digite o caminho')}
              style={{
                width: "100%"
              }}
              suffix={<Tooltip title="Copiar cURL">
                <IconButton
                  size="small"
                  onClick={() => {
                    navigator.clipboard.writeText(`curl -X post ${process.env.REACT_APP_BACKEND_URL}/runtime/${values.path} \\
                      --header 'access_token: ${values.access_token}'`)
                    toast.success(i18n.t('Copiado com sucesso'))
                  }}
                >
                  <CopyAll sx={{
                    fontSize: 14
                  }} />
                </IconButton>
              </Tooltip>}
            />
          </Stack>
        </Grid>
        <Grid item xs={5}>
          <Stack>
            <Typography variant="caption" color="text.secondary">
              {i18n.t('Access Token')}
            </Typography>
            <Input
              name="path"
              value={values.access_token}
              onChange={handleChange}
              readOnly
              style={{
                width: "100%"
              }}
            />
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack sx={{ width: '100%' }}>
            <Typography variant="caption" color="text.secondary">
              {i18n.t('cURL')}
            </Typography>

            <AceEditor
              mode="sh"
              theme="terminal"
              value={`curl -X post ${process.env.REACT_APP_BACKEND_URL}/runtime/${values.path} \\
--header 'access_token: ${values.access_token}'`}
              readOnly
              minLines={3}
              maxLines={15}
              name="UNIQUE_ID_OF_DIV"
              editorProps={{ $blockScrolling: true, }}
              style={{ width: "100%" }}
            />
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Divider sx={{ my: 1 }} />
        </Grid>

        <Grid item xs={12}>
          <Collapse
            items={[
              {
                key: "1",
                label: "Gatilho",
                children: (
                  <Stack sx={{ mt: -1.5 }}>
                    <Stack spacing={2}>
                      <Stack sx={{ width: '100%' }} spacing={2}>
                      <Stack>
          <Typography variant="caption" color="gray">
            Fuso horário
          </Typography>
          <Select
            showSearch
            placeholder={"Selecione uma zona"}
            id="queue"
            allowClear
            defaultValue={"America/Sao_Paulo"}
            onChange={handleChangeSelect}
            value={values?.timezone}
            options={timeZones.map((it) => ({ value: it.tzCode, label: it.name }))}
            size="large"
            style={{ width: '100%' }}
          />
        </Stack>
                        <Stack sx={{
                          width: "100%",
                        }}>
                          <Typography variant="caption" color="text.secondary">
                            {i18n.t('Dias da semana')}
                          </Typography>
                          <Select
                            required
                            placeholder={i18n.t('Dias da semana')}
                            mode="multiple"
                            name="daysOfWeek"
                            style={{
                              width: '100%',
                              height: "100%"

                            }}
                            value={values.daysOfWeek?.length === 7 ? [i18n.t("all days")] : values?.daysOfWeek}
                            onChange={e => handleChangeDays({
                              target: {
                                name: "daysOfWeek",
                                value: e
                              }

                            })}
                            label={i18n.t('Days of week')}

                          >
                            <MenuItem key={i18n.t("all days")} value={i18n.t("all days")}>
                              <Stack direction="row" alignItems="center" >
                                <Checkbox checked={values.daysOfWeek?.length === 7} />
                                <ListItemText primary={i18n.t("all days")} />
                              </Stack>
                            </MenuItem>
                            {daysOfWeeksArray?.map((day) => (
                              <MenuItem key={day.name} className={values.daysOfWeek?.length === 7 ? "Mui-selected" : ""} value={day.name} >
                                <Stack direction="row" alignItems="center" >
                                  <Checkbox checked={values.daysOfWeek?.indexOf(day.name) > -1} />
                                  <ListItemText primary={i18n.t(day.name)} />
                                </Stack>
                              </MenuItem>
                            ))}
                          </Select>
                        </Stack>


                      </Stack>
                      <Stack sx={{
                        width: "100%",
                      }}>
                        <Typography variant="caption" color="gray">
                          Horário:
                        </Typography>
                        <Input
                          type="time"
                          name="from"
                          disabled={values.daysOfWeek?.length === 0}
                          value={values.from}
                          style={{ width: 180 }}
                          onChange={(ev) => handleChange(ev)}
                        />
                      </Stack>
                    </Stack>
                  </Stack>
                )
              }
            ]}
          ></Collapse>

        </Grid>

      </Grid>

    </NodeMenu>
  )
}

export default StartMenu
