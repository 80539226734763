import React, { useState, useEffect, useContext, useRef } from "react";
import Picker from '@emoji-mart/react';
import data from '@emoji-mart/data';
import MicRecorder from "mic-recorder-to-mp3";
import {
  Input,
  Button,
  Upload,
  Switch,
  Image,
  Tooltip,
  Row,
  Col,
  Dropdown,
  Menu,
} from "antd";
import {
  PaperClipOutlined,
  SmileOutlined,
  SendOutlined,
  ClearOutlined,
  AudioOutlined,
  CheckCircleOutlined,
  StopOutlined,
  DeleteOutlined,
  FileOutlined,
  ThunderboltOutlined,
} from "@ant-design/icons";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import RecordingTimer from "./RecordingTimer";
import { ReplyMessageContext } from "../../context/ReplyingMessage/ReplyingMessageContext";
import { AuthContext } from "../../context/Auth/AuthContext";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import toastError from "../../errors/toastError";
import useQuickMessages from "../../hooks/useQuickMessages";

const Mp3Recorder = new MicRecorder({ bitRate: 128 });

const EmojiOptions = ({ disabled, showEmoji, setShowEmoji, handleAddEmoji }) => {
  const pickerRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (pickerRef.current && !pickerRef.current.contains(event.target)) {
        setShowEmoji(false);
      }
    };

    if (showEmoji) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showEmoji, setShowEmoji]);

  return (
    <>
      <Tooltip title="Emojis">
        <Button
          icon={<SmileOutlined />}
          component="span"
          disabled={disabled}
          onClick={() => setShowEmoji((prev) => !prev)}
          style={{ marginRight: 7 }}
        />
      </Tooltip>
      {showEmoji && (
        <div
          ref={pickerRef}
          style={{
            position: "absolute",
            bottom: 60,
            zIndex: 1000,
            boxShadow: "0 2px 10px rgba(0, 0, 0, 0.15)",
          }}
        >
          <Picker
            data={data}
            onEmojiSelect={handleAddEmoji}
            set="native"
            theme="light"
            icons="auto"
            emojiVersion="15.0"
            locale="pt"
            autoFocus={false}
            navPosition="top"
            previewPosition="none"
            searchPosition="none"
            skinTonePosition="none"
          />
        </div>
      )}
    </>
  );
};

const QuickMessageButton = ({ quickMessages, inputMessage, setInputMessage, disabled }) => {
  const filteredMessages = quickMessages.filter((m) =>
    m.label.toLowerCase().includes(inputMessage.toLowerCase())
  );

  const menu = (
    <Menu>
      {filteredMessages.map((message) => (
        <Menu.Item key={message.value} onClick={() => setInputMessage(message.value)}>
          {message.label}
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Tooltip title="Respostas Rápidas">
      <Dropdown overlay={menu} trigger={["click"]} disabled={disabled}>
        <Button icon={<ThunderboltOutlined />} style={{ marginRight: 7 }} disabled={disabled} />
      </Dropdown>
    </Tooltip>
  );
};

const SignSwitch = ({ setSignMessage, signMessage }) => (
  <Tooltip title="Assinar mensagem">
    <Switch
      checked={signMessage}
      onChange={(checked) => setSignMessage(checked)}
      size="small"
      style={{ marginBottom: 4 }}
    />
  </Tooltip>
);

const InternalMessage = ({ setInternalMessage, internalMessage }) => (
  <Tooltip title="Mensagem interna">
    <Switch
      checked={internalMessage}
      onChange={(checked) => setInternalMessage(checked)}
      size="small"
    />
  </Tooltip>
);

const FileInput = ({ handleChangeMedias, fileList, setFileList, disableOption }) => (
  <Tooltip title="Anexar arquivos">
    <Upload
      multiple
      showUploadList={false}
      beforeUpload={() => false}
      fileList={fileList}
      onChange={handleChangeMedias}
      onRemove={() => setFileList([])}
      disabled={disableOption()}
    >
      <Button icon={<PaperClipOutlined />} style={{ marginRight: 7 }} disabled={disableOption()} />
    </Upload>
  </Tooltip>
);

const ActionButtons = ({
  inputMessage,
  medias,
  loading,
  recording,
  ticketStatus,
  handleSendMessage,
  handleCancelAudio,
  handleUploadAudio,
  handleStartRecording,
}) => {
  if (medias.length > 0) {
    return (
      <Tooltip title="Enviar imagens">
        <Button icon={<SendOutlined />} onClick={handleSendMessage} disabled={loading} style={{ marginLeft: "auto" }} />
      </Tooltip>
    );
  }
  if (inputMessage) {
    return (
      <Tooltip title="Enviar mensagem">
        <Button icon={<SendOutlined />} onClick={handleSendMessage} disabled={loading} style={{ marginLeft: "auto" }} />
      </Tooltip>
    );
  } else if (recording) {
    return (
      <>
        <Tooltip title="Parar gravação">
          <Button icon={<StopOutlined />} onClick={handleCancelAudio} disabled={loading} />
        </Tooltip>
        {loading ? null : <RecordingTimer />}
        <Tooltip title="Enviar áudio">
          <Button icon={<CheckCircleOutlined />} onClick={handleUploadAudio} disabled={loading} />
        </Tooltip>
      </>
    );
  }
  return (
    <Tooltip title="Gravar áudio">
      <Button
        icon={<AudioOutlined />}
        onClick={handleStartRecording}
        disabled={loading || ticketStatus !== "open"}
        style={{ marginLeft: "auto" }}
      />
    </Tooltip>
  );
};

const MediaPreview = ({ medias, handleRemoveMedia }) => (
  <Row style={{ marginBottom: 5, display: 'flex', flexWrap: 'wrap' }}>
    {medias.map((media, index) => (
      <Col key={index} style={{ margin: '10px' }}>
        <div style={{ position: "relative", display: "inline-block" }}>
          {media.type.startsWith("image/") ? (
            <Image
              width={50}
              height={50}
              src={URL.createObjectURL(media)}
              alt={media.name}
              style={{ borderRadius: 5 }}
            />
          ) : (
            <FileOutlined style={{ fontSize: 50 }} />
          )}
          <Button
            icon={<DeleteOutlined />}
            onClick={() => handleRemoveMedia(index)}
            size="small"
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              padding: "0 5px",
              backgroundColor: "rgba(255, 255, 255, 0.8)",
            }}
          />
        </div>
      </Col>
    ))}
  </Row>
);

const CustomInput = ({
  loading,
  inputRef,
  ticketStatus,
  inputMessage,
  setInputMessage,
  handleSendMessage,
  handleInputPaste,
  disableOption,
  internalMessage,
}) => {
  const onKeyPress = (e) => {
    if (loading || e.shiftKey) return;
    else if (e.key === "Enter") {
      handleSendMessage();
    }
  };

  const onPaste = (e) => {
    if (ticketStatus === "open") {
      handleInputPaste(e);
    }
  };

  return (
    <Input.TextArea
      ref={inputRef}
      value={inputMessage}
      onChange={(e) => setInputMessage(e.target.value)}
      onPaste={onPaste}
      onKeyPress={onKeyPress}
      placeholder={
        internalMessage
          ? i18n.t("Digite uma mensagem que será vista apenas pelos usuários internos.")
          : ticketStatus === "open"
            ? i18n.t("messagesInput.placeholderOpen")
            : i18n.t("messagesInput.placeholderClosed")
      }
      disabled={disableOption()}
      autoSize={{ minRows: 1 }}
      style={{
        flex: 1,
        borderRadius: 5,
        paddingLeft: 10,
        paddingRight: 10,
        marginRight: 7,
        backgroundColor: internalMessage ? "#FFFCCC" : undefined,
      }}
    />
  );
};

const MessageInputCustom = (props) => {
  const { ticketStatus, ticketId } = props;
  const [medias, setMedias] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [inputMessage, setInputMessage] = useState("");
  const [showEmoji, setShowEmoji] = useState(false);
  const [loading, setLoading] = useState(false);
  const [recording, setRecording] = useState(false);
  const inputRef = useRef();
  const { setReplyingMessage, replyingMessage } =
    useContext(ReplyMessageContext);
  const { user } = useContext(AuthContext);
  const [internalMessage, setInternalMessage] = useState(false);
  const [signMessage, setSignMessage] = useLocalStorage("signOption", true);
  const { list: listQuickMessages } = useQuickMessages();
  const [quickMessages, setQuickMessages] = useState([]);

  useEffect(() => {
    inputRef.current?.focus();
  }, [ticketId, replyingMessage, medias]);

  useEffect(() => {
    inputRef.current.focus();
    return () => {
      setInputMessage("");
      setShowEmoji(false);
      setMedias([]);
      setReplyingMessage(null);
    };
  }, [ticketId, setReplyingMessage]);

  useEffect(() => {
    inputRef.current.focus();
  }, [medias, inputMessage]);

  useEffect(() => {
    const fetchQuickMessages = async () => {
      const companyId = localStorage.getItem("companyId");
      const messages = await listQuickMessages({ companyId, userId: user.id });
      const options = messages.map((m) => ({
        value: m.message,
        label: `/${m.shortcode} - ${m.message.substring(0, 35)}...`,
      }));
      setQuickMessages(options);
    };
    fetchQuickMessages();
  }, [user.id]);

  const handleAddEmoji = (emoji) => {
    setInputMessage((prevState) => prevState + emoji.native);
    setShowEmoji(false);
  };

  const handleChangeMedias = (info) => {
    const selectedMedias = info.fileList.map((file) => file.originFileObj || file);
    setFileList(info.fileList);
    setMedias(selectedMedias);
  };

  const handleInputPaste = (e) => {
    if (e.clipboardData.files[0]) {
      setMedias((prev) => [...prev, e.clipboardData.files[0]]);
    }
  };

  const handleUploadMedia = async () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("fromMe", true);
    medias.forEach((media) => {
      formData.append("medias", media);
      formData.append("body", media?.name);
    });

    try {
      await api.post(`/messages/${ticketId}`, formData);
      setMedias([]);
      setFileList([]);
    } catch (err) {
      toastError(err);
    }

    setLoading(false);
  };

  const handleSendMessage = async () => {
    if (!inputMessage.trim() && medias.length === 0) return;
    setLoading(true);

    if (inputMessage.trim()) {
      const message = {
        read: 1,
        fromMe: true,
        mediaUrl: "",
        body: signMessage
          ? `*${user?.name}:*\n${inputMessage.trim()}`
          : inputMessage.trim(),
        quotedMsg: replyingMessage,
        internalMessage: internalMessage,
      };

      try {
        await api.post(`/messages/${ticketId}`, message);
        setInputMessage("");
        setReplyingMessage(null);
      } catch (err) {
        toastError(err);
      }
    }

    if (medias.length > 0) {
      await handleUploadMedia();
    }

    setLoading(false);
    inputRef.current?.focus();
  };

  const handleStartRecording = async () => {
    setLoading(true);
    try {
      await navigator.mediaDevices.getUserMedia({ audio: true });
      await Mp3Recorder.start();
      setRecording(true);
      setLoading(false);
    } catch (err) {
      toastError(err);
      setLoading(false);
    }
  };

  const handleUploadAudio = async () => {
    setLoading(true);
    try {
      const [, blob] = await Mp3Recorder.stop().getMp3();
      if (blob.size < 10000) {
        setLoading(false);
        setRecording(false);
        return;
      }

      const formData = new FormData();
      const filename = `audio-record-site-${new Date().getTime()}.mp3`;
      formData.append("medias", blob, filename);
      formData.append("body", filename);
      formData.append("fromMe", true);

      await api.post(`/messages/${ticketId}`, formData);
    } catch (err) {
      toastError(err);
    }

    setRecording(false);
    setLoading(false);
  };

  const handleCancelAudio = async () => {
    try {
      await Mp3Recorder.stop().getMp3();
      setRecording(false);
    } catch (err) {
      toastError(err);
    }
  };

  const handleRemoveMedia = (index) => {
    setMedias((prevMedias) => prevMedias.filter((_, i) => i !== index));
    setFileList((prevFileList) => prevFileList.filter((_, i) => i !== index));
  };

  const disableOption = () => loading || recording || ticketStatus !== "open";

  return (
    <div style={{ background: "#eee", padding: 10 }}>
      {replyingMessage && (
        <div style={{ display: "flex", justifyContent: "space-between", background: "rgba(198, 232, 248,0.5)", padding: "5px", borderRadius: "5px" }}>
          <div style={{ flex: 1 }}>
            <span>{replyingMessage.body}</span>
          </div>
          <Button
            icon={<ClearOutlined />}
            onClick={() => setReplyingMessage(null)}
          />
        </div>
      )}

      {medias.length > 0 && (
        <MediaPreview
          medias={medias}
          handleRemoveMedia={handleRemoveMedia}
        />
      )}

      <div style={{ display: "flex", alignItems: "center", gap: "7px" }}>
        <EmojiOptions
          disabled={disableOption()}
          handleAddEmoji={handleAddEmoji}
          showEmoji={showEmoji}
          setShowEmoji={setShowEmoji}
        />
        <FileInput
          fileList={fileList}
          setFileList={setFileList}
          handleChangeMedias={handleChangeMedias}
          disableOption={disableOption}
        />
        <QuickMessageButton
          quickMessages={quickMessages}
          inputMessage={inputMessage}
          setInputMessage={setInputMessage}
          disabled={disableOption()}
        />
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <SignSwitch signMessage={signMessage} setSignMessage={setSignMessage} />
          <InternalMessage style={{ backgroundColor: "#FFFCCC" }} internalMessage={internalMessage} setInternalMessage={setInternalMessage} />
        </div>
        <CustomInput
          loading={loading}
          inputRef={inputRef}
          ticketStatus={ticketStatus}
          inputMessage={inputMessage}
          setInputMessage={setInputMessage}
          handleSendMessage={handleSendMessage}
          handleInputPaste={handleInputPaste}
          disableOption={disableOption}
          internalMessage={internalMessage}
        />
        <ActionButtons
          inputMessage={inputMessage}
          medias={medias}
          loading={loading}
          recording={recording}
          ticketStatus={ticketStatus}
          handleSendMessage={handleSendMessage}
          handleCancelAudio={handleCancelAudio}
          handleUploadAudio={handleUploadAudio}
          handleStartRecording={handleStartRecording}
        />
      </div>
    </div>
  );
};

export default MessageInputCustom;
