import { useState, useMemo } from "react";
import { Box, FormHelperText, Stack, Typography } from "@mui/material";
import Dropzone from "../Dropzone";
import { readFile } from "./helper";
import fileFormatIcons from "../Dropzone/Formats";

const FlowDropzone = ({ onSetJson,onSetFile,file, ...rest }) => {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const typeIcon = "bot";
  const IconFormat = useMemo(() => {
    return fileFormatIcons[typeIcon || "json"];
  }, [typeIcon]);

  const onDrop = ([droppedFile], e) => {
    setLoading(true);
    setError("");
    readFile(droppedFile)
      .then(json => {
        onSetFile(droppedFile);
        onSetJson(json);
      })
      .catch(err => {
        setError("Erro ao ler o arquivo");
      })
      .finally(() => setLoading(false));
  };

  return (
    <Stack spacing={2} className="fullHeight">
      <Box sx={{ flexGrow: 1 }}>
        <Dropzone
          onDropAccepted={onDrop}
          maxSize={rest.maxSize}
          accept={{
            "application/bot": [".bot"]
          }}
          error={error}
          multiple={false}
        >
          {({ getRootProps, getInputProps }) => (
            <Stack>
              
              <Box
                {...getRootProps()}
                sx={{
                  cursor: loading ? "not-allowed" : "pointer",
                  border: error ? "2px dashed" : "2px dashed",
                  borderColor: error ? "error.main" : "divider",
                  px: 2,
                  py: 4,
                  borderRadius: 4
                }}
              >
                <input {...getInputProps()} />
                <Stack alignItems="center" spacing={2}>
                  <IconFormat
                    style={{color: "gray"}}
                    loading={loading}
                    filename={file?.name}
                    format={rest.format}
                  />
                </Stack>
              </Box>
            </Stack>
          )}
        </Dropzone>
        {/* <Dropzone
          multiple={false}
          maxSize={rest.maxSize}
          onDropAccepted={onDrop}
          typeIcon="bot"
          accept={{
            "application/bot": [".bot"]
          }}
          error={error}
          loading={loading}
          filename={file?.name}
        /> */}
      </Box>
    </Stack>
  );
};

export default FlowDropzone;
