import React, { useEffect, useState } from 'react';
import { Tabs } from 'antd';
import { Box } from '@mui/material';
import RequestTrace from './Request';
import TraceLogs from './Trace';

const onChange = (key) => {
};



export default function TraceItem({
    item,
}) {
    const [ body, setBody ] = useState("")
    const [ trace, setTrace ] = useState([])

    useEffect(()=>{
        if(!item) return
        if(item?.request){
            setBody(JSON.stringify(JSON.parse(item?.request), null, 2) || "{}")
        }
        if(item?.trace){
            setTrace(JSON.parse(item?.trace))
        }

        //on dismmount set body to empty
        return () => {
            setBody("")
            setTrace([])
        }
    }, [item])


    const items = [
        {
            key: '1',
            label: 'Requisição',
            children: <RequestTrace item={body} />,
        },
        {
            key: '2',
            label: 'Trace',
            children: <TraceLogs item={trace} />,
        },
    ];
    return (
        <Box sx={{ px: 2, py: 1 }}>
            <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
        </Box>

    )
}

