import { useMemo } from 'react'
import { Box, Stack } from '@mui/material'
import { getPasswordStrength } from '../../../commons/utils/password'

export default function PasswordStrengthTest({ value }) {
  // min=0, max=4
  const strength = useMemo(() => value ? getPasswordStrength(value) : 0, [value])
  const fontSize = '10px'

  const colors = {
    1: 'error',
    2: 'warning',
    3: 'success',
    4: 'success'
  }
  const labels = {
    1: 'Fraca',
    2: 'Boa',
    3: 'Forte',
    4: 'Muito Forte',
  }

  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={1}
      sx={{ height: fontSize }}
    >
      <Box sx={{
        height: 6,
        width: 210,
        bgcolor: 'divider',
        position: 'relative'
      }}>
        <Box sx={{
          height: '100%',
          width: `${strength * 25}%`,
          bgcolor: strength ? `${colors[strength]}.main` : '',
          position: 'absolute',
          zIndex: 1,
          top: 0,
          left: 0
        }} />
      </Box>
      <Box component="span" sx={{ fontWeight: 500 }}>
        <Box sx={{
          color: strength ? `${colors[strength]}.dark` : '',
          lineHeight: fontSize,
          fontSize
        }}>{labels[strength] || ''}</Box>
      </Box>
    </Stack>
  )
}
