import React, { useEffect, useState, useContext } from "react";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { Select, Typography } from "antd";
import { UserOutlined } from '@ant-design/icons';
import { AuthContext } from "../../context/Auth/AuthContext";

const UserSelect = ({ selectedUserId, onChange }) => {
	const [users, setUsers] = useState([]);
	const { user } = useContext(AuthContext);
	const { companyId } = user;

	useEffect(() => {
		(async () => {
			try {
				const { data } = await api.get("/users/", {
					params: { companyId: companyId }
				  });
				  const list = data.users
				const usersData = list.map((item) => {
					return {
						value: item.id,
						label: (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <UserOutlined style={{ marginRight: '4px' }}/>
                <Typography.Text style={{ fontWeight: 500, marginRight: '4px' }}>-</Typography.Text>
                <Typography.Text style={{ fontWeight: 500 }}>{item.name}</Typography.Text>
              </div>
            ),
					}
				})
				setUsers(usersData);
			} catch (err) {
				toastError(err);
			}
		})();
	}, [companyId]);

	const filterOption = (input, option) => {
	console.log(option.label)
		return option.label.props.children.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
	}

	return (
		<Select
			showSearch
			placeholder={"Selecione um usuário"}
			id="user"
			allowClear
			filterOption={filterOption}
			optionFilterProp="children"
			onChange={onChange}
			value={selectedUserId}
			options={users}
			size="large"
			style={{ width: '100%' }}
      maxTagCount="responsive"
      tagRender={(props) => {
        const { label, closable, onClose } = props;
        return (
          <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '30px',
            padding: '4px 8px',
            backgroundColor: '#F0F0F0',
            borderRadius: '5px',
            margin: '1px'
          }}>
            {label}
            {closable && <span onClick={onClose} style={{ marginLeft: '8px', cursor: 'pointer' }}>×</span>}
          </div>
        );
      }}
		/>
	);
};

export default UserSelect;
