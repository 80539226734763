import React, { useRef, useState } from "react";
import {
  Divider,
  Grid,
  Popover,
  Stack,
  Switch,
  Slider,
  IconButton,
  Tooltip,
  Typography,
  Box
} from "@mui/material"
import { renderIcons } from "../../../../Icons";
import toastError from "../../../../../../../errors/toastError";
import { AudioPlayer } from 'react-audio-player-component';
import "./style.css"
import { i18n } from "../../../../../../../translate/i18n";
import NodeMenu from "../../../StyleNodes/Menu";
import { Input, Select, Upload, Modal, Empty, Button, Dropdown, Space } from "antd";
import {
  AlarmAdd,
  AttachFileOutlined,
  DeleteOutline,
  DragHandle,
  FontDownloadOutlined,
  FormatSize,
  MoveToInbox,
  Phone,
  Photo,
  TimeToLeave,
  UploadFileOutlined,
  Visibility
} from "@mui/icons-material";
import { putObject } from "../../../../../../../commons/utils/s3";
import ChannelSelect from "../../../../../../../components/ChannelSelect";
import { PictureInPicture, VoiceChat,ArrowDropDownOutlined } from "@material-ui/icons";

const { TextArea } = Input;
const handleStyle = { left: 10 };

const getBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

export const ActiveMessageMenu = ({ node, anchorRef }) => {
  const { data } = node;
  const [values, setValues] = useState(data);
  const [loadingImage, setLoadingImage] = useState(false);
  const [dragging, setDragging] = useState(false);
  const handleRun = () => { };

  const handleChange = ({ target }) => {
    values[target.name] = target.value;
    setValues({ ...values });
  };

  const handleChangeSwitch = value => {
    values["returnOptions"] = value;
    if (value === true) {
      if (values?.continueFlow) {
        values["continueFlow"] = false;
        return setValues({
          ...values
        });
      }
    } else {
      values["continueFlow"] = true;
      setValues({ ...values });
    }
  };


  const handleDragStart = (ev, item) => {
    setDragging({ draggingItem: item });
    ev.dataTransfer.setData('text/plain', '');
  }

  const handleDragEnd = () => {
    setDragging(false);
  }

  const handleDragOver = (ev) => {
    ev.preventDefault();
  }

  const handleDrop = (e, targetItem) => {
    //reorder the list 
    const draggedItem = dragging.draggingItem;
    const items = values.messagesArray.filter(item => item !== draggedItem);
    const targetIndex = items.indexOf(targetItem);
    items.splice(targetIndex, 0, draggedItem);
    values.messagesArray = items;
    setValues({ ...values });

  }

  const handleAddMessage = type => {
    values.messagesArray.push({ type });
    setValues({ ...values });
  };

  const handleDeleteMessage = index => {
    values.messagesArray.splice(index, 1);
    setValues({ ...values });
  };

  const handleUpload = index => {
    const input = document.getElementById(index + "input");
    input.click();
    input.onchange = e => {
      const file = e.target.files[0];
      const fileList = e.target.files;
      if (file) {
        const dataFile = putObject(file.name, file, file.type);
        dataFile
          .then(data => {
            values.messagesArray[index].fileUrl = data.file;
            setValues({ ...values });
          })
          .catch(err => {
            toastError(err);
          });
      }
    }
  };


  const handleChangeMessages = (e, index) => {
    values.messagesArray[index].message = e.target.value;
    setValues({ ...values });
  };
  const handleChangeInterval = (e, index) => {
    values.messagesArray[index].interval = e.target.value;
    setValues({ ...values });
  };

  const handleAddVariable = (type,index) => {
    values.messagesArray[index].message = values.messagesArray[index]?.message ? values.messagesArray[index]?.message + ` {{flow.${type}}}` : `{{flow.${type}}}`
    setValues({ ...values });
  }

  return (
    <NodeMenu
      open
      node={node}
      anchorRef={anchorRef}
      values={values}
      onChange={handleChange}
      onRun={handleRun}
      size={1000}
    >
      <Stack direction="row" alignItems="center" spacing={1}>
        <Stack
          sx={{
            backgroundColor: `#0C3249`,
            padding: 1,
            borderRadius: "10px 10px 10px 10px"
          }}
        >
          {renderIcons(data?.payload?.icon)}
        </Stack>
        <Stack spacing={0}>
          <Typography variant="h6"> {i18n.t("Enviar mensagem")}</Typography>
          <Typography color="text.secondary" sx={{ mt: -0.7 }}>
            {i18n.t("Enviar uma mensagem para o contato")}
          </Typography>
        </Stack>
      </Stack>
      <Divider sx={{ my: 1 }} />
      <Grid container spacing={2} >

        <Grid item xs={6}>
          <Grid container spacing={2} sx={{
            mb: 3
          }}>
            <Grid item xs={6}>
              <Stack>
                <Typography variant="caption" color="text.secondary">
                  Nome
                </Typography>
                <Input
                  name="userName"
                  value={values.userName}
                  onChange={handleChange}
                  placeholder="Joao da Silva"
                  autoFocus
                  size="large"
                />
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <Stack>
                <Typography variant="caption" color="text.secondary">
                  Telefone
                </Typography>
                <Input
                  name="userPhone"
                  placeholder="5511999999999"
                  value={values.userPhone}
                  onChange={handleChange}
                  size="large"
                />
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack>
                <Typography variant="caption" color="text.secondary">
                  Canal
                </Typography>
                <ChannelSelect
                  selectedChannel={values?.channel ? parseInt(values?.channel) : null}
                  onChange={value =>
                    handleChange({ target: { name: "channel", value } })
                  }
                  usedConnections={[]}
                />
              </Stack>
            </Grid>

            <Grid item xs={12}>
              <Stack>
                <Typography variant="caption" color="text.secondary">
                  {i18n.t("Tipo da mensagem")}
                </Typography>
                <Stack direction="row" spacing={2} sx={{ mt: 1 }}>

                  <Stack
                    onClick={() => handleAddMessage("Texto")}
                    sx={{
                      backgroundColor: `#387599`,
                      padding: 1,
                      width: "65px",
                      borderRadius: "10px 10px 10px 10px",
                      border: `2px solid #0C3249 `,
                      borderStyle: "dashed",
                      color: "white",
                      cursor: "pointer",
                      "&:hover": {
                        backgroundColor: "#2c6586",
                        color: "white"
                      }
                    }}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <FormatSize />
                    <Typography variant="caption" color="white">
                      {i18n.t("Texto")}
                    </Typography>
                  </Stack>

                  <Stack
                    onClick={() => handleAddMessage("Arquivo")}
                    sx={{
                      backgroundColor: `#387599`,
                      padding: 1,
                      width: "65px",
                      borderRadius: "10px 10px 10px 10px",
                      border: `2px solid #0C3249 `,
                      borderStyle: "dashed",
                      color: "white",
                      cursor: "pointer",
                      "&:hover": {
                        backgroundColor: "#2c6586",
                        color: "white"
                      }
                    }}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <AttachFileOutlined />
                    <Typography variant="caption" color="white">
                      {i18n.t("Arquivo")}
                    </Typography>
                  </Stack>

                  <Stack
                    onClick={() => handleAddMessage("Audio")}
                    sx={{
                      backgroundColor: `#387599`,
                      padding: 1,
                      width: "65px",
                      borderRadius: "10px 10px 10px 10px",
                      border: `2px solid #0C3249 `,
                      borderStyle: "dashed",
                      color: "white",
                      cursor: "pointer",
                      "&:hover": {
                        backgroundColor: "#2c6586",
                        color: "white"
                      }
                    }}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Phone />
                    <Typography variant="caption" color="white">
                      {i18n.t("Áudio")}
                    </Typography>
                  </Stack>

                  <Stack
                    onClick={() => handleAddMessage("Video")}
                    sx={{
                      backgroundColor: `#387599`,
                      padding: 1,
                      width: "65px",
                      borderRadius: "10px 10px 10px 10px",
                      border: `2px solid #0C3249 `,
                      borderStyle: "dashed",
                      color: "white",
                      cursor: "pointer",
                      "&:hover": {
                        backgroundColor: "#2c6586",
                        color: "white"
                      }
                    }}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <VoiceChat />
                    <Typography variant="caption" color="white">
                      {i18n.t("Vídeo")}
                    </Typography>
                  </Stack>

                  <Stack
                    onClick={() => handleAddMessage("Imagem")}
                    sx={{
                      backgroundColor: `#387599`,
                      padding: 1,
                      width: "65px",
                      borderRadius: "10px 10px 10px 10px",
                      border: `2px solid #0C3249 `,
                      borderStyle: "dashed",
                      color: "white",
                      cursor: "pointer",
                      "&:hover": {
                        backgroundColor: "#2c6586",
                        color: "white"
                      }
                    }}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Photo />
                    <Typography variant="caption" color="white">
                      {i18n.t("Imagem")}
                    </Typography>
                  </Stack>

                  <Stack
                    onClick={() => handleAddMessage("Intervalo")}
                    sx={{
                      backgroundColor: `#387599`,
                      padding: 1,
                      width: "65px",
                      borderRadius: "10px 10px 10px 10px",
                      border: `2px solid #0C3249 `,
                      borderStyle: "dashed",
                      color: "white",
                      cursor: "pointer",
                      "&:hover": {
                        backgroundColor: "#2c6586",
                        color: "white"
                      }
                    }}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <AlarmAdd />
                    <Typography variant="caption" color="white">
                      {i18n.t("Intervalo")}
                    </Typography>
                  </Stack>
                </Stack>
                {/* <Select
              placeholder={i18n.t('Selecione o tipo da mensagem')}
              id="messageType"
              onChange={handleChangeSelect}
              value={values.messageType}
              options={["Imagem", "Documento", "Texto", "Audio","Video"].map((option) => {
                return { value: option, label: option }
              })}
              size="large"
            /> */}
              </Stack>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid
            container
            spacing={2}
            className="sortable-list"
            sx={{
              maxHeight: "500px",
              overflowY: "auto",
              pr: 2,
              mt: 2.5
            }}
          >

            {values?.messagesArray.length
              ? values?.messagesArray?.map((message, index) => (
                <>

                  {message.type === "Texto" ? (

                    <Grid
                      item
                      xs={12}
                      draggable
                      onDragStart={(e) => handleDragStart(e, message)}
                      onDragEnd={handleDragEnd}
                      onDragOver={handleDragOver}
                      onDrop={(e) => handleDrop(e, message)}
                      className={dragging.draggingItem === message ? "dragging" : ""}
                      sx={{
                        backgroundColor: `#f1f1f199`,
                        borderRadius: "10px 10px 10px 10px",
                        padding: 2,
                        ml: 2,
                      }}
                    >
                      <Stack>
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Typography
                            variant="caption"
                            color="text.secondary"
                          >
                            {i18n.t("Mensagem")}
                          </Typography>
                          <Stack direction={"row"} spacing={1} alignItems="center">
                            <DragHandle style={{
                              cursor: "grab"
                            }} />
                            <IconButton
                              size="small"
                              onClick={() => handleDeleteMessage(index)}
                            >
                              <DeleteOutline />
                            </IconButton>

                          </Stack>
                        </Stack>
                        <TextArea
                          placeholder="Digite sua mensagem"
                          name="message"
                          style={{ height: 200 }}
                          value={message.message}
                          onChange={e => handleChangeMessages(e, index)}
                          size="large"
                        />
                         <Stack direction="row" spacing={1} alignItems="center" sx={{ ml: 1 }}>
                            <Dropdown
                              menu={{
                                items :  [
                                  {
                                    label: <Typography variant="caption">Nome</Typography>,
                                    key: `${index}0`,
                                    onClick: (e) => {
                                      handleAddVariable("nome",index)
                                    }
                                  },
                                  {
                                    label: <Typography variant="caption" >Primeiro nome</Typography>,
                                    key: `${index}1`,
                                    onClick: (e) => {
                                      handleAddVariable("primeiroNome",index)
                                    }
                                  },
                                  {
                                    label: <Typography variant="caption">Sobrenome</Typography>,
                                    key: `${index}2`,
                                    onClick: (e) => {
                                      handleAddVariable("sobrenome",index)
                                    }
                                  },
                                  {
                                    label: <Typography variant="caption" >CPF</Typography>,
                                    key: `${index}3`,
                                    onClick: (e) => {
                                      handleAddVariable("cpf",index)
                                    }
                                  },
                                  {
                                    label: <Typography variant="caption" >CNPJ</Typography>,
                                    key: `${index}4`,
                                    onClick: (e) => {
                                      handleAddVariable("cnpj",index)
                                    }
                                  },
                                  {
                                    label: <Typography variant="caption" >Endereço</Typography>,
                                    key: `${index}5`,
                                    onClick: (e) => {
                                      handleAddVariable("endereco",index)
                                    }
                                  },
                                  {
                                    label: <Typography variant="caption">Telefone</Typography>,
                                    key: `${index}6`,
                                    onClick: (e) => {
                                      handleAddVariable("telefone",index)
                                    }
                                  },
                                  {
                                    label: <Typography variant="caption" >Email</Typography>,
                                    key: `${index}7`,
                                    onClick: (e) => {
                                      handleAddVariable("email",index)
                                    }
                                  },
                                  {
                                    label: <Typography variant="caption">Data de nascimento</Typography>,
                                    key: `${index}8`,
                                    onClick: (e) => {
                                      handleAddVariable("dataNascimento",index)
                                    }
                                  },
                                  {
                                    label: <Typography variant="caption">Data</Typography>,
                                    key: `${index}9`,
                                    onClick: (e) => {
                                      handleAddVariable("dataCompleta",index)
                                    }
                                  },
                                  {
                                    label: <Typography variant="caption">Data e hora</Typography>,
                                    key: `${index}10`,
                                    onClick: (e) => {
                                      handleAddVariable("dataHora",index)
                                    }
                                  },
                                  {
                                    label: <Typography variant="caption">Sudação</Typography>,
                                    key: `${index}11`,
                                    onClick: (e) => {
                                      handleAddVariable("saudacao",index)
                                    }
                                  },
                                ],
                              }}
                              trigger={['click']}
                            >
                              <a onClick={(e) => e.preventDefault()}>
                                <Space>
                                  Adicionar variável
                                  <ArrowDropDownOutlined style={{marginTop: 6}} />
                                </Space>
                              </a>
                            </Dropdown>
                          </Stack>
                      </Stack>
                    </Grid>

                  ) : message?.type === "Intervalo" ? (
                    <>
                      <Grid
                        item
                        xs={12}
                        draggable
                        onDragStart={(e) => handleDragStart(e, message)}
                        onDragEnd={handleDragEnd}
                        onDragOver={handleDragOver}
                        onDrop={(e) => handleDrop(e, message)}
                        className={dragging.draggingItem === message ? "dragging" : ""}
                        sx={{
                          backgroundColor: `#f1f1f199`,
                          borderRadius: "10px 10px 10px 10px",
                          padding: 2,
                          ml: 2
                        }}
                      >
                        <Stack>
                          <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Typography
                              variant="caption"
                              color="text.secondary"
                            >
                              {i18n.t(`${message.type} de tempo`)}
                            </Typography>
                            <Stack direction={"row"} spacing={1} alignItems="center">
                              <DragHandle style={{
                                cursor: "grab"
                              }} />
                              <IconButton
                                size="small"
                                onClick={() => handleDeleteMessage(index)}
                              >
                                <DeleteOutline />
                              </IconButton>

                            </Stack>
                          </Stack>
                          <Stack direction="row" alignItems="center" spacing={1} sx={{
                            width: "100%",
                            pl: 2,
                            mt: -2
                          }}>
                            <Slider
                              defaultValue={message.interval}
                              valueLabelDisplay="auto"
                              onChange={(ev) => handleChangeInterval({
                                target: {
                                  name: "interval",
                                  value: ev.target.value,
                                },
                              }, index)}
                              style={{
                                marginTop: 15,
                              }}
                              min={1}
                              max={100}
                            />
                            <Stack>
                              <Input
                                name="interval"
                                size="large"
                                readOnly
                                addonAfter={
                                  <Typography variant="caption" color="white" fontWeight="bold">
                                    segundos
                                  </Typography>
                                }
                                type="number"
                                required
                                value={message.interval}
                                onChange={(ev) => handleChangeInterval(ev, index)}
                                style={{ width: "100%", marginTop: 15 }}
                                placeholder="Valor"
                              />
                            </Stack>
                          </Stack>
                        </Stack>
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid
                        item
                        xs={12}
                        draggable
                        onDragStart={(e) => handleDragStart(e, message)}
                        onDragEnd={handleDragEnd}
                        onDragOver={handleDragOver}
                        onDrop={(e) => handleDrop(e, message)}
                        className={dragging.draggingItem === message ? "dragging" : ""}
                        sx={{
                          backgroundColor: `#f1f1f199`,
                          borderRadius: "10px 10px 10px 10px",
                          padding: 2,
                          ml: 2
                        }}
                      >
                        <Stack>
                          <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Typography
                              variant="caption"
                              color="text.secondary"
                            >
                              {i18n.t(`${message.type} URL`)}
                            </Typography>
                            <Stack direction={"row"} spacing={1} alignItems="center">
                              <DragHandle style={{
                                cursor: "grab"
                              }} />
                              <IconButton
                                size="small"
                                onClick={() => handleDeleteMessage(index)}
                              >
                                <DeleteOutline />
                              </IconButton>

                            </Stack>
                          </Stack>
                          <Input
                            placeholder={
                              message.type === "Imagem"
                                ? "https://example.com/image.png"
                                : message.type === "Documento"
                                  ? "https://example.com/document.pdf"
                                  : "https://example.com/audio.mp3"
                            }
                            name="fileUrl"
                            addonAfter={
                              <Tooltip title={i18n.t("Anexar arquivo")}>
                                <IconButton
                                  size="small"
                                  onClick={() => handleUpload(index)}
                                >
                                  <AttachFileOutlined
                                    style={{ fontSize: 20, color: "white" }}
                                  />
                                </IconButton>
                              </Tooltip>
                            }
                            value={message.fileUrl}
                            onChange={e => handleChangeMessages(e, index)}
                            size="large"
                          />
                          <input
                            accept={message?.type === "Imagem" ? "image/*" : message?.type === "Audio" ? "audio/*" : message?.type === "Video" ? "video/*" : "application/*"}
                            type="file"
                            id={index + "input"} style={{
                              display: "none"
                            }} />
                          {message?.type === "Imagem" && message?.fileUrl && (
                            <Stack alignItems="center" justifyContent="center" sx={{
                              backgroundColor: `white`,
                              borderRadius: "10px 10px 10px 10px",
                              mt: 2
                            }}>
                              <img
                                src={message.fileUrl}
                                style={{
                                  maxWidth: "200px",
                                  height: "auto",
                                  marginTop: 10,
                                  borderRadius: 10
                                }}
                              />
                            </Stack>
                          )}
                          {message?.type === "Audio" && message?.fileUrl && (
                            <Stack alignItems="center" justifyContent="center" sx={{
                              backgroundColor: `white`,
                              borderRadius: "10px 10px 10px 10px",
                              mt: 2,
                              py: 2
                            }}>
                              <AudioPlayer
                                src={message.fileUrl}
                                minimal={true}
                                width={350}
                                trackHeight={40}
                                barWidth={1}
                                gap={1}
                                seekBarColor="#e0e0e0"
                                volumeControlColor="#000"
                                visualise={true}
                                backgroundColor="#e0e0e0"
                                barColor="#000"
                                barPlayedColor="#000"
                                skipDuration={2}
                                showLoopOption={true}
                                showVolumeControl={true}
                              />
                            </Stack>
                          )}

                          {message?.type === "Video" && message?.fileUrl && (
                            <Stack alignItems="center" justifyContent="center" sx={{
                              backgroundColor: `white`,
                              borderRadius: "10px 10px 10px 10px",
                              mt: 2,
                              py: 2
                            }}>
                              <video
                                src={message.fileUrl}
                                style={{
                                  maxWidth: "500px",
                                  height: "auto",
                                  marginTop: 10,
                                  borderRadius: 10
                                }}
                                controls
                              />
                            </Stack>
                          )}
                        </Stack>
                      </Grid>

                    </>
                  )}
   
                  <Grid item xs={12} sx={{
                    mt: 0.5
                  }}>
                    
                  </Grid>        
               
                </>
              ))
              : <Grid item xs={12} sx={{
                backgroundColor: `#f1f1f199`,
                borderRadius: "10px 10px 10px 10px",
                padding: 2,
                ml: 2,

              
              }}>
                    <Stack direction="row" justifyContent="center" alignItems="center" sx={{
                      height: "200px"
                    
                    }}>
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Selecione um tipo de mensagem"/>
                    
                    </Stack>
                </Grid>
}
          </Grid>
        </Grid>
      </Grid>
    </NodeMenu>
  );
};

export default ActiveMessageMenu;
