import {
  Typography,
  Stack,
  Divider,
  Button,
  Box,
  Grid,
  IconButton,
  Avatar
} from "@mui/material";

import FacebookIcon from "../../../../assets/img/channels/facebook.svg";
import WhatsAppIcon from "../../../../assets/img/channels/whatsapp.svg";
import TelegramIcon from "../../../../assets/img/channels/telegram.svg";
import InstagramIcon from "../../../../assets/img/channels/instagram.svg";
import Gmail from "../../../../assets/img/channels/gmail.png";
import Outlook from "../../../../assets/img/channels/owa.png";
import { Bolt, Check, WhatsApp } from "@mui/icons-material";
import toastError from "../../../../errors/toastError";

export default function BasicPlan({ onSelect, disabled }) {
  const handleSelect = () => {
    if (disabled)
      return toastError(
        "Não é permitido regredir o plano, entre em contato com o suporte para mais informações."
      );
    onSelect();
  };

  return (
    <Stack
      sx={{
        backgroundColor: disabled ? "#E0E0E0" : "#FFFFFF",
        borderRadius: "10px",
        padding: 3,
        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.05)",
        width: "280px",
        height: "480px",
        border: "1px solid #E0E0E0"
      }}
      spacing={1}
      justifyContent="space-between"
    >
      <Stack>
        <Stack direction="row" spacing={2} alignItems="center">
          <Stack
            sx={{
              borderRadius: "50%",
              backgroundColor: disabled ? "#b9b9b9" : "#0C3249",
              height: "50px",
              width: "50px"
            }}
            justifyContent="center"
            alignItems="center"
          >
            <Bolt
              sx={{
                color: "#FFFFFF",
                fontSize: "40px"
              }}
            />
          </Stack>
          <Typography variant="h5" color="text.secondary" fontWeight="bold">
            BASIC
          </Typography>
        </Stack>
        <Typography
          variant="h4"
          sx={{ mt: 2 }}
          color={disabled ? "text.disabled" : "text.primary"}
        >
          R$ 97.00
        </Typography>
        <Divider sx={{ my: 2 }} />
        <Typography
          variant="body1"
          color={disabled ? "text.disabled" : "text.primary"}
        >
          FEATURES
        </Typography>
        <Stack direction="row" spacing={1}>
          <Check sx={{ color: disabled ? "#b9b9b9" : "#0C3249" }} />
          <Typography
            variant="body1"
            color={disabled ? "text.disabled" : "text.primary"}
          >
            TakeFlow API
          </Typography>
        </Stack>
        <Stack direction="row" spacing={1}>
          <Check sx={{ color: disabled ? "#b9b9b9" : "#0C3249" }} />
          <Typography
            variant="body1"
            color={disabled ? "text.disabled" : "text.primary"}
          >
            TakeFlow Chat
          </Typography>
        </Stack>
        <Stack
          direction="row"
          spacing={1}
          color={disabled ? "text.disabled" : "text.primary"}
        >
          <Check sx={{ color: disabled ? "#b9b9b9" : "#0C3249" }} />
          <Typography variant="body1">Mensagens ilimitadas</Typography>
        </Stack>
        <Stack direction="row" spacing={1}>
          <Check sx={{ color: disabled ? "#b9b9b9" : "#0C3249" }} />
          <Typography
            variant="body1"
            color={disabled ? "text.disabled" : "text.primary"}
          >
            5 Usuários
          </Typography>
        </Stack>
        <Stack direction="row" spacing={1}>
          <Check sx={{ color: disabled ? "#b9b9b9" : "#0C3249" }} />
          <Typography
            variant="body1"
            color={disabled ? "text.disabled" : "text.primary"}
          >
            1 Conexão
          </Typography>
        </Stack>
        <Stack direction="row" spacing={1}>
          <Check sx={{ color: disabled ? "#b9b9b9" : "#0C3249" }} />
          <Typography
            variant="body1"
            color={disabled ? "text.disabled" : "text.primary"}
          >
            5 Campanhas
          </Typography>
        </Stack>

        <Divider sx={{ mt: 2, mb: 2 }} />
        <Stack direction="row" spacing={1}>
          <Avatar
            src={WhatsAppIcon}
            sx={{
              width: 40,
              height: 40,
              objectFit: "fill",
              background: "background.default",
              border: 0,
              p: 1
            }}
            imgProps={{
              style: {
                objectFit: "fill"
              }
            }}
          />
        </Stack>
      </Stack>
      <Stack
        onClick={handleSelect}
        sx={{
          mt: "20px",
          cursor: "pointer",
          backgroundColor: disabled ? "#b9b9b9" : "#0C3249",
          color: "#FFFFFF",
          height: "40px",
          width: "100%",
          borderRadius: "10px"
        }}
        justifyContent="center"
        alignItems="center"
      >
        <Typography variant="body1" color="white" fontWeight="bold">
          OBTER BASIC
        </Typography>
      </Stack>
    </Stack>
  );
}
