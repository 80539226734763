import React, { useState, useEffect, useContext } from "react";

import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Colorize } from "@material-ui/icons";
import { ColorBox } from "material-ui-color";

import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";
import { IconButton, InputAdornment } from "@material-ui/core";
import { Input, Modal, Button, Switch } from "antd";
import { Divider, Stack, Typography, Grid } from "@mui/material";
import { ColorPicker } from "antd";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap"
  },
  multFieldLine: {
    display: "flex",
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(1)
    }
  },

  btnWrapper: {
    position: "relative"
  },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  colorAdorment: {
    width: 20,
    height: 20
  }
}));

const PlanSchema = Yup.object().shape({
  name: Yup.string().min(3, "Mensagem muito curta").required("Obrigatório")
});

const CreatePlanModal = ({ open, onClose, planId, reload, onTicketUpdate }) => {
  const classes = useStyles();
  const { user } = useContext(AuthContext);
  const [colorPickerModalOpen, setColorPickerModalOpen] = useState(false);
  const [apiFeature, setApiFeature] = useState(false)
  const [values, setValues] = useState({
    name: "",
    api: false,
    users: 0,
    connections: 0,
    queues: 0,
    campaigns: 0,
    flows: 0,
    value: 0,
    color: "",
    visible: false
  });

  const [color, setColor] = useState("#0F69E7");
  const initialState = {
    name: "",
    api: false,
    users: 0,
    connections: 0,
    queues: 0,
    campaigns: 0,
    flows: 0,
    value: 0,
    color: "",
    visible: false

  };

  const [plan, setPlan] = useState(initialState);

  useEffect(() => {
    try {
      (async () => {
        if (!planId) return;

        const { data } = await api.get(`/plans/${planId}`);
        setValues(prevState => {
          return { ...prevState, ...data };
        });
        setColor(data.color);
      })();
    } catch (err) {
      toastError(err);
    }
  }, [planId, open]);

  const handleClose = () => {
    setPlan(initialState);
    setColorPickerModalOpen(false);
    onClose();
  };

  const handleChange = (e) => {
    values[e.target.name] = e.target.value;
    setValues({ ...values });
  };
    

  const handleSavePlan = async () => {
    const planData = { ...values, color};
    try {
      if (planId) {
        await api.put(`/plans/${planId}`, {
          ...planData,
          id: planId,
        });
      } else {
        const { data } = await api.post("/plans", planData);
        onTicketUpdate && onTicketUpdate(data);
      }
      toast.success(i18n.t("Plano salvo com sucesso!"));
      if (typeof reload == "function") {
        reload();
      }
    } catch (err) {
  
      toastError(err);
    }
    handleClose();
  };

  
  const handleChangeColor = (color) => { 
    let hexColor 
    try{
      hexColor = color.toHexString();
    }catch(error){

    }

    setColor(hexColor);
  }


  const renderTitle = () => {
    return (
      <Stack>
        <Typography variant="h6" sx={{ ml: 1 }}>
          {planId ? `Editar plano` : `Novo plano`}
        </Typography>
        <Typography variant="caption" sx={{ ml: 1 }}>
          {planId ? `Edite o plano` : `Adicione um novo plano`}
        </Typography>
      </Stack>
    );
  };

  return (
    <Modal
      title={renderTitle()}
      open={open}
      onCancel={handleClose}
      onOk={handleSavePlan}
      okText={planId ? "Salvar" : "Adicionar"}
    >
      <Divider sx={{ my: 1 }} />
      <Stack p={1}>

              <Grid container spacing={1} sx={{ p: 1 }}>
                <Grid item xs={9}>
                  <Stack>
                    <Typography variant="caption" color="gray">
                      Nome*
                    </Typography>
                    <Input
                      size="large"
                      id="name"
                      value={values.name}
                      onChange={handleChange}
                      name="name"
                      placeholder="Nome do plano"
                    />
                  </Stack>
                </Grid>
                
                <Grid item xs={1}>
                    <Stack >
                      <Typography variant="caption" color="gray">
                        Cor*
                      </Typography>
                      <ColorPicker size="large" onChange={handleChangeColor} value={color} />
                    </Stack>
                </Grid>
                <Grid item xs={2}>
                    <Stack  sx={{
                      marginLeft:1.5
                    }}>
                      <Typography variant="caption" color="gray">
                        Visivel *
                      </Typography>
                      <Stack sx={{
                        width: 20,
                        marginTop: 1.2
                      }}>
                      <Switch
                        checked={values.visible}
                        onChange={(checked) => {
                          values.visible = checked;
                          setValues({...values});
                        }} />
                        </Stack>
                    </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Stack>
                    <Typography variant="caption" color="gray">
                      Quantidade de usuários*
                    </Typography>
                    <Input
                      size="large"
                      id="name"
                      value={values.users}
                      type="number"
                      onChange={handleChange}
                      name="users"
                      placeholder="0"
                    />
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Stack>
                    <Typography variant="caption" color="gray">
                      Conexões*
                    </Typography>
                    <Input
                      size="large"
                      id="name"
                      value={values.connections}
                      type="number"
                      onChange={handleChange}
                      name="connections"
                      placeholder="0"
                    />
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Stack>
                    <Typography variant="caption" color="gray">
                      Departamentos*
                    </Typography>
                    <Input
                      size="large"
                      id="name"
                      value={values.queues}
                      type="number"
                      onChange={handleChange}
                      name="queues"
                      placeholder="0"
                    />
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Stack>
                    <Typography variant="caption" color="gray">
                      Campanhas*
                    </Typography>
                    <Input
                      size="large"
                      id="name"
                      value={values.campaigns}
                      type="number"
                      onChange={handleChange}
                      name="campaigns"
                      placeholder="0"
                    />
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Stack>
                    <Typography variant="caption" color="gray">
                      Fluxos*
                    </Typography>
                    <Input
                      size="large"
                      id="name"
                      value={values.flows}
                      type="number"
                      onChange={handleChange}
                      name="flows"
                      placeholder="0"
                    />
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Stack>
                    <Typography variant="caption" color="gray">
                      Valor do plano *
                    </Typography>
                    <Input
                      size="large"
                      id="name"
                      value={values.value}
                      type="number"
                      onChange={handleChange}
                      name="value"
                      placeholder="0"
                    />
                  </Stack>
                </Grid>
              </Grid>
      </Stack>
    </Modal>
  );
};

export default CreatePlanModal;
