import {
  Box,
  Divider,
  Grid,
  Stack,
  Typography,
  Tooltip,
  IconButton,
} from "@mui/material"
import NodeMenu from "../../../StyleNodes/Menu"

import AceEditor from "react-ace"
import "ace-builds/src-noconflict/theme-github"
import "ace-builds/src-noconflict/mode-json"
import React, { useState } from "react"
import { i18n } from "../../../../../../../translate/i18n";
import { renderIcons } from "../../../../Icons"
import UserSelect from "../../../../../../../components/UserSelect"
import QueueSelect from "../../../../../../../components/QueueSelectSingle"
import { InfoOutlined } from "@mui/icons-material"

export const TicketTransferMenu = ({ node, anchorRef }) => {
  const { data } = node
  const [values, setValues] = useState(data)

  const handleRun = () => { }

  const handleChange = ({ target }) => {
    values[target.name] = target.value
    setValues({ ...values })
  }
  return (
    <NodeMenu
      open
      node={node}
      anchorRef={anchorRef}
      values={values}
      onChange={handleChange}
      onRun={handleRun}
    >
      <Stack direction="row" alignItems="center" spacing={1}>
        <Stack sx={{
          backgroundColor: `#0C3249`,
          padding: 1,
          borderRadius: "10px 10px 10px 10px"
        }}
        >
          {renderIcons(data?.payload?.icon)}
        </Stack>
        <Stack spacing={0}>
          <Typography variant="h6"> {i18n.t('Transferir atendimento')}</Typography>
          <Typography color="text.secondary" sx={{ mt: -0.7 }}>
            {i18n.t('Transfira o atendimento para um usuário ou departamento')}
          </Typography>

        </Stack>
      </Stack>
      <Divider sx={{ my: 1 }} />
      <Grid container spacing={2}>
      <Grid item xs={12}>
          <Stack>
            <Typography variant="caption" color="text.secondary">
              {i18n.t('Usuário')}
            </Typography>
            <UserSelect
              selectedUserId={values.user}
              onChange={(value) => handleChange({ target: { name: "user", value } })}
            />
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack>
            <Typography variant="caption" color="text.secondary">
              {i18n.t('Departamento')}
            </Typography>
            <QueueSelect
              selectedQueueIds={values.queue}
              onChange={(value) => handleChange({ target: { name: "queue", value } })}
            />
          </Stack>
        </Grid>
       
      </Grid>

      <Box my={1}>
        <Stack direction="row" alignItems="center" spacing={1}>
        <Typography variant="caption" color="text.secondary">

          Mensagem de transferência
        </Typography>
        <Tooltip title="Essa mensagem será vista apenas no ticket, não será enviada para o contato.">
          <IconButton size="small">
            <InfoOutlined  sx={{
              fontSize: 14,
            }}/>
          </IconButton>
        </Tooltip>
        </Stack>
        <AceEditor
          mode="text"
          theme="github"
          value={values.message}
          onChange={(value) =>
            handleChange({
              target: { name: "message", value },
            })
          }
          maxLines={10}
          minLines={10}
          name="UNIQUE_ID_OF_DIV"
          editorProps={{ $blockScrolling: true }}
          setOptions={{
            useWorker: false,
          }}
          style={{ width: "100%" }}
        />
      </Box>
    </NodeMenu>
  )
}

export default TicketTransferMenu
