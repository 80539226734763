import React, { useState, useEffect, useRef } from "react";
import { Stack, Typography, Grid } from "@mui/material";
import {
  BarChart,
  CheckCircle,
  Close,
  Search,
  Timelapse,
} from "@mui/icons-material";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import ChannelSelect from "../../components/ChannelSelect";
import QueueSelect from "../../components/QueueSelect";
import UserSelect from "../../components/UserSelect";
import TagSelect from "../../components/TagSelect";
import Chart from "./Chart";
import useTickets from "../../hooks/useTickets";
import ClosedChart from "./ClosedChart";

const Tags = () => {
  const [selectedChannel, setSelectedChannel] = useState("");
  const [usedConnections, setUsedConnections] = useState([]);
  const [selectedQueueIds, setSelectedQueue] = useState([]);
  const [selectedTag, setSelectedTag] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const date = useRef(new Date().toISOString());
  const { tickets } = useTickets({ date: date.current });
  const [countContact, setCountContact] = useState(0);
  const { tickets: closedTickets } = useTickets({
    date: date.current,
    status: "closed",
  });

  useEffect(() => {
    const fetchContactLists = async () => {
      try {
        const { data } = await api.get("/contacts/");
        setCountContact(data.count);
      } catch (err) {
        toastError(err);
      }
    };
    fetchContactLists();
  }, []);

  return (
    <Stack
      sx={{
        p: 2,
        height: "100%",
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4} lg={2.4}>
          <Stack
            p={2}
            sx={{
              borderRadius: "10px",
              backgroundColor: "#fff",
              boxShadow: "0 0 10px rgba(0,0,0,0.1)",
              height: "100%",
            }}
          >
            <Stack direction="row" spacing={1} alignItems="center">
              <CheckCircle sx={{ color: "#00b894", fontSize: { xs: "24px", sm: "30px" } }} />
              <Typography variant="h6">Total de contatos</Typography>
            </Stack>
            <Typography sx={{ mt: 1 }} variant="h4">
              {countContact}
            </Typography>
          </Stack>
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={2.4}>
          <Stack
            p={2}
            sx={{
              borderRadius: "10px",
              backgroundColor: "#fff",
              boxShadow: "0 0 10px rgba(0,0,0,0.1)",
              height: "100%",
            }}
          >
            <Stack direction="row" spacing={1} alignItems="center">
              <CheckCircle sx={{ color: "#00b894", fontSize: { xs: "24px", sm: "30px" } }} />
              <Typography variant="h6">Atendidos Hoje</Typography>
            </Stack>
            <Typography sx={{ mt: 1 }} variant="h4">
              {tickets.length}
            </Typography>
          </Stack>
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={2.4}>
          <Stack
            p={2}
            sx={{
              borderRadius: "10px",
              backgroundColor: "#fff",
              boxShadow: "0 0 10px rgba(0,0,0,0.1)",
              height: "100%",
            }}
          >
            <Stack direction="row" spacing={1} alignItems="center">
              <Stack
                sx={{
                  width: 24,
                  height: 24,
                  borderRadius: "50%",
                  backgroundColor: "#b8001e",
                }}
                justifyContent="center"
                alignItems="center"
              >
                <Close sx={{ color: "#fff", fontSize: { xs: "18px", sm: "20px" } }} />
              </Stack>
              <Typography variant="h6">Finalizados Hoje</Typography>
            </Stack>
            <Typography sx={{ mt: 1 }} variant="h4">
              {closedTickets.length}
            </Typography>
          </Stack>
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={2.4}>
          <Stack
            p={2}
            sx={{
              borderRadius: "10px",
              backgroundColor: "#fff",
              boxShadow: "0 0 10px rgba(0,0,0,0.1)",
              height: "100%",
            }}
          >
            <Stack direction="row" spacing={1} alignItems="center">
              <Stack
                sx={{
                  width: 24,
                  height: 24,
                  borderRadius: "50%",
                  backgroundColor: "#008ab8",
                }}
                justifyContent="center"
                alignItems="center"
              >
                <Timelapse sx={{ color: "#fff", fontSize: { xs: "18px", sm: "20px" } }} />
              </Stack>
              <Typography variant="h6">TM de Atendimento</Typography>
            </Stack>
            <Typography sx={{ mt: 1 }} variant="h4">
              0s
            </Typography>
          </Stack>
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={2.4}>
          <Stack
            p={2}
            sx={{
              borderRadius: "10px",
              backgroundColor: "#fff",
              boxShadow: "0 0 10px rgba(0,0,0,0.1)",
              height: "100%",
            }}
          >
            <Stack direction="row" spacing={1} alignItems="center">
              <Stack
                sx={{
                  width: 24,
                  height: 24,
                  borderRadius: "50%",
                  backgroundColor: "#00b83e",
                }}
                justifyContent="center"
                alignItems="center"
              >
                <Timelapse sx={{ color: "#fff", fontSize: { xs: "18px", sm: "20px" } }} />
              </Stack>
              <Typography variant="h6">TM de Resolução</Typography>
            </Stack>
            <Typography sx={{ mt: 1 }} variant="h4">
              0s
            </Typography>
          </Stack>
        </Grid>

        <Grid item xs={12}>
          <Stack
            p={2}
            sx={{
              borderRadius: "10px",
              backgroundColor: "#fff",
              boxShadow: "0 0 10px rgba(0,0,0,0.1)",
            }}
          >
            <Stack direction="row" spacing={1} alignItems="center">
              <Stack
                sx={{
                  width: 24,
                  height: 24,
                  borderRadius: "50%",
                  backgroundColor: "#00b83e",
                }}
                justifyContent="center"
                alignItems="center"
              >
                <Search sx={{ color: "#fff", fontSize: { xs: "18px", sm: "20px" } }} />
              </Stack>
              <Typography variant="h6">Filtros</Typography>
            </Stack>
            <Grid container spacing={2} sx={{ mt: 0 }}>
              <Grid item xs={12} sm={6} md={3}>
                <Stack>
                  <Typography variant="caption" color="gray">
                    Filtrar por canal
                  </Typography>
                  <ChannelSelect
                    selectedChannel={selectedChannel}
                    onChange={setSelectedChannel}
                    usedConnections={usedConnections}
                  />
                </Stack>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Stack>
                  <Typography variant="caption" color="gray">
                    Filtrar por departamento
                  </Typography>
                  <QueueSelect
                    selectedQueueIds={selectedQueueIds}
                    onChange={setSelectedQueue}
                  />
                </Stack>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Stack>
                  <Typography variant="caption" color="gray">
                    Filtrar por usuário
                  </Typography>
                  <UserSelect
                    selectedUserId={selectedUser}
                    onChange={setSelectedUser}
                  />
                </Stack>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Stack>
                  <Typography variant="caption" color="gray">
                    Filtrar por tag
                  </Typography>
                  <TagSelect
                    selectedTagsIds={selectedTag}
                    onChange={setSelectedTag}
                  />
                </Stack>
              </Grid>
            </Grid>
          </Stack>
        </Grid>

        <Grid item xs={12}>
          <Stack
            p={2}
            sx={{
              borderRadius: "10px",
              backgroundColor: "#fff",
              boxShadow: "0 0 10px rgba(0,0,0,0.1)",
            }}
          >
            <Stack direction="row" spacing={1} alignItems="center">
              <Stack
                sx={{
                  width: 24,
                  height: 24,
                  borderRadius: "50%",
                  backgroundColor: "#00b83e",
                }}
                justifyContent="center"
                alignItems="center"
              >
                <BarChart sx={{ color: "#fff", fontSize: { xs: "18px", sm: "20px" } }} />
              </Stack>
              <Typography variant="h6">Atendimentos por horário</Typography>
            </Stack>
            <Stack sx={{ height: "340px" }}>
              <Chart />
            </Stack>
          </Stack>
        </Grid>

        <Grid item xs={12} sx={{ mb: 3 }}>
          <Stack
            p={2}
            sx={{
              borderRadius: "10px",
              backgroundColor: "#fff",
              boxShadow: "0 0 10px rgba(0,0,0,0.1)",
            }}
          >
            <Stack direction="row" spacing={1} alignItems="center">
              <Stack
                sx={{
                  width: 24,
                  height: 24,
                  borderRadius: "50%",
                  backgroundColor: "#b8001e",
                }}
                justifyContent="center"
                alignItems="center"
              >
                <BarChart sx={{ color: "#fff", fontSize: { xs: "18px", sm: "20px" } }} />
              </Stack>
              <Typography variant="h6">Atendimentos finalizados por horário</Typography>
            </Stack>
            <Stack sx={{ height: "340px" }}>
              <ClosedChart />
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default Tags;
