import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  
  Checkbox,
  Divider,
  Grid,
  IconButton,
  ListItemText,
  MenuItem,
  Popover,
  Stack,
  Switch,
  Tooltip,
  Typography,
  Select as MuiSelect
} from "@mui/material"
import NodeMenu from "../../../StyleNodes/Menu"
import { v4 as uuidV4 } from "uuid"
import React, { useRef, useState, useCallback } from "react"
import { i18n } from "../../../../../../../translate/i18n";
import { renderIcons } from "../../../../Icons"
import timeZones from 'timezones-list'
import { Input, Select , Button} from "antd"
import "./style.css"
import { daysOfWeeksArray } from "./utils";
import { DeleteOutline } from "@mui/icons-material";
const handleStyle = { left: 10 }

export const TimeIntervalMenu = ({ node, anchorRef }) => {
  const { data } = node
  const [values, setValues] = useState(data)


  const handleChangeDays = ({ target }, index) => {
    values.timeIntervals[index][target.name] = target.value === 'string' ? target.value.split(',') : target.value
    const ordered = values.timeIntervals[index][target.name].map((it) => {
      const day = daysOfWeeksArray.map((day) => {
        if (day.name === it) return {
          id: day.id,
          name: day.name
        }
      })
      return day
    })
    const joinArrayAndRemoveUndefineds = ordered.flat().filter((it) => it !== undefined)
    const organizeArrayById = joinArrayAndRemoveUndefineds.sort((a, b) => a.id - b.id)
    if (target.value.length) {
      if (target.value.indexOf(i18n.t('all days')) === target.value.length - 1 || target.value.length === 7) {
        values.timeIntervals[index][target.name] = daysOfWeeksArray.map((day) => day.name)
      } else {
        values.timeIntervals[index][target.name] = organizeArrayById.map((day) => day.name)
      }
    } else {
      values.timeIntervals[index][target.name] = []
    }
    setValues({ ...values })
  };



  const handleAddInterval = () => {
    const id = uuidV4()
    if (!values.timeIntervals) values.timeIntervals = []
    values.timeIntervals = values.timeIntervals.concat({
      id,
      from: null,
      until: null,
      daysOfWeek: [],
    })
    setValues({ ...values })
  }


  const handleDateChange = ({ target }, index) => {
    values.timeIntervals[index][target.name] = target.value
    setValues({ ...values })
  };

  const handleChange = ({ target }) => {
    values[target.name] = target.value
    setValues({ ...values })
  }

  const handleChangeAutoComplete = name => ({ target }) => {
    values[name] = (target.value).label
    setValues({ ...values })
  }

  const handleDeleteInterval = useCallback(
    (id) => {
      delete values.payload?.port[id]
      setValues({
        ...values,
        timeIntervals: values.timeIntervals.filter((inter) => inter.id != id),
      })
    },
    [values]
  )

  const handleChangeSelect = (data) => {
    values["timezone"] = data
    setValues({ ...values })
  }


  const handleRun = () => { }



  return (
    <NodeMenu
      open
      node={node}
      anchorRef={anchorRef}
      values={values}
      onChange={handleChange}
      onRun={handleRun}
    >
      <Stack direction="row" alignItems="center" spacing={1}>
        <Stack sx={{
          backgroundColor: `#0C3249`,
          padding: 1,
          borderRadius: "10px 10px 10px 10px"
        }}
        >
          {renderIcons(data?.payload?.icon)}
        </Stack>
        <Stack spacing={0}>
          <Typography variant="h6"> {i18n.t('Intervalo de tempo')}</Typography>
          <Typography color="text.secondary" sx={{ mt: -0.7 }}>
            {i18n.t('Adicione um intervalo de tempo ao seu fluxo')}
          </Typography>

        </Stack>
      </Stack>
      <Divider sx={{ my: 1 }} />
      <Box my={1}>
        <Stack>
          <Typography variant="caption" color="gray">
            Fuso horário
          </Typography>
          <Select
            showSearch
            placeholder={"Selecione uma zona"}
            id="queue"
            allowClear
            onChange={handleChangeSelect}
            value={values?.timezone}
            options={timeZones.map((it) => ({ value: it.tzCode, label: it.name }))}
            size="large"
            style={{ width: '100%' }}
          />
        </Stack>
      </Box>
      <div style={{ maxHeight: 400, overflow: "hidden", position: "relative", height: "100%" }}>
      <Stack
                spacing={1}
                sx={{
                  maxHeight: 400,
                  overflowY: "scroll",
                  overflowX: "hidden",
                  height: "100%",
                  paddingRight: 3
                }}
              >

      {values?.timeIntervals && values.timeIntervals.map((inter, index) => (
        <>
          <Divider sx={{ mb: 2, mt: 2 }} />
          <Grid container spacing={2} wrap="nowrap" alignItems="center">
            <Grid item >
              <Typography color="primary" variant="h5" sx={{
                mt: -4.5
              }}> #{index + 1}</Typography>
            </Grid>
            <Grid item xs >
              <Stack direction="column" spacing={1} alignItems="center">
                <Stack sx={{
                  width: "100%",
                }}>
                  <Typography variant="caption" color="gray">
                    Dias da semana
                  </Typography>
                  <Select
                    required
                    placeholder={i18n.t('Dias da semana')}
                    mode="multiple"
                    name="daysOfWeek"
                    style={{
                      width: '100%',
                      height: "100%"

                    }}
                    value={inter.daysOfWeek.length === 7 ? [i18n.t("all days")] : inter.daysOfWeek}
                    onChange={e => handleChangeDays({
                      target: {
                        name: "daysOfWeek",
                        value: e
                      }

                    }, index)}
                    label={i18n.t('Days of week')}

                  >
                    <MenuItem key={i18n.t("all days")} value={i18n.t("all days")}>
                      <Stack direction="row" alignItems="center" >
                        <Checkbox checked={inter.daysOfWeek.length === 7} />
                        <ListItemText primary={i18n.t("all days")} />
                      </Stack>
                    </MenuItem>
                    {daysOfWeeksArray.map((day) => (
                      <MenuItem key={day.name} className={inter.daysOfWeek.length === 7 ? "Mui-selected" : ""} value={day.name} >
                        <Stack direction="row" alignItems="center" >
                          <Checkbox checked={inter.daysOfWeek.indexOf(day.name) > -1} />
                          <ListItemText primary={i18n.t(day.name)} />
                        </Stack>
                      </MenuItem>
                    ))}
                  </Select>
                </Stack>
                <Box>
                  <Grid container spacing={2} wrap="nowrap" alignItems="flex-end">
                    <Grid item xs={10}>
                      <Stack sx={{
                        width: "100%",
                      }}>
                        <Typography variant="caption" color="gray">
                          De:
                        </Typography>
                        <Input
                          type="time"
                          name="from"
                          value={inter.from}
                          style={{ width: 180 }}
                          onChange={(ev) => handleDateChange(ev, index)}
                        />
                      </Stack>
                    </Grid>

                    <Grid item xs={10}>
                      <Stack sx={{
                        width: "100%",
                      }}>
                        <Typography variant="caption" color="gray">
                          Até:
                        </Typography>
                        <Input
                          type="time"
                          name="until"
                          value={inter.until}
                          style={{ width: 180 }}
                          onChange={(ev) => handleDateChange(ev, index)}
                        />
                      </Stack>
                    </Grid>
                  </Grid>
                </Box>
              </Stack>
            </Grid>
            <Grid item>
              <Tooltip title={i18n.t('Remover intervalo')}>
                <IconButton
                  disabled={values.timeIntervals.length === 1}
                  onClick={() => handleDeleteInterval(inter.id)}
                >
                  <DeleteOutline />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>


        </>
      ))
      }
      </Stack>
      </div>

       <Stack direction="row" justifyContent="center" mt={3}>
        <Button type="primary" onClick={handleAddInterval} >
          {values?.timeIntervals && values.timeIntervals.length > 0 ? i18n.t('Adicionar mais intervalos') : i18n.t('Adicionar um intervalo')}
        </Button>
      </Stack>
    </NodeMenu>
  )
}

export default TimeIntervalMenu
