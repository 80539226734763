import StartNode, { StartMenu, StartData } from "./start"
import SMTPNode, { SMTPMenu } from "./smtp"
import ConditionalNode, { ConditionalData, ConditionalMenu } from "./conditional"
import DBQueryNode, { DBQueryMenu, DBQueryData } from "./dbQuery"
import GraphqlNode, { GraphqlData, GraphqlMenu } from "./graphql"
import JsonToXlsxNode, { JsonToXlsxMenu } from "./jsonToXlsx"
import RequestNode, { RequestMenu, RequestData } from "./request"
import StartLoopNode, { StartLoopMenu } from "./startLoop"
import WaitTimeNode, { WaitTimeMenu } from "./waitTime"
import ScheduleNode , { ScheduleMenu } from "./schedule"
import LoopControlNode, { LoopControlMenu } from "./loopControl"
import JumpNode, { JumpMenu } from "./jump"
import JsonNode , { JsonMenu } from "./json"
import FlowVariableNode, { FlowVariableMenu } from "./flowVariable"
import EndLoopNode, { EndLoopMenu } from "./endLoop"
import RunCodeNode,{RunCodeMenu} from "./code"
import MessageNode,{MessageMenu} from "./message"
import TicketTransferNode,{TicketTransferMenu} from "./ticketTransfer"
import CloseTicketNode from "./closeTicket"
import ActiveMessageNode, { ActiveMessageMenu } from "./activeMessage"
import TagTicketNode, { TagTicketMenu } from "./tagTicket"
import EndFlowNode from "./endFlow"
import InactiveNode,{InactiveMenu} from "./inactive"
import SplitNode,{SplitMenu} from "./split"
import SaveMessageNode, { SaveMessageMenu } from "./saveMessage"
import TimeIntervalNode, { TimeIntervalMenu } from "./timeInterval"
import AnnotationNode from "./annotation"


export const nodeTypes = {
    annotation: AnnotationNode,
    start: StartNode,
    smtp: SMTPNode,
    dbQuery: DBQueryNode,
    request: RequestNode,
    jsonToXlsx: JsonToXlsxNode,
    graphql: GraphqlNode,
    conditional: ConditionalNode,
    startLoop: StartLoopNode,
    waitTime: WaitTimeNode,
    schedule: ScheduleNode,
    loopControl: LoopControlNode,
    jump: JumpNode,
    json: JsonNode,
    flowVariable: FlowVariableNode,
    endLoop: EndLoopNode,
    code: RunCodeNode,
    message: MessageNode,
    ticketTransfer: TicketTransferNode,
    closeTicket: CloseTicketNode,
    activeMessage: ActiveMessageNode,
    tagTicket: TagTicketNode,
    endFlow: EndFlowNode,
    inactive: InactiveNode,
    split: SplitNode,
    saveMessage: SaveMessageNode,
    timeInterval: TimeIntervalNode

}

export const nodeData = {
    start: StartData,
    conditional: ConditionalData,
}


export const NodeMenu = {
    start: StartMenu,
    smtp: SMTPMenu,
    dbQuery: DBQueryMenu,
    request: RequestMenu,
    jsonToXlsx: JsonToXlsxMenu,
    graphql: GraphqlMenu,
    conditional: ConditionalMenu,
    startLoop: StartLoopMenu,
    waitTime: WaitTimeMenu,
    schedule: ScheduleMenu,
    loopControl: LoopControlMenu,
    jump: JumpMenu,
    json: JsonMenu,
    flowVariable: FlowVariableMenu,
    endLoop: EndLoopMenu,
    code: RunCodeMenu,
    message: MessageMenu,
    ticketTransfer: TicketTransferMenu,
    activeMessage: ActiveMessageMenu,
    tagTicket: TagTicketMenu,
    inactive: InactiveMenu,
    split: SplitMenu,
    saveMessage: SaveMessageMenu,
    timeInterval: TimeIntervalMenu
}
