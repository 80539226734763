export const RequestData = {
  hasInput: true,
  port: {
    error: {
      color: '#EF5350',
    },
    success: {
      color: '#5FD07E',
    },
  },
  category: {
    color: '#1E88E5',
  },
  retryable: false,
  type: 'request',
  method: 'GET',
  title: 'Request 01',
  label: 'request node',
}

export default RequestData
