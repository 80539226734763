import React, {useContext, useState} from "react";
import { Avatar, Dialog, DialogContent, DialogTitle, Divider, Tooltip, Typography } from "@material-ui/core";
import { IconButton, Stack } from "@mui/material";
import { CloseOutlined, ScheduleOutlined, SendOutlined } from "@material-ui/icons";
import { v4 as uuidv4 } from "uuid";
import { TicketsContext } from "../../context/Tickets/TicketsContext";
import InvividualScheduleModal from "../IndividualScheduleModal";

const TickerTransfer = ({ open, onClose, infos }) => {
	const [scheduleModalOpen, setScheduleModalOpen] = useState(false);

    const { setCurrentTicket } = useContext(TicketsContext);
    const handleSelectTicket = ticket => {
        onClose()
        const code = uuidv4();
        const { id, uuid } = ticket;
        setCurrentTicket({ id, uuid, code });
    };

    const handleCloseScheduleModal = () => {
		setScheduleModalOpen(false);
	}

    return (
        <Dialog
            open={open}
            onClose={onClose}
        >
            <InvividualScheduleModal
				open={scheduleModalOpen}
				onClose={handleCloseScheduleModal}
				aria-labelledby="form-dialog-title"
				contactId={infos?.contact?.id}
			/>
            <DialogTitle>
                <Typography variant="h6">
                    Nova transferência de atendimento
                </Typography>
            </DialogTitle>
            <Divider />
            <DialogContent>
                <Stack my={2} spacing={1}>
                    <Stack p={2} sx={{
                        backgroundColor: "#f8f8f8",
                        borderRadius: "5px",
                        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"
                    }}
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Stack mb={1} >
                            <Avatar
                                src={infos?.contact?.profilePicUrl}
                           />
                        </Stack>
                     


                        <Stack>
                              <Divider style={{marginTop: "10px", marginBottom: "10px"}} />
                            <Typography variant="body1">
                                <><b>Contato:</b></> {infos?.contact?.name}
                            </Typography>
                            <Typography variant="body1">
                                <><b>Telefone:</b></> {infos?.contact?.number}
                            </Typography>
                            <Divider style={{marginTop: "10px", marginBottom: "10px"}} />

                        </Stack>
                        <Stack direction="row">
                         <Tooltip title="Agendar mensagem">
                                <IconButton
                                    onClick={() => setScheduleModalOpen(true)}
                                
                                >
                                    <ScheduleOutlined />

                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Fechar">
                                <IconButton
                                    onClick={onClose}
                                >
                                    <CloseOutlined />

                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Iniciar atendimento">
                                <IconButton 
                                    onClick={() => handleSelectTicket(infos)}
                                >
                                    <SendOutlined />

                                </IconButton>
                            </Tooltip>
                   
                           
                        </Stack>

                    </Stack>
                </Stack>
            </DialogContent>
        </Dialog>
    );
};

export default TickerTransfer;
