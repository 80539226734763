import React, { useState, useEffect } from "react";
import { Card, Avatar, theme } from "antd";
import { i18n } from "../../translate/i18n";
import CardHeader from "@material-ui/core/CardHeader";


const { useToken } = theme;

const TicketInfo = ({ contact, ticket, onClick }) => {
  const { user } = ticket;
  const [userName, setUserName] = useState("");
  const [contactName, setContactName] = useState("");
   
  useEffect(() => {
    if (contact) {
      setContactName(contact?.name);
      if (document.body.offsetWidth < 600) {
        if (contact?.name.length > 10) {
          const truncadName = contact?.name.substring(0, 10) + "...";
          setContactName(truncadName);
        }
      }
    }

    if (user && contact) {
      //		setUserName(`${i18n.t("messagesList.header.assignedTo")} ${ticket.user.name} - ${ticket.queue.name}`);
      setUserName(
        `${i18n.t("messagesList.header.assignedTo")} ${ticket?.user?.name} ${ 
          ticket?.queue
            ? " | Setor: " + ticket?.queue?.name
            : " | Setor: Nenhum"
        }`
      );
      if (document.body.offsetWidth < 600) {
        setUserName(`${user?.name}`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <CardHeader
      onClick={onClick}
      style={{ cursor: "pointer" }}
      titleTypographyProps={{ noWrap: true }}
      subheaderTypographyProps={{ noWrap: true }}
      avatar={<Avatar src={contact?.profilePicUrl} alt="contact_image" size={48}/>}
      title={
        <>
          {`${contactName} #${ticket.id}`}
          {!!contact?.group?.name && (
            <span
              style={{
                backgroundColor: "#7C7C7C",
                marginLeft: "8px",
                padding: "1.5px 8px",
                borderRadius: "4px",
                color: "white",
                fontSize: "smaller",
                maxWidth: "120px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {contact.group.name}
            </span>
          )}
        </>
      }
      subheader={ticket?.user && `${userName}`}
    />
  );
};

export default TicketInfo;
