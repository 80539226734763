import React, { useState, useEffect, useReducer, useContext, useRef } from "react";

import { toast } from "react-toastify";
import { useHistory, useParams } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import { TBody, THead, Table, TCell, TRow } from "../../components/Table";
import Paper from "@material-ui/core/Paper";
import Search from "antd/es/input/Search"
import { Button } from "antd"
import IconButton from "@material-ui/core/IconButton";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditIcon from "@material-ui/icons/Edit";
import api from "../../services/api";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import ConfirmationModal from "../../components/ConfirmationModal/";
import { i18n } from "../../translate/i18n";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import MainContainer from "../../components/MainContainer";
import toastError from "../../errors/toastError";
import { isArray } from "lodash";
import CampaignModal from "../../components/CampaignModal";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import PauseCircleOutlineIcon from "@material-ui/icons/PauseCircleOutline";
import DescriptionIcon from "@material-ui/icons/Description";

import { useDate } from "../../hooks/useDate";
import { socketConnection } from "../../services/socket";
import { Box, Chip, Stack, Tooltip, Typography } from "@mui/material";
import NewCampaingModal from "../../components/NewCampaignModal";
import ShowCampaignResultModal from "../../components/ShowCampaignResult";
import { Visibility } from "@mui/icons-material";


const reducer = (state, action) => {
  if (action.type === "LOAD_CAMPAIGNS") {
    const campaigns = action.payload;
    const newCampaigns = [];

    if (isArray(campaigns)) {
      campaigns.forEach((campaign) => {
        const campaignIndex = state.findIndex((u) => u.id === campaign.id);
        if (campaignIndex !== -1) {
          state[campaignIndex] = campaign;
        } else {
          newCampaigns.push(campaign);
        }
      });
    }

    return [...state, ...newCampaigns];
  }

  if (action.type === "UPDATE_CAMPAIGNS") {
    const campaign = action.payload;
    const campaignIndex = state.findIndex((u) => u.id === campaign.id);

    if (campaignIndex !== -1) {
      state[campaignIndex] = campaign;
      return [...state];
    } else {
      return [campaign, ...state];
    }
  }

  if (action.type === "DELETE_CAMPAIGN") {
    const campaignId = action.payload;

    const campaignIndex = state.findIndex((u) => u.id === campaignId);
    if (campaignIndex !== -1) {
      state.splice(campaignIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    backgroundColor: "#fff",
    overflowY: "hidden",
    borderRadius: "10px",
    ...theme.scrollbarStyles,
  },
}));

const Contacts = () => {

  const classes = useStyles();

  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [showCampaignResult, setShowCampaignResult] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [deletingCampaign, setDeletingCampaign] = useState(null);
  const [campaignModalOpen, setCampaignModalOpen] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [searchParam, setSearchParam] = useState("");
  const [campaigns, dispatch] = useReducer(reducer, []);
  const [showInfos, setShowInfos] = useState(null);
  const { datetimeToClient } = useDate();

  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [searchParam]);

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      fetchCampaigns();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParam, pageNumber]);

  useEffect(() => {
    const companyId = localStorage.getItem("companyId");
    const socket = socketConnection({ companyId });

    socket.on(`company-${companyId}-campaign`, (data) => {
      if (data.action === "update" || data.action === "create") {
        dispatch({ type: "UPDATE_CAMPAIGNS", payload: data.record });
      }
      if (data.action === "delete") {
        dispatch({ type: "DELETE_CAMPAIGN", payload: +data.id });
      }
    });
    return () => {
      socket.disconnect();
    };
  }, []);

  const fetchCampaigns = async () => {
    try {
      const { data } = await api.get("/broadcasts/", {
        params: { searchParam, pageNumber },
      });
      dispatch({ type: "LOAD_CAMPAIGNS", payload: data.records });
      setHasMore(data.hasMore);
      setLoading(false);
    } catch (err) {
      toastError(err);
    }
  };

  const handleOpenCampaignModal = () => {
    setSelectedCampaign(null);
    setCampaignModalOpen(true);
  };

  const handleCloseCampaignModal = () => {
    setSelectedCampaign(null);
    setCampaignModalOpen(false);
  };

  const handleSearch = (event) => {
    setSearchParam(event.target.value.toLowerCase());
  };

  const handleEditCampaign = (campaign) => {
    setSelectedCampaign(campaign);
    setCampaignModalOpen(true);
  };

  const handleDeleteCampaign = async (campaignId) => {
    try {
      await api.delete(`/broadcast/${campaignId}`);
      toast.success(i18n.t("Campanha removida com sucesso!"));
    } catch (err) {
      toastError(err);
    }
    setDeletingCampaign(null);
    setSearchParam("");
    setPageNumber(1);
  };

  const loadMore = () => {
    setPageNumber((prevState) => prevState + 1);
  };

  const handleScroll = (e) => {
    if (!hasMore || loading) return;
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      loadMore();
    }
  };

  const formatStatus = (val) => {
    switch (val) {
      case "INATIVA":
        return <Chip label="Inativa" color="error" />;
      case "PROGRAMADA":
        return <Chip label="Programada" color="warning" />;
      case "EM_ANDAMENTO":
        return <Chip label="Em andamento" color="primary" />;
      case "CANCELADA":
        return <Chip label="Cancelada" color="error" />;
        case "ERRO":
          return <Chip label="Erro" color="error" />;
      case "FINALIZADA":
        return <Chip label="Finalizada" color="success" />;
        case "PAUSADA":
          return <Chip label="Pausada" color="warning" />;
      default:
        return val;
    }
  };

  const handleOpenResult = (id) => {
    setShowInfos(id);
    setShowCampaignResult(true);

  }

  const cancelCampaign = async (campaign) => {
    try {
      await api.post(`/broadcast/stop/${campaign.id}`);
      toast.success(i18n.t("Campanha pausada com sucesso!"));
      setPageNumber(1);
      fetchCampaigns();
    } catch (err) {
      toast.error(err.message);
    }
  };

  const restartCampaign = async (campaign) => {
    try {
      await api.post(`/broadcast/start/${campaign.id}`);
      toast.success(i18n.t("Campanha retomada com sucesso!"));
      setPageNumber(1);
      fetchCampaigns();
    } catch (err) {
      toast.error(err.message);
    }
  };

  return (
    <MainContainer className={classes.mainContainer}>
      <ConfirmationModal
        title={
          deletingCampaign &&
          `${i18n.t("campaigns.confirmationModal.deleteTitle")} ${
            deletingCampaign?.name
          }?`
        }
        open={confirmModalOpen}
        onClose={setConfirmModalOpen}
        onConfirm={() => handleDeleteCampaign(deletingCampaign.id)}
      >
        {i18n.t("campaigns.confirmationModal.deleteMessage")}
      </ConfirmationModal>
      {/* <ConfirmationModal
        title={
          deletingCampaign &&
          `${i18n.t("campaigns.confirmationModal.deleteTitle")} ${
            deletingCampaign?.name
          }?`
        }
        open={confirmModalOpen}
        onClose={setConfirmModalOpen}
        onConfirm={() => handleDeleteCampaign(deletingCampaign.id)}
      >
        {i18n.t("campaigns.confirmationModal.deleteMessage")}
      </ConfirmationModal>
      <CampaignModal
        resetPagination={() => {
          setPageNumber(1);
          fetchCampaigns();
        }}
        open={campaignModalOpen}
        onClose={handleCloseCampaignModal}
        aria-labelledby="form-dialog-title"
        campaignId={selectedCampaign && selectedCampaign.id}
      />
 */}
      <ShowCampaignResultModal
        open={showCampaignResult && showInfos !== null}
        onClose={() => setShowCampaignResult(false)}
        campaign={showInfos}
      />
      <NewCampaingModal
        open={campaignModalOpen}
        onClose={handleCloseCampaignModal}
      />


      <Paper
        className={classes.mainPaper}
        variant="outlined"
        onScroll={handleScroll}
      >
        <MainHeader>
          <Stack>
            <Typography variant="h5" color="black" style={{ fontWeight: "bold", marginLeft: "10px", marginTop: "10px" }} gutterBottom>
              Campanhas
            </Typography>
            <Typography style={{ marginTop: "-10px", marginLeft: "10px" }} variant="caption" color="textSecondary">
              Agende uma campanha para enviar mensagens para seus contatos
            </Typography>
          </Stack>
          <MainHeaderButtonsWrapper>
            <Search
              placeholder={i18n.t("Buscar")}
              size="large"
              type="search"
              value={searchParam}
              onChange={handleSearch}
              style={{
                width: "300px",
                marginRight: "10px",
                marginLeft: "10px",
              }}
            />
            <Button
              size="large"
              type="primary"
              onClick={handleOpenCampaignModal}
            >
              Nova campanha
            </Button>

          </MainHeaderButtonsWrapper>
        </MainHeader>
        <Stack
          style={{
            padding: "20px",
            backgroundColor: "#fff",
            borderRadius: "5px",
            height: "93%",
            overflowY: "auto",
          }}
        >
          <Paper style={{
            boxShadow: "none",
            backgroundColor: "#fff",
          }} >

            <Table size="small">
              <THead sticky="true">
                <TRow>
                  <TCell align="center" sx={{ color: "white" }}>
                    {i18n.t("campaigns.table.name")}
                  </TCell>
                  <TCell align="center" sx={{ color: "white" }}>
                    {i18n.t("campaigns.table.status")}
                  </TCell>
                  <TCell align="center" sx={{ color: "white" }}>
                    Método
                  </TCell>
                  <TCell align="center" sx={{ color: "white" }}>
                    {i18n.t("Conexões")}
                  </TCell>
 
                  <TCell align="center" sx={{ color: "white" }}>
                    {i18n.t("Fluxo")}
                  </TCell>
                  <TCell align="center" sx={{ color: "white" }}>
                    {i18n.t("campaigns.table.scheduledAt")}
                  </TCell>
                  <TCell align="center" sx={{ color: "white" }}>
                    {i18n.t("campaigns.table.actions")}
                  </TCell>
                </TRow>
              </THead>
              <TBody>
                <>
                  {campaigns.map((campaign) => (
                    <TRow key={campaign.id}>
                      <TCell align="center">{campaign?.name}</TCell>
                      <TCell align="center">
                        {formatStatus(campaign.status)}
                      </TCell>
                      <TCell align="center">
                    {campaign.method === "bySheet" ? (
                      <Chip label="Por Planilha" />
                    ): campaign.method === "byLabel" ? (
                      <Chip label="Por Etiqueta" />
                    ): campaign.method === "allContacts" ? (
                      <Chip label="Todos os contatos" />
                    ): null }
                  </TCell>
                  
                      <TCell align="center">
                        {campaign.channels
                          ? campaign.channels.split(",").length
                          : "Não definido"}
                      </TCell>
     
                      <TCell align="center">
                        {campaign.flow?.name
                          ? campaign.flow?.name
                          : "Não definido"}
                      </TCell>
                      <TCell align="center">
                        {campaign.scheduledAt
                          ? datetimeToClient(campaign.scheduledAt)
                          : "Sem agendamento"}
                      </TCell>


                      <TCell align="center">
                        {campaign.status === "EM_ANDAMENTO" && (
                          <IconButton
                            onClick={() => cancelCampaign(campaign)}
                            title="Parar Campanha"
                            size="small"
                          >
                            <PauseCircleOutlineIcon />
                          </IconButton>
                        )}
                        {campaign.status === "PAUSADA" && (
                          <IconButton
                            onClick={() => restartCampaign(campaign)}
                            title="Parar Campanha"
                            size="small"
                          >
                            <PlayCircleOutlineIcon />
                          </IconButton>
                        )}
                        <IconButton
                          onClick={() =>
                            handleOpenResult(campaign.id)
                          }
                          size="small"
                        >
                          <Visibility />
                        </IconButton>
                        {/* <IconButton
                          size="small"
                          onClick={() => handleEditCampaign(campaign)}
                        >
                          <EditIcon />
                        </IconButton> */}

                        <IconButton
                          size="small"
                          onClick={(e) => {
                            setConfirmModalOpen(true);
                            setDeletingCampaign(campaign);
                          }}
                        >
                          <DeleteOutlineIcon />
                        </IconButton>
                      </TCell>
                    </TRow>
                  ))}
                  {loading && <TableRowSkeleton columns={7} />}
                </>
              </TBody>
            </Table>
          </Paper>
        </Stack>
      </Paper>
    </MainContainer>
  );
};

export default Contacts;
