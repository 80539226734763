import { Box, BoxProps } from '@mui/material'

export default function IconFile({ sx, menu, ...props }) {
  return (
    <Box
      sx={{
        ...(menu && {
          height: 20,
          width: 20,
        }),
        path: {
          fill: 'currentColor'
        },
        ...sx,
      }}
      {...props}
    />
  )
}
