export function shouldShowFabToScrollBottom(store, options) {
  const { threshold, target } = options

  if (target) {
    store.current = {
      scroll:
        target.pageYOffset !== undefined
          ? target.pageYOffset
          : target.scrollTop,
      height: target.scrollHeight - target.offsetHeight,
    }
  }

  return store.current?.height - store.current?.scroll > threshold
}
