import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  Grid,
  Popover,
  Stack,
  Switch,
  Typography,
  useTheme,
} from "@mui/material"
import NodeMenu from "../../../StyleNodes/Menu"
import React, { useRef, useState } from "react"
import { i18n } from "../../../../../../../translate/i18n";
import { Input, Select } from "antd"
import { renderIcons } from "../../../../Icons"
import Editor from "@monaco-editor/react";

const handleStyle = { left: 10 }

export const RunCodeMenu = ({ node, anchorRef }) => {
  const { data } = node
  const theme = useTheme()

  const [values, setValues] = useState(data)


  const handleRun = () => { }

  const handleChange = ({ target }) => {
    values[target.name] = target.value
    setValues({ ...values })
  }

  return (
    <NodeMenu
      open
      node={node}
      anchorRef={anchorRef}
      values={values}
      onChange={handleChange}
      onRun={handleRun}
    >
      <Stack direction="row" alignItems="center" spacing={1}>
        <Stack sx={{
          backgroundColor: `#0C3249`,
          padding: 1,
          borderRadius: "10px 10px 10px 10px"
        }}
        >
          {renderIcons(data?.payload?.icon)}
        </Stack>
        <Stack spacing={0}>
          <Typography variant="h6"> {i18n.t('Código Javascript')}</Typography>
          <Typography color="text.secondary" sx={{ mt: -0.7 }}>
            {i18n.t('Execute um código Javascript')}
          </Typography>

        </Stack>
      </Stack>
      <Divider sx={{ my: 1 }} />
 
          <Stack className="fullHeight">
            
            <Typography variant="caption" color="text.secondary">
              {i18n.t('Código Javascript')}
            </Typography>
            <Stack sx={{
              height: "300px",
            }}>

         
            <Editor
            height="100%"
            defaultLanguage="javascript"
            theme={'vs-dark' }
            defaultValue={values.code}
            onChange={(value) =>
              handleChange({
                target: { name: "code", value },
              })
            }
          />
             </Stack>
          </Stack>






    </NodeMenu>
  )
}

export default RunCodeMenu
