import styled from "styled-components"
import { Paper, Box } from "@mui/material"

export const Node = styled(Box)`
  border-radius: 10px;
  cursor: pointer;
  margin-bottom: 16px;
  border: 0px solid transparent !important;
  box-shadow: rgba(99, 99, 99, 0.1) 0px 2px 8px 0px;
  background-color: transparent;
`

export const IconContainer = styled.div.attrs((props) => props)`
  background: ${({ color }) => color}22;
  border-radius: 5px;
  width: 50px;
  height: 50px;
  padding: 10px;
`
