import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Popover,
  Stack,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material"
import NodeMenu from "../../../StyleNodes/Menu"

import { ExpandMore, InfoOutlined, UploadFileOutlined } from "@mui/icons-material"
import React, { useEffect, useRef, useState } from "react"
import { i18n } from "../../../../../../../translate/i18n";
import { Input, Select } from "antd"
import { renderIcons } from "../../../../Icons"

const handleStyle = { left: 10 }

export const LoopControlMenu = ({ node, anchorRef }) => {
  const { data } = node
  const [anchorEl, setAnchorEl] = useState(null)
  const [values, setValues] = useState(data)

  const handleRun = () => { }

  const handleChange = ({ target }) => {
    values[target.name] = target.value
    setValues({ ...values })
  }
  const handleChangeNumber = ({ target }) => {
    const { value } = target
    if (parseInt(value) > 5 || parseInt(value) < 1) return 
    values[target.name] = value
    setValues({ ...values })
  }



  const handleChangeSelectMaxRetries = (value) => {
    values["maxRetriesAction"] = value
    setValues({ ...values })
  }
  const handleChangeSelect = (value) => {
    values["action"] = value
    setValues({ ...values })
  }
  const handleChangeAlias = ({ target }) => {
    if (target.value.length > 20) return
    target.value = target.value.replace(/[^a-zA-Z0-9]/g, "")
    values[target.name] = target.value
    setValues({ ...values })
  }


  return (
    <NodeMenu
      open
      node={node}
      anchorRef={anchorRef}
      values={values}
      onChange={handleChange}
      onRun={handleRun}
    >
      <Stack direction="row" alignItems="center" spacing={1}>
        <Stack sx={{
          backgroundColor: `#0C3249`,
          padding: 1,
          borderRadius: "10px 10px 10px 10px"
        }}
        >
          {renderIcons(data?.payload?.icon)}
        </Stack>
        <Stack spacing={0}>
          <Typography variant="h6"> {i18n.t('Controle de loop')}</Typography>
          <Typography color="text.secondary" sx={{ mt: -0.7 }}>
            {i18n.t('Controle de loop no fluxo')}
          </Typography>

        </Stack>
      </Stack>
      <Divider sx={{ my: 1 }} />
      <Grid container spacing={2}>
      <Grid item xs={4}>
          <Stack>
            <Stack direction="row" alignItems="center" spacing={0.5}>
            <Typography variant="caption" color="text.secondary">
              {i18n.t('Alias')}
            </Typography>
              <Tooltip title="Use o alias criado para controlar o loop do fluxo">
                <IconButton size="small">
                  <InfoOutlined sx={{fontSize: "12px"}} />
                </IconButton>
              </Tooltip>
            </Stack>
            <Input
              placeholder="Alias"
              name="alias"
              value={values.alias}
              onChange={handleChangeAlias}
              size="large"
            />
          </Stack>
        </Grid>
        <Grid item xs>
          <Stack>
            <Typography variant="caption" color="text.secondary">
              {i18n.t('Ação')}
            </Typography>
            <Select
              placeholder={i18n.t('Ação')}
              id="action"
              onChange={handleChangeSelect}
              value={values.action}
              options={[{
                label: "Continuar",
                value: "continue"
              }, {
                label: "Parar",
                value: "break"
              },
              {
                label: "Retornar índice",
                value: "returnIndex"
              }
            ].map((option) => {
                return { value: option.value, label: option.label }
              })}
              size="large"
            />
          </Stack>
        </Grid>
        {values.action === "returnIndex" && (
          <>  
          <Grid item xs={12}>
                <Typography variant="body2" fontWeight="bold" color="text.secondary">
                  {i18n.t('Configurações avançadas')}
                </Typography>
                <Divider sx={{mt:1}} />
              </Grid>



                <Grid item xs={4}>
                <Stack>
                  <Stack direction="row" alignItems="center" spacing={0.5}>
                  <Typography variant="caption" color="text.secondary">
                    {i18n.t('Máximo de retornos')}
                  </Typography>
                    <Tooltip title="Informe um número máximo de tentavivas na execução do loop.">
                      <IconButton size="small">
                        <InfoOutlined sx={{fontSize: "12px"}} />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                  <Input
                    placeholder="1"
                    type="number"
                    name="maxRetries"
                    max={5}
                    min={1}
                    value={values.maxRetries}
                    onChange={handleChangeNumber}
                    size="large"
                  />
                </Stack>
              </Grid>
              <Grid item xs>
          <Stack>
            <Typography variant="caption" color="text.secondary">
              {i18n.t('Ação após o limite de tentativas')}
            </Typography>
            <Select
              placeholder={i18n.t('Ação')}
              id="maxRetriesAction"
              onChange={handleChangeSelectMaxRetries}
              value={values.maxRetriesAction}
              options={[{
                label: "Continuar",
                value: "continue"
              }, {
                label: "Parar",
                value: "break"
              }
            ].map((option) => {
                return { value: option.value, label: option.label }
              })}
              size="large"
            />
          </Stack>
        </Grid>
              </>
        )}
      
      </Grid>
    </NodeMenu>
  )
}

export default LoopControlMenu
