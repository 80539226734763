// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.ant-modal-root.ant-modal-wrap {
    position: fixed;
    inset: 0;
    z-index: 1000;
    overflow: none !important;
    outline: 0;
    -webkit-overflow-scrolling: touch;
}`, "",{"version":3,"sources":["webpack://./src/pages/Flow/Diagram/Builder/StyleNodes/Menu/style.css"],"names":[],"mappings":";AACA;IACI,eAAe;IACf,QAAQ;IACR,aAAa;IACb,yBAAyB;IACzB,UAAU;IACV,iCAAiC;AACrC","sourcesContent":["\n.ant-modal-root.ant-modal-wrap {\n    position: fixed;\n    inset: 0;\n    z-index: 1000;\n    overflow: none !important;\n    outline: 0;\n    -webkit-overflow-scrolling: touch;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
