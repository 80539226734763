import React from 'react'
import {
    Grid, Paper, Skeleton,
} from '@mui/material'
import styled from "styled-components"
import { IconContainer } from '../Nodes/styled'

const NodeSkeleton = styled(Paper)`
  margin: 16px;
  width: 388px;
  height: 70px;
  border: 1px solid ${({ theme }) => theme.palette.background.default};
  border-radius: 8px;
  padding: 8px 16px;
  cursor: pointer;
  margin-bottom: 16px;
`

export default function NodeItemSkeleton() {
    return (
        <>
            <NodeSkeleton
                className="dndnode"
            >
                <Grid container wrap="nowrap" alignItems="center" spacing={2}>
                    <Grid item>
                        <IconContainer>
                            <Skeleton variant="rectangular" width={35} height={35} />
                        </IconContainer>
                    </Grid>
                    <Grid item xs zeroMinWidth>
                        <Skeleton variant="text" width="100%" />
                        <Skeleton variant="text" width="100%" />
                    </Grid>
                    <Grid item>
                        <Skeleton variant="circular" width={15} height={15} />
                    </Grid>
                </Grid>
            </NodeSkeleton>
            <NodeSkeleton
                className="dndnode"
            >
                <Grid container wrap="nowrap" alignItems="center" spacing={2}>
                    <Grid item>
                        <IconContainer>
                            <Skeleton variant="rectangular" width={35} height={35} />
                        </IconContainer>
                    </Grid>
                    <Grid item xs zeroMinWidth>
                        <Skeleton variant="text" width="100%" />
                        <Skeleton variant="text" width="100%" />
                    </Grid>
                    <Grid item>
                        <Skeleton variant="circular" width={15} height={15} />
                    </Grid>
                </Grid>
            </NodeSkeleton>
            <NodeSkeleton
                className="dndnode"
            >
                <Grid container wrap="nowrap" alignItems="center" spacing={2}>
                    <Grid item>
                        <IconContainer>
                            <Skeleton variant="rectangular" width={35} height={35} />
                        </IconContainer>
                    </Grid>
                    <Grid item xs zeroMinWidth>
                        <Skeleton variant="text" width="100%" />
                        <Skeleton variant="text" width="100%" />
                    </Grid>
                    <Grid item>
                        <Skeleton variant="circular" width={15} height={15} />
                    </Grid>
                </Grid>
            </NodeSkeleton>
        </>

    )
}
