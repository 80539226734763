import { Paper, Stack, Typography } from "@mui/material"
import { LoadingButton } from '@mui/lab'
import moment from "moment"

export default function BuildItem({ item, loading, disabled, onReset }) {
  return (
    <Paper variant="outlined" sx={{ p: 2, borderRadius: 2 }}>
      <Stack direction="row" alignItems="center" spacing={3}>
        <Stack spacing={1} sx={{ flexGrow: 1 }}>
          <Typography color="primary" fontWeight={500}>
            {item.version}{') '}
            {item.name}
          </Typography>
          {item.description ? (
            <Typography variant="body2">
              {item.description}
            </Typography>
          ) : (
            <Typography variant="body2" color="text.secondary" fontStyle="italic">
              {"Sem descrição"}
            </Typography>
          )}
          <Typography variant="caption">
            {"Publicado em"}
            {' '}
            {moment(item.createdAt).format('DD/MM HH:mm')}
          </Typography>
        </Stack>

        <LoadingButton
          onClick={onReset}
          loading={loading}
          disabled={loading || disabled}
          variant="contained"
          color="primary"
        >
          <span>Resetar</span>
        </LoadingButton>
      </Stack>
    </Paper>
  )
}
