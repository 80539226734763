import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Chip from "@material-ui/core/Chip";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import { Select } from "antd";
import { Stack,Box,Typography } from "@mui/material"
const useStyles = makeStyles(theme => ({
	chips: {
		display: "flex",
		flexWrap: "wrap",
	},
	chip: {
		margin: 2,
	},
}));

const FlowSelect = ({ selectedTagsIds, onChange }) => {
	const classes = useStyles();
	const [queues, setQueues] = useState([]);
	useEffect(() => {
		(async () => {
			try {
				const { data } = await api.get("/flows");
				const queuesData = data?.flows.map((item) => {
					return {
						value: item.id,
						label: <Stack direction="row" spacing={1}  alignItems="center" mt={0.5} mr={1}>
		
							<Typography variant="body2" sx={{ fontWeight: 500 }}>
								{item?.name}
							</Typography>
						</Stack>,
					}
				})
				setQueues(queuesData);
			} catch (err) {
				toastError(err);
			}
		})();
	}, []);

	const handleChange = e => {
		onChange(e.target.value);
	};

	const filterOption = (input, option) => {
        //filter by tag name
        return option.label.props.children[0].props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
       }

	return (
		<Select
			placeholder={"Selecione um fluxo"}
			id="queue"
			allowClear
			filterOption={filterOption}
			optionFilterProp="children"
			onChange={onChange}
			value={selectedTagsIds}
			options={queues}
			size="large"
			style={{ width: '100%' }}
		/>
	);
};

export default FlowSelect;
