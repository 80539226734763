import React, {
  useState,
  useEffect,
  useReducer,
  useCallback,
  useContext
} from "react";
import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import { TBody, THead, Table, TCell, TRow } from "../../components/Table";

import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditIcon from "@material-ui/icons/Edit";
import Search from "antd/es/input/Search"

import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";

import { i18n } from "../../translate/i18n";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import ConfirmationModal from "../../components/ConfirmationModal";
import toastError from "../../errors/toastError";
import { Chip } from "@material-ui/core";
import { socketConnection } from "../../services/socket";
import { AuthContext } from "../../context/Auth/AuthContext";
import { Stack, Typography, Tooltip } from "@mui/material";
import useCompanies from "../../hooks/useCompanies";
import EditAccountModal from "../../components/EditAccountModal";


const reducer = (state, action) => {
  if (action.type === "LOAD_TAGS") {
    const accounts = action.payload;
    const newAccounts = [];

    accounts.forEach(account => {
      const accountIndex = state.findIndex(s => s.id === account.id);
      if (accountIndex !== -1) {
        state[accountIndex] = account;
      } else {
        newAccounts.push(account);
      }
    });

    return [...state, ...newAccounts];
  }

  if (action.type === "UPDATE_TAGS") {
    const account = action.payload;
    const accountIndex = state.findIndex(s => s.id === account.id);

    if (accountIndex !== -1) {
      state[accountIndex] = account;
      return [...state];
    } else {
      return [account, ...state];
    }
  }

  if (action.type === "DELETE_TAG") {
    const accountId = action.payload;

    const accountIndex = state.findIndex(s => s.id === accountId);
    if (accountIndex !== -1) {
      state.splice(accountIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const useStyles = makeStyles(theme => ({
  mainPaper: {
    flex: 1,
    backgroundColor: "#fff",
    overflowY: "hidden",
    borderRadius: "10px",
    ...theme.scrollbarStyles,
  }
}));



const Accounts = () => {
  const classes = useStyles();

  const { user } = useContext(AuthContext);

  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [deletingAccount, setDeletingAccount] = useState(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [searchParam, setSearchParam] = useState("");
  const [accounts, dispatch] = useReducer(reducer, []);
  const [accountModalOpen, setAccountModalOpen] = useState(false);
  const [accountModaDeleteConfirmOpen, setAccountModalDeleteConfirmOpen] = useState(false)

  const { list, save, update, remove } = useCompanies();


  const fetchAccounts = useCallback(async () => {
    try {
      const data = await list()
      dispatch({ type: "LOAD_TAGS", payload: data });
      setHasMore(data.hasMore);
      setLoading(false);
    } catch (err) {
      toastError(err);
    }
  }, [searchParam, pageNumber]);

  const deleteAccount = useCallback(async (id) => {
    if(!id) return
    try {
      dispatch({ type: "DELETE_TAG", payload: id });
      setLoading(false);
    } catch (err) {
      toastError(err);
    }
  }, [searchParam, pageNumber]);

  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [searchParam]);

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      fetchAccounts();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchParam, pageNumber, fetchAccounts]);



  const handleOpenAccountModal = () => {
    setSelectedAccount(null);
    setAccountModalOpen(true);
  };

  const handleCloseAccountModal = () => {
    setSelectedAccount(null);
    setAccountModalOpen(false);
  };


  const handleCloseAccountComfirmModal = ()=> {
    setSelectedAccount(null)
    setAccountModalDeleteConfirmOpen(false)
  }

  const handleOpenAccountComfirmModal = ()=> {
    setAccountModalDeleteConfirmOpen(true)
  }

  const handleSearch = event => {
    setSearchParam(event.target.value.toLowerCase());
  };

  const handleEditAccount = account => {
    setSelectedAccount(account);
    setAccountModalOpen(true);
  };

  const loadMore = () => {
    setPageNumber(prevState => prevState + 1);
  };

  const handleScroll = e => {
    if (!hasMore || loading) return;
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      loadMore();
    }
  };

  const handleSave = (values) =>{
    update(values).then((res)=>{
      toast.success("Conta atualizada com sucesso!")
      fetchAccounts()
      handleCloseAccountModal()
    } )
  }

  const handleRemove= (id) =>{
    remove(id).then((res)=>{
      toast.success("Deletado com sucesso!")
      deleteAccount(id)
      handleCloseAccountComfirmModal()
    })
  }

  return (
    <MainContainer>
      <EditAccountModal
        open={accountModalOpen}
        onClose={handleCloseAccountModal}
        accountData={selectedAccount}
        onSave={handleSave}
      />
     
      <ConfirmationModal
        open={accountModaDeleteConfirmOpen}
        onClose={handleCloseAccountComfirmModal}
        title="Atenção"
        onConfirm={()=>{
          handleRemove(selectedAccount.id)
        }}
      >
        Deseja deletar essa empresa ?
      </ConfirmationModal>

      <Paper
        className={classes.mainPaper}
        variant="outlined"
        onScroll={handleScroll}
      >
        <MainHeader>
          <Stack>
            <Typography variant="h5" color="black" style={{ fontWeight: "bold", marginLeft: "10px", marginTop: "10px" }} gutterBottom>
              Contas
            </Typography>
            <Typography style={{ marginTop: "-10px", marginLeft: "10px" }} variant="caption" color="textSecondary">
              Veja as contas cadastradas
            </Typography>
          </Stack>

          <MainHeaderButtonsWrapper>
            <Search
              placeholder={i18n.t("Buscar")}
              size="large"
              type="search"
              value={searchParam}
              onChange={handleSearch}
              style={{
                width: "300px",
                marginRight: "10px",
                marginLeft: "10px",
              }}
            />
         
          </MainHeaderButtonsWrapper>
        </MainHeader>
        <Stack
          style={{
            padding: "20px",
            backgroundColor: "#fff",
            borderRadius: "5px",
            height: "93%",
            overflowY: "auto",
            
          }}
        >
              <Paper style={{
            boxShadow: "none",
            backgroundColor: "#fff",
          }} >
          <Table size="small" sx={{
              backgroundColor: "#fff",
            }}>
                       <THead sticky="true">

                <TRow>
                  <TCell style={{ color: "#fff" }} align="center">
                    Nome
                  </TCell>
                  <TCell style={{ color: "#fff" }} align="center">
                    CPF / CNPJ
                  </TCell>
                  <TCell style={{ color: "#fff" }} align="center">
                    Telefone
                  </TCell>
                  <TCell style={{ color: "#fff" }} align="center">
                    Email
                  </TCell>
                  <TCell style={{ color: "#fff" }} align="center">
                    Plano
                  </TCell>
                  <TCell style={{ color: "#fff" }} align="center">
                    Status
                  </TCell>
                  <TCell style={{ color: "#fff" }} align="center">
                    Ações
                  </TCell>
                </TRow>
              </THead>
              <TBody>
                <>
                  {accounts.map(account => (
                    <TRow key={account.id}>

                      <TCell align="center">{account.name}</TCell>
                      <TCell align="center">{account.cpfCnpj}</TCell>
                      <TCell align="center">{account.phone}</TCell>
                      <TCell align="center">{account.email}</TCell>
                      <TCell align="center">{`${account?.plan?.name} - R$${account?.plan?.value}`}</TCell>
                      <TCell align="center">{account?.status ? "Ativo" : "Inativo"}</TCell>
                      <TCell align="center">
                        <Tooltip title="Editar">
                        <IconButton
                          size="small"
                          onClick={() => handleEditAccount(account)}
                        >
                          <EditIcon />
                        </IconButton>
                        </Tooltip>

                        <Tooltip title="Deletar">
                          <IconButton
                            size="small"
                            onClick={() => {
                              handleOpenAccountComfirmModal()
                              setSelectedAccount(account)
                            }}
                          >
                            <DeleteOutlineIcon />
                          </IconButton>
                        </Tooltip>

                      </TCell>
                    </TRow>
                  ))}
                  {loading && <TableRowSkeleton columns={7} />}
                </>
              </TBody>
            </Table>
          </Paper>
        </Stack>
      </Paper>
    </MainContainer>
  );
};

export default Accounts;
