
export const daysOfWeeksArray = [
    { id: 1, name: "sunday" },
    { id: 2, name: "monday" },
    { id: 3, name: "tuesday" },
    { id: 4, name: "wednesday" },
    { id: 5, name: "thursday" },
    { id: 6, name: "friday" },
    { id: 7, name: "saturday" }
];


// export const handleText = (data) => {
//     const { timeIntervals } = data
//     if (timeIntervals.length >= 1) {
//         const findDaysOrder = timeIntervals.map((interval) => {

//             if (interval.daysOfWeek.length === 7) {
//                 return {
//                     days: i18n.t('all days'),
//                     from: formatDate(interval.from, 'HH:mm'),
//                     until: formatDate(interval.until, 'HH:mm')
//                 }
//             }
//             if (!interval.daysOfWeek.length) {
//                 return []
//             }
//             const addIntervalsIds = interval?.daysOfWeek?.map((day) => {
//                 const findDay = daysOfWeeksArray.find((it) => it.name === day)
//                 return {
//                     id: findDay?.id,
//                     name: findDay?.name
//                 }
//             })

//             const checkHaveSequenceTrueFalse = addIntervalsIds?.map((day, index) => {
//                 if (index === 0) return true
//                 return day.id - addIntervalsIds[index - 1].id === 1
//             })

//             const haveSequence = checkHaveSequenceTrueFalse?.every((it) => it === true)

//             if (haveSequence) {
//                 const firstDay = addIntervalsIds[0].name
//                 const lastDay = addIntervalsIds[addIntervalsIds.length - 1].name
//                 return {
//                     days: i18n.t(firstDay).substring(0, 3) + ' - ' + i18n.t(lastDay).substring(0, 3),
//                     from: formatDate(interval?.from, 'HH:mm'),
//                     until: formatDate(interval?.until, 'HH:mm')
//                 }
//             } else {
//                 return {
//                     days: addIntervalsIds?.map((day) => i18n.t(day.name).substring(0, 3)).join(', '),
//                     from: formatDate(interval?.from, 'HH:mm'),
//                     until: formatDate(interval?.until, 'HH:mm')
//                 }
//             }
//         })
//         return findDaysOrder
//     } else {
//         return []
//     }
// }