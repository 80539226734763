import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  Divider,
  Grid,
  Popover,
  Stack,
  Switch,
  Typography,
} from "@mui/material"
import NodeMenu from "../../../StyleNodes/Menu"

import AceEditor from "react-ace"

import "ace-builds/src-noconflict/theme-github"
import "ace-builds/src-noconflict/mode-json"
import { ExpandMore, UploadFileOutlined } from "@mui/icons-material"
import React, { useRef, useState } from "react"
import { i18n } from "../../../../../../../translate/i18n";
import { Input, Select } from "antd"
import { renderIcons } from "../../../../Icons"
import FlowSelect from "../../../../../../../components/FlowSelect"

const handleStyle = { left: 10 }

export const JumpMenu = ({ node, anchorRef }) => {
  const { data } = node
  const [anchorEl, setAnchorEl] = useState(null)
  const [values, setValues] = useState(data)
  const [openImport, setOpenImport] = useState(false)

  const handleRun = () => { }

  const handleChange = ({ target }) => {
    values[target.name] = target.value
    setValues({ ...values })
  }


  const handleChangeFlow = (value) => {
    values["flow"] = value
    setValues({ ...values })
  }

  const handleChangeSwitch = ( value) => {
    values["returnOptions"] = value
    if (value === true) {
      setValues({ ...values, payload: { ...values.payload, port: { error: { color: "#EF5350" }, success: { color: "#5FD07E" } } } })
    } else {
      delete values.payload?.port
      setValues({ ...values })
    }
  }


  return (
    <NodeMenu
      open
      node={node}
      anchorRef={anchorRef}
      values={values}
      onChange={handleChange}
      onRun={handleRun}
    >
      <Stack direction="row" alignItems="center" spacing={1}>
        <Stack sx={{
          backgroundColor: `#0C3249`,
          padding: 1,
          borderRadius: "10px 10px 10px 10px"
        }}
        >
          {renderIcons(data?.payload?.icon)}
        </Stack>
        <Stack spacing={0}>
          <Typography variant="h6"> {i18n.t('Pular para fluxo')}</Typography>
          <Typography color="text.secondary" sx={{ mt: -0.7 }}>
            {i18n.t('Pule para um outro fluxo')}
          </Typography>

        </Stack>
      </Stack>
      <Divider sx={{ my: 1 }} />
      <Grid container spacing={2}>
        <Grid item xs>
          <Stack>
            <Typography variant="caption" color="text.secondary">
              {i18n.t('Fluxo')}
            </Typography>
            <FlowSelect
                selectedTagsIds={values?.flow ? parseInt(values?.flow) : null}
                onChange={handleChangeFlow}
              />
          </Stack>
        </Grid>
        <Grid item xs={1.5}>
          <Stack>
            <Typography variant="caption" color="text.secondary">
              {i18n.t('Continuar')}
            </Typography>
            <Checkbox style={{
              height: 30,
              width: 30,
              marginTop:3,
              
             }} checked={values?.returnOptions} onChange={(e) => handleChangeSwitch(e.target.checked)} />
          </Stack>
        </Grid>
       
      </Grid>





      <Box my={1}>
        <Typography variant="caption" color="text.secondary">
          Data
        </Typography>
        <AceEditor
          mode="json"
          theme="github"
          value={values.data}
          onChange={(value) =>
            handleChange({
              target: { name: "data", value },
            })
          }
          maxLines={10}
          minLines={10}
          name="UNIQUE_ID_OF_DIV"
          editorProps={{ $blockScrolling: true }}
          setOptions={{
            useWorker: false,
          }}
          style={{ width: "100%" }}
        />
      </Box>
    </NodeMenu>
  )
}

export default JumpMenu
