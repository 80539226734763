import styled from "styled-components"

export const Container = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  z-index: 20;
  background: #fafafa;
  padding: 15px 20px;
  border-bottom: 1px solid #e0e0e0;
`
