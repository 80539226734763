import React from "react";

const MainHeaderButtonsWrapper = ({ children }) => {
  return (
    <div
      style={{
        flex: "none",
        marginLeft: "auto",
        display: "flex",
        gap: "8px",
      }}
    >
      {children}
    </div>
  );
};

export default MainHeaderButtonsWrapper;
