import {
  Avatar,
  Box,
  Collapse,
  Chip,
  IconButton,
  Stack,
  Typography,
  Tooltip,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { TRow, TCell } from '../../../../components/Table'
import moment from 'moment'
import { useState } from 'react'
import classNames from 'classnames'
import {
  AccessAlarm,
  AutoMode,
  CommentOutlined,
  Cookie,
  FindInPage,
  Flag,
  Loop,
  Save,
  Sell,
  TimeToLeave,
  UpdateDisabled,
} from '@mui/icons-material'
import { Tabs, TabsProps } from 'antd'
import AceEditor from 'react-ace'
import {
  AccessTime,
  AccessTimeFilledOutlined,
  AddToPhotos,
  AirlineStops,
  Api,
  CallSplit,
  ChatBubble,
  Code,
  Edit,
  Email,
  Filter,
  FilterAlt,
  Fingerprint,
  Javascript,
  Keyboard,
  Language,
  MouseOutlined,
  OpenInBrowser,
  PlayArrowOutlined,
  Public,
  PublicOff,
  Search,
  Storage,
  Timelapse,
  TimelapseSharp,
  Watch,
} from '@mui/icons-material'
import SettingsEthernetIcon from '@mui/icons-material/SettingsEthernet'
import { AiFillFileExcel } from 'react-icons/ai'
import 'ace-builds/src-noconflict/theme-github'
import 'ace-builds/src-noconflict/mode-json'

export default function Row({ item, index }) {
  const [open, setOpen] = useState(false)
  const [rowVisibility, setRowVisibility] = useState('collapse')

  const METHOD_COLOR = {
    GET: '#1E88E5',
    POST: '#5FD07E',
    PUT: '#FF9800',
    PATCH: '#1E88E5',
    DELETE: '#EF5350',
  }

  const handleToggleRow = () => {
    if (!open) {
      // going to open
      setRowVisibility('visible')
    }
    setOpen(!open)
  }

  const handleEndAnimation = (el, done) => {
    if (!open) {
      setTimeout(() => {
        setRowVisibility('collapse')
        done()
      }, 100)
    }
  }

  const renderIcons = (icon) => {
    switch (icon) {
      case 'startIcon':
        return (
          <PlayArrowOutlined sx={{ color: '#fff', width: 20, height: 20 }} />
        )
      case 'requestIcon':
        return <Api sx={{ color: '#fff', width: 20, height: 20 }} />
      case 'graphqlIcon':
        return <Api sx={{ color: '#fff', width: 20, height: 20 }} />
      case 'dbQueryIcon':
        return <Storage sx={{ color: '#fff', width: 20, height: 20 }} />
      case 'conditionalIcon':
        return <FilterAlt sx={{ color: '#fff', width: 20, height: 20 }} />
      case 'runCodeIcon':
        return <Code sx={{ color: '#fff', width: 20, height: 20 }} />
      case 'scheduleIcon':
        return <Timelapse sx={{ color: '#fff', width: 20, height: 20 }} />
      case 'jsonIcon':
        return (
          <SettingsEthernetIcon sx={{ color: '#fff', width: 20, height: 20 }} />
        )
      case 'flowVariableIcon':
        return <CallSplit sx={{ color: '#fff', width: 20, height: 20 }} />
        case 'saveMessageIcon':
          return (
            <Save sx={{ color: '#fff', width: 20, height: 20 }} />
          )
      case 'messageIcon':
        return <ChatBubble sx={{ color: '#fff', width: 20, height: 20 }} />
        case 'timeIntervalIcon':
          return (
            <AccessTime  sx={{ color: '#fff', width: 20, height: 20 }} />
          )
        case 'inactiveIcon':
          return <AccessAlarm sx={{ color: '#fff', width: 20, height: 20 }} />
          case 'splitIcon':
            return <CallSplit sx={{ color: '#fff', width: 20, height: 20 }} />
      case 'activeMessageIcon':
        return <ChatBubble sx={{ color: '#fff', width: 20, height: 20 }} />
      case 'endFlowIcon':
        return <Flag sx={{ color: '#fff', width: 20, height: 20 }} />
      case 'tagTicketIcon':
        return <Sell sx={{ color: '#fff', width: 20, height: 20 }} />
      case 'jumpIcon':
        return <AirlineStops sx={{ color: '#fff', width: 20, height: 20 }} />
      case 'browserIcon':
        return <Public sx={{ color: '#fff', width: 20, height: 20 }} />
      case 'gotoUrlIcon':
        return <OpenInBrowser sx={{ color: '#fff', width: 20, height: 20 }} />
      case 'injectJavascriptIcon':
        return <Code sx={{ color: '#fff', width: 20, height: 20 }} />
      case 'pageClickIcon':
        return <MouseOutlined sx={{ color: '#fff', width: 20, height: 20 }} />
      case 'pageTypeIcon':
        return <Keyboard sx={{ color: '#fff', width: 20, height: 20 }} />
      case 'waitSelectorIcon':
        return (
          <AccessTimeFilledOutlined
            sx={{ color: '#fff', width: 20, height: 20 }}
          />
        )
      case 'solveCaptchaIcon':
        return <Fingerprint sx={{ color: '#fff', width: 20, height: 20 }} />
      case 'closeBrowserIcon':
        return <PublicOff sx={{ color: '#fff', width: 20, height: 20 }} />
      case 'jsonToXlsxIcon':
        return (
          <AiFillFileExcel style={{ color: '#fff', width: 20, height: 20 }} />
        )
      case 'waitTimeIcon':
        return <AccessTime style={{ color: '#fff', width: 20, height: 20 }} />
      case 'updateTicketIcon':
        return <Edit style={{ color: '#fff', width: 20, height: 20 }} />
      case 'createTicketIcon':
        return <AddToPhotos style={{ color: '#fff', width: 20, height: 20 }} />
      case 'searchTicketIcon':
        return <Search style={{ color: '#fff', width: 20, height: 20 }} />
      case 'smtpIcon':
        return <Email style={{ color: '#fff', width: 20, height: 20 }} />
      case 'setCookiesIcon':
        return <Cookie style={{ color: '#fff', width: 20, height: 20 }} />

      case 'startLoopIcon':
        return <Loop style={{ color: '#fff', width: 20, height: 20 }} />
      case 'endLoopIcon':
        return (
          <UpdateDisabled style={{ color: '#fff', width: 20, height: 20 }} />
        )
      case 'loopControlIcon':
        return <AutoMode style={{ color: '#fff', width: 20, height: 20 }} />
      case 'findCookiesIcon':
        return <FindInPage style={{ color: '#fff', width: 20, height: 20 }} />
      case 'createTabulationIcon':
        return (
          <CommentOutlined style={{ color: '#fff', width: 20, height: 20 }} />
        )
    }
  }

  const tabItems = [
    {
      key: '1',
      label: 'Variáveis',
      children: (
        <AceEditor
          mode="json"
          theme="github"
          value={JSON.stringify(item?.variables, null, 2) || '{}'}
          readOnly
          maxLines={10}
          minLines={10}
          name="UNIQUE_ID_OF_DIV"
          editorProps={{ $blockScrolling: true }}
          setOptions={{
            useWorker: false,
          }}
          style={{ width: '100%' }}
        />
      ),
    },
    {
      key: '2',
      label: 'Resposta',
      children: (
        <AceEditor
          mode="json"
          theme="github"
          value={JSON.stringify(item?.output, null, 2) || '{}'}
          readOnly
          maxLines={10}
          minLines={10}
          name="UNIQUE_ID_OF_DIV"
          editorProps={{ $blockScrolling: true }}
          setOptions={{
            useWorker: false,
          }}
          style={{ width: '100%' }}
        />
      ),
    },
  ]


  const renderStatus = {
    error: {
      label: "Erro",
      color: 'error.main',
    },
    success: {
      label: "Sucesso",
      color: 'success.main',
    },
    next: {
      label: "Próximo",
      color: 'info.main',
    },
    else: {
      label: "Else",
      color: 'warning.main',
    }
  }

  return (
    <>
      <TRow
        style={{
          boxShadow: 'none',
        }}
        sx={{
          position: 'relative',
          zIndex: 1,
          bgcolor: index % 2 === 0 ? 'background.paper' : 'background.default',
          cursor: 'pointer',
          boxShadow: (theme) => theme.shadows[1],
        }}
        active={open}
        onClick={handleToggleRow}
      >
        <TCell padding="checkbox" sx={{ width: '5px' }}>
          <IconButton size="small" onClick={handleToggleRow}>
            <Box
              component={ExpandMoreIcon}
              className={classNames({
                active: open,
              })}
              sx={{
                transition: (theme) => theme.transitions.create('transform'),
                transform: 'rotate(0)',
                '&.active': {
                  transform: 'rotate(-180deg)',
                },
              }}
            />
          </IconButton>
        </TCell>

        <TCell align="left" width={2}>
          <Stack direction="row" alignItems="left" spacing={1}>
            <Tooltip
              title={renderStatus[item?.status]?.label}
              placement="top"
            >

              <Avatar
                sizes="small"
                sx={{
                  backgroundColor: renderStatus[item?.status]?.color,
                  width: 30,
                  height: 30,
                }}
              >
                {renderIcons(`${item?.type}Icon`)}
              </Avatar>
            </Tooltip>
          </Stack>
        </TCell>
        <TCell align="left" width={15}>
          <Stack direction="row" spacing={1}>
            <Typography variant="body2"> - </Typography>
            <Typography variant="body2" fontWeight="bold">
              {item?.type.toUpperCase()}
            </Typography>
          </Stack>
        </TCell>
        <TCell align="left" width={5}>
          <Chip
            size="small"
            color="info"
            label={
              moment(item?.finishedAt).diff(
                moment(item?.startedAt),
                'milliseconds',
              ) + ' ms'
            }
          />
        </TCell>
        <TCell align="left"></TCell>
      </TRow>
      <tr style={{ border: 'none' }}>
        <td colSpan={7} style={{ border: 'none', padding: 0 }}>
          <Box sx={{ visibility: rowVisibility }}>
            <Collapse
              in={open}
              timeout={215}
              unmountOnExit
              addEndListener={handleEndAnimation}
            >
              <Box
                sx={{
                  border: '1px solid',
                  borderColor: 'divider2',
                  borderRadius: 2,
                  overflow: 'hidden',
                }}
              >
                <Stack
                  sx={{
                    backgroundColor: '#fff',
                    borderRadius: '5px',
                    px: 1,
                  }}
                >
                  <Tabs items={tabItems} />
                </Stack>
              </Box>
            </Collapse>
          </Box>
        </td>
      </tr>
    </>
  )
}
