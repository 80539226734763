import { Modal, Input, Button } from "antd";
import { useContext, useEffect, useRef, useState } from "react";
import Picker from '@emoji-mart/react';
import data from '@emoji-mart/data';
import { AuthContext } from "../../context/Auth/AuthContext";
import api from "../../services/api";
import { SmileOutlined, ArrowRightOutlined } from "@ant-design/icons";
import toastError from "../../errors/toastError";
import { useLocalStorage } from "../../hooks/useLocalStorage";

/**
 * @typedef {Object} MessageModalEditProps 
 * @property {boolean}  show
 * @property {(show: boolean)=>void} setShow
 * @property {any} message
 */

/**
 * @param {MessageModalEditProps} props  
 * @returns 
 */

export const MessageModalEdit = (props) => {
  const { user } = useContext(AuthContext);
  const inputRef = useRef(null);
  const emojiRef = useRef(null);
  const [inputEditedMessage, setInputEditeMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [showEmoji, setShowEmoji] = useState(false);
  const [signMessage] = useLocalStorage("signOption", true);

  useEffect(() => {
    if (!props.message.body) return;
    setInputEditeMessage(removeUserFromMessage(props.message.body));
  }, [props.message]);

  const removeUserFromMessage = (message) => {
    return String(message).replace(`*${user?.name}:*\n`, "");
  };

  const handleEditMessage = async () => {
    if (inputEditedMessage.trim() === "") return;
    setLoading(true);

    const message = {
      read: 1,
      fromMe: true,
      mediaUrl: "",
      body: signMessage
        ? `*${user?.name}:*\n${inputEditedMessage.trim()}`
        : inputEditedMessage.trim(),
      quotedMsg: null,
    };
    try {
      await api.put(`/messages/${props.message.id}`, message);
    } catch (err) {
      toastError(err);
    }
    setLoading(false);
    props.setShow(false);
  };

  const handleAddEmoji = (emoji) => {
    setInputEditeMessage((prevState) => prevState + emoji.native);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleEditMessage();
    }
  };

  const handleEscKey = (e) => {
    if (e.key === "Escape") {
      props.setShow(false);
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleEscKey);
    return () => window.removeEventListener("keydown", handleEscKey);
  }, []);

  const handleClickOutside = (event) => {
    if (emojiRef.current && !emojiRef.current.contains(event.target)) {
      setShowEmoji(false);
    }
  };

  useEffect(() => {
    if (showEmoji) {
      window.addEventListener("mousedown", handleClickOutside);
    } else {
      window.removeEventListener("mousedown", handleClickOutside);
    }
    return () => window.removeEventListener("mousedown", handleClickOutside);
  }, [showEmoji]);

  return (
    <Modal
      open={props.show}
      onCancel={() => props.setShow(false)}
      width={600}
      centered={false}
      footer={null}
      closeIcon={null}
      style={{ top: "70%", left: "22%" }}
    >
      <div style={{ display: "flex", alignItems: "center", position: "relative" }}>
        <Button
          icon={<SmileOutlined />}
          onClick={() => setShowEmoji((prevState) => !prevState)}
          disabled={loading}
          style={{ marginRight: 10 }}
        />
        {showEmoji && (
          <div
            ref={emojiRef}
            style={{
              position: "absolute",
              bottom: 60,
              left: "-22px",
              zIndex: 1000,
              boxShadow: "0 2px 10px rgba(0, 0, 0, 0.15)",
            }}
          >
            <Picker
              data={data}
              onEmojiSelect={handleAddEmoji}
              set="native"
              theme="light"
              icons="auto"
              emojiVersion="15.0"
              locale="pt"
              autoFocus={false}
              navPosition="top"
              previewPosition="none"
              searchPosition="none"
              skinTonePosition="none"
            />
          </div>
        )}

        <Input.TextArea
          ref={inputRef}
          autoSize={{ minRows: 1 }}
          value={inputEditedMessage}
          onChange={(e) => setInputEditeMessage(e.target.value)}
          onPressEnter={handleKeyPress}
          disabled={loading}
          style={{ flex: 1, resize: "none", marginRight: 10 }}
        />

        <Button
          icon={<ArrowRightOutlined />}
          type="primary"
          onClick={handleEditMessage}
          disabled={loading}
        />
      </div>
    </Modal>
  );
};
