import React, { useState, useContext, useEffect, useCallback } from "react";
import { toast } from "react-toastify";
import { Button } from "antd";

import { green } from "@material-ui/core/colors";
import { Stack, Typography } from "@mui/material";
import { IconButton, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Edit, DeleteOutline } from "@material-ui/icons";

import { i18n } from "../../../../translate/i18n";
import toastError from "../../../../errors/toastError";
import { AuthContext } from "../../../../context/Auth/AuthContext";

import api from "../../../../services/api";

import MainHeader from "../../../../components/MainHeader";
import MainContainer from "../../../../components/MainContainer";
import TableRowSkeleton from "../../../../components/TableRowSkeleton";
import ConfirmationModal from "../../../../components/ConfirmationModal";
import ConnectionGroupModal from "../../../../components/ConnectionGroupModal";
import MainHeaderButtonsWrapper from "../../../../components/MainHeaderButtonsWrapper";
import { TBody, THead, Table, TCell, TRow } from "../../../../components/Table";

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    overflowY: "hidden",
    backgroundColor: "#fff",
    borderRadius: "10px",
    ...theme.scrollbarStyles,
  },
  customTableCell: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: theme.typography.pxToRem(14),
    border: "1px solid #dadde9",
    maxWidth: 450,
  },
  tooltipPopper: {
    textAlign: "center",
  },
  buttonProgress: {
    color: green[500],
  },
}));

const Groups = () => {
  const classes = useStyles();
  const { user } = useContext(AuthContext);

  const [groupModalOpen, setGroupModalOpen] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [groups, setGroups] = useState([]);

  const confirmationModalInitialState = {
    action: "",
    title: "",
    message: "",
    groupId: "",
    open: false,
  };
  const [confirmModalInfo, setConfirmModalInfo] = useState(
    confirmationModalInitialState
  );

  useEffect(() => {
    (async () => {
      await loadGroups();
    })();
  }, []);

  const loadGroups = async () => {
    try {
      setLoading(true);

      const { data } = await api.get("/connectionGroups/");

      setGroups(data);
    } catch (err) {
      toastError(
        "Não foi possível carregar os grupos. Tente novamente mais tarde."
      );
    } finally {
      setLoading(false);
    }
  };

  const renderConenctionCount = (group) => {
    const count = group?.childrens?.length;

    if (!count) return 0;

    return count;
  };

  const renderConenctionEnabledCount = (group) => {
    const childrens = group?.childrens || [];

    if (!childrens?.length) return 0;

    let totalCount = 0;

    for (const children of childrens) {
      if (children?.whatsapp?.status !== "CONNECTED") continue;

      totalCount++;
    }

    return totalCount;
  };

  const renderConenctionDisabledCount = (group) => {
    const childrens = group?.childrens || [];

    if (!childrens?.length) return 0;

    let totalCount = 0;

    for (const children of childrens) {
      if (children?.whatsapp?.status === "CONNECTED") continue;

      totalCount++;
    }

    return totalCount;
  };

  const handleEditGroup = (group) => {
    setSelectedGroup(group);
    setGroupModalOpen(true);
  };

  const handleOpenGroupModal = useCallback(() => {
    setSelectedGroup(null);
    setGroupModalOpen(true);
  }, [setSelectedGroup, setGroupModalOpen]);

  const handleCloseGroupModal = useCallback(() => {
    setGroupModalOpen(false);
    setSelectedGroup(null);
  }, [setSelectedGroup, setGroupModalOpen]);

  const handleSuccessGroupModal = useCallback(() => {
    (async () => {
      await loadGroups();
    })();
  }, []);

  const handleOpenConfirmationModal = (action, groupId) => {
    if (action === "delete") {
      setConfirmModalInfo({
        action: action,
        title: i18n.t("connections.confirmationModal.deleteTitle"),
        message: i18n.t("connections.confirmationModal.deleteMessage"),
        groupId: groupId,
      });
    }
    setConfirmModalOpen(true);
  };

  const handleSubmitConfirmationModal = async () => {
    if (confirmModalInfo.action === "delete") {
      try {
        await api.delete(`/connectionGroups/${confirmModalInfo.groupId}`);
        toast.success(i18n.t("connections.toasts.deleted"));

        (async () => {
          await loadGroups();
        })();
      } catch (err) {
        toastError(err);
      }
    }

    setConfirmModalInfo(confirmationModalInitialState);
  };

  return (
    <MainContainer>
      <ConfirmationModal
        title={confirmModalInfo.title}
        open={confirmModalOpen}
        onClose={setConfirmModalOpen}
        onConfirm={handleSubmitConfirmationModal}
      >
        {confirmModalInfo.message}
      </ConfirmationModal>
      <ConnectionGroupModal
        open={groupModalOpen}
        onSuccess={handleSuccessGroupModal}
        onClose={handleCloseGroupModal}
        group={selectedGroup}
        loading={loading}
        setLoading={setLoading}
      />
      <Paper
        className={classes.mainPaper}
        style={{ overflow: "hidden" }}
        variant="outlined"
      >
        <MainHeader>
          <Stack>
            <Typography
              variant="h5"
              color="black"
              style={{
                fontWeight: "bold",
                marginLeft: "10px",
                marginTop: "10px",
              }}
              gutterBottom
            >
              {i18n.t("groups.title")}
            </Typography>
            <Typography
              style={{ marginTop: "-10px", marginLeft: "10px" }}
              variant="caption"
              color="textSecondary"
            >
              Agrupe suas conexões para agilizar a criação de novas campanhas.
            </Typography>
          </Stack>

          <MainHeaderButtonsWrapper>
            <Button
              type="primary"
              style={{ marginTop: 10 }}
              size="large"
              onClick={handleOpenGroupModal}
            >
              Novo grupo
            </Button>
          </MainHeaderButtonsWrapper>
        </MainHeader>
        <Stack
          style={{
            overflowY: "auto",
            padding: "20px",
            backgroundColor: "#fff",
            borderRadius: "5px",
            height: "93%",
          }}
        >
          <Paper
            style={{
              boxShadow: "none",
              backgroundColor: "#fff",
            }}
          >
            <Table size="small">
              <THead sticky="true">
                <TRow style={{ color: "#fff" }}>
                  <TCell style={{ color: "#fff" }} align="center">
                    {i18n.t("groups.table.name")}
                  </TCell>
                  <TCell style={{ color: "#fff" }} align="center">
                    {i18n.t("groups.table.count")}
                  </TCell>
                  {user.profile === "admin" && (
                    <TCell style={{ color: "#fff" }} align="center">
                      {i18n.t("groups.table.enabled")}
                    </TCell>
                  )}
                  <TCell style={{ color: "#fff" }} align="center">
                    {i18n.t("groups.table.disabled")}
                  </TCell>

                  {user.profile === "admin" && (
                    <TCell style={{ color: "#fff" }} align="center">
                      {i18n.t("connections.table.actions")}
                    </TCell>
                  )}
                </TRow>
              </THead>
              <TBody>
                {loading ? (
                  <TableRowSkeleton />
                ) : (
                  <>
                    {!!groups?.length &&
                      groups.map((group) => (
                        <TRow key={group.id}>
                          <TCell align="center">{group?.name}</TCell>
                          <TCell align="center">
                            {renderConenctionCount(group)}
                          </TCell>
                          <TCell align="center">
                            {renderConenctionEnabledCount(group)}
                          </TCell>
                          <TCell align="center">
                            {renderConenctionDisabledCount(group)}
                          </TCell>
                          {user.profile === "admin" && (
                            <TCell align="center">
                              <IconButton
                                size="small"
                                onClick={() => handleEditGroup(group)}
                              >
                                <Edit />
                              </IconButton>

                              <IconButton
                                size="small"
                                onClick={(e) => {
                                  handleOpenConfirmationModal(
                                    "delete",
                                    group.id
                                  );
                                }}
                              >
                                <DeleteOutline />
                              </IconButton>
                            </TCell>
                          )}
                        </TRow>
                      ))}
                  </>
                )}
              </TBody>
            </Table>
          </Paper>
        </Stack>
      </Paper>
    </MainContainer>
  );
};

export default Groups;
