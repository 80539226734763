import React, { useState, useEffect, useContext } from "react";
import { Modal } from "antd";
import { Divider, Stack, Typography, Grid, Box } from "@mui/material";
import { PlansConstant } from "./Plans";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import SelectPlanStep from "./Steps/PlanSelect";
import PaymentStep from "./Steps/Payment";
import { AuthContext } from "../../context/Auth/AuthContext";
import { toast } from "react-toastify";

const steps = [ "Seleção do plano"];


const SelectPlanModal = ({ open, onClose, plan, method }) => {
  const [focus, setFocus] = useState("");
  const [loading, setLoading] = useState(false);
  const [planSelected, setPlanSelected] = useState();
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});
	const { user } = useContext(AuthContext);
	const { companyId } = user;


  const renderTitle = () => {
    return (
      <Stack >
        <Typography variant="h6" sx={{ ml: 1 }}>
          {method === "upgrade" ? "Atualizar plano" : "Novo plano"}
        </Typography>
        <Typography variant="caption" sx={{ ml: 1 }}>
          Selecione um plano abaixo
        </Typography>
      </Stack>
    );
  };

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };



  const handleClose = () => {
    setPlanSelected(null);
    setActiveStep(0);
    onClose();
  };
  const handleConfirm = () => {
    setPlanSelected(null);
    setActiveStep(0);
    onClose();
    toast.success("Plano atualizado com sucesso!");
  };

  const getStepContent = step => {
    switch (step) {
      case 0:
        return (
          <SelectPlanStep
            handleNext={handleConfirm}
            setPlan={setPlanSelected}
            company={companyId}
            plan={plan}
            method={method}
          />
        )
        case 1:
          return (
            <PaymentStep
              handleFinish={handleConfirm}
              plan={planSelected}
              company={companyId}
              method={method}
            />
          )
     
    }
  }

  useEffect(() => {
    if (!plan) return;
    setPlanSelected(PlansConstant.find(item => item.name === plan));
    return () => {
      setPlanSelected(null);
    };
  }, [plan]);




  return (
    <Modal
   
      open={open}
      onCancel={handleClose}
      footer={null}
      width="1400px"
    >

      <SelectPlanStep
            handleNext={handleConfirm}
            setPlan={setPlanSelected}
            company={companyId}
            plan={plan}
            method={method}
          />
    </Modal>
  );
};

export default SelectPlanModal;
