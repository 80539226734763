import { Divider, Grid, IconButton, Paper, Typography } from "@mui/material"
import { Box } from "@mui/system"
import { Position, NodeProps } from "react-flow-renderer"
import { Container, HandleContainer, Handle, Tag } from "./styled"
import Node from "../../StyleNodes/Node"

export { SaveMessageMenu } from "./menu"

const handleStyle = { left: 10 }

const options = [{
  value: "nome",
  label: "Nome completo",
},{
  value: "primeiroNome",
  label: "Primeiro nome",
} ,{
  value: "sobrenome",
  label: "Sobrenome",
},{
  value: "cpf",
  label: "CPF",
}, {
  value: "cnpj",
  label: "CNPJ",
},
{
  value: "endereco",
  label: "Endereço",
},
{
  value: "telefone",
  label: "Telefone",
},
{
  value: "email",
  label: "Email",
}, {
  value: "dataNascimento",
  label: "Data de nascimento",
},{
  value: "dataCompleta",
  label: "Data",
},
{
  value: "dataHora",
  label: "Data e hora"
},
{
  value: "saudacao",
  label: "Saudação"
}]


const SaveMessageNode = (node) => {
  const { data } = node


  return (

    <Node node={node}>
    <Grid
     container
     style={{ position: "relative" }}
     spacing={1}
     px={2}
     mt={0}
     alignItems="center"
   >
     <Grid item sx={{ mb: 1 }}>
       <Typography variant="caption" color="gray">
          {data?.messageType ? options.find((item) => item.value === data?.messageType).label : "Escolha o tipo de mensagem"}
       </Typography>
     </Grid>
     <Grid item>
     <HandleContainer>
        <Handle
          type="source"
          position={Position.Right}
          id="next"
          color={data.payload?.port.next.color}
        />
      </HandleContainer>
     </Grid>
   </Grid>
  

 </Node >
  )
}

export default SaveMessageNode
