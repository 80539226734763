import { Grid, Switch, Typography, useTheme } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { BuilderContext } from "../..";
import { Drawer, Button , Modal} from "antd";
import "./style.css";
const NodeMenu = ({
  node,
  open,
  children,
  optionalContinue,
  values,
  onChange,
  onRun,
  height,
  margin,
  anchorRef,
  size
}) => {

  useEffect(()=>{
    if(node?.data?.payload?.dontChange) return
  },[node?.data?.payload?.dontChange])

  const theme = useTheme();
  const { closeMenu, saveNode } = useContext(BuilderContext);

  const handleClose = () => {
    saveNode({ ...node, data: values });
    closeMenu();
  };

  const handleSave = () => {
    saveNode({ ...node, data: values });
    closeMenu();
  };

  const handleChangeSwitch = (ev, value) => {
    !values.payload.port && (values.payload.port = {});
    if (ev.target.name === "next") {
      const payload = values.payload;
      if (!value) {
        delete payload.port.next;
        onChange({ target: { name: "payload", value: payload } });
      } else {
        payload.port.next = { color: payload.category.color };
        onChange({ target: { name: "payload", value: payload } });
      }
    }
    onChange({ target: { name: ev.target.name, value } });
  };

  return (
    <Modal 
      open 
      width={size ? size : 650} 
      height={height}
      style={{
        overflow: "hidden",
      }}
      onClose={handleClose} 
      okText="Salvar"
      onCancel={handleClose}
      onOk={handleSave}
    >
      <Grid container className="fullHeight" style={{ overflow: "hidden" }}>
        <Grid item xs className="fullHeight">
          <Grid
            container
            direction="column"
            className="fullHeight"
            wrap="nowrap"
          >
            {children}

            <Grid
              container
              justifyContent="flex-end"
              alignItems="center"
              spacing={1}
              sx={{
                p: 2
              }}
            >
              {optionalContinue && (
                <Grid item xs>
                  <Grid container alignItems="center">
                    <Grid item>
                      <Switch
                        size="small"
                        name="next"
                        checked={values.next}
                        value={values.next}
                        onChange={handleChangeSwitch}
                      />
                    </Grid>
                    <Grid item xs>
                      <Typography fontWeight="bold">Continuar fluxo</Typography>
                      <Typography variant="subtitle2">
                        Após processar este nó, o fluxo continuará para o
                        próximo nó vinculado
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default NodeMenu;
