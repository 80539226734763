import {
    Typography,
    Stack,
    Divider,
    Button,
    Box,
    Grid,
    IconButton,
    Avatar
  } from "@mui/material";
  
  import FacebookIcon from "../../../../assets/img/channels/facebook.svg";
  import WhatsAppIcon from "../../../../assets/img/channels/whatsapp.svg";
  import TelegramIcon from "../../../../assets/img/channels/telegram.svg";
  import InstagramIcon from "../../../../assets/img/channels/instagram.svg";
  import Gmail from "../../../../assets/img/channels/gmail.png";
  import Outlook from "../../../../assets/img/channels/owa.png";
  import { Bolt, Check, WhatsApp } from "@mui/icons-material";
  
  export default function EnterPrisePlan({
    onSelect
  }) {
    return (
      <Stack
        sx={{
          backgroundColor: "#FFFFFF",
          borderRadius: "10px",
          padding: 3,
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.05)",
          width: "300px",
          height: "580px"
        }}
        spacing={1}
        justifyContent="space-between"
      >
        <Stack>

      
        <Stack direction="row" spacing={2} alignItems="center">
          <Stack
            sx={{
              borderRadius: "50%",
              backgroundColor: "#E07941",
              height: "50px",
              width: "50px"
            }}
            justifyContent="center"
            alignItems="center"
          >
            <Bolt
              sx={{
                color: "#FFFFFF",
                fontSize: "40px"
              }}
            />
          </Stack>
          <Typography variant="h5" color="text.secondary" fontWeight="bold" >
            ETERPRISE
          </Typography>
        </Stack>
        <Typography variant="h4" sx={{mt: 2}}>SOB CONSULTA</Typography>
        <Divider sx={{ my: 2 }} />
        <Typography variant="body1">Plano personalizado para as necessidades de sua empresa. Consulte nosso time.</Typography>


        </Stack>

  
   
        <Stack
            onClick={onSelect}
            sx={{
              mt: "20px",
              cursor: "pointer",
              backgroundColor: "#E07941",
              color: "#FFFFFF",
              height: "40px",
              width: "100%",
              borderRadius: "10px"
            }}
            justifyContent="center"
            alignItems="center"
          >
            <Typography variant="body1" color="white" fontWeight="bold">
              SOLICITAR CONTATO
            </Typography>
          </Stack>
      </Stack>
    );
  }
  