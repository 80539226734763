import React, { useContext } from "react";
import { Route as RouterRoute, Redirect } from "react-router-dom";

import { AuthContext } from "../context/Auth/AuthContext";
import BackdropLoading from "../components/BackdropLoading";

const Route = ({
  component: Component,
  isPrivate = false,
  isAdmin = false,
  isSuper = false,
  ...rest
}) => {
  const { user, isAuth, loading } = useContext(AuthContext);

  const nowPath = rest.path;
  const routerPath = window.location.pathname;

  if (!isAuth && isPrivate) {
    return (
      <>
        {loading && <BackdropLoading />}
        <Redirect to={{ pathname: "/login", state: { from: rest.location } }} />
      </>
    );
  }

  const homePath = user.profile === "admin" ? "/" : "/tickets";

  if (isAuth && !isPrivate) {
    return (
      <>
        {loading && <BackdropLoading />}
        <Redirect to={{ pathname: homePath, state: { from: rest.location } }} />
      </>
    );
  }

  if (loading) {
    return <BackdropLoading />;
  }

  if (isAdmin && user.profile !== "admin") {
    return (
      <>
        {loading ? (
          <BackdropLoading />
        ) : (
          nowPath === routerPath && (
            <Redirect
              to={{ pathname: homePath, state: { from: rest.location } }}
            />
          )
        )}
      </>
    );
  }

  if (isSuper && !user.super) {
    return (
      <>
        {loading ? (
          <BackdropLoading />
        ) : (
          nowPath === routerPath && (
            <Redirect
              to={{ pathname: homePath, state: { from: rest.location } }}
            />
          )
        )}
      </>
    );
  }

  return (
    <>
      {loading ? (
        <BackdropLoading />
      ) : (
        <RouterRoute {...rest} component={Component} />
      )}
    </>
  );
};

export default Route;
