import { Avatar, Divider, Grid, IconButton, Paper, Typography, Stack ,Box,Popover} from "@mui/material"
import { useCallback, useContext, useEffect, useRef, useState } from "react"
import moment from "moment"
import { Container, HandleContainer, Handle, Tag } from "./styled"
import Node from "../../StyleNodes/Node"
import { BuilderContext } from "../.."
import { AuthContext } from "../../../../../../context/Auth/AuthContext"
import TextArea from "antd/es/input/TextArea"
import { v4 as uuid4 } from "uuid"
import 'moment/locale/pt-br'
import { CloseOutlined, DeleteOutline, Send } from "@mui/icons-material"

const AnnotationNode = (node) => {
  const [isShown, setIsShown] = useState(false);
  const [anchorEl, setAnchorEl] = useState();
  const hoverRef = useRef(null)
  const containerRef = useRef(null)
  const { user } = useContext(AuthContext);

  const { closeMenu, deleteNode, saveNode } = useContext(BuilderContext)
  const [comment, setComment] = useState('')
  const { data } = node

  useEffect(() => {
    if (node.data?.active) {
      setAnchorEl(containerRef.current)
    }
  }, [])

  const handleSubmit = useCallback((e) => {
    e.preventDefault()
    saveNode({
      ...node,
      data: {
        comments: (node.data.comments || []).concat({
          id: uuid4(),
          user: user,
          comment,
          createdAt: new Date().toISOString()
        })
      }
    })
    setComment('')

  }, [node, comment, saveNode])



  const handleMouseEnter = () => {
    if (!hoverRef.current) { return }
    setIsShown(true)
    hoverRef.current.style.width = 'auto'
    hoverRef.current.style.opacity = '1'
    // hoverRef.current.style.display = 'inline-block'
  }


  const handleMouseLeave = () => {
    if (!hoverRef.current) { return }

    setIsShown(false)
    hoverRef.current.style.width = '0'
    hoverRef.current.style.opacity = '0'

    // hoverRef.current.style.display = 'none'
  }

  const handleOpenMenu = (ev) => {
    setAnchorEl(containerRef.current)
  }

  const handleCloseMenu = (ev) => {
    setAnchorEl(null)
    setIsShown(false)
    handleMouseLeave()
  }

  const handleDeleteNode = () => {
    deleteNode(node)
  }

  const renderLastComment = (comment) => {
    return (
      <Grid container wrap="nowrap" alignItems="flex-start">
        <Grid item>
          <Avatar src={comment.user?.picture} sx={{ mr: 1 }}>
            {comment.user?.name[0]}
          </Avatar>
        </Grid>
        <Grid item zeroMinWidth>
          <Stack>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography fontWeight="bold" noWrap>{comment.user.name}</Typography>
              <Typography color="text.secondary" fontSize={8} noWrap>{moment(comment.createdAt).locale('pt-br').fromNow()}</Typography>
            </Stack>
            <Typography color="text.secondary" fontSize={12} noWrap sx={{ marginTop: '-2px' }}>{comment.comment}</Typography>
          </Stack>
        </Grid>
      </Grid>
    )
  }

  const userBaloon = node?.data.comments.length ? node.data.comments[node.data.comments.length - 1].user : node?.data.createdBy
  const lastComment = node?.data.comments.length ? node.data.comments[node.data.comments.length - 1] : null

  return (
    // <Node node={node}>
    <Container ref={containerRef}>
      <Stack onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        direction="row"
        sx={{ height: 58 }}
      >
        {(!isShown || !!anchorEl) && (
          <Box onClick={handleOpenMenu} sx={{ p: 1, bgcolor: 'background.paper', cursor: 'pointer', borderRadius: '50%', borderBottomLeftRadius: 0 }}>
            <Avatar src={userBaloon.picture} >
              {userBaloon.name[0]}
            </Avatar>
          </Box>
        )}

        {!anchorEl && (<Box onClick={handleOpenMenu} ref={hoverRef} sx={{ p: 1, height: 58, overflow: 'hidden', width: 0, transition: 'width 200ms', opacity: 0, bgcolor: 'background.paper', cursor: 'pointer', borderRadius: 4, borderBottomLeftRadius: 0 }}>
          {
            lastComment ? renderLastComment(lastComment) : (
              <Grid container wrap="nowrap" alignItems="flex-start">
                <Grid item>

                  <Avatar src={user?.picture} sx={{ mr: 1 }}>
                    {userBaloon.name[0]}
                  </Avatar>
                </Grid>
                <Grid item zeroMinWidth>
                  <Stack>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Typography fontWeight="bold" noWrap>{userBaloon.name}</Typography>
                      <Typography color="text.secondary" fontSize={8} noWrap>{moment(lastComment?.createdAt || node.data.createdAt).fromNow()}</Typography>
                    </Stack>
                    <Typography color="text.secondary" fontSize={12} noWrap sx={{ marginTop: '-2px' }}>Clique para adicionar um comentário</Typography>
                  </Stack>
                </Grid>
              </Grid>
            )}
        </Box>
        )}
      </Stack>

      <Popover
        open={!!anchorEl}
        onClose={handleCloseMenu}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        sx={{ ml: 2 }}
      >
        <Box sx={{ width: 400 }}>
          <Grid container sx={{ px: 2, py: 1 }} alignItems="center" >
            <Grid item xs>
              <Typography>Comentários</Typography>
            </Grid>
            <Grid item>
              <IconButton size="small">
                <DeleteOutline onClick={handleDeleteNode} />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton size="small">
                <CloseOutlined onClick={handleCloseMenu} />
              </IconButton>
            </Grid>
          </Grid>
          <Divider sx={{ mx: 2 }} />

          <Stack spacing={2} sx={{ p: 2 }}>
            {node.data.comments?.map(it => (
              <Box>
                <Grid container key={it.id} spacing={1} wrap="nowrap">
                  <Grid item>
                    <Avatar src={it.user?.picture} sx={{ width: 30, height: 30 }} >
                      {it.user?.name[0]}
                    </Avatar>
                  </Grid>
                  <Grid item>
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <Typography fontWeight="bold">{it.user.name}</Typography>
                      <Typography color="text.secondary"> {moment(it.createdAt).fromNow()}</Typography>
                    </Stack>
                    {it.comment}
                  </Grid>
                </Grid>
              </Box>
            ))}

            {!node.data.comments?.length && (
              <Typography color="text.secondary" align="center">Nenhum comentário aqui.</Typography>
            )}
          </Stack>


          <Box sx={{ px: 2, pb: 2 }}>
            <form onSubmit={handleSubmit}>
              <Grid container alignItems="center" spacing={1}>
                <Grid item>
                  <Avatar src={user?.picture} sx={{ width: 30, height: 30 }} >
                    {user?.name[0]}
                  </Avatar>
                </Grid>
                <Grid item xs>
                  <TextArea
                    name="comment"
                    
                    placeholder="Faça um comentário"
                    
                    autoFocus
                    value={comment}
                    onChange={(ev) => { setComment(ev.target.value) }}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        handleSubmit(e)
                      }
                    }}
            
                  />
                </Grid>
                <Grid item>
                  <IconButton type="submit" >
                    <Send />
                  </IconButton>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Box>
      </Popover>
    </Container >

    // </Node>
  )
}

export default AnnotationNode
