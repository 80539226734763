import React from 'react'
import { Box, CircularProgress, Stack, Typography } from '@mui/material'
import IconFile from '../../IconFile'

export default function FileFormat({
    Icon, format, filename, loading, multiple
}) {
    return (
        <>
            <Stack sx={{ position: 'relative' }}>
                <IconFile
                    component={Icon}
                    sx={{
                        width: 70,
                        fontSize: 70,
                    
                        ...(loading && { opacity: 0.1 })
                    }}
                />
                {loading && (
                    <Stack
                        alignItems="center"
                        justifyContent="center"
                        className="fullHeight"
                        sx={{ position: 'absolute', top: 0, left: 0 }}
                    >
                        <CircularProgress size={70} />
                    </Stack>
                )}
            </Stack>

            <Stack alignItems="center" justifyContent="center" className="fullWidth">
                {filename
                    ? (
                        <Typography
                            display="block"
                            variant="h6"
                            color="primary"
                            pt={1}
                            noWrap
                            sx={{ maxWidth: 280 }}
                        >
                            {filename}
                        </Typography>
                    ) : (
                        <Typography>
                            Arraste e solte ou selecione os arquivos
                        </Typography>
                    )}
                <Typography color="text.secondary" variant="caption">
                   Arquivos permitidos: {format}
                </Typography>
            </Stack>
        </>
    )
}