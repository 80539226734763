import React, { useState, useEffect, useContext, useRef } from "react";

import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

import { head } from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import Chip from "@material-ui/core/Chip";
import IconButton from "@material-ui/core/IconButton";
import { i18n } from "../../translate/i18n";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import { Stack, Typography, Grid } from '@mui/material';

import api from "../../services/api";
import toastError from "../../errors/toastError";
import { Box, Divider, FormControl, Select, ListItemText, MenuItem } from "@material-ui/core";
import moment from "moment";
import { AuthContext } from "../../context/Auth/AuthContext";
import { isArray, capitalize } from "lodash";
import ConfirmationModal from "../ConfirmationModal";
import useScheduleMessages from "../../hooks/useScheduleMessages";
import { Input, Modal, Button } from "antd";
import ContactSelect from "../ContactSelect";
import TextArea from "antd/es/input/TextArea";


const filterOptions = createFilterOptions({
  trim: true,
});

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap"
  },

  multFieldLine: {
    display: "flex",
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(1)
    }
  },

  uploadButton: {
    display: "flex",
    flexWrap: "wrap"
  },

  uploadInput: {
    display: "none"
  },

  sendMessageIcons: {
    color: "grey"
  },

  chipsField: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    border: "1px solid rgba(0, 0, 0, 0.25)",
    borderRadius: 4,
    paddingTop: 8,
    paddingBottom: 8
  },

  chipBox: {
    margin: 2,
    height: 28
  },

  btnWrapper: {
    position: "relative"
  },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  }
}));

const ScheduleSchema = Yup.object().shape({
  body: Yup.string()
    .min(5, "Mensagem muito curta")
    .required("Obrigatório")
    .max(Infinity),
  sendAt: Yup.string().required("Obrigatório")
});

const ScheduleModal = ({
  open,
  onClose,
  scheduleData,
  contactId,
  cleanContact,
  reload
}) => {
  const classes = useStyles();
  const history = useHistory();
  const { user } = useContext(AuthContext);

  const initialState = {
    body: "",
    contactId: "",
    sendAt: moment().add(1, "hour").format("YYYY-MM-DDTHH:mm"),
    sentAt: ""
  };

  const initialContact = {
    id: "",
    name: ""
  };
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [schedule, setSchedule] = useState(initialState);
  const [currentContact, setCurrentContact] = useState(initialContact);
  const [attachment, setAttachment] = useState(null);
  const attachmentFile = useRef(null);
  const [selectedContact, setSelectedContact] = useState(null);



useEffect(()=>{
    if(scheduleData && scheduleData.contactId){
      setSelectedContact(scheduleData.contactId);
    }
},[scheduleData])


  useEffect(() => {
    if (open) {
      try {
        (async () => {
      
          if (!scheduleData?.id) return;

          const { data } = await api.get(`/schedules/${scheduleData?.id}`);
          setSchedule(prevState => {
            return {
              ...prevState,
              ...data,
              sendAt: moment(data.sendAt).format("YYYY-MM-DDTHH:mm")
            };
          });
          setCurrentContact(data.contact);
        })();
      } catch (err) {
        toastError(err);
      }
    }
  }, [scheduleData?.id, contactId, open, user]);

  const handleClose = () => {
    onClose();
    setAttachment(null);
    setSchedule(initialState);
  };

  const handleAttachmentFile = e => {
    const file = head(e.target.files);
    if (file) {
      setAttachment(file);
    }
  };

  const handleSaveSchedule = async values => {
    const scheduleData = { ...values, userId: user.id };
    try {
      if (scheduleData?.id) {
        await api.put(`/schedules/${scheduleData?.id}`, scheduleData);
        if (attachment != null) {
          const formData = new FormData();
          formData.append("file", attachment);
          await api.post(
            `/schedules/${scheduleData.id}/media-upload`,
            formData
          );
        }
      } else {
        const { data } = await api.post("/schedules", scheduleData);
        if (attachment != null) {
          const formData = new FormData();
          formData.append("file", attachment);
          await api.post(`/schedules/${data.id}/media-upload`, formData);
        }
      }
      toast.success(i18n.t("scheduleModal.success"));
      if (typeof reload == "function") {
        reload();
      }
      if (contactId) {
        if (typeof cleanContact === "function") {
          cleanContact();
          history.push("/schedules");
        }
      }
    } catch (err) {
      toastError(err);
    }
    setCurrentContact(initialContact);
    setSchedule(initialState);
    handleClose();
  };

  const deleteMedia = async () => {
    if (attachment) {
      setAttachment(null);
      attachmentFile.current.value = null;
    }

    if (schedule.mediaPath) {
      await api.delete(`/schedules/${schedule.id}/media-upload`);
      setSchedule(prev => ({
        ...prev,
        mediaPath: null
      }));
      toast.success(i18n.t("schedules.toasts.deleted"));
      if (typeof reload == "function") {
        reload();
      }
    }
  };

  const handleClick = typeName => {
    setSchedule(prev => {
      return { ...prev, body: prev.body + typeName };
    });
  };
  
  const renderTitle = () => {
    return <Stack   >
      <Typography variant="h6" sx={{ ml: 1 }}>
        {scheduleData?.id ? "Editar agendamento" : "Novo agendamento"}
      </Typography>
      <Typography variant="caption" sx={{ ml: 1 }}>
        {scheduleData?.id ? "Edite o agendamento" : "Agende uma nova mensagem"}
      </Typography>
    </Stack>
  }


  return (
    <div className={classes.root}>
      <ConfirmationModal
        title={i18n.t("campaigns.confirmationModal.deleteTitle")}
        open={confirmationOpen}
        onClose={() => setConfirmationOpen(false)}
        onConfirm={deleteMedia}
      >
        {i18n.t("campaigns.confirmationModal.deleteMessage")}
      </ConfirmationModal>
      <Modal
        title={renderTitle()}
        open={open}
        onCancel={handleClose}
        footer={null}
      >
        <div style={{ display: "none" }}>
          <input
            type="file"
            ref={attachmentFile}
            onChange={e => handleAttachmentFile(e)}
          />
        </div>
        <Divider sx={{ mt: 1,mb:2 }} />

        <Stack p={1}>
          <Formik
            initialValues={schedule}
            enableReinitialize={true}
            validationSchema={ScheduleSchema}
            onSubmit={(values, actions) => {
              setTimeout(() => {
                handleSaveSchedule({
                  ...values,
                  userId: user.id,
                  contactId: selectedContact,

                });
                actions.setSubmitting(false);
              }, 400);
            }}
          >
            {({ touched, errors, isSubmitting, values, handleSubmit, handleChange }) => (
              <Form>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Stack >
                      <Typography variant="caption" color="gray">
                        Contato *
                      </Typography>
                      <ContactSelect
                        selectedContactsId={selectedContact}
                        onChange={setSelectedContact}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={12}>
                    <Stack >
                      <Typography variant="caption" color="gray">
                        Mensagem *
                      </Typography>

                      <TextArea
                        rows={9}
                        onChange={handleChange}
                        value={values?.body}
                        multiline={true}
                        label={i18n.t("scheduleModal.form.body")}
                        name="body"
                      />

                    </Stack>
                  </Grid>
       
                  <Grid item xs={12}>
                  <Stack >
                      <Typography variant="caption" color="gray">
                        Data do agendamento *
                      </Typography>
                      <Input
                        type="datetime-local"
                        value={values?.sendAt}
                        onChange={handleChange}
                        size="large"
                        name="sendAt"
                      />
                  </Stack>
                    
                  </Grid>
                  {((schedule.mediaPath || attachment) !== "false") && ((schedule.mediaPath || attachment))   ? (
                    <Grid xs={12} item>
                      <Button startIcon={<AttachFileIcon />}>
                        {attachment != null
                          ? attachment?.name
                          : schedule.mediaName}
                      </Button>
                      <IconButton
                        onClick={() => setConfirmationOpen(true)}
                        color="secondary"
                      >
                        <DeleteOutlineIcon />
                      </IconButton>
                    </Grid>
                  ):null}
                </Grid>
                <DialogActions>
                  {!attachment && !schedule.mediaPath && (
                    <Button
                      type="primary"
                      onClick={() => attachmentFile.current.click()}
                      disabled={isSubmitting}
                      variant="outlined"
                    >
                      {i18n.t("schedules.dialogs.buttons.attach")}
                    </Button>
                  )}
                  <Button
                    onClick={handleClose}
                    color="secondary"
                    disabled={isSubmitting}
                    variant="outlined"
                  >
                    {i18n.t("scheduleModal.buttons.cancel")}
                  </Button>
                    <Button
                      type="primary"
                      color="primary"
                      onClick={handleSubmit}
                      disabled={isSubmitting}
                      variant="contained"
                      className={classes.btnWrapper}
                    >
                      {scheduleData?.id
                        ? `${i18n.t("scheduleModal.buttons.okEdit")}`
                        : `${i18n.t("scheduleModal.buttons.okAdd")}`}
                      {isSubmitting && (
                        <CircularProgress
                          size={24}
                          className={classes.buttonProgress}
                        />
                      )}
                    </Button>
           
                </DialogActions>
              </Form>
            )}
          </Formik>
        </Stack>
      </Modal>
    </div>
  );
};

export default ScheduleModal;
