import React, { useState, useCallback, useContext, useEffect } from "react";
import { toast } from "react-toastify";
import { format, parseISO } from "date-fns";

import { Stack, Typography, Avatar } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import {
  IconButton,
  Paper,
  Tooltip,
  CircularProgress,
} from "@material-ui/core";
import {
  Edit,
  SignalCellularConnectedNoInternet2Bar,
  SignalCellularConnectedNoInternet0Bar,
  SignalCellular4Bar,
  CropFree,
  DeleteOutline,
  Facebook,
  Instagram,
  WhatsApp,
  Telegram
} from "@material-ui/icons";
import { TBody, THead, Table, TCell, TRow } from "../../../../components/Table";
import FacebookLogin from '@greatsumini/react-facebook-login';
import { socketConnection } from "../../../../services/socket";

import MainContainer from "../../../../components/MainContainer";
import MainHeader from "../../../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../../../components/MainHeaderButtonsWrapper";
import TableRowSkeleton from "../../../../components/TableRowSkeleton";
import { AuthContext } from "../../../../context/Auth/AuthContext";
import { Dropdown, Button } from "antd";
import api from "../../../../services/api";
import WhatsAppModal from "../../../../components/WhatsAppModal";
import ConfirmationModal from "../../../../components/ConfirmationModal";
import QrcodeModal from "../../../../components/QrcodeModal";
import { i18n } from "../../../../translate/i18n";
import { WhatsAppsContext } from "../../../../context/WhatsApp/WhatsAppsContext";
import toastError from "../../../../errors/toastError";

import FacebookIcon from "../../../../assets/img/channels/facebook.svg"
import GupShupIcon from "../../../../assets/img/channels/gupshup.svg"

import WhatsAppIcon from "../../../../assets/img/channels/whatsapp.svg"
import TelegramIcon from "../../../../assets/img/channels/telegram.svg"
import InstagramIcon from "../../../../assets/img/channels/instagram.svg"
import Gmail from "../../../../assets/img/channels/gmail.png"
import Outlook from "../../../../assets/img/channels/owa.png"
import TelegramModal from "../../../../components/TelegramModal";
import GupshupModal from "../../../../components/GupshupModal";


const useStyles = makeStyles(theme => ({
  mainPaper: {
    flex: 1,
    overflowY: "hidden",
    backgroundColor: "#fff",
    borderRadius: "10px",
    ...theme.scrollbarStyles
  },
  customTableCell: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: theme.typography.pxToRem(14),
    border: "1px solid #dadde9",
    maxWidth: 450
  },
  tooltipPopper: {
    textAlign: "center"
  },
  buttonProgress: {
    color: green[500]
  }
}));

const CustomToolTip = ({ title, content, children }) => {
  const classes = useStyles();

  return (
    <Tooltip
      arrow
      classes={{
        tooltip: classes.tooltip,
        popper: classes.tooltipPopper
      }}
      title={
        <React.Fragment>
          <Typography gutterBottom color="inherit">
            {title}
          </Typography>
          {content && <Typography>{content}</Typography>}
        </React.Fragment>
      }
    >
      {children}
    </Tooltip>
  );
};

const IconChannel = channel => {
  switch (channel) {
    case "facebook":
      return <Facebook />;
    case "instagram":
      return <Instagram />;
    case "whatsapp":
      return <WhatsApp />;
      case "gupshup":
        return <GupShupIcon />;
      case "telegram":
        return <Telegram />;
    default:
      return "error";
  }
};

const Connections = () => {
  const classes = useStyles();
  const { user } = useContext(AuthContext);
  const { whatsApps, loading, dispatch } = useContext(WhatsAppsContext);
  const [whatsAppModalOpen, setWhatsAppModalOpen] = useState(false);
  const [qrModalOpen, setQrModalOpen] = useState(false);
  const [selectedWhatsApp, setSelectedWhatsApp] = useState(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const[telegramModal, setTelegramModal] = useState(false);
  const[gupshupModal, setGupshupModal] = useState(false);

  const confirmationModalInitialState = {
    action: "",
    title: "",
    message: "",
    whatsAppId: "",
    open: false
  };
  const [confirmModalInfo, setConfirmModalInfo] = useState(
    confirmationModalInitialState
  );

  useEffect(() => {
    const companyId = localStorage.getItem("companyId");
    const socket = socketConnection({ companyId });
    socket.on(`company-${companyId}-whatsapp`, (data) => {
      if (data.action === "update") {
        dispatch({ type: "UPDATE_WHATSAPPS", payload: data.whatsapp });
      }
    });

    socket.on(`company-${companyId}-whatsapp`, (data) => {
      if (data.action === "delete") {
        dispatch({ type: "DELETE_WHATSAPPS", payload: data.whatsappId });
      }
    });

    socket.on(`company-${companyId}-whatsappSession`, (data) => {
      if (data.action === "update") {
        dispatch({ type: "UPDATE_SESSION", payload: data.session });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, [dispatch]);

  const responseFacebook = response => {
    if (response.status !== "unknown") {
      const { accessToken, id } = response;

      api
        .post("/facebook", {
          facebookUserId: id,
          facebookUserToken: accessToken
        })
        .then(response => {
          toast.success(i18n.t("connections.facebook.success"));
        })
        .catch(error => {
          toastError(error);
        });
    }
  };

  const responseInstagram = response => {
    if (response.status !== "unknown") {
      const { accessToken, id } = response;

      api
        .post("/facebook", {
          addInstagram: true,
          facebookUserId: id,
          facebookUserToken: accessToken
        })
        .then(response => {
          toast.success(i18n.t("connections.facebook.success"));
        })
        .catch(error => {
          toastError(error);
        });
    }
  };

  const handleStartWhatsAppSession = async whatsAppId => {
    try {
      await api.post(`/whatsappsession/${whatsAppId}`);
    } catch (err) {
      toastError(err);
    }
  };

  const handleRequestNewQrCode = async whatsAppId => {
    try {
      await api.put(`/whatsappsession/${whatsAppId}`);
    } catch (err) {
      toastError(err);
    }
  };

  const handleOpenWhatsAppModal = () => {
    setSelectedWhatsApp(null);
    if(whatsApps?.length && whatsApps?.length >= user?.company?.plan?.connections) {
      toast.error("Limite de conexões atingido, faça upgrade do seu plano.",{
        onClick: () => {
          window.location.href = "/financeiro";
        }
      });
      return;
    }
    setWhatsAppModalOpen(true);
  };

  const handleOpenTelegramModal = () => {
    setTelegramModal(true);
  };

  const handleOpenGupshupModal = () => {
    setGupshupModal(true);
  };


  const handleCloseWhatsAppModal = useCallback(() => {
    setWhatsAppModalOpen(false);
    setSelectedWhatsApp(null);
  }, [setSelectedWhatsApp, setWhatsAppModalOpen]);


  const handleCloseTelegramModal = useCallback(() => {
    setTelegramModal(false);
  }, [setTelegramModal]);


  const handleCloseGupshupModal = useCallback(() => {
    setGupshupModal(false);
  }, [setGupshupModal]);

  const handleOpenQrModal = whatsApp => {
    setSelectedWhatsApp(whatsApp);
    setQrModalOpen(true);
  };

  const handleCloseQrModal = useCallback(() => {
    setSelectedWhatsApp(null);
    setQrModalOpen(false);
  }, [setQrModalOpen, setSelectedWhatsApp]);

  const handleEditWhatsApp = whatsApp => {
    setSelectedWhatsApp(whatsApp);
    setWhatsAppModalOpen(true);
  };

  const handleOpenConfirmationModal = (action, whatsAppId) => {
    if (action === "disconnect") {
      setConfirmModalInfo({
        action: action,
        title: i18n.t("connections.confirmationModal.disconnectTitle"),
        message: i18n.t("connections.confirmationModal.disconnectMessage"),
        whatsAppId: whatsAppId
      });
    }

    if (action === "delete") {
      setConfirmModalInfo({
        action: action,
        title: i18n.t("connections.confirmationModal.deleteTitle"),
        message: i18n.t("connections.confirmationModal.deleteMessage"),
        whatsAppId: whatsAppId
      });
    }
    setConfirmModalOpen(true);
  };

  const handleSubmitConfirmationModal = async () => {
    if (confirmModalInfo.action === "disconnect") {
      try {
        await api.delete(`/whatsappsession/${confirmModalInfo.whatsAppId}`);
      } catch (err) {
        toastError(err);
      }
    }

    if (confirmModalInfo.action === "delete") {
      try {
        await api.delete(`/whatsapp/${confirmModalInfo.whatsAppId}`);
        toast.success(i18n.t("connections.toasts.deleted"));
      } catch (err) {
        toastError(err);
      }
    }

    setConfirmModalInfo(confirmationModalInitialState);
  };

  const renderActionButtons = whatsApp => {
    return (
      <>
        {whatsApp.status === "qrcode" && (
          <Button
            size="small"
            variant="contained"
            type="primary"
            onClick={() => handleOpenQrModal(whatsApp)}
          >
            {i18n.t("connections.buttons.qrcode").toUpperCase()}
          </Button>
        )}
        {whatsApp.status === "DISCONNECTED" && (
          <>
            <Button
              size="small"
              variant="outlined"
              type="primary"
              onClick={() => handleStartWhatsAppSession(whatsApp.id)}
            >
              {i18n.t("connections.buttons.tryAgain").toUpperCase()}
            </Button>{" "}
            <Button
              size="small"
              variant="outlined"
              type="dashed"
              onClick={() => handleRequestNewQrCode(whatsApp.id)}
            >
              {i18n.t("connections.buttons.newQr").toUpperCase()}
            </Button>
          </>
        )}
        {(whatsApp.status === "CONNECTED" ||
          whatsApp.status === "PAIRING" ||
          whatsApp.status === "TIMEOUT") && (
            <Button
              size="small"
              variant="outlined"
              type="dashed"
              onClick={() => {
                handleOpenConfirmationModal("disconnect", whatsApp.id);
              }}
            >
              {i18n.t("connections.buttons.disconnect").toUpperCase()}
            </Button>
          )}
        {whatsApp.status === "OPENING" && (
          <Button size="small" variant="outlined" disabled type="default">
            {i18n.t("connections.buttons.connecting").toUpperCase()}
          </Button>
        )}
      </>
    );
  };

  const renderStatusToolTips = whatsApp => {
    return (
      <div className={classes.customTableCell}>
        {whatsApp.status === "DISCONNECTED" && (
          <CustomToolTip
            title={i18n.t("connections.toolTips.disconnected.title")}
            content={i18n.t("connections.toolTips.disconnected.content")}
          >
            <SignalCellularConnectedNoInternet0Bar color="secondary" />
          </CustomToolTip>
        )}
        {whatsApp.status === "OPENING" && (
          <CircularProgress size={24} className={classes.buttonProgress} />
        )}
        {whatsApp.status === "qrcode" && (
          <CustomToolTip
            title={i18n.t("connections.toolTips.qrcode.title")}
            content={i18n.t("connections.toolTips.qrcode.content")}
          >
            <CropFree />
          </CustomToolTip>
        )}
        {whatsApp.status === "CONNECTED" && (
          <CustomToolTip title={i18n.t("connections.toolTips.connected.title")}>
            <SignalCellular4Bar style={{ color: green[500] }} />
          </CustomToolTip>
        )}
        {(whatsApp.status === "TIMEOUT" || whatsApp.status === "PAIRING") && (
          <CustomToolTip
            title={i18n.t("connections.toolTips.timeout.title")}
            content={i18n.t("connections.toolTips.timeout.content")}
          >
            <SignalCellularConnectedNoInternet2Bar color="secondary" />
          </CustomToolTip>
        )}
      </div>
    );
  };


  const channels = [
    {
      label: <Stack direction="row" alignItems="center" onClick={handleOpenWhatsAppModal}>
        <Avatar src={WhatsAppIcon}  sx={{
          width: 40,
          height: 40,
          objectFit: "fill",
          background: 'background.default',
          border: 0,
          p: 1,
        }}
          imgProps={{
            style: {
              objectFit: "fill",
            },
          }} />
        <Typography variant="body2" sx={{ ml: 1 }}>
          WhatsApp
        </Typography>
      </Stack>,
      key: "whatsapp",
    },
    {
      label: <Stack direction="row" alignItems="center" onClick={handleOpenGupshupModal}>
        <Avatar src={GupShupIcon}  sx={{
          width: 40,
          height: 40,
          objectFit: "fill",
          background: 'background.default',
          border: 0,
          p: 1,
        }}
          imgProps={{
            style: {
              objectFit: "fill",
            },
          }} />
        <Typography variant="body2" sx={{ ml: 1 }}>
          WhatsApp (Gupshup)
        </Typography>
      </Stack>,
      key: "gupshup",
    },
    {
      label:   <FacebookLogin
      appId={process.env.REACT_APP_FACEBOOK_APP_ID}
      autoLoad={false}
      fields="name,email,picture"
      version="13.0"
      scope="public_profile,pages_messaging,pages_show_list,pages_manage_metadata,pages_read_engagement"
      callback={responseFacebook}
      render={renderProps => (
        <Stack direction="row" alignItems="center"  onClick={renderProps.onClick}>
        <Avatar src={FacebookIcon} sx={{
          width: 40,
          height: 40,
          objectFit: "fill",
          background: 'background.default',
          border: 0,
          p: 1,
        }}
          imgProps={{
            style: {
              objectFit: "fill",
            },
          }} />
        <Typography variant="body2" sx={{ ml: 1 }}>
          Messenger
        </Typography>
      </Stack>
      )}
    />,
      key: "messenger",
      disabled: false
    },
    {
      label:       <FacebookLogin
      appId={process.env.REACT_APP_FACEBOOK_APP_ID}
      autoLoad={false}
      fields="name,email,picture"
      version="13.0"
      scope="public_profile,instagram_basic,instagram_manage_messages,pages_messaging,pages_show_list,pages_manage_metadata,pages_read_engagement"
      callback={responseInstagram}
      render={renderProps => (
        <Stack direction="row" alignItems="center" onClick={renderProps.onClick}>
        <Avatar src={InstagramIcon} sx={{
          width: 40,
          height: 40,
          objectFit: "fill",
          background: 'background.default',
          border: 0,
          p: 1,
        }}
          imgProps={{
            style: {
              objectFit: "fill",
            },
          }} />
        <Typography variant="body2" sx={{ ml: 1 }}>
          Instagram
        </Typography>
      </Stack>
      )}
    />,
      key: "instagram",
      disabled: false
    },
    {
      label: <Stack direction="row" alignItems="center"  onClick={handleOpenTelegramModal}>
        <Avatar src={TelegramIcon} sx={{
          width: 40,
          height: 40,
          objectFit: "fill",
          background: 'background.default',
          border: 0,
          p: 1,
        }}
          imgProps={{
            style: {
              objectFit: "fill",
            },
          }} />
        <Typography variant="body2" sx={{ ml: 1 }}>
          Telegram
        </Typography>
      </Stack>,
      key: "telegram",
    },
    {
      label: <Stack direction="row" alignItems="center" >
        <Avatar src={Outlook} sx={{
          width: 40,
          height: 40,
          objectFit: "fill",
          background: 'background.default',
          border: 0,
          p: 1,
        }}
          imgProps={{
            style: {
              objectFit: "fill",
            },
          }} />
        <Typography variant="body2" sx={{ ml: 1 }}>
          Outlook
        </Typography>
      </Stack>,
      key: "outlook",
      disabled: true
    },
    {
      label: <Stack direction="row" alignItems="center" >
        <Avatar src={Gmail} sx={{
          width: 40,
          height: 40,
          objectFit: "fill",
          background: 'background.default',
          border: 0,
          p: 1,
        }}
          imgProps={{
            style: {
              objectFit: "fill",
            },
          }} />
        <Typography variant="body2" sx={{ ml: 1 }}>
          Gmail
        </Typography>
      </Stack>,
      key: "gmail",
      disabled: true
    },
  ]


  return (
    <MainContainer>
      <ConfirmationModal
        title={confirmModalInfo.title}
        open={confirmModalOpen}
        onClose={setConfirmModalOpen}
        onConfirm={handleSubmitConfirmationModal}
      >
        {confirmModalInfo.message}
      </ConfirmationModal>
      <QrcodeModal
        open={qrModalOpen}
        onClose={handleCloseQrModal}
        whatsAppId={!whatsAppModalOpen && selectedWhatsApp?.id}
      />
      <WhatsAppModal
        open={whatsAppModalOpen}
        onClose={handleCloseWhatsAppModal}
        whatsAppId={!qrModalOpen && selectedWhatsApp?.id}
      />

<TelegramModal
        open={telegramModal}
        onClose={handleCloseTelegramModal}
        telegramId={null}
      />

<GupshupModal
        open={gupshupModal}
        onClose={handleCloseGupshupModal}
        telegramId={null}
      />

      <Paper
        className={classes.mainPaper}
        style={{ overflow: "hidden" }}
        variant="outlined"
      >
        <MainHeader>
          <Stack>
            <Typography
              variant="h5"
              color="black"
              style={{
                fontWeight: "bold",
                marginLeft: "10px",
                marginTop: "10px"
              }}
              gutterBottom
            >
              {i18n.t("connections.title")}
            </Typography>
            <Typography
              style={{ marginTop: "-10px", marginLeft: "10px" }}
              variant="caption"
              color="textSecondary"
            >
              Conecte seus canais de atendimento para receber mensagens e
              iniciar conversas com seus clientes.
            </Typography>
          </Stack>

          <MainHeaderButtonsWrapper>
            <Dropdown menu={{ items: channels }} placement="bottom" trigger={["click"]} >
              <Button type="primary" style={{ marginTop: 10 }} size="large">Nova conexão</Button>
            </Dropdown>
          </MainHeaderButtonsWrapper>

        </MainHeader>
        <Stack
          style={{
            overflowY: "auto",
            padding: "20px",
            backgroundColor: "#fff",
            borderRadius: "5px",
            height: "93%"
          }}
        >
          <Paper style={{
            boxShadow: "none",
            backgroundColor: "#fff",
          }} >
            <Table size="small">
              <THead sticky="true">
                <TRow style={{ color: "#fff" }}>
                  <TCell style={{ color: "#fff" }} align="center">
                    Canal
                  </TCell>
                  <TCell style={{ color: "#fff" }} align="center">
                    {i18n.t("connections.table.name")}
                  </TCell>
                  <TCell style={{ color: "#fff" }} align="center">
                    {i18n.t("connections.table.status")}
                  </TCell>
                  {user.profile === "admin" && (
                    <TCell style={{ color: "#fff" }} align="center">
                      {i18n.t("connections.table.session")}
                    </TCell>
                  )}
                  <TCell style={{ color: "#fff" }} align="center">
                    {i18n.t("connections.table.lastUpdate")}
                  </TCell>
            
                  {user.profile === "admin" && (
                    <TCell style={{ color: "#fff" }} align="center">
                      {i18n.t("connections.table.actions")}
                    </TCell>
                  )}
                </TRow>
              </THead>
              <TBody>
                {loading ? (
                  <TableRowSkeleton />
                ) : (
                  <>
                    {whatsApps?.length > 0 &&
                      whatsApps.map(whatsApp => (
                        <TRow key={whatsApp.id}>
                          <TCell align="center">
                            {IconChannel(whatsApp.channel)}
                          </TCell>
                          <TCell align="center">{whatsApp?.name}</TCell>
                          <TCell align="center">
                            {renderStatusToolTips(whatsApp)}
                          </TCell>
                          {user.profile === "admin" && (
                            <TCell align="center">
                              {renderActionButtons(whatsApp)}
                            </TCell>
                          )}
                          <TCell align="center">
                            {format(
                              parseISO(whatsApp.updatedAt),
                              "dd/MM/yy HH:mm"
                            )}
                          </TCell>
                        
                          {user.profile === "admin" && (
                            <TCell align="center">
                              <IconButton
                                size="small"
                                onClick={() => handleEditWhatsApp(whatsApp)}
                              >
                                <Edit />
                              </IconButton>

                              <IconButton
                                size="small"
                                onClick={e => {
                                  handleOpenConfirmationModal(
                                    "delete",
                                    whatsApp.id
                                  );
                                }}
                              >
                                <DeleteOutline />
                              </IconButton>
                            </TCell>
                          )}
                        </TRow>
                      ))}
                  </>
                )}
              </TBody>
            </Table>
          </Paper>
        </Stack>
      </Paper>
    </MainContainer>
  );
};

export default Connections;
