import React, { useEffect, useRef, useState } from "react";
import { Select, Tag } from "antd";
import toastError from "../../errors/toastError";
import api from "../../services/api";

const { Option } = Select;

export function TagsContainer({ ticket, dataTags }) {
  const [tags, setTags] = useState([]);
  const [selecteds, setSelecteds] = useState([]);
  const isMounted = useRef(true);

  useEffect(() => {
    if (dataTags) {
      setTags((prevTags) => [...prevTags, ...dataTags]);
    }
  }, [dataTags]);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (isMounted.current) {
      loadTags().then(() => {
        if (Array.isArray(ticket?.tags)) {
          setSelecteds(ticket?.tags);
        } else {
          setSelecteds([]);
        }
      });
    }
  }, [ticket]);

  const createTag = async (data) => {
    try {
      const { data: responseData } = await api.post(`/tags`, data);
      return responseData;
    } catch (err) {
      toastError(err);
    }
  };

  const loadTags = async () => {
    try {
      const { data } = await api.get(`/tags/list`);
      setTags(data);
    } catch (err) {
      toastError(err);
    }
  };

  const syncTags = async (data) => {
    try {
      const { data: responseData } = await api.post(`/tags/sync`, data);
      return responseData;
    } catch (err) {
      toastError(err);
    }
  };

  const handleChange = async (value) => {
    let optionsChanged = [];
    for (let item of value) {
      if (tags.find((tag) => tag.name === item) === undefined) {
        const newTag = await createTag({ name: item });
        optionsChanged.push(newTag);
      } else {
        optionsChanged.push(tags.find((tag) => tag.name === item));
      }
    }
    await loadTags();
    setSelecteds(optionsChanged);
    await syncTags({ ticketId: ticket.id, tags: optionsChanged });
  };

  return (
    <div style={{ padding: 4, background: "#fff", borderRadius: 4 }}>
      <Select
        mode="tags"
        size="large"
        placeholder="Tags"
        value={selecteds.map(tag => tag.name)}
        onChange={handleChange}
        style={{ width: '100%' }}
        tagRender={(props) => {
          const { label, closable, onClose } = props;
          return (
            <div style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '30px',
              padding: '4px 8px',
              backgroundColor: '#F0F0F0',
              borderRadius: '5px',
              margin: '1px'
            }}>
              {label}
              {closable && <span onClick={onClose} style={{ marginLeft: '8px', cursor: 'pointer' }}>×</span>}
            </div>
          );
        }}
      >
        {tags.map((tag) => (
          <Option key={tag.name} value={tag.name}>
            <Tag color={tag.color || 'blue'}>{tag.name}</Tag>
          </Option>
        ))}
      </Select>
    </div>
  );
}
