import React, { useContext, useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { Button, Tooltip, Space, Dropdown, Menu, theme } from "antd";
import {
  MoreOutlined,
  ReloadOutlined,
  TagsOutlined,
  AndroidOutlined,
  IssuesCloseOutlined,
  CloseOutlined,
  UndoOutlined,
  ClockCircleOutlined,
  SwapOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
// import TagModal from "../TagModal";
import ConfirmationModal from "../ConfirmationModal";
import TransferTicketModalCustom from "../TransferTicketModalCustom";
import InvividualScheduleModal from "../IndividualScheduleModal";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";
import { TicketsContext } from "../../context/Tickets/TicketsContext";
import { Can } from "../Can";
// import { toast } from "react-toastify";

const { useToken } = theme;

const TicketActionButtonsCustom = ({ ticket, onTicketUpdate }) => {
  const history = useHistory();
  const { token } = useToken();
  const [loading, setLoading] = useState(false);
  const { user } = useContext(AuthContext);
  const { setCurrentTicket } = useContext(TicketsContext);

  // const [tagModalOpen, setTagModalOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [transferTicketModalOpen, setTransferTicketModalOpen] = useState(false);
  const [scheduleModalOpen, setScheduleModalOpen] = useState(false);
  const [contactId, setContactId] = useState(null);
  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  // const handleClearBot = async () => {
  //   if (!ticket) return;
  //   try {
  //     const data = {
  //       whatsappId: ticket?.whatsappId,
  //       ticketId: ticket?.id,
  //       contactNumber: ticket?.contact?.number,
  //     };
  //     await api.post(`/flows/clear`, data);
  //     toast.success(i18n.t("Bot limpo com sucesso!"));
  //   } catch (err) {
  //     toastError(err);
  //   }
  // };

  // const handleOpenTagModal = () => {
  //   setTagModalOpen(true);
  // };

  // const handleCloseTagModal = () => {
  //   setTagModalOpen(false);
  // };

  const handleCloseTicketOptionsMenu = () => {
    setMenuOpen(false);
  };

  const handleUpdateTicketStatus = async (e, status, userId) => {
    setLoading(true);
    try {
      await api.put(`/tickets/${ticket.id}`, {
        status: status,
        userId: userId || null,
        queueId: ticket.queueId,
        queue: ticket.queue
      });

      setLoading(false);
      if (status === "open") {
        setCurrentTicket({ ...ticket, code: "#open" });
      } else {
        setCurrentTicket({ id: null, code: null });
        history.push("/tickets");
      }
    } catch (err) {
      setLoading(false);
      toastError(err);
    }
  };

  const handleUpdateTicketStatusNoMessage = async (e, status, userId) => {
    setLoading(true);
    try {
      await api.put(`/tickets-nomessage/${ticket.id}`, {
        status: status,
        userId: userId || null,
      });

      setLoading(false);
      if (status === "open") {
        setCurrentTicket({ ...ticket, code: "#open" });
      } else {
        setCurrentTicket({ id: null, code: null });
        history.push("/tickets");
      }
    } catch (err) {
      setLoading(false);
      toastError(err);
    }
  };

  const handleDeleteTicket = async () => {
    try {
      await api.delete(`/tickets/${ticket.id}`);
    } catch (err) {
      toastError(err);
    }
  };

  const handleOpenConfirmationModal = () => {
    setConfirmationOpen(true);
    handleCloseTicketOptionsMenu();
  };

  const handleOpenTransferModal = () => {
    setTransferTicketModalOpen(true);
    handleCloseTicketOptionsMenu();
  };

  const handleCloseTransferTicketModal = () => {
    if (isMounted.current) {
      setTransferTicketModalOpen(false);
    }
  };

  const handleOpenScheduleModal = () => {
    handleCloseTicketOptionsMenu();
    setContactId(ticket.contact.id);
    setScheduleModalOpen(true);
  };

  const handleCloseScheduleModal = () => {
    setScheduleModalOpen(false);
    setContactId(null);
  };

  const renderDeleteOption = () => (
    <Can
      role={user.profile}
      perform="ticket-options:deleteTicket"
      yes={() => (
        <>
          <Menu.Divider style={{ margin: "4px 0" }} />
          <Menu.Item
            key="delete"
            icon={<DeleteOutlined />}
            onClick={handleOpenConfirmationModal}
            style={{ color: token.colorError }}
          >
            {i18n.t("ticketOptionsMenu.delete")}
          </Menu.Item>
        </>
      )}
    />
  );

  const menu = (
    <Menu>
      <Menu.Item
        key="schedule"
        icon={<ClockCircleOutlined />}
        onClick={handleOpenScheduleModal}
      >
        {i18n.t("ticketOptionsMenu.schedule")}
      </Menu.Item>
      <Menu.Item
        key="transfer"
        icon={<SwapOutlined />}
        onClick={handleOpenTransferModal}
      >
        {i18n.t("ticketOptionsMenu.transfer")}
      </Menu.Item>
      {user && user.profile && renderDeleteOption()}
    </Menu>
  );

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        width: "100%",
        backgroundColor: token.colorLight,
        borderRadius: "0 8px 0 0",
      }}
    >
      <Space
        size="small"
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          width: "100%",
          flexWrap: "wrap",
        }}
      >
        {ticket.status === "closed" && (
          <Button
            type="default"
            icon={<ReloadOutlined />}
            loading={loading}
            size="middle"
            style={{ marginRight: "12px" }}
            onClick={(e) => handleUpdateTicketStatus(e, "open", user?.id)}
          >
            {i18n.t("messagesList.header.buttons.reopen")}
          </Button>
        )}
        {ticket.status === "open" && (
          <>
            {/* <TagModal
              onTicketUpdate={onTicketUpdate}
              open={tagModalOpen}
              onClose={handleCloseTagModal}
              aria-labelledby="form-dialog-title"
            /> */}
            {/* <Tooltip title={"Limpar dados do bot"}>
              <Button
                type="default"
                icon={<AndroidOutlined />}
                size="middle"
                onClick={handleClearBot}
              ></Button>
            </Tooltip> */}
            {/* <Tooltip title={i18n.t("messagesList.header.buttons.tags")}>
              <Button
                type="default"
                icon={<TagsOutlined />}
                size="middle"
                onClick={handleOpenTagModal}
              ></Button>
            </Tooltip> */}
            <Tooltip title={i18n.t("messagesList.header.buttons.return")}>
              <Button
                type="default"
                icon={<UndoOutlined />}
                size="middle"
                onClick={(e) => handleUpdateTicketStatus(e, "pending", null)}
              />
            </Tooltip>
            <Tooltip title={i18n.t("Resolver sem mensagem de finalização")}>
              <Button
                type="default"
                icon={<IssuesCloseOutlined style={{ color: token.colorLight }} />}
                size="middle"
                onClick={(e) =>
                  handleUpdateTicketStatusNoMessage(e, "closed", user?.id)
                }
                style={{ backgroundColor: token.colorNotification }}
              />
            </Tooltip>
            <Tooltip title={i18n.t("messagesList.header.buttons.resolve")}>
              <Button
                type="default"
                icon={<CloseOutlined style={{ color: token.colorLight }} />}
                size="middle"
                onClick={(e) => handleUpdateTicketStatus(e, "closed", user?.id)}
                style={{ backgroundColor: token.colorError }}
              />
            </Tooltip>
            <Dropdown
              overlay={menu}
              trigger={["click"]}
              open={menuOpen}
              onOpenChange={(open) => setMenuOpen(open)}
              getPopupContainer={() => document.body}
              placement="bottomLeft"
            >
              <Button icon={<MoreOutlined />} style={{ marginRight: "12px" }} />
            </Dropdown>
          </>
        )}
        {ticket.status === "pending" && (
          <Button
            type="default"
            size="middle"
            loading={loading}
            style={{ marginRight: "8px" }}
            onClick={(e) => handleUpdateTicketStatus(e, "open", user?.id)}
          >
            {i18n.t("messagesList.header.buttons.accept")}
          </Button>
        )}
      </Space>
      <ConfirmationModal
        title={`${i18n.t("ticketOptionsMenu.confirmationModal.title")} ${ticket.contact?.name}?`}
        open={confirmationOpen}
        onClose={() => setConfirmationOpen(false)}
        onConfirm={handleDeleteTicket}
      >
        {i18n.t("ticketOptionsMenu.confirmationModal.message")}
      </ConfirmationModal>
      <TransferTicketModalCustom
        modalOpen={transferTicketModalOpen}
        onClose={handleCloseTransferTicketModal}
        ticketid={ticket.id}
      />
      <InvividualScheduleModal
        open={scheduleModalOpen}
        onClose={handleCloseScheduleModal}
        aria-labelledby="form-dialog-title"
        contactId={contactId}
      />
    </div>
  );
};

export default TicketActionButtonsCustom;
