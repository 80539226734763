
export const NodesCategories = {
    trigger: {
      color: '#8e44ad',
      title: "Gatilho",
    },
    integration: {
      color: '#1E88E5',
      title: "Avançado",
    },
    automation: {
      color: '#508991',
      title: "Automação",
    },
    answer: {
      color: '#5FD07E',
      title: "Resposta",
    },
    attendance: {
      color: '#FF9800',
      title: "Atendimento",
    },
    action: {
      color: '#37e3a7',
      title: "Ação",
    },
    flow: {
      color: '#26C6DA',
      title: "Lógica"
    },
  }
  
  export default NodesCategories
  