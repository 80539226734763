import { Position } from "react-flow-renderer";
import { Chip, Divider, Grid, Stack, Typography } from "@mui/material";
import { i18n } from "../../../../../../translate/i18n";

import { HandleContainer, Handle } from "./styled";
import Node from "../../StyleNodes/Node";
export { StartData } from "./data";
export { StartMenu } from "./menu";

const StartNode = (node) => {
  return (
    <Node node={node}>
      <Grid
        container
        style={{ position: "relative" }}
        spacing={1}
        px={2}
        mt={0}
        alignItems="center"
      >
        <Grid item sx={{ mb: 1 }}>
          {node?.data?.daysOfWeek?.length > 0 ? (
            <>
              {node?.data?.daysOfWeek.map((day, index) => (
                <Chip
                  size="small"
                  label={i18n.t(day)}
                  color="primary"
                  sx={{ mr: 0.5, mt: 0.5 }}
                />
              ))}
              <Divider sx={{ my: 1 }} />
              <Stack
                direction="row"
                spacing={0.5}
                alignItems="center"
                sx={{
                  ml: 0.5,
                  mt: 0.5,
                }}
              >
                <Typography variant="caption" color="primary">
                  Agendado para:
                </Typography>
                <Chip size="small" label={node?.data?.from} color="primary" />
              </Stack>
            </>
          ) : (
            "Inicio"
          )}
        </Grid>
        <Grid item>
          <HandleContainer>
            <Handle
              type="source"
              position={Position.Right}
              id="next"
              color="#0C3249"
            />
          </HandleContainer>
        </Grid>
      </Grid>
    </Node>
  );
};

export default StartNode;
