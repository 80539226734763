import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  useContext
} from "react"
import ReactFlow, {
  addEdge,
  applyNodeChanges,
  applyEdgeChanges,
  Background,
  MarkerType,
  BackgroundVariant,
  ReactFlowProvider,
  MiniMap,
  Controls,
} from "react-flow-renderer"
import { ClickAwayListener, Divider, Grid, MenuItem, MenuList, Paper, Popover, useTheme, Popper, Stack, Typography } from "@mui/material"
import { AuthContext } from "../../../../context/Auth/AuthContext";

import { v4 as uuidv4 } from "uuid"
import ConnectionLine from "./StyleNodes/ConnectionLine"
import CustomEdge from "./StyleNodes/Edge"
import { nodeTypes as CustomNodes, NodeMenu } from "./nodes"
import {toast} from "react-toastify"
import { utc } from "moment"
import Cookies from "js-cookie"
import cloneDeep from "lodash/cloneDeep"
import NodesCategories from "../Sidebar/ConstantNodes"
import shortid from "shortid"
import throttle from "lodash/throttle"
import { ChatBubble } from "@material-ui/icons"

shortid.characters('0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ_@');

const fitViewOptions = {
  padding: 0.2,
}

export const BuilderContext = createContext({
  openMenu: () => { },
  closeMenu: () => { },
  saveNode: () => { },
  deleteNode: () => { },
  flow: ''
})

const Diagram = ({ flow, validation, onChangeNodes, onChangeEdges , nodes, edges}) => {



  const {
    branch: {
      diagram: {
        nodes: initialNodes,
        edges: initialEdges,
        viewport: initialViewport,
        lastEvent: initialLastEvent,
      },
      stats
    },
  } = flow

  const [contextMenu, setContextMenu] = useState(null)
  const mousePosition = useRef()
  const reactFlowWrapper = useRef(null)
  const { user } = useContext(AuthContext);


  useEffect(() => {
    if (!reactFlowWrapper.current) return
    reactFlowWrapper.current.addEventListener("contextmenu", (event) => {
      event.preventDefault();
      setContextMenu({
        x: event.pageX,
        y: event.pageY
      })
    });
  }, [reactFlowWrapper?.current])




  const [configNode, setConfigNode] = useState(undefined)
  const nodeTypes = useMemo(() => CustomNodes, [])
  const edgeTypes = useMemo(
    () => ({
      custom: CustomEdge,
    }),
    []
  )

  const [reactFlowInstance, setReactFlowInstance] = useState()

  const onReactFlowInit = (instance) => {
    setReactFlowInstance(instance)
  }


  const onNodesChange = useCallback(
    async (changes, echo) => {
      const removingStart = changes.some(
        (change) =>
          change.type === "remove" &&
          nodes.find((node) => node.id === change.id)?.type === "start"
      )
      if (removingStart) {
        return
      }

      onChangeNodes((nds) => applyNodeChanges(changes, nds))
    },
    [onChangeNodes]
  )


  const onEdgesChange = useCallback(
    async (changes, echo) => {
      if (echo) {
        return onChangeEdges((eds) => {
          return applyEdgeChanges(changes, eds)
        })
      }
      const withAck = changes.find((it) => it.type === "remove")
      const changesToPublish = changes.filter((it) => it.type !== "select")
      if (withAck) {
        return onChangeEdges((nds) => applyEdgeChanges(changes, nds))
      }

      changesToPublish.length &&
      onChangeEdges((nds) => applyEdgeChanges(changes, nds))
    },
    [onChangeEdges]
  )

  const updateNode = useCallback(
    async (node, echo) => {
      node.selected = false
      if (echo) {
        return onChangeNodes((nds) =>
          nds.map((nd) => {
            if (nd.id === node.id) {
              nd.data = node.data
            }
            nd.selected = false
            return nd
          })
        )
      }

      onChangeNodes((nds) =>
        nds.map((nd) => {
          if (nd.id === node.id) {
            nd.data = node.data
          }

          nd.selected = false
          return nd
        })
      )
    },
    [onChangeNodes]
  )

  const onConnect = useCallback(

    async (connection) => {
      if (connection.source === connection.target) {
        toast.error("Você não pode conectar um nó a ele mesmo")
      }
      const currentNode = reactFlowInstance
        ?.getNodes()
        ?.find((it) => it.id === connection.source)
 
      const sourcePort = currentNode?.data.payload?.port[connection?.sourceHandle] 
   
      const newConnection = {
        ...connection,
        id: uuidv4(),
        style: { stroke: sourcePort.color },
        color: sourcePort.color,
        type: "custom",
        markerEnd: {
          type: MarkerType.ArrowClosed,
          color: sourcePort.color,
          width: 8,
          height: 8,
        },
      }
      onChangeEdges((eds) => {
        return addEdge(newConnection, eds)
      })
    },

    [onChangeEdges, reactFlowInstance]
  )

  const handleConnectStart = useCallback((event) => {
    reactFlowWrapper.current?.classList?.add("react-flow_connecting")
  }, [])

  const handleConnectStop = useCallback((event) => {
  }, [])

  const handleConnectEnd = useCallback((event) => {
    reactFlowWrapper.current?.classList?.remove("react-flow_connecting")
  }, [])

  const handleNodePosition = useCallback(
    async (_, nd, nds) => {
      const positionEvents = nds.map((nd) => ({
        type: "position",
        finished: true,
        id: nd.id,
        position: nd.position,
        positionAbsolute: nd.positionAbsolute,
      }))
    },
    []
  )

  const onDragOver = useCallback((event) => {
    event.preventDefault()
    event.dataTransfer.dropEffect = "move"
  }, [])

  const onDrop = useCallback(
    async (event) => {
      event.preventDefault()

      // @ts-ignore: Object is possibly 'null'.
      const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect()
      const node = JSON.parse(
        event.dataTransfer.getData("application/reactflow")
      )

      const { type } = node
      // check if the dropped element is valid
      if (typeof type === "undefined" || !type) {
        return
      }

      // @ts-ignore: Object is possibly 'null'.
      const position = reactFlowInstance.project({
        x: event.clientX - reactFlowBounds.left,
        y: event.clientY - reactFlowBounds.top,
      })

      const totalSameType = reactFlowInstance
        ?.getNodes()
        ?.filter((it) => it.type === type).length
      const newNode = {
        id: `${flow.path}-${shortid.generate()}`,
        type,
        position,
        data: {
          ...node.data,
          payload: {
            ...node.data?.payload,
            icon: node.icon,
            category: NodesCategories[node.category],
          },
          title: `${node.data.title}${totalSameType ? ` - ${totalSameType}` : ''}`
        },
      }

      onChangeNodes(prev => ([...prev, newNode]))
    },
    [reactFlowInstance]
  )


  const handleOpenMenu = (node) => {
    setConfigNode(cloneDeep(node))
  }

  const handleCloseMenu = () => {
    setConfigNode(undefined)
  }

  const handleSaveNode = (node) => {

    if (node.data?.port?.next && !node.data.next) {
      delete node.data.payload?.port.next
    } else if (node.data.next) {
      !node.data.payload.port && (node.data.payload.port = {})
      node.data.payload.port.next = {
        color: node.data.payload?.category.color
      }
    }

    updateNode(node)
    const removeEdges = []
    for (const edge of edges) {
      if (edge.source === node.id && edge.sourceHandle && !node.data.payload?.port[edge.sourceHandle]) {
        removeEdges.push({
          id: edge.id,
          type: 'remove'
        })
      }
    }
    onEdgesChange(removeEdges)
  }

  const handleDeleteNode = (node) => {
    onNodesChange([{ id: node.id, type: 'remove' }])
  }
  const handleMouseMove = (event) => {
    try{
      event.persist();
    }catch(e){
    }
    setTimeout(() => {
    const { clientX, clientY } = event; // Copia os valores necessários
    const position = reactFlowInstance?.project({ x: clientX, y: clientY });
    mousePosition.current = position;
    }, 80);
  }
  // const handleMouseMove = throttle(
  //   (event) => {
  //     event.persist();
  //     const { clientX, clientY } = event; // Copia os valores necessários
  //     const position = reactFlowInstance?.project({ x: clientX, y: clientY });
  //     mousePosition.current = position;
  //   },
  //   80
  // );
  

  const handleCopy = useCallback(() => {
    const selectedNodes = nodes.filter((node) => node.selected)
    const selectedEdges = edges.filter((node) => node.selected)
    Cookies.set("clipboard", JSON.stringify({ selectedEdges, selectedNodes }), {
      expires: utc().add(5, "minute").toDate(),
    })
  }, [nodes, edges])

  const handlePaste = useCallback(async () => {
    const clipboard = Cookies.get("clipboard")
    if (!clipboard) {
      return
    }

    const { selectedNodes, selectedEdges } = JSON.parse(clipboard)
    const idsMap = {}

    let position = {
      x: Number.MAX_SAFE_INTEGER,
      y: Number.MAX_SAFE_INTEGER,
    }

    for (const node of selectedNodes) {
      idsMap[node.id] = `${flow.path}-${shortid.generate()}`
      if (node.position.x < position.x) {
        position = node.position
      }
    }

    const offsetPosition = {
      x: mousePosition.current?.x - position?.x,
      y: mousePosition.current?.y - position?.y,
    }

    const nodesToAdd = selectedNodes.map((node) => ({
      ...node,
      position: {
        x: node.position?.x + offsetPosition?.x,
        y: node.position?.y + offsetPosition?.y,
      },
      id: idsMap[node.id],
    }))

    const edgesToAdd = selectedEdges
      .filter((edge) => idsMap[edge.source] && idsMap[edge.target])
      .map((edge) => ({
        ...edge,
        id: uuidv4(),
        source: idsMap[edge.source],
        target: idsMap[edge.target],
      }))

    if (nodesToAdd.length) {
      onChangeNodes((nds) => nds.concat(nodesToAdd))
    }

    if (edgesToAdd.length) {
      onChangeEdges((nds) => nds.concat(edgesToAdd))
    }
  }, [onChangeNodes, onChangeEdges])

  const handleShortcuts = useCallback(
    (ev) => {
      const ctrlDown = ev.ctrlKey || ev.metaKey
      if (!ctrlDown) {
        return
      }

      if (ev.keyCode === 67) {
        handleCopy()
      }

      if (ev.keyCode === 86) {
        handlePaste()
      }
      if (ev.keyCode === 90) {
        // remove las action
      

        // z
      }
      if (ev.keyCode === 89) {
        // redo last action
        // y
      }
    },
    [nodes, edges, onChangeNodes, onChangeEdges]
  )


  const handleAddComment = useCallback(async () => {

    const reactFlowBounds = reactFlowWrapper.current?.getBoundingClientRect()


    const position = reactFlowInstance.project({
      x: contextMenu.x - reactFlowBounds.left,
      y: contextMenu.y - reactFlowBounds.top,
    })

    const newNode = {
      id: `${flow.identifier}-${shortid.generate()}`,
      type: 'annotation',
      position,
      data: {
        comments: [],
        createdBy: user,
        createdAt: new Date()
      },
    }

    setContextMenu(null)
    onChangeNodes(nds => nds.concat(newNode))

  }, [reactFlowInstance, contextMenu])

  const MenuDialog = useMemo(() => {
    if (!configNode) {
      return <></>
    }

    const Menu = NodeMenu[configNode.type]
    return <Menu node={configNode} />
  }, [configNode])

  return (
    <BuilderContext.Provider
      value={{
        openMenu: handleOpenMenu,
        closeMenu: handleCloseMenu,
        saveNode: handleSaveNode,
        deleteNode: handleDeleteNode,
        validation,
        flow: flow.id,
        stats
      }}
    >
      <div
        className="reactflow-wrapper"
        ref={reactFlowWrapper}
        tabIndex={0}
        onKeyDown={handleShortcuts}
        style={{ height: "100%", position: 'relative', outline: 0 }}
      >
        <ReactFlowProvider>
          <ReactFlow
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onConnect={onConnect}
            deleteKeyCode={["Delete", "Backspace"]}
            nodeTypes={nodeTypes}
            edgeTypes={edgeTypes}
            onConnectStart={handleConnectStart}
            onConnectStop={handleConnectStop}
            onConnectEnd={handleConnectEnd}
            onNodeDragStop={handleNodePosition}
            onMouseMove={handleMouseMove}
            fitView
            onInit={onReactFlowInit}
            onDrop={onDrop}
            onDragOver={onDragOver}
            fitViewOptions={fitViewOptions}
            minZoom={0}
            connectionLineComponent={ConnectionLine}
          >
            <Background variant={BackgroundVariant.Lines} />
            <MiniMap />
            <Controls />
          </ReactFlow>
        </ReactFlowProvider>
      </div>
      <Popover
        open={!!contextMenu}
        anchorReference="anchorPosition"
        anchorPosition={{ top: contextMenu?.y, left: contextMenu?.x }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}

      >
        <Paper>
          <ClickAwayListener onClickAway={() => setContextMenu(null)}>
            <MenuList
              id="composition-menu"
              aria-labelledby="composition-button"
              sx={{ minWidth: 200 }}
            // onKeyDown={handleListKeyDown}
            >
              <MenuItem onClick={handleAddComment}>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <ChatBubble />
                  <Typography>Adicionar comentário</Typography>
                </Stack>

              </MenuItem>
            </MenuList>
          </ClickAwayListener>
        </Paper>
      </Popover>
      {MenuDialog}
    </BuilderContext.Provider>
  )
}

Diagram.propTypes = {}

export default Diagram