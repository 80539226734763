import React from "react";

const TabPanel = ({ children, value, name, ...rest }) => {
  return (
    <div
      role="tabpanel"
      id={`simple-tabpanel-${name}`}
      aria-labelledby={`simple-tab-${name}`}
      style={{ display: value === name ? 'block' : 'none', height: '100%' }}
      {...rest}
    >
      {children}
    </div>
  );
};

export default TabPanel;
